import makeState from "./userSettings.state.store";

export default {
  setUserHomePage(state, value) {
    state.userHomePage = value;
  },
  setAccountHomePage(state, value) {
    state.accountHomePage = value;
  },
  setAllActivePages(state, value) {
    state.allActivePages = value;
  },
  resetUserSettings(state) {
    Object.assign(state, makeState());
  }
};
