import { operations, predefinedLiteralOptions } from "@/constants";

const getSelectedOption = (options) =>
  options?.find((option) => option?.selected) || {};

const getAllSelectedOptions = (options) =>
  options?.filter((option) => option?.selected);

const getSelectedComparatorOption = (value, isInverse, isHasOrHasNoValue) => {
  if (isHasOrHasNoValue) {
    if (isInverse) {
      return operations.HAS_VALUE;
    }

    return operations.HAS_NO_VALUE;
  }

  if (isInverse) {
    return `NOT_${value}`;
  }

  return value;
};

const isPredefinedLiteralOption = ({ valueDataType, value }) =>
  !!predefinedLiteralOptions[valueDataType]?.find(
    (predefinedLiteralOption) => predefinedLiteralOption.value === value
  ) || false;

export {
  getSelectedOption,
  getAllSelectedOptions,
  getSelectedComparatorOption,
  isPredefinedLiteralOption
};
