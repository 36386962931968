import {
  getDuplicateIndexes,
  getSelectedOption,
  isLessThanOrEqualToMaxLength,
  isNumber,
  isRequired
} from "@/utils";
import {
  variableAssignmentType,
  variableType,
  expectedDataTypes,
  timers
} from "@/constants";
import { cloneDeep } from "lodash";
import TriggerStepValidationMixin from "@/organisms/TriggerStep/TriggerStepValidation.mixin";

export default {
  mixins: [TriggerStepValidationMixin],
  data() {
    return {
      errorMessages: {
        actionName: undefined,
        actionDescription: undefined,
        functionStepsList: undefined,
        nextActionStep: undefined
      },
      nextActionStepHasError: false
    };
  },
  watch: {
    nextActionStepHasError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.nextActionStepHasError = false;
        }, timers.MODERATE);
      }
    }
  },
  methods: {
    resetNextStepErrorMessage() {
      this.errorMessages.nextActionStep = "";
    },
    async isNextActionStepInvalid() {
      let isInvalid = false;

      if (!isRequired(this.nextActionStep)) {
        isInvalid = true;
        this.errorMessages.nextActionStep = "required";
        this.nextActionStepHasError = true;
      } else {
        this.resetNextStepErrorMessage();
      }

      return isInvalid;
    },
    validateActionName() {
      let isInvalid = false;

      if (!isRequired(this.actionName)) {
        isInvalid = true;
        this.errorMessages.actionName = "required";
      } else if (!isLessThanOrEqualToMaxLength(this.actionName, 255)) {
        isInvalid = true;
        this.errorMessages.actionName = "maximum of 255 characters";
      } else {
        this.errorMessages.actionName = "";
      }
      return isInvalid;
    },
    validateActionDescription() {
      let isInvalid = false;

      if (!isRequired(this.actionDescription)) {
        isInvalid = true;
        this.errorMessages.actionDescription = "required";
      } else if (!isLessThanOrEqualToMaxLength(this.actionDescription, 255)) {
        isInvalid = true;
        this.errorMessages.actionDescription = "maximum of 255 characters";
      } else {
        this.errorMessages.actionDescription = "";
      }
      return isInvalid;
    },
    validateActionDetails() {
      const isActionNameInvalid = this.validateActionName();
      const isActionDescriptionInvalid = this.validateActionDescription();

      return isActionNameInvalid || isActionDescriptionInvalid;
    },
    validateUniqueNamesInExpectedData() {
      let isInvalid = false;
      const expectedDataNames = this.expectedData.map(
        ({ expectedVariableName }) => expectedVariableName
      );
      const indexOfExpectedDataDuplicates =
        getDuplicateIndexes(expectedDataNames);

      if (indexOfExpectedDataDuplicates.length) {
        isInvalid = true;
        for (const index of indexOfExpectedDataDuplicates) {
          this.expectedData[index].expectedVariableNameErrorMessage =
            "variable names must be unique";
        }
      }

      return isInvalid;
    },
    validateExpectedDataName(index) {
      let isInvalid = false;

      if (!isRequired(this.expectedData[index].expectedVariableName)) {
        isInvalid = true;
        this.expectedData[index].expectedVariableNameErrorMessage = "required";
      }
      return isInvalid;
    },
    validateExpectedDataType(index) {
      let isInvalid = false;

      if (!isRequired(this.expectedData[index].expectedVariableType)) {
        isInvalid = true;
        this.expectedData[index].expectedVariableTypeErrorMessage = "required";
      }

      return isInvalid;
    },
    validateExpectedDataSubType(index) {
      let isInvalid = false;
      const isSubTypeValidationRequired =
        this.expectedData[index].expectedVariableType ===
        expectedDataTypes.DDQ_TASK;

      if (
        isSubTypeValidationRequired &&
        !isRequired(this.expectedData[index].expectedVariableSubType)
      ) {
        isInvalid = true;
        this.expectedData[index].expectedVariableSubTypeErrorMessage =
          "required";
      }

      return isInvalid;
    },
    validateExpectedData() {
      let isExpectedDataInvalid = [];

      for (let index = 0; index < this.expectedData.length; index++) {
        this.expectedData[index].expectedVariableNameErrorMessage = "";
        this.expectedData[index].expectedVariableTypeErrorMessage = "";
        this.expectedData[index].expectedVariableSubTypeErrorMessage = "";

        isExpectedDataInvalid.push(this.validateExpectedDataName(index));
        isExpectedDataInvalid.push(this.validateExpectedDataType(index));
        isExpectedDataInvalid.push(this.validateExpectedDataSubType(index));
      }

      isExpectedDataInvalid.push(this.validateUniqueNamesInExpectedData());

      return isExpectedDataInvalid.includes(true);
    },
    validateFunctionStepList() {
      let isInvalid = false;

      if (!isRequired(this.functionSteps)) {
        isInvalid = true;
        this.errorMessages.functionStepsList = "At least 1 step is required";
      } else {
        this.errorMessages.functionStepsList = "";
      }

      return isInvalid;
    },
    validateComponentOptionsOptions(setToBlock) {
      let isInvalid = false;

      if (this.isProxyValueDataType(setToBlock.valueDataType)) {
        const selectedValueOption = getSelectedOption(
          setToBlock.componentOptions?.componentOptions?.options
        );

        if (
          !isRequired(selectedValueOption) ||
          !isRequired(selectedValueOption.value)
        ) {
          isInvalid = true;
          setToBlock.setComponentOptions({
            ...setToBlock.componentOptions,
            componentOptions: {
              ...setToBlock.componentOptions.componentOptions,
              error: "required"
            }
          });
        } else {
          setToBlock.setComponentOptions({
            ...setToBlock.componentOptions,
            componentOptions: {
              ...setToBlock.componentOptions.componentOptions,
              error: ""
            }
          });
        }
      } else {
        const selectedValueOption = getSelectedOption(
          setToBlock.componentOptions?.options
        );

        if (
          !isRequired(selectedValueOption) ||
          !isRequired(selectedValueOption.value)
        ) {
          isInvalid = true;
          setToBlock.setComponentOptions({
            ...setToBlock.componentOptions,
            error: "required"
          });
        } else {
          setToBlock.setComponentOptions({
            ...setToBlock.componentOptions,
            error: ""
          });
        }
      }

      return isInvalid;
    },
    validateStringOrNumericLiteral(setToBlock) {
      let isInvalid = false;

      if (!isRequired(setToBlock.componentOptions.value)) {
        isInvalid = true;
        setToBlock.setComponentOptions({
          ...setToBlock.componentOptions,
          error: "required"
        });
      } else {
        setToBlock.setComponentOptions({
          ...setToBlock.componentOptions,
          error: ""
        });
      }

      return isInvalid;
    },
    validateCalculationOperator(section) {
      let isInvalid = false;
      const selectedBracketOperatorOption = getSelectedOption(
        section.operator.options
      );

      if (
        !isRequired(selectedBracketOperatorOption) ||
        !isRequired(selectedBracketOperatorOption.value)
      ) {
        isInvalid = true;
        section.operator.error = "required";
      } else {
        section.operator.error = "";
      }

      return isInvalid;
    },
    validateCalculationValue(calculation) {
      let isInvalid = false;

      const isValidOption = calculation.value.options.find(
        ({ value }) => value === calculation.value.value
      );
      const isNumericVariable =
        isNaN(calculation.value.value) && !isValidOption;

      if (!isRequired(calculation.value.value)) {
        isInvalid = true;
        calculation.value.error = "required";
      } else if (
        isNumericVariable ||
        !isNumber(Number(calculation.value.value))
      ) {
        isInvalid = true;
        calculation.value.error = "value must be a number";
      } else {
        calculation.value.error = "";
      }

      return isInvalid;
    },
    validateExpression(setToBlock) {
      let isExpressionValid = [];

      for (
        let index = 0;
        index < setToBlock.componentOptions.bracketSection.length;
        index++
      ) {
        const newBracketSection = cloneDeep(
          setToBlock.componentOptions.bracketSection[index]
        );
        isExpressionValid.push(
          this.validateCalculationOperator(newBracketSection)
        );

        for (const calculation of newBracketSection.bracket.componentOptions
          .calculation) {
          isExpressionValid.push(this.validateCalculationOperator(calculation));
          isExpressionValid.push(this.validateCalculationValue(calculation));
        }

        setToBlock.componentOptions.bracketSection.splice(
          index,
          1,
          newBracketSection
        );
      }

      return isExpressionValid.includes(true);
    },
    validateSetToBlockValue(selectedValueTypeOption, setToBlock) {
      if (selectedValueTypeOption.value === variableAssignmentType.LITERAL) {
        if (
          setToBlock.valueDataType === variableType.STRING ||
          setToBlock.valueDataType === variableType.NUMERIC ||
          setToBlock.valueDataType === variableType.DATE_TIME
        ) {
          return this.validateStringOrNumericLiteral(setToBlock);
        } else if (setToBlock.valueDataType === variableType.EXPRESSION) {
          return this.validateExpression(setToBlock);
        } else {
          return this.validateComponentOptionsOptions(setToBlock);
        }
      } else {
        return this.validateComponentOptionsOptions(setToBlock);
      }
    },
    validateSetToBlockValueDataType(setToBlock) {
      let isInvalid = false;

      if (
        !isRequired(setToBlock.valueDataType) &&
        !isRequired(setToBlock.valueDataTypeText)
      ) {
        isInvalid = true;
        setToBlock.setValueDataTypeErrorMessage("required");
      } else {
        setToBlock.setValueDataTypeErrorMessage("");
      }
      return isInvalid;
    },
    validateSetToBlockValueTypeOption(selectedValueTypeOption, setToBlock) {
      let isInvalid = false;

      if (
        !isRequired(selectedValueTypeOption) ||
        !isRequired(selectedValueTypeOption.value)
      ) {
        isInvalid = true;
        setToBlock.setValueTypeErrorMessage("required");
      } else {
        setToBlock.setValueTypeErrorMessage("");
      }

      return isInvalid;
    },
    validateSetToBlock(setToBlock) {
      let isSetToBlockInvalid = [];

      if (!setToBlock.isHidden) {
        const selectedValueTypeOption = getSelectedOption(
          setToBlock.valueTypeOptions
        );

        isSetToBlockInvalid.push(
          this.validateSetToBlockValueDataType(setToBlock)
        );
        isSetToBlockInvalid.push(
          this.validateSetToBlockValueTypeOption(
            selectedValueTypeOption,
            setToBlock
          )
        );
        if (
          selectedValueTypeOption?.value !== variableAssignmentType.NO_VALUE
        ) {
          isSetToBlockInvalid.push(
            this.validateSetToBlockValue(selectedValueTypeOption, setToBlock)
          );
        }
      }

      return isSetToBlockInvalid.includes(true);
    },
    validateSetToBlockList() {
      let isInvalid = false;

      for (let index = 0; index < this.setToBlockList.length; index++) {
        const isSetToBlockInvalid = this.validateSetToBlock(
          this.setToBlockList[index]
        );

        if (!isInvalid && isSetToBlockInvalid) {
          isInvalid = true;
        }
      }

      return isInvalid;
    },
    validateSetStepVariableNameSearchValue(setStep) {
      let isInvalid = false;

      if (!isRequired(setStep.variableNameSearchValue)) {
        isInvalid = true;
        setStep.setVariableNameErrorMessage("required");
      } else {
        setStep.setVariableNameErrorMessage("");
      }
      return isInvalid;
    },
    validateSelectedSetStepVariableNameOption(setStep) {
      let isInvalid = false;

      const selectedOption = getSelectedOption(setStep.variableNameOptions);

      if (!isRequired(selectedOption) || !isRequired(selectedOption.value)) {
        isInvalid = true;
        setStep.setVariableNameErrorMessage("required");
      } else {
        setStep.setVariableNameErrorMessage("");
      }
      return isInvalid;
    },
    validateSetStep(setStep) {
      let isSetStepInvalid = [];

      if (this.isCRUDSetStep(setStep.id)) {
        isSetStepInvalid.push(
          this.validateSetStepVariableNameSearchValue(setStep)
        );
      } else {
        isSetStepInvalid.push(
          this.validateSelectedSetStepVariableNameOption(setStep)
        );
      }
      isSetStepInvalid.push(this.validateSetToBlock(setStep));

      return isSetStepInvalid.includes(true);
    },
    validateSetStepList() {
      let isSetStepListInvalid = [];

      for (let index = 0; index < this.setStepList.length; index++) {
        isSetStepListInvalid.push(
          this.validateSetStep(this.setStepList[index].setStep)
        );
      }

      return isSetStepListInvalid.includes(true);
    },
    validateIfStatementLeftOption(left, ifStatementToValidate) {
      const selectedLeftOption = getSelectedOption(left.options);
      let isInvalid = false;

      if (
        !isRequired(selectedLeftOption) ||
        !isRequired(selectedLeftOption.value)
      ) {
        isInvalid = true;
        ifStatementToValidate.setLeftErrorMessage("required");
      } else {
        ifStatementToValidate.setLeftErrorMessage("");
      }
      return isInvalid;
    },
    validateIfStatementComparatorOption(comparator, ifStatementToValidate) {
      const selectedComparatorOption = getSelectedOption(comparator.options);
      let isInvalid = false;

      if (
        !isRequired(selectedComparatorOption) ||
        !isRequired(selectedComparatorOption.value)
      ) {
        isInvalid = true;
        ifStatementToValidate.setComparatorErrorMessage("required");
      } else {
        ifStatementToValidate.setComparatorErrorMessage("");
      }
      return isInvalid;
    },
    validateIfStatementOperatorOption(operator, ifStatementToValidate) {
      const selectedOperatorOption = getSelectedOption(operator.options);
      let isInvalid = false;

      if (
        !isRequired(selectedOperatorOption) ||
        !isRequired(selectedOperatorOption.value)
      ) {
        isInvalid = true;
        ifStatementToValidate.setOperatorErrorMessage("required");
      } else {
        ifStatementToValidate.setOperatorErrorMessage("");
      }
      return isInvalid;
    },
    validateIfStatement() {
      let isIfStatementInvalid = [];

      for (let index = 0; index < this.ifStatementList.length; index++) {
        const ifStatementToValidate = this.ifStatementList[index].ifStatement;
        const { left, comparator, operator } = ifStatementToValidate;

        isIfStatementInvalid.push(
          this.validateIfStatementLeftOption(left, ifStatementToValidate)
        );
        isIfStatementInvalid.push(
          this.validateIfStatementComparatorOption(
            comparator,
            ifStatementToValidate
          )
        );
        isIfStatementInvalid.push(
          this.validateIfStatementOperatorOption(
            operator,
            ifStatementToValidate
          )
        );
      }

      return isIfStatementInvalid.includes(true);
    },
    validateTriggerStepList() {
      let isInvalid = false;

      if (this.triggerStepList?.length) {
        const isTriggerStepInvalid = [];

        for (let index = 0; index < this.triggerStepList.length; index++) {
          isTriggerStepInvalid.push(
            this.validateTriggerStep(this.triggerStepList[index].triggerStep)
          );
        }

        isInvalid = isTriggerStepInvalid.includes(true);
      }

      return isInvalid;
    },
    validateUniqueAssignmentForProxyVariables(name, index) {
      let isInvalid = false;
      const localVariableNames = this.functionSteps.map(
        ({ variableNameSearchValue }) => variableNameSearchValue
      );
      localVariableNames.splice(index, 1);
      if (name && localVariableNames.includes(name)) {
        isInvalid = true;
        this.functionSteps[index].setVariableNameErrorMessage(
          "Variable name is already in use"
        );
      } else {
        this.functionSteps[index].setVariableNameErrorMessage("");
      }

      return isInvalid;
    },
    async validateAction() {
      const isActionDetailsInvalid = this.validateActionDetails();
      const isExpectedDataInvalid = this.validateExpectedData();
      const isFunctionStepListInvalid = this.validateFunctionStepList();
      const isSetStepListInvalid = this.validateSetStepList();
      const isSetToBlockListInvalid = this.validateSetToBlockList();
      const isIfStatementListInvalid = this.validateIfStatement();
      const isTriggerStepListInvalid = this.validateTriggerStepList();

      return (
        isActionDetailsInvalid ||
        isExpectedDataInvalid ||
        isFunctionStepListInvalid ||
        isSetStepListInvalid ||
        isSetToBlockListInvalid ||
        isIfStatementListInvalid ||
        isTriggerStepListInvalid
      );
    }
  }
};
