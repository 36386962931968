import { format } from "date-fns";
import { eb360DateFormat } from "@/constants";

/**
 * @namespace Date
 */

/**
 * Returns `true` if provided parameter is an ISO date
 * @memberof Date
 * @param {string} string - date to test
 * @returns {boolean} - result
 */
const isISODate = (string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(string)) {
    return false;
  }

  const date = new Date(string);

  return date instanceof Date && !isNaN(date) && date.toISOString() === string;
};

/**
 * Returns a randomly generated number
 * @memberof Date
 * @returns {number} - random number
 */
const makeUUID = () =>
  Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

/**
 * Returns `true` if the provided parameter is a valid date
 * @memberof Date
 * @param {Date} date - date to check
 * @returns {boolean} - result of check
 */
const isValidDate = (date) => new Date(date)?.toString() !== "Invalid Date";

// eslint-disable-next-line jsdoc/check-param-names
/**
 * Returns a date in a provided specified format
 * @memberof Date
 * @param {object} parameter - Provided object
 * @param {string} parameter.stringifiedDate - The date in a string format.
 * @param {string} parameter.dateFormat - The requested date format.
 * @param {Function} parameter._isValidDate - Returns a boolean which indicates if date is valid.
 * @param {Function} parameter._format - Returns a new date instance which follows the requested format.
 * @returns {string} A date instance in a specific formatted string
 */
const makeDateToMatchFormat = ({
  stringifiedDate = "",
  dateFormat = eb360DateFormat.DEFAULT_DATE_FORMAT_SLASH,
  _isValidDate = isValidDate,
  _format = format
} = {}) => {
  const date = new Date(stringifiedDate);
  if (_isValidDate(date)) {
    return _format(date, dateFormat);
  } else {
    return stringifiedDate;
  }
};

export { makeUUID, isISODate, makeDateToMatchFormat, isValidDate };
