<template>
  <div>
    <div class="d-flex" style="justify-content: center">
      <h4 class="ml-auto">Configure Actions for {{ companyName }}</h4>

      <div class="ml-auto my-2 d-flex">
        <!-- default actions button for resetting configuration -->
        <b-form-checkbox
          v-model="status"
          name="checkbox-1"
          :value="1"
          :unchecked-value="0"
          class="mt-2"
        >
          Default Actions
        </b-form-checkbox>

        <!-- save button -->
        <b-btn @click="onSubmitActions" variant="outline-success">
          Submit
        </b-btn>
      </div>
    </div>
    <b-overlay :show="isSubmiting">
      <b-row class="ml-5">
        <!-- All actions col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">All Actions</h5>
          <hr style="margin: 0px" />
          <draggable
            :list="allActions"
            :v-model="allActions"
            group="actions"
            style="height: calc(100vh - 165px); overflow-y: auto"
          >
            <b-list-group
              v-for="(actionAll, index) in allActions"
              :key="index"
              v-show="!actionAll.isHidden"
            >
              <b-list-group-item class="mb-1">{{
                actionAll.btnText
              }}</b-list-group-item>
            </b-list-group>
          </draggable>
        </b-col>

        <!-- What action will occur col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">What action will occur</h5>
          <hr style="margin: 0px" />
          <draggable
            style="height: calc(100vh - 165px); overflow-y: auto"
            :list="actionsOccur"
            :v-model="actionsOccur"
            group="actions"
          >
            <b-list-group
              v-for="(actionOccur, index) in actionsOccur"
              :key="index"
            >
              <b-list-group-item class="mb-1">{{
                actionOccur.btnText
              }}</b-list-group-item>
            </b-list-group>
          </draggable>
        </b-col>

        <!-- Extra actions col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">Extra actions</h5>
          <hr style="margin: 0px" />
          <draggable
            style="height: calc(100vh - 165px); overflow-y: auto"
            :list="extraActions"
            :v-model="extraActions"
            group="actions"
          >
            <b-list-group
              v-for="(extraAction, index) in extraActions"
              :key="index"
            >
              <b-list-group-item class="mb-1">{{
                extraAction.btnText
              }}</b-list-group-item>
            </b-list-group>
          </draggable>
        </b-col>

        <!-- Unavailable Actions col -->
        <b-col
          class="kanban-col"
          style="border: 1px dashed black; padding: 0px"
          cols="3"
        >
          <h5 class="pt-1">Unavailable Actions</h5>
          <hr style="margin: 0px" />
          <draggable
            style="height: calc(100vh - 165px); overflow-y: auto"
            :list="actionsNotAvailable"
            :v-model="actionsNotAvailable"
            group="actions"
          >
            <b-list-group
              v-for="(notAvailableAction, index) in actionsNotAvailable"
              :key="index"
            >
              <b-list-group-item class="mb-1">{{
                notAvailableAction.btnText
              }}</b-list-group-item>
            </b-list-group>
          </draggable>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { allActions } from "@/constants";
import { EventBus } from "@/event-bus.js";
import { clone } from "lodash";

export default {
  components: {
    draggable
  },
  data() {
    return {
      isSubmiting: false,
      isLoading: false,
      isLoadingData: false,
      allActions: clone(allActions),
      actionsOccur: [],
      extraActions: [],
      actionsNotAvailable: [],
      status: 0,
      companyName: null,
      questionnaires: [],
      products: []
    };
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
    this.getAdditionalVouchers();
  },
  created() {
    EventBus.$on("callgetAdditionalVouchers", () => {
      this.getAdditionalVouchers();
    });
  },
  methods: {
    async getAdditionalVouchers() {
      this.isSubmiting = true;
      let schema = JSON.parse(localStorage.getItem("actionsSchema"));
      if (schema) {
        if (schema.actionsOccur) {
          schema.actionsOccur.map((column) => {
            let staticFieldIndex = allActions.findIndex(
              (x) => x.id == column.id
            );
            if (staticFieldIndex != -1) {
              let staticAction = allActions[staticFieldIndex];
              if (column.btnText != staticAction.btnText) {
                column.btnText = staticAction.btnText;
              }
              Object.keys(staticAction).map((staticKey) => {
                if (column[staticKey] == null) {
                  column[staticKey] = staticAction[staticKey];
                } else if (typeof staticAction[staticKey] == "object") {
                  Object.keys(staticAction[staticKey]).map((subStaticKey) => {
                    if (column[staticKey][subStaticKey] == null) {
                      column[staticKey][subStaticKey] =
                        staticAction[staticKey][subStaticKey];
                    }
                  });
                }
              });
            }
            this.actionsOccur = schema.actionsOccur || [];
          });
        }
        if (schema.extraActions) {
          schema.extraActions.map((column) => {
            let staticFieldIndex = allActions.findIndex(
              (x) => x.id == column.id
            );
            if (staticFieldIndex != -1) {
              let staticAction = allActions[staticFieldIndex];
              if (column.btnText != staticAction.btnText) {
                column.btnText = staticAction.btnText;
              }
              Object.keys(staticAction).map((staticKey) => {
                if (column[staticKey] == null) {
                  column[staticKey] = staticAction[staticKey];
                } else if (typeof staticAction[staticKey] == "object") {
                  Object.keys(staticAction[staticKey]).map((subStaticKey) => {
                    if (column[staticKey][subStaticKey] == null) {
                      column[staticKey][subStaticKey] =
                        staticAction[staticKey][subStaticKey];
                    }
                  });
                }
              });
            }
            this.extraActions = schema.extraActions || [];
          });
        }
        if (schema.actionsNotAvailable) {
          schema.actionsNotAvailable.map((column) => {
            let staticFieldIndex = allActions.findIndex(
              (x) => x.id == column.id
            );
            if (staticFieldIndex != -1) {
              let staticAction = allActions[staticFieldIndex];
              if (column.btnText != staticAction.btnText) {
                column.btnText = staticAction.btnText;
              }
              Object.keys(staticAction).map((staticKey) => {
                if (column[staticKey] == null) {
                  column[staticKey] = staticAction[staticKey];
                } else if (typeof staticAction[staticKey] == "object") {
                  Object.keys(staticAction[staticKey]).map((subStaticKey) => {
                    if (column[staticKey][subStaticKey] == null) {
                      column[staticKey][subStaticKey] =
                        staticAction[staticKey][subStaticKey];
                    }
                  });
                }
              });
            }
            this.actionsNotAvailable = schema.actionsNotAvailable || [];
          });
        }
      }
      this.getQuestionnariesData();
    },
    resetAllActionsValue(allSelectedActions) {
      if (allSelectedActions) {
        let abcqActionIndex = this.allActions.findIndex((x) => x.id == 9);
        if (abcqActionIndex != -1) {
          this.allActions.splice(abcqActionIndex, 1);
        }
        abcqActionIndex = this.actionsNotAvailable.findIndex((x) => x.id == 9);
        if (abcqActionIndex != -1) {
          this.actionsNotAvailable.splice(abcqActionIndex, 1);
        }
        allSelectedActions.map((actions) => {
          let actionsIndex = this.allActions.findIndex(
            (x) => x.id == actions.id
          );
          if (actionsIndex != -1) {
            this.allActions.splice(actionsIndex, 1);
          }
        });
        this.allActions.map((actions) => {
          if (!actions.isHidden) {
            let actionsOccurIndex = this.actionsOccur.findIndex(
              (x) => x.btnText == actions.btnText
            );
            if (actionsOccurIndex != -1) {
              this.actionsOccur[actionsOccurIndex] = actions;
            }

            let extraActionsIndex = this.extraActions.findIndex(
              (x) => x.btnText == actions.btnText
            );
            if (extraActionsIndex != -1) {
              this.extraActions[extraActionsIndex] = actions;
            }

            let actionsNotAvailableIndex = this.actionsNotAvailable.findIndex(
              (x) => x.btnText == actions.btnText
            );
            if (actionsNotAvailableIndex != -1) {
              this.actionsNotAvailable[actionsNotAvailableIndex] = actions;
            }
          }
        });
        allSelectedActions.map((actions) => {
          let actionsIndex = this.allActions.findIndex(
            (x) => x.btnText == actions.btnText
          );
          if (actionsIndex != -1) {
            this.allActions.splice(actionsIndex, 1);
          }
        });
      }
      this.onSubmitActions();
    },

    // save configuarion API
    async onSubmitActions() {
      let questionare = clone(allActions);
      questionare.push(...this.questionnaires);
      questionare.push(...this.products);
      let formData = {
        company_id: this.$route.params.id,
        actionsOccur: this.actionsOccur,
        extraActions: this.extraActions,
        actionsNotAvailable: this.actionsNotAvailable,
        allActions: this.status == 1 ? questionare : this.allActions,
        is_default_actions: this.status
      };
      this.isSubmiting = true;
      this.isLoading = true;
      try {
        let result = await HTTP(
          "post",
          "thirdparty/update-tp/dynamic-actions",
          formData,
          makeAuthorizationHeader()
        );
        //
        if (result.status == 200) {
          let schema = JSON.parse(result?.data?.additional_data);
          if (schema) {
            this.allActions =
              schema.allActions && schema.allActions.length
                ? schema.allActions
                : [];
            this.actionsOccur =
              schema.actionsOccur && schema.actionsOccur.length
                ? schema.actionsOccur
                : [];
            this.extraActions =
              schema.extraActions && schema.extraActions.length
                ? schema.extraActions
                : [];
            this.actionsNotAvailable =
              schema.actionsNotAvailable && schema.actionsNotAvailable.length
                ? schema.actionsNotAvailable
                : [];
          }
          let actionsData = schema || null;
          localStorage.setItem("actionsSchema", JSON.stringify(actionsData));
          this.status = 0;
          this.isSubmiting = false;
          this.isLoading = false;
        }
      } catch (error) {
        this.isSubmiting = false;
        this.isLoading = false;
      }
    },

    // get MSQ
    async getQuestionnariesData() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslist/${this.$route.params.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          result.data.questionnaires.map((items) => {
            this.allActions.push(items);
          });
          result.data.products.map((items) => {
            this.allActions.push(items);
          });
          this.questionnaires = result.data.questionnaires;
          this.products = result.data.products;
          let allSelectedActions = [];
          allSelectedActions.push(...this.actionsOccur);
          allSelectedActions.push(...this.extraActions);
          allSelectedActions.push(...this.actionsNotAvailable);

          if (allSelectedActions && allSelectedActions.length > 0) {
            this.resetAllActionsValue(allSelectedActions);
            // this.allActions = schema.allActions || []
          } else {
            this.isSubmiting = false;
          }
        }
      } catch (error) {
        this.isSubmiting = false;
      }
    }
  }
};
</script>

<style>
.kanban-col {
  min-height: 500px;
}
</style>
