import { riskAlertKeysDTO } from "@/constants";

const ESG_DISPLAY_TEXT = {
  MODERATE: "Moderate",
  WEAK: "Weak",
  STRONG: "Strong",
  CRITICAL: "Critical",
  SERIOUS: "Serious",
  NO_MATCH: "None",
  NONE: "None"
};

const RISK_ALERT_HEADERS = [
  { text: "Pillar", value: riskAlertKeysDTO.PILLAR },
  { text: "Risk severity", value: riskAlertKeysDTO.SEVERITY },
  { text: "Risk category", value: riskAlertKeysDTO.CATEGORY },
  { text: "Risk date", value: riskAlertKeysDTO.DATE },
  { text: "Risk alert received", value: riskAlertKeysDTO.RECEIVED_DATE },
  { text: "Description", value: riskAlertKeysDTO.DESCRIPTION }
];

const esgTexts = {
  ESG_EXPLORE: "ESG Explore",
  ESG_THIRD_PARTIES: "ESG Explore third parties",
  ESG_EXPLORE_SUMMARY: "ESG Explore Summary"
};

export { esgTexts, RISK_ALERT_HEADERS, ESG_DISPLAY_TEXT };
