var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2',attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('b-collapse',{class:_vm.isPreview ? 'mt-1' : 'mt-5',attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-form-group',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"label":(_vm.$store.state.form1.enableSectionFieldEnumeration == 1
          ? _vm.sectionIndex + 1 + '.' + (_vm.fieldIndex + 1)
          : '') +
        ' ' +
        _vm.label,"id":_vm.fieldData.id.toString()}},[_c('b-form-checkbox',{staticClass:"my-2",attrs:{"value":"1","unchecked-value":"0"},on:{"change":function($event){_vm.isFocus = true}},model:{value:(_vm.acceptTermsText),callback:function ($$v) {_vm.acceptTermsText=$$v},expression:"acceptTermsText"}},[(_vm.fieldData.validation && _vm.fieldData.validation.required == 1)?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.checkboxLabel)+" "),(
            _vm.fieldData.validation &&
            _vm.fieldData.validation.required == 1 &&
            _vm.acceptTermsText != 1 &&
            _vm.isFocus
          )?_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":false}},[_vm._v(_vm._s(_vm.$t("createProfile.requiredValidationMessage")))]):_vm._e()],1),(_vm.buttonLabel != '')?_c('b-button',{attrs:{"block":"","variant":"primary","href":_vm.downloadPdf(),"target":"_blank"}},[_vm._v(_vm._s(_vm.buttonLabel))]):_vm._e()],1),_c('b-modal',{attrs:{"cancel-title":"Close","ok-title":"save","size":"xl","title":"Checkbox Settings"},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[(_vm.showSettings)?_c('AdvancePDFSettings',{attrs:{"fieldData":_vm.fieldData},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }