import {
  colors,
  gradeRating,
  riskScale,
  strengthScale,
  themes,
  typographySize,
  greenliteKeySections
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";

/**
 * @namespace GreenliteESG
 */

/**
 * Returns theme for a given ESG pillar rating
 * @memberof GreenliteESG
 * @param {string} pillarRating - rating
 * @returns {string} - theme
 */
const getPillarRatingTheme = (pillarRating) => {
  switch (pillarRating) {
    case gradeRating.AAA:
      return themes.SUCCESS;

    case gradeRating.AA:
      return themes.STRONG_GREEN;

    case gradeRating.A:
      return themes.MUTED_GREEN;

    case gradeRating.BBB:
      return themes.MUTED_YELLOW;

    case gradeRating.BB:
      return themes.WARNING;

    case gradeRating.B:
      return themes.ERROR;

    case gradeRating.C:
      return themes.STRONG_ERROR;

    default:
      return themes.NONE;
  }
};

/**
 * Returns theme for a given ESG strength rating
 * @memberof GreenliteESG
 * @param {string} strengthRating - rating
 * @returns {string} - theme
 */
const getStrengthRatingTheme = (strengthRating) => {
  switch (strengthRating) {
    case strengthScale.STRONG:
      return themes.MUTED_GREEN;

    case strengthScale.MODERATE:
      return themes.MUTED_YELLOW;

    case strengthScale.WEAK:
      return themes.ERROR;

    case strengthScale.NONE:
      return themes.INVERSE;

    default:
      return themes.NONE;
  }
};

/**
 * Returns theme for a given ESG risk rating
 * @memberof GreenliteESG
 * @param {string} riskRating - rating
 * @returns {string} - theme
 */
const getRiskRatingTheme = (riskRating) => {
  switch (riskRating) {
    case riskScale.NO_MATCH:
    case riskScale.NONE:
      return themes.MUTED_GREEN;

    case riskScale.CRITICAL:
      return themes.INVERSE;

    case riskScale.SERIOUS:
      return themes.ERROR;

    case riskScale.MODERATE:
      return themes.MUTED_YELLOW;

    default:
      return themes.NONE;
  }
};

/**
 * Return a background hex color for a given pillar rating
 * @memberof GreenliteESG
 * @param {string} pillarRating - rating
 * @returns {string} - color
 */
const getOverallPillarBackgroundColor = (pillarRating) => {
  switch (pillarRating) {
    case gradeRating.AAA:
      return colors.appleGreen;

    case gradeRating.AA:
      return colors.robinEggBlue;

    case gradeRating.A:
      return colors.teaGreen;

    case gradeRating.BBB:
      return colors.shandy;

    case gradeRating.BB:
      return colors.metallicYellow;

    case gradeRating.B:
      return colors.spanishRed;

    case gradeRating.C:
      return colors.bloodRed;

    default:
      return "";
  }
};

/**
 * Return a background hex color for a given risk rating
 * @memberof GreenliteESG
 * @param {string} riskRating - rating
 * @returns {string} - color
 */
const getRiskRatingBackgroundColor = (riskRating) => {
  switch (riskRating) {
    case riskScale.NO_MATCH:
    case riskScale.NONE:
      return colors.teaGreen;

    case riskScale.CRITICAL:
      return colors.black;

    case riskScale.SERIOUS:
      return colors.spanishRed;

    case riskScale.MODERATE:
      return colors.shandy;

    default:
      return "";
  }
};

/**
 * Return a background hex color for a given strength rating
 * @memberof GreenliteESG
 * @param {string} strengthRating - rating
 * @returns {string} - color
 */
const getStrengthRatingBackgroundColor = (strengthRating) => {
  switch (strengthRating) {
    case strengthScale.STRONG:
      return colors.teaGreen;

    case strengthScale.MODERATE:
      return colors.shandy;

    case strengthScale.WEAK:
      return colors.spanishRed;

    case strengthScale.NONE:
      return colors.black;

    default:
      return "";
  }
};

/**
 * Returns a props object to use with StickyTable and/or TableHeader components
 * @memberof GreenliteESG
 * @param {string} label - label to be used in table
 * @param {boolean} isTableHeader - if the object is to be used as a table column header
 * @param {boolean} isBold - if the text should include the bold style
 * @returns {{componentOptions: {tag: string, text: string}, component: {}}} - object to be used in table
 */
const makeESGTableLabelObject = (label, isTableHeader, isBold) => ({
  ...(isTableHeader && { value: "label" }),
  component: BaseText,
  componentOptions: {
    tag: "span",
    text: label,
    ...(isBold && { size: typographySize.BODY_TEXT_BOLD })
  }
});

/**
 * Returns a props object to use with StickyTable and/or TableRow components
 * @memberof GreenliteESG
 * @param {string} text - text to be used in table
 * @param {string} theme - color theme
 * @returns {{componentOptions: {theme: string, styles: {}, text: string, minimumWidth: string, size: string}, component: {}}} - object to be used in table
 */
const makeESGTableRatingObject = (text, theme) => ({
  component: BaseBadge,
  componentOptions: {
    text,
    theme,
    minimumWidth: "0",
    size: typographySize.BODY_TEXT_BOLD
  },
  styles: {
    padding: "15px"
  }
});

/**
 * Returns a theme based on provided key and rating
 * @memberof GreenliteESG
 * @param {string} ratingValue - rating
 * @param {string} key - key
 * @returns {string} - theme
 */
const getRatingTheme = (ratingValue, key) => {
  if (greenliteKeySections.ratingKeys.includes(key)) {
    return getPillarRatingTheme(ratingValue);
  } else if (greenliteKeySections.severityKeys.includes(key)) {
    return getRiskRatingTheme(ratingValue);
  } else if (greenliteKeySections.strengthKeys.includes(key)) {
    return getStrengthRatingTheme(ratingValue);
  } else {
    return "";
  }
};

export {
  getRatingTheme,
  getPillarRatingTheme,
  getStrengthRatingTheme,
  getRiskRatingTheme,
  getOverallPillarBackgroundColor,
  getRiskRatingBackgroundColor,
  getStrengthRatingBackgroundColor,
  makeESGTableLabelObject,
  makeESGTableRatingObject
};
