var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"title":'Preview' + ' ' + _vm.templateName + ' ' + 'Template',"size":"lg","no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(!_vm.submiting)?_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.resetModal}},[_vm._v("Close")]):_vm._e(),(!_vm.submiting && !_vm.responseSuccess)?_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":_vm.checkValidation}},[_vm._v("Send")]):_vm._e()]},proxy:true}]),model:{value:(_vm.showAddAdminEmailSettingModal),callback:function ($$v) {_vm.showAddAdminEmailSettingModal=$$v},expression:"showAddAdminEmailSettingModal"}},[_c('div',[(!_vm.submiting && !_vm.responseSuccess)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-input',{attrs:{"state":_vm.$v.form.emailAddress.$dirty
                ? !_vm.$v.form.emailAddress.$error
                : null,"placeholder":"Write your email here.....","type":"email"},model:{value:(_vm.$v.form.emailAddress.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.emailAddress, "$model", $$v)},expression:"$v.form.emailAddress.$model"}}),(
              _vm.$v.form.emailAddress.$dirty &&
              _vm.$v.form.emailAddress.$error &&
              !_vm.$v.form.emailAddress.required
            )?_c('b-form-invalid-feedback',[_vm._v("Please Enter Email Address")]):_vm._e(),(
              _vm.$v.form.emailAddress.$dirty &&
              _vm.$v.form.emailAddress.$error &&
              !_vm.$v.form.emailAddress.email
            )?_c('b-form-invalid-feedback',[_vm._v("Please Enter valid Email")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"6"}},[_c('multiselect',{class:{
              'form-control is-invalid p-0':
                _vm.$v.form.selectedLanguageCodes.$error
            },attrs:{"maxHeight":220,"state":_vm.$v.form.selectedLanguageCodes.$dirty
                ? !_vm.$v.form.selectedLanguageCodes.$error
                : null,"label":"languages","track-by":"languages","placeholder":"Select Language","multiple":true,"options":_vm.langauagesOptions},on:{"input":_vm.setEmailLanguages},model:{value:(_vm.$v.form.selectedLanguageCodes.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.selectedLanguageCodes, "$model", $$v)},expression:"$v.form.selectedLanguageCodes.$model"}}),(
              _vm.$v.form.selectedLanguageCodes.$dirty &&
              _vm.$v.form.selectedLanguageCodes.$error &&
              !_vm.$v.form.selectedLanguageCodes.required
            )?_c('b-form-invalid-feedback',[_vm._v("Please Select a language")]):_vm._e()],1)],1):(_vm.submiting && !_vm.responseSuccess)?_c('div',[_c('div',{staticStyle:{"display":"grid","justify-content":"center"}},[_c('p',[_vm._v("Email Templates is now being sending......")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-spinner',{attrs:{"type":"grow","label":"Loading..."}})],1)])]):_c('div',[_c('p',{staticClass:"my-2 text-center"},[_vm._v(" Email templates have been sent successfully. "),_c('br')])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }