import makeState from "./esg.state.store";

export default {
  resetESGState(state) {
    Object.assign(state, makeState());
  },
  setESGReports(state, value) {
    state.ESGReports = value;
  },
  setLatestESGReport(state, value) {
    state.latestESGReport = value;
  },
}