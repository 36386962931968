<template>
  <div class="end-operations" data-test-id="end-operations">
    <CallToAction
      v-if="isCtaVisible"
      class="end-operations__add-round--cta"
      data-test-id="end-operations__add-round--cta"
      aria-label="Add round"
      value="Add round"
      :theme="themes.SECONDARY"
      @click="$emit('change', { name: roundEvents.ADD_ROUND })"
    />
    <div
      v-else
      class="end-operations__details"
      data-test-id="end-operations__details"
    >
      <template v-for="(operation, index) in endOperations">
        <component
          v-if="operation.component"
          :key="operation.id"
          :is="operation.component"
          v-bind="{
            ...operation.componentOptions,
            id: operation.id
          }"
          :data-test-id="`end-operations__details-operation--${index}`"
          @change="$emit('change', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { roundEvents, themes } from "@/constants";

export default {
  name: "EndOperations",
  components: {
    CallToAction
  },
  props: {
    endOperations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      roundEvents,
      themes
    };
  },
  computed: {
    isCtaVisible() {
      return !this.endOperations.length;
    }
  }
};
</script>
