var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2',attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"isHidden":_vm.isHidden,"label":_vm.label,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('b-collapse',{staticClass:"card mt-5",attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.fieldData.isConditional == '1')?_c('b-button',{attrs:{"variant":"outline-dark","disabled":"","title":"This field is hidden by default and will display bt is controlled element"}},[_c('font-awesome-icon',{attrs:{"icon":"eye-slash"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"mt-3",attrs:{"id":_vm.fieldData.id}},[_c('h4',[_vm._v(_vm._s(_vm.fieldData.label[_vm.selectedLanguage]))]),_c('p',[_vm._v(_vm._s(_vm.fieldData.description[_vm.selectedLanguage]))]),_c('b-form-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.isNaFeature),expression:"!fieldData.isNaFeature"}],attrs:{"id":"na-principa-contact","value":"1","unchecked-value":"0"},model:{value:(_vm.fieldData.isNa),callback:function ($$v) {_vm.$set(_vm.fieldData, "isNa", $$v)},expression:"fieldData.isNa"}},[_vm._v(_vm._s(_vm.$t("na")))])],1),_c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","title":_vm.isUpdtating
          ? _vm.$t('workflow.update') + ' ' + _vm.fieldData.label[_vm.selectedLanguage]
          : _vm.$t('workflow.add') + ' ' + _vm.fieldData.label[_vm.selectedLanguage],"size":"xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.resetModal}},[_vm._v(_vm._s(_vm.$t("closeBtnText")))]),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.isUpdtating ? _vm.updateRow() : _vm.addRow()}}},[_vm._v(_vm._s(_vm.isUpdtating ? _vm.$t("updateBtn") : _vm.$t("saveBtnText")))])]},proxy:true}]),model:{value:(_vm.showAddRowModal),callback:function ($$v) {_vm.showAddRowModal=$$v},expression:"showAddRowModal"}},[_c('div',{class:_vm.$store.state.rightAlign
            ? 'card-body text-right'
            : 'card-body text-left'},[_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.forenameDisabled),expression:"!fieldData.advance || !fieldData.advance.forenameDisabled"}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"fieldset-1","description":_vm.fieldData.foreNameHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.foreNameValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequireForename)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.foreNameLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.foreNamePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.foreName.$dirty
                    ? !_vm.$v.form.foreName.$error
                    : null},model:{value:(_vm.$v.form.foreName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.foreName, "$model", $$v)},expression:"$v.form.foreName.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.lastnameDisabled),expression:"!fieldData.advance || !fieldData.advance.lastnameDisabled"}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"fieldset-2","description":_vm.fieldData.lastNameHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.lastNameValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequireLastname)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.lastNameLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.lastNamePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.lastName.$dirty
                    ? !_vm.$v.form.lastName.$error
                    : null},model:{value:(_vm.$v.form.lastName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.lastName, "$model", $$v)},expression:"$v.form.lastName.$model"}})],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.contactDisabled),expression:"!fieldData.advance || !fieldData.advance.contactDisabled"}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"fieldset-3","description":_vm.fieldData.contactNumberHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.contactNumberValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequireContact)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.contactNumberLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"onkeydown":"return event.keyCode !== 69","disabled":_vm.isDisabled == 1,"type":"number","onpaste":"return false;","placeholder":_vm.fieldData.contactNumberPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.contactNumber.$dirty
                    ? !_vm.$v.form.contactNumber.$error
                    : null},model:{value:(_vm.$v.form.contactNumber.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.contactNumber, "$model", $$v)},expression:"$v.form.contactNumber.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.emailDisabled),expression:"!fieldData.advance || !fieldData.advance.emailDisabled"}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"fieldset-4","description":_vm.fieldData.emailAddressHelperText[_vm.selectedLanguage],"invalid-feedback":!_vm.$v.form.emailAddress.email
                  ? _vm.$t('validations.invalidFormat')
                  : _vm.fieldData.emailAddressValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequireEmail)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.emailAddressLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"email","placeholder":_vm.fieldData.emailAddressPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.emailAddress.$dirty
                    ? !_vm.$v.form.emailAddress.$error
                    : null},model:{value:(_vm.$v.form.emailAddress.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.emailAddress, "$model", $$v)},expression:"$v.form.emailAddress.$model"}})],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.roleDisabled),expression:"!fieldData.advance || !fieldData.advance.roleDisabled"}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"fieldset-5","description":_vm.fieldData.rolePositionHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.rolePositionValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequireRole)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.rolePositionLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.rolePositionPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.rolePositionLabel.$dirty
                    ? !_vm.$v.form.rolePositionLabel.$error
                    : null},model:{value:(_vm.$v.form.rolePositionLabel.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.rolePositionLabel, "$model", $$v)},expression:"$v.form.rolePositionLabel.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.linkedinDisabled),expression:"!fieldData.advance || !fieldData.advance.linkedinDisabled"}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"fieldset-6","description":_vm.fieldData.linkedInProfileHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.linkedInProfileValidationMessage[_vm.selectedLanguage]}},[_c('label',[(JSON.parse(_vm.fieldData.validation.isRequireLinkedin))?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.linkedInProfileLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.linkedInProfilePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.linkedInProfile.$dirty
                    ? !_vm.$v.form.linkedInProfile.$error
                    : null},model:{value:(_vm.$v.form.linkedInProfile.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.linkedInProfile, "$model", $$v)},expression:"$v.form.linkedInProfile.$model"}})],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled),expression:"!fieldData.advance || !fieldData.advance.freeFormDisabled"}],staticClass:"col-md-6"},[(_vm.fieldData.freeFormLabel)?_c('div',[_c('b-form-group',{attrs:{"id":"fieldset-6","description":_vm.fieldData.freeFormHelperText[_vm.selectedLanguage] || '',"invalid-feedback":_vm.fieldData.freeFormValidationMessage[_vm.selectedLanguage] || ''}},[_c('label',[(
                      _vm.fieldData.validation &&
                      JSON.parse(_vm.fieldData.validation.isRequireFreeForm)
                    )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.freeFormLabel[_vm.selectedLanguage] || "")+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.freeFormPlaceholderText[_vm.selectedLanguage] || '',"state":_vm.$v.form && _vm.$v.form.freeForm.$dirty
                      ? !_vm.$v.form.freeForm.$error
                      : null},model:{value:(_vm.$v.form.freeForm.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.freeForm, "$model", $$v)},expression:"$v.form.freeForm.$model"}})],1)],1):_vm._e()])])])]),(_vm.taskStatus != 4)?_c('b-row',{attrs:{"id":_vm.fieldData.id}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"disabled":_vm.isDisabled == 1 || _vm.fieldData.isNa == 1,"variant":"outline-success","size":"lg"},on:{"click":function($event){_vm.showAddRowModal = !_vm.showAddRowModal}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("workflow.add"))+" ")],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"p-5",attrs:{"cols":"12"}},[_c('b-table-simple',{attrs:{"striped":"","hover":""}},[_c('b-thead',[_c('b-tr',[(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.forenameDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.foreNameLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.lastnameDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.lastNameLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.contactDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.contactNumberLabel[_vm.selectedLanguage]))]):_vm._e(),(!_vm.fieldData.advance || !_vm.fieldData.advance.emailDisabled)?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.emailAddressLabel[_vm.selectedLanguage]))]):_vm._e(),(!_vm.fieldData.advance || !_vm.fieldData.advance.roleDisabled)?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.rolePositionLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.linkedinDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.linkedInProfileLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.freeFormLabel && _vm.fieldData.freeFormLabel[_vm.selectedLanguage]))]):_vm._e(),_c('b-th',[_vm._v(_vm._s(_vm.$t("upload.fileAction")))])],1)],1),_vm._l((_vm.items),function(item,index){return _c('b-tbody',{key:index},[_c('b-tr',[(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.forenameDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.forename))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.lastnameDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.lastName))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.contactDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.contact_number))]):_vm._e(),(!_vm.fieldData.advance || !_vm.fieldData.advance.emailDisabled)?_c('b-td',[_vm._v(_vm._s(item.email_address))]):_vm._e(),(!_vm.fieldData.advance || !_vm.fieldData.advance.roleDisabled)?_c('b-td',[_vm._v(_vm._s(item.role_or_positon))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.linkedinDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.linked_in_profile))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.free_form))]):_vm._e(),_c('b-td',[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.onEditRow(item, index)}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.taskStatus != 4 ? 'edit' : 'eye'}})],1),(_vm.taskStatus != 4)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)],1)],1)})],2)],1)],1),_c('b-modal',{attrs:{"cancel-title":"Close","ok-title":"save","size":"xl","scrollable":"","title":"Advanced Settings"},on:{"ok":_vm.onSaveData},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[_c('AdvanceSettings',{attrs:{"fieldData":_vm.fieldData,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }