<template>
  <div class="base-loader" data-test-id="base-loader">
    <font-awesome-icon icon="spinner" class="base-loader__icon" />
  </div>
</template>

<script>
export default {
  name: "BaseLoader"
};
</script>

<style lang="scss" scoped>

.base-loader {
  font-size: 4em;
  text-align: center;

  &__icon {
    @include rotate(1s);
  }
}
</style>
