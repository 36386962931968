<template>
  <section>
    <BaseLoader v-if="isLoadingTableData" />
    <template v-else>
      <InputErrorMessage :error="errorMessage" />
      <StickyTable
        :table-headers="tableHeaders"
        :table-rows="tableRows"
        class="version-table"
        data-test-id="version-table"
      />
    </template>
  </section>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  productVersionTableHeaders,
  actionName,
  typographySize,
  serviceVersionMapper,
  mutationName,
  getterName
} from "@/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";

export default {
  name: "VersionTemplate",
  components: { StickyTable, BaseLoader, InputErrorMessage },
  data() {
    return {
      isLoadingTableData: false,
      errorMessage: ""
    };
  },
  async created() {
    await this.fetchProductVersionsWrapper();
  },
  computed: {
    ...mapGetters({
      getProductVersionsList:
        getterName.PLATFORM_SETTINGS.GET_PRODUCT_VERSIONS_LIST
    }),
    tableHeaders() {
      return this.getProductVersionsList.length
        ? Object.keys(productVersionTableHeaders).reduce((acc, key) => {
            if (productVersionTableHeaders[key]) {
              acc.push({
                value: key,
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: productVersionTableHeaders[key],
                  size: typographySize.BODY_TEXT_BOLD
                }
              });
            }
            return acc;
          }, [])
        : [];
    },
    tableRows() {
      return this.getProductVersionsList.length
        ? this.getProductVersionsList.map((version) =>
            Object.keys(version).reduce((acc, versionKey) => {
              acc[versionKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text:
                    serviceVersionMapper[version[versionKey]] ||
                    version[versionKey]
                }
              };
              return acc;
            }, {})
          )
        : [];
    }
  },
  beforeDestroy() {
    this.setProductVersionsList({});
  },
  methods: {
    ...mapActions({
      fetchProductVersions: actionName.PLATFORM_SETTINGS.FETCH_PRODUCT_VERSIONS
    }),
    ...mapMutations({
      setProductVersionsList:
        mutationName.PLATFORM_SETTINGS.SET_PRODUCT_VERSIONS_LIST
    }),
    async fetchProductVersionsWrapper() {
      try {
        this.isLoadingTableData = true;
        this.errorMessage = "";
        await this.fetchProductVersions();
      } catch (error) {
        const { response = {} } = error || {};
        const description = response.status
          ? ` There was an error with status ${response.status}.`
          : "";

        this.errorMessage = `Failed to retrieve the available versions.${description}`;
      } finally {
        this.isLoadingTableData = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.version-table {
  margin-top: 50px;
}
</style>
