var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('AppLoader',{staticClass:"text-left"}):_c('b-container',{staticClass:"m-0",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticStyle:{"overflow":"hidden"},attrs:{"cols":_vm.mainCols,"offset":"offset"}},[(_vm.isInvalid)?_c('b-alert',{attrs:{"variant":"warning","show":"","dismissible":""}},[_vm._v(" Dismissible Alert! Click the close button over there "),_c('b',[_vm._v("⇒")])]):_vm._e(),_c('div',[_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm.form.formTitle ? _vm.form.formTitle[_vm.selectedLanguage || 'english'] : 'No Title'))]),_c('p',[_vm._v(_vm._s(_vm.form.formDescription ? _vm.form.formDescription[_vm.selectedLanguage || 'english'] : 'No Description'))]),(!_vm.form.formSection.length && _vm.form.formType==1)?_c('p',[_vm._v("Please add at least one section to preview the form")]):_vm._e()])],1),_c('div',{staticClass:"preview-scrollable-wrapper",attrs:{"id":"scrollspy"}},[(_vm.form.formSection.length)?_c('div',{attrs:{"id":"element"}},[_c('b-form',_vm._l((_vm.form.formSection),function(sectionData,index){return _c('SectionContainer',{key:index,attrs:{"id":'section_'+index,"isPreview":true,"selectedLanguage":_vm.selectedLanguage,"sectionData":sectionData,"inputType":sectionData.inputType,"formType":parseInt(_vm.form.formType)}})}),1)],1):_vm._e()]),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"6"}},[(_vm.form.formSection.length)?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      											el: '#scrollspy',
     											container: '#scrollspy',
     											duration: 500,
     											easing: 'linear',
												force: true, 
												x: false,
												y: true}),expression:"{\n      \t\t\t\t\t\t\t\t\t\t\tel: '#scrollspy',\n     \t\t\t\t\t\t\t\t\t\t\tcontainer: '#scrollspy',\n     \t\t\t\t\t\t\t\t\t\t\tduration: 500,\n     \t\t\t\t\t\t\t\t\t\t\teasing: 'linear',\n\t\t\t\t\t\t\t\t\t\t\t\tforce: true, \n\t\t\t\t\t\t\t\t\t\t\t\tx: false,\n\t\t\t\t\t\t\t\t\t\t\t\ty: true}"}],staticClass:"btn btn-warning",attrs:{"href":"#"}},[_vm._v("Top")]):_vm._e()]),_c('b-col',[(_vm.form.formSection.length)?_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-success","isPreview":true},on:{"click":_vm.onSubmit}},[_vm._v("Submit")]):_vm._e()],1)],1)],1)],1),(_vm.formType==1)?_c('b-col',{staticClass:"mt-2",staticStyle:{"position":"relative","overflow-y":"auto","height":"500px"},attrs:{"cols":"3"}},[_c('b-list-group',{directives:[{name:"b-scrollspy",rawName:"v-b-scrollspy:scrollspy",value:(50),expression:"50",arg:"scrollspy"}]},_vm._l((_vm.form.formSection),function(section,index){return _c('b-list-group-item',{key:section.id,staticClass:"text-left",attrs:{"href":'#section_'+index}},[_c('font-awesome-icon',{attrs:{"icon":"check-circle"}}),_vm._v("  "+_vm._s(section.sectionTitle[_vm.selectedLanguage])+" ")],1)}),1)],1):_vm._e()],1)],1),_c('b-modal',{attrs:{"id":"modal-submit","title":"Submited Data"}},[_c('pre',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.userForm))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }