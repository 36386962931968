<template>
  <section>
    <BaseLoader v-if="isLoadingTableData" />
    <template v-else>
      <a v-if="errorMessage" target="_blank" :href="platformRequestSupportLink">
        {{ errorMessage }}
      </a>
      <router-view
        v-else
        :automations="automations"
        :automation="getAutomation"
        :automationConditions="automationConditions"
        @fetchAutomations="fetchAutomations"
        @editAutomation="onEditAutomation"
      />
    </template>
  </section>
</template>

<script>
import { automationsService } from "@/services";
import { mapState } from "vuex";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { cloneDeep } from "lodash";

export default {
  name: "AutomationsTemplate",
  data() {
    return {
      isLoadingTableData: false,
      errorMessage: "",
      automationId: "",
      automations: [],
      automationConditions: []
    };
  },
  components: {
    BaseLoader
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink
    }),
    getAutomation() {
      const automation = this.automations.find(
        ({ id }) => id === this.automationId
      );
      return cloneDeep(automation);
    }
  },
  methods: {
    automationFailure() {
      this.errorMessage = "Please contact support.";
    },
    onEditAutomation(id) {
      this.automationId = id;
    },
    async fetchAutomations() {
      try {
        this.isLoadingTableData = true;
        const { data = [] } = await automationsService.fetchAutomations(
          this.companyId
        );
        this.automations = data;
      } catch (error) {
        this.automationFailure();
      } finally {
        this.isLoadingTableData = false;
      }
    },
    async fetchAutomationConditions() {
      try {
        this.isLoadingTableData = true;
        const { data = [] } =
          await automationsService.fetchAutomationConditions(this.companyId);
        this.automationConditions = data;
      } catch (error) {
        this.automationFailure();
      } finally {
        this.isLoadingTableData = false;
      }
    }
  },
  async created() {
    this.automationId = this.$route?.params?.id;

    await this.fetchAutomations();
    await this.fetchAutomationConditions();
  }
};
</script>
