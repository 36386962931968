<template>
  <footer
    data-test-id="base-footer"
    :class="['base-footer', isFixed && 'base-footer--fixed']"
  >
    <div class="base-footer__left">
      <template v-if="showLeftSlot">
        <slot name="left"></slot>
      </template>
    </div>
    <div v-if="showRightSlot" class="base-footer__right">
      <slot name="right"></slot>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BaseFooter",
  props: {
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showLeftSlot() {
      return !!this.$slots.left;
    },
    showRightSlot() {
      return !!this.$slots.right;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  border-top: 1px solid $chinese-silver;
  background: $white;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0 -2px 5px 0 rgba($black, 0.25);
  }
}
</style>
