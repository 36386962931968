import { state as makeState } from "./thirdParty.state.store";

export const mutations = {
  setTCertificationOrderTypes(state, payload) {
    state.tCertificationOrderTypes = payload;
  },
  setESGMetricsOrderTypes(state, payload) {
    state.ESGMetricsOrderTypes = payload;
  },
  setFormsLanguagesList(state, payload) {
    state.formsLanguagesList = payload;
  },
  resetThirdParty(state) {
    Object.assign(state, makeState());
  },
  setIsThirdPartyAddModalVisible(state, value) {
    state.isThirdPartyAddModalVisible = value;
  },
  setIsThirdPartyDnbModalVisible(state, value) {
    state.isAddThirdPartyDnbModalVisible = value;
  },
  setIsThirdPartyNewModalVisible(state, value) {
    state.isAddThirdPartyModalNewVisible = value;
  },
  setThirdPartyModalData(state, data) {
    state.thirdPartyModalData = data;
  },
  resetThirdPartyModalData(state) {
    state.thirdPartyModalData = null;
  },
  setOCStatesList(state, payload) {
    state.OCStatesList = payload;
  },
  setHeaderConfigData(state, payload) {
    state.headerConfigData = payload;
  },
  setEddClientAllPackages(state, payload) {
    state.EDDClientAllPackages = payload;
  },
  setCustomAttributes(state, payload) {
    state.customAttributes = payload;
  },
  setIsUBODataLoading(state, payload) {
    state.isUBODataLoading = payload;
  },
  setThirdPartyUBOList(state, payload) {
    state.thirdPartyUBOList = payload;
  },
  setThirdPartyUBOError(state, status) {
    state.thirdPartyUBOError =
      status === 404
        ? "No results found"
        : "Something went wrong, please try again. If the issue persists please contact support.";
  },
  setCreateThirdPartyError(state, payload) {
    state.createThirdPartyError = payload;
  },
  setIsCreatingThirdParty(state, payload) {
    state.isCreatingThirdParty = payload;
  },
  setIsCreatingThirdPartySuccessful(state, payload) {
    state.isCreatingThirdPartySuccessful = payload;
  }
};
