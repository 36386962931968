import { actions } from "./auth.actions.store";
import { state } from "./auth.state.store";
import { mutations } from "./auth.mutations.store";
import { getters } from "./auth.getters.store";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
};
