<template>
  <div>
    <h5 class="font-size-text">Third Party Risk</h5>
    <highcharts :options="supplierRiskChartOptions" />
  </div>
</template>

<script>
export default {
  name: "SupplierRiskComponent",
  props: {
    supplierRiskChartOptions: Object
  }
};
</script>
