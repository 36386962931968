<template>
  <b-tabs
      v-model="tabIndex"
      nav-class="custom-tab-css"
      vertical
      small
  >
    <b-tab
      :lazy="true"
      :title="esgTexts.ESG_EXPLORE_SUMMARY"
    >
      <ThirdPartyESGTable
        :company-id="companyId"
        :third-party-id="thirdPartyId"
      />
    </b-tab>
    <b-tab
      :lazy="true"
      title="Risk alerts"
    >
      <RiskAlertTable
        :company-id="companyId"
        :third-party-id="thirdPartyId"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import ThirdPartyESGTable from "@/organisms/ThirdPartyESGTable/ThirdPartyESGTable";
import RiskAlertTable from "@/organisms/RiskAlertTable/RiskAlertTable";
import { esgTexts } from "@/constants";

export default {
  name: "ThirdPartyESGTemplate",
  components: {
    RiskAlertTable,
    ThirdPartyESGTable
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    thirdPartyId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabIndex: 0,
      esgTexts
    };
  }
}
</script>