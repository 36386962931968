var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticStyle:{"max-height":"633px"}},[_c('div',{staticClass:"text-center"},[_c('h4',{staticStyle:{"font-size":"17px"}},[_vm._v("Avg. Third Party Risk Score")])]),_c('b-row',[_c('b-col',{staticStyle:{"align-self":"center"},attrs:{"cols":"6"}},[(_vm.avgRiskScorePercent.length)?_c('div',_vm._l((_vm.avgRiskScorePercent),function(item,index){return _c('span',{key:index},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"center","font-size":"47px"}},[_c('div',[_c('h1',{style:(item.avg == 0
                      ? 'color:#4D9DE0;font-size:56px;'
                      : item.status == 1
                      ? 'color : #e71d36; font-size:56px;'
                      : 'color : #3ddc97; font-size:56px;')},[_vm._v(" "+_vm._s(item.avg)+"% ")])]),(item.avg != 0)?_c('div',[(item && item.status == 1)?_c('span',[_c('font-awesome-icon',{staticStyle:{"font-size":"50px","color":"#e71d36"},attrs:{"icon":"arrow-up"}})],1):(item && item.status == 0)?_c('span',[_c('font-awesome-icon',{staticStyle:{"font-size":"50px","color":"#3ddc97"},attrs:{"icon":"arrow-down"}})],1):_vm._e()]):_vm._e()]),_c('p',{staticClass:"text-center"},[_vm._v(" Avg. Third Party Risk Score "),_c('span',[_c('b',[_c('span',{style:(item.avg == 0
                        ? 'color:#4D9DE0'
                        : item.status == 1
                        ? 'color:#e71d36'
                        : 'color:#3ddc97')},[_vm._v(" "+_vm._s(item.avg == 0 ? "DID NOT CHANGE" : item.status == 1 ? "INCREASED" : "DECREASED"))])])]),_vm._v(" BETWEEN PERIOD 1 AND 2 ")])])}),0):_vm._e()]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-overlay',{staticClass:"my-4",attrs:{"show":_vm.riskScoreChartLoading,"rounded":"sm"}},[_c('div',_vm._l((_vm.riskOpt),function(item,index){return _c('div',{key:index},[_c('highcharts',{attrs:{"options":_vm.riskOpt[index]}})],1)}),0)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }