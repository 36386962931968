<template>
  <div data-test-id="ultimate-beneficial-owners">
    <BaseText text="UBO LISTS" />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "UltimateBeneficialOwnersTemplate",
  components: {
    BaseText
  }
};
</script>
