import {
  customAttributesChartKeysAPIDTO,
  customAttributesChartKeysDTO
} from "@/constants";

const makeCustomAttributeChartDataDTO = (data = []) =>
  data?.map((item) => ({
    [customAttributesChartKeysDTO.VALUE]:
      item?.[customAttributesChartKeysAPIDTO.VALUE],
    [customAttributesChartKeysDTO.COUNT]:
      item?.[customAttributesChartKeysAPIDTO.COUNT],
    [customAttributesChartKeysDTO.LABEL]:
      item?.[customAttributesChartKeysAPIDTO.LABEL]
  })) || [];

export { makeCustomAttributeChartDataDTO };
