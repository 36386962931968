import { colors } from "@/constants";

const setGlobalCssVars = (
  platformPrimaryColor = colors.robinEggBlue,
  platformSecondaryColor = colors.white
) => {
  platformPrimaryColor &&
    document.documentElement.style.setProperty(
      "--primary-color",
      platformPrimaryColor
    );
  platformSecondaryColor &&
    document.documentElement.style.setProperty(
      "--navigation-bar-text-color",
      platformSecondaryColor
    );
};

const setAppDataInDom = (platformfavicon, platformRegisteredBusinessName) => {
  const favicon = document.getElementById("favicon");
  const iconSrc = platformfavicon || "favicon-default.ico";
  favicon.setAttribute("href", iconSrc);
  document.title = platformRegisteredBusinessName || "Ethixbase";
};

export { setAppDataInDom, setGlobalCssVars };
