const makeMultiSelectCountriesCustomAttributeDTO = (selectedCountries = []) =>
  Array.isArray(selectedCountries)
    ? selectedCountries?.map(({ id, name }) => ({
        id,
        label: name
      }))
    : [];

const makeMultiSelectCountriesCustomAttributeAPIDTO = (
  selectedCountries = []
) =>
  Array.isArray(selectedCountries)
    ? selectedCountries?.map(({ id, name }) => ({
        id,
        value: name
      }))
    : [];

export {
  makeMultiSelectCountriesCustomAttributeDTO,
  makeMultiSelectCountriesCustomAttributeAPIDTO
};
