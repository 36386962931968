const whiteSpace = {
  NO_WRAP: "no-wrap",
  NORMAL: "normal"
};

const textAlign = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right"
};

export { whiteSpace, textAlign };
