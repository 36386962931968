import {
  actionKeys,
  actionKeysApi,
  actionStepType,
  brackets,
  calculationStepType,
  numericBinaryOperator,
  validity,
  variableAssignmentType,
  variableType,
  endOperations
} from "@/constants";
import { cloneDeep } from "lodash";
import { isBinaryOperator, isPredefinedLiteralOption } from "@/utils";

const isNullValue = (value) => value?.data?.data?.value === null;

const makeValueDTO = (value = "", valueDataType = "", variableType = {}) => {
  if (valueDataType === variableType.BOOLEAN) {
    return `${value}`;
  } else {
    return value;
  }
};

const makeFlatSetStepDTO = (setSuccessStep = {}) => ({
  [actionKeys.STEP_TYPE]: actionStepType.SET,
  [actionKeys.COMMENT]: setSuccessStep?.[actionKeysApi.COMMENT] || "",
  [actionKeys.VALUE_DATA_TYPE]: setSuccessStep?.valueDataType,
  [actionKeys.VALUE_TYPE]: setSuccessStep?.getSelectedValueTypeOption
    ? setSuccessStep?.getSelectedValueTypeOption()?.value
    : undefined,
  [actionKeys.VALUE]: setSuccessStep?.componentValue,
  [actionKeys.VARIABLE_NAME]: setSuccessStep?.variableNameSearchValue,
  [actionKeys.VARIABLE_SCOPE]: setSuccessStep?.variableScope,
  [actionKeys.VARIABLE_TYPE]: setSuccessStep?.variableType,
  [actionKeys.OPERATIONS]: setSuccessStep?.endOperations
});

const makeBaseOperationForBaseStepDTO = (operation = {}) => ({
  [actionKeys.OPERATION]: operation?.[actionKeysApi.OPERATION],
  [actionKeys.INPUT_TYPE]: {
    [actionKeys.TYPE]:
      operation?.[actionKeysApi.INPUT_TYPE]?.[actionKeysApi.TYPE],
    [actionKeys.IS_ARRAY]:
      operation?.[actionKeysApi.INPUT_TYPE]?.[actionKeysApi.IS_ARRAY]
  },
  [actionKeys.OUTPUT_TYPE]: {
    [actionKeys.TYPE]:
      operation?.[actionKeysApi.INPUT_TYPE]?.[actionKeysApi.TYPE],
    [actionKeys.IS_ARRAY]:
      operation?.[actionKeysApi.INPUT_TYPE]?.[actionKeysApi.IS_ARRAY]
  }
});

const makeRoundOperationForBaseStepDTO = ({
  operation,
  _makeBaseOperationForBaseStepDTO = makeBaseOperationForBaseStepDTO
}) => ({
  ..._makeBaseOperationForBaseStepDTO(operation),
  [actionKeys.SETTINGS]: {
    [actionKeys.DECIMALS]:
      operation?.[actionKeysApi.SETTINGS]?.[actionKeys.DECIMALS]
  }
});

const makeOperationForBaseStepDTO = ({
  operation = {},
  _makeRoundOperationForBaseStepDTO = makeRoundOperationForBaseStepDTO,
  _makeBaseOperationForBaseStepDTO = makeBaseOperationForBaseStepDTO
}) => {
  if (operation[actionKeys.OPERATION] === endOperations.ROUND) {
    return _makeRoundOperationForBaseStepDTO({ operation });
  } else {
    return _makeBaseOperationForBaseStepDTO(operation);
  }
};

const makeBaseStepDTO = ({
  step_type,
  variable,
  value,
  comment,
  operations,
  _makeOperationForBaseStepDTO = makeOperationForBaseStepDTO
} = {}) => ({
  [actionKeys.STEP_TYPE]: step_type,
  [actionKeys.COMMENT]: comment || "",
  [actionKeys.VARIABLE_SCOPE]: variable?.type,
  [actionKeys.VARIABLE_TYPE]: variable?.data?.type,
  [actionKeys.VARIABLE_NAME]: variable?.data?.data?.name,
  [actionKeys.DISPLAY_VALUE_TYPE]: variable?.data?.type,
  [actionKeys.VALUE_TYPE]: value?.type,
  [actionKeys.VALUE_DATA_TYPE]:
    variable?.data?.data?.sub_type || variable?.data?.type,
  [actionKeys.OPERATIONS]:
    operations?.map((operation) =>
      _makeOperationForBaseStepDTO({ operation })
    ) || []
});

const makeTypeLiteralStepDTO = ({
  functionStep,
  _makeBaseStepDTO = makeBaseStepDTO,
  _makeValueDTO = makeValueDTO,
  _isPredefinedLiteralOption = isPredefinedLiteralOption
}) => {
  const baseStep = _makeBaseStepDTO(functionStep);
  const setStep = {
    ...baseStep,
    [actionKeys.VALUE]: _makeValueDTO(
      functionStep?.value?.data?.data?.value,
      baseStep[actionKeys.VALUE_DATA_TYPE],
      variableType
    )
  };
  return {
    ...setStep,
    [actionKeys.VALUE_TYPE]: _isPredefinedLiteralOption(setStep)
      ? variableAssignmentType.ENTITY
      : variableAssignmentType.LITERAL
  };
};

const makeTypeVariableStepDTO = ({
  functionStep = {},
  _makeBaseStepDTO = makeBaseStepDTO
}) => {
  let valueDataType;
  let displayValueType;

  if (functionStep?.variable?.data?.type === variableType.DATE_TIME) {
    valueDataType = variableType.DATE_TIME;
    displayValueType = variableType.DATE_TIME;
  } else {
    valueDataType =
      functionStep?.variable?.data?.data?.sub_type ||
      functionStep?.variable?.data?.type;
    displayValueType = functionStep?.variable?.data?.type;
  }

  return {
    ..._makeBaseStepDTO(functionStep),
    [actionKeys.VALUE_TYPE]:
      functionStep?.value?.data?.type === variableAssignmentType.LITERAL
        ? variableAssignmentType.LITERAL
        : variableAssignmentType.ENTITY,
    [actionKeys.DISPLAY_VALUE_TYPE]: displayValueType,
    [actionKeys.VALUE_DATA_TYPE]: valueDataType,
    [actionKeys.VALUE]: functionStep?.value?.data?.data?.data?.name
  };
};

const makeSubBracketDTO = ({ bracket = [], operator = "" } = {}) => {
  const bracketCopy = cloneDeep(bracket);
  if (bracketCopy.length % 2 === 1) {
    bracketCopy.unshift(numericBinaryOperator.PLUS);
  }
  return {
    bracket: bracketCopy.reduce((acc, _, index) => {
      if (index % 2 === 0) {
        acc.push({
          operator: bracketCopy[index],
          value: bracketCopy[index + 1]
        });
      }
      return acc;
    }, []),
    operator
  };
};

const makeCalculationStepDTO = (
  value = "",
  operator = numericBinaryOperator.PLUS
) => ({
  value,
  operator
});

const makeBracketDTO = (
  bracket = [makeCalculationStepDTO()],
  operator = numericBinaryOperator.PLUS
) => ({
  bracket,
  operator
});

const makeFlatCalculationListDTO = (calculationList) =>
  Array.isArray(calculationList)
    ? calculationList.map(({ type, data }) => {
        if (type === calculationStepType.LOGIC) {
          return data?.value;
        } else if (type === calculationStepType.VARIABLE) {
          return data?.data?.data?.name;
        } else if (type === calculationStepType.LITERAL) {
          return data?.data?.value;
        }
      })
    : [];

const extractBracketsFromListDTO = ({
  calculationList = [],
  _makeBracketDTO = makeBracketDTO
}) => {
  let calculationListCopy = cloneDeep(calculationList);
  const openBracketIndex = calculationListCopy.indexOf(
    brackets.OPEN_PARENTHESES
  );
  const closeBracketIndex = calculationListCopy.indexOf(
    brackets.CLOSE_PARENTHESES
  );
  const isBracketAvailable =
    openBracketIndex !== -1 && closeBracketIndex !== -1;
  if (isBracketAvailable) {
    const bracket = calculationListCopy.slice(
      openBracketIndex + 1,
      closeBracketIndex
    );
    const operator =
      calculationListCopy[openBracketIndex - 1] || numericBinaryOperator.PLUS;

    calculationListCopy.splice(
      openBracketIndex,
      bracket.length + 2,
      _makeBracketDTO(bracket, operator)
    );

    return extractBracketsFromListDTO({ calculationList: calculationListCopy });
  } else {
    return calculationListCopy;
  }
};

const makeCalculationListDTO = ({
  calculationList = [],
  _makeFlatCalculationListDTO = makeFlatCalculationListDTO,
  _extractBracketsFromListDTO = extractBracketsFromListDTO,
  _isBinaryOperator = isBinaryOperator,
  _makeBracketDTO = makeBracketDTO,
  _makeSubBracketDTO = makeSubBracketDTO
}) => {
  const flatCalculationList = _makeFlatCalculationListDTO(calculationList);
  const listWithBrackets = _extractBracketsFromListDTO({
    calculationList: flatCalculationList
  });

  let result = listWithBrackets.reduce((acc, item, index) => {
    if (item?.bracket) {
      acc.push(item);
    } else if (!_isBinaryOperator(item)) {
      const operator =
        listWithBrackets[index - 1] || numericBinaryOperator.PLUS;
      acc.push(_makeBracketDTO([item], operator));
    }
    return acc;
  }, []);
  result = result.map(_makeSubBracketDTO);

  return result;
};

const makeExpressionStepDTO = ({
  functionStep = {},
  _makeBaseStepDTO = makeBaseStepDTO,
  _makeCalculationListDTO = makeCalculationListDTO
}) => ({
  ..._makeBaseStepDTO(functionStep),
  [actionKeys.DISPLAY_VALUE_TYPE]: variableType.EXPRESSION,
  [actionKeys.VALUE_DATA_TYPE]: variableType.EXPRESSION,
  [actionKeys.VALUE_TYPE]: variableAssignmentType.LITERAL,
  [actionKeys.VALUE]: _makeCalculationListDTO({
    calculationList: functionStep?.value?.data?.blocks
  })
});

const makeNoValueStepDTO = ({
  functionStep = {},
  _makeBaseStepDTO = makeBaseStepDTO
}) => ({
  ..._makeBaseStepDTO(functionStep),
  [actionKeys.VALUE_TYPE]: variableAssignmentType.NO_VALUE
});

const makeProxyStepDTO = ({
  functionStep = {},
  _makeBaseStepDTO = makeBaseStepDTO
}) => ({
  ..._makeBaseStepDTO(functionStep),
  [actionKeys.VALUE]: functionStep?.value?.data?.data?.name,
  [actionKeys.VARIABLE_TYPE]: variableType.PROXY,
  [actionKeys.VALUE_TYPE]: variableAssignmentType.ENTITY
});

const makeSetStepDTO = ({
  functionStep,
  _makeProxyStepDTO = makeProxyStepDTO,
  _makeNoValueStepDTO = makeNoValueStepDTO,
  _makeExpressionStepDTO = makeExpressionStepDTO,
  _makeTypeVariableStepDTO = makeTypeVariableStepDTO,
  _makeTypeLiteralStepDTO = makeTypeLiteralStepDTO,
  _isNullValue = isNullValue
}) => {
  if (functionStep?.value?.data?.type === variableType.PROXY) {
    return _makeProxyStepDTO({ functionStep });
  } else if (_isNullValue(functionStep?.value)) {
    return _makeNoValueStepDTO({ functionStep });
  } else if (functionStep?.value?.type === variableType.EXPRESSION) {
    return _makeExpressionStepDTO({ functionStep });
  } else if (functionStep?.value?.type === variableType.VARIABLE) {
    return _makeTypeVariableStepDTO({ functionStep });
  } else if (functionStep?.value?.type === variableAssignmentType.LITERAL) {
    return _makeTypeLiteralStepDTO({ functionStep });
  }
};

const makeBracketAPIDTO = (bracket, entities, functionSteps) =>
  bracket.reduce((acc, { operator, value }, index) => {
    const entity = entities.find(({ data }) => data.data.name === value);

    const localVariable = entity
      ? null
      : functionSteps.find(({ variableName }) => variableName === value);

    if (index > 0) {
      acc.push({
        type: calculationStepType.LOGIC,
        data: {
          value: operator
        }
      });
    }

    if (entity) {
      acc.push({
        type: calculationStepType.VARIABLE,
        data: {
          type: entity.type,
          data: {
            type: entity.data.type,
            data: {
              ...(!!entity && entity.data.data)
            }
          }
        }
      });
    } else if (localVariable) {
      acc.push({
        type: calculationStepType.VARIABLE,
        data: {
          type: localVariable[actionKeys.VARIABLE_SCOPE],
          data: {
            type: localVariable[actionKeys.VARIABLE_TYPE],
            data: {
              name: localVariable[actionKeys.VARIABLE_NAME]
            }
          }
        }
      });
    } else {
      acc.push({
        type: calculationStepType.LITERAL,
        data: {
          type: "numeric",
          data: {
            value: Number(value)
          }
        }
      });
    }
    return acc;
  }, []);

const makeExpressionAPIDTO = ({
  blocks,
  entities,
  functionSteps,
  _makeBracketAPIDTO = makeBracketAPIDTO
}) =>
  blocks.reduce((acc, { operator, bracket }, index) => {
    if (index > 0) {
      acc.push({
        type: calculationStepType.LOGIC,
        data: {
          value: operator
        }
      });
    }
    const bracketAPIDTO = _makeBracketAPIDTO(bracket, entities, functionSteps);

    if (bracket.length > 1) {
      bracketAPIDTO.unshift({
        type: calculationStepType.LOGIC,
        data: {
          value: brackets.OPEN_PARENTHESES
        }
      });
      bracketAPIDTO.push({
        type: calculationStepType.LOGIC,
        data: {
          value: brackets.CLOSE_PARENTHESES
        }
      });
    }
    acc.push(...bracketAPIDTO);
    return acc;
  }, []);

const isLiteralStepAPIDTO = ({
  valueType,
  valueDataType,
  value,
  _isPredefinedLiteralOption = isPredefinedLiteralOption
} = {}) =>
  valueType === variableAssignmentType.LITERAL ||
  _isPredefinedLiteralOption({ valueDataType, value });

const makeValueAPIDTO = ({
  value = "",
  valueDataType = "",
  variableType = {},
  validity = {},
  _isPredefinedLiteralOption = isPredefinedLiteralOption
}) => {
  if (valueDataType === variableType.NUMERIC) {
    return Number(value);
  } else if (valueDataType === variableType.DATE_TIME) {
    return _isPredefinedLiteralOption({ valueDataType, value })
      ? value
      : Number(value);
  } else if (valueDataType === variableType.BOOLEAN) {
    return `${value}` === validity.TRUE;
  } else {
    return value;
  }
};

const makeSetStepVariableAPIDTO = ({ functionStep = {}, entity } = {}) => ({
  [actionKeysApi.STEP_TYPE]: functionStep?.[actionKeys.STEP_TYPE],
  [actionKeysApi.COMMENT]: functionStep?.[actionKeys.COMMENT] || "",
  [actionKeysApi.VARIABLE]: {
    [actionKeysApi.TYPE]: functionStep?.[actionKeys.VARIABLE_SCOPE],
    [actionKeysApi.DATA]: {
      [actionKeysApi.TYPE]: functionStep?.[actionKeys.VARIABLE_TYPE],
      [actionKeysApi.DATA]: {
        [actionKeysApi.NAME]: functionStep?.[actionKeys.VARIABLE_NAME],
        ...(!!entity && entity.data.data)
      }
    }
  }
});

const makeSetStepValueForExpressionValueTypeAPIDTO = ({
  functionStep,
  entities,
  functionSteps,
  _makeExpressionAPIDTO = makeExpressionAPIDTO
} = {}) => ({
  [actionKeysApi.TYPE]: functionStep[actionKeys.VALUE_DATA_TYPE],
  [actionKeysApi.DATA]: {
    [actionKeysApi.BLOCKS]: _makeExpressionAPIDTO({
      blocks: functionStep[actionKeys.VALUE],
      entities,
      functionSteps
    })
  }
});

const makeSetStepValueForNoValueTypeAPIDTO = ({ functionStep = {} } = {}) => ({
  [actionKeysApi.TYPE]: variableAssignmentType.LITERAL,
  [actionKeysApi.DATA]: {
    [actionKeysApi.TYPE]: functionStep[actionKeys.VARIABLE_TYPE],
    [actionKeysApi.DATA]: {
      [actionKeysApi.VALUE]: null
    }
  }
});

const makeSetStepValueForLiteraValueTypeAPIDTO = ({
  functionStep,
  _makeValueAPIDTO = makeValueAPIDTO
} = {}) => ({
  [actionKeysApi.TYPE]: variableAssignmentType.LITERAL,
  [actionKeysApi.DATA]: {
    [actionKeysApi.TYPE]: functionStep[actionKeys.VARIABLE_TYPE],
    [actionKeysApi.DATA]: {
      [actionKeysApi.VALUE]: _makeValueAPIDTO({
        value: functionStep[actionKeys.VALUE],
        valueDataType: functionStep[actionKeys.VALUE_DATA_TYPE],
        variableType,
        validity
      })
    }
  }
});

const makeSetStepValueForLocalVariableAPIDTO = ({
  localVariable = {},
  entityVariable
} = {}) => ({
  [actionKeysApi.TYPE]: actionKeysApi.VARIABLE,
  [actionKeysApi.DATA]: {
    [actionKeysApi.TYPE]: localVariable?.[actionKeys.VARIABLE_SCOPE],
    [actionKeysApi.DATA]: {
      [actionKeysApi.TYPE]: localVariable?.[actionKeys.VARIABLE_TYPE],
      [actionKeysApi.DATA]: {
        [actionKeysApi.NAME]: localVariable?.[actionKeys.VARIABLE_NAME],
        ...(!!entityVariable && entityVariable.data.data)
      }
    }
  }
});

const makeSetStepValueForProxyAPIDTO = ({ entityVariable } = {}) => ({
  [actionKeysApi.TYPE]: actionKeysApi.VARIABLE,
  [actionKeysApi.DATA]: {
    [actionKeysApi.TYPE]: variableType.PROXY,
    [actionKeysApi.DATA]: {
      ...(!!entityVariable && entityVariable.data.data)
    }
  }
});

const makeSetStepBaseValueAPIDTO = ({ entityVariable } = {}) => ({
  [actionKeysApi.TYPE]: actionKeysApi.VARIABLE,
  [actionKeysApi.DATA]: {
    [actionKeysApi.TYPE]: entityVariable?.type,
    [actionKeysApi.DATA]: {
      [actionKeysApi.TYPE]: entityVariable?.data?.type,
      [actionKeysApi.DATA]: {
        ...entityVariable?.data?.data
      }
    }
  }
});

const makeSetStepValueAPIDTO = ({
  functionStep,
  entities,
  functionSteps,
  localVariable,
  entityVariable,
  isExpression,
  isNoValueType,
  isProxy,
  isLiteralStep,
  _makeSetStepValueForExpressionValueTypeAPIDTO = makeSetStepValueForExpressionValueTypeAPIDTO,
  _makeSetStepValueForNoValueTypeAPIDTO = makeSetStepValueForNoValueTypeAPIDTO,
  _makeSetStepValueForLiteraValueTypeAPIDTO = makeSetStepValueForLiteraValueTypeAPIDTO,
  _makeSetStepValueForLocalVariableAPIDTO = makeSetStepValueForLocalVariableAPIDTO,
  _makeSetStepValueForProxyAPIDTO = makeSetStepValueForProxyAPIDTO,
  _makeSetStepBaseValueAPIDTO = makeSetStepBaseValueAPIDTO
} = {}) => {
  if (isExpression) {
    return {
      [actionKeysApi.VALUE]: _makeSetStepValueForExpressionValueTypeAPIDTO({
        functionStep,
        entities,
        functionSteps
      })
    };
  } else if (isNoValueType) {
    return {
      [actionKeysApi.VALUE]: _makeSetStepValueForNoValueTypeAPIDTO({
        functionStep
      })
    };
  } else if (isLiteralStep) {
    return {
      [actionKeysApi.VALUE]: _makeSetStepValueForLiteraValueTypeAPIDTO({
        functionStep
      })
    };
  } else if (localVariable) {
    return {
      [actionKeysApi.VALUE]: _makeSetStepValueForLocalVariableAPIDTO({
        localVariable,
        entityVariable
      })
    };
  } else if (isProxy) {
    return {
      [actionKeysApi.VALUE]: _makeSetStepValueForProxyAPIDTO({
        entityVariable
      })
    };
  } else {
    return {
      [actionKeysApi.VALUE]: _makeSetStepBaseValueAPIDTO({ entityVariable })
    };
  }
};

const makeSetStepRoundOperationAPIDTO = ({ operation = {} }) => ({
  operation: endOperations.ROUND,
  input_type: {
    type: variableType.NUMERIC,
    is_array: false
  },
  output_type: {
    type: variableType.NUMERIC,
    is_array: false
  },
  settings: {
    decimals: operation.getSelectedDecimalValue()?.value || 0
  }
});

const makeSetStepOperationAPIDTO = ({
  operations = [],
  _makeSetStepRoundOperationAPIDTO = makeSetStepRoundOperationAPIDTO
} = {}) => ({
  operations: operations.map((operation) => {
    if (operation.endOperationType === endOperations.ROUND) {
      return _makeSetStepRoundOperationAPIDTO({ operation });
    } else {
      return null;
    }
  })
});

const makeSetStepAPIDTO = ({
  functionStep,
  entities,
  functionSteps,
  _makeSetStepVariableAPIDTO = makeSetStepVariableAPIDTO,
  _makeSetStepValueAPIDTO = makeSetStepValueAPIDTO,
  _makeSetStepOperationAPIDTO = makeSetStepOperationAPIDTO
}) => {
  const entity = entities.find(
    ({ data }) => data.data.name === functionStep[actionKeys.VARIABLE_NAME]
  );
  const isExpression =
    functionStep[actionKeys.VALUE_DATA_TYPE] === variableType.EXPRESSION;
  const isLiteralStep = isLiteralStepAPIDTO(functionStep);
  const localVariable = isLiteralStep
    ? undefined
    : functionSteps.find(
        ({ variableName }) => variableName === functionStep[actionKeys.VALUE]
      );
  const isNoValueType =
    functionStep[actionKeys.VALUE_TYPE] === variableAssignmentType.NO_VALUE;
  const isProxy =
    functionStep[actionKeys.VALUE_DATA_TYPE] === variableType.PROXY;
  const entityVariable = entities.find(
    ({ data }) => data.data.name === functionStep[actionKeys.VALUE]
  );

  return {
    ..._makeSetStepVariableAPIDTO({ functionStep, entity }),
    ..._makeSetStepValueAPIDTO({
      functionStep,
      entities,
      functionSteps,
      localVariable,
      entityVariable,
      isExpression,
      isNoValueType,
      isProxy,
      isLiteralStep
    }),
    ..._makeSetStepOperationAPIDTO({ operations: functionStep.operations })
  };
};

const makeParsedExpectedDataAPIDTO = ({ type, data }) => ({
  [actionKeysApi.TYPE]: type,
  [actionKeysApi.DATA]: {
    [actionKeysApi.TYPE]: data?.[actionKeys.TYPE],
    [actionKeysApi.DATA]:
      data?.[actionKeys.TYPE] === variableType.PROXY
        ? {
            [actionKeysApi.NAME]: data?.data?.[actionKeys.NAME],
            [actionKeysApi.IS_READ_ONLY]: data?.data?.[actionKeys.IS_READ_ONLY],
            [actionKeysApi.ATTRIBUTE_NAME]:
              data?.data?.[actionKeys.ATTRIBUTE_NAME],
            [actionKeysApi.CONTEXT]: {
              [actionKeysApi.FORM_ID]:
                data?.data?.[actionKeys.CONTEXT]?.[actionKeys.FORM_ID]
            },
            [actionKeysApi.ENTITY]: data?.data?.[actionKeys.ENTITY],
            [actionKeysApi.PROXY_NAME]: data?.data?.[actionKeys.PROXY_NAME],
            [actionKeysApi.REFERENCE_URL]:
              data?.data?.[actionKeys.REFERENCE_URL]
          }
        : {
            [actionKeysApi.NAME]: data?.data?.[actionKeys.NAME],
            [actionKeysApi.IS_ENUM]: data?.data?.[actionKeys.IS_ENUM],
            [actionKeysApi.IS_READ_ONLY]: data?.data?.[actionKeys.IS_READ_ONLY],
            [actionKeysApi.IS_CUSTOM_ATTRIBUTE]:
              data?.data?.[actionKeys.IS_CUSTOM_ATTRIBUTE],
            [actionKeysApi.IDENTIFIER]: data?.data?.[actionKeys.IDENTIFIER],
            [actionKeysApi.SUB_TYPE]: data?.data?.[actionKeys.SUB_TYPE],
            ...(data?.[actionKeys.DATA]?.hasOwnProperty([
              actionKeys.IS_ARRAY
            ]) && {
              [actionKeysApi.IS_ARRAY]:
                data[actionKeys.DATA][actionKeys.IS_ARRAY]
            })
          }
  }
});

export {
  isNullValue,
  makeValueDTO,
  makeFlatSetStepDTO,
  makeBaseStepDTO,
  makeTypeLiteralStepDTO,
  makeTypeVariableStepDTO,
  makeSubBracketDTO,
  makeCalculationStepDTO,
  makeBracketDTO,
  makeFlatCalculationListDTO,
  extractBracketsFromListDTO,
  makeCalculationListDTO,
  makeExpressionStepDTO,
  makeNoValueStepDTO,
  makeSetStepDTO,
  makeBracketAPIDTO,
  makeExpressionAPIDTO,
  makeValueAPIDTO,
  makeParsedExpectedDataAPIDTO,
  makeSetStepAPIDTO,
  makeProxyStepDTO,
  isLiteralStepAPIDTO,
  makeOperationForBaseStepDTO,
  makeBaseOperationForBaseStepDTO,
  makeRoundOperationForBaseStepDTO,
  makeSetStepOperationAPIDTO,
  makeSetStepRoundOperationAPIDTO,
  makeSetStepVariableAPIDTO,
  makeSetStepValueAPIDTO,
  makeSetStepValueForExpressionValueTypeAPIDTO,
  makeSetStepValueForNoValueTypeAPIDTO,
  makeSetStepValueForLiteraValueTypeAPIDTO,
  makeSetStepValueForLocalVariableAPIDTO,
  makeSetStepValueForProxyAPIDTO,
  makeSetStepBaseValueAPIDTO
};
