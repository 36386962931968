var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trigger-step"},[_c('Select',{staticClass:"trigger-step__action-to-trigger",attrs:{"data-test-id":"trigger-step__action-to-trigger","label":"Action to trigger","name":`action-name-${_vm.id}`,"id":`action-name-${_vm.id}`,"options":_vm.actionNameOptions,"error":_vm.actionNameErrorMessage,"placeholder":"Select..."},on:{"change":function($event){return _vm.$emit('change', {
        property: _vm.triggerStepKeys.ACTIONS,
        id: _vm.id,
        event: $event
      })}}}),(_vm.isExpectDataListVisible)?[_c('BaseText',{staticClass:"trigger-step__expected-data",attrs:{"data-test-id":"trigger-step__expected-data","text":"Expected data for action"}}),_c('ul',{staticClass:"trigger-step__expected-data-list",attrs:{"data-test-id":"trigger-step__expected-data-list"}},_vm._l((_vm.expectDataList),function({ name, options, type, searchValue, errorMessage },index){return _c('li',{key:`${name}-${index}`,staticClass:"trigger-step__expected-data-list-element",attrs:{"data-test-id":"trigger-step__expected-data-list-element"}},[_c('BaseText',{staticClass:"trigger-step__expected-data-list-element-text",attrs:{"data-test-id":"trigger-step__expected-data-list-element-text"}},[_c('span',{staticClass:"trigger-step__expected-data-list-element--bold"},[_vm._v(" "+_vm._s(name)+" ")]),_vm._v(" ("+_vm._s(_vm.getExpectedDataTypesText(type))+") ")]),_c('SearchSelect',{staticClass:"trigger-step__expected-data-list-element-input",attrs:{"label":name,"isLabelHidden":"","name":_vm.toSnakeWrapper(name, _vm.id),"id":_vm.toSnakeWrapper(name, _vm.id),"data-test-id":`trigger-step__expected-data-name--${name}`,"options":options,"searchValue":searchValue,"error":errorMessage,"placeholder":"Select..."},on:{"change":function($event){return _vm.$emit('change', {
              name,
              property: _vm.triggerStepKeys.EXPECTED_DATA_MAPPING,
              id: _vm.id,
              event: $event
            })},"reset":function($event){return _vm.$emit('reset', {
              name,
              property: _vm.triggerStepKeys.EXPECTED_DATA_MAPPING,
              id: _vm.id,
              event: $event
            })},"create":function($event){return _vm.$emit('create', {
              name,
              property: _vm.triggerStepKeys.EXPECTED_DATA_MAPPING,
              id: _vm.id,
              event: $event
            })}}})],1)}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }