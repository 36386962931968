import { makeAuthorizationHeader, HTTP } from "@/utils";
import { endpoints } from "@/constants";
import {
  makeUserEmailTemplatesDTO,
  makeUserEmailTemplateDTO,
  makeUserEmailTemplateAPIDTO
} from "./userEmailTemplates.dto";

const fetchUserEmailTemplates = async (companyId) => {
  const response = await HTTP(
    "get",
    endpoints.USER_EMAIL_TEMPLATES(companyId),
    null,
    makeAuthorizationHeader()
  );
  response.data = makeUserEmailTemplatesDTO({
    userEmailTemplates: response.data.data
  });
  return response;
};

const fetchUserEmailTemplate = async ({ companyId, templateId }) => {
  const response = await HTTP(
    "get",
    endpoints.USER_EMAIL_TEMPLATE(companyId, templateId),
    null,
    makeAuthorizationHeader()
  );
  response.data = makeUserEmailTemplateDTO({
    userEmailTemplate: response.data
  });
  return response;
};

const makeUserEmailTemplate = async ({ companyId, userEmailTemplate }) => {
  const response = await HTTP(
    "post",
    endpoints.USER_EMAIL_TEMPLATES(companyId),
    makeUserEmailTemplateAPIDTO({ userEmailTemplate }),
    makeAuthorizationHeader()
  );
  response.data = makeUserEmailTemplateDTO({
    userEmailTemplate: response.data
  });
  return response;
};

const updateUserEmailTemplate = async ({ companyId, userEmailTemplate }) => {
  const response = await HTTP(
    "patch",
    endpoints.USER_EMAIL_TEMPLATE(companyId, userEmailTemplate.id),
    makeUserEmailTemplateAPIDTO({ userEmailTemplate }),
    makeAuthorizationHeader()
  );
  response.data = makeUserEmailTemplateDTO({
    userEmailTemplate: response.data
  });
  return response;
};

const cloneUserEmailTemplate = async ({ companyId, templateId }) => {
  const response = await HTTP(
    "post",
    endpoints.CLONE_USER_EMAIL_TEMPLATE(companyId, templateId),
    null,
    makeAuthorizationHeader()
  );
  response.data = makeUserEmailTemplateDTO({
    userEmailTemplate: response.data
  });
  return response;
};

const deleteUserEmailTemplate = async ({ companyId, templateId }) => {
  const response = await HTTP(
    "delete",
    endpoints.USER_EMAIL_TEMPLATE(companyId, templateId),
    null,
    makeAuthorizationHeader()
  );
  response.data = makeUserEmailTemplateDTO({
    userEmailTemplate: response.data
  });
  return response;
};

export const userEmailTemplatesService = {
  fetchUserEmailTemplates,
  fetchUserEmailTemplate,
  makeUserEmailTemplate,
  updateUserEmailTemplate,
  cloneUserEmailTemplate,
  deleteUserEmailTemplate
};
