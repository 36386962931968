<template>
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <b-collapse
      :class="isPreview ? 'mt-1' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-form-group
        :label="
          ($store.state.form1.enableSectionFieldEnumeration == 1
            ? sectionIndex + 1 + '.' + (fieldIndex + 1)
            : '') +
          ' ' +
          label
        "
        :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
        :id="fieldData.id.toString()"
      >
        <b-form-checkbox
          class="my-2"
          value="1"
          unchecked-value="0"
          v-model="acceptTermsText"
          @change="isFocus = true"
        >
          <span
            v-if="fieldData.validation && fieldData.validation.required == 1"
            class="required-span"
            >*</span
          >
          {{ checkboxLabel }}
          <b-form-invalid-feedback
            :state="false"
            v-if="
              fieldData.validation &&
              fieldData.validation.required == 1 &&
              acceptTermsText != 1 &&
              isFocus
            "
            class="text-danger"
            >{{
              $t("createProfile.requiredValidationMessage")
            }}</b-form-invalid-feedback
          >
        </b-form-checkbox>
        <b-button
          v-if="buttonLabel != ''"
          block
          variant="primary"
          :href="downloadPdf()"
          target="_blank"
          >{{ buttonLabel }}</b-button
        >
      </b-form-group>
      <!-- advanced settings tab -->
      <!-- @ok="onSaveData" -->
      <!-- @hidden="onUpdateSettings" -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        size="xl"
        v-model="showSettings"
        title="Checkbox Settings"
      >
        <AdvancePDFSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import AdvancePDFSettings from "../advancePDFComponent.vue";
import MenuButtons from "../menuButtons";
import { routeKeys, routeNames } from "@/constants";

export default {
  name: "DownloadPDF",
  components: {
    MenuButtons,
    AdvancePDFSettings
  },
  data() {
    return {
      translationLanguage: "english",
      acceptTermsText: null,
      isFocus: false,
      label: "",
      buttonLabel: "",
      checkboxLabel: "",
      showSettings: false,
      collapse: true,
      postData: []
    };
  },
  watch: {
    selectedLanguage() {
      // this.translationLanguage = val
      this.setData();
    }
  },
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage"
    })
  },
  mounted() {
    this.tabClick(this.selectedLanguage);
    this.setData();
    // this.grtPdfForms();
  },
  created() {
    EventBus.$on("onSubmitEndUserForm", (value) => {
      if (value == "progress" || this.fieldData.validation.required == 0) {
        this.stTempData();
      } else {
        if (this.fieldData.validation.required == 1) {
          if (this.acceptTermsText == 1) {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
            this.stTempData();
          } else {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          }
        }
      }
    });

    EventBus.$on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },
  methods: {
    tabClick(key) {
      let tempKey = key.toLowerCase();
      this.translationLanguage = tempKey;
    },
    setData() {
      (this.acceptTermsText = this.fieldData.selected),
        (this.label = this.fieldData.label[this.selectedLanguage]),
        (this.buttonLabel = this.fieldData.buttonLabel[this.selectedLanguage]),
        (this.checkboxLabel =
          this.fieldData.checkboxLabel[this.selectedLanguage]);

      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elements && data.elements.length > 0) {
          data.elements.map(() => {
            if (data.elementId == this.fieldData.id) {
              this.acceptTermsText =
                data.elements[
                  data.elements.findIndex((x) => x.key == "tickbox")
                ].postValue;
            }
          });
        }
      });
    },

    stTempData() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.postData = [];
        let aa = [
          {
            postValue: this.acceptTermsText,
            postNameTerms: "tickbox",
            key: "tickbox",
            label: this.acceptTermsText
          },
          {
            postValue: this.checkboxLabel,
            postNameTerms: "tickboxLabel",
            key: "tickboxLabel",
            label: this.fieldData.checkboxLabel[this.selectedLanguage]
          }
        ];
        this.postData.push(aa);
        this.setDataToUser();
      }
    },

    setDataToUser() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId == this.fieldData.id) {
            a.elements = this.postData[0];
          }
        });
      }
    },

    downloadPdf() {
      if (this.fieldData.linkPDF && this.fieldData.selectedPdfForm) {
        let selectedForm = this.fieldData.selectedPdfForm.pdfFiles;
        if (selectedForm && selectedForm.file) {
          return selectedForm.file[this.selectedLanguage];
        }
      }
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
