<template>
    <div class="edd-results">
    <b-card style="border: none">
        <b-card-title>
            <div class="text-right">
            <b-dropdown
                size="sm"
                variant="outline-info"
                class="text-capitalize"
            >
                <template v-slot:button-content>
                <font-awesome-icon icon="cogs" />
                </template>
                <b-dropdown-item @click="onSelectEddResultChart('Pie')">{{$t('eb360.analyticsTabChart.selectChart.pie')}}</b-dropdown-item>
                <b-dropdown-item @click="onSelectEddResultChart('Pyramid')">{{$t('eb360.analyticsTabChart.selectChart.pyramid')}}</b-dropdown-item>
                <b-dropdown-item @click="onSelectEddResultChart('Funnel')">{{$t('eb360.analyticsTabChart.selectChart.funnel')}}</b-dropdown-item>
            </b-dropdown>
            </div>
        </b-card-title>
        <highcharts :options="eddResultCountChartOptions" />
    </b-card>
    </div>
</template>

<script>
export default {
  name: "EddResultsComponent",
  props:{
      eddResultCountChartOptions: Object
  },
  methods:{
    // change chart type functions
    onSelectEddResultChart ( type ) {
      this.eddResultCountChartOptions.chart.type = type.toLowerCase();
    },
  }
}
</script>

<style lang="scss" scoped>
.edd-results {
  border: $card-border;
}
</style>
