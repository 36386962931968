<template>
  <div>
    <b-overlay :show="isLoadingData" rounded="sm">
      <b-navbar toggleable="lg" type="dark" variant="light">
        <b-navbar-brand href="#" class="text-dark">
          <strong>{{ $t("eb360.documentsTabComponent.title") }}</strong>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-button
                class="mr-2"
                variant="outline-warning"
                size="sm"
                :disabled="isDisabled"
                @click="onShowAddModal"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
              <b-button
                class="mr-2"
                variant="outline-info"
                size="sm"
                @click="getTpManageDocuments"
              >
                <font-awesome-icon icon="sync" />
              </b-button>
              <b-form-group
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
              >
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    :placeholder="
                      $t('eb360.manageThirdParties.searchPlaceholder')
                    "
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-table
        responsive="sm"
        :sort-by.sync="sortBy"
        borderless
        hover
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :items="documentsData"
        :fields="fields"
      >
        <template v-slot:cell(title)="data">
          <p>
            {{ data.item.title || "" }}
          </p>
        </template>
        <template v-slot:cell(link)="data">
          <p
            class="text-primary"
            style="cursor: pointer"
            v-b-tooltip
            :title="renderTooltip(data.item)"
            @click="viewDocumentCall(data.item)"
          >
            <u>{{ renderFunction(data.item) }}</u>
          </p>
        </template>
        <template v-slot:cell(file_type)="data">
          <p>
            {{ renderFileTypeFunction(data.item) }}
          </p>
        </template>
        <template v-slot:cell(actions)="data">
          <b-dropdown
            size="sm"
            variant="outline"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <font-awesome-icon icon="ellipsis-v" />
            </template>
            <b-dropdown-item-button
              style="cursor: pointer"
              @click="viewDocumentCall(data.item)"
            >
              View Doc
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="data.item.type == 'Uploaded'"
              @click="onShowDeleteModal(data.item)"
            >
              Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row>
        <b-col cols="2">
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          />
        </b-col>
        <b-col>
          <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            aria-controls="my-table"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal
      v-model="showAddDocumentModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Add Document"
      scrollable
      size="lg"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-overlay :show="uploading">
            <b-row>
              <b-col cols="12">
                <label>
                  {{ $t("eb360.documentsTabComponent.uploadDoc") }} :
                </label>
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                  @vdropzone-sending="sendingEvent"
                  @vdropzone-file-added="onUploadPreview"
                  @vdropzone-success="vsuccess"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{ $t("eb360.documentsTabComponent.dragDrop") }}
                    </h3>
                    <div class="subtitle">
                      {{ $t("eb360.documentsTabComponent.selectFile") }}
                    </div>
                  </div>
                </vue-dropzone>
              </b-col>
              <b-col class="mt-3" cols="12">
                <b-form-group
                  :label="$t('eb360.documentsTabComponent.displayName')"
                >
                  <b-form-input
                    v-model="$v.form.displayName.$model"
                    :state="
                      $v.form.displayName.$dirty
                        ? !$v.form.displayName.$error
                        : null
                    "
                    :placeholder="$t('eb360.documentsTabComponent.displayName')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div>
              <b-form-group label="Filename :">
                <b-form-input
                  placeholder="Enter Filename"
                  v-model="form.fileName"
                />
              </b-form-group>
              <b-form-group label="Comments :">
                <b-form-textarea
                  v-model="form.comments"
                  placeholder="Add your comments here..."
                />
              </b-form-group>
            </div>
          </b-overlay>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <p>{{ $t("eb360.documentsTabComponent.uploadingDoc") }}</p>
          <b-spinner type="grow" :label="$t('loadingSpinnerText')"></b-spinner>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{ $t("eb360.documentsTabComponent.uploadSuccessfull") }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          variant="outline-danger"
          v-if="!submiting"
          :disabled="uploading"
          @click="resetModal"
        >
          Close
        </b-button>
        <b-button
          v-if="!submiting && !responseSuccess"
          variant="outline-success"
          size="md"
          :disabled="uploading"
          @click="checkValidation()"
        >
          {{ $t("uploadBtn") }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Delete"
      v-model="deleteModal"
      scrollable
      size="md"
    >
      Are you sure you want to delete this document?
      <template v-slot:modal-footer>
        <b-button
          variant="outline-danger"
          :disabled="submiting"
          @click="onCloseDeleteModal"
        >
          Close
        </b-button>
        <b-button
          size="md"
          variant="outline-success"
          :disabled="submiting"
          @click="onDeleteDoc"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { hasHttp, HTTP, makeAuthorizationHeader } from "@/utils";
import { config } from "@/configs";
import vue2Dropzone from "@/utils/VueDropzone.vue";
import { endpoints } from "@/constants";
import { mapState } from "vuex";

export default {
  name: "DocumentsTabComponent",
  mixins: [validationMixin],
  props: {
    companyId: Number,
    thirdPartyId: Number,
    isDisabled: Boolean
  },
  data() {
    return {
      form: this.makeFormObj(),
      filter: null,
      pageOptions: [5, 10, 25, 50, 100],
      sortBy: "title",
      perPage: 5,
      currentPage: 1,
      isLoadingData: false,
      documentsData: [],
      showAddDocumentModal: false,
      dropzoneOptions: {
        url: `${config.API_URL}${endpoints.THIRD_PARTY_UPLOAD_DOCUMENT}`,
        thumbnailWidth: 150,
        maxFilesize: 25,
        autoProcessQueue: false,
        addRemoveLinks: true,
        headers: makeAuthorizationHeader()
      },
      fields: [
        {
          key: "title",
          label: this.$t("eb360.documentsTabComponent.title"),
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "type",
          label: this.$t("eb360.documentsTabComponent.type"),
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "uploaded_at",
          label: this.$t("eb360.documentsTabComponent.date"),
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "link",
          label: this.$t("eb360.documentsTabComponent.link"),
          sortable: false,
          sortDirection: "desc"
        },
        {
          key: "file_type",
          label: "File Type",
          sortable: false,
          sortDirection: "desc"
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          sortDirection: "desc"
        }
      ],
      submiting: false,
      responseSuccess: false,
      uploading: false,
      deleteModal: false,
      documentId: null,
      docFile: null
    };
  },
  validations: {
    form: {
      displayName: { required }
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.documentsData.length;
    }
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  mounted() {
    this.getTpManageDocuments();
  },
  methods: {
    onShowDeleteModal({ id, file }) {
      this.deleteModal = true;
      this.documentId = id;
      this.docFile = file;
    },
    makePostObjForDeletingDoc() {
      return {
        documentId: this.documentId,
        file: this.docFile,
        companyId: this.companyId,
        thirdPartyId: this.thirdPartyId
      };
    },
    async onDeleteDoc() {
      try {
        this.submiting = true;
        let { data = {} } = await HTTP(
          "post",
          endpoints.THIRD_PARTY_DELETE_DOCUMENT,
          this.makePostObjForDeletingDoc(),
          makeAuthorizationHeader()
        );
        this.onDeleteDocSuccessfully(data);
      } catch (error) {
        return error;
      } finally {
        this.submiting = false;
      }
    },
    onDeleteDocSuccessfully(data) {
      this.$bvToast.toast(data.message, {
        noAutoHide: false,
        appendToast: true
      });
      this.onCloseDeleteModal();
      this.getTpManageDocuments();
    },
    onCloseDeleteModal() {
      this.deleteModal = false;
      this.documentId = null;
      this.docFile = null;
    },
    onUploadPreview(file) {
      if (file) {
        this.form.fileName = file.name;
        this.form.fileType = file.type;
      }
    },
    onShowAddModal() {
      this.showAddDocumentModal = true;
    },
    checkValidation() {
      this.$v.form.$touch();
      if (
        !this.$v.form.$anyError &&
        this.$refs.myVueDropzone.getQueuedFiles().length > 0
      ) {
        this.uploading = true;
        this.$refs.myVueDropzone.processQueue();
        this.getTpManageDocuments();
      }
    },
    makeFormObj() {
      return {
        fileName: null,
        displayName: null,
        fileType: null,
        fileUploader: null,
        uploadedDate: null,
        comments: null,
        status: null
      };
    },
    resetModal() {
      this.showAddDocumentModal = false;
      this.form = this.makeFormObj();
      this.submiting = false;
      this.uploading = false;
      this.responseSuccess = false;
      this.$v.form.$reset();
    },
    makePostObjForTpManagedDocuments() {
      return {
        thirdPartyId: this.thirdPartyId,
        companyId: this.companyId
      };
    },
    async getTpManageDocuments() {
      try {
        this.isLoadingData = true;
        let response = await HTTP(
          "post",
          endpoints.THIRD_PARTY_MANAGE_DOCUMENTS,
          this.makePostObjForTpManagedDocuments(),
          makeAuthorizationHeader()
        );
        this.getTpManageDocumentsSuccessfully(response);
      } catch (error) {
        return error;
      } finally {
        this.isLoadingData = false;
      }
    },
    getTpManageDocumentsSuccessfully({ data, status }) {
      const isValidResponse = data?.status == 1 && status == 200;
      this.documentsData = isValidResponse ? data.response : [];
      this.$store.state.documentsTabLazyLoad = false;
    },
    viewDocumentCall(data) {
      if (hasHttp(data.url)) {
        window.open(data.url);
      } else {
        const url =
          data.url[0] === "/" ? data.url : `${config.BASE_URL}${data.url}`;

        window.open(url);
      }
    },
    vsuccess() {
      this.uploading = false;
      this.submiting = false;
      this.responseSuccess = true;
      this.$bvToast.toast(
        this.$t("eb360.documentsTabComponent.uploadSuccessfull"),
        {
          title: this.$t("eb360.documentsTabComponent.completeDoc"),
          noAutoHide: false,
          appendToast: true
        }
      );
    },
    sendingEvent(_, __, formData) {
      let metaData = {
        fileName: this.form.fileName,
        fileType: this.form.fileType,
        comments: this.form.comments
      };
      formData.append("thirdPartyId", this.thirdPartyId);
      formData.append("companyId", this.companyId);
      formData.append("memberId", this.userId);
      formData.append("displayName", this.form.displayName);
      formData.append("meta_data", JSON.stringify(metaData));
    },
    renderFunction({ meta_data, title }) {
      let { fileName } = meta_data ? JSON.parse(meta_data) : {};
      return fileName || title || "";
    },
    renderFileTypeFunction({ meta_data }) {
      const { fileType } = meta_data ? JSON.parse(meta_data) : {};
      return fileType || "";
    },
    renderTooltip({ meta_data }) {
      const { comments } = meta_data ? JSON.parse(meta_data) : {};
      return comments || "";
    }
  }
};
</script>
