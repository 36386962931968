import { state as makeState } from "@/store/company/company.state.store";

export const mutations = {
  setCompanyUsers(state, payload) {
    state.companyUsers = payload;
  },
  setCompanyList(state, payload) {
    state.companyList = payload;
  },
  setTriggerFetchAdditionalVouchers(state, value) {
    state.triggerFetchAdditionalVouchers = value;
  },
  setCompanyViews(state, payload) {
    state.companyViews = payload;
  },
  resetCompany(state) {
    Object.assign(state, makeState());
  },
  setRemainingVouchers(state, payload) {
    state.remainingVouchers = payload;
  },
  setCompanyDetails(state, payload) {
    state.companyDetails = payload;
  },
  setCompanyId(state, payload) {
    state.companyId = parseInt(payload);
  },
  setCompanyName(state, payload) {
    state.companyName = payload;
  }
};
