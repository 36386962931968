import {
  actionKeys,
  actionStepType,
  expectedDataTypes,
  getterName,
  operations,
  triggerDataType,
  triggerStepKeys
} from "@/constants";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import triggerStepMixin from "@/organisms/TriggerStep/TriggerStep.mixin";
import { mapGetters } from "vuex";
import TriggerStep from "@/organisms/TriggerStep/TriggerStep";
import { getSelectedOption } from "@/utils";

export default {
  mixins: [triggerStepMixin],
  computed: {
    ...mapGetters({
      getSystemActions: getterName.ACTIONS.GET_SYSTEM_ACTIONS
    }),
    systemActionsOptions() {
      const systemActionsOptions = this.getSystemActions.map(
        ({ name, id }) => ({
          text: name,
          value: id
        })
      );

      return [...this.makeFirstOptionForSelect(), ...systemActionsOptions];
    }
  },
  methods: {
    makeNewTriggerStep(stepIndex = this.functionSteps.length - 1) {
      this.updateAllStepIndexes(operations.ADD, stepIndex);
      this.functionSteps.splice(
        stepIndex,
        0,
        this.makeTriggerStepAndExtendTriggerStepList(
          undefined,
          this.addBlockIndex
        )
      );
    },
    makeTriggerStepAndExtendTriggerStepList(
      { actionId = "", expectedDataMapping = [], comment = "" } = {},
      index
    ) {
      const expectedDataList = this.makeTriggerStepExpectedDataList(
        actionId,
        index,
        expectedDataMapping
      );
      const triggerStepInstance = this.makeTriggerStep({
        actionNameOptions: makeOptionsForSelect(
          actionId,
          this.systemActionsOptions
        ),
        expectDataList: expectedDataList,
        comment
      });
      this.triggerStepList.push({
        stepIndex: index,
        triggerStep: triggerStepInstance
      });

      return triggerStepInstance;
    },
    deleteTriggerStepIndexFromList(index) {
      this.triggerStepList = this.triggerStepList.filter(
        ({ stepIndex }) => stepIndex !== index
      );
    },
    deleteTriggerStepIdFromList(id) {
      this.triggerStepList = this.triggerStepList.filter(
        ({ triggerStep }) => triggerStep.id !== id
      );
    },
    makeSuccessTriggerStep(triggerStep, stepIndex) {
      return {
        stepType: actionStepType.TRIGGER,
        component: TriggerStep,
        componentOptions: this.makeTriggerStepAndExtendTriggerStepList(
          triggerStep,
          stepIndex
        )
      };
    },
    makeSuccessTriggerStepForIfBlock() {
      const { index: stepIndex, event } = this.successStepEvent;
      const successTriggerStep = this.makeSuccessTriggerStep(
        undefined,
        stepIndex
      );
      if (event.property === actionKeys.ELSE_BLOCK) {
        const elseBlocks = [
          ...(this.functionSteps[stepIndex]?.[event.property] || []),
          successTriggerStep
        ];
        this.$set(this.functionSteps[stepIndex], event.property, elseBlocks);
      } else {
        const successSteps = [
          ...(this.functionSteps[stepIndex]?.[event.property]?.[event.index]
            ?.successSteps || []),
          successTriggerStep
        ];
        this.$set(
          this.functionSteps[stepIndex][event.property][event.index],
          actionKeys.SUCCESS_STEPS,
          successSteps
        );
      }
    },
    updateTriggerStepsIndexes(editType, index) {
      for (
        let triggerStepsIndex = 0;
        triggerStepsIndex < this.triggerStepList.length;
        triggerStepsIndex++
      ) {
        if (this.triggerStepList[triggerStepsIndex].stepIndex >= index) {
          if (
            editType === operations.ADD &&
            this.triggerStepList[triggerStepsIndex].stepIndex >= index
          ) {
            this.triggerStepList[triggerStepsIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.triggerStepList[triggerStepsIndex].stepIndex > index
          ) {
            this.triggerStepList[triggerStepsIndex].stepIndex -= 1;
          }
        }
      }
    },
    onTriggerStepChange(event, index) {
      if (event.property === triggerStepKeys.EXPECTED_DATA_MAPPING) {
        this.changeTriggerExpectedData(event, index);
      } else if (event.property === triggerStepKeys.ACTIONS) {
        this.changeTriggerActionName(event, index);
      }
    },
    getAllOptionsForTriggerStep(index) {
      const optionsPerStep = this.getAllAvailableOptionsByStepIndex(index);
      const expectedDataOptions = this.expectedData.map(
        ({ expectedVariableName, expectedVariableType }) => ({
          text: expectedVariableName,
          value: expectedVariableName,
          type: expectedVariableType
        })
      );
      return [...optionsPerStep, ...expectedDataOptions];
    },
    getSearchValueInTriggerExpectedDataMapping(expectedDataMapping, name) {
      let searchValue = "";

      if (expectedDataMapping?.length) {
        const { triggeringData } = expectedDataMapping.find(
          ({ triggeredExpectedDataVariableName }) =>
            triggeredExpectedDataVariableName === name
        );

        if (triggeringData.type === triggerDataType.PROXY) {
          searchValue = triggeringData.data.name;
        } else if (triggeringData.type === triggerDataType.IDENTIFIER) {
          searchValue = JSON.stringify(triggeringData.data.value);
        } else if (triggeringData.type === triggerDataType.LITERAL) {
          searchValue = triggeringData.data.data.value;
        } else if (triggeringData.type === triggerDataType.VARIABLE) {
          searchValue = triggeringData.data.data.data.name;
        }
      }

      return searchValue;
    },
    makeTriggerStepExpectedDataOptions(options = [], index = 0, entity = "") {
      if (options.length) {
        return options.map(({ text, value }) => ({
          text,
          value: JSON.stringify(value)
        }));
      } else {
        return this.getAllOptionsForTriggerStep(index).filter(
          ({ type, subType }) => subType === entity || type === entity
        );
      }
    },
    makeTriggerStepExpectedDataList(
      actionId = "",
      index = 0,
      expectedDataMapping = []
    ) {
      return this.getSystemActions
        .find(({ id }) => id === actionId)
        ?.expectedDataDefinition?.variables?.map(
          ({ entity, name, options }) => {
            const tempOptions = this.makeTriggerStepExpectedDataOptions(
              options,
              index,
              entity
            );
            const value = this.getSearchValueInTriggerExpectedDataMapping(
              expectedDataMapping,
              name
            );
            const componentOptions = makeOptionsForSelect(value, tempOptions);
            return {
              name,
              searchValue: getSelectedOption(componentOptions)?.text || "",
              options: componentOptions,
              type: entity,
              errorMessage: ""
            };
          }
        );
    },
    changeTriggerExpectedData(event) {
      const { triggerStep } = this.triggerStepList.find(
        ({ triggerStep }) => triggerStep.id === event.id
      );
      const expectedDataObj = triggerStep.expectDataList.find(
        ({ name }) => name === event.name
      );
      expectedDataObj.options = makeOptionsForSelect(
        event.event,
        expectedDataObj.options
      );
      expectedDataObj.searchValue = getSelectedOption(
        expectedDataObj.options
      )?.text;
    },
    changeTriggerActionName(event, index, expectedDataMapping = []) {
      const { triggerStep } = this.triggerStepList.find(
        ({ triggerStep }) => triggerStep.id === event.id
      );
      const expectedDataList = this.makeTriggerStepExpectedDataList(
        event.event,
        index,
        expectedDataMapping
      );

      this.setTriggerStep({
        actionNameOptions: makeOptionsForSelect(
          event.event,
          this.systemActionsOptions
        ),
        expectDataList: expectedDataList,
        step: triggerStep
      });
    },
    amendSuccessTriggerStep(event, stepIndex) {
      this.onTriggerStepChange(event.event, stepIndex);
    },
    updateAllSuccessTriggerStepsOptions() {
      this.triggerStepList.forEach(({ stepIndex, triggerStep }) => {
        this.updateTriggerStepExpectedDataList(triggerStep, stepIndex);
      });
    },
    updateTriggerStepExpectedDataList(triggerStep = {}, index = 0) {
      const expectedDataList = triggerStep.expectDataList?.map(
        (expectedData) => {
          if (expectedData.type !== expectedDataTypes.DDQ_FORM) {
            const options = this.getAllOptionsForTriggerStep(index).filter(
              ({ subType, type }) =>
                subType === expectedData.type || type === expectedData.type
            );
            return {
              ...expectedData,
              options: makeOptionsForSelect(expectedData.searchValue, options)
            };
          } else {
            return expectedData;
          }
        }
      );
      this.setTriggerStep({
        expectDataList: expectedDataList,
        step: triggerStep
      });
    }
  }
};
