<template>
  <div
    v-observe-visibility="{
      callback: ddqStatApiCall,
      intersection: {
        threshold: 0.9
      }
    }"
  >
    <b-overlay :show="ddqStatChartLoading" rounded="sm">
      <div v-for="(values, valueIndex) in overallValues" :key="valueIndex">
        <div v-if="values['ddq_count_' + formId + '_' + formType] >= 0">
          <span class="text-center" style="font-weight: bold">{{
            formTitle
          }}</span>
          <b-card style="margin-right: 20px" class="text-center statsCard">
            <span>
              <b>{{ formLabel }}</b>
            </span>
            <b-card-text
              class="text-primary text-center"
              v-b-tooltip.hover
              :title="itemsType"
            >
              <span style="color: #515254; font-weight: bold; font-size: 40px">
                {{ values["ddq_count_" + formId + "_" + formType] }}
              </span>
              <br />
              <span style="color: #515254">
                {{ values["ddq_percentage_" + formId + "_" + formType] }}%
              </span>
            </b-card-text>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { mapState } from "vuex";

export default {
  name: "DdqStatusComponent",
  props: {
    itemsKey: String,
    itemsIndex: Number,
    selectedWidgets: Array,
    formId: Number,
    formType: Number,
    itemsType: String,
    formLabel: String,
    formTitle: String
  },
  data() {
    return {
      ddqStatChartLoading: false,
      ddqStatChart: [],
      overallValues: []
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    })
  },
  mounted() {
    this.selectedWidgets.map((items) => {
      if (
        this.itemsKey.includes("Not_Started") ||
        this.itemsKey.includes("In_Progress_-_Pause_Reminders") ||
        this.itemsKey.includes("In_Progress") ||
        this.itemsKey.includes("Completed") ||
        this.itemsKey.includes("Cancelled") ||
        this.itemsKey.includes("Self_Evidenced") ||
        this.itemsKey.includes("Declined_/_Refused")
      ) {
        this.ddqStatChart[items.index] = false;
      }
    });
  },
  methods: {
    ddqStatApiCall(isVisible) {
      if (isVisible) {
        if (this.ddqStatChart[this.itemsIndex] == false) {
          this.selectedWidgets.map((items) => {
            if (items.key == this.itemsKey) {
              this.getDdqstat();
            }
          });
        }
      }
    },
    async getDdqstat() {
      this.ddqStatChartLoading = true;
      let formData = {
        company_id: this.companyId,
        widgettype: this.formType,
        formId: this.formId
      };
      try {
        let result = await HTTP(
          "post",
          "dashboard/getddqstate",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.data) {
          this.overallValues.push(result.data.data);
          this.ddqStatChartLoading = false;
          this.ddqStatChart[this.itemsIndex] = true;
        }
      } catch (err) {
        this.ddqStatChartLoading = false;
        return [err];
      }
    }
  }
};
</script>
