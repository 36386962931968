<template>
  <div class="greenlite-third-parties" data-test-id="greenlite-third-parties">
    <div>
      <div class="greenlite-third-parties__table-bar">
        <TableNavBar
          @table-search="onSearchTable"
          @table-search-clear="onClearTable"
          :search-value="searchValue"
          :is-table-loading="isLoadingTableData"
        />
      </div>
      <BaseLoader v-if="isLoadingTableData" />
      <template v-else>
        <template v-if="hasTableData">
          <div class="greenlite-third-parties__table-holder">
            <StickyTable
              class="third-party-greenlite-table__table"
              data-test-id="third-party-greenlite-table__table"
              :table-headers="greenliteThirdPartyHeaders"
              :table-rows="greenliteThirdPartyRows"
              @table-header-click="onTableHeaderClick"
            />
          </div>
          <PerPagePagination
            :items-per-page="itemsPerPage"
            :current-page="currentPage"
            :total-items="totalItems"
            :options="pageOptions"
            @change-items="onChangeItems"
            @change-current-page="onChangeCurrentPage"
          />
        </template>
        <BaseText
          v-else
          data-test-id="third-party-greenlite-table__error-message"
          :text="[greenliteThirdPartiesDataError]"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { omit, without } from "lodash";
import { esgService } from "@/services";
import {
  typographySize,
  greenliteThirdPartiesTableHeaders,
  greenliteThirdPartyKeys,
  urls,
  sortOrder,
  thirdPartyTabHash
} from "@/constants";
import { getRatingTheme, makeStringCamelToSnakeCase } from "@/utils";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseText from "@/atoms/BaseText/BaseText";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import PerPagePagination from "@/molecules/PerPagePagination/PerPagePagination";
import TableNavBar from "@/molecules/TableNavBar/TableNavBar";

export default {
  name: "GreenliteThirdParties",
  components: {
    StickyTable,
    BaseText,
    BaseLoader,
    PerPagePagination,
    TableNavBar
  },
  data() {
    return {
      pageOptions: [10, 20, 50, 100],
      greenliteThirdPartiesData: [],
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 150,
      greenliteThirdPartiesDataError: "",
      isLoadingTableData: false,
      columnPosition: 0,
      columnName: "name",
      columnSortOrder: sortOrder.DESCENDING,
      searchValue: null
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    }),
    hasTableData() {
      return this.greenliteThirdPartiesData?.length;
    },
    greenliteThirdPartyHeaders() {
      return this.greenliteThirdPartiesData?.length
        ? Object.keys(
            omit(this.greenliteThirdPartiesData[0], greenliteThirdPartyKeys.ID)
          ).reduce((acc, key) => {
            if (greenliteThirdPartiesTableHeaders[key]) {
              acc.push({
                value: key,
                text: greenliteThirdPartiesTableHeaders[key],
                styles: {
                  verticalAlign: "top"
                },
                sortable: true
              });
            }
            if (acc.length - 1 === this.columnPosition) {
              acc[this.columnPosition].sortOrder = this.columnSortOrder;
            }
            return acc;
          }, [])
        : [];
    },
    greenliteThirdPartyRows() {
      return this.greenliteThirdPartiesData?.length
        ? this.greenliteThirdPartiesData.map((greenliteThirdPartyData) => {
            const validKeys = without(
              Object.keys(greenliteThirdPartyData),
              greenliteThirdPartyKeys.ID
            );
            return validKeys.reduce((acc, greenliteThirdPartyDataKey) => {
              if (greenliteThirdPartyDataKey === greenliteThirdPartyKeys.NAME) {
                acc[greenliteThirdPartyDataKey] = {
                  component: "RouterLink",
                  componentOptions: {
                    defaultSlots: (() =>
                      greenliteThirdPartyData[greenliteThirdPartyDataKey])(),
                    to: `${urls.THIRD_PARTY_PROFILE(
                      greenliteThirdPartyData[greenliteThirdPartyKeys.ID]
                    )}${thirdPartyTabHash.ESG}`
                  }
                };
              } else {
                acc[greenliteThirdPartyDataKey] = {
                  component: BaseBadge,
                  componentOptions: {
                    tag: "span",
                    theme: getRatingTheme(
                      greenliteThirdPartyData[greenliteThirdPartyDataKey],
                      greenliteThirdPartyDataKey
                    ),
                    text: greenliteThirdPartyData[greenliteThirdPartyDataKey],
                    size: typographySize.BODY_TEXT_BOLD,
                    minimumWidth: "100%",
                    style: {
                      display: greenliteThirdPartyData[
                        greenliteThirdPartyDataKey
                      ]
                        ? "static"
                        : "none"
                    }
                  }
                };
              }
              return acc;
            }, {});
          })
        : [];
    }
  },
  async mounted() {
    await this.fetchEsgThirdParties();
  },
  methods: {
    onTableHeaderClick(columnIndex, columnSortOrder) {
      this.columnPosition = columnIndex;
      this.columnName = makeStringCamelToSnakeCase(
        this.greenliteThirdPartyHeaders[columnIndex]?.value
      );
      this.columnSortOrder = columnSortOrder;
      this.fetchEsgThirdParties();
    },
    onSearchTable(value) {
      this.searchValue = value;
      this.fetchEsgThirdParties();
    },
    onClearTable(value) {
      this.searchValue = value;
      this.fetchEsgThirdParties();
    },
    onChangeItems(val) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt(val);
      this.fetchEsgThirdParties();
    },
    onChangeCurrentPage(val) {
      this.currentPage = parseInt(val);
      this.fetchEsgThirdParties();
    },
    fetchEsgThirdPartiesSuccessfully({ data, total }) {
      if (data?.length) {
        this.greenliteThirdPartiesData = data;
        this.totalItems = total;
      } else {
        this.greenliteThirdPartiesDataError = "No results found.";
      }
    },
    makeQueryForEsgThirdParties() {
      return {
        per_page: this.itemsPerPage,
        page_number: this.currentPage,
        sort: `${this.columnName}|${this.columnSortOrder}`,
        third_party_name_search: this.searchValue
      };
    },
    async fetchEsgThirdParties() {
      try {
        this.isLoadingTableData = true;
        const { data = {} } = await esgService.fetchEsgThirdParties(
          this.companyId,
          this.makeQueryForEsgThirdParties()
        );
        this.fetchEsgThirdPartiesSuccessfully(data);
      } catch (error) {
        this.fetchEsgThirdPartiesFailure();
        return error;
      } finally {
        this.isLoadingTableData = false;
      }
    },
    fetchEsgThirdPartiesFailure() {
      this.greenliteThirdPartiesData = [];
      this.greenliteThirdPartiesDataError =
        "Unfortunately something has gone wrong, please try again later.";
    }
  }
};
</script>

<style lang="scss" scoped>
.greenlite-third-parties {
  padding: 20px 0;
  overflow: hidden;

  &__table-holder {
    max-height: calc(100vh - 300px);
    overflow-x: scroll;
    overflow-y: scroll;
    margin-bottom: 20px;
  }

  &__table-bar {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 5px;
    margin-right: 10px;
  }
}
</style>
