<template id="template-hr">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <b-collapse class="mt-5" id="collapse-1" v-model="collapse">
      <hr />
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import { mapState } from "vuex";

export default {
  name: "HrGroup",
  components: {
    MenuButtons
  },
  computed: {
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true
    };
  },
  methods: {},
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
