<template>
  <div class="modal-header">
    <h4 class="modal-header__title" data-test-id="modal-header__title">
      {{ title }}
    </h4>
    <CallToAction
      class="modal-header__cta"
      icon="times"
      theme="none"
      @click="$emit('click', $event)"
    />
  </div>
</template>

<script>
import CallToAction from "@/atoms/CallToAction/CallToAction";

export default {
  name: "ModalHeader",
  components: { CallToAction },
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid $chinese-silver;

  &__title {
    @include heading-four;
    margin: 0;
  }

  &__cta {
    width: 30px;
    height: 30px;
  }
}
</style>
