import {
  actionKeys,
  automationsKeys,
  companyOrganizationsKeys,
  ESGReportsKeys,
  greenliteThirdPartyKeys,
  numberOfEmployeesKeys,
  productVersionsKeysDTO,
  registrationNumbersKeys,
  seniorPrincipalsKeys,
  userRolesKeysDTO,
  userEmailTemplateKeysDTO
} from "@/constants";

const greenliteThirdPartiesTableHeaders = {
  [greenliteThirdPartyKeys.NAME]: "Third party",
  [greenliteThirdPartyKeys.GREENLITE_EXPLORE_RATING]: "ESG Explore Rating",
  [greenliteThirdPartyKeys.ENVIRONMENTAL_RATING]: "Environmental Rating",
  [greenliteThirdPartyKeys.SOCIAL_RATING]: "Social Rating",
  [greenliteThirdPartyKeys.GOVERNANCE_RATING]: "Governance Overall Rating",
  [greenliteThirdPartyKeys.RISK_SEVERITY]: "Risk Severity",
  [greenliteThirdPartyKeys.RESILIENCE_STRENGTH]: "Resilience Strength",
  [greenliteThirdPartyKeys.ENVIRONMENTAL_RISK]: "Environmental Risk Severity",
  [greenliteThirdPartyKeys.SOCIAL_RISK]: "Social Risk Severity",
  [greenliteThirdPartyKeys.GOVERNANCE_RISK]: "Governance Risk Severity",
  [greenliteThirdPartyKeys.ENVIRONMENT_RESILIENCE]:
    "Environment Resilience Strength",
  [greenliteThirdPartyKeys.ENVIRONMENTAL_PRACTICES]:
    "Environmental Practices Strength",
  [greenliteThirdPartyKeys.CARBON_NEUTRAL]: "Carbon Neutral Strength",
  [greenliteThirdPartyKeys.SOCIAL_RESILIENCE]: "Social Resilience Strength",
  [greenliteThirdPartyKeys.HUMAN_RIGHTS_AND_MODERN_SLAVERY]:
    "Human Rights & Modern Slavery Strength",
  [greenliteThirdPartyKeys.LABOUR]: "Labour Strength",
  [greenliteThirdPartyKeys.DIVERSITY_AND_INCLUSION]:
    "Diversity & Inclusion Strength",
  [greenliteThirdPartyKeys.HEALTH_AND_SAFETY]: "Health & Safety Strength",
  [greenliteThirdPartyKeys.GOVERNANCE_RESILIENCE]:
    "Governance Resilience Strength",
  [greenliteThirdPartyKeys.ANTI_BRIBERY_CORRUPTION]:
    "Anti Bribery & Corruption Strength",
  [greenliteThirdPartyKeys.WHISTLEBLOWING]: "Whistleblowing Strength",
  [greenliteThirdPartyKeys.SUPPLY_CHAIN]: "Supply Chain Strength"
};

const actionsListTableHeaders = {
  [actionKeys.NAME]: "Name",
  [actionKeys.DESCRIPTION]: "Description",
  [actionKeys.IS_ACTIVE]: "Status",
  [actionKeys.IS_SYSTEM]: "Created by",
  [actionKeys.ACTION]: "Action"
};

const registrationNumbersTableHeaders = {
  [registrationNumbersKeys.TYPE]: "Registration Type",
  [registrationNumbersKeys.NUMBER]: "Registration Number"
};

const numberOfEmployeesTableHeaders = {
  [numberOfEmployeesKeys.DESCRIPTION]: "Type",
  [numberOfEmployeesKeys.VALUE]: "No. of Employee"
};
const seniorPrincipalsTableHeaders = {
  [seniorPrincipalsKeys.TITLE]: "Job Title",
  [seniorPrincipalsKeys.NAME_PREFIX]: "Prefix",
  [seniorPrincipalsKeys.FULL_NAME]: "Senior Principal",
  [seniorPrincipalsKeys.NAME_SUFFIX]: "Suffix",
  [seniorPrincipalsKeys.GENDER]: "Gender",
  [seniorPrincipalsKeys.SELECTED]: "In Associates"
};

const companyOrganizationsTableHeaders = {
  [companyOrganizationsKeys.NAME]: "Registered Company Name",
  [companyOrganizationsKeys.REGISTERED_COUNTRY]: "Registered Country",
  [companyOrganizationsKeys.REGISTRATION_STATUS]: "Registration Status",
  [companyOrganizationsKeys.MATCH_CONFIDENCE]:
    "Match Confidence(1 weak - 10 strong)",
  [companyOrganizationsKeys.SELECT]: "Select"
};

const automationsListTableHeaders = {
  [automationsKeys.NAME]: "Name",
  [automationsKeys.DESCRIPTION]: "Description",
  [automationsKeys.CREATED_BY]: "Created by",
  [automationsKeys.IS_ACTIVE]: "Status",
  [automationsKeys.ACTIONS]: "Actions"
};

const automationConditionTableHeaders = {
  [automationsKeys.NAME]: "Name",
  [automationsKeys.TYPE]: "Data type"
};

const ESGReportsTableHeaders = {
  [ESGReportsKeys.VERSION]: "Version",
  [ESGReportsKeys.TRIGGER_DATE]: "Trigger Date",
  [ESGReportsKeys.REPORT_ID]: "Reports"
};

const productVersionTableHeaders = {
  [productVersionsKeysDTO.SERVICE]: "Service",
  [productVersionsKeysDTO.VERSION]: "Version"
};

const roleTableHeaders = {
  [userRolesKeysDTO.NAME]: "Role name",
  [userRolesKeysDTO.DESCRIPTION]: "Description",
  [userRolesKeysDTO.ACTIONS]: "Actions"
};

const ultimateBeneficialOwnersHeaders = {
  SELECT: "Select",
  BENEFICIARY_NAME: "Beneficiary name",
  BENEFICIARY_TYPE: "Beneficiary type",
  RISK_STATUS: "IDD",
  BUSINESS_OWNERSHIP_TYPE: "Business ownership type",
  BENEFICIARY_OWNERSHIP_PERCENTAGE: "Beneficial ownership percentage (%)",
  DIRECT_OWNERSHIP_PERCENTAGE: "Direct ownership percentage (%)",
  INDIRECT_OWNERSHIP_PERCENTAGE: "Indirect ownership percentage (%)",
  PERSON_OF_SIGNIFICANT_CONTROL: "Person of significant control",
  VIEW_PROFILE: "View profile"
};

const userEmailTemplatesListTableHeaders = {
  [userEmailTemplateKeysDTO.NAME]: "Name",
  [userEmailTemplateKeysDTO.DESCRIPTION]: "Description",
  [userEmailTemplateKeysDTO.ACTION]: "Action"
};

export {
  greenliteThirdPartiesTableHeaders,
  actionsListTableHeaders,
  registrationNumbersTableHeaders,
  numberOfEmployeesTableHeaders,
  seniorPrincipalsTableHeaders,
  companyOrganizationsTableHeaders,
  automationsListTableHeaders,
  automationConditionTableHeaders,
  roleTableHeaders,
  ESGReportsTableHeaders,
  productVersionTableHeaders,
  ultimateBeneficialOwnersHeaders,
  userEmailTemplatesListTableHeaders
};
