<!-- EDD Report type details -->
<template>
  <div class="p-4" style="height: 90vh; overflow-y: auto">
    <p class="text-left">What is included in each report type?</p>
    <b-overlay :show="isLoading">
      <div class="d-flex">
        <div
          v-for="(eddType, index) in eddReportTypes"
          :key="index"
          class="mr-2"
        >
          <b-card style="width: 400px">
            <template #header>
              <div class="text-center">
                {{ eddType.key }}
              </div>
            </template>
            <b-list-group flush>
              <b-list-group-item
                v-for="(eddValue, indexValue) in eddType.value"
                :key="indexValue"
                class="mb-1"
                style="display: flex"
              >
                <font-awesome-icon
                  class="m-2"
                  icon="check"
                  style="color: #28a745"
                />
                <p>{{ eddType.value[indexValue].product_name }}</p>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { mapState } from "vuex";

export default {
  name: "eddReportType",
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    })
  },
  mounted() {
    this.getEddReportsType();
  },
  data() {
    return {
      eddReportTypes: null,
      isLoading: false
    };
  },
  methods: {
    async getEddReportsType() {
      this.isLoading = true;
      try {
        let result = await HTTP(
          "get",
          `thirdparty/edd/client/all/packages/selected/products/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          this.eddReportTypes = result.data;
        }
        this.isLoading = false;
      } catch (error) {
        return [error];
      }
    }
  }
};
</script>

<style></style>
