<template id="template-input">
  <b-form-group class="mt-2" label-cols-lg="3">
    <template #label>
      <p>
        {{
          attributeData.tp_alias
            ? attributeData.tp_alias
            : attributeData.tp_customatt
        }}
      </p>
    </template>

    <!-- if custom attribute is required -->
    <div v-if="parseInt(attributeData.tp_is_required)">
      <b-form-radio-group
        id="radio-group-1"
        v-model="form.value"
        :options="attributeData.tp_customatt_values"
        name="radio-options"
        value-field="id"
        text-field="label"
        @change="saveValue()"
      >
      </b-form-radio-group>
      <div
        v-if="form.value == null"
        style="width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545"
      >
        Please select some option.
      </div>
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <b-form-radio-group
        id="radio-group-1"
        v-model="form.value"
        :options="attributeData.tp_customatt_values"
        name="radio-options"
        value-field="id"
        text-field="label"
        @change="saveValue()"
      >
      </b-form-radio-group>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  name: "Radio",
  components: {},
  data() {
    return {
      form: {
        value: null
      }
    };
  },
  mounted() {
    if (this.values.value) {
      let filteredValue = this.attributeData.tp_customatt_values.filter(
        (val) => val.id == this.values.value
      );
      this.form.value = filteredValue[0].id;
    }
  },
  methods: {
       // save custom attribute value
      saveValue(){
        if(parseInt(this.attributeData.tp_is_required)){
        // emit event saveValue when custom attribute is required
        EventBus.$emit(
          "saveValue",
          this.form.value.toString(),
          this.attributeData.id,
          this.form.value == null,
          this.attributeData.tp_type
        );
      } else {
        // emit event saveValue when custom attribute is not required
        EventBus.$emit(
          "saveValue",
          this.form.value.toString(),
          this.attributeData.id,
          false,
          this.attributeData.tp_type
        );
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
