var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2',attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"isHidden":_vm.isHidden,"label":_vm.label,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('b-collapse',{staticClass:"card mt-5",attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.fieldData.isConditional == '1')?_c('b-button',{attrs:{"variant":"outline-dark","disabled":"","title":"This field is hidden by default and will display bt is controlled element"}},[_c('font-awesome-icon',{attrs:{"icon":"eye-slash"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"mt-3",attrs:{"id":_vm.fieldData.id}},[_c('h4',[_vm._v(_vm._s(_vm.fieldData.label[_vm.selectedLanguage]))]),_c('p',[_vm._v(_vm._s(_vm.fieldData.description[_vm.selectedLanguage]))]),_c('b-form-checkbox',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.fieldData.isNaFeature || parseInt(_vm.fieldData.isNaFeature) === 0
        ),expression:"\n          !fieldData.isNaFeature || parseInt(fieldData.isNaFeature) === 0\n        "}],attrs:{"value":"1","unchecked-value":"0"},on:{"input":_vm.onToggleNa},model:{value:(_vm.fieldData.isNa),callback:function ($$v) {_vm.$set(_vm.fieldData, "isNa", $$v)},expression:"fieldData.isNa"}},[_vm._v(_vm._s(_vm.$t("na")))])],1),_c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","title":_vm.isUpdtating
          ? _vm.$t('workflow.update') + ' ' + _vm.fieldData.label[_vm.selectedLanguage]
          : _vm.$t('workflow.add') + ' ' + _vm.fieldData.label[_vm.selectedLanguage],"size":"xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.resetModal}},[_vm._v(_vm._s(_vm.$t("closeBtnText")))]),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.isUpdtating ? _vm.updateRow() : _vm.addRow()}}},[_vm._v(_vm._s(_vm.isUpdtating ? _vm.$t("updateBtn") : _vm.$t("saveBtnText")))])]},proxy:true}]),model:{value:(_vm.showAddRowModal),callback:function ($$v) {_vm.showAddRowModal=$$v},expression:"showAddRowModal"}},[_c('div',{class:_vm.$store.state.rightAlign
            ? 'card-body text-right'
            : 'card-body text-left'},[_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.entityNameDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.entityNameDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.entityNameHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.entityNameValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequireEntityName)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.entityNameLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"placeholder":_vm.fieldData.entityNamePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.entityName.$dirty
                    ? !_vm.$v.form.entityName.$error
                    : null,"type":"text"},model:{value:(_vm.$v.form.entityName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.entityName, "$model", $$v)},expression:"$v.form.entityName.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.dateOfRegistrationDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.dateOfRegistrationDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.dateOfRegistrationHelperText[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(
                      _vm.fieldData.validation.isRequireDateOfRegistration
                    )
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.dateOfRegistrationLabel[_vm.selectedLanguage])+" ")]),_c('b-input-group',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left'},[_c('Datepicker',{staticClass:"form-control",class:_vm.$v.form.dateOfRegistration.$error
                      ? 'border border-danger rounded'
                      : null,attrs:{"disabled":_vm.isDisabled == 1,"input-class":"custom-date-picker","calendar-class":"date-calender","format":_vm.customDateFormat,"disabled-dates":_vm.disabledDates,"state":_vm.$v.form && _vm.$v.form.dateOfRegistration.$dirty
                      ? !_vm.$v.form.dateOfRegistration.$error
                      : null},model:{value:(_vm.$v.form.dateOfRegistration.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.dateOfRegistration, "$model", $$v)},expression:"$v.form.dateOfRegistration.$model"}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon2"}},[_c('i',{staticClass:"fa fa-calendar"})])])],1),(
                  _vm.$v.form.dateOfRegistration.$error &&
                  _vm.fieldData.validation.isRequireDateOfRegistration &&
                  !_vm.form.dateOfRegistration
                )?_c('label',{staticClass:"text-danger",staticStyle:{"font-size":"80%"}},[_vm._v(_vm._s(_vm.fieldData.dateOfRegistrationValidationMessage[ _vm.selectedLanguage ]))]):_vm._e()],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.countryOfRegistrationDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.countryOfRegistrationDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"description":_vm.fieldData.countryOfRegistrationHelperText[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(
                      _vm.fieldData.validation.isRequireCountryOfRegistration
                    )
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.countryOfRegistrationLabel[_vm.selectedLanguage])+" ")]),_c('v-select',{class:_vm.$v.form.countryOfRegistration.$error
                    ? 'border border-danger rounded'
                    : null,attrs:{"disabled":_vm.isDisabled == 1,"searchable":true,"clearable":true,"label":"name","state":_vm.$v.form && _vm.$v.form.countryOfRegistration.$dirty
                    ? !_vm.$v.form.countryOfRegistration.$error
                    : null,"options":_vm.optionsCountries},on:{"input":_vm.onSelected},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(option.icon)?_c('country-flag',{attrs:{"country":option.icon,"size":"small"}}):_vm._e(),_c('span',[_vm._v(_vm._s(" " + option.name))])]}}]),model:{value:(_vm.$v.form.countryOfRegistration.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.countryOfRegistration, "$model", $$v)},expression:"$v.form.countryOfRegistration.$model"}}),(
                  _vm.$v.form.countryOfRegistration.$error &&
                  _vm.fieldData.validation.isRequireCountryOfRegistration &&
                  !_vm.form.countryOfRegistration
                )?_c('label',{staticClass:"text-danger",staticStyle:{"font-size":"80%"}},[_vm._v(_vm._s(_vm.fieldData.countryOfRegistrationValidationMessage[ _vm.selectedLanguage ]))]):_vm._e()],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.percentageOwnedDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.percentageOwnedDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.percentageOwnedHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.percentageOwnedValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequirePercentageOwned)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.percentageOwnedLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"onkeydown":"return event.keyCode !== 69","disabled":_vm.isDisabled == 1,"min":"1","max":"100","placeholder":_vm.fieldData.percentageOwnedPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.percentageOwned.$dirty
                    ? !_vm.$v.form.percentageOwned.$error
                    : null,"type":"number","onpaste":"return false;"},model:{value:(_vm.$v.form.percentageOwned.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.percentageOwned, "$model", $$v)},expression:"$v.form.percentageOwned.$model"}})],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.registrationNumberDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.registrationNumberDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registrationNumberHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.registrationNumberValidationMessage[
                  _vm.selectedLanguage
                ]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(
                      _vm.fieldData.validation.isRequireRegistrationNumber
                    )
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registrationNumberLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"placeholder":_vm.fieldData.registrationNumberPlaceholderText[
                    _vm.selectedLanguage
                  ],"state":_vm.$v.form && _vm.$v.form.registrationNumber.$dirty
                    ? !_vm.$v.form.registrationNumber.$error
                    : null,"type":"text","onpaste":"return false;"},model:{value:(_vm.$v.form.registrationNumber.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.registrationNumber, "$model", $$v)},expression:"$v.form.registrationNumber.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled),expression:"!fieldData.advance || !fieldData.advance.freeFormDisabled"}],staticClass:"col-md-6"},[(_vm.fieldData.freeFormLabel)?_c('div',[_c('b-form-group',{attrs:{"description":_vm.fieldData.freeFormHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.freeFormValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                      _vm.fieldData.validation &&
                      JSON.parse(_vm.fieldData.validation.isRequireFreeForm)
                    )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.freeFormLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"placeholder":_vm.fieldData.freeFormPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.freeForm.$dirty
                      ? !_vm.$v.form.freeForm.$error
                      : null,"type":"text"},model:{value:(_vm.$v.form.freeForm.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.freeForm, "$model", $$v)},expression:"$v.form.freeForm.$model"}})],1)],1):_vm._e()])])])]),(_vm.taskStatus != 4)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.isToggle)?_c('b-spinner'):_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"disabled":_vm.isDisabled == 1 || parseInt(_vm.fieldData.isNa) === 1,"variant":"outline-success","size":"lg"},on:{"click":function($event){_vm.showAddRowModal = !_vm.showAddRowModal}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("workflow.add"))+" ")],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.isValidationRequired(_vm.fieldData))?_c('label',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("createProfile.requiredValidationMessage")))]):_vm._e()])],1):_vm._e(),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"p-5",attrs:{"cols":"12"}},[_c('b-table-simple',{attrs:{"striped":"","hover":""}},[_c('b-thead',[_c('b-tr',[(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.entityNameDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.entityNameLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.dateOfRegistrationDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.dateOfRegistrationLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.percentageOwnedDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.percentageOwnedLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.countryOfRegistrationDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.countryOfRegistrationLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.registrationNumberDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.registrationNumberLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.freeFormLabel ? _vm.fieldData.freeFormLabel[_vm.selectedLanguage] : ""))]):_vm._e(),_c('b-th',[_vm._v(_vm._s(_vm.$t("upload.fileAction")))])],1)],1),_vm._l((_vm.items),function(item,index){return _c('b-tbody',{key:index},[_c('b-tr',[(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.entityNameDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.entity_name || ""))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.dateOfRegistrationDisabled
                )?_c('b-td',[_vm._v(_vm._s(_vm.format_date(item.date_of_registration)))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.percentageOwnedDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.percentage_owned || ""))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.countryOfRegistrationDisabled
                )?_c('b-td',[_vm._v(_vm._s((item.counrty_registration && item.counrty_registration.name) || ""))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.registrationNumberDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.registration_number))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.free_form || ""))]):_vm._e(),_c('b-td',[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.onEditRow(item, index)}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.taskStatus != 4 ? 'edit' : 'eye'}})],1),(_vm.taskStatus != 4)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)],1)],1)})],2)],1)],1),_c('b-modal',{attrs:{"cancel-title":"Close","ok-title":"save","size":"xl","scrollable":"","title":"Advanced Settings"},on:{"ok":_vm.onSaveData},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[_c('AdvanceSettings',{attrs:{"fieldData":_vm.fieldData,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }