<template>
  <div class="error-404">
    <div>
      <h2>Oops!</h2>
      <h3>404 Not Found</h3>
      <p>Sorry, an error has occurred, Requested page not found!</p>
    </div>
    <div class="error-404__link-container">
      <router-link :to="urls.HOME">Take Me Home</router-link>
    </div>
  </div>
</template>

<script>
import { urls } from "@/constants";

export default {
  name: "Error404",
  data() {
    return {
      urls
    };
  }
};
</script>

<style lang="scss" scoped>
.error-404 {
  padding-top: 60px;

  &__link-container {
    margin-top: 20px;
  }
}
</style>
