var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2',attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('b-collapse',{directives:[{name:"show",rawName:"v-show",value:(JSON.parse(!_vm.fieldData.isHidden) || !_vm.isPreview),expression:"JSON.parse(!fieldData.isHidden) || !isPreview"}],staticClass:"card mt-5",attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.fieldData.isConditional == '1')?_c('b-button',{attrs:{"variant":"outline-dark","disabled":"","title":"This field is hidden by default and will display bt is controlled element"}},[_c('font-awesome-icon',{attrs:{"icon":"eye-slash"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"mt-3",attrs:{"id":_vm.fieldData.id}},[_c('h4',[_vm._v(_vm._s(_vm.fieldData.label[_vm.selectedLanguage]))]),_c('p',[_vm._v(_vm._s(_vm.fieldData.description[_vm.selectedLanguage]))])]),_c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","title":_vm.isUpdtating
          ? _vm.$t('workflow.update') + ' ' + _vm.fieldData.label[_vm.selectedLanguage]
          : _vm.$t('workflow.add') + ' ' + _vm.fieldData.label[_vm.selectedLanguage],"size":"xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.resetModal}},[_vm._v(_vm._s(_vm.$t("closeBtnText")))]),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.isUpdtating ? _vm.updateRow() : _vm.addReferee()}}},[_vm._v(_vm._s(_vm.isUpdtating ? _vm.$t("updateBtn") : _vm.$t("saveBtnText")))])]},proxy:true}]),model:{value:(_vm.showAddRowModal),callback:function ($$v) {_vm.showAddRowModal=$$v},expression:"showAddRowModal"}},[_c('div',{class:_vm.$store.state.rightAlign
            ? 'card-body text-right'
            : 'card-body text-left'},[_c('div',{staticClass:"form-row"},[_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.refereeNameDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.refereeNameDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereeNameHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.refereeNameValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(_vm.fieldData.validation.isRequireRefereeName)
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereeNameLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"placeholder":_vm.fieldData.refereeNamePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.refereeName.$dirty
                  ? !_vm.$v.form.refereeName.$error
                  : null,"type":"text"},model:{value:(_vm.$v.form.refereeName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereeName, "$model", $$v)},expression:"$v.form.refereeName.$model"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.refereePositionRoleDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.refereePositionRoleDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereePositionRoleHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.refereePositionRoleValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(
                    _vm.fieldData.validation.isRequireRefereePositionRole
                  )
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereePositionRoleLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.refereePositionRolePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.refereePositionRole.$dirty
                  ? !_vm.$v.form.refereePositionRole.$error
                  : null},model:{value:(_vm.$v.form.refereePositionRole.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereePositionRole, "$model", $$v)},expression:"$v.form.refereePositionRole.$model"}})],1)],1),_c('div',{staticClass:"form-row"},[_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.refereeCompanyDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.refereeCompanyDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereeCompanyHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.refereeCompanyValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(_vm.fieldData.validation.isRequireRefereeCompany)
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereeCompanyLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.refereeCompanyPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.refereeCompany.$dirty
                  ? !_vm.$v.form.refereeCompany.$error
                  : null},model:{value:(_vm.$v.form.refereeCompany.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereeCompany, "$model", $$v)},expression:"$v.form.refereeCompany.$model"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.refereeWebsiteDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.refereeWebsiteDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereeWebsiteHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.refereeWebsiteValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(_vm.fieldData.validation.isRequireRefereeWebsite)
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereeWebsiteLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"text","placeholder":_vm.fieldData.refereeWebsitePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.refereeWebsite.$dirty
                  ? !_vm.$v.form.refereeWebsite.$error
                  : null},model:{value:(_vm.$v.form.refereeWebsite.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereeWebsite, "$model", $$v)},expression:"$v.form.refereeWebsite.$model"}})],1)],1),_c('div',{staticClass:"form-row"},[_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.refereeEmailDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.refereeEmailDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereeEmailHelperText[_vm.selectedLanguage],"invalid-feedback":!_vm.$v.form.refereeEmail.email
                ? _vm.$t('validations.invalidFormat')
                : _vm.fieldData.refereeEmailValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(_vm.fieldData.validation.isRequireRefereeEmail)
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereeEmailLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"type":"email","placeholder":_vm.fieldData.refereeEmailPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.refereeEmail.$dirty
                  ? !_vm.$v.form.refereeEmail.$error
                  : null},model:{value:(_vm.$v.form.refereeEmail.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereeEmail, "$model", $$v)},expression:"$v.form.refereeEmail.$model"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.refereePhoneDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.refereePhoneDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereePhoneHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.refereePhoneValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(_vm.fieldData.validation.isRequireRefereePhone)
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereePhoneLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"onkeydown":"return event.keyCode !== 69","disabled":_vm.isDisabled == 1,"type":"number","onpaste":"return false;","placeholder":_vm.fieldData.refereePhonePlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.refereePhone.$dirty
                  ? !_vm.$v.form.refereePhone.$error
                  : null},model:{value:(_vm.$v.form.refereePhone.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereePhone, "$model", $$v)},expression:"$v.form.refereePhone.$model"}})],1)],1),_c('div',{staticClass:"form-row"},[_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.refereeNatureOfRelationshipDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.refereeNatureOfRelationshipDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereeNatureOfRelationshipHelperText[
                _vm.selectedLanguage
              ],"invalid-feedback":_vm.fieldData.refereeNatureOfRelationshipValidationMessage[
                _vm.selectedLanguage
              ]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(
                    _vm.fieldData.validation.isRequireRefereeNatureOfRelationship
                  )
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereeNatureOfRelationshipLabel[_vm.selectedLanguage])+" ")]),_c('b-form-textarea',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"placeholder":_vm.fieldData.refereeNatureOfRelationshipPlaceholderText[
                  _vm.selectedLanguage
                ],"state":_vm.$v.form && _vm.$v.form.refereeNatureOfRelationship.$dirty
                  ? !_vm.$v.form.refereeNatureOfRelationship.$error
                  : null},model:{value:(_vm.$v.form.refereeNatureOfRelationship.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereeNatureOfRelationship, "$model", $$v)},expression:"$v.form.refereeNatureOfRelationship.$model"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.refereeLengthOfRelationDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.refereeLengthOfRelationDisabled\n            "}],staticClass:"col-md-6",attrs:{"description":_vm.fieldData.refereeLengthOfRelationHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.refereeLengthOfRelationValidationMessage[
                _vm.selectedLanguage
              ]}},[_c('label',[(
                  _vm.fieldData.validation &&
                  JSON.parse(
                    _vm.fieldData.validation.isRequireRefereeLengthOfRelation
                  )
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.refereeLengthOfRelationLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"onkeydown":"return event.keyCode !== 69","disabled":_vm.isDisabled == 1,"type":"number","onpaste":"return false;","placeholder":_vm.fieldData.refereeLengthOfRelationPlaceholderText[
                  _vm.selectedLanguage
                ],"state":_vm.$v.form && _vm.$v.form.refereeLengthOfRelation.$dirty
                  ? !_vm.$v.form.refereeLengthOfRelation.$error
                  : null},model:{value:(_vm.$v.form.refereeLengthOfRelation.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.refereeLengthOfRelation, "$model", $$v)},expression:"$v.form.refereeLengthOfRelation.$model"}})],1)],1),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled),expression:"!fieldData.advance || !fieldData.advance.freeFormDisabled"}],staticClass:"col-md-6"},[(_vm.fieldData.freeFormLabel)?_c('div',[_c('b-form-group',{attrs:{"description":_vm.fieldData.freeFormHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.freeFormValidationMessage[_vm.selectedLanguage]}},[_c('label',[(
                      _vm.fieldData.validation &&
                      JSON.parse(_vm.fieldData.validation.isRequireFreeForm)
                    )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.freeFormLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"placeholder":_vm.fieldData.freeFormPlaceholderText[_vm.selectedLanguage],"state":_vm.$v.form && _vm.$v.form.freeForm.$dirty
                      ? !_vm.$v.form.freeForm.$error
                      : null,"type":"text"},model:{value:(_vm.$v.form.freeForm.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.freeForm, "$model", $$v)},expression:"$v.form.freeForm.$model"}})],1)],1):_vm._e()])])])]),(_vm.taskStatus != 4)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"disabled":_vm.isDisabled == 1,"variant":"outline-success","size":"lg"},on:{"click":function($event){_vm.showAddRowModal = !_vm.showAddRowModal}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("workflow.add"))+" ")],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"p-5",attrs:{"cols":"12"}},[_c('b-table-simple',{attrs:{"striped":"","hover":""}},[_c('b-thead',[_c('b-tr',[(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.refereeNameDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereeNameLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereePositionRoleDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereePositionRoleLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeCompanyDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereeCompanyLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeEmailDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereeEmailLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereePhoneDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereePhoneLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeWebsiteDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereeWebsiteLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeNatureOfRelationshipDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereeNatureOfRelationshipLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeLengthOfRelationDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.refereeLengthOfRelationLabel[_vm.selectedLanguage]))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled
                )?_c('b-th',[_vm._v(_vm._s(_vm.fieldData.freeFormLabel && _vm.fieldData.freeFormLabel[_vm.selectedLanguage]))]):_vm._e(),_c('b-th',[_vm._v(_vm._s(_vm.$t("upload.fileAction")))])],1)],1),_vm._l((_vm.items),function(item,index){return _c('b-tbody',{key:index},[_c('b-tr',[(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.refereeNameDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.name))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereePositionRoleDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.position))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeCompanyDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.company))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeEmailDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.email))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereePhoneDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.phone))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeWebsiteDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.website))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeNatureOfRelationshipDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.relationship))]):_vm._e(),(
                  !_vm.fieldData.advance ||
                  !_vm.fieldData.advance.refereeLengthOfRelationDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.length_of_relation))]):_vm._e(),(
                  !_vm.fieldData.advance || !_vm.fieldData.advance.freeFormDisabled
                )?_c('b-td',[_vm._v(_vm._s(item.free_form))]):_vm._e(),_c('b-td',[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.onEditRow(item, index)}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.taskStatus != 4 ? 'edit' : 'eye'}})],1),(_vm.taskStatus != 4)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)],1)],1)})],2)],1)],1),_c('b-modal',{attrs:{"cancel-title":"Close","ok-title":"save","size":"xl","scrollable":"","title":"Advanced Settings"},on:{"ok":_vm.onSaveData},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[_c('AdvanceSettings',{attrs:{"fieldData":_vm.fieldData,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }