<template>
  <b-row>
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabClick(data)"
              v-for="(data, index) in form.formTranslations"
              :key="index"
              :title="data"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-input
                  v-if="fieldData.inputType != 'paragraph'"
                  v-model="label"
                  type="text"
                ></b-form-input>

                <b-form-textarea
                  v-if="fieldData.inputType == 'paragraph'"
                  v-model="label"
                  rows="6"
                  max-rows="9"
                  >{{ fieldData.label[transalationLanguage] }}</b-form-textarea
                >
              </div>
              <b-button
                class="btn btn-primary mt-3"
                @click="addLanguageToField(data)"
                variant="primary"
                >Update</b-button
              >
            </b-tab>
          </b-tabs>
        </b-tab>
        <!-- for linking trainings --------------------->
        <b-tab v-if="fieldData.inputType == 'link'" title="Advance">
          <div>
            <!-- check for shw pdf form -->
            <b-form-checkbox
              v-model="fieldData.linkForm"
              @change="onLinkFormClick"
              class="mb-3"
              >Link Policy</b-form-checkbox
            >

            <b-form-group label="Select Policy" v-if="fieldData.linkForm">
              <v-select
                label="title"
                v-model="fieldData.selectedForm"
                @input="onSelectTraining"
                :options="this.formTrainings"
                searchable
                clearable
              ></v-select>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
    <!-- End of column -->
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Helper } from "@/helpers";
import { HTTP } from "@/utils";

export default {
  name: "AdvanceHeadingSettings",
  components: {},
  data() {
    return {
      transalationLanguage: "english",
      linkForm: false,
      selectedForm: null,
      formTrainings: [],
      label: undefined,
      isLoading: false
    };
  },
  mounted() {
    this.label = this.fieldData.label[this.transalationLanguage];
    this.getTrainings();
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },
  methods: {
    ...mapActions(["updateField"]),

    tabClick(key) {
      this.transalationLanguage = key.toLowerCase();
      this.label = this.fieldData.label[this.transalationLanguage];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;

      Helper.makeToast(this.$bvToast, "success", "Language Update");
    },

    // for linking forms
    onLinkFormClick(e) {
      this.linkForm = e;
      if (e == false) {
        // this.fieldData.selectedPdfForm = null;
        this.form.linkedTraining = null;
        this.selectedForm = null;
      }
      Object.assign(this.fieldData, (this.fieldData.linkPDF = e));
    },

    async getTrainings() {
      try {
        let result = await HTTP("post", "v1/getclientforms", {
          companyId: this.form.clientId,
          type: 3
        });
        if (
          result.data &&
          result.data.training &&
          result.data.training.length
        ) {
          this.formTrainings = result.data.training;
        }
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },

    onSelectTraining(e) {
      // this.setSubTask(e);
      this.selectedForm = e.title;
      let linkedTraining = {
        id: e.id || null,
        formId: this.form.formId,
        company_id: e.company_id || null,
        title: e.title || null,
        createdBy: e.created_by_id || null
        // shcema: e.schema || null
      };
      Object.assign(this.fieldData, { linkedTraining });
    }
  },
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
