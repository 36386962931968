import {
  userEmailTemplateKeysDTO,
  userEmailTemplateKeysAPIDTO,
  userEmailTemplatesDestinationTypes
} from "@/constants";
import { makeUUID } from "@/utils";

const makeUserEmailTemplateDestinationEmailDTO = (
  userEmailTemplateDestinationEmail
) => ({
  [userEmailTemplateKeysDTO.TYPE]:
    userEmailTemplateDestinationEmail?.[userEmailTemplateKeysAPIDTO.TYPE],
  [userEmailTemplateKeysDTO.VALUE]: {
    [userEmailTemplateKeysDTO.EMAIL]:
      userEmailTemplateDestinationEmail?.[userEmailTemplateKeysAPIDTO.VALUE]?.[
        userEmailTemplateKeysAPIDTO.EMAIL
      ]
  }
});

const makeUserEmailTemplateDestinationEmailAPIDTO = (
  userEmailTemplateDestinationEmail
) => ({
  [userEmailTemplateKeysAPIDTO.TYPE]:
    userEmailTemplateDestinationEmail?.[userEmailTemplateKeysDTO.TYPE],
  [userEmailTemplateKeysAPIDTO.VALUE]: {
    [userEmailTemplateKeysAPIDTO.EMAIL]:
      userEmailTemplateDestinationEmail?.[userEmailTemplateKeysDTO.VALUE]?.[
        userEmailTemplateKeysDTO.EMAIL
      ]
  }
});

const makeUserEmailTemplateDestinationRoleDTO = (
  userEmailTemplateDestinationRole
) => ({
  [userEmailTemplateKeysDTO.TYPE]:
    userEmailTemplateDestinationRole?.[userEmailTemplateKeysAPIDTO.TYPE],
  [userEmailTemplateKeysDTO.VALUE]: {
    [userEmailTemplateKeysDTO.COMPANY_ID]:
      userEmailTemplateDestinationRole?.[userEmailTemplateKeysAPIDTO.VALUE]?.[
        userEmailTemplateKeysAPIDTO.COMPANY_ID
      ],
    [userEmailTemplateKeysDTO.ROLE_ID]:
      userEmailTemplateDestinationRole?.[userEmailTemplateKeysAPIDTO.VALUE]?.[
        userEmailTemplateKeysAPIDTO.ROLE_ID
      ]
  }
});

const makeUserEmailTemplateDestinationRoleAPIDTO = (
  userEmailTemplateDestinationRole
) => ({
  [userEmailTemplateKeysAPIDTO.TYPE]:
    userEmailTemplateDestinationRole?.[userEmailTemplateKeysDTO.TYPE],
  [userEmailTemplateKeysAPIDTO.VALUE]: {
    [userEmailTemplateKeysAPIDTO.COMPANY_ID]:
      userEmailTemplateDestinationRole?.[userEmailTemplateKeysDTO.VALUE]?.[
        userEmailTemplateKeysDTO.COMPANY_ID
      ],
    [userEmailTemplateKeysAPIDTO.ROLE_ID]:
      userEmailTemplateDestinationRole?.[userEmailTemplateKeysDTO.VALUE]?.[
        userEmailTemplateKeysDTO.ROLE_ID
      ]
  }
});

const makeUserEmailTemplateDestinationUserDTO = (
  userEmailTemplateDestinationUser
) => ({
  [userEmailTemplateKeysDTO.TYPE]:
    userEmailTemplateDestinationUser?.[userEmailTemplateKeysAPIDTO.TYPE],
  [userEmailTemplateKeysDTO.VALUE]: {
    [userEmailTemplateKeysDTO.USER_ID]:
      userEmailTemplateDestinationUser?.[userEmailTemplateKeysAPIDTO.VALUE]?.[
        userEmailTemplateKeysAPIDTO.USER_ID
      ]
  }
});

const makeUserEmailTemplateDestinationUserAPIDTO = (
  userEmailTemplateDestinationUser
) => ({
  [userEmailTemplateKeysAPIDTO.TYPE]:
    userEmailTemplateDestinationUser?.[userEmailTemplateKeysDTO.TYPE],
  [userEmailTemplateKeysAPIDTO.VALUE]: {
    [userEmailTemplateKeysAPIDTO.USER_ID]:
      userEmailTemplateDestinationUser?.[userEmailTemplateKeysDTO.VALUE]?.[
        userEmailTemplateKeysDTO.USER_ID
      ]
  }
});

const makeUserEmailTemplateDestinationDTO = ({
  userEmailTemplateDestination = [],
  _makeUserEmailTemplateDestinationEmailDTO = makeUserEmailTemplateDestinationEmailDTO,
  _makeUserEmailTemplateDestinationRoleDTO = makeUserEmailTemplateDestinationRoleDTO,
  _makeUserEmailTemplateDestinationUserDTO = makeUserEmailTemplateDestinationUserDTO
} = {}) =>
  userEmailTemplateDestination?.map((userEmailDestination) => {
    if (
      userEmailDestination[userEmailTemplateKeysAPIDTO.TYPE] ===
      userEmailTemplatesDestinationTypes.EMAIL
    ) {
      return _makeUserEmailTemplateDestinationEmailDTO(userEmailDestination);
    } else if (
      userEmailDestination[userEmailTemplateKeysAPIDTO.TYPE] ===
      userEmailTemplatesDestinationTypes.ROLE
    ) {
      return _makeUserEmailTemplateDestinationRoleDTO(userEmailDestination);
    } else if (
      userEmailDestination[userEmailTemplateKeysAPIDTO.TYPE] ===
      userEmailTemplatesDestinationTypes.USER
    ) {
      return _makeUserEmailTemplateDestinationUserDTO(userEmailDestination);
    }
  }) || [];

const makeUserEmailTemplateDestinationAPIDTO = ({
  userEmailTemplateDestination = [],
  _makeUserEmailTemplateDestinationEmailAPIDTO = makeUserEmailTemplateDestinationEmailAPIDTO,
  _makeUserEmailTemplateDestinationRoleAPIDTO = makeUserEmailTemplateDestinationRoleAPIDTO,
  _makeUserEmailTemplateDestinationUserAPIDTO = makeUserEmailTemplateDestinationUserAPIDTO
} = {}) =>
  userEmailTemplateDestination?.map((userEmailDestination) => {
    if (
      userEmailDestination[userEmailTemplateKeysDTO.TYPE] ===
      userEmailTemplatesDestinationTypes.EMAIL
    ) {
      return _makeUserEmailTemplateDestinationEmailAPIDTO(userEmailDestination);
    } else if (
      userEmailDestination[userEmailTemplateKeysDTO.TYPE] ===
      userEmailTemplatesDestinationTypes.ROLE
    ) {
      return _makeUserEmailTemplateDestinationRoleAPIDTO(userEmailDestination);
    } else if (
      userEmailDestination[userEmailTemplateKeysDTO.TYPE] ===
      userEmailTemplatesDestinationTypes.USER
    ) {
      return _makeUserEmailTemplateDestinationUserAPIDTO(userEmailDestination);
    }
  }) || [];

const makeUserEmailTemplateExpectedDataDTO = (expectedData) => ({
  [userEmailTemplateKeysDTO.VARIABLE]:
    expectedData?.[userEmailTemplateKeysAPIDTO.VARIABLE],
  [userEmailTemplateKeysDTO.TYPE]:
    expectedData?.[userEmailTemplateKeysAPIDTO.TYPE],
  [userEmailTemplateKeysDTO.ID]: makeUUID()
});

const makeUserEmailTemplateExpectedDataAPIDTO = (expectedData) => ({
  [userEmailTemplateKeysAPIDTO.VARIABLE]:
    expectedData?.[userEmailTemplateKeysDTO.VARIABLE],
  [userEmailTemplateKeysAPIDTO.TYPE]:
    expectedData?.[userEmailTemplateKeysDTO.TYPE]
});

const makeUserEmailTemplateDTO = ({
  userEmailTemplate = {},
  _makeUserEmailTemplateExpectedDataDTO = makeUserEmailTemplateExpectedDataDTO,
  _makeUserEmailTemplateDestinationDTO = makeUserEmailTemplateDestinationDTO
} = {}) => ({
  [userEmailTemplateKeysDTO.ID]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.ID],
  [userEmailTemplateKeysDTO.COMPANY_ID]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.COMPANY_ID],
  [userEmailTemplateKeysDTO.NAME]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.NAME],
  [userEmailTemplateKeysDTO.DESCRIPTION]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.DESCRIPTION],
  [userEmailTemplateKeysDTO.SUBJECT]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.SUBJECT],
  [userEmailTemplateKeysDTO.BODY]:
    userEmailTemplate?.[userEmailTemplateKeysAPIDTO.BODY],
  [userEmailTemplateKeysDTO.EXPECTED_DATA]: userEmailTemplate?.[
    userEmailTemplateKeysAPIDTO.EXPECTED_DATA
  ]?.map((userEmailTemplate) =>
    _makeUserEmailTemplateExpectedDataDTO(userEmailTemplate)
  ),
  [userEmailTemplateKeysDTO.TO]: _makeUserEmailTemplateDestinationDTO({
    userEmailTemplateDestination:
      userEmailTemplate?.[userEmailTemplateKeysAPIDTO.TO]
  }),
  [userEmailTemplateKeysDTO.CC]: _makeUserEmailTemplateDestinationDTO({
    userEmailTemplateDestination:
      userEmailTemplate?.[userEmailTemplateKeysAPIDTO.CC]
  }),
  [userEmailTemplateKeysDTO.BCC]: _makeUserEmailTemplateDestinationDTO({
    userEmailTemplateDestination:
      userEmailTemplate?.[userEmailTemplateKeysAPIDTO.BCC]
  })
});

const makeUserEmailTemplateAPIDTO = ({
  userEmailTemplate = {},
  _makeUserEmailTemplateExpectedDataAPIDTO = makeUserEmailTemplateExpectedDataAPIDTO,
  _makeUserEmailTemplateDestinationAPIDTO = makeUserEmailTemplateDestinationAPIDTO
} = {}) => ({
  [userEmailTemplateKeysAPIDTO.COMPANY_ID]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.COMPANY_ID],
  [userEmailTemplateKeysAPIDTO.ID]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.ID],
  [userEmailTemplateKeysAPIDTO.NAME]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.NAME],
  [userEmailTemplateKeysAPIDTO.DESCRIPTION]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.DESCRIPTION],
  [userEmailTemplateKeysAPIDTO.SUBJECT]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.SUBJECT],
  [userEmailTemplateKeysAPIDTO.BODY]:
    userEmailTemplate?.[userEmailTemplateKeysDTO.BODY],
  [userEmailTemplateKeysAPIDTO.EXPECTED_DATA]: userEmailTemplate?.[
    userEmailTemplateKeysDTO.EXPECTED_DATA
  ]?.map((userEmailTemplate) =>
    _makeUserEmailTemplateExpectedDataAPIDTO(userEmailTemplate)
  ),
  [userEmailTemplateKeysAPIDTO.TO]: _makeUserEmailTemplateDestinationAPIDTO({
    userEmailTemplateDestination:
      userEmailTemplate?.[userEmailTemplateKeysDTO.TO]
  }),
  [userEmailTemplateKeysAPIDTO.CC]: _makeUserEmailTemplateDestinationAPIDTO({
    userEmailTemplateDestination:
      userEmailTemplate?.[userEmailTemplateKeysDTO.CC]
  }),
  [userEmailTemplateKeysAPIDTO.BCC]: _makeUserEmailTemplateDestinationAPIDTO({
    userEmailTemplateDestination:
      userEmailTemplate?.[userEmailTemplateKeysDTO.BCC]
  })
});

const makeUserEmailTemplatesDTO = ({
  userEmailTemplates = [],
  _makeUserEmailTemplateDTO = makeUserEmailTemplateDTO
} = {}) =>
  userEmailTemplates?.map((userEmailTemplate) =>
    _makeUserEmailTemplateDTO({ userEmailTemplate })
  ) || [];

export {
  makeUserEmailTemplateDestinationEmailDTO,
  makeUserEmailTemplateDestinationEmailAPIDTO,
  makeUserEmailTemplateDestinationRoleDTO,
  makeUserEmailTemplateDestinationRoleAPIDTO,
  makeUserEmailTemplateDestinationUserDTO,
  makeUserEmailTemplateDestinationUserAPIDTO,
  makeUserEmailTemplateDestinationDTO,
  makeUserEmailTemplateDestinationAPIDTO,
  makeUserEmailTemplateExpectedDataDTO,
  makeUserEmailTemplateExpectedDataAPIDTO,
  makeUserEmailTemplateDTO,
  makeUserEmailTemplateAPIDTO,
  makeUserEmailTemplatesDTO
};
