import {
  actionStepType,
  expectedDataTypes,
  expectedDataTypesText,
  ifBlockSuccessStepType,
  variableAssignmentType,
  variableType,
  variableTypeText
} from "./types.constant";
import { activityState, validity } from "./usageState.constant";
import { numericBinaryOperator, operations } from "./operations.constant";

const countTypeMostRecentOption = [
  { value: "0", text: "Only Most Recent", label: "Only Most Recent" }
];

const countTypeAllOption = [{ value: "1", text: "All", label: "All" }];

const emptyOption = {
  value: "",
  text: "",
  selected: true,
  disabled: true,
  hidden: true
};

const countTypeDashboardOptions = [
  emptyOption,
  ...countTypeMostRecentOption,
  ...countTypeAllOption
];

const variableTypeOptions = [
  {
    value: expectedDataTypes.THIRD_PARTY,
    text: expectedDataTypesText.THIRD_PARTY
  },
  {
    value: expectedDataTypes.IDD_IDD_PLUS,
    text: expectedDataTypesText.IDD_IDD_PLUS
  },
  {
    value: expectedDataTypes.DDQ_TASK,
    text: expectedDataTypesText.DDQ_TASK
  }
];

const numericBinaryOperatorOptions = [
  emptyOption,
  {
    text: numericBinaryOperator.PLUS,
    value: numericBinaryOperator.PLUS
  },
  {
    text: numericBinaryOperator.MINUS,
    value: numericBinaryOperator.MINUS
  },
  {
    text: numericBinaryOperator.MULTIPLICATION,
    value: numericBinaryOperator.MULTIPLICATION
  },
  {
    text: numericBinaryOperator.DIVIDER,
    value: numericBinaryOperator.DIVIDER
  }
];

const variableValueTypeOptions = [
  {
    ...emptyOption,
    text: "Select..."
  },
  { text: variableTypeText.NUMERIC, value: variableType.NUMERIC },
  { text: variableTypeText.BOOLEAN, value: variableType.BOOLEAN },
  { text: variableTypeText.STRING, value: variableType.STRING },
  {
    text: variableTypeText.EXPRESSION,
    value: variableType.EXPRESSION
  },
  {
    text: variableTypeText.PROXY,
    value: variableType.PROXY
  },
  {
    text: variableTypeText.DATE_TIME,
    value: variableType.DATE_TIME
  }
];

const variableAssignmentOptions = [
  { text: "Existing", value: variableAssignmentType.ENTITY },
  { text: "New", value: variableAssignmentType.LITERAL }
];

const variableAssignmentOptionsWithNoValue = [
  ...variableAssignmentOptions,
  {
    text: "No value",
    value: variableAssignmentType.NO_VALUE
  }
];

const variableAssignmentOptionsWithNoValueAndEmptyOptions = [
  emptyOption,
  ...variableAssignmentOptionsWithNoValue
];

const variableAssignmentOptionsWithEmptyOption = [
  {
    ...emptyOption,
    text: "Select..."
  },
  ...variableAssignmentOptions
];

const stepAccordionOptionValue = {
  ADD_STEP_ABOVE: "addStepAbove",
  ADD_STEP_BELOW: "addStepBelow",
  DELETE_STEP: "deleteStep",
  ADD_COMMENT: "addComment",
  REMOVE_COMMENT: "removeComment"
};

const stepAccordionOptions = [
  { text: "Add Comment", value: stepAccordionOptionValue.ADD_COMMENT },
  { text: "Add step above", value: stepAccordionOptionValue.ADD_STEP_ABOVE },
  {
    text: "Add step below",
    value: stepAccordionOptionValue.ADD_STEP_BELOW
  },
  { text: "Delete step", value: stepAccordionOptionValue.DELETE_STEP }
];

const triggerStepAccordionOptions = [
  { text: "Delete step", value: stepAccordionOptionValue.DELETE_STEP }
];

const variableValueBooleanOptions = [
  {
    ...emptyOption,
    text: "Select..."
  },
  { text: "Yes", value: validity.TRUE },
  { text: "No", value: validity.FALSE }
];

const comparatorOptions = [
  {
    text: "Equals",
    value: operations.EQUALS,
    isInverse: false,
    allowedTypes: [
      variableType.STRING,
      variableType.NUMERIC,
      variableType.BOOLEAN,
      variableType.COUNTRY,
      variableType.NUMERIC_11_POINT_SCALE,
      variableType.ETHIXBASE_RISK_SCALE,
      variableType.DATE_TIME
    ]
  },
  {
    text: "Not equals",
    value: operations.NOT_EQUALS,
    isInverse: true,
    allowedTypes: [
      variableType.STRING,
      variableType.NUMERIC,
      variableType.BOOLEAN,
      variableType.COUNTRY,
      variableType.NUMERIC_11_POINT_SCALE,
      variableType.ETHIXBASE_RISK_SCALE,
      variableType.DATE_TIME
    ]
  },
  {
    text: "Contains",
    value: operations.CONTAINS,
    isInverse: false,
    allowedTypes: [variableType.STRING]
  },
  {
    text: "Does not contain",
    value: operations.NOT_CONTAINS,
    isInverse: true,
    allowedTypes: [variableType.STRING]
  },
  {
    text: "Greater than",
    value: operations.GREATER_THAN,
    isInverse: false,
    allowedTypes: [variableType.NUMERIC]
  },
  {
    text: "After",
    value: operations.GREATER_THAN,
    isInverse: false,
    allowedTypes: [variableType.DATE_TIME]
  },
  {
    text: "Less than",
    value: operations.LESS_THAN,
    isInverse: false,
    allowedTypes: [variableType.NUMERIC]
  },
  {
    text: "Before",
    value: operations.LESS_THAN,
    isInverse: false,
    allowedTypes: [variableType.DATE_TIME]
  },
  {
    text: "Greater than or equal to",
    value: operations.GREATER_THAN_OR_EQUAL_TO,
    isInverse: false,
    allowedTypes: [variableType.NUMERIC]
  },
  {
    text: "Equal or after",
    value: operations.GREATER_THAN_OR_EQUAL_TO,
    isInverse: false,
    allowedTypes: [variableType.DATE_TIME]
  },
  {
    text: "Less than or equal to",
    value: operations.LESS_THAN_OR_EQUAL_TO,
    isInverse: false,
    allowedTypes: [variableType.NUMERIC]
  },
  {
    text: "Equal or before",
    value: operations.LESS_THAN_OR_EQUAL_TO,
    isInverse: false,
    allowedTypes: [variableType.DATE_TIME]
  },
  {
    text: "Has Value",
    value: operations.HAS_VALUE,
    isInverse: true,
    allowedTypes: [
      variableType.STRING,
      variableType.NUMERIC,
      variableType.BOOLEAN,
      variableType.COUNTRY,
      variableType.NUMERIC_11_POINT_SCALE,
      variableType.ETHIXBASE_RISK_SCALE,
      variableType.DATE_TIME
    ]
  },
  {
    text: "Has no value",
    value: operations.HAS_NO_VALUE,
    isInverse: false,
    allowedTypes: [
      variableType.STRING,
      variableType.NUMERIC,
      variableType.BOOLEAN,
      variableType.COUNTRY,
      variableType.NUMERIC_11_POINT_SCALE,
      variableType.ETHIXBASE_RISK_SCALE,
      variableType.DATE_TIME
    ]
  },
  {
    text: "In",
    value: operations.IN,
    isInverse: false,
    allowedTypes: [variableType.STRING]
  },
  {
    text: "Has one or more in",
    value: operations.HAS_ONE_OR_MORE_IN,
    isInverse: false,
    allowedTypes: [{ type: variableType.STRING, isArray: true }]
  }
];

const joinOperatorOptions = [
  {
    text: "and",
    value: operations.AND,
    selected: true
  },
  {
    text: "or",
    value: operations.OR
  }
];

const actionStepOptions = [
  {
    ...emptyOption,
    text: "Select step type"
  },
  { text: actionStepType.SET, value: actionStepType.SET },
  { text: actionStepType.IF, value: actionStepType.IF },
  { text: actionStepType.END, value: actionStepType.END },
  { text: actionStepType.TRIGGER, value: actionStepType.TRIGGER }
];

const comparatorOptionsMap = {
  [operations.EQUALS]: {
    value: operations.EQUALS,
    isInverse: false
  },
  [operations.NOT_EQUALS]: {
    value: operations.EQUALS,
    isInverse: true
  },
  [operations.CONTAINS]: {
    value: operations.CONTAINS,
    isInverse: false
  },
  [operations.NOT_CONTAINS]: {
    value: operations.CONTAINS,
    isInverse: true
  },
  [operations.GREATER_THAN]: {
    value: operations.GREATER_THAN,
    isInverse: false
  },
  [operations.LESS_THAN]: {
    value: operations.LESS_THAN,
    isInverse: false
  },
  [operations.GREATER_THAN_OR_EQUAL_TO]: {
    value: operations.GREATER_THAN_OR_EQUAL_TO,
    isInverse: false
  },
  [operations.LESS_THAN_OR_EQUAL_TO]: {
    value: operations.LESS_THAN_OR_EQUAL_TO,
    isInverse: false
  },
  [operations.HAS_VALUE]: {
    value: operations.EQUALS,
    isInverse: true
  },
  [operations.HAS_NO_VALUE]: {
    value: operations.EQUALS,
    isInverse: false
  },
  [operations.IN]: {
    value: operations.IN,
    isInverse: false
  },
  [operations.HAS_ONE_OR_MORE_IN]: {
    value: operations.HAS_ONE_OR_MORE_IN,
    isInverse: false
  }
};

const ifSuccessStepOptions = [
  {
    ...emptyOption,
    text: "Select step type"
  },
  { text: actionStepType.SET, value: ifBlockSuccessStepType.SET },
  { text: actionStepType.END, value: ifBlockSuccessStepType.END },
  { text: actionStepType.TRIGGER, value: ifBlockSuccessStepType.TRIGGER }
];

const activityStateOptions = [
  {
    ...emptyOption,
    text: "Select automation state"
  },
  {
    text: activityState.ACTIVE,
    value: validity.TRUE
  },
  {
    text: activityState.INACTIVE,
    value: validity.FALSE
  }
];

const predefinedLiteralOptions = {
  [variableType.DATE_TIME]: [
    {
      value: "NOW",
      text: "Now",
      isArray: false,
      type: variableType.DATE_TIME,
      subType: variableType.DATE_TIME
    }
  ]
};

const decimalOptions = [
  {
    ...emptyOption,
    text: "Select ..."
  },
  {
    text: "0",
    value: 0
  },
  {
    text: "1",
    value: 1
  },
  {
    text: "2",
    value: 2
  },
  {
    text: "3",
    value: 3
  },
  {
    text: "4",
    value: 4
  },
  {
    text: "5",
    value: 5
  },
  {
    text: "6",
    value: 6
  },
  {
    text: "7",
    value: 7
  },
  {
    text: "8",
    value: 8
  },
  {
    text: "9",
    value: 9
  }
];

export {
  activityStateOptions,
  ifSuccessStepOptions,
  comparatorOptionsMap,
  actionStepOptions,
  joinOperatorOptions,
  comparatorOptions,
  variableValueBooleanOptions,
  triggerStepAccordionOptions,
  stepAccordionOptions,
  stepAccordionOptionValue,
  variableAssignmentOptionsWithEmptyOption,
  variableAssignmentOptionsWithNoValueAndEmptyOptions,
  variableAssignmentOptionsWithNoValue,
  variableAssignmentOptions,
  variableValueTypeOptions,
  numericBinaryOperatorOptions,
  variableTypeOptions,
  countTypeDashboardOptions,
  emptyOption,
  predefinedLiteralOptions,
  countTypeAllOption,
  countTypeMostRecentOption,
  decimalOptions
};
