<template>
  <div class="questionares-page">
    <BaseText
      :text="$t('questionnaires.title')"
      :size="typographySize.BODY_TEXT_BOLD"
      class="questionares-page__title"
    />
    <AppLoader v-if="isLoading" class="text-center" small />
    <b-row
      class="ml-2 mt-2 mr-2"
      style="height: calc(100vh - 200px); overflow: auto"
    >
      <b-col
        cols="3"
        v-for="(questionnaire, index) in questionnaires"
        :key="index"
      >
        <b-card bg-variant="default" class="mb-2" style="height: 350px">
          <b-card-text class="my-3">
            <font-awesome-icon
              icon="file-signature"
              size="5x"
              class="supplier__icon"
            />
          </b-card-text>
          <b-card-text class="mb-1">{{
            getFormTitle(questionnaire)
          }}</b-card-text>
          <b-card-text
            class="mb-0 text-capitalize"
            v-if="questionnaire.comp_name_eng && questionnaire.country"
            >({{ questionnaire.comp_name_eng }} -
            {{ questionnaire.country }})</b-card-text
          >
          <b-card-text class="mb-0 text-capitalize"
            >{{ $t("questionnaires.requestedDate") }}:
            {{
              dateFormat(questionnaire.onboarding_stage_tasks_created_at)
            }}</b-card-text
          >
          <b-card-text
            class="mb-2"
            v-html="getQuestionnaireStatus(questionnaire.task_status)"
          ></b-card-text>
          <b-button
            v-if="
              questionnaire.task_status < 4 || questionnaire.task_status == 14
            "
            class="mr-2"
            variant="outline-primary"
            @click="
              getTaskForm(
                questionnaire.form_id,
                questionnaire.type,
                questionnaire.id,
                questionnaire.task_title
              )
            "
          >
            {{ getQuestionnaireButtonLabel(questionnaire.task_status) }}
          </b-button>
          <b-button
            v-else-if="questionnaire.task_status == 4"
            variant="outline-dark"
            @click="downloadPdf(questionnaire, '/download/questionnaire')"
          >
            <font-awesome-icon icon="file-pdf" />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppLoader from "@/components/Loader/Loader";
import { Helper } from "@/helpers";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { makeAuthorizationHeader, HTTP } from "@/utils";
import BaseText from "@/atoms/BaseText/BaseText";
import { typographySize } from "@/constants";

export default {
  name: "MyTPPQuestionannires",
  components: {
    AppLoader,
    BaseText
  },
  data() {
    return {
      questionnaires: [],
      typographySize
    };
  },
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage",
      isLoading: "isLoading"
    }),
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  watch: {
    selectedLanguage() {
      this.breadcrumbs = [
        {
          text: this.$t("dashboard"),
          href: "/onboarding/dashboard"
        },

        {
          text: this.$t("questionnaires.title"),
          active: true
        }
      ];
    }
  },
  mounted() {
    this.getTasks();
  },
  methods: {
    async getTasks() {
      try {
        let result = await HTTP(
          "post",
          "v1/mytpp/tasks",
          {
            userId: this.userId
          },
          makeAuthorizationHeader(),
          null,
          true
        );
        this.training = result.data.training;
        this.questionnaires = result.data.questionnaires;
        this.exams = result.data.exams;
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },

    dateFormat(data) {
      moment.locale("en");
      return moment.utc(data).local().format("LLLL");
    },
    getFormTitle(form) {
      let formSchema = JSON.parse(form.schema || form.form_schema);
      return formSchema.formTitle[this.selectedLanguage];
    },
    makeToast() {
      const h = this.$createElement;
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        this.$t("toast.generatePDF"),
        h("b-spinner", { props: { type: "grow", small: true } })
      ]);
      this.$bvToast.toast([vNodesMsg], {
        variant: "warning",
        title: "PDF",
        autoHideDelay: 5000
      });
    },

    async downloadPdf(questionnaire, route) {
      let formDefaultLanguage = localStorage.getItem("formDefaultLanguage");
      let taskId = questionnaire.id;
      let title = `${questionnaire.task_title}.pdf`;
      this.makeToast();
      HTTP(
        "post",
        `v1/mytpp${route}/${taskId}`,
        { formDefaultLanguage },
        makeAuthorizationHeader(),
        null,
        false,
        "blob"
      )
        .then((response) => {
          if (window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([response.data]);
            // IE11
            window.navigator.msSaveOrOpenBlob(blob, title);
            Helper.makeToast(
              this.$bvToast,
              "success",
              this.$t("toast.successPDF")
            );
            return false;
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", title); // or any other extension
            document.body.appendChild(link);
            link.click();
            if (response) {
              Helper.makeToast(
                this.$bvToast,
                "success",
                this.$t("toast.successPDF")
              );
            }
          }
        })
        .catch(() => {
          Helper.makeToast(
            this.$bvToast,
            "danger",
            this.$t("toast.errorPDF")
            // "PDF not downloaded please try again"
          );
        });
    },

    getTaskFormType(value) {
      let type = "Questionniare";
      if (value == 2) {
        type = "Survey";
      } else if (value == 3) {
        type = "Training";
      } else if (value == 4) {
        type = "Exam";
      }
      return type;
    },

    getQuestionnaireStatus(taskStatus) {
      let status = "";
      if (taskStatus == 4) {
        status = `<span class="badge badge-success">${this.$t(
          "questionnaires.batchText"
        )}</span>`;
      } else if (taskStatus == 1) {
        status = `<span class="badge badge-warning">${this.$t(
          "questionnaires.batchInProgressText"
        )}</span>`;
      } else if (taskStatus == 0) {
        status = `<span class="badge badge-primary">${this.$t(
          "questionnaires.batchNotStartedext"
        )}</span>`;
      } else if (taskStatus == 8) {
        status = `<span class="badge badge-danger">${this.$t(
          "questionnaires.batchCancelledtext"
        )}</span>`;
      } else if (taskStatus == 9) {
        status = `<span class="badge badge-danger">${this.$t(
          "questionnaires.batchDeclinedtext"
        )}</span>`;
      } else if (taskStatus == 10) {
        status = `<span class="badge badge-danger">${this.$t(
          "questionnaires.batchSelfEvidencetext"
        )}</span>`;
      } else if (taskStatus == 14) {
        status = `<span class="badge badge-warning">${this.$t(
          "questionnaires.batchInProgressPauseRemindersText"
        )}</span>`;
      }
      return status;
    },

    getQuestionnaireButtonLabel(taskStatus) {
      let status = "";

      if (taskStatus == 0) {
        status = this.$t("startBtnText");
      } else if (taskStatus == 1 || taskStatus == 14) {
        status = this.$t("continueBtnText");
      }
      return status;
    },

    age(value) {
      return moment(value).fromNow();
    },

    async getTaskForm(formId, formType, taskId, task_title) {
      localStorage.removeItem("taskId");
      localStorage.removeItem("activeFormId");
      localStorage.removeItem("activeFormSchema");
      localStorage.removeItem("taskFormData");
      localStorage.removeItem("activeTaskStatus");
      localStorage.setItem("taskId", taskId);
      localStorage.setItem("task_title", task_title);
      if (formType !== 1) {
        let result = await HTTP(
          "post",
          `v1/mytpp/task/${taskId}`,
          {
            userId: this.userId,
            formType
          },
          makeAuthorizationHeader(),
          null,
          true
        );

        if (result.data.task && result.data.task) {
          let form = result.data.task.form.schema;
          let form_data = JSON.parse(result.data.task.form_data);
          //

          if (form_data && form_data.hasOwnProperty("user_data")) {
            //
            this.$store.state.getUserFormSubmitArray = form_data.user_data;
          }
          localStorage.setItem("activeFormSchema", form);
          localStorage.setItem(
            "activeTaskStatus",
            result.data.task.task_status
          );

          let newRoute = "";
          if (formType == 1) {
            // if (form_data.user_data && form_data.user_data.length) {
            // 	localStorage.setItem(
            // 		"taskFormData",
            // 		form_data.user_data
            // 	);
            // }
            if (form_data && form_data.hasOwnProperty("user_consent")) {
              localStorage.setItem("userConsent", form_data.user_consent);
            } else {
              localStorage.setItem("userConsent", 0);
            }

            newRoute = "/user-form";
          } else if (formType == 2) {
            newRoute = "/user-form";
          }
          this.$router.push({
            path: newRoute,
            params: {
              from: "/mytpp"
            }
          });
        } else {
          this.$route.push("/user-form");
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.supplier__icon {
  color: $bright-gray;
}
.questionares-page {
  &:deep(.questionares-page__title) {
    font-size: 30px;
  }
}
</style>
