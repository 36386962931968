<template>
  <div class="change-log">
    <div v-if="isDnbDisabled" class="change-log__disable-text">
      <BaseText
        text="To enable please upgrade to Premium Corporate Data."
        data-test-id="change-log__disable-text--dnb-disabled"
        :size="typographySize.BODY_TEXT_ITALIC"
      />
    </div>
    <div v-else-if="!isDnbVerified" class="change-log__disable-text">
      <BaseText
        text="Premium Corporate Data is enabled on your system but hasn't been used to verify this Third Party so there is no additional data available."
        data-test-id="change-log__disable-text--dnb-not-verified"
        :size="typographySize.BODY_TEXT_ITALIC"
      />
    </div>
    <div
      v-else-if="!hasOrganisationsChangeLogData"
      class="change-log__disable-text"
    >
      <BaseText
        text="No recorded changes."
        data-test-id="change-log__disable-text--no-recorded-changes"
        :size="typographySize.BODY_TEXT_ITALIC"
      />
    </div>
    <div v-else class="change-log__row" data-test-id="change-log__row">
      <h6 class="change-log__title">Change log</h6>
      <div class="change-log__row-col">
        <div
          class="change-log__row-col-table"
          v-for="(tableRow, index) in tablesData"
          :key="index"
        >
          <StickyTable :table-headers="tableHeaders" :table-rows="tableRow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import {
  typographySize,
  changeLogConstants,
  changeLogProhibitedValues
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  findDeepDifferences,
  getAllDeepObjectsWithKey
} from "./logic/ChangeLog.logic";
import moment from "moment";
import "moment/locale/es";
import { startCase } from "lodash";
import {
  isNumber,
  removeInstancesFromString,
  isObject,
  isString
} from "@/utils";

export default {
  name: "ChangeLog",
  components: {
    StickyTable,
    BaseText
  },
  props: {
    organisationsChangeLogData: {
      type: Array,
      default: () => []
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ],
      tablesData: [],
      typographySize
    };
  },
  created() {
    this.setTablesData();
  },
  computed: {
    hasOrganisationsChangeLogData() {
      return !!this.organisationsChangeLogData?.length;
    },
    timeSortedOrganisationsChangeLogData() {
      if (!this.hasOrganisationsChangeLogData) {
        return [];
      }

      return this.organisationsChangeLogData.sort(
        (changeObjectOne, changeObjectTwo) =>
          new Date(changeObjectTwo?.timestamp) -
          new Date(changeObjectOne?.timestamp)
      );
    }
  },
  methods: {
    setTablesData() {
      const changes = this.splitChangeLogDataIntoIndividualRows();

      this.tablesData = changes?.map((data) => {
        const tableRows = this.makeTableRowsBasedOnChangeType(data);

        return this.makeTableRowData(tableRows);
      });
    },
    makeChangesObject(data = {}, value = {}) {
      return {
        title: this.createChangeTitle(data?.key, value?.path),
        previousValue: this.setChangeValueAsText(
          value?.value?.[changeLogConstants.KEY_ORIGINAL]
        ),
        newValue: this.setChangeValueAsText(
          value?.value?.[changeLogConstants.KEY_UPDATED]
        ),
        timestamp: this.setFormattedDate(data?.timestamp),
        type: value?.value?.type
      };
    },
    splitChangeLogDataIntoIndividualRows() {
      const changes = [];

      this.timeSortedOrganisationsChangeLogData?.forEach((data) => {
        const values = this.findChangeItems(data?.previous, data?.current);
        const mappedValues = values.map((value) =>
          this.makeChangesObject(data, value)
        );

        changes.push(...mappedValues);
      });

      return changes;
    },
    makeTableRowsBasedOnChangeType({
      title = "",
      timestamp = "",
      previousValue = "",
      newValue = "",
      type = ""
    } = {}) {
      const tableRowData = [
        {
          label: "Change to",
          value: title
        },
        {
          label: "Date of update",
          value: timestamp
        }
      ];

      switch (type) {
        case changeLogConstants.VALUE_UPDATED:
          tableRowData.splice(
            1,
            0,
            ...[
              {
                label: "Previous value",
                value: previousValue
              },
              {
                label: "New value",
                value: newValue
              }
            ]
          );
          break;
        case changeLogConstants.VALUE_DELETED:
          tableRowData.splice(1, 0, {
            label: "Deleted record",
            value: previousValue
          });
          break;
        case changeLogConstants.VALUE_CREATED:
          tableRowData.splice(1, 0, {
            label: "Created record",
            value: newValue
          });
          break;
      }

      return tableRowData;
    },
    makeTableRowData(dataMap) {
      return dataMap?.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label
          },
          styles: {
            border: "none",
            width: "140px",
            verticalAlign: "baseline",
            paddingLeft: "8px"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            text: value,
            size: typographySize.BODY_TEXT_BOLD
          },
          styles: {
            border: "none"
          }
        }
      }));
    },
    setFormattedDate(value) {
      return value ? moment(value).format("DD/MM/YY") : "-";
    },
    findChangeItems(previous, current) {
      const differences = findDeepDifferences({
        item1: previous,
        item2: current
      });
      const changeList = getAllDeepObjectsWithKey({
        item: differences,
        targetKey: changeLogConstants.KEY_ORIGINAL
      });

      return changeList;
    },
    setChangeValueAsText(value) {
      if (isString(value) && value?.length) {
        return value;
      }

      if (isNumber(value)) {
        return value?.toString();
      }

      if (isObject(value)) {
        return this.listObjectValues(value);
      }

      if (Array.isArray(value) && value?.length) {
        return this.handleArrayDataDisplay(value);
      }

      return "-";
    },
    listObjectValues(value = {}) {
      return (
        Object?.entries(value)?.map(
          ([key, value]) =>
            `${this.createChangeTitle(key)}: ${this.setChangeValueAsText(
              value
            )}`
        ) || []
      );
    },
    handleArrayDataDisplay(arrayProperties = []) {
      return (
        arrayProperties?.map((value) => this.setChangeValueAsText(value)) || []
      );
    },
    createChangeTitle(key = "", path = []) {
      if (!Array.isArray(path)) {
        return key;
      }

      if (typeof key !== "string") {
        return "";
      }

      const filteredPath = this.filterNumericalValuesFromPathList([
        key,
        ...path
      ]);
      const readableKeys = this.makePathKeysIntoReadableFormat(filteredPath);

      return readableKeys?.join(" - ");
    },
    filterNumericalValuesFromPathList(list = []) {
      return list?.filter((property) => isNaN(parseInt(property))) || [];
    },
    makePathKeysIntoReadableFormat(path = []) {
      return (
        path?.map((key) => {
          key = this.removeProhibitedValuesFromKeyTitle(key);

          return startCase(key);
        }) || []
      );
    },
    removeProhibitedValuesFromKeyTitle(key = "") {
      // As we're not allowed to display references of duns or DnB to users (https://ethixbase.atlassian.net/browse/PD-5481)
      // These need to be removed from the title of any property.

      const prohibitedValues = [
        changeLogProhibitedValues.DNB,
        changeLogProhibitedValues.DUNS
      ];

      return removeInstancesFromString(key, prohibitedValues);
    }
  }
};
</script>
<style lang="scss" scoped>
.change-log {
  &__disable-text {
    text-align: center;
  }

  &__title {
    background-color: $grey;
    padding: 10px 8px;
    margin: 0;
  }

  &__row {
    &-col {
      &-table {
        margin: 16px 0;
        border-bottom: 2px solid $grey;
      }
    }
  }
}
</style>
