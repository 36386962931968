
class Helper {
    makeToast(show, variant = null, message, title) {
      show.toast(message, {
        // title: `Clone ${variant || "default"}`,
        title: title || 'Alert',
        variant,
        solid: true,
        autoHideDelay: 500,
      });
   }
}
export default new Helper();
