<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    @blur="addLabelLanguageToField(language)"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="First Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="firstNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Last Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="lastNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Date Of Birth label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="dateOfBirthTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Percentage Owned Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="percentageOwnedTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Country of birth Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="countryOfBirthTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Passport Number Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="passportNumberTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Free Form Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="freeFormTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Helper Text------------------- -->
        <b-tab title="Helper Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabHelperTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for First Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="firstNameHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text for Last Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="lastNameHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Date Of Birth</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="dateOfBirthHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Percentage Owned</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="percentageOwnedHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Country Of Birth</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="countryOfBirthHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Passport Number</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="passportNumberHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Free Form</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="freeFormHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Place Holder Text------------------- -->
        <b-tab title="Place Holder">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabPlaceHolderTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>PlaceHolder Text for First Name</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="firstNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>PlaceHolder Text for Last Name</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="lastNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>PlaceHolder Text for Percentage Owned</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="percentageOwnedPlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>PlaceHolder Text for Passport Number</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="passportNumberPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>PlaceHolder Text for Free Form</label>
                    <b-form-input
                      @blur="addPlaceHolderLanguageToField(language)"
                      v-model="freeFormPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation Message------------------- -->
        <b-tab title="Validation Message">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabValidationMessageClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for First Name</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="firstNameValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message for Last Name</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="lastNameValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Date Of Birth</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="dateOfBirthValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Percentage Owned</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="percentageOwnedValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Country Of Birth</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="countryOfBirthValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Passport Number</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="passportNumberValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Free Form</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="freeFormValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <b-tab title="Configure">
          <b-form-group class="w-50 mb-5" label="Selec County List Type">
            <v-select
              :id="fieldData.id"
              searchable
              clearable
              :options="renderOptions"
              label="text"
              class="mb-5"
              v-model="fieldData.selectedListTypeIndex"
            />
          </b-form-group>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for First Name</label>
                <b-form-input v-model="firstNamePostName"></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for Last Name</label>
                <b-form-input v-model="lastNamePostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Date Of Birth</label>
                <b-form-input v-model="dateOfBirthPostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Percentage Owned</label>
                <b-form-input v-model="percentageOwnedPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Country Of Birth</label>
                <b-form-input v-model="countryOfBirthPostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Passport Number</label>
                <b-form-input v-model="passportNumberPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Free Form</label>
                <b-form-input v-model="freeFormPostName"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <!-- ----------------Advance------------------->
        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <b-form-checkbox
                class="text-left mb-3"
                v-model="firstNameDisabled"
                >Disable First Name?</b-form-checkbox
              >
              <b-form-checkbox class="text-left mb-3" v-model="lastNameDisabled"
                >Disable Last Name?</b-form-checkbox
              >
              <b-form-checkbox
                class="text-left mb-3"
                v-model="dateOfBirthDisabled"
                >Disable Date Of Birth?</b-form-checkbox
              >
              <b-form-checkbox
                class="text-left mb-3"
                v-model="percentageOwnedDisabled"
                >Disable Percentage Owned?</b-form-checkbox
              >
              <b-form-checkbox
                class="text-left mb-3"
                v-model="countryOfBirthDisabled"
                >Disable Country Of Birth</b-form-checkbox
              >
              <b-form-checkbox
                class="text-left mb-3"
                v-model="passportNumberDisabled"
                >Disable Passport Number</b-form-checkbox
              >
              <b-form-checkbox
                class="text-left mb-3"
                @input="freeFormDisabledCheck"
                v-model="freeFormDisabled"
                >Disable Free Form</b-form-checkbox
              >
              <b-form-checkbox
                class="text-left mb-3"
                value="1"
                unchecked-value="0"
                v-model="fieldData.isNaFeature"
                >Disable N/A Feature</b-form-checkbox
              >
            </b-col>
          </b-row>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFirstName"
                >Is required First Name?</b-form-checkbox
              >
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireLastName"
                >Is required Last Name?</b-form-checkbox
              >
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireDateOfBirth"
                >Is required Date Of Birth?</b-form-checkbox
              >
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequirePercentageOwned"
                >Is required Percentage Owned?</b-form-checkbox
              >
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireCountryOfBirth"
                >Is required Country Of Birth</b-form-checkbox
              >
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequirePassportNumber"
                >Is required Passport Number</b-form-checkbox
              >
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFreeForm"
                >Is required Free Form</b-form-checkbox
              >
            </b-col>
          </div>
        </b-tab>

        <!-- for conditions -->
        <b-tab title="Advance">
          <b-col cols="12" class="mr-0">
            <b-form-checkbox
              v-model="isConditional"
              name="conditional"
              value="1"
              unchecked-value="0"
              >Is Conditional?</b-form-checkbox
            >

            <div v-if="isConditional == 1">
              <b-row>
                <table class="table table-sm">
                  <thead>
                    <tr class="text-center">
                      <th>No</th>
                      <th>When Field</th>
                      <th>Has Value</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(selectedOpt, index) in fieldData.conditions"
                      :key="selectedOpt.id"
                    >
                      <td>{{ index }}</td>
                      <td>
                        <b-form-input disabled :value="selectedOpt.label" />
                      </td>
                      <td>
                        <b-form-input disabled :value="selectedOpt.value" />
                      </td>
                      <td>
                        <b-button
                          variant="outline-danger"
                          class="btn-sm ml-1"
                          @click="onRemoveConditionOpt(selectedOpt.id, index)"
                        >
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </td>
                    </tr>

                    <tr
                      v-for="(noOfOption, index) in noOfConditions"
                      :key="index"
                    >
                      <td>
                        <v-select
                          v-model="conditionalField"
                          class="mb-2"
                          :options="conditionalSectionElementLables"
                          lablel="label"
                          @input="getSelectedElement"
                        ></v-select>
                      </td>
                      <td>
                        <v-select
                          class="mb-2"
                          label="text"
                          v-model="conditionalFieldValue"
                          :options="selectedElementOption"
                        ></v-select>
                      </td>

                      <td>
                        <b-button
                          class="btn-sm"
                          variant="outline-primary"
                          @click="setFieldConditions"
                          >Apply</b-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-row>
            </div>
          </b-col>
        </b-tab>
        <!-- ----------------Advanced Date------------------- -->
        <b-tab title="Advanced Date">
          <div class="text-left mt-4">
            <div class="col-12">
              <b-form-checkbox
                v-model="disabledMinDateInput"
                class="col-md-12 my-1"
                @change="onToggleDatesCheckbox('min')"
                >Disable Past Dates</b-form-checkbox
              >
              <b-collapse :visible="!disabledMinDateInput">
                <div class="form-group col-md-6">
                  <label for>Minimum date</label>
                  <b-form-input type="date" v-model="minDate"></b-form-input>
                </div>
              </b-collapse>

              <b-form-checkbox
                class="col-md-12 my-1"
                @change="onToggleDatesCheckbox('max')"
                v-model="disabledMaxDateInput"
                >Disable Future Dates</b-form-checkbox
              >
              <b-collapse :visible="!disabledMaxDateInput">
                <div class="form-group col-md-6">
                  <label for>Maximum date</label>
                  <b-form-input type="date" v-model="maxDate"></b-form-input>
                </div>
              </b-collapse>

              <b-form-group label="Select Date Format">
                <b-form-radio-group
                  @input="onSetDateFormat(selectedDateFormat)"
                  stacked
                  v-model="selectedDateFormat"
                  :options="optionsDateFormatt"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import uid from "uid";
import list from "@/utils/countryListTypes.json";

export default {
  name: "IndividualShareHoldersSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },

  data() {
    return {
      transalationLanguage: "english",
      // Input General

      description: null,
      // labels
      label: null,
      firstNameTitle: null,
      lastNameTitle: null,
      dateOfBirthTitle: null,
      percentageOwnedTitle: null,
      countryOfBirthTitle: null,
      passportNumberTitle: null,
      freeFormTitle: null,

      // helper text
      firstNameHelperText: null,
      lastNameHelperText: null,
      dateOfBirthHelperText: null,
      percentageOwnedHelperText: null,
      countryOfBirthHelperText: null,
      passportNumberHelperText: null,
      freeFormHelperText: null,

      // Place Holder Text
      firstNamePlaceHolderText: null,
      lastNamePlaceHolderText: null,
      percentageOwnedPlaceHolderText: null,
      passportNumberPlaceHolderText: null,
      freeFormPlaceHolderText: null,

      // Validation Message
      firstNameValidationMessage: null,
      lastNameValidationMessage: null,
      dateOfBirthValidationMessage: null,
      percentageOwnedValidationMessage: null,
      countryOfBirthValidationMessage: null,
      passportNumberValidationMessage: null,
      freeFormValidationMessage: null,

      // hidden/diabled
      firstNameDisabled: false,
      lastNameDisabled: false,
      dateOfBirthDisabled: false,
      percentageOwnedDisabled: false,
      countryOfBirthDisabled: false,
      passportNumberDisabled: false,
      freeFormDisabled: true,

      // validation
      isRequireFirstName: true,
      isRequireLastName: true,
      isRequireDateOfBirth: true,
      isRequirePercentageOwned: true,
      isRequirePassportNumber: true,
      isRequireCountryOfBirth: true,
      isRequireFreeForm: false,

      // attributes
      firstNamePostName: null,
      lastNamePostName: null,
      dateOfBirthPostName: null,
      percentageOwnedPostName: null,
      countryOfBirthPostName: null,
      passportNumberPostName: null,
      freeFormPostName: null,

      // conditions
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      selectedElementOption: [],
      conditionalField: null,
      conditionalFieldValue: null,
      condition: {},
      isConditional: 0,
      noOfConditions: [1],

      // advance date
      minDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.minDate
          ? this.fieldData.advanceDate.minDate
          : undefined,
      maxDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.maxDate
          ? this.fieldData.advanceDate.maxDate
          : undefined,
      disabledMinDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMinDateInput
          ? this.fieldData.advanceDate.disabledMinDateInput
          : false,
      disabledMaxDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMaxDateInput
          ? this.fieldData.advanceDate.disabledMaxDateInput
          : false,
      selectedDateFormat: this.fieldData.format || "default",
      optionsDateFormatt: [
        { text: "Default", value: "default" },
        { text: "Monday 12 Aug 2019", value: "dddd MMMM Do YYYY" },
        { text: "September 19, 2019", value: "LL" },
        { text: "dd/mm/yyyy", value: "DD/MM/YYYY" },
        { text: "mm/dd/yyyy", value: "MM/DD/YYYY" }
      ]
    };
  },

  created() {
    EventBus.$on("onSaveSettings", () => {
      // this.addLabelLanguageToField(this.transalationLanguage);
      // this.addHelperTextLanguageToField(this.transalationLanguage);
      // this.addPlaceHolderLanguageToField(this.transalationLanguage);
      // this.addValidationMessageLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
      this.addDateAdvacne();
    });
  },

  mounted() {
    this.setFiledsLabelText();
    this.setFiledsHelperText();
    this.setFiledsPlaceHolderText();
    this.setFiledsValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();
    this.getCurrentSectionList();
  },

  computed: {
    ...mapGetters({ form: "form1" }),
    renderOptions() {
      return list;
    }
  },

  methods: {
    onSelectListType(e) {
      this.fieldData.selectedListTypeIndex = e;
    },

    freeFormDisabledCheck() {},
    setFiledsLabelText() {
      this.description = this.fieldData.description[this.transalationLanguage];

      this.label = this.fieldData.label[this.transalationLanguage];

      this.firstNameTitle =
        this.fieldData.firstNameLabel[this.transalationLanguage];
      this.lastNameTitle =
        this.fieldData.lastNameLabel[this.transalationLanguage];
      this.dateOfBirthTitle =
        this.fieldData.dateOfBirthLabel[this.transalationLanguage];

      this.percentageOwnedTitle =
        this.fieldData.percentageOwnedLabel[this.transalationLanguage];

      this.countryOfBirthTitle =
        this.fieldData.countryOfBirthLabel[this.transalationLanguage];

      this.passportNumberTitle =
        this.fieldData.passportNumberLabel[this.transalationLanguage];

      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    setFiledsHelperText() {
      // Helper Text

      this.firstNameHelperText =
        this.fieldData.firstNameHelperText[this.transalationLanguage];

      this.lastNameHelperText =
        this.fieldData.lastNameHelperText[this.transalationLanguage];

      this.dateOfBirthHelperText =
        this.fieldData.dateOfBirthHelperText[this.transalationLanguage];

      this.percentageOwnedHelperText =
        this.fieldData.percentageOwnedHelperText[this.transalationLanguage];

      this.countryOfBirthHelperText =
        this.fieldData.countryOfBirthHelperText[this.transalationLanguage];

      this.passportNumberHelperText =
        this.fieldData.passportNumberHelperText[this.transalationLanguage];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    setFiledsPlaceHolderText() {
      // Place Holder Text

      this.firstNamePlaceHolderText =
        this.fieldData.firstNamePlaceholderText[this.transalationLanguage];

      this.lastNamePlaceHolderText =
        this.fieldData.lastNamePlaceholderText[this.transalationLanguage];

      this.percentageOwnedPlaceHolderText =
        this.fieldData.percentageOwnedPlaceholderText[
          this.transalationLanguage
        ];

      this.passportNumberPlaceHolderText =
        this.fieldData.passportNumberPlaceholderText[this.transalationLanguage];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    setFiledsValidationMessage() {
      // Validation Message

      this.firstNameValidationMessage =
        this.fieldData.firstNameValidationMessage[this.transalationLanguage];

      this.lastNameValidationMessage =
        this.fieldData.lastNameValidationMessage[this.transalationLanguage];

      this.dateOfBirthValidationMessage =
        this.fieldData.dateOfBirthValidationMessage[this.transalationLanguage];

      this.percentageOwnedValidationMessage =
        this.fieldData.percentageOwnedValidationMessage[
          this.transalationLanguage
        ];

      this.countryOfBirthValidationMessage =
        this.fieldData.countryOfBirthValidationMessage[
          this.transalationLanguage
        ];

      this.passportNumberValidationMessage =
        this.fieldData.passportNumberValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    setFiledsAttributes() {
      (this.firstNamePostName =
        this.fieldData.attributes && this.fieldData.attributes.firstNamePostName
          ? this.fieldData.attributes.firstNamePostName
          : undefined),
        (this.lastNamePostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.lastNamePostName
            ? this.fieldData.attributes.lastNamePostName
            : undefined),
        (this.dateOfBirthPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.dateOfBirthPostName
            ? this.fieldData.attributes.dateOfBirthPostName
            : undefined),
        (this.percentageOwnedPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.percentageOwnedPostName
            ? this.fieldData.attributes.percentageOwnedPostName
            : undefined),
        (this.countryOfBirthPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.countryOfBirthPostName
            ? this.fieldData.attributes.countryOfBirthPostName
            : undefined),
        (this.passportNumberPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.passportNumberPostName
            ? this.fieldData.attributes.passportNumberPostName
            : undefined),
        (this.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : undefined);
    },

    setFiledsAdvance() {
      (this.firstNameDisabled =
        this.fieldData.advance && this.fieldData.advance.firstNameDisabled),
        (this.lastNameDisabled =
          this.fieldData.advance && this.fieldData.advance.lastNameDisabled),
        (this.dateOfBirthDisabled =
          this.fieldData.advance && this.fieldData.advance.dateOfBirthDisabled),
        (this.percentageOwnedDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.percentageOwnedDisabled),
        (this.countryOfBirthDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.countryOfBirthDisabled),
        (this.passportNumberDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.passportNumberDisabled),
        (this.freeFormDisabled =
          this.fieldData.advance && this.fieldData.advance.freeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireFirstName =
        this.fieldData.validation &&
        this.fieldData.validation.isRequireFirstName),
        (this.isRequireLastName =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireLastName),
        (this.isRequireDateOfBirth =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireDateOfBirth),
        (this.isRequirePercentageOwned =
          this.fieldData.validation &&
          this.fieldData.validation.isRequirePercentageOwned),
        (this.isRequireCountryOfBirth =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireCountryOfBirth),
        (this.isRequirePassportNumber =
          this.fieldData.validation &&
          this.fieldData.validation.isRequirePassportNumber),
        (this.isRequireFreeForm =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireFreeForm);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.description = this.fieldData.description[this.transalationLanguage];
      this.firstNameTitle =
        this.fieldData.firstNameLabel[this.transalationLanguage];
      this.lastNameTitle =
        this.fieldData.lastNameLabel[this.transalationLanguage];
      this.dateOfBirthTitle =
        this.fieldData.dateOfBirthLabel[this.transalationLanguage];
      this.percentageOwnedTitle =
        this.fieldData.percentageOwnedLabel[this.transalationLanguage];
      this.countryOfBirthTitle =
        this.fieldData.countryOfBirthLabel[this.transalationLanguage];
      this.passportNumberTitle =
        this.fieldData.passportNumberLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    tabHelperTextClick(language) {
      // Helper Text

      this.transalationLanguage = language.toLowerCase();
      this.firstNameHelperText =
        this.fieldData.firstNameHelperText[this.transalationLanguage];

      this.lastNameHelperText =
        this.fieldData.lastNameHelperText[this.transalationLanguage];

      this.dateOfBirthHelperText =
        this.fieldData.dateOfBirthHelperText[this.transalationLanguage];

      this.percentageOwnedHelperText =
        this.fieldData.percentageOwnedHelperText[this.transalationLanguage];

      this.countryOfBirthHelperText =
        this.fieldData.countryOfBirthHelperText[this.transalationLanguage];

      this.passportNumberHelperText =
        this.fieldData.passportNumberHelperText[this.transalationLanguage];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    tabPlaceHolderTextClick(language) {
      // Place Holder Text

      this.transalationLanguage = language.toLowerCase();
      this.firstNamePlaceHolderText =
        this.fieldData.firstNamePlaceholderText[this.transalationLanguage];

      this.lastNamePlaceHolderText =
        this.fieldData.lastNamePlaceholderText[this.transalationLanguage];

      this.percentageOwnedPlaceHolderText =
        this.fieldData.percentageOwnedPlaceholderText[
          this.transalationLanguage
        ];

      this.passportNumberPlaceHolderText =
        this.fieldData.passportNumberPlaceholderText[this.transalationLanguage];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    tabValidationMessageClick(language) {
      // Validation Message

      this.transalationLanguage = language.toLowerCase();
      this.firstNameValidationMessage =
        this.fieldData.firstNameValidationMessage[this.transalationLanguage];

      this.lastNameValidationMessage =
        this.fieldData.lastNameValidationMessage[this.transalationLanguage];

      this.dateOfBirthValidationMessage =
        this.fieldData.dateOfBirthValidationMessage[this.transalationLanguage];

      this.percentageOwnedValidationMessage =
        this.fieldData.percentageOwnedValidationMessage[
          this.transalationLanguage
        ];

      this.countryOfBirthValidationMessage =
        this.fieldData.countryOfBirthValidationMessage[
          this.transalationLanguage
        ];

      this.passportNumberValidationMessage =
        this.fieldData.passportNumberValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;
      this.fieldData.firstNameLabel[tempKey] = this.firstNameTitle;
      this.fieldData.lastNameLabel[tempKey] = this.lastNameTitle;
      this.fieldData.dateOfBirthLabel[tempKey] = this.dateOfBirthTitle;
      this.fieldData.percentageOwnedLabel[tempKey] = this.percentageOwnedTitle;
      this.fieldData.countryOfBirthLabel[tempKey] = this.countryOfBirthTitle;
      this.fieldData.passportNumberLabel[tempKey] = this.passportNumberTitle;
      this.fieldData.freeFormLabel[tempKey] = this.freeFormTitle;
    },

    addHelperTextLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // Helper text

      this.fieldData.firstNameHelperText[tempKey] = this.firstNameHelperText;
      this.fieldData.lastNameHelperText[tempKey] = this.lastNameHelperText;
      this.fieldData.dateOfBirthHelperText[tempKey] =
        this.dateOfBirthHelperText;
      this.fieldData.percentageOwnedHelperText[tempKey] =
        this.percentageOwnedHelperText;
      this.fieldData.countryOfBirthHelperText[tempKey] =
        this.countryOfBirthHelperText;
      this.fieldData.passportNumberHelperText[tempKey] =
        this.passportNumberHelperText;
      this.fieldData.freeFormHelperText[tempKey] = this.freeFormHelperText;
    },

    addPlaceHolderLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // Place Holder text

      this.fieldData.firstNamePlaceholderText[tempKey] =
        this.firstNamePlaceHolderText;
      this.fieldData.lastNamePlaceholderText[tempKey] =
        this.lastNamePlaceHolderText;
      this.fieldData.percentageOwnedPlaceholderText[tempKey] =
        this.percentageOwnedPlaceHolderText;
      this.fieldData.passportNumberPlaceholderText[tempKey] =
        this.passportNumberPlaceHolderText;
      this.fieldData.freeFormPlaceholderText[tempKey] =
        this.freeFormPlaceHolderText;
    },

    addValidationMessageLanguageToField(language) {
      let tempKey = language.toLowerCase();
      // Validation Message

      this.fieldData.firstNameValidationMessage[tempKey] =
        this.firstNameValidationMessage;
      this.fieldData.lastNameValidationMessage[tempKey] =
        this.lastNameValidationMessage;
      this.fieldData.dateOfBirthValidationMessage[tempKey] =
        this.dateOfBirthValidationMessage;
      this.fieldData.percentageOwnedValidationMessage[tempKey] =
        this.percentageOwnedValidationMessage;
      this.fieldData.countryOfBirthValidationMessage[tempKey] =
        this.countryOfBirthValidationMessage;
      this.fieldData.passportNumberValidationMessage[tempKey] =
        this.passportNumberValidationMessage;
      this.fieldData.freeFormValidationMessage[tempKey] =
        this.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireFirstName: this.firstNameDisabled
          ? false
          : this.isRequireFirstName,
        isRequireLastName: this.lastNameDisabled
          ? false
          : this.isRequireLastName,
        isRequireDateOfBirth: this.dateOfBirthDisabled
          ? false
          : this.isRequireDateOfBirth,
        isRequirePercentageOwned: this.percentageOwnedDisabled
          ? false
          : this.isRequirePercentageOwned,
        isRequirePassportNumber: this.passportNumberDisabled
          ? false
          : this.isRequirePassportNumber,
        isRequireCountryOfBirth: this.countryOfBirthDisabled
          ? false
          : this.isRequireCountryOfBirth,
        isRequireFreeForm: this.freeFormDisabled
          ? false
          : this.isRequireFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        firstNameDisabled: this.firstNameDisabled,
        lastNameDisabled: this.lastNameDisabled,
        dateOfBirthDisabled: this.dateOfBirthDisabled,
        percentageOwnedDisabled: this.percentageOwnedDisabled,
        countryOfBirthDisabled: this.countryOfBirthDisabled,
        passportNumberDisabled: this.passportNumberDisabled,
        freeFormDisabled: this.freeFormDisabled
      };
      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        firstNamePostName: this.firstNamePostName,
        lastNamePostName: this.lastNamePostName,
        dateOfBirthPostName: this.dateOfBirthPostName,
        percentageOwnedPostName: this.percentageOwnedPostName,
        countryOfBirthPostName: this.countryOfBirthPostName,
        passportNumberPostName: this.passportNumberPostName,
        freeFormPostName: this.freeFormPostName
      };
      Object.assign(this.fieldData, { attributes });
    },

    getCurrentSectionList() {
      let conditionalSectionElements =
        this.form.formSection[this.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (element.inputType === "radio") {
            // return element
            let dd = {
              label: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
    },

    setFieldConditions() {
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceFieldId: this.conditionalField.element.id,
          targetFieldId: this.fieldData.id,
          value: this.conditionalFieldValue,
          label: this.conditionalField.label
        };
        let elements = [
          ...this.form.formSection[this.sectionIndex].formElements
        ];
        elements.map((element) => {
          if (element.options) {
            element.options.map((opt) => {
              if (
                opt.value === this.conditionalFieldValue &&
                this.conditionalField.element.id === element.id
              ) {
                element.conditionalFields.push(condition);
              }
            });
          }
        });
        this.fieldData.isConditional = this.isConditional;
        this.fieldData.isHidden = true;
        // Object.assign(this.fieldData, { condition });
        this.fieldData.conditions.push(condition);
        this.conditionalFieldValue = null;
        this.conditionalField = null;
      }
    },

    onRemoveConditionOpt(optId, index) {
      if (optId && index !== -1) {
        this.fieldData.conditions.splice(index, 1);
      }
    },

    onToggleDatesCheckbox(key) {
      if (key == "min") {
        this.minDate = undefined;
      }
      if (key == "max") {
        this.maxDate = undefined;
      }
    },

    addDateAdvacne() {
      let yesterday = new Date(Date.now() - 864e5); // testing required
      let tomorrow = new Date();
      // tomorrow.setDate(new Date().getDate() + 1);

      let advanceDate = {
        minDate:
          this.minDate && !this.disabledMinDateInput
            ? this.minDate
            : this.disabledMinDateInput
            ? yesterday
            : undefined,
        maxDate:
          this.maxDate && !this.disabledMaxDateInput
            ? this.maxDate
            : this.disabledMaxDateInput
            ? tomorrow
            : undefined,
        disabledMinDateInput: this.disabledMinDateInput,
        disabledMaxDateInput: this.disabledMaxDateInput
      };
      Object.assign(this.fieldData, { advanceDate });
    },

    onSetDateFormat() {
      let format = this.selectedDateFormat;
      Object.assign(this.fieldData, { format });
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
