<template>
  <b-card class="mt-3 json-container" header="JSON Data Preview">
    <pre class="m-0 text-left">{{form1}}</pre>
  </b-card>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: "FormJsonContainer",
  components: {},
  computed:{
    ...mapState([
      'form1'
    ])
  },
  // props: {
  //   form: Object
  // }
};
</script>
<style scoped>
.json-container{
  /* height: 500px; */
   height: calc(100vh - 150px);
  overflow-y: auto
}
</style>

