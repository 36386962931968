<template>
  <div class="ml-5">
    <b-card no-body class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0">
      <b-navbar toggleable="lg">
        <b-navbar-brand href="#" class="text-dark">
          <strong style="color: #3b3e66"
            >Action Details for {{ companyName }}</strong
          >
        </b-navbar-brand>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <!-- Reload button -->
            <b-button
              variant="outline-info"
              size="sm"
              class="mr-4"
              @click="reloadTable"
            >
              <font-awesome-icon icon="sync" />
            </b-button>

            <!-- search bar -->
            <b-form-group>
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>
      <!-- Action details table data -->
      <b-table
        sticky-header="calc(100vh - 295px)"
        :busy.sync="isLoading"
        bordered
        ref="table"
        id="action-details-table"
        :fields="staticFields"
        :items="getTableData"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        no-sort-reset
      >
        <template v-slot:cell(lastRequestedDate)="data">
          <p>{{ lastTriggeredDate(data.item.lastRequestedDate) }}</p>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { allActions } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import { Helper } from "@/helpers";
import { mapState } from "vuex";

export default {
  name: "ActionDetails",
  data() {
    return {
      allActions,
      companyName: null,
      isLoading: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "title", label: "Action", sortable: false },
        { key: "count", label: "Count", sortable: false },
        {
          key: "lastRequestedDate",
          label: "Last Date Triggered",
          sortable: false
        }
      ],
      items: []
    };
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  methods: {
    lastTriggeredDate(value) {
      moment.locale("en");
      return moment(value).format("DD/MM/YYYY");
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "action-details-table");
    },

    // Get action details table data
    async getTableData() {
      try {
        let formData = {
          company_id: this.$route.params.id,
          userId: this.userId
        };
        let result = await HTTP(
          "post",
          "thirdparty/account/actions/count",
          formData,
          makeAuthorizationHeader()
        );
        //
        return result.data;
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    }
  }
};
</script>

<style></style>
