import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import Vuelidate from "vuelidate";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import Sortable from "vue-sortable";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueCodemirror from "vue-codemirror";
import VueScrollTo from "vue-scrollto";
import axios from "axios";
import VueAxios from "vue-axios";
import vSelect from "vue-select";
import VueRouter from "vue-router";
import router from "@/routes";
import CountryFlag from "vue-country-flag";
import VueTour from "vue-tour";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import VueNumber from "vue-number-animation";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import store from "@/store";
import "codemirror/lib/codemirror.css";
import "vue-select/dist/vue-select.css";
import "vue-tour/dist/vue-tour.css";
import i18n from "./i18n";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import VueHighcharts from "vue-highcharts";
import highchartsMore from "highcharts/highcharts-more";
import highcharts3d from "highcharts/highcharts-3d";
import highchartsFunnel from "highcharts/modules/funnel";
import stockInit from "highcharts/modules/stock";
import highmaps from "highcharts/modules/map";
import solidGaugeInit from "highcharts/modules/solid-gauge";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueObserveVisibility, {
  ObserveVisibility
} from "vue-observe-visibility";
import InputColorPicker from "vue-native-color-picker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
import VueHtmlToPaper from "vue-html-to-paper";
import PortalVue from "portal-vue";

dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
library.add(fas);

// make sure we can use it in our components
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
};

Vue.use(VueHtmlToPaper, options);
Vue.use(VueRouter);
Vue.use(VueTour);
Vue.component("quill-editor", quillEditor);
Vue.component("multiselect", Multiselect);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);
Vue.component("country-flag", CountryFlag);
Vue.component("vue-number-input", VueNumberInput);
Vue.use(VueScrollTo);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Sortable);
Vue.use(Vuelidate);
Vue.use(VueNumber);
Vue.use(VueSidebarMenu);
Vue.use(VueObserveVisibility);
Vue.directive("observe-visibility", ObserveVisibility);
Vue.use(HighchartsVue);
highmaps(Highcharts);
highchartsMore(Highcharts);
highchartsFunnel(Highcharts);
stockInit(Highcharts);
highcharts3d(Highcharts);
solidGaugeInit(Highcharts);
Vue.use(VueHighcharts, { Highcharts });
Vue.use(PortalVue);
Vue.use(VueCodemirror, {});
Vue.use(InputColorPicker);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
