<template>
	<b-modal
		v-model="loading"
		centered
		no-close-on-backdrop
		no-close-on-esc
		no-enforce-focus
		hide-footer
		hide-header-close
		id="modal-loader"
		size="xl"
		title="Please wait..!"
	>
		<b-spinner type="grow" class="mr-3"></b-spinner>
		<span v-if="count == 0" class="animate-slide-from-left">{{content}}</span>
		<span v-if="count == 1" class="animate-slide-from-left">{{content2}}</span>
		<span v-if="count == 2" class="animate-slide-from-left">{{content3}}</span>
	</b-modal>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
	name: "AppLoaderModal",
	data() {
		return {
			loading: false,
			count: 0,
			interval: null
		};
	},
	mounted() {
		this.loading = this.isMulti ? this.isLoadingMulti : this.isLoading;
	},
	watch: {
		isLoadingMulti(a) {
			this.loading = a;
			if (a) {
				this.count = 0;
				this.showCount();
			} else {
				clearInterval(this.interval);
			}
		}
	},
	computed: {
		...mapState({
			isLoading: "isLoading",
			isLoadingMulti: "isLoadingMulti"
		}),
		...mapGetters({
			isLoading: "isLoading",
			isLoadingMulti: "isLoadingMulti"
		})
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {
		showCount() {
			let self = this;
			this.interval = setInterval(() => {
				self.count = self.count == 2 ? 2 : self.count + 1;
			}, 3000);
		}
	},

	props: {
		content: String,
		content2: String,
		content3: String,
		isMulti: Boolean,
		title: String
	}
};
</script>
<style scoped>

.animate-slide-from-left {
	position: relative;
	animation: fromLeft 1s;
	opacity: 1;
}
@keyframes fromLeft {
	from {
		left: -5px;
		opacity: 0;
	}
	to {
		left: 0;
		opacity: 1;
	}
}

</style>
