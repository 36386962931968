const erafCasting = {
  entities: [
    {
      name: "Third Party",
      entity: "third-party"
    },
    {
      name: "Instant Due Diligence Report",
      entity: "instant-due-diligence-report"
    },
    {
      name: "Modern Slavery Questionnaire v 3",
      entity: "ddq-task",
      context: {
        formId: 620
      },
      referenceUrl: "v1/eraf/companies/848/references/questionnaires/620"
    },
    {
      name: "KYC - Products 1 devtest",
      entity: "ddq-task",
      context: {
        formId: 681
      },
      referenceUrl: "v1/eraf/companies/848/references/questionnaires/681"
    }
  ],
  types: [
    {
      type: "string",
      isArray: true,
      comparators: [
        {
          comparator: "equals",
          types: [
            {
              isArray: true,
              type: "string"
            }
          ]
        },
        {
          comparator: "not_equals",
          types: [
            {
              isArray: true,
              type: "string"
            }
          ]
        },
        {
          comparator: "contains",
          types: [
            {
              isArray: true,
              type: "string"
            },
            {
              isArray: false,
              type: "string"
            }
          ]
        },
        {
          comparator: "not_contains",
          types: [
            {
              isArray: true,
              type: "string"
            },
            {
              isArray: false,
              type: "string"
            }
          ]
        },
        {
          comparator: "in",
          types: [
            {
              isArray: true,
              type: "string"
            }
          ]
        }
      ],
      castTo: []
    },
    {
      type: "string",
      isArray: false,
      comparators: [
        {
          comparator: "equals",
          types: [
            {
              isArray: false,
              type: "string"
            }
          ]
        },
        {
          comparator: "not_equals",
          types: [
            {
              isArray: false,
              type: "string"
            }
          ]
        },
        {
          comparator: "contains",
          types: [
            {
              isArray: false,
              type: "string"
            }
          ]
        },
        {
          comparator: "not_contains",
          types: [
            {
              isArray: false,
              type: "string"
            }
          ]
        },
        {
          comparator: "in",
          types: [
            {
              isArray: true,
              type: "string"
            }
          ]
        }
      ],
      castTo: []
    },
    {
      type: "datetime",
      isArray: false,
      comparators: [
        {
          comparator: "equals",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "not_equals",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "greaterthan",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "lessthan",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "greaterthanorequalto",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "lessthanorequalto",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        }
      ],
      castTo: [
        {
          type: "numeric",
          subType: "numeric",
          isArray: false
        },
        {
          type: "numeric",
          subType: "expression",
          isArray: false
        }
      ]
    },
    {
      type: "numeric",
      isArray: false,
      comparators: [
        {
          comparator: "equals",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "not_equals",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "greaterthan",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "lessthan",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "greaterthanorequalto",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "lessthanorequalto",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "has_value",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        },
        {
          comparator: "has_no_value",
          types: [
            {
              isArray: false,
              type: "datetime"
            },
            {
              isArray: false,
              type: "numeric"
            }
          ]
        }
      ],
      castTo: [
        {
          type: "datetime",
          subType: "datetime",
          isArray: false
        },
        {
          type: "numeric",
          subType: "expression",
          isArray: false
        },
        {
          type: "numeric",
          subType: "ethiXbase Risk Scale",
          isArray: false
        },
        {
          type: "numeric",
          subType: "4 Point scale",
          isArray: false
        },
        {
          type: "numeric",
          subType: "11 point numeric scale",
          isArray: false
        }
      ]
    }
  ]
};

export { erafCasting };
