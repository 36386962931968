<template>
  <div>
    <div>
      <div style="display: flex; justify-content: space-between">
        <!-- help icon -->
        <div class="ml-auto mr-2" style="display: flex; align-items: center">
          <a
            v-if="platformKnowledgeCentreLink"
            :href="platformKnowledgeCentreLink"
            target="_blank"
          >
            <b-img
              style="width: 100%"
              :src="require(`@/assets/questionmarkimage.png`)"
              v-b-tooltip.hover
            />
          </a>
        </div>
        <!-- help icon -->

        <!-- edit user view settings dropdown -->
        <div v-if="$route.params.id > 0" style="padding: 10px">
          <b-dropdown
            size="sm"
            variant="outline"
            toggle-class="text-decoration-none"
            no-caret
            dropleft
          >
            <template #button-content>
              <font-awesome-icon icon="ellipsis-h" />
            </template>
            <b-dropdown-item-button
              class="text-secondary"
              @click="updatePlatformView()"
              >Save Settings</b-dropdown-item-button
            >
            <b-dropdown-item-button
              class="text-secondary"
              @click="openEditViewModal()"
              >View Settings</b-dropdown-item-button
            >
            <b-dropdown-item-button
              class="text-secondary"
              @click="showDeleteModal()"
              >Delete View</b-dropdown-item-button
            >
          </b-dropdown>
        </div>
        <!-- edit user view settings dropdown -->
      </div>
      <div style="overflow: hidden auto; height: calc(100vh - 210px)">
        <!-- advance filter options -->
        <b-collapse v-model="advanceFilterForms">
          <b-card class="mb-2 bg-light">
            <b-row class="text-center">
              <b-col cols="12">
                <h4>{{ $t("eb360.manageThirdParties.filterResult") }}</h4>
                <div class="text-right">
                  <b-btn
                    @click="resetAdvanceFilter"
                    class="mr-2"
                    variant="outline-danger"
                    size="sm"
                    >Clear Filters</b-btn
                  >
                  <b-button
                    @click="applyThirdPartyFilter"
                    :disabled="callFilterApi"
                    variant="outline-success"
                    size="sm"
                    >{{ $t("applyBtn") }}</b-button
                  >
                </div>
              </b-col>
            </b-row>
            <b-row style="text-align: left">
              <b-col cols="4">
                <b-form-group label="Date From:">
                  <b-form-datepicker
                    v-model="advanceFilter.dateFrom"
                    @input="setFilterUploadDateFrom"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit'
                    }"
                    placeholder=""
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Date To:">
                  <b-form-datepicker
                    v-model="advanceFilter.dateTo"
                    @input="setFilterUploadDateTo"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit'
                    }"
                    placeholder=""
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Type">
                  <v-select
                    label="text"
                    v-model="advanceFilter.eventType"
                    :options="optionsFilterTypeResults"
                    :clearable="true"
                    :multiple="true"
                    @input="selectFilterTypeResults"
                  ></v-select>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Relates to">
                  <b-form-input
                    v-model="advanceFilter.source"
                    @input="setFilterSource"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Description">
                  <b-form-input
                    v-model="advanceFilter.description"
                    @input="setFilterDescription"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Tags">
                  <v-select
                    label="text"
                    v-model="advanceFilter.tags"
                    :options="optionsFilterTagsAlert"
                    :clearable="true"
                    :multiple="true"
                    @input="selectFilterTagsAlert"
                  ></v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
        <!-- advance filter options -->

        <!-- alert when list is filtered -->
        <b-alert
          style="margin: 0px 0px 1px 0px; background: #4d9de0"
          v-model="resetFilter"
          variant="warning"
        >
          <b style="color: white">You are viewing a filtered list</b>
        </b-alert>
        <!-- alert when list is filtered -->

        <b-navbar toggleable="lg" type="dark" variant="light">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-form>
                <!-- search bar -->
                <b-form class="mr-2" onSubmit="return false;">
                  <b-input-group size="sm">
                    <b-form-input
                      v-model="filter"
                      :placeholder="
                        $t('eb360.manageThirdParties.searchPlaceholder')
                      "
                      @keyup.enter="filterTable = true"
                      v-b-tooltip.hover
                      title="It only searches the 'Relates to' field."
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        variant="outline-success"
                        @click="filterTable = true"
                        >{{ $t("searchBtn") }}</b-button
                      >
                      <b-button @click="setFIlterClear">{{
                        $t("clearBtnText")
                      }}</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
                <!-- search bar -->

                <!-- advance filter button -->
                <b-btn
                  class="mr-2"
                  size="sm"
                  variant="outline-dark"
                  @click="advanceFilterForms = !advanceFilterForms"
                  :style="
                    resetFilter ? 'background-color: #4D9DE0;color:#fff' : ''
                  "
                >
                  <font-awesome-icon icon="filter" />
                  <!-- Filter -->
                </b-btn>
                <!-- advance filter button -->

                <!-- reload table button -->
                <b-button
                  @click="reloadTable"
                  variant="outline-info"
                  size="sm"
                  class="mr-2"
                >
                  <font-awesome-icon icon="sync" />
                </b-button>
                <!-- reload table button -->
              </b-nav-form>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <b-overlay :show="isLoading" rounded="sm">
          <!-- Event log table data -->
          <div v-if="showTableData">
            <b-table
              bordered
              sticky-header="calc(100vh - 235px)"
              ref="table"
              id="event-log-table"
              :fields="staticFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items="getTableItems"
              responsive="sm"
              small
              outlined
              :filter="filterTable ? filter : null"
              :per-page="perPage"
              :current-page="currentPage"
              @scroll.native="handleScroll"
            >
              <!-- dynamic width for every column -->
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width: field.width ? field.width + 'px' : colWidth
                  }"
                />
              </template>
              <!-- dynamic width for every column -->

              <template v-slot:cell(created_at)="data">
                {{ updateDateFormat(data.item.created_at) }}
              </template>
              <template v-slot:cell(type)="data">
                <div>
                  {{ data.item.event_type ? data.item.event_type.text : "" }}
                </div>
              </template>
              <template v-slot:cell(source)="data">
                <div v-if="data.item.event_type && data.item.event_type.id">
                  <router-link
                    v-if="data.item.source_url"
                    :to="data.item.source_url"
                  >
                    {{ data.item.source }}
                  </router-link>
                  <p v-else>{{ data.item.source }}</p>
                </div>
              </template>
              <template v-slot:cell(description)="data">
                {{ data.item.description }}
              </template>
              <template v-slot:cell(tags)="data">
                <div
                  style="display: flex; justify-content: flex-start"
                  v-if="data.item.event_type && data.item.event_type.tags"
                >
                  <div
                    v-for="(tags, tagsIndex) in data.item.event_log_all_tags"
                    :key="tagsIndex"
                  >
                    <b-badge variant="light">{{ tags.tag }}</b-badge>
                  </div>
                </div>
              </template>
            </b-table>
            <b-overlay :show="tableLoaderTop" rounded="sm" />

            <b-overlay :show="tableLoader && !tableLoaderTop" rounded="sm" />
            <div class="text-center" v-if="totalRows == 0">
              <b> No data available </b>
            </div>
          </div>
          <!-- Event log table data -->
        </b-overlay>
      </div>

      <b-row>
        <b-col cols="3"> </b-col>
        <b-col cols="6" v-if="totalRows > 0">
          <p class="text-center">
            <b-button
              class="m-1"
              size="sm"
              @click="goToPreviousPage"
              :disabled="this.currentPage <= 1"
              variant="light"
            >
              <font-awesome-icon icon="chevron-left" />
            </b-button>
            {{ $t("eb360.manageThirdParties.resultRecords") }}
            <b>{{ fromRows }}</b>
            {{ $t("eb360.manageThirdParties.toRecords") }}
            <b>{{ toRows }}</b>
            {{ $t("eb360.manageThirdParties.ofRecords") }}
            <b>{{ totalRows }}</b>
            <b-button
              class="m-1 ml-2"
              size="sm"
              @click="goToNextPage"
              :disabled="this.currentPage >= this.last_page"
              variant="light"
            >
              <font-awesome-icon icon="chevron-right" />
            </b-button>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-modal ref="modal-delete" title="Are you sure?" @ok="deleteView">
      <p class="my-4">Are you sure you want to delete view?</p>
    </b-modal>
  </div>
</template>

<script>
import EventLogJson from "@/utils/icon.json";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import eventLogStaticFields from "@/utils/eventLogStaticFields.json";
import { EventBus } from "@/event-bus.js";
import {
  urls,
  eventTypeId,
  actionName,
  dateFormatOptionsTypes
} from "@/constants";
import { mapActions, mapState } from "vuex";

export default {
  name: "EventLog",
  data() {
    return {
      urls,
      staticFields: eventLogStaticFields.fields,
      sortBy: "created_at",
      sortDesc: true,
      filterTable: false,
      filter: "",
      isLoading: false,
      perPage: 50,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 75, 100],
      fromRows: 0,
      toRows: 0,
      totalRows: 0,
      EventLogJsonData: EventLogJson.eventLog,
      colWidth: "200px",
      last_page: 0,
      tableLoaderTop: false,
      tableLoader: false,
      showTableData: false,
      advanceFilterForms: false,
      callFilterApi: true,
      advanceFilter: {
        dateFrom: null,
        dateTo: null,
        eventType: [],
        source: null,
        description: null,
        tags: []
      },
      optionsFilterTypeResults: [],
      optionsFilterTagsAlert: [],
      filterAdvance: {
        dateFrom: null,
        dateTo: null,
        eventType: [],
        source: null,
        description: null,
        tags: []
      },
      resetFilter: false,
      isLoadingEventLogData: false,
      tableItems: [],
      viewDetail: null,
      filterData: [],
      setFilterData: [],
      eventTypeId
    };
  },
  computed: {
    ...mapState({
      platformKnowledgeCentreLink: (state) =>
        state.platformSettings.platformKnowledgeCentreLink,
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.onReload();
      }
    },
    filter(newValue, oldValue) {
      if (
        newValue !== oldValue &&
        !Array.isArray(this.filterData) &&
        this.filterData?.filter !== undefined
      ) {
        this.filterData.filter = newValue;
      }
    }
  },
  mounted() {
    this.onReload();
  },
  methods: {
    ...mapActions({
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS
    }),
    async getTableItems() {
      if (!this.isLoadingEventLogData) {
        let tableData;

        if (this.resetFilter) {
          tableData = await this.getEventLogDataWithAdvanceFilter();
        } else {
          tableData = await this.eventLogData();
        }
        // Prevent multiple calls of same endpoint
        setTimeout(() => {
          this.isLoadingEventLogData = false;
        }, 1000);

        return tableData;
      }
    },
    onReload() {
      this.showTableData = false;
      this.filterData = [];
      this.setFilterData = [];
      this.resetFilter = false;
      this.filter = "";
      this.filterTable = false;
      this.optionsFilterTypeResults = [];
      this.optionsFilterTagsAlert = [];
      this.advanceFilterForms = false;
      this.filterAdvance = {
        dateFrom: null,
        dateTo: null,
        eventType: [],
        source: null,
        description: null,
        tags: []
      };
      this.advanceFilter = {
        dateFrom: null,
        dateTo: null,
        eventType: [],
        source: null,
        description: null,
        tags: []
      };
      this.getEventTpe();
      this.getEventTags();

      if (this.$route.params.id > 0) {
        this.getConfiguration();
      } else {
        this.showTableData = true;
        this.reloadTable();
      }
    },
    // get filters value for user view
    async getConfiguration() {
      try {
        let result = await HTTP(
          "get",
          `v1/platform/view/detail/${this.$route.params.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data.data) {
          this.viewDetail = result.data.data;
          let configuartion = JSON.parse(result.data.data.configuration);
          if (configuartion.filters.length == 0) {
            this.showTableData = true;
          } else if (configuartion && configuartion.filters) {
            this.resetFilter = true;
            this.filterData = configuartion.filters;

            if (configuartion.filters.upload_date_from_filter) {
              this.advanceFilter.dateFrom =
                configuartion.filters.upload_date_from_filter;
            }
            if (configuartion.filters.upload_date_to_filter) {
              this.advanceFilter.dateTo =
                configuartion.filters.upload_date_to_filter;
            }
            if (configuartion.filters.source_filter) {
              this.advanceFilter.source = configuartion.filters.source_filter;
            }
            if (configuartion.filters.description_filter) {
              this.advanceFilter.description =
                configuartion.filters.description_filter;
            }
            if (configuartion.filters.tags_filter.length > 0) {
              this.optionsFilterTagsAlert.map((val) => {
                let index = configuartion.filters.tags_filter.findIndex(
                  (x) => x == val.value
                );

                if (index != -1) {
                  this.advanceFilter.tags.push(val);
                  this.filterAdvance.tags.push(val.value);
                }
              });
            }
            if (configuartion.filters.type_filter.length > 0) {
              this.optionsFilterTypeResults.map((val) => {
                let index = configuartion.filters.type_filter.findIndex(
                  (x) => x == val.value
                );

                if (index != -1) {
                  this.advanceFilter.eventType.push(val);
                  this.filterAdvance.eventType.push(val.value);
                }
              });
            }
            this.currentPage = 1;
            this.showTableData = true;
          }
        }
      } catch (error) {
        return [error];
      }
    },
    showDeleteModal() {
      this.$refs["modal-delete"].show();
    },
    openEditViewModal() {
      EventBus.$emit(
        "addNewViewModal",
        this.viewDetail.title,
        this.viewDetail.view_type,
        this.$route.params.id
      );
    },
    async deleteView() {
      this.isLoading = true;
      let result = HTTP(
        "post",
        `v1/platform/view/delete/${this.$route.params.id}`,
        { company_id: this.companyId },
        makeAuthorizationHeader()
      );
      return result
        .then(async (response) => {
          if (response.data.success == 1) {
            this.$bvToast.toast(response.data.message, {
              title: "Done",
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
            if (response.data.platformViews.length === 0) {
              this.$store.state.sidebarMenu = [];
            }
            await this.fetchAdditionalVouchers();
            this.$router.push(urls.EVENT_LOG);
          } else if (response.data.success == 0) {
            this.$bvToast.toast(response.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
          }
          this.isLoading = false;
          return response;
        })
        .catch((error) => {
          this.isLoading = false;
          return error;
        });
    },

    // update platform view settings
    async updatePlatformView() {
      let configuredColumns = {
        visibleFields: [],
        filters: this.setFilterData
      };
      let formData = {
        company_id: this.companyId,
        configuration: configuredColumns,
        title: null,
        created_by_id: this.userId,
        view_type: null,
        description: null
      };
      this.isLoading = true;
      let result = HTTP(
        "put",
        `v1/platform/view/update/${this.$route.params.id}`,
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then(async (response) => {
          if (response.data.success == 1) {
            await this.fetchAdditionalVouchers();
            this.$bvToast.toast(response.data.message, {
              title: "Done",
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
          } else {
            this.$bvToast.toast(response.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
          }
          this.isLoading = false;
          this.advanceFilterForms = false;
          return response;
        })
        .catch((error) => {
          this.isLoading = false;
          this.advanceFilterForms = false;
          return error;
        });
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "event-log-table");
    },
    updateDateFormat(date) {
      const utcFormattedString = `${date.replace(" ", "T")}Z`;
      const formatOptions = {
        weekday: [dateFormatOptionsTypes.LONG],
        year: [dateFormatOptionsTypes.NUMERIC],
        month: [dateFormatOptionsTypes.LONG],
        day: [dateFormatOptionsTypes.NUMERIC],
        hour: [dateFormatOptionsTypes.NUMERIC],
        minute: [dateFormatOptionsTypes.NUMERIC]
      };

      return new Date(utcFormattedString).toLocaleString("en", formatOptions);
    },
    changeDescription(text, entityName, reportLevel, status, date) {
      let item = {
        entity_name: entityName ? entityName.toUpperCase() : "",
        report_level: reportLevel == 1 ? "IDD" : "IDD+",
        colour: status == 0 ? "Green" : "Red",
        OGM_alerted_date: date
      };
      let regExp = /(?<={{).*?(?=}})/g;
      let matchArr = text.match(regExp);
      matchArr.map((data) => {
        for (const [key, value] of Object.entries(item)) {
          if (`${key}` == data) {
            text = text.replace(data, `${value}`);
          }
        }
      });
      text = text.replace(/{{/g, "<b>");
      text = text.replace(/}}/g, "</b>");
      return text;
    },

    // get event log data
    async eventLogData() {
      if (!this.tableLoader && !this.tableLoaderTop) {
        this.isLoading = true;
      }
      let formData = {
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        company_id: this.companyId,
        userId: this.userId,
        per_page: this.perPage,
        page: this.currentPage
      };
      let result = HTTP(
        "post",
        "v1/event/log/get",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          const items = response.data.data;
          this.last_page = response.data.last_page;
          this.fromRows = response.data.from == null ? 0 : response.data.from;
          this.toRows = response.data.to == null ? 0 : response.data.to;
          this.totalRows = response.data.total;
          this.filterTable = false;
          this.tableLoader = false;
          this.tableLoaderTop = false;
          this.isLoading = false;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          this.tableLoader = false;
          this.tableLoaderTop = false;
          return [error];
        });
    },
    setFIlterClear() {
      this.filter = "";
      this.filterTable = false;
      this.reloadTable();
    },
    goToPreviousPage() {
      this.showTableData = false;
      this.currentPage--;
      this.tableLoaderTop = true;
      this.tableLoader = true;
      setTimeout(() => {
        this.showTableData = true;
      }, 10);
    },
    goToNextPage() {
      this.showTableData = false;
      this.currentPage++;
      this.tableLoader = true;
      setTimeout(() => {
        this.showTableData = true;
      }, 10);
    },

    handleScroll(event) {
      let tableScrollHeight = event.srcElement.scrollHeight;
      let tableScrollTop = event.srcElement.scrollTop;
      let tableClientHeight = event.srcElement.clientHeight;
      let tableScrollClientHeight = tableScrollHeight - tableScrollTop;
      let fixedTableScrollClientHeight = tableScrollClientHeight.toFixed();
      let parsedFixedTableScrollClientHeight = parseInt(
        fixedTableScrollClientHeight
      );
      let addFixedTableScrollClientHeight =
        parsedFixedTableScrollClientHeight + 1;

      if (
        parsedFixedTableScrollClientHeight == tableClientHeight ||
        addFixedTableScrollClientHeight == tableClientHeight
      ) {
        this.handleScrolledToBottom();
      }

      if (tableScrollTop == 0) {
        this.handleScrolledToTop();
      }
    },

    handleScrolledToBottom() {
      if (this.currentPage >= this.last_page) {
        return false;
      } else {
        this.showTableData = false;
        this.currentPage++;
        this.tableLoader = true;
        setTimeout(() => {
          this.showTableData = true;
        }, 10);
      }
    },
    handleScrolledToTop() {
      if (this.currentPage <= 1) {
        return false;
      } else {
        this.showTableData = false;
        this.currentPage--;
        this.tableLoaderTop = true;
        this.tableLoader = true;
        setTimeout(() => {
          this.showTableData = true;
        }, 10);
      }
    },

    // check filter values to enable/disable apply button
    checkFilterValue() {
      if (
        this.advanceFilter.dateFrom != null ||
        this.advanceFilter.dateTo != null ||
        this.advanceFilter.eventType.length != 0 ||
        this.advanceFilter.source != null ||
        this.advanceFilter.description != null ||
        this.advanceFilter.tags.length != 0
      ) {
        this.callFilterApi = false;
      } else {
        this.callFilterApi = true;
      }
    },
    resetAdvanceFilter() {
      this.resetFilter = false;
      this.callFilterApi = true;
      this.filterAdvance = {
        dateFrom: null,
        dateTo: null,
        eventType: [],
        source: null,
        description: null,
        tags: []
      };
      this.advanceFilter = {
        dateFrom: null,
        dateTo: null,
        eventType: [],
        source: null,
        description: null,
        tags: []
      };
      this.advanceFilterForms = false;
      this.filter = "";
      this.filterTable = false;
      this.currentPage = 1;
      this.reloadTable();
    },
    applyThirdPartyFilter() {
      this.isLoading = true;
      this.resetFilter = true;
      this.currentPage = 1;
      this.filterData = [];
      this.advanceFilterForms = false;
      setTimeout(() => {
        this.reloadTable();
      }, 200);
    },
    setFilterUploadDateFrom() {
      this.checkFilterValue();
    },
    setFilterUploadDateTo() {
      this.checkFilterValue();
    },
    selectFilterTypeResults(value) {
      this.filterAdvance.eventType = [];
      value.map((filterTypeResults) => {
        this.filterAdvance.eventType.push(filterTypeResults.value);
      });
      this.checkFilterValue();
    },
    setFilterSource() {
      this.checkFilterValue();
    },
    setFilterDescription() {
      this.checkFilterValue();
    },
    selectFilterTagsAlert(value) {
      this.filterAdvance.tags = [];
      value.map((filterTagsAlert) => {
        this.filterAdvance.tags.push(filterTagsAlert.value);
      });
      this.checkFilterValue();
    },

    // filtered event log table data
    getEventLogDataWithAdvanceFilter() {
      if (!this.tableLoader && !this.tableLoaderTop) {
        this.isLoading = true;
      }
      let formData = {};
      if (this.filterData.length == 0) {
        formData = {
          filter: this.filter,
          sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
          per_page: this.perPage,
          page: this.currentPage,
          company_id: this.companyId,
          upload_date_from_filter: this.advanceFilter.dateFrom,
          upload_date_to_filter: this.advanceFilter.dateTo,
          type_filter: this.filterAdvance.eventType,
          source_filter: this.advanceFilter.source,
          description_filter: this.advanceFilter.description,
          tags_filter: this.filterAdvance.tags
        };
      } else {
        formData = this.filterData;
      }
      this.setFilterData = formData;
      let result = HTTP(
        "post",
        "v1/event/log/get",
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          const items = response.data.data;
          this.last_page = response.data.last_page;
          this.fromRows = response.data.from == null ? 0 : response.data.from;
          this.toRows = response.data.to == null ? 0 : response.data.to;
          this.totalRows = response.data.total;
          this.filterTable = false;
          this.tableLoader = false;
          this.tableLoaderTop = false;
          this.isLoading = false;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          this.tableLoader = false;
          this.tableLoaderTop = false;
          return [error];
        });
    },

    // event type API
    async getEventTpe() {
      let result = HTTP(
        "get",
        "v1/event/log/types",
        null,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          response.data.map((items) => {
            this.optionsFilterTypeResults.push({
              text: items.text,
              value: items.id
            });
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.tableLoader = false;
          this.tableLoaderTop = false;
          return [error];
        });
    },

    // event tag API
    async getEventTags() {
      let result = HTTP(
        "get",
        "v1/event/log/tags",
        null,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          response.data.map((items) => {
            this.optionsFilterTagsAlert.push({
              text: items.tag,
              value: items.id
            });
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.tableLoader = false;
          this.tableLoaderTop = false;
          return [error];
        });
    }
  }
};
</script>
