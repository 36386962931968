<template>
  <div class="data-table" data-test-id="data-table">
    <SearchFilterBar
      v-if="searchFilterBarOptions"
      v-bind="searchFilterBarOptions"
      class="data-table__search-filter-bar"
      @click="onComponentClick(dataTableEvents.SEARCH_FILTER_BAR, $event)"
    />
    <div :style="{ height: gridHeight }">
      <AgGridTable
        v-bind="agGridOptions"
        v-on="$listeners"
        ref="ag-grid-table"
        class="data-table__table"
      />
    </div>
    <PerPagePagination
      v-if="paginationOptions"
      v-bind="paginationOptions"
      @click="onComponentClick(dataTableEvents.PER_PAGE_PAGINATION, $event)"
    />
  </div>
</template>

<script>
import PerPagePagination from "@/molecules/PerPagePagination/PerPagePagination";
import SearchFilterBar from "@/organisms/SearchFilterBar/SearchFilterBar.vue";
import AgGridTable from "@/molecules/AgGridTable/AgGridTable.vue";
import BaseGridComponentWrapper from "@/atoms/BaseGridComponentWrapper/BaseGridComponentWrapper.vue";
import { dataTableEvents } from "@/constants";

export default {
  name: "DataTable",
  data() {
    return {
      dataTableEvents
    };
  },
  components: {
    AgGridTable,
    // DO NOT DELETE BaseGridComponentWrapper.
    // This needs to be declared here, although it is not used within this file.
    // eslint-disable-next-line vue/no-unused-components
    BaseGridComponentWrapper,
    PerPagePagination,
    SearchFilterBar
  },
  props: {
    searchFilterBarOptions: {
      type: Object,
      default: () => null
    },
    agGridOptions: {
      type: Object,
      default: () => null
    },
    paginationOptions: {
      type: Object,
      default: () => null
    },
    gridHeight: {
      type: String,
      default: "500px"
    }
  },
  methods: {
    onComponentClick(type, event) {
      this.$emit("click", { type, event });
    }
  }
};
</script>
