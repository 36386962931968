import { TriggerStepClass } from "@/organisms/TriggerStep/TriggerStep.class";

export default {
  data() {
    return {
      triggerStepList: []
    };
  },
  computed: {
    isTriggerVisible() {
      return !!this.triggerStepList.length;
    }
  },
  methods: {
    removeTriggerStepByIndex(index) {
      this.triggerStepList = this.triggerStepList.filter(
        (triggerStep, stepIndex) => {
          if (stepIndex !== index) {
            return triggerStep;
          }
        }
      );
    },
    makeTriggerStep({
      actionNameOptions = [],
      expectDataList = [],
      actionNameErrorMessage = "",
      comment = ""
    } = {}) {
      return new TriggerStepClass({
        actionNameOptions,
        expectDataList,
        actionNameErrorMessage,
        comment,
        hasComment: !!comment
      });
    },
    setTriggerStep({
      actionNameOptions = null,
      expectDataList = null,
      actionNameErrorMessage = null,
      step = null
    }) {
      if (step) {
        actionNameOptions && step.setActionNameOptions(actionNameOptions);
        expectDataList && step.setExpectDataList(expectDataList);
        actionNameErrorMessage &&
          step.setActionNameErrorMessage(actionNameErrorMessage);
      }
    }
  }
};
