<template id="template-signature">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      :label="fieldData.label[selectedLanguage]"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <b-collapse
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div class="p-4 text-center">
        <div class="text-center" style="display: inline-flex">
          <h3 v-if="fieldData.signatureTitleTextIsDisabled == 0">
            {{
              form1.enableSectionFieldEnumeration == 1
                ? sectionIndex + 1 + "." + (fieldIndex + 1)
                : ""
            }}
            {{ fieldData.signatureTitle[selectedLanguage] }}
          </h3>
        </div>
        <p
          v-if="fieldData.signatureDescriptionIsDisabled == 0"
          style="font: italic"
        >
          {{ fieldData.signatureDescription[selectedLanguage] }}
        </p>
      </div>

      <b-form-checkbox
        :id="fieldData.id"
        :disabled="isDisabled == 1"
        v-if="fieldData.acceptTermsTextIsDisabled == 0"
        :class="$store.state.rightAlign ? 'text-right mb-3' : 'text-left mb-3'"
        v-model="acceptTermsText"
        name="checkbox-1"
        value="accepted"
        unchecked-value="not_accepted"
      >
        <span
          v-if="fieldData.validation && fieldData.validation.requiredTerms == 1"
          class="required-span"
          >*</span
        >
        {{ fieldData.acceptTermsText[selectedLanguage] }}
      </b-form-checkbox>
      <p
        v-html="fieldData.acceptTermsDescriptionText[selectedLanguage]"
        :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
      ></p>

      <p
        class="text-center"
        v-if="fieldData.signInTheAreaBelowLabelIsDisabled == 0"
      >
        {{ fieldData.signInTheAreaBelowLabel[selectedLanguage] }}
        <span
          v-if="fieldData.validation && fieldData.validation.required == 1"
          class="required-span"
          >*</span
        >
      </p>
      <b-img v-if="isDisabled == 1" :src="dataUrl" fluid></b-img>
      <div v-else id="122">
        <vueSignature
          ref="signature"
          :id="fieldData.id"
          :sigOption="option"
          :h="'250px'"
          :defaultUrl="dataUrl"
          style="border: 1px dashed"
        ></vueSignature>
      </div>

      <b-button-group class="mt-5">
        <b-button :disabled="isDisabled == 1" variant="info" @click="clear">{{
          $t("signature.clear")
        }}</b-button>
      </b-button-group>

      <b-row v-if="fieldData.printNameLabelIsDisabled == 0">
        <b-col cols="12" class="text-left">
          <div :class="$store.state.rightAlign ? 'text-right' : 'text-left'">
            <label>{{ fieldData.printNameLabel[selectedLanguage] }}</label>
            <span
              v-if="fieldData.validation && fieldData.validation.required == 1"
              class="required-span"
              >*</span
            >
          </div>
        </b-col>
      </b-row>

      <b-row v-if="fieldData.printNameLabelIsDisabled == 0">
        <b-col>
          <b-form-input
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :disabled="isDisabled == 1"
            type="text"
            v-model="signatureName"
            @blur="save"
            @input="setNameValue"
            :state="
              isFocus &&
              fieldData.validation &&
              fieldData.validation.requiredName == 1
                ? !$v.signatureName.required
                  ? false
                  : true
                : null
            "
            @focus="isFocus = true"
            :placeholder="fieldData.printNamePlaceholder[selectedLanguage]"
          ></b-form-input>
          <span
            v-if="
              fieldData.validation.requiredName &&
              !$v.signatureName.required &&
              isFocus
            "
            :class="
              $store.state.rightAlign
                ? 'float-right text-danger'
                : 'float-left text-danger'
            "
            >{{ $t("signature.require") }}</span
          >
        </b-col>
      </b-row>

      <!-- new for company -->
      <b-row v-if="fieldData.companyFieldLabelIsDisabled == 0">
        <b-col cols="12" class="text-left">
          <div :class="$store.state.rightAlign ? 'text-right' : 'text-left'">
            <label>{{ fieldData.printCompanyLabel[selectedLanguage] }}</label>
            <span
              v-if="
                fieldData.validation &&
                fieldData.validation.requiredCompany == 1
              "
              class="required-span"
              >*</span
            >
          </div>
        </b-col>
      </b-row>

      <b-row v-if="fieldData.companyFieldLabelIsDisabled == 0">
        <b-col>
          <b-form-input
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :disabled="isDisabled == 1"
            type="text"
            v-model="signatureCompany"
            @blur="save"
            @input="setCompanyValue"
            :state="
              isFocus &&
              fieldData.validation &&
              fieldData.validation.requiredCompany == 1
                ? !$v.signatureCompany.required
                  ? false
                  : true
                : null
            "
            @focus="isFocus = true"
          ></b-form-input>
          <span
            v-if="
              fieldData.validation.requiredCompany == 1 &&
              !$v.signatureCompany.required &&
              isFocus
            "
            :class="
              $store.state.rightAlign
                ? 'float-right text-danger'
                : 'float-left text-danger'
            "
            >{{ $t("signature.require") }}</span
          >
        </b-col>
      </b-row>

      <!-- new for place -->
      <b-row v-if="fieldData.placeFieldLabelIsDisabled == 0">
        <b-col cols="12" class="text-left">
          <div :class="$store.state.rightAlign ? 'text-right' : 'text-left'">
            <label>{{ fieldData.printPlaceLabel[selectedLanguage] }}</label>
            <span
              v-if="
                fieldData.validation && fieldData.validation.requiredPlace == 1
              "
              class="required-span"
              >*</span
            >
          </div>
        </b-col>
      </b-row>

      <b-row v-if="fieldData.placeFieldLabelIsDisabled == 0">
        <b-col>
          <b-form-input
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :disabled="isDisabled == 1"
            type="text"
            v-model="signaturePlace"
            @blur="save"
            @input="setPlaceValue"
            :state="
              isFocus &&
              fieldData.validation &&
              fieldData.validation.requiredPlace == 1
                ? !$v.signaturePlace.required
                  ? false
                  : true
                : null
            "
            @focus="isFocus = true"
          ></b-form-input>
          <span
            v-if="
              fieldData.validation.requiredPlace == 1 &&
              !$v.signaturePlace.required &&
              isFocus
            "
            :class="
              $store.state.rightAlign
                ? 'float-right text-danger'
                : 'float-left text-danger'
            "
            >{{ $t("signature.require") }}</span
          >
        </b-col>
      </b-row>

      <b-row v-if="fieldData.positionRoleLabelIsDisabled == 0">
        <b-col cols="12" class="text-left">
          <div :class="$store.state.rightAlign ? 'text-right' : 'text-left'">
            <label>{{ fieldData.positionRoleLabel[selectedLanguage] }}</label>
            <span
              v-if="
                fieldData.validation &&
                fieldData.validation.requirePositionRole == 1
              "
              class="required-span"
              >*</span
            >
          </div>
        </b-col>
      </b-row>

      <b-row v-if="fieldData.positionRoleLabelIsDisabled == 0">
        <b-col>
          <b-form-input
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :id="fieldData.id"
            :disabled="isDisabled == 1"
            type="text"
            v-model="positionRole"
            @blur="save"
            @input="setPostionRoleValue"
            :state="
              isFocus &&
              fieldData.validation &&
              fieldData.validation.requiredPositionRole == 1
                ? !$v.positionRole.required
                  ? false
                  : true
                : null
            "
            @focus="isFocus = true"
            :placeholder="fieldData.positionRolePlaceholder[selectedLanguage]"
          ></b-form-input>
          <span
            v-if="
              fieldData.validation.requiredPositionRole == 1 &&
              !$v.positionRole.required &&
              isFocus
            "
            :class="
              $store.state.rightAlign
                ? 'float-right text-danger'
                : 'float-left text-danger'
            "
            >{{ $t("signature.require") }}</span
          >
        </b-col>
      </b-row>

      <b-row v-if="fieldData.dateFieldLabelIsDisabled == 0">
        <b-col cols="12" class="text-left">
          <div :class="$store.state.rightAlign ? 'text-right' : 'text-left'">
            <label>{{ fieldData.dateFieldLabel[selectedLanguage] }}</label>
            <span
              v-if="
                fieldData.validation && fieldData.validation.requiredDate == 1
              "
              class="required-span"
              >*</span
            >
          </div>
        </b-col>
      </b-row>

      <div
        :class="
          $store.state.rightAlign
            ? 'text-right input-group mb-3'
            : 'text-left input-group mb-3'
        "
        v-if="fieldData.dateFieldLabelIsDisabled == 0"
      >
        <b-input-group-append v-if="$store.state.rightAlign">
          <span class="input-group-text" id="basic-addon2">
            <i class="fa fa-calendar"></i>
          </span>
        </b-input-group-append>
        <Datepicker
          :disabled="isDisabled == 1"
          :class="
            fieldData.validation &&
            fieldData.validation.required == 1 &&
            isFocus &&
            !signatureDate &&
            $store.state.rightAlign
              ? 'text-right validation-color'
              : fieldData.validation &&
                fieldData.validation.required == 1 &&
                isFocus &&
                !signatureDate
              ? 'text-left validation-color'
              : $store.state.rightAlign
              ? 'text-right'
              : 'text-left'
          "
          calendar-class="date-calender"
          :input-class="
            $store.state.rightAlign
              ? 'custom-date-picker date-picker-right'
              : 'custom-date-picker date-picker-left'
          "
          class="form-control"
          v-model="signatureDate"
          @input="setDateValue"
          @blur="save"
          :format="customFormatter"
          :disabled-dates="disabledDates"
          @focus="isFocus = true"
        ></Datepicker>
        <b-input-group-append v-if="!$store.state.rightAlign">
          <span class="input-group-text" id="basic-addon2">
            <i class="fa fa-calendar"></i>
          </span>
        </b-input-group-append>

        <br />
      </div>
      <span
        style="margin-top: -13px"
        v-if="
          fieldData.validation.requiredDate == 1 && !signatureDate && isFocus
        "
        :class="
          $store.state.rightAlign
            ? 'float-right text-danger'
            : 'float-left text-danger'
        "
        >{{ $t("signature.require") }}</span
      >

      <!-- show advance field options -->
      <b-modal
        @hidden="onUpdateSettings"
        ok-title="Save"
        @ok="onSaveData"
        scrollable
        size="xl"
        v-model="showSettings"
        title="Signature Settings"
      >
        <SignatureSettings
          :fieldData="fieldData"
          :sectionIndex="sectionIndex"
          v-on:toggle-field="showSettings = !showSettings"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import Vue from "vue";
import vueSignature from "vue-signature";
import SignatureSettings from "../advanceSignatureSettingsCompenent.vue";
import { required } from "vuelidate/lib/validators";
import MenuButtons from "../menuButtons";
import Datepicker from "vuejs-datepicker";
import { formBuilderConstants, routeKeys, routeNames } from "@/constants";
import { mapGetters, mapMutations, mapState } from "vuex";
import { Helper } from "@/helpers";
import { EventBus } from "@/event-bus.js";
import moment from "moment";
import "moment/locale/es";
import { clone } from "lodash";

Vue.use(vueSignature);

export default {
  name: "SignaturePad",
  components: {
    vueSignature,
    SignatureSettings,
    MenuButtons,
    Datepicker
  },

  data() {
    return {
      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: null,
      signature: null,
      signInTheAreaBelowLabel: null,
      signatureTitleText: null,
      signatureTitleTextIsDisabled: null,
      signatureDescriptionText: null,
      signatureDescriptionTextIsDisabled: null,
      acceptTermsText: null,
      acceptTermsTextIsDisabled: null,
      printNameLabel: null,
      printNameLabelIsDisabled: null,
      dateFieldLabelIsDisabled: null,
      positionRoleLabelIsDisabled: null,
      dateFieldLabel: null,
      dateFieldPlaceholder: null,
      signatureName: null,
      positionRole: null,
      signatureDate: null,
      signatureCompany: null,
      signaturePlace: null,
      isFocus: false,
      isError: false,
      isSuccess: false,
      postData: [],

      // validation
      isRequired: 1,
      isRequiredName: 1,
      isRequiredPositionRole: 1,
      isRequiredDate: 1,
      isRequiredTerms: 1,
      isRequiredCompany: 1,
      isRequiredPlace: 1,

      // Signature pad options
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      dataUrl: "",
      disabledDates: {
        to:
          this.fieldData.advance && this.fieldData.advance.disabledMinDateInput
            ? new Date(Date.now() - 864e5)
            : this.fieldData.advance && this.fieldData.advance.minDate
            ? new Date(this.fieldData.advance.minDate)
            : "", // Disable all dates up to specific date
        from:
          this.fieldData.advance && this.fieldData.advance.disabledMaxDateInput
            ? new Date()
            : this.fieldData.advance && this.fieldData.advance.maxDate
            ? new Date(this.fieldData.advance.maxDate)
            : "" // Disable all dates after specific date
      }
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse", "userFormSubmitArray"])
  },
  created() {
    EventBus.$on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.stTempData();
      } else {
        if (
          this.fieldData.validation.required == 1 ||
          this.fieldData.validation.requiredName == 1 ||
          this.fieldData.validation.requirePositionRole == 1 ||
          this.fieldData.validation.requiredDate == 1 ||
          this.fieldData.validation.requiredTerms == 1 ||
          this.fieldData.validation.requiredCompany == 1 ||
          this.fieldData.validation.requiredPlace == 1
        ) {
          if (
            (this.fieldData.validation.requiredTerms == 1 &&
              !this.acceptTermsText) ||
            this.acceptTermsText === formBuilderConstants.NOT_ACCEPTED_TERMS
          ) {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
            this.isFocus = true;
          } else if (
            this.fieldData.validation.requiredDate == 1 &&
            !this.signatureDate
          ) {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
            this.isFocus = true;
          } else if (
            this.fieldData.validation.required == 1 &&
            !this.signature
          ) {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
            this.isFocus = true;
          } else if (
            this.fieldData.validation.requirePositionRole == 1 &&
            !this.positionRole
          ) {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
            this.isFocus = true;
          } else if (
            this.fieldData.validation.requiredName == 1 &&
            !this.signatureName
          ) {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
            this.isFocus = true;
          } else if (
            this.fieldData.validation.requiredCompany == 1 &&
            !this.signatureCompany
          ) {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
            this.isFocus = true;
          } else if (
            this.fieldData.validation.requiredPlace == 1 &&
            !this.signaturePlace
          ) {
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
            this.isFocus = true;
          } else {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
            this.stTempData();
          }
        } else {
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
          this.stTempData();
        }
      }
    });

    EventBus.$on("OnInvalidFieldCatch", (inValidData) => {
      //
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  mounted() {
    let self = this;
    this.isRequired = this.fieldData.validation.required;
    this.isRequiredName = this.fieldData.validation.requiredName;
    this.isrequiredPositionRole =
      this.fieldData.validation.requiredPositionRole;
    this.isRequiredDate = this.fieldData.validation.requiredDate;
    this.isRequiredTerms = this.fieldData.validation.requiredTerms;
    this.dataUrl =
      (this.fieldData.data && this.fieldData.data.signatureValue) || "";
    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name === "MyCompanyView" ||
      this.$route.name == "mytpptraining"
    ) {
      this.getDataForUser();
    }
    this.addNewFileds();

    document.getElementById("122").addEventListener("click", () => {
      self.save();
    });
  },

  validations: {
    signatureName: {
      required
    },
    positionRole: {
      required
    },
    signatureCompany: {
      required
    },
    signaturePlace: {
      required
    }
  },

  watch: {
    toggleAllCoppalse(vlue) {
      this.collapse = vlue;
    }
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    onUpdateSettings() {
      this.TOGGLE_LOADER(2);
    },

    addNewFileds() {
      Object.assign(this.fieldData, {
        companyFieldLabelIsDisabled:
          this.fieldData.companyFieldLabelIsDisabled || 0
      });
      Object.assign(this.fieldData, {
        placeFieldLabelIsDisabled: this.fieldData.placeFieldLabelIsDisabled || 0
      });

      Object.assign(this.fieldData, {
        printCompanyLabel: this.fieldData.printCompanyLabel || {
          english: "Company"
        }
      });
      Object.assign(this.fieldData, {
        printPlaceLabel: this.fieldData.printPlaceLabel || { english: "Place" }
      });
    },

    setValue() {
      const registerData = clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === "MyCompanyView"
      ) {
        if (!this.$v.$invalid) {
          registerData[this.fieldData.id] = "signature";
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;
        const numkeys = Object.keys(registerData).length;
        this.$store.state.progressValue = numkeys;

        let data = {
          acceptTermsText: this.acceptTermsText,
          signatureValue: this.signature,
          nameValue: this.signatureName,
          positionRoleValue: this.positionRole,
          dateValue: this.signatureDate,
          companyValue: this.companyValue,
          placeValue: this.placeValue
        };
        Object.assign(this.fieldData, { data });
      }
      // this.setDataToUser();
    },

    setNameValue(e) {
      this.signatureName = e;
      if (!this.$v.$invalid) {
        this.setValue();
      }
    },

    setCompanyValue(e) {
      this.signatureCompany = e;
      if (!this.$v.$invalid) {
        this.setValue();
      }
    },

    setPlaceValue(e) {
      this.signaturePlace = e;
      if (!this.$v.$invalid) {
        this.setValue();
      }
    },

    setPostionRoleValue(e) {
      this.positionRole = e;
      if (!this.$v.$invalid) {
        this.setValue();
      }
    },

    setDateValue(e) {
      this.signatureDate = e;
      if (!this.$v.$invalid) {
        this.setValue();
      }
    },

    getDataForUser() {
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elements && data.elements.length > 0) {
          data.elements.map((element) => {
            if (data.elementId === this.fieldData.id) {
              this.acceptTermsText =
                element[
                  element.findIndex((x) => x.key == "acceptTermsText")
                ].postValue;
              this.signatureName =
                element[
                  element.findIndex((x) => x.key == "nameValue")
                ].postValue;
              this.positionRole =
                element[
                  element.findIndex((x) => x.key == "positionRoleValue")
                ].postValue;
              this.signatureDate =
                element[
                  element.findIndex((x) => x.key == "dateValue")
                ].postValue;
              this.signature =
                element[
                  element.findIndex((x) => x.key == "signatureValue")
                ].postValue;
              this.signatureCompany =
                element[
                  element.findIndex((x) => x.key == "companyValue")
                ].postValue;
              this.signaturePlace =
                element[
                  element.findIndex((x) => x.key == "placeValue")
                ].postValue;
              this.dataUrl =
                element[
                  element.findIndex((x) => x.key == "signatureValue")
                ].postValue;
            }
          });
        }
      });
    },

    stTempData() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === "MyCompanyView"
      ) {
        this.postData = [];

        let aa = [
          {
            postValue: this.acceptTermsText,
            postNameTerms: "acceptTerms",
            key: "acceptTermsText",
            label: this.fieldData.acceptTermsText
          },
          {
            postValue: this.fieldData.acceptTermsDescriptionText,
            postNameTerms: "information",
            key: "informationText",
            label: this.fieldData.acceptTermsDescriptionText
          },
          {
            postValue: this.fieldData.data.signatureValue || this.signature,
            postNameSignature:
              (this.fieldData.attributes &&
                this.fieldData.attributes.postNameSignature) ||
              "postNameSignature",
            key: "signatureValue",
            label: this.fieldData.signInTheAreaBelowLabel
          },
          {
            postValue: this.fieldData.data.nameValue || this.signatureName,
            postNameName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.postNameName) ||
              "postNameName",
            key: "nameValue",
            label: this.fieldData.printNameLabel
          },
          {
            postValue:
              this.fieldData.data.positionRoleValue || this.positionRole,
            postNamePositionRole:
              (this.fieldData.attributes &&
                this.fieldData.attributes.postNamePositionRole) ||
              "postNamePositionRole",
            key: "positionRoleValue",
            label: this.fieldData.positionRoleLabel
          },
          {
            postValue: this.fieldData.data.dateValue || this.signatureDate,
            postNameDate:
              (this.fieldData.attributes &&
                this.fieldData.attributes.postNameDate) ||
              "postNameDate",
            key: "dateValue",
            label: this.fieldData.dateFieldLabel
          },
          {
            postValue:
              this.fieldData.data.companyValue || this.signatureCompany,
            postNameCompany: "postNameCompany",
            key: "companyValue",
            label: this.fieldData.printCompanyLabel
          },
          {
            postValue: this.fieldData.data.placeValue || this.signaturePlace,
            postNamePlace: "postNamePlace",
            key: "placeValue",
            label: this.fieldData.printPlaceLabel
          }
        ];

        this.postData.push(aa);
        this.setDataToUser();
      }
    },

    setDataToUser() {
      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name === "MyCompanyView"
      ) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements = this.postData;
            a.disableTitle = this.fieldData.signatureTitleTextIsDisabled;
            a.elementTitle = this.fieldData.signatureTitle;
            a.signatureTitle = this.fieldData.signatureTitle;
          }
        });
      }
    },

    save() {
      if (!this.$refs.signature.isEmpty()) {
        let png = this.$refs.signature.save();
        this.signature = png;
        this.dataUrl = png;
        this.setValue(png);
      }
    },

    clear() {
      this.$refs.signature.clear();
      this.signature = null;
      this.dataUrl = "";
    },

    addWaterMark() {
      let _this = this;
      _this.$refs.signature.addWaterMark({
        text: "mark text", // watermark text, > default ''
        font: "20px Arial", // mark font, > default '20px sans-serif'
        style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
        fillStyle: "red", // fillcolor, > default '#333'
        strokeStyle: "blue", // strokecolor, > default '#333'
        x: 100, // fill positionX, > default 20
        y: 200, // fill positionY, > default 20
        sx: 100, // stroke positionX, > default 40
        sy: 200 // stroke positionY, > default 40
      });
    },

    fromDataURL() {
      let _this = this;
      _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },

    handleDisabled() {
      let _this = this;
      _this.disabled = !_this.disabled;
    },

    onSaveData() {
      EventBus.$emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    },

    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: "Alert",
        variant,
        solid: true
      });
    },

    customFormatter(date) {
      let customFormat = this.fieldData.format;
      if (customFormat == "default") {
        return moment(date).format("DD-MM-YYYY");
      } else {
        return moment(date).locale("en").format(customFormat);
      }
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style>
.custom-date-picker {
  width: 100%;
  border: none;
}
.date-picker-right {
  text-align: right !important;
}
.date-picker-left {
  text-align: left !important;
}
.input-group-text-left {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.custom-date-picker:focus {
  border: none;
}
</style>
