<template>
  <div>
    <b-card class="mt-3" header="Configure Form">
      <div class="row" style="height: calc(100vh - 330px); overflow: auto">
        <div class="col-6 text-left">
          <b-form-group label="Translation Type">
            <b-form-radio
              v-model="formTranslationType"
              @input="updateTranslationType"
              name="translationtype"
              value="custom"
              >Custom</b-form-radio
            >
            <b-form-radio
              v-model="formTranslationType"
              @input="updateTranslationType"
              name="translationtype"
              value="googletranslate"
              >Google Translate</b-form-radio
            >
          </b-form-group>

          <b-form-group
            label="Form Languages"
            v-if="formTranslationType == 'custom'"
          >
            <b-form-checkbox-group
              @change="updateFormLanguages"
              v-model="formTranslations"
              :options="formConfigLanguages.languages"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="col-6 text-left">
          <b-form-group label="Version">
            <b-form-input
              @input="updateFormVersion"
              v-model="formVersion"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Type" stacked>
                <b-form-radio-group
                  :disabled="isFormPublished"
                  stacked
                  v-model="formType"
                  :options="formTypeOptions"
                  name="form-type"
                  @input="updateFormType"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                v-if="formType == 4"
                v-model="isUnlimitedExam"
                @change="onExamUnlitedClick"
                class="mb-3"
                >Unlimited Attempts</b-form-checkbox
              >

              <b-form-checkbox
                v-if="formType == 4"
                v-model="form.formConfiguration.exam.isAllowedEmpty"
                class="mb-3"
                >Allow Blank Answers</b-form-checkbox
              >

              <b-form-group
                v-if="formType == 4 && !isUnlimitedExam"
                label="Maximum number of attempts"
              >
                <b-form-input
                  size="sm"
                  min="0"
                  @input="updateMaxExamAttempts"
                  v-model="maxExamAttempts"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="formType == 4"
                label="Exam Time Limit (minutes)"
              >
                <b-form-input
                  size="sm"
                  min="0"
                  @input="updateExamTimeLimit"
                  v-model="examTimeLimit"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <b-form-group v-if="formType == 4" label="Sequential Answers">
                <b-form-checkbox
                  v-model="examSequentialAnswer"
                  value="1"
                  @input="updateSequentialAnswers"
                  >Enable sequential answers</b-form-checkbox
                >
              </b-form-group>
              <b-form-group v-if="formType == 4" label="Randomised Questions">
                <b-form-checkbox
                  v-model="examRandomiseQuestions"
                  value="1"
                  @input="updateExamRandomiseQuestions"
                  >Enable Randomised Questions</b-form-checkbox
                >
              </b-form-group>
              <b-form-group
                v-if="formType == 4 && examRandomiseQuestions == 1"
                label="Number questions per attempt"
              >
                <b-form-input
                  size="sm"
                  min="0"
                  @input="updateExamUniqueAnswersPerAttempt"
                  v-model="examUniqueAnswersPerAttempt"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <b-form-group v-if="formType == 4" label="Exam pass mark">
                <b-form-input
                  size="sm"
                  min="0"
                  @input="updateExamPassMark"
                  v-model="examPassMark"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- for traning exams -->
            <b-col
              cols="12"
              class="text-left"
              v-if="formType == 3 && this.formExams.length"
            >
              <b-form-group label="Select Exams">
                <v-select
                  label="title"
                  :value="selectedExamForTraining"
                  @input="onSelectExam"
                  :options="this.formExams"
                ></v-select>
              </b-form-group>
            </b-col>

            <!-- for tranings signature forms -->
            <b-col
              cols="12"
              class="text-left"
              v-if="formType == 3 && this.formTrainings.length"
            >
              <b-form-group label="Select Sign Of">
                <v-select
                  label="title"
                  :value="selectedTraining"
                  @input="onSelectTraining"
                  :options="this.formTrainings"
                ></v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- check for form intro show -->
          <b-form-checkbox
            v-if="formType != 4"
            v-model="showFormIntro"
            @change="onShowFormClick"
            class="mb-3"
            >Show Form Intro</b-form-checkbox
          >

          <b-form-group
            label="Layout"
            stacked
            v-if="formType != 4 && formType != 3"
          >
            <b-form-radio-group
              stacked
              v-model="formLayout"
              :options="formLayoutOptions"
              name="form-layout"
              @change="updateFormLayout"
            ></b-form-radio-group>
          </b-form-group>

          <b-form-group
            label="Enumerated Fields"
            v-if="formType != 4 && formType != 3"
          >
            <b-form-checkbox
              @change="updateFormEnumeration"
              v-model="enableSectionFieldEnumeration"
              value="1"
              unchecked-value="0"
              stacked
              >Enable enumerated fields</b-form-checkbox
            >
          </b-form-group>

          <b-form-group label="Password">
            <b-form-checkbox
              @input="updateFormPassword"
              v-model="formRequirePassword"
              value="1"
              unchecked-value="0"
              stacked
              >Enable password access</b-form-checkbox
            >
          </b-form-group>

          <b-form-group label="Scoring">
            <b-form-checkbox
              v-model="form.suppressScoreReporting"
              :value="true"
              :unchecked-value="false"
              >Suppress score reporting</b-form-checkbox
            >
          </b-form-group>
        </div>
      </div>
    </b-card>
    <b-navbar toggleable="sm" type="dark" variant="dark">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-button
              size="sm"
              variant="light"
              class="my-2 my-sm-0"
              type="button"
              to="/form/details"
              >Next</b-button
            >
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { HTTP } from "@/utils";
import formConfigLanguages from "@/utils/formConfigLanguages.json";
import { formStatus, localStorageItem } from "@/constants";
import { Helper } from "@/helpers";

export default {
  name: "FormConfigurationContainer",
  components: {},
  data() {
    return {
      formConfigLanguages,
      formTranslationType: "custom",
      formVersion: undefined,
      formTranslations: [],
      formType: null,
      formRequirePassword: 0,
      showFormIntro: false,
      isFormPublished: false,
      formLayout: "vertical",
      formTypeOptions: [
        {
          text: "External Questionaire",
          value: 1
        },
        {
          text: "Survey",
          value: 2
        },
        {
          text: "Training",
          value: 3
        },
        {
          text: "Exam",
          value: 4
        },
        {
          text: "Executive Summary",
          value: 5
        },
        {
          text: "PDF Uploads",
          value: 6
        },
        {
          text: "Internal Questionaire",
          value: 7
        },
        {
          text: "Signature Form",
          value: 8
        },
        {
          text: "Internal Company Form",
          value: 9
        },
        {
          text: "Certification Form",
          value: 10
        }
      ],
      formLayoutOptions: [
        {
          text: "Vertical",
          value: "vertical"
        },
        {
          text: "Tabbed",
          value: "tabbed"
        }
      ],
      enableSectionFieldEnumeration: 0,
      maxExamAttempts: 5,
      examTimeLimit: 0,
      examSequentialAnswer: 0,
      examRandomiseQuestions: 0,
      examUniqueAnswersPerAttempt: 0,
      examPassMark: 0,
      isUnlimitedExam: false,
      formExams: [],
      formTrainings: [],
      selectedExamForTraining: null,
      selectedTraining: null,
      isDisabledTranslation: false
    };
  },
  computed: {
    ...mapGetters({
      selectedLanguage: "selectedLanguage"
    }),
    ...mapState({
      form: "form1"
    })
  },
  watch: {
    form() {
      this.updateFormLocals();
    }
  },
  async mounted() {
    this.isFormPublished =
      parseInt(localStorage.getItem(localStorageItem.FORM_STATUS)) ===
      formStatus.PUBLISHED;
    await this.updateFormLocals();
  },
  async beforeDestroy() {
    await EventBus.$emit("onAutoSaveJsonForm", true);
  },
  methods: {
    async getExamsForTraining() {
      let formId = this.$store.state.form1.formId;
      try {
        let result = await HTTP("post", "v1/getclientexams", {
          formId
        });
        if (result.data && result.data.exams && result.data.exams.length) {
          this.formExams = result.data.exams;
        }
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    async getTrainings() {
      let formId = this.$store.state.form1.formId;
      try {
        let result = await HTTP("post", "v1/getclienttrainingdata", {
          formId
        });
        if (
          result.data &&
          result.data.training &&
          result.data.training.length
        ) {
          this.formTrainings = result.data.training;
        }
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    async setSubTask(e) {
      let formId = this.$store.state.form1.formId;
      let relatedTaskIds = [];
      relatedTaskIds.push({
        formId: e.id,
        type: 8,
        is_active: 1
      });
      try {
        await HTTP("post", "v1/set/onboardingforms/sub/tasks", {
          relatedTaskIds,
          formId
        });
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },

    updateFormLocals() {
      this.formTranslationType = this.form.formTranslationType
        ? this.form.formTranslationType
        : "custom";
      this.formTranslations = this.form.formTranslations || [];
      this.formVersion = this.form.formVersion;
      this.formType = this.form.formType;
      this.showFormIntro = this.form.isShowFormIntro || false;
      this.formLayout = this.form.formLayout;

      if (this.form.formType == 4) {
        this.maxExamAttempts = parseInt(
          this.form.formConfiguration.exam.examMaxAttempts
        );
        this.examTimeLimit = parseInt(
          this.form.formConfiguration.exam.examTimeLimit
        );
        this.examSequentialAnswer = parseInt(
          this.form.formConfiguration.exam.examSequentialAnswer
        );
        this.examRandomiseQuestions = parseInt(
          this.form.formConfiguration.exam.examRandomiseQuestions
        );
        this.examUniqueAnswersPerAttempt = parseInt(
          this.form.formConfiguration.exam.examUniqueAnswersPerAttempt
        );
        this.examPassMark = parseInt(
          this.form.formConfiguration.exam.examPassMark
        );

        this.isUnlimitedExam =
          this.form.formConfiguration.exam.isUnlimitedAttempts || false;
      }

      this.enableSectionFieldEnumeration = parseInt(
        this.form.enableSectionFieldEnumeration
      );

      if (this.form.formType == 3) {
        this.getExamsForTraining();
        this.getTrainings();
        this.selectedExamForTraining =
          (this.form.linkedExam && this.form.linkedExam.title) || null;
      }
    },
    updateExamUniqueAnswersPerAttempt() {
      this.form.formConfiguration.exam.examUniqueAnswersPerAttempt =
        this.examUniqueAnswersPerAttempt;
    },
    onShowFormClick(e) {
      this.showFormIntro = e;
      Object.assign(this.form, (this.form.isShowFormIntro = e));
    },
    updateExamPassMark() {
      this.form.formConfiguration.exam.examPassMark = parseInt(
        this.examPassMark
      );
    },
    updateExamRandomiseQuestions() {
      if (this.examRandomiseQuestions != 1) {
        this.form.formConfiguration.exam.examUniqueAnswersPerAttempt = 0;
        this.examUniqueAnswersPerAttempt = 0;
      }
      this.form.formConfiguration.exam.examRandomiseQuestions = parseInt(
        this.examRandomiseQuestions
      );
    },
    updateSequentialAnswers() {
      this.form.formConfiguration.exam.examSequentialAnswer = parseInt(
        this.examSequentialAnswer
      );
    },
    updateMaxExamAttempts() {
      this.form.formConfiguration.exam.examMaxAttempts = parseInt(
        this.maxExamAttempts
      );
    },
    updateExamTimeLimit() {
      this.form.formConfiguration.exam.examTimeLimit = parseInt(
        this.examTimeLimit
      );
    },
    updateFormEnumeration(e) {
      this.form.enableSectionFieldEnumeration = e;
    },
    updateFormLanguages(e) {
      this.form.formTranslations = e;
    },
    updateFormType() {
      this.form.formType = this.formType;
      if (this.formType == 3) {
        this.getExamsForTraining();
        this.getTrainings();
      }
    },
    updateFormLayout(e) {
      this.form.formLayout = e;
    },
    updateFormPassword() {
      this.form.formRequirePassword = this.formRequirePassword;
    },
    updateTranslationType() {
      this.form.formTranslationType = this.formTranslationType;
    },
    updateFormVersion() {
      this.form.formVersion = this.formVersion;
    },
    onSelectExam(e) {
      this.selectedExamForTraining = e.title;
      let linkedExam = {
        id: e.id || null,
        formId: this.form.formId,
        company_id: e.company_id || null,
        title: e.title || null,
        createdBy: e.created_by_id || null
      };
      Object.assign(this.form, { linkedExam });
    },
    onSelectTraining(e) {
      this.setSubTask(e);
      this.selectedTraining = e.title;
      let linkedTraining = {
        id: e.id || null,
        formId: this.form.formId,
        company_id: e.company_id || null,
        title: e.title || null,
        createdBy: e.created_by_id || null
      };
      Object.assign(this.form, { linkedTraining });
    },
    onExamUnlitedClick(e) {
      if (e) {
        this.maxExamAttempts = 0;
      } else {
        this.maxExamAttempts = 5;
      }
      this.updateMaxExamAttempts();
      Object.assign(this.form.formConfiguration.exam, {
        isUnlimitedAttempts: e
      });
    },
    onDisabledTranslation(e) {
      Object.assign(this.form.formConfiguration, {
        isDisabledTranslation: e
      });
    }
  }
};
</script>
