var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"label":_vm.label,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('b-collapse',{class:_vm.isPreview ? 'mt-2' : 'mt-5',attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"1"}},[(
            _vm.fieldData.files &&
            _vm.fieldData.files.length > 1 &&
            _vm.fieldData.controlsOut &&
            _vm.slide > 0
          )?_c('b-button',{staticClass:"col-1",attrs:{"variant":"outline"},on:{"click":_vm.prev}},[_c('font-awesome-icon',{attrs:{"size":"5x","icon":"caret-left"}})],1):_vm._e()],1),_c('b-col',{staticStyle:{"max-hieght":"300px"},attrs:{"cols":"10"}},[_c('b-carousel',{ref:"appCarousel",class:_vm.fieldData.files &&
            _vm.fieldData.files.length > 1 &&
            _vm.fieldData.controlsOut
              ? 'col-10'
              : 'col-12',staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel-1","interval":0,"controls":_vm.fieldData.controls,"indicators":_vm.fieldData.indicators,"img-width":_vm.fieldData.imgWidth,"img-height":_vm.fieldData.imgHeight},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.fieldData.files),function(data,index){return _c('b-carousel-slide',{key:index,attrs:{"caption":data.caption[_vm.selectedLanguage],"text":data.text[_vm.selectedLanguage],"img-blank":!data.url,"img-alt":data.imgAlt,"img-src":data.url,"img-height":200,"background":"#ababab"}})}),1)],1),_c('b-col',{attrs:{"cols":"1"}},[(
            _vm.fieldData.files &&
            _vm.fieldData.files.length > 1 &&
            _vm.fieldData.controlsOut &&
            _vm.slide !== _vm.fieldData.files.length - 1
          )?_c('b-button',{staticClass:"col-1",attrs:{"variant":"outline"},on:{"click":_vm.next}},[_c('font-awesome-icon',{attrs:{"size":"5x","icon":"caret-right"}})],1):_vm._e()],1)],1),_c('b-modal',{attrs:{"cancel-title":"Close without saving","cancel-variant":"outline-danger","ok-title":"save","ok-variant":"outline-success","no-close-on-esc":"","no-close-on-backdrop":"","no-enforce-focus":"","hide-header-close":"","hide-footer":_vm.disableButtons,"size":"xl","title":"Carousel Advanced Settings"},on:{"ok":_vm.onSaveData,"hidden":_vm.onUpdateSettings},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[(_vm.showSettings)?_c('AdvanceTrainingSettings',{attrs:{"fieldData":_vm.fieldData},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}}):_vm._e()],1)],1),_c('b-toast',{attrs:{"variant":"primary","id":"toast-1","title":"Notification"},model:{value:(_vm.showToast1),callback:function ($$v) {_vm.showToast1=$$v},expression:"showToast1"}},[_vm._v("Settings Updated")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }