<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Text" active>
      <b-tabs pills card vertical>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div>
            <b-form-group label="Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="label"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- Options tabb -->
    <b-tab title="Options">
      <!-- select slides tab -->
      <b-tabs pills card vertical>
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <b-form @submit.stop.prevent class="active text-left mt-2">
            <table class="table table-sm">
              <thead>
                <tr class="text-center">
                  <th>Option Title</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(option, index) in fieldData.options" :key="index">
                  <td class="pr-2 pt-1 pl-2">
                    <b-form-input
                      :value="option.text[transalationLanguage]"
                      @input="addOptionText($event, index)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      type="number"
                      :value="option.Value"
                      @input="addOptionValue($event, index)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-button
                      variant="outline-danger"
                      class="btn btn-outline-danger btn-sm"
                      @click="onDeleteOption(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-button variant="primary" @click="addOption">
              <i class="fa fa-plus"></i>
            </b-button>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-tab>
  </b-tabs>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "AdvanceCarouselSettings",
  data() {
    return {
      isLoading: false,
      transalationLanguage: "english",
      label: undefined,
      optionValue: undefined,
      optiponText: undefined
    };
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },
  created() {
    EventBus.$on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
    });
  },
  mounted() {
    this.label = this.fieldData.label[this.transalationLanguage];
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    tabClick(language) {
      let tempKey = language.toLowerCase();
      this.transalationLanguage = tempKey;
      this.label = this.fieldData.label[this.transalationLanguage];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.showToast("success", "Language Update");
    },

    addOptionText(value, index) {
      this.optiponText = value;
      this.fieldData.options[index].text[this.transalationLanguage] = value;
    },
    addOptionValue(value, index) {
      this.optionValue = value;
      this.fieldData.options[index].value = value;
    },

    addOption() {
      let newOptiopn = {
        value: this.optionValue,
        text: {
          english: this.optiponText
        }
      };
      this.fieldData.options.push(newOptiopn);
    },

    onDeleteOption(index) {
      if (this.fieldData.options.length) {
        this.fieldData.options.splice(this.fieldData.options.indexOf(index), 1);
      }
    },

    showToast(variant = null, message) {
      this.$bvToast.toast(message, {
        // title: `Clone ${variant || "default"}`,
        title: "Alert",
        variant,
        solid: true
      });
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean
  }
};
</script>
