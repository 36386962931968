<template>
  <div>
    <b-card style="max-height: 633px">
      <div class="text-center">
        <h4 style="font-size: 17px">Avg. Third Party Risk Score</h4>
      </div>
      <b-row>
        <b-col cols="6" style="align-self: center">
          <div v-if="avgRiskScorePercent.length">
            <span v-for="(item, index) in avgRiskScorePercent" :key="index">
              <div
                class="d-flex"
                style="justify-content: center; font-size: 47px"
              >
                <div>
                  <h1
                    :style="
                      item.avg == 0
                        ? 'color:#4D9DE0;font-size:56px;'
                        : item.status == 1
                        ? 'color : #e71d36; font-size:56px;'
                        : 'color : #3ddc97; font-size:56px;'
                    "
                  >
                    {{ item.avg }}%
                  </h1>
                </div>
                <div v-if="item.avg != 0">
                  <span v-if="item && item.status == 1">
                    <font-awesome-icon
                      style="font-size: 50px; color: #e71d36"
                      icon="arrow-up"
                    />
                  </span>
                  <span v-else-if="item && item.status == 0">
                    <font-awesome-icon
                      style="font-size: 50px; color: #3ddc97"
                      icon="arrow-down"
                    />
                  </span>
                </div>
              </div>
              <p class="text-center">
                Avg. Third Party Risk Score
                <span>
                  <b
                    ><span
                      :style="
                        item.avg == 0
                          ? 'color:#4D9DE0'
                          : item.status == 1
                          ? 'color:#e71d36'
                          : 'color:#3ddc97'
                      "
                    >
                      {{
                        item.avg == 0
                          ? "DID NOT CHANGE"
                          : item.status == 1
                          ? "INCREASED"
                          : "DECREASED"
                      }}</span
                    ></b
                  >
                </span>
                BETWEEN PERIOD 1 AND 2
              </p>
            </span>
          </div>
        </b-col>
        <b-col cols="6">
          <b-overlay :show="riskScoreChartLoading" class="my-4" rounded="sm">
            <div>
              <div v-for="(item, index) in riskOpt" :key="index">
                <highcharts :options="riskOpt[index]" />
              </div>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "AverageSupplierRiskComparativeComponent",
  props: {
    avgRiskScorePercent: Array,
    riskOpt: Array,
    riskScoreChartLoading: Boolean
  }
};
</script>
