const actionName = {
  PLATFORM_SETTINGS: {
    FETCH_PUBLIC_PLATFORM_SETTINGS:
      "platformSettings/fetchPublicPlatformSettings",
    FETCH_PLATFORM_SETTINGS: "platformSettings/fetchPlatformSettings",
    UPDATE_PLATFORM_SETTINGS: "platformSettings/updatePlatformSettings",
    FETCH_PLATFORM_USER_LIST: "platformSettings/fetchPlatformUserList",
    FETCH_PRODUCT_VERSIONS: "platformSettings/fetchProductVersions"
  },
  AUTH: {
    LOG_IN: "auth/logIn",
    LOG_OUT: "auth/logOut",
    FETCH_EB360_APP_DATA: "auth/fetchEB360AppData"
  },
  USER_SETTINGS: {
    GET_USER_SETTINGS: "userSettings/getUserSettings"
  },
  THIRD_PARTY: {
    FETCH_T_CERTIFICATION_OPTIONS: "thirdParty/fetchTCertificationOptions",
    FETCH_ESG_METRICS_OPTIONS: "thirdParty/fetchESGMetricsOptions",
    FETCH_FORMS_LANGUAGES_LIST: "thirdParty/fetchFormsLanguagesList",
    FETCH_OC_STATES_LIST: "thirdParty/fetchOCStatesList",
    FETCH_HEADER_CONFIG_DATA: "thirdParty/fetchHeaderConfigData",
    FETCH_EDD_CLIENT_ALL_PACKAGES: "thirdParty/fetchEDDClientAllPackages",
    FETCH_CUSTOM_ATTRIBUTES: "thirdParty/fetchCustomAttributes",
    FETCH_ALL_THIRD_PARTY_DATA: "thirdParty/fetchAllThirdPartyData",
    FETCH_PLATFORM_FEATURE: "thirdParty/fetchPlatformFeature",
    FETCH_THIRD_PARTIES_UBOS: "thirdParty/fetchThirdPartyUBOs",
    CREATE_THIRD_PARTY_FROM_UBO: "thirdParty/createThirdPartyFromUBO"
  },
  ACTIONS: {
    FETCH_ACTIONS: "actions/fetchActions",
    FETCH_ACTION: "actions/fetchAction",
    SAVE_ACTION: "actions/saveAction",
    AMEND_ACTION: "actions/amendAction",
    DELETE_ACTION: "actions/deleteAction",
    CLONE_ACTION: "actions/cloneAction",
    FETCH_EXPECTED_DATA_ENTITIES: "actions/fetchExpectedDataEntities",
    FETCH_ENTITY_DYNAMIC_REFERENCE: "actions/fetchEntitiesDynamicReference"
  },
  ROLES: {
    FETCH_ROLES: "roles/fetchRoles",
    FETCH_ROLE: "roles/fetchRole",
    CREATED_ROLE: "roles/createRole",
    UPDATE_ROLE: "roles/updateRole",
    DELETE_ROLE: "roles/deleteRole"
  },
  COMPANY: {
    SELECT_COMPANY: "company/selectCompany",
    FETCH_COMPANIES: "company/fetchCompanies",
    FETCH_COMPANY_USERS: "company/fetchCompanyUsers",
    UPDATE_COMPANY_USER: "company/updateCompanyUser",
    ADD_COMPANY_USER: "company/addCompanyUser",
    DELETE_COMPANY_USER: "company/deleteCompanyUser",
    FETCH_ADDITIONAL_VOUCHERS: "company/fetchAdditionalVouchers"
  },
  ESG: {
    FETCH_ESG_REPORTS: "esg/fetchESGReports",
    FETCH_ESG_REPORT_BY_ID: "esg/fetchESGReportById"
  },
  CUSTOM_ATTRIBUTES: {
    FETCH_CUSTOM_ATTRIBUTES_LIST: "customAttributes/fetchCustomAttributesList"
  },
  USER_EMAIL_TEMPLATES: {
    FETCH_USER_EMAIL_TEMPLATES: "userEmailTemplates/fetchUserEmailTemplates",
    FETCH_USER_EMAIL_TEMPLATE: "userEmailTemplates/fetchUserEmailTemplate",
    MAKE_USER_EMAIL_TEMPLATE: "userEmailTemplates/makeUserEmailTemplate",
    UPDATE_USER_EMAIL_TEMPLATE: "userEmailTemplates/updateUserEmailTemplate",
    CLONE_USER_EMAIL_TEMPLATE: "userEmailTemplates/cloneUserEmailTemplate",
    DELETE_USER_EMAIL_TEMPLATE: "userEmailTemplates/deleteUserEmailTemplate"
  }
};

export { actionName };
