<template id="template-signature">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      :label="fieldData.label[selectedLanguage]"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <b-collapse
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>

      <h3 class="text-left mb-5">
        {{
          form1.enableSectionFieldEnumeration == 1
            ? sectionIndex + 1 + "." + (fieldIndex + 1)
            : ""
        }}
        {{
          this.fieldData.questionText[selectedLanguage] ||
          label[selectedLanguage]
        }}
      </h3>
      <b-form-group
        class="text-left"
        v-if="this.fieldData.questionAnswerInputType == 2"
      >
        <b-form-checkbox-group
          v-for="(answer, answerIndex) in this.fieldData.answers"
          :key="answerIndex"
          name="answer"
        >
          <b-form-checkbox>{{ answer.answer }}</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <div v-if="fieldData.questionAnswerInputType == 1">
        <b-form-group
          class="text-left"
          v-for="(answer, index) in fieldData.answers"
          :key="index"
        >
          <b-form-radio v-model="selectedAnswer" :value="answer.answer">{{
            answer.answer
          }}</b-form-radio>
        </b-form-group>
      </div>

      <b-form-group
        class="text-left"
        v-if="this.fieldData.questionAnswerInputType == 3"
      >
        <b-button
          pill
          variant="outline-primary"
          class="mr-3"
          v-for="(answer, index) in this.fieldData.answers"
          :key="index"
          >{{ answer.answer }}</b-button
        >
      </b-form-group>
      <span>You selected answer:</span>
      <span style="color: red">you are required to select one</span>
      <p v-if="this.fieldData.questionTimeLimit > 0">
        <span>Show question timer here</span>
      </p>
      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="lg"
        v-model="showSettings"
        scrollable
        title="Exam Question Settings"
      >
        <ExamQuestionSettings
          :fieldData="fieldData"
          :sectionIndex="sectionIndex"
          v-on:toggle-field="showSettings = !showSettings"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import ExamQuestionSettings from "../advanceExamQuestionSettingsCompenent";
import MenuButtons from "../menuButtons";
import { mapState, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "Question",
  components: {
    ExamQuestionSettings,
    MenuButtons
  },

  data() {
    return {
      selectedAnswers: [],
      questionType: "multiple-choice",
      questionTimeLimit: null,
      questionText: null,
      questionLevel: 1,
      questionImage: null,
      questionScore: null,
      selectedAnswer: "",

      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: {
        english: "Question Title goes here"
      },
      fields: [
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        },
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        },
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        },
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(vlue) {
      this.collapse = vlue;
    }
  },
  mounted() {},
  methods: {
    onOpen() {
      this.$refs.popover.$emit("open");
    },

    onClose() {
      this.popoverShow = false;
    },

    focusRef() {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {});
    },
    onSaveData() {
      EventBus.$emit("onSaveSettings", "saving settings");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
