<template>
  <b-card-body class="total-third-parties">
    <h4 class="total-third-parties__title">
      Total Number of Third Parties
    </h4>
    <!-- total third parties card start -->
    <b-card
        :bg-variant="'info'"
        text-variant="white"
        class="text-center"
    >
      <b-card-title>{{$t('eb360.analyticsTabChart.thirdPartyCard')}}</b-card-title>
      <b-card-text
          style="font-size: 50px; color: #515254"
          class="text-success text-center"
      >
          <span style="color: #ffff; font-weight: bold">
              {{totalNumberOfThirdParties}}
          </span>
      </b-card-text>
    </b-card>
      <!-- total third parties card end -->
  </b-card-body>
</template>

<script>
export default {
  name: "TotalTPComponent",
  props:{
    totalNumberOfThirdParties: Number
  }
}
</script>

<style lang="scss" scoped>
.total-third-parties {
  border: $card-border;

  &__title {
    @include heading-four;
    margin-bottom: 16px;
  }
}
</style>
