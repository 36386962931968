import { pullAt, isEqual } from "lodash";
import { changeLogConstants } from "@/constants";
import { isObject, isFunction, isDate, isValue } from "@/utils";

const setChangeTypeBasedOnValues = (value1, value2) => {
  if (value1 === value2) {
    return changeLogConstants.VALUE_UNCHANGED;
  }

  if (
    isDate(value1) &&
    isDate(value2) &&
    value1.getTime() === value2.getTime()
  ) {
    return changeLogConstants.VALUE_UNCHANGED;
  }

  if (value1 === undefined) {
    return changeLogConstants.VALUE_CREATED;
  }

  if (value2 === undefined) {
    return changeLogConstants.VALUE_DELETED;
  }

  return changeLogConstants.VALUE_UPDATED;
};

const findIndexesOfMatchingValuesFromTwoArrays = (item1 = [], item2 = []) => {
  const indexes = {
    item1: [],
    item2: []
  };

  for (let index = 0; index < item1.length; index++) {
    for (let index2 = 0; index2 < item2.length; index2++) {
      if (isEqual(item1[index], item2[index2])) {
        indexes.item1.push(index);
        indexes.item2.push(index2);
      }
    }
  }

  return indexes;
};

const makeDifferenceValueBasedOnType = ({
  item1,
  item2,
  _setChangeTypeBasedOnValues = setChangeTypeBasedOnValues
} = {}) => {
  const type = _setChangeTypeBasedOnValues(item1, item2);

  if (type === changeLogConstants.VALUE_UNCHANGED) {
    return undefined;
  }

  return {
    type,
    [changeLogConstants.KEY_ORIGINAL]: item1,
    [changeLogConstants.KEY_UPDATED]: item2
  };
};

const findDeepDifferences = ({
  item1,
  item2,
  _makeDifferenceValueBasedOnType = makeDifferenceValueBasedOnType,
  _findIndexesOfMatchingValuesFromTwoArrays = findIndexesOfMatchingValuesFromTwoArrays,
  _findDeepDifferences = findDeepDifferences
} = {}) => {
  if (isFunction(item1) || isFunction(item2)) {
    throw new Error("Invalid argument. Function given");
  }

  if (isValue(item1) || isValue(item2)) {
    return _makeDifferenceValueBasedOnType({ item1, item2 });
  }

  // For Array values - find matching values and remove them, regardless of index.
  if (Array.isArray(item1) && Array.isArray(item2)) {
    const indexes = _findIndexesOfMatchingValuesFromTwoArrays(item1, item2);

    pullAt(item1, indexes?.item1);
    pullAt(item2, indexes?.item2);
  }

  const differences = {};
  const foundKeys = {};

  for (let key in item1) {
    foundKeys[key] = true;

    const mapValue = _findDeepDifferences({
      item1: item1[key],
      item2: item2[key]
    });

    if (mapValue) {
      differences[key] = mapValue;
    }
  }

  for (let key in item2) {
    if (!foundKeys[key]) {
      const mapValue = _findDeepDifferences({
        item1: undefined,
        item2: item2[key]
      });
      if (mapValue) {
        differences[key] = mapValue;
      }
    }
  }

  return Object.keys(differences)?.length ? differences : undefined;
};

const getAllDeepObjectsWithKey = ({
  item,
  targetKey = "",
  currentPath = [],
  _getAllDeepObjectsWithKey = getAllDeepObjectsWithKey
}) => {
  const result = [];

  if (item && isObject(item)) {
    // Check if the current object has the target key.
    if (item.hasOwnProperty(targetKey)) {
      result.push({ path: currentPath, value: item });
    }

    // Recursively explore the nested objects.
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const newPath = [...currentPath, key];
        const deepResults = _getAllDeepObjectsWithKey({
          item: item[key],
          targetKey,
          currentPath: newPath
        });
        result.push(...deepResults);
      }
    }
  }

  return result;
};

export {
  setChangeTypeBasedOnValues,
  findIndexesOfMatchingValuesFromTwoArrays,
  makeDifferenceValueBasedOnType,
  findDeepDifferences,
  getAllDeepObjectsWithKey
};
