<template>
  <div
    class="tooltip__container"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
    ref="tooltip-container"
  >
    <portal to="tooltip">
      <TooltipTextBox
        v-if="isTooltipVisible"
        ref="tooltip"
        :baseText="text"
        :style="getStyles"
        :maximum-width="maximumTooltipSize"
      />
    </portal>
    <BaseIcon
      v-if="icon"
      :icon="icon"
      :shape="iconShape"
      :size="iconSize"
      :theme="iconTheme"
    />
  </div>
</template>

<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import TooltipTextBox from "@/molecules/TooltipTextBox/TooltipTextBox";
import { position, shapes, themes } from "@/constants";
import { isValidTheme } from "@/utils";

export default {
  name: "Tooltip",
  components: {
    BaseIcon,
    TooltipTextBox
  },
  props: {
    icon: {
      type: String,
      default: "info"
    },
    iconShape: {
      type: String,
      default: shapes.CIRCLE
    },
    iconSize: {
      type: Number,
      default: 12
    },
    iconTheme: {
      type: String,
      default: themes.VERY_STRONG_INVERSE,
      validator(value) {
        return isValidTheme(value);
      }
    },
    position: {
      type: String,
      default: position.TOP_RIGHT,
      validator: (value) =>
        [
          position.TOP_RIGHT,
          position.TOP_LEFT,
          position.BOTTOM_RIGHT,
          position.BOTTOM_LEFT
        ].includes(value)
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isTooltipVisible: false,
      tooltipPositionTop: 0,
      tooltipPositionLeft: 0,
      paddingBuffer: 5,
      maximumTooltipSize: 300,
      tooltipIsLeft: false,
      tooltipIsTop: false,
      leftPositions: [position.TOP_LEFT, position.BOTTOM_LEFT],
      rightPositions: [position.TOP_RIGHT, position.BOTTOM_RIGHT],
      bottomPositions: [position.BOTTOM_LEFT, position.BOTTOM_RIGHT],
      topPositions: [position.TOP_LEFT, position.TOP_RIGHT]
    };
  },
  computed: {
    getStyles() {
      let transFormStyles = {};

      if (this.tooltipIsLeft && this.tooltipIsTop) {
        transFormStyles = {
          transform: `translate(calc(-100% - ${
            this.iconSize * 2 + this.paddingBuffer
          }px), calc(-100% + ${this.iconSize * 2}px))`
        };
      } else if (this.tooltipIsLeft) {
        transFormStyles = {
          transform: `translateX(calc(-100% - ${
            this.iconSize * 2 + this.paddingBuffer
          }px))`
        };
      } else if (this.tooltipIsTop) {
        transFormStyles = {
          transform: `translateY(calc(-100% + ${this.iconSize * 2}px))`
        };
      }
      return {
        left: `${this.iconSize * 2 + this.tooltipPositionLeft}px`,
        top: `${this.tooltipPositionTop}px`,
        position: "absolute",
        ...transFormStyles
      };
    }
  },
  methods: {
    async showTooltip() {
      this.isTooltipVisible = true;
      await this.$nextTick();
      await this.setTooltipPosition();
    },
    isLeftPosition() {
      return this.leftPositions.includes(this.position);
    },
    isRightPosition() {
      return this.rightPositions.includes(this.position);
    },
    isBottomPosition() {
      return this.bottomPositions.includes(this.position);
    },
    isTopPosition() {
      return this.topPositions.includes(this.position);
    },
    setTooltipPositionWithinWindow() {
      const {
        left: documentLeft,
        right: documentRight,
        top: documentTop,
        bottom: documentBottom
      } = document.documentElement.getBoundingClientRect();
      const {
        left: tooltipLeft,
        right: tooltipRight,
        top: tooltipTop,
        bottom: tooltipBottom,
        width: tooltipWidth
      } = this.$refs.tooltip?.$el?.getBoundingClientRect();
      const isToolTipTooSmallAgainstRightSideOfScreen =
        documentRight === tooltipRight &&
        tooltipWidth < this.maximumTooltipSize;

      if (documentLeft > tooltipLeft && this.isLeftPosition()) {
        this.tooltipIsLeft = false;
      } else if (
        (documentRight < tooltipRight ||
          isToolTipTooSmallAgainstRightSideOfScreen) &&
        this.isRightPosition()
      ) {
        this.tooltipIsLeft = true;
      }

      if (documentTop > tooltipTop && this.isTopPosition()) {
        this.tooltipIsTop = false;
      } else if (documentBottom < tooltipBottom && this.isBottomPosition()) {
        this.tooltipIsTop = true;
      }
    },
    async setTooltipPosition() {
      this.tooltipIsLeft = false;
      this.tooltipIsTop = false;
      const { left: containerLeft, top: containerTop } =
        this.$refs["tooltip-container"].getBoundingClientRect();

      this.tooltipPositionTop =
        containerTop + document.documentElement.scrollTop;
      this.tooltipPositionLeft =
        containerLeft + document.documentElement.scrollLeft;

      if (this.isLeftPosition()) {
        this.tooltipIsLeft = true;
      }

      if (this.isTopPosition()) {
        this.tooltipIsTop = true;
      }

      await this.$nextTick();
      this.setTooltipPositionWithinWindow();
    },
    hideTooltip() {
      this.isTooltipVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip {
  &__container {
    position: relative;
    display: inline-flex;
  }
}
</style>
