const themes = {
  NONE: "none",
  INVERSE: "inverse",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  SUCCESS_WHITE: "success-white",
  SUCCESS_INVERSE: "success-inverse",
  WARNING: "warning",
  WARNING_INVERSE: "warning-inverse",
  ERROR: "error",
  ERROR_INVERSE: "error-inverse",
  STRONG_ERROR: "strong-error",
  STRONG_ERROR_INVERSE: "strong-error-inverse",
  MUTED_GREEN: "muted-green",
  MUTED_GREEN_INVERSE: "muted-green-inverse",
  STRONG_GREEN: "strong-green",
  STRONG_ROBIN: "strong-robin",
  STRONG_GREEN_INVERSE: "strong-green-inverse",
  MUTED_YELLOW: "muted-yellow",
  MUTED_YELLOW_INVERSE: "muted-yellow-inverse",
  VERY_STRONG: "very-strong",
  VERY_STRONG_INVERSE: "very-strong-inverse",
  STRONG: "strong",
  MODERATE: "moderate",
  WEAK: "weak",
  GREY: "grey",
  DARK_GREY: "dark-grey",
  DARK_GREY_INVERSE: "dark-grey-inverse",
  ETHIXBASE_PRIMARY: "ethixbase-primary",
  ETHIXBASE_PRIMARY_INVERSE: "ethixbase-primary-inverse"
};

const typographySize = {
  BODY_TEXT: "body-text",
  BODY_TEXT_SMALL: "body-text--small",
  BODY_TEXT_ITALIC: "body-text--italic",
  BODY_TEXT_BOLD: "body-text--bold",
  HEADING_ONE: "heading--one",
  HEADING_TWO: "heading--two",
  LARGE_TEXT: "large-text",
  DISPLAY_TEXT: "display-text"
};

const sizes = {
  DEFAULT: "",
  LARGE: "large"
};

const space = {
  NONE: "none",
  SMALL: "small",
  MODERATE: "moderate",
  LARGE: "large"
};

const border = {
  SOLID: "solid",
  DASHED: "dashed",
  DOTTED: "dotted"
};

const chartStyles = {
  TITLE: {
    fontSize: "1.4rem",
    fontWeight: "400",
    color: "#000000",
    fill: "#000000",
    fontFamily: "Roboto, sans-serif;"
  }
};

export { space, sizes, typographySize, themes, border, chartStyles };
