<template>
  <div>
    <!-- title="Preview Email Template" -->
    <b-modal
      :title="'Preview' + ' ' + templateName + ' ' + 'Template'"
      v-model="showAddAdminEmailSettingModal"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <div>
        <b-row v-if="!submiting && !responseSuccess">
          <b-col cols="6">
            <b-form-input
              v-model="$v.form.emailAddress.$model"
              :state="
                $v.form.emailAddress.$dirty
                  ? !$v.form.emailAddress.$error
                  : null
              "
              placeholder="Write your email here....."
              type="email"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="
                $v.form.emailAddress.$dirty &&
                $v.form.emailAddress.$error &&
                !$v.form.emailAddress.required
              "
              >Please Enter Email Address</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-if="
                $v.form.emailAddress.$dirty &&
                $v.form.emailAddress.$error &&
                !$v.form.emailAddress.email
              "
              >Please Enter valid Email</b-form-invalid-feedback
            >
          </b-col>
          <b-col cols="6">
            <multiselect
              :maxHeight="220"
              v-model="$v.form.selectedLanguageCodes.$model"
              :state="
                $v.form.selectedLanguageCodes.$dirty
                  ? !$v.form.selectedLanguageCodes.$error
                  : null
              "
              :class="{
                'form-control is-invalid p-0':
                  $v.form.selectedLanguageCodes.$error
              }"
              label="languages"
              track-by="languages"
              placeholder="Select Language"
              :multiple="true"
              :options="langauagesOptions"
              @input="setEmailLanguages"
            >
            </multiselect>
            <b-form-invalid-feedback
              v-if="
                $v.form.selectedLanguageCodes.$dirty &&
                $v.form.selectedLanguageCodes.$error &&
                !$v.form.selectedLanguageCodes.required
              "
              >Please Select a language</b-form-invalid-feedback
            >
          </b-col>
        </b-row>
        <div v-else-if="submiting && !responseSuccess">
          <div style="display: grid; justify-content: center">
            <p>Email Templates is now being sending......</p>
            <div style="display: flex; justify-content: center">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            Email templates have been sent successfully.
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="!submiting" variant="outline-danger" @click="resetModal"
          >Close</b-button
        >
        <b-button
          v-if="!submiting && !responseSuccess"
          variant="outline-success"
          @click="checkValidation"
          >Send</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { Helper } from "@/helpers";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],
  name: "EmailTemplateFormModal",
  data() {
    return {
      showAddAdminEmailSettingModal: false,
      emailTemplateId: null,
      submiting: false,
      responseSuccess: false,
      langauagesOptions: [],
      form: {
        emailAddress: null,
        selectedLanguageCodes: []
      },
      selectedCodes: []
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    })
  },
  created() {
    EventBus.$on("triggerEmailModal", (data) => {
      this.emailTemplateId = null;
      this.showAddModal(data);
    });
  },
  validations: {
    form: {
      emailAddress: {
        required,
        email
      },
      selectedLanguageCodes: {
        required
      }
    }
  },
  methods: {
    setEmailLanguages() {
      this.selectedCodes = [];
      this.form.selectedLanguageCodes.forEach((languages) => {
        this.selectedCodes.push(languages.language_code);
      });
    },
    showAddModal(data) {
      this.emailTemplateId = data.item.id;
      let templateData = data.item.template_data
        ? JSON.parse(data.item.template_data)
        : null;
      this.langauagesOptions = templateData.languages
        ? templateData.languages
        : [];
      this.showAddAdminEmailSettingModal = true;
    },
    resetModal() {
      this.form = {
        emailAddress: null,
        selectedLanguageCodes: []
      };
      this.emailTemplateId = [];
      this.submiting = false;
      this.responseSuccess = false;
      this.selectedCodes = [];
      this.showAddAdminEmailSettingModal = false;
      this.$v.$reset();
    },
    checkValidation() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      } else {
        this.previewEmailApiCall();
      }
    },
    makeToster(tosterVarient, tosterMsg) {
      this.resetModal();
      Helper.makeToast(this.$bvToast, tosterVarient, tosterMsg);
    },
    async previewEmailApiCall() {
      let formData = {
        email_template_id: this.emailTemplateId,
        company_id: this.companyId,
        email_address: this.form.emailAddress,
        email_language_codes: this.selectedCodes
      };
      try {
        this.submiting = true;
        let result = await HTTP(
          "post",
          "v1/dynamic-templates/previwe-email",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster(
            "success",
            "Email templates have been sent successfully."
          );
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
      } catch (err) {
        this.submiting = false;
        this.responseSuccess = false;
        this.makeToster("warning", "Oops!! something went wrong!! ");
        return [err];
      }
    }
  },
  props: {
    templateName: String
  }
};
</script>
<style>
.modal-body-style {
  overflow-x: hidden;
}
</style>
