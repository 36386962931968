<template id="template-select">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    class="text-left"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <!-- preview tab -->
    <b-collapse
      :class="isPreview ? 'mt-0' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :id="fieldData.id"
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperText[selectedLanguage]"
            :invalid-feedback="fieldData.validationMessage[selectedLanguage]"
          >
            <label>
              <span
                v-if="
                  fieldData.validation && fieldData.validation.required == 1
                "
                class="required-span"
                >*</span
              >
              {{
                form1.enableSectionFieldEnumeration == 1
                  ? sectionIndex + 1 + "." + (fieldIndex + 1)
                  : ""
              }}
              {{ label ? label : fieldData.label[selectedLanguage] }}
            </label>
            <v-select
              :id="fieldData.id"
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchable"
              :clearable="fieldData.configuration.isClearable"
              :multiple="fieldData.configuration.isMultipleSelect"
              label="country_name"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :options="renderOptions"
              :value="selectedCountry1"
              @input="checkCondition"
            >
              <template slot="option" slot-scope="option">
                <country-flag
                  v-if="option.country_code"
                  :country="option.country_code"
                  size="small"
                />
                <span>{{ "&nbsp;" + option.country_name }}</span>
              </template>
              <template #no-options>
                {{ $t("questionnaires.noSearchOptionText") }}
              </template>
            </v-select>
            <label
              v-if="
                parseInt(fieldData.validation?.required) === 1 &&
                isFocus &&
                !selectedCountry1?.length
              "
              class="text-danger"
              >{{ $t("createProfile.requiredValidationMessage") }}</label
            >
          </b-form-group>
        </b-col>
      </b-row>
      <!-- advance settings tab -->

      <b-modal
        cancel-title="Close"
        @ok="onSaveData"
        ok-title="save"
        size="xl"
        v-model="showSettings"
        title="Select Picker Advanced Settings"
      >
        <AdvanceSelectSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          v-on:update-form-settings="getData"
          :sectionIndex="sectionIndex"
          @label-text="label = $event"
          @helper-text="helperText = $event"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import MenuButtons from "../menuButtons";
import AdvanceSelectSettings from "@/molecules/AdvanceRadioSettingsComponent/AdvanceRadioSettingsComponent.vue";
import CountryCodes from "@/utils/CpiCountryCodes.json";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "CpiCountriesSelectGroup",
  components: {
    MenuButtons,
    AdvanceSelectSettings,
    vSelect
  },
  data() {
    return {
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      helperText: undefined,
      isRequired: 1,
      name: "",
      renderOptions: CountryCodes.countries.filter(
        (country) => country.cpi2018 !== null
      ),
      selectedCountry: [],
      selectedCountry1: null,
      selectedCpi: null
    };
  },
  created() {
    EventBus.$on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          this.fieldData.validation &&
          this.fieldData.validation.required == 1
        ) {
          if (!this.selectedCountry1?.length) {
            this.isFocus = true;
            this.$store.state.validFields = false;
            this.fieldData.isInValid = true;
          } else {
            this.$store.state.validFields = true;
            this.fieldData.isInValid = false;
          }
        }
      }
    });

    EventBus.$on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse", "userFormSubmitArray"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  mounted() {
    this.isRequired = this.fieldData.validation.required;

    // let getOptions = this.fieldData.options;
    // getOptions.map(option => {
    // 	let code = option || "No Value Set";
    // 	this.renderOptions.push(code);
    // });
    if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
      this.setFieldValue();
    }
  },
  methods: {
    getData(data) {
      this.$emit("update-form-settings", data);
    },

    checkCondition(value) {
      this.selectedCountry1 = value;
      this.selectedCpi = value.cpi2018 || 0;
      this.isFocus = false;
      this.$store.state.validFields = true;
      this.fieldData.isInValid = false;

      this.setValue(value);
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.forEach((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements.forEach((el) => {
              this.selectedCountry1 = el.postValue || null;
            });
          }
        });
    },

    setValue(e) {
      const registerData = clone(this.$store.state.registerData);
      if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
        // this.fieldData.selected = this.selectedCountry;
        // let aa = [];
        // this.fieldData.selected.map(c => {
        // 	aa.push(c.name);
        // });
        if (e) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;

        if (
          this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelect &&
          this.selectedCountry1.length
        ) {
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                postValue: this.selectedCountry1,
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameCountries"
              };
            }
          });
        } else if (
          this.selectedCountry1 &&
          (this.selectedCountry1.name || this.selectedCountry1.country_name)
        ) {
          let aa = [];
          aa.push(this.selectedCountry1);
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                postValue: aa,
                postName:
                  (this.fieldData.attributes &&
                    this.fieldData.attributes.postName) ||
                  "postNameCountries"
              };
            }
          });
        }
      }
    },

    onSaveData() {
      EventBus.$emit("onSaveSettings", "saving settings");
    }
  },

  validations: {
    selected: {
      required
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $white !important;
}
</style>
