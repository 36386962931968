<template>
  <table class="sticky-table" data-test-id="sticky-table" v-bind="$attrs">
    <caption
      v-if="caption"
      class="sticky-table__caption"
      data-test-id="sticky-table__caption"
    >
      {{
        caption
      }}
    </caption>
    <thead class="sticky-table__head">
      <TableHeader
        v-for="(
          {
            text,
            value,
            sortable,
            sortOrder,
            componentOptions,
            component,
            styles
          },
          index
        ) in tableHeaders"
        :key="makeStringWithSeparatorWrapper(value)"
        :table-header="text"
        :sortable="sortable"
        :sort-order="sortOrder"
        :header-position="index"
        :component="component"
        :component-options="componentOptions"
        :styles="styles"
        @click="onColumnHeaderClick"
      />
    </thead>
    <tbody class="sticky-table__body">
      <TableRow
        v-for="(tableRow, tableRowLevel) in tableRows"
        :key="`table-row-${tableRowLevel}`"
        :table-row="tableRow"
        :table-headers="tableHeaders"
        :table-row-level="tableRow.tableRowLevel"
        :styles="tableRow.styles"
        @click="onTableRow($event, tableRowLevel)"
      />
    </tbody>
  </table>
</template>

<script>
import TableHeader from "@/molecules/TableHeader/TableHeader";
import TableRow from "@/molecules/TableRow/TableRow";
import { makeStringWithSeparator } from "@/utils";

export default {
  name: "StickyTable",
  components: {
    TableRow,
    TableHeader
  },
  props: {
    tableHeaders: {
      type: Array,
      default: () => []
    },
    tableRows: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: ""
    }
  },
  methods: {
    makeStringWithSeparatorWrapper(value) {
      return `sticky-table-header-row-${makeStringWithSeparator(value, "-")}`;
    },
    onColumnHeaderClick(columnPosition, columnSortOrder) {
      this.$emit("table-header-click", columnPosition, columnSortOrder);
    },
    onTableRow(value, tableRowLevel) {
      this.$emit("table-row-click", value, tableRowLevel);
    }
  }
};
</script>

<style lang="scss" scoped>
.sticky-table {
  width: 100%;

  &__caption {
    @include body-text-bold;

    caption-side: top;
    padding: 5px;
    background-color: $ghost-white;
    color: $black;

    & + .sticky-table__head {
      border-top: 1px;
    }
  }
}
</style>
