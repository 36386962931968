import { toCamel } from "@/utils";

const isObject = (object) =>
  object === Object(object) &&
  !Array.isArray(object) &&
  typeof object !== "function";

const keysToCamel = (object) => {
  if (isObject(object)) {
    const newObject = {};

    Object.keys(object).forEach((objectKey) => {
      newObject[toCamel(objectKey)] = keysToCamel(object[objectKey]);
    });

    return newObject;
  } else if (Array.isArray(object)) {
    return object.map((index) => keysToCamel(index));
  }

  return object;
};

export { isObject, keysToCamel };
