import actions from "@/store/esg/esg.actions.store";
import state from "@/store/esg/esg.state.store";
import mutations from "@/store/esg/esg.mutations.store";
import getters from "@/store/esg/esg.getters.store";

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}