import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeESGReportsSummaryDTO,
  makeEsgThirdPartiesDTO,
  makeSingleESGReportDTO,
  makeEsgExportReportAPIDTO
} from "./dto/esg.dto";

const fetchEsgRatings = (companyId, formData) =>
  HTTP(
    "get",
    endpoints.ESG_OVERALL_RATINGS(companyId),
    null,
    makeAuthorizationHeader(),
    formData
  );

const fetchEsgResilienceStrength = (companyId) =>
  HTTP(
    "get",
    endpoints.ESG_RESILIENCE_STRENGTH(companyId),
    null,
    makeAuthorizationHeader()
  );

const fetchEsgRiskSeverity = (companyId) =>
  HTTP(
    "get",
    endpoints.ESG_RISK_SEVERITY(companyId),
    null,
    makeAuthorizationHeader()
  );

const fetchEsgStatusData = (companyId) =>
  HTTP("get", endpoints.ESG_STATUS(companyId), null, makeAuthorizationHeader());

const fetchScoreByRiskAndResilience = (companyId) =>
  HTTP(
    "get",
    endpoints.ESG_SCORE_BY_RISK_AND_RESILIENCE(companyId),
    null,
    makeAuthorizationHeader()
  );

const exportESGReports = (payload) =>
  HTTP(
    "post",
    endpoints.ESG_EXPORT_REPORTS,
    makeEsgExportReportAPIDTO(payload),
    makeAuthorizationHeader()
  );

const fetchEsgThirdParties = async (companyId, query = "") => {
  const result = await HTTP(
    "get",
    endpoints.ESG_THIRD_PARTIES(companyId),
    null,
    makeAuthorizationHeader(),
    query
  );
  result.data = makeEsgThirdPartiesDTO(result.data);
  return result;
};

const fetchESGReports = async (endpointParams) => {
  const { data = [] } = await HTTP(
    "get",
    endpoints.ESG_THIRD_PARTY_LIST_OF_REPORTS(endpointParams),
    null,
    makeAuthorizationHeader()
  );

  return makeESGReportsSummaryDTO(data);
};

const fetchESGReportById = async (endpointParams) => {
  const { data = {} } = await HTTP(
    "get",
    endpoints.ESG_THIRD_PARTY_SINGLE_REPORT(endpointParams),
    null,
    makeAuthorizationHeader()
  );

  return makeSingleESGReportDTO(data);
};

export const esgService = {
  fetchEsgRatings,
  fetchEsgResilienceStrength,
  fetchEsgRiskSeverity,
  fetchEsgStatusData,
  fetchScoreByRiskAndResilience,
  fetchEsgThirdParties,
  fetchESGReports,
  fetchESGReportById,
  exportESGReports
};
