<template>
  <component
    v-if="isErrorAvailable"
    :is="getErrorWrapperTag"
    class="input-error"
    data-test-id="input-error"
  >
    <BaseText
      v-for="{ errorMessage, id } in errors"
      :key="id"
      class="input-error-message"
      :text="errorMessage"
      theme="error-inverse"
      :tag="getErrorTag"
      :size="typographySize.BODY_TEXT_SMALL"
    />
  </component>
</template>

<script>
import { typographySize } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import { makeUUID } from "@/utils";

export default {
  name: "InputErrorMessage",
  components: {
    BaseText
  },
  data() {
    return {
      typographySize
    };
  },
  props: {
    error: {
      required: true,
      validator: (prop) =>
        typeof prop === "string" ||
        Array.isArray(prop) ||
        prop === null ||
        prop === undefined
    }
  },
  computed: {
    isErrorAvailable() {
      if (Array.isArray(this.error)) {
        return !!this.error.length;
      } else {
        return !!this.error;
      }
    },
    hasMultipleErrorMessages() {
      return Array.isArray(this.error) && !!this.error.length;
    },
    errors() {
      return this.hasMultipleErrorMessages
        ? this.error.reduce((acc, errorMessage) => {
            acc.push({ errorMessage, id: makeUUID() });
            return acc;
          }, [])
        : [{ errorMessage: this.error, id: makeUUID() }];
    },
    getErrorTag() {
      return this.hasMultipleErrorMessages ? "li" : "span";
    },
    getErrorWrapperTag() {
      return this.errors.length > 1 ? "ul" : "div";
    }
  }
};
</script>

<style lang="scss" scoped>
.input-error {
  padding-top: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
