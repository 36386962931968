<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Text" active>
      <b-tabs pills card vertical>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div>
            <b-form-group label="Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="label"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>

          <div>
            <b-form-group label="Helper Text">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="helperText"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- ----------------Advanced------------------- -->
    <b-tab title="Advanced">
      <div @submit.stop.prevent class="text-left mt-4">
        <div class="col-12">
          <div class="form-group col-md-6">
            <label>Minimum Range</label>
            <b-form-input v-model="min" type="number"></b-form-input>
          </div>
          <div class="form-group col-md-6">
            <label>Maximum Range</label>
            <b-form-input v-model="max" type="number"></b-form-input>
          </div>
          <br />
        </div>
        <button class="btn btn-primary btn-sm ml-4" @click="onUpdatSettings">
          Update
        </button>
      </div>
    </b-tab>
  </b-tabs>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "AdvanceRangeSettings",
  data() {
    return {
      isLoading: false,
      transalationLanguage: "english",
      label: undefined,
      helperText: undefined,
      min: this.fieldData.interval,
      max: this.fieldData.controls
    };
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },
  created() {
    EventBus.$on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
    });
  },
  mounted() {
    this.label = this.fieldData.label[this.transalationLanguage];
    this.helperText = this.fieldData.helperText[this.transalationLanguage];
    this.min = this.fieldData.min;
    this.max = this.fieldData.max;
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    tabClick(language) {
      let tempKey = language.toLowerCase();
      this.transalationLanguage = tempKey;
      this.label = this.fieldData.label[this.transalationLanguage];
      this.helperText = this.fieldData.helperText[this.transalationLanguage];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.showToast("success", "Language Update");
    },

    onUpdatSettings() {
      this.fieldData.min = this.min;
      this.fieldData.max = this.max;
      this.fieldData.helperText[this.transalationLanguage] = this.helperText;
    },

    showToast(variant = null, message) {
      this.$bvToast.toast(message, {
        // title: `Clone ${variant || "default"}`,
        title: "Alert",
        variant,
        solid: true
      });
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean
  }
};
</script>
