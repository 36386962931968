<template>
  <div class="error-message">
    <p class="error-message__icon" :style="getStyles()">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    </p>
    <p class="error-message__text">{{ text }}</p>
  </div>
</template>

<script>
import EB2ColorPallete from "@/utils/eb2ColorPallete.json";

export default {
  name: "ErrorMessage",
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  methods: {
    getStyles() {
      return {
        color: EB2ColorPallete.EB2ColorPallete.chatsRedColor,
        fontSize: "2em"
      };
    }
  }
};
</script>

<style scoped>
.error-message {
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 25px;
}
</style>
