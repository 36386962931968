var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isReady)?_c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2',attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"isHidden":_vm.isHidden,"label":_vm.label,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(JSON.parse(!_vm.fieldData.isHidden) || !_vm.isPreview),expression:"JSON.parse(!fieldData.isHidden) || !isPreview"}],style:({ textAlign: 'left' })},[(_vm.isRequired)?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_c('span',{staticClass:"d-inline-flex"},[_vm._v(_vm._s(parseInt(_vm.form1.enableSectionFieldEnumeration) === 1 ? _vm.sectionIndex + 1 + "." + (_vm.fieldIndex + 1) : " ")+" "),_c('p',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.fieldData.registeredLabel[_vm.selectedLanguage]))])])]),_c('b-collapse',{directives:[{name:"show",rawName:"v-show",value:(JSON.parse(!_vm.fieldData.isHidden) || !_vm.isPreview),expression:"JSON.parse(!fieldData.isHidden) || !isPreview"}],staticClass:"card",attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[(parseInt(_vm.fieldData.isConditional) === 1 && !_vm.isPreview)?_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"outline-dark","disabled":"","title":"This field is hidden by default and will display bt is controlled element"}},[_c('font-awesome-icon',{attrs:{"icon":"eye-slash"}})],1)],1)],1):_vm._e(),_c('div',{class:_vm.$store.state.rightAlign
          ? 'card-body text-right'
          : 'card-body text-left',attrs:{"id":_vm.fieldData.id}},[_c('p',[_vm._v(_vm._s(_vm.fieldData.registeredDescription[_vm.selectedLanguage]))]),_c('b-form-checkbox',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.fieldData.isNaFeature || parseInt(_vm.fieldData.isNaFeature) === 0
        ),expression:"\n          !fieldData.isNaFeature || parseInt(fieldData.isNaFeature) === 0\n        "}],attrs:{"value":"1","unchecked-value":"0"},on:{"input":_vm.onClilckNa},model:{value:(_vm.fieldData.isNa),callback:function ($$v) {_vm.$set(_vm.fieldData, "isNa", $$v)},expression:"fieldData.isNa"}},[_vm._v(" "+_vm._s(_vm.$t("na"))+" ")]),(_vm.isToggle)?_c('b-spinner'):_c('div',{staticClass:"form-row",attrs:{"id":_vm.fieldData.id}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.fieldData.advance ||
            !_vm.fieldData.advance.registeredFormCountryDisabled
          ),expression:"\n            !fieldData.advance ||\n            !fieldData.advance.registeredFormCountryDisabled\n          "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registeredAddressCountryHelperText[_vm.selectedLanguage]}},[_c('label',{staticClass:"text-left"},[(
                  _vm.fieldData.validation &&
                  _vm.fieldData.validation.isRequireRegistrationFormCountry
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registeredAddressCountryLabel[_vm.selectedLanguage])+" ")]),_c('v-select',{class:_vm.$v.registrationForm.country.$error
                  ? 'border border-danger rounded'
                  : null,attrs:{"disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1,"label":"name","state":_vm.$v.registrationForm && _vm.$v.registrationForm.country.$dirty
                  ? !_vm.$v.registrationForm.country.$error
                  : null,"options":_vm.optionsCountries},on:{"input":_vm.onSelectedRegistrationForm},nativeOn:{"blur":function($event){return _vm.$v.registrationForm.$touch()}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(option.icon)?_c('country-flag',{attrs:{"country":option.icon,"size":"small"}}):_vm._e(),_c('span',[_vm._v(_vm._s(" " + option.name))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("questionnaires.noSearchOptionText"))+" ")]},proxy:true}],null,false,1646153422),model:{value:(_vm.$v.registrationForm.country.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.country, "$model", $$v)},expression:"$v.registrationForm.country.$model"}}),(
                _vm.$v.registrationForm.country.$error &&
                _vm.fieldData.validation.isRequireRegistrationFormCountry &&
                !_vm.registrationForm.country
              )?_c('label',{staticClass:"text-danger",staticStyle:{"font-size":"80%"}},[_vm._v(_vm._s(_vm.$t("createProfile.requiredValidationMessage")))]):_vm._e()],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.fieldData.advance ||
            !_vm.fieldData.advance.registeredFormCityDisabled
          ),expression:"\n            !fieldData.advance ||\n            !fieldData.advance.registeredFormCityDisabled\n          "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registeredAddressCityStateHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',{class:_vm.fieldData.validation.isRequireRegistrationFormCity
                  ? 'text-left mt-0'
                  : 'text-left mt-2'},[(
                  _vm.fieldData.validation &&
                  _vm.fieldData.validation.isRequireRegistrationFormCity
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registeredAddressCityStateLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1,"type":"text","placeholder":_vm.fieldData.registeredAddressCityStatePlaceholder[
                  _vm.selectedLanguage
                ],"state":_vm.$v.registrationForm && _vm.$v.registrationForm.cityState.$dirty
                  ? !_vm.$v.registrationForm.cityState.$error
                  : null},on:{"input":_vm.checkFormProgress},nativeOn:{"blur":function($event){return _vm.$v.registrationForm.$touch()}},model:{value:(_vm.$v.registrationForm.cityState.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.cityState, "$model", $$v)},expression:"$v.registrationForm.cityState.$model"}})],1)],1)]),_c('div',{staticClass:"form-row",attrs:{"id":_vm.fieldData.id}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.fieldData.advance ||
            !_vm.fieldData.advance.registeredFormAddress1Disabled
          ),expression:"\n            !fieldData.advance ||\n            !fieldData.advance.registeredFormAddress1Disabled\n          "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registeredAddressLine1HelperText[_vm.selectedLanguage],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',{staticClass:"text-left"},[(
                  _vm.fieldData.validation &&
                  _vm.fieldData.validation.isRequireRegistrationFormAddress1
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registeredAddressLine1Label[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1,"placeholder":_vm.fieldData.registeredAddressLine1Placeholder[_vm.selectedLanguage],"state":_vm.$v.registrationForm && _vm.$v.registrationForm.address.$dirty
                  ? !_vm.$v.registrationForm.address.$error
                  : null},on:{"input":_vm.checkFormProgress},nativeOn:{"blur":function($event){return _vm.$v.registrationForm.$touch()}},model:{value:(_vm.$v.registrationForm.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.address, "$model", $$v)},expression:"$v.registrationForm.address.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.fieldData.advance ||
            !_vm.fieldData.advance.registeredFormAddress2Disabled
          ),expression:"\n            !fieldData.advance ||\n            !fieldData.advance.registeredFormAddress2Disabled\n          "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registeredAddressLine2HelperText[_vm.selectedLanguage],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',{staticClass:"text-left"},[(
                  _vm.fieldData.validation &&
                  _vm.fieldData.validation.isRequireRegistrationFormAddress2
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registeredAddressLine2Label[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1,"type":"text","placeholder":_vm.fieldData.registeredAddressLine2Placeholder[_vm.selectedLanguage],"state":_vm.$v.registrationForm && _vm.$v.registrationForm.addressTwo.$dirty
                  ? !_vm.$v.registrationForm.addressTwo.$error
                  : null},on:{"input":_vm.checkFormProgress},nativeOn:{"blur":function($event){return _vm.$v.registrationForm.$touch()}},model:{value:(_vm.$v.registrationForm.addressTwo.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.addressTwo, "$model", $$v)},expression:"$v.registrationForm.addressTwo.$model"}})],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.fieldData.advance ||
            !_vm.fieldData.advance.registeredFormTownDisabled
          ),expression:"\n            !fieldData.advance ||\n            !fieldData.advance.registeredFormTownDisabled\n          "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registeredAddressTownHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',{staticClass:"text-left"},[(
                  _vm.fieldData.validation &&
                  _vm.fieldData.validation.isRequireRegistrationFormTown
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registeredAddressTownLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1,"type":"text","placeholder":_vm.fieldData.registeredAddressTownPlaceholder[_vm.selectedLanguage],"state":_vm.$v.registrationForm && _vm.$v.registrationForm.town.$dirty
                  ? !_vm.$v.registrationForm.town.$error
                  : null},on:{"input":_vm.checkFormProgress},nativeOn:{"blur":function($event){return _vm.$v.registrationForm.$touch()}},model:{value:(_vm.$v.registrationForm.town.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.town, "$model", $$v)},expression:"$v.registrationForm.town.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.fieldData.advance || !_vm.fieldData.advance.registeredFormZipDisabled
          ),expression:"\n            !fieldData.advance || !fieldData.advance.registeredFormZipDisabled\n          "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registeredAddressPostalZipCodeHelperText[
                _vm.selectedLanguage
              ],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',{staticClass:"text-left"},[(
                  _vm.fieldData.validation &&
                  _vm.fieldData.validation.isRequireRegistrationFormZip
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registeredAddressPostalZipCodeLabel[ _vm.selectedLanguage ])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1,"type":"text","placeholder":_vm.fieldData.registeredAddressPostalZipCodePlaceholder[
                  _vm.selectedLanguage
                ],"state":_vm.$v.registrationForm && _vm.$v.registrationForm.zipCode.$dirty
                  ? !_vm.$v.registrationForm.zipCode.$error
                  : null,"id":"inputZip"},on:{"input":_vm.checkFormProgress},nativeOn:{"blur":function($event){return _vm.$v.registrationForm.$touch()}},model:{value:(_vm.$v.registrationForm.zipCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.zipCode, "$model", $$v)},expression:"$v.registrationForm.zipCode.$model"}})],1)],1)]),(_vm.showFreeForm)?_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.fieldData.advance ||
            !_vm.fieldData.advance.registrationFormFreeFormDisabled
          ),expression:"\n            !fieldData.advance ||\n            !fieldData.advance.registrationFormFreeFormDisabled\n          "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.registrationFormFreeFormHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',{staticClass:"text-left"},[(
                  _vm.fieldData.validation &&
                  JSON.parse(
                    _vm.fieldData.validation.isRequireRegistrationFreeForm
                  )
                )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.registrationFormFreeFormLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1,"placeholder":_vm.fieldData.registrationFormFreeFormPlaceholderText[
                  _vm.selectedLanguage
                ],"state":_vm.$v.registrationForm && _vm.$v.registrationForm.freeForm.$dirty
                  ? !_vm.$v.registrationForm.freeForm.$error
                  : null,"type":"text"},on:{"input":_vm.checkFormProgress},nativeOn:{"blur":function($event){return _vm.$v.registrationForm.$touch()}},model:{value:(_vm.$v.registrationForm.freeForm.$model),callback:function ($$v) {_vm.$set(_vm.$v.registrationForm.freeForm, "$model", $$v)},expression:"$v.registrationForm.freeForm.$model"}})],1)],1)]):_vm._e()],1),_c('div',{class:_vm.$store.state.rightAlign
          ? 'card-body text-right'
          : 'card-body text-left',attrs:{"id":_vm.fieldData.id}},[_c('b-form-checkbox',{attrs:{"value":"1","unchecked-value":"0","disabled":parseInt(_vm.isDisabled) === 1 || parseInt(_vm.fieldData.isNa) === 1},on:{"input":_vm.onCheckSameAddress},model:{value:(_vm.tradingAndRegisteredAddressIsSame),callback:function ($$v) {_vm.tradingAndRegisteredAddressIsSame=$$v},expression:"tradingAndRegisteredAddressIsSame"}},[_vm._v(_vm._s(_vm.fieldData.checkBoxLabel[_vm.selectedLanguage]))]),_c('div',[_c('h5',{attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[_vm._v(" "+_vm._s(_vm.fieldData.tradingLabel[_vm.selectedLanguage])+" ")]),_c('p',{attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[_vm._v(" "+_vm._s(_vm.fieldData.tradingDescription[_vm.selectedLanguage])+" ")]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.tradingFormCountryDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.tradingFormCountryDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.tradingAddressAddressCountryHelperText[
                  _vm.selectedLanguage
                ]}},[_c('label',[(
                    _vm.fieldData.validation &&
                    _vm.fieldData.validation.isRequireTradingFormCountry
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.tradingAddressCountryLabel[_vm.selectedLanguage])+" ")]),_c('v-select',{class:_vm.$v.tradingForm.country.$error
                    ? 'border border-danger rounded'
                    : null,attrs:{"disabled":parseInt(_vm.isDisabled) === 1 ||
                  parseInt(_vm.tradingAndRegisteredAddressIsSame) !== 0,"label":"name","state":_vm.$v.tradingForm && _vm.$v.tradingForm.country.$dirty
                    ? !_vm.$v.tradingForm.country.$error
                    : null,"options":_vm.optionsCountries},on:{"input":_vm.onSelectedTradingForm},nativeOn:{"blur":function($event){return _vm.$v.tradingForm.$touch()}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(option.icon)?_c('country-flag',{attrs:{"country":option.icon,"size":"small"}}):_vm._e(),_c('span',[_vm._v(_vm._s(" " + option.name))])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("questionnaires.noSearchOptionText"))+" ")]},proxy:true}],null,false,1646153422),model:{value:(_vm.$v.tradingForm.country.$model),callback:function ($$v) {_vm.$set(_vm.$v.tradingForm.country, "$model", $$v)},expression:"$v.tradingForm.country.$model"}}),(
                  _vm.$v.tradingForm.country.$error &&
                  _vm.fieldData.validation.isRequireTradingFormCountry &&
                  !_vm.tradingForm.country
                )?_c('label',{staticClass:"text-danger",staticStyle:{"font-size":"80%"}},[_vm._v(_vm._s(_vm.$t("createProfile.requiredValidationMessage")))]):_vm._e()],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.tradingFormCityDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.tradingFormCityDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"disabled":_vm.tradingAndRegisteredAddressIsSame != 0,"description":_vm.fieldData.tradingAddressAddressCityStateHelperText[
                  _vm.selectedLanguage
                ],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',{class:_vm.fieldData.validation.isRequireTradingFormCity
                    ? 'text-left mt-0'
                    : 'text-left mt-0'},[(
                    _vm.fieldData.validation &&
                    _vm.fieldData.validation.isRequireTradingFormCity
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.tradingAddressCityStateLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1,"type":"text","placeholder":_vm.fieldData.tradingAddressCityStatePlaceholder[
                    _vm.selectedLanguage
                  ],"state":_vm.$v.tradingForm && _vm.$v.tradingForm.cityState.$dirty
                    ? !_vm.$v.tradingForm.cityState.$error
                    : null},nativeOn:{"blur":function($event){return _vm.$v.tradingForm.$touch()}},model:{value:(_vm.$v.tradingForm.cityState.$model),callback:function ($$v) {_vm.$set(_vm.$v.tradingForm.cityState, "$model", $$v)},expression:"$v.tradingForm.cityState.$model"}})],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.tradingFormAddress1Disabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.tradingFormAddress1Disabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"disabled":_vm.tradingAndRegisteredAddressIsSame != 0,"description":_vm.fieldData.tradingAddressAddressLine1HelperText[
                  _vm.selectedLanguage
                ],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',[(
                    _vm.fieldData.validation &&
                    _vm.fieldData.validation.isRequireTradingFormAddress1
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.tradingAddressLine1Label[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1,"type":"text","placeholder":_vm.fieldData.tradingAddressLine1Placeholder[_vm.selectedLanguage],"state":_vm.$v.tradingForm && _vm.$v.tradingForm.address.$dirty
                    ? !_vm.$v.tradingForm.address.$error
                    : null},nativeOn:{"blur":function($event){return _vm.$v.tradingForm.$touch()}},model:{value:(_vm.$v.tradingForm.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.tradingForm.address, "$model", $$v)},expression:"$v.tradingForm.address.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.tradingFormAddress2Disabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.tradingFormAddress2Disabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"disabled":_vm.tradingAndRegisteredAddressIsSame != 0,"description":_vm.fieldData.tradingAddressAddressLine2HelperText[
                  _vm.selectedLanguage
                ],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',[(
                    _vm.fieldData.validation &&
                    _vm.fieldData.validation.isRequireTradingFormAddress2
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.tradingAddressLine2Label[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1,"type":"text","placeholder":_vm.fieldData.tradingAddressLine2Placeholder[_vm.selectedLanguage],"state":_vm.$v.tradingForm && _vm.$v.tradingForm.addressTwo.$dirty
                    ? !_vm.$v.tradingForm.addressTwo.$error
                    : null},nativeOn:{"blur":function($event){return _vm.$v.tradingForm.$touch()}},model:{value:(_vm.$v.tradingForm.addressTwo.$model),callback:function ($$v) {_vm.$set(_vm.$v.tradingForm.addressTwo, "$model", $$v)},expression:"$v.tradingForm.addressTwo.$model"}})],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.tradingFormTownDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.tradingFormTownDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"disabled":_vm.tradingAndRegisteredAddressIsSame != 0,"description":_vm.fieldData.tradingAddressAddressTownHelperText[
                  _vm.selectedLanguage
                ],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',[(
                    _vm.fieldData.validation &&
                    _vm.fieldData.validation.isRequireTradingFormTown
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.tradingAddressTownLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1,"type":"text","placeholder":_vm.fieldData.tradingAddressTownPlaceholder[_vm.selectedLanguage],"state":_vm.$v.tradingForm && _vm.$v.tradingForm.town.$dirty
                    ? !_vm.$v.tradingForm.town.$error
                    : null},nativeOn:{"blur":function($event){return _vm.$v.tradingForm.$touch()}},model:{value:(_vm.$v.tradingForm.town.$model),callback:function ($$v) {_vm.$set(_vm.$v.tradingForm.town, "$model", $$v)},expression:"$v.tradingForm.town.$model"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance || !_vm.fieldData.advance.tradingFormZipDisabled
            ),expression:"\n              !fieldData.advance || !fieldData.advance.tradingFormZipDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"disabled":_vm.tradingAndRegisteredAddressIsSame != 0,"description":_vm.fieldData.tradingAddressAddressPostalZipCodeHelperText[
                  _vm.selectedLanguage
                ],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',[(
                    _vm.fieldData.validation &&
                    _vm.fieldData.validation.isRequireTradingFormZip
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.tradingAddressPostalZipCodeLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1,"type":"text","placeholder":_vm.fieldData.tradingAddressPostalZipCodePlaceholder[
                    _vm.selectedLanguage
                  ],"state":_vm.$v.tradingForm && _vm.$v.tradingForm.zipCode.$dirty
                    ? !_vm.$v.tradingForm.zipCode.$error
                    : null,"id":"inputZip"},nativeOn:{"blur":function($event){return _vm.$v.tradingForm.$touch()}},model:{value:(_vm.$v.tradingForm.zipCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.tradingForm.zipCode, "$model", $$v)},expression:"$v.tradingForm.zipCode.$model"}})],1)],1)]),(_vm.showFreeForm)?_c('div',{staticClass:"form-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.fieldData.advance ||
              !_vm.fieldData.advance.tradingFormFreeFormDisabled
            ),expression:"\n              !fieldData.advance ||\n              !fieldData.advance.tradingFormFreeFormDisabled\n            "}],staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"description":_vm.fieldData.tradingFormFreeFormHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[_c('label',[(
                    _vm.fieldData.validation &&
                    JSON.parse(_vm.fieldData.validation.isRequireTradingFreeForm)
                  )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.tradingFormFreeFormLabel[_vm.selectedLanguage])+" ")]),_c('b-form-input',{attrs:{"disabled":parseInt(_vm.isDisabled) === 1,"placeholder":_vm.fieldData.tradingFormFreeFormPlaceholderText[
                    _vm.selectedLanguage
                  ],"state":_vm.$v.tradingForm && _vm.$v.tradingForm.freeForm.$dirty
                    ? !_vm.$v.tradingForm.freeForm.$error
                    : null,"type":"text"},nativeOn:{"blur":function($event){return _vm.$v.tradingForm.$touch()}},model:{value:(_vm.$v.tradingForm.freeForm.$model),callback:function ($$v) {_vm.$set(_vm.$v.tradingForm.freeForm, "$model", $$v)},expression:"$v.tradingForm.freeForm.$model"}})],1)],1)]):_vm._e()])],1),_c('b-modal',{attrs:{"cancel-title":"Close","ok-title":"save","size":"xl","scrollable":"","title":"Advanced Settings"},on:{"ok":_vm.onSaveData},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[_c('AdvanceSettings',{attrs:{"fieldData":_vm.fieldData,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }