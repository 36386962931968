<template>
  <section class="actions">
    <BaseLoader v-if="isLoadingTableData" />
    <template v-else>
      <Modal
        class="actions__modal"
        v-if="isModalVisible"
        title="Delete Action"
        @close-modal="closeModal"
      >
        <div class="actions__modal-text">
          <BaseText :text="makeModalText" />
        </div>
        <template #left>
          <CallToAction
            type="button"
            theme="error-inverse"
            value="Cancel"
            data-test-id="actions__modal-cancel-cta"
            @click="onCancelDeleteAction"
          />
        </template>
        <template #right>
          <CallToAction
            type="button"
            value="Delete"
            data-test-id="actions__modal-delete-cta"
            @click="deleteActionWrapper"
          />
        </template>
      </Modal>
      <div class="actions__operations">
        <CallToAction
          theme="strong"
          value="Add"
          data-test-id="actions__operations-cta"
          @click="onCTAAdd"
        />
      </div>
      <BaseText
        :text="operationFeedback"
        theme="success-inverse"
        data-test-id="actions__operations-feedback"
      />
      <InputErrorMessage :error="errorMessagesApi" />
      <StickyTable
        v-if="isDataAvailable"
        caption="Actions"
        class="actions__table"
        data-test-id="actions__table"
        :table-headers="actionsHeaders"
        :table-rows="actionsRows"
        @table-row-click="onTableRowClick"
      />
      <BaseText
        v-else
        text="You do not have any actions. Click the Add button to create one."
        data-test-id="actions__table-fallback-message"
      />
    </template>
  </section>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import DropDownList from "@/molecules/DropDownList/DropDownList";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { mapState, mapActions } from "vuex";
import {
  actionsListTableHeaders,
  typographySize,
  actionKeys,
  operations,
  externalUrls,
  urls,
  actionName,
  actionTypesText,
  actionTypes,
  actionsEvents
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Modal from "@/molecules/Modal/Modal";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { hasStatus500, hasStatus404, isNotAuthorized } from "@/utils";

export default {
  name: "Actions",
  components: {
    StickyTable,
    BaseText,
    CallToAction,
    InputErrorMessage,
    Modal,
    BaseLoader
  },
  data() {
    return {
      operationFeedback: "",
      errorMessagesApi: "",
      selectedAction: {
        id: "",
        name: ""
      },
      isModalVisible: false,
      isLoadingTableData: false,
      externalUrls
    };
  },
  computed: {
    ...mapState({
      actions: (state) => state.actions.actions
    }),
    makeActionsListForTable() {
      return this.actions.length
        ? this.actions.map(({ name, description, isSystem }) => ({
            [actionKeys.NAME]: name,
            [actionKeys.DESCRIPTION]: description,
            [actionKeys.IS_SYSTEM]: actionTypesText[isSystem],
            [actionKeys.ACTION]: "Action"
          }))
        : [];
    },
    isDataAvailable() {
      return !!this.actionsHeaders.length && !!this.actionsRows.length;
    },
    actionsHeaders() {
      return this.makeActionsListForTable.length
        ? Object.keys(this.makeActionsListForTable[0]).reduce((acc, key) => {
            if (actionsListTableHeaders[key]) {
              acc.push({
                value: key,
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: actionsListTableHeaders[key],
                  size: typographySize.BODY_TEXT_BOLD,
                  styles: {
                    margin: "0"
                  }
                },
                styles: {
                  verticalAlign: "top",
                  background: "white",
                  padding: "5px"
                }
              });
            }
            return acc;
          }, [])
        : [];
    },
    actionsRows() {
      return this.makeActionsListForTable.length
        ? this.makeActionsListForTable.map((action, index) =>
            Object.keys(action).reduce((acc, actionKey) => {
              const isSystemAction =
                action[actionKeys.IS_SYSTEM] ===
                actionTypesText[actionTypes.SYSTEM_ACTIONS];

              if (actionKey === actionKeys.ACTION && !isSystemAction) {
                acc[actionKey] = {
                  component: DropDownList,
                  componentOptions: {
                    dropDownList: [
                      {
                        value: JSON.stringify({
                          operation: operations.EDIT,
                          id: this.actions[index].id
                        }),
                        text: "Edit"
                      },
                      {
                        value: JSON.stringify({
                          operation: operations.CLONE,
                          id: this.actions[index].id
                        }),
                        text: "Clone"
                      },
                      {
                        value: JSON.stringify({
                          operation: operations.DELETE,
                          id: this.actions[index].id,
                          name: this.actions[index].name
                        }),
                        text: "Delete"
                      }
                    ],
                    style: {
                      display: "inline-block"
                    }
                  }
                };
              } else {
                const isMenuDisabled =
                  isSystemAction && actionKey === actionKeys.ACTION;

                acc[actionKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: isMenuDisabled ? "" : action[actionKey],
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              }
              return acc;
            }, {})
          )
        : [];
    },
    makeModalText() {
      return `Are you sure you want to delete the action, ${this.selectedAction.name}?`;
    }
  },
  methods: {
    ...mapActions({
      deleteAction: actionName.ACTIONS.DELETE_ACTION,
      cloneAction: actionName.ACTIONS.CLONE_ACTION
    }),
    onCancelDeleteAction() {
      this.resetSelectedAction();
      this.closeModal();
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onCTAAdd() {
      this.$emit(actionsEvents.RESET_ACTION);
      this.$router.push(urls.ADD_ACTION);
    },
    setSelectedAction(id = "", name = "") {
      this.selectedAction.id = id;
      this.selectedAction.name = name;
    },
    resetSelectedAction() {
      this.setSelectedAction("", "");
    },
    onTableRowClick(tableRowObj) {
      const { operation, id, name } = JSON.parse(tableRowObj);
      if (operation === operations.EDIT) {
        this.$router.push(urls.EDIT_ACTION_ID(id));
        this.$emit(actionsEvents.FETCH_ACTION, id);
      } else if (operation === operations.DELETE) {
        this.setSelectedAction(id, name);
        this.isModalVisible = true;
      } else if (operation === operations.CLONE) {
        this.cloneActionWrapper(id);
      }
    },
    async cloneActionWrapper(actionId) {
      try {
        this.isLoadingTableData = true;
        await this.cloneAction(actionId);
      } catch (error) {
        this.showError(error, "clone");
      } finally {
        this.isLoadingTableData = false;
      }
    },
    async deleteActionWrapper() {
      try {
        this.closeModal();
        this.isLoadingTableData = true;
        this.operationFeedback = "";
        this.errorMessagesApi = "";
        await this.deleteAction(this.selectedAction.id);
        this.operationFeedback = "Action deleted successfully";
      } catch (error) {
        this.showError(error, "delete");
      } finally {
        this.resetSelectedAction();
        this.isLoadingTableData = false;
      }
    },
    showError({ response } = {}, type = "") {
      if (hasStatus500(response)) {
        this.errorMessagesApi = "There is a server error. Please contact support.";
      } else if (hasStatus404(response)) {
        this.errorMessagesApi = `Failed to find and ${type} the action.`;
      } else if (!isNotAuthorized(response)) {
        this.errorMessagesApi = `There is an unknown error with status of ${response?.status}. Please contact support.`;
      }
    }
  },
  mounted() {
    this.operationFeedback = this.$route?.params?.message;
  }
};
</script>

<style lang="scss" scoped>
.actions {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &__modal-text {
    padding: 15px 15px 0;
  }

  &__operations {
    text-align: right;
    padding-bottom: 20px;
  }
}
</style>
