const operations = {
  ADD: "add",
  EDIT: "edit",
  CLONE: "clone",
  DELETE: "delete",
  CREATE: "create",
  ADD_BRACKET: "addBracket",
  DELETE_BRACKET: "deleteBracket",
  AND: "AND",
  OR: "OR",
  EQUALS: "EQUALS",
  NOT_EQUALS: "NOT_EQUALS",
  CONTAINS: "CONTAINS",
  NOT_CONTAINS: "NOT_CONTAINS",
  GREATER_THAN: "GREATERTHAN",
  LESS_THAN: "LESSTHAN",
  GREATER_THAN_OR_EQUAL_TO: "GREATERTHANOREQUALTO",
  LESS_THAN_OR_EQUAL_TO: "LESSTHANOREQUALTO",
  HAS_NO_VALUE: "HAS_NO_VALUE",
  HAS_VALUE: "HAS_VALUE",
  IN: "IN",
  HAS_ONE_OR_MORE_IN: "HASONEORMOREIN"
};

const numericBinaryOperator = {
  PLUS: "+",
  MINUS: "-",
  MULTIPLICATION: "*",
  DIVIDER: "/"
};

const brackets = {
  OPEN_PARENTHESES: "(",
  CLOSE_PARENTHESES: ")"
};

export { brackets, numericBinaryOperator, operations };
