<template>
  <div class="point-value">
    <BaseText
      class="point-value__value"
      data-test-id="point-value__value"
      :text="getText"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "PointValue",
  components: {
    BaseText
  },
  props: {
    count: {
      required: true,
      type: Number
    },
    totalEsgReports: {
      required: true,
      type: Number
    }
  },
  computed: {
    getText() {
      return [
        this.count,
        this.calculatePercentage(this.count, this.totalEsgReports)
      ];
    }
  },
  methods: {
    calculatePercentage(count, total) {
      const result = Math.round((count / total) * 100);
      return `${isNaN(result) ? 0 : result}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
.point-value {
  text-align: center;

  &:deep(.point-value__value:nth-child(1)),
  &:deep(.point-value__value:nth-child(2)) {
    color: $davy-grey;
    margin: 0px;
  }

  &:deep(.point-value__value:nth-child(1)) {
    @include body-text-bold;
    font-size: 40px;
    font-size: 2.5em;
  }
}
</style>
