<template id="template-section">
  <div class="border border-light p-3 pb-2 mb-1">
    <!-- field header -->
    <b-collapse
      :visible="
        form1.formTitle.english.toLowerCase().includes(formTitles.MSQ)
          ? !isHidden
          : !sectionData.isHidden
      "
    >
      <div
        :class="rightAlign ? 'text-right' : 'text-left'"
        v-if="
          (formTypeLocal == 1 || formTypeLocal == 9) &&
          sectionData.advance &&
          !sectionData.advance.hideSectionTitle
        "
      >
        <h3>
          <b-badge v-if="!rightAlign" variant="dark">{{ counter + 1 }}</b-badge>
          &nbsp;{{ sectionData.sectionTitle[selectedLanguage] }}
          <b-badge
            v-if="rightAlign && selectedLanguage == 'arabic'"
            variant="dark"
            >{{
              new Intl.NumberFormat("ar-EG", {}).format(counter + 1)
            }}</b-badge
          >
          <b-badge
            v-if="rightAlign && selectedLanguage == 'hebrew'"
            variant="dark"
            >{{ counter + 1 }}</b-badge
          >
        </h3>
        <br />
        <h5 class="text-justify">
          {{
            sectionData.sectionSubTitle &&
            sectionData.sectionSubTitle[selectedLanguage]
          }}
        </h5>
      </div>

      <ElementsContainer
        v-for="(fieldData, index) in sectionData.formElements"
        :key="index"
        :fieldData="fieldData"
        :isPreview="true"
        :isDisabled="isDisabled"
        :sectionIndex="sectionData.index"
        :fieldIndex="index"
        :inputType="fieldData.inputType"
      />
      <hr />
    </b-collapse>
  </div>
</template>

<script>
import ElementsContainer from "../components/templatesConstants/elementIndex";
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { formTitles } from "@/constants";

export default {
  name: "SectionUserContainer",
  components: {
    ElementsContainer
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      formTypeLocal: 0,
      collapse: true,
      sectionTitle: undefined,
      isRepeatable: 0,
      formTitles
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage", "rightAlign"]),
    ...mapState(["rightAlign"])
  },

  created() {
    //
    EventBus.$on("sectionCheck", (value) => {
      if (value.id == this.sectionData.id) {
        //
        this.sectionData.isHidden = value.isHidden;
        this.isHidden = value.isHidden;
        if (value.isHidden == false) {
          value.formElements[0].isHidden = false;
        }
        for (let item of this.sectionData.formElements) {
          for (let foundEle of this.$store.state.userFormSubmitArray) {
            if (foundEle.elementId === item.id) {
              // main new changes for section 5 and 7  22/2/22
              if (foundEle.elements.length) {
                if (value.isHidden == 1 || value.isHidden == true) {
                  foundEle.elements = [];
                  item.isHidden = value.isHidden;
                }
              }
              foundEle.isHiddenElement = value.isHidden ? 1 : 0;
            }
          }
        }
      }
    });
  },
  mounted() {
    this.formTypeLocal = this.formType || this.$store.state.form1.formType;
    this.forHiddenForStaticSection();
  },
  methods: {
    ...mapActions([
      "setSectionIndex",
      "setfieldIndex",
      "repeatSectionContentStore"
    ]),

    ...mapMutations(["ADD_FIELD"]),

    sectionCondition() {
      let sections = this.form1.formSection;
      let indigenousSection1 = sections.find(
        (sec) =>
          sec.sectionTitle[this.selectedLanguage] ==
          "Services/Goods (Additional Question)"
      );
      if (indigenousSection1) {
        let sec = this.form1.formSection[indigenousSection1.index];
        Object.assign(sec, (sec.isHidden = true));
        EventBus.$emit(
          "sectionCheck",
          this.form1.formSection[indigenousSection1.index]
        );
        this.form1.formSection[indigenousSection1.index].formElements.map(
          (ele) => {
            if (
              !this.form1.formTitle.english
                .toLowerCase()
                .includes("lieferkettengesetz") &&
              !this.form1.formTitle.english
                .toLowerCase()
                .includes("human rights questionnaire")
            ) {
              ele.validation.required = 0;
            }
            ele.isInValid = false;
          }
        );
      } else {
        let sec = this.form1.formSection[indigenousSection1.index];
        Object.assign(sec, (sec.isHidden = false));
        EventBus.$emit(
          "sectionCheck",
          this.form1.formSection[indigenousSection1.index]
        );
        this.form1.formSection[indigenousSection1.index].formElements.map(
          (ele) => {
            ele.validation.required = 1;
          }
        );
      }
    },

    onCloneSection(index, field) {
      let data = { index, field };
      this.repeatSectionContentStore(data);
    },
    forHiddenForStaticSection() {
      if (
        this.form1.formTitle.english.toLowerCase().includes(formTitles.MSQ) &&
        this.sectionData.sectionTitle.english === "Workforce"
      ) {
        this.sectionData.isHidden = false;
      }
    }
  },
  props: {
    sectionData: Object,
    sectionIndex: Number,
    isPreview: Boolean,
    formType: Number,
    isDisabled: Number,
    counter: Number
  }
};
</script>
<style scoped>
.section-preview {
  border: dashed 1px;
}
.section-height {
  min-height: 75px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
