<template id="template-references">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <!-- input content -->

    <b-collapse
      class="card mt-5"
      id="collapse-1"
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1'"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <div class="mt-3" :id="fieldData.id">
        <h4>{{ fieldData.label[selectedLanguage] }}</h4>
        <p>{{ fieldData.description[selectedLanguage] }}</p>
      </div>
      <b-modal
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        v-model="showAddRowModal"
        :title="
          isUpdtating
            ? $t('workflow.update') + ' ' + fieldData.label[selectedLanguage]
            : $t('workflow.add') + ' ' + fieldData.label[selectedLanguage]
        "
        size="xl"
      >
        <div
          :class="
            $store.state.rightAlign
              ? 'card-body text-right'
              : 'card-body text-left'
          "
        >
          <div class="form-row">
            <b-form-group
              class="col-md-6"
              :description="fieldData.refereeNameHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.refereeNameValidationMessage[selectedLanguage]
              "
              v-show="
                !fieldData.advance || !fieldData.advance.refereeNameDisabled
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireRefereeName)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.refereeNameLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                v-model="$v.form.refereeName.$model"
                :placeholder="
                  fieldData.refereeNamePlaceholderText[selectedLanguage]
                "
                :state="
                  $v.form && $v.form.refereeName.$dirty
                    ? !$v.form.refereeName.$error
                    : null
                "
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="col-md-6"
              :description="
                fieldData.refereePositionRoleHelperText[selectedLanguage]
              "
              :invalid-feedback="
                fieldData.refereePositionRoleValidationMessage[selectedLanguage]
              "
              v-show="
                !fieldData.advance ||
                !fieldData.advance.refereePositionRoleDisabled
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(
                      fieldData.validation.isRequireRefereePositionRole
                    )
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.refereePositionRoleLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="$v.form.refereePositionRole.$model"
                :placeholder="
                  fieldData.refereePositionRolePlaceholderText[selectedLanguage]
                "
                :state="
                  $v.form && $v.form.refereePositionRole.$dirty
                    ? !$v.form.refereePositionRole.$error
                    : null
                "
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="form-row">
            <b-form-group
              class="col-md-6"
              :description="
                fieldData.refereeCompanyHelperText[selectedLanguage]
              "
              :invalid-feedback="
                fieldData.refereeCompanyValidationMessage[selectedLanguage]
              "
              v-show="
                !fieldData.advance || !fieldData.advance.refereeCompanyDisabled
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireRefereeCompany)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.refereeCompanyLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="$v.form.refereeCompany.$model"
                :placeholder="
                  fieldData.refereeCompanyPlaceholderText[selectedLanguage]
                "
                :state="
                  $v.form && $v.form.refereeCompany.$dirty
                    ? !$v.form.refereeCompany.$error
                    : null
                "
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="col-md-6"
              :description="
                fieldData.refereeWebsiteHelperText[selectedLanguage]
              "
              :invalid-feedback="
                fieldData.refereeWebsiteValidationMessage[selectedLanguage]
              "
              v-show="
                !fieldData.advance || !fieldData.advance.refereeWebsiteDisabled
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireRefereeWebsite)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.refereeWebsiteLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="text"
                v-model="$v.form.refereeWebsite.$model"
                :placeholder="
                  fieldData.refereeWebsitePlaceholderText[selectedLanguage]
                "
                :state="
                  $v.form && $v.form.refereeWebsite.$dirty
                    ? !$v.form.refereeWebsite.$error
                    : null
                "
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="form-row">
            <b-form-group
              class="col-md-6"
              :description="fieldData.refereeEmailHelperText[selectedLanguage]"
              v-show="
                !fieldData.advance || !fieldData.advance.refereeEmailDisabled
              "
              :invalid-feedback="
                !$v.form.refereeEmail.email
                  ? $t('validations.invalidFormat')
                  : fieldData.refereeEmailValidationMessage[selectedLanguage]
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireRefereeEmail)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.refereeEmailLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                type="email"
                v-model="$v.form.refereeEmail.$model"
                :placeholder="
                  fieldData.refereeEmailPlaceholderText[selectedLanguage]
                "
                :state="
                  $v.form && $v.form.refereeEmail.$dirty
                    ? !$v.form.refereeEmail.$error
                    : null
                "
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="col-md-6"
              :description="fieldData.refereePhoneHelperText[selectedLanguage]"
              :invalid-feedback="
                fieldData.refereePhoneValidationMessage[selectedLanguage]
              "
              v-show="
                !fieldData.advance || !fieldData.advance.refereePhoneDisabled
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(fieldData.validation.isRequireRefereePhone)
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.refereePhoneLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                onkeydown="return event.keyCode !== 69"
                :disabled="isDisabled == 1"
                type="number"
                onpaste="return false;"
                v-model="$v.form.refereePhone.$model"
                :placeholder="
                  fieldData.refereePhonePlaceholderText[selectedLanguage]
                "
                :state="
                  $v.form && $v.form.refereePhone.$dirty
                    ? !$v.form.refereePhone.$error
                    : null
                "
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="form-row">
            <b-form-group
              class="col-md-6"
              :description="
                fieldData.refereeNatureOfRelationshipHelperText[
                  selectedLanguage
                ]
              "
              :invalid-feedback="
                fieldData.refereeNatureOfRelationshipValidationMessage[
                  selectedLanguage
                ]
              "
              v-show="
                !fieldData.advance ||
                !fieldData.advance.refereeNatureOfRelationshipDisabled
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(
                      fieldData.validation.isRequireRefereeNatureOfRelationship
                    )
                  "
                  class="required-span"
                  >*</span
                >
                {{
                  fieldData.refereeNatureOfRelationshipLabel[selectedLanguage]
                }}
              </label>
              <b-form-textarea
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                :disabled="isDisabled == 1"
                v-model="$v.form.refereeNatureOfRelationship.$model"
                :placeholder="
                  fieldData.refereeNatureOfRelationshipPlaceholderText[
                    selectedLanguage
                  ]
                "
                :state="
                  $v.form && $v.form.refereeNatureOfRelationship.$dirty
                    ? !$v.form.refereeNatureOfRelationship.$error
                    : null
                "
              ></b-form-textarea>
            </b-form-group>
            <b-form-group
              class="col-md-6"
              :description="
                fieldData.refereeLengthOfRelationHelperText[selectedLanguage]
              "
              :invalid-feedback="
                fieldData.refereeLengthOfRelationValidationMessage[
                  selectedLanguage
                ]
              "
              v-show="
                !fieldData.advance ||
                !fieldData.advance.refereeLengthOfRelationDisabled
              "
            >
              <label>
                <span
                  v-if="
                    fieldData.validation &&
                    JSON.parse(
                      fieldData.validation.isRequireRefereeLengthOfRelation
                    )
                  "
                  class="required-span"
                  >*</span
                >
                {{ fieldData.refereeLengthOfRelationLabel[selectedLanguage] }}
              </label>
              <b-form-input
                :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
                onkeydown="return event.keyCode !== 69"
                :disabled="isDisabled == 1"
                type="number"
                onpaste="return false;"
                v-model="$v.form.refereeLengthOfRelation.$model"
                :placeholder="
                  fieldData.refereeLengthOfRelationPlaceholderText[
                    selectedLanguage
                  ]
                "
                :state="
                  $v.form && $v.form.refereeLengthOfRelation.$dirty
                    ? !$v.form.refereeLengthOfRelation.$error
                    : null
                "
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="form-row">
            <div
              class="col-md-6"
              v-show="!fieldData.advance || !fieldData.advance.freeFormDisabled"
            >
              <div v-if="fieldData.freeFormLabel">
                <b-form-group
                  :description="fieldData.freeFormHelperText[selectedLanguage]"
                  :invalid-feedback="
                    fieldData.freeFormValidationMessage[selectedLanguage]
                  "
                >
                  <label>
                    <span
                      v-if="
                        fieldData.validation &&
                        JSON.parse(fieldData.validation.isRequireFreeForm)
                      "
                      class="required-span"
                      >*</span
                    >
                    {{ fieldData.freeFormLabel[selectedLanguage] }}
                  </label>
                  <b-form-input
                    :class="
                      $store.state.rightAlign ? 'text-right' : 'text-left'
                    "
                    :disabled="isDisabled == 1"
                    v-model="$v.form.freeForm.$model"
                    :placeholder="
                      fieldData.freeFormPlaceholderText[selectedLanguage]
                    "
                    :state="
                      $v.form && $v.form.freeForm.$dirty
                        ? !$v.form.freeForm.$error
                        : null
                    "
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button variant="danger" @click="resetModal">{{
            $t("closeBtnText")
          }}</b-button>
          <b-button
            variant="success"
            @click="isUpdtating ? updateRow() : addReferee()"
            >{{ isUpdtating ? $t("updateBtn") : $t("saveBtnText") }}</b-button
          >
        </template>
      </b-modal>

      <b-row v-if="taskStatus != 4">
        <b-col cols="12">
          <b-button
            :disabled="isDisabled == 1"
            class="mr-2 mt-2"
            variant="outline-success"
            size="lg"
            @click="showAddRowModal = !showAddRowModal"
          >
            <font-awesome-icon icon="plus" />
            {{ $t("workflow.add") }}
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12" class="p-5">
          <b-table-simple striped hover>
            <b-thead>
              <b-tr>
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.refereeNameDisabled
                  "
                  >{{ fieldData.refereeNameLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereePositionRoleDisabled
                  "
                  >{{
                    fieldData.refereePositionRoleLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeCompanyDisabled
                  "
                  >{{ fieldData.refereeCompanyLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeEmailDisabled
                  "
                  >{{ fieldData.refereeEmailLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereePhoneDisabled
                  "
                  >{{ fieldData.refereePhoneLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeWebsiteDisabled
                  "
                  >{{ fieldData.refereeWebsiteLabel[selectedLanguage] }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeNatureOfRelationshipDisabled
                  "
                  >{{
                    fieldData.refereeNatureOfRelationshipLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeLengthOfRelationDisabled
                  "
                  >{{
                    fieldData.refereeLengthOfRelationLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{
                    fieldData.freeFormLabel &&
                    fieldData.freeFormLabel[selectedLanguage]
                  }}</b-th
                >
                <b-th>{{ $t("upload.fileAction") }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in items" :key="index">
              <b-tr>
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.refereeNameDisabled
                  "
                  >{{ item.name }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereePositionRoleDisabled
                  "
                  >{{ item.position }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeCompanyDisabled
                  "
                  >{{ item.company }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeEmailDisabled
                  "
                  >{{ item.email }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereePhoneDisabled
                  "
                  >{{ item.phone }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeWebsiteDisabled
                  "
                  >{{ item.website }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeNatureOfRelationshipDisabled
                  "
                  >{{ item.relationship }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance ||
                    !fieldData.advance.refereeLengthOfRelationDisabled
                  "
                  >{{ item.length_of_relation }}</b-td
                >
                <b-td
                  v-if="
                    !fieldData.advance || !fieldData.advance.freeFormDisabled
                  "
                  >{{ item.free_form }}</b-td
                >
                <b-td>
                  <b-button
                    @click="onEditRow(item, index)"
                    size="sm"
                    class="mr-1"
                  >
                    <font-awesome-icon
                      :icon="taskStatus != 4 ? 'edit' : 'eye'"
                    />
                  </b-button>
                  <b-button
                    @click="onDeleteRow(index)"
                    variant="danger"
                    size="sm"
                    v-if="taskStatus != 4"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Advanced Settings"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import AdvanceSettings from "../workflowSettings/advanceTradeReferencesSettings.vue";
import { Helper } from "@/helpers";
import { validationMixin } from "vuelidate";
import { EventBus } from "@/event-bus.js";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "ReferencesGroup",
  mixins: [validationMixin],
  components: {
    MenuButtons,
    AdvanceSettings
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"])
  },
  data() {
    return {
      showFreeForm: false,
      showAddRowModal: false,
      items: [],
      tempElements: [],
      isHidden: false,
      condition: {},
      title: null,
      form: {
        refereeName: null,
        refereePositionRole: null,
        refereeCompany: null,
        refereeEmail: null,
        refereePhone: null,
        refereeWebsite: null,
        refereeNatureOfRelationship: null,
        refereeLengthOfRelation: 0,
        freeForm: null
      },
      label: null,
      showSettings: false,
      collapse: true,
      isUpdtating: false,
      selectedRowIndex: null,
      postData: [],
      taskStatus: 0
    };
  },

  watch: {
    selectedLanguage(newLanguage) {
      this.updateLabels(newLanguage);
    }
  },

  created() {
    EventBus.$on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.setDataToUser();
      } else {
        let validations = Object.values(this.fieldData.validation);
        const found = validations.find((value) => value == true);
        if (
          (this.items && this.items.length > 0) ||
          this.fieldData.isHidden ||
          found == undefined
        ) {
          this.setDataToUser();
          this.$store.state.validFields = true;
          this.fieldData.isInValid = false;
        } else {
          this.$store.state.validFields = false;
          this.fieldData.isInValid = true;
        }
      }
    });

    EventBus.$on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },

  mounted() {
    this.setFreeFormFields();
    this.setNewConditions();
    if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
      this.getDataForUser();
    }
    let st = parseInt(localStorage.getItem("activeTaskStatus"));
    this.taskStatus = st;
  },

  validations() {
    return {
      form: {
        refereeName: {
          [this.refereeName]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereeName
              ? required
              : !required
        },
        refereePositionRole: {
          [this.refereePositionRole]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereePositionRole
              ? required
              : !required
        },
        refereeCompany: {
          [this.refereeCompany]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereeCompany
              ? required
              : !required
        },
        refereeEmail: {
          required:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereeEmail
              ? required
              : !required,
          email
        },

        refereePhone: {
          [this.refereePhone]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereePhone
              ? required
              : !required
        },

        refereeWebsite: {
          [this.refereeWebsite]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereeWebsite
              ? required
              : !required
        },

        refereeNatureOfRelationship: {
          [this.refereeNatureOfRelationship]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereeNatureOfRelationship
              ? required
              : !required
        },

        refereeLengthOfRelation: {
          [this.refereeLengthOfRelation]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireRefereeLengthOfRelation
              ? required
              : !required
        },
        freeForm: {
          [this.freeForm]:
            this.fieldData.validation &&
            this.fieldData.validation.isRequireFreeForm
              ? required
              : !required
        }
      }
    };
  },

  methods: {
    setFreeFormFields() {
      if (this.fieldData.freeFormLabel == undefined) {
        Object.assign(this.fieldData, {
          freeFormLabel: {
            english: "Free Form"
          },
          freeFormPlaceholderText: {
            english: ""
          },
          freeFormHelperText: {
            english: ""
          },
          freeFormValidationMessage: {
            english: "This field is required"
          }
        });
        let validation = {
          ...this.fieldData.validation,
          isRequireFreeForm:
            this.fieldData.validation.isRequireFreeForm || false
        };
        Object.assign(this.fieldData, { validation });
        let advance = {
          ...this.fieldData.advance,
          freeFormDisabled: this.fieldData.advance.freeFormDisabled || true
        };
        Object.assign(this.fieldData, { advance });
        // this.showFreeForm = true;
      }
    },
    setNewConditions() {
      Object.assign(this.fieldData, {
        isInValid: this.fieldData.isInValid || null
      });
      Object.assign(this.fieldData, {
        isHidden: this.fieldData.isHidden || false
      });
      Object.assign(this.fieldData, {
        isConditional: this.fieldData.isConditional || 0
      });
      Object.assign(this.fieldData, {
        conditions:
          this.fieldData.conditions && this.fieldData.conditions.length
            ? this.fieldData.conditions
            : []
      });
      Object.assign(this.fieldData, {
        conditionalFields:
          this.fieldData.conditionalFields &&
          this.fieldData.conditionalFields.length
            ? this.fieldData.conditionalFields
            : []
      });
      Object.assign(this.fieldData, {
        sectionConditions:
          this.fieldData.sectionConditions &&
          this.fieldData.sectionConditions.length
            ? this.fieldData.sectionConditions
            : []
      });
    },

    addReferee() {
      const registerData = clone(this.$store.state.registerData);
      this.$v.form.$touch();
      if (this.$v.form.$anyError && this.$v.form.$invalid) {
        return false;
      }
      registerData[this.fieldData.id] = "trade-references";
      this.$store.state.registerData = registerData;

      // main calculations :p
      const numkeys = Object.keys(registerData).length;
      // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
      this.$store.state.progressValue = numkeys;

      let row = {
        name: this.form.refereeName,
        position: this.form.refereePositionRole,
        company: this.form.refereeCompany,
        email: this.form.refereeEmail,
        phone: this.form.refereePhone,
        website: this.form.refereeWebsite,
        relationship: this.form.refereeNatureOfRelationship,
        length_of_relation: this.form.refereeLengthOfRelation,
        free_form: this.form.freeForm,

        actions: "&nbsp;"
      };

      if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
        let aa = [
          {
            postValue: row.name,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.refereeNamePostName) ||
              "refereeNamePostName",
            key: "name",
            labelKey: "refereeNameLabel",
            label: this.fieldData.refereeNameLabel[this.selectedLanguage]
          },
          {
            postValue: row.position,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.refereePositionRolePostName) ||
              "refereePositionRolePostName",
            key: "position",
            labelKey: "refereePositionRoleLabel",
            label:
              this.fieldData.refereePositionRoleLabel[this.selectedLanguage]
          },
          {
            postValue: row.company,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.refereeCompanyPostName) ||
              "refereeCompanyPostName",
            key: "company",
            labelKey: "refereeCompanyLabel",
            label: this.fieldData.refereeCompanyLabel[this.selectedLanguage]
          },
          {
            postValue: row.email,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.refereeEmailPostName) ||
              "refereeEmailPostName",
            key: "email",
            labelKey: "refereeEmailLabel",
            label: this.fieldData.refereeEmailLabel[this.selectedLanguage]
          },
          {
            postValue: row.phone,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.refereePhonePostName) ||
              "refereePhonePostName",
            key: "phone",
            labelKey: "refereePhoneLabel",
            label: this.fieldData.refereePhoneLabel[this.selectedLanguage]
          },
          {
            postValue: row.website,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.refereeWebsitePostName) ||
              "refereeWebsitePostName",
            key: "website",
            labelKey: "refereeWebsiteLabel",
            label: this.fieldData.refereeWebsiteLabel[this.selectedLanguage]
          },
          {
            postValue: row.relationship,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes
                  .refereeNatureOfRelationshipPostName) ||
              "refereeNatureOfRelationshipPostName",
            key: "relationship",
            labelKey: "refereeNatureOfRelationshipLabel",
            label:
              this.fieldData.refereeNatureOfRelationshipLabel[
                this.selectedLanguage
              ]
          },
          {
            postValue: row.length_of_relation,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.refereeLengthOfRelationPostName) ||
              "refereeLengthOfRelationPostName",
            key: "length_of_relation",
            labelKey: "refereeLengthOfRelationLabel",
            label:
              this.fieldData.refereeLengthOfRelationLabel[this.selectedLanguage]
          },
          {
            postValue: row.free_form,
            postName:
              (this.fieldData.attributes &&
                this.fieldData.attributes.freeFormPostName) ||
              "freeFormPostName",
            key: "free_form",
            labelKey: "freeFormLabel",
            label: this.fieldData.freeFormLabel[this.selectedLanguage]
          }
        ];
        this.postData.push(aa);
        this.items.push(row);
      }

      this.resetModal();
    },

    updateLabels(newLanguage) {
      this.postData[0].forEach((data) => {
        data.label = this.fieldData?.[data.labelKey]?.[newLanguage];
      });
    },

    getDataForUser() {
      this.$store.state.getUserFormSubmitArray.map((data) => {
        if (data.elements && data.elements.length > 0) {
          data.elements.map((element) => {
            if (data.elementId === this.fieldData.id) {
              let row = {
                name: element[element.findIndex((x) => x.key == "name")]
                  .postValue,
                position:
                  element[element.findIndex((x) => x.key == "position")]
                    .postValue,
                company:
                  element[element.findIndex((x) => x.key == "company")]
                    .postValue,
                email:
                  element[element.findIndex((x) => x.key == "email")].postValue,
                phone:
                  element[element.findIndex((x) => x.key == "phone")].postValue,
                website:
                  element[element.findIndex((x) => x.key == "website")]
                    .postValue,
                relationship:
                  element[element.findIndex((x) => x.key == "relationship")]
                    .postValue,
                length_of_relation:
                  element[
                    element.findIndex((x) => x.key == "length_of_relation")
                  ].postValue,

                actions: "&nbsp;"
              };
              this.items.push(row);
              this.postData = data.elements;
            }
          });
        }
      });
    },

    setDataToUser() {
      if (this.$route.name === routeNames[routeKeys.USER_FORM_PAGE]) {
        this.$store.state.userFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements = this.postData;
          }
        });
      }
    },

    resetModal() {
      (this.form.refereeName = null),
        (this.form.refereePositionRole = null),
        (this.form.refereeCompany = null),
        (this.form.refereeEmail = null),
        (this.form.refereePhone = null),
        (this.form.refereeWebsite = null),
        (this.form.refereeNatureOfRelationship = null),
        (this.form.refereeLengthOfRelation = 0),
        (this.form.freeForm = null);
      this.$v.$reset();
      this.showAddRowModal = false;
      this.isUpdtating = false;
    },

    updateRow() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError && this.$v.form.$invalid) {
        return false;
      }
      this.items[this.selectedRowIndex].name = this.form.refereeName;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex((x) => x.key == "name")
      ].postValue = this.form.refereeName;

      this.items[this.selectedRowIndex].position =
        this.form.refereePositionRole;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "position"
        )
      ].postValue = this.form.refereePositionRole;

      this.items[this.selectedRowIndex].company = this.form.refereeCompany;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "company"
        )
      ].postValue = this.form.refereeCompany;

      this.items[this.selectedRowIndex].email = this.form.refereeEmail;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex((x) => x.key == "email")
      ].postValue = this.form.refereeEmail;

      this.items[this.selectedRowIndex].phone = this.form.refereePhone;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex((x) => x.key == "phone")
      ].postValue = this.form.refereePhone;

      this.items[this.selectedRowIndex].website = this.form.refereeWebsite;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "website"
        )
      ].postValue = this.form.refereeWebsite;

      this.items[this.selectedRowIndex].relationship =
        this.form.refereeNatureOfRelationship;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "relationship"
        )
      ].postValue = this.form.refereeNatureOfRelationship;

      this.items[this.selectedRowIndex].length_of_relation =
        this.form.refereeLengthOfRelation;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "length_of_relation"
        )
      ].postValue = this.form.refereeLengthOfRelation;

      this.items[this.selectedRowIndex].free_form = this.form.freeForm;
      this.postData[this.selectedRowIndex][
        this.postData[this.selectedRowIndex].findIndex(
          (x) => x.key == "free_form"
        )
      ].postValue = this.form.freeForm;

      // ----------
      this.resetModal();
    },

    onDeleteRow(index) {
      const registerData = clone(this.$store.state.registerData);
      if (this.items.length == 1 && this.postData.length == 1) {
        delete registerData[this.fieldData.id];
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;
      }

      this.items.splice(index, 1);
      this.postData.splice(index, 1);
    },

    onEditRow(item, index) {
      this.isUpdtating = true;
      this.showAddRowModal = true;
      this.selectedRowIndex = index;
      this.form = {
        refereeName: item.name,
        refereePositionRole: item.position,
        refereeCompany: item.company,
        refereeEmail: item.email,
        refereePhone: item.phone,
        refereeWebsite: item.website,
        refereeNatureOfRelationship: item.relationship,
        refereeLengthOfRelation: item.length_of_relation,
        freeForm: item.free_form
      };
    },

    // not in use
    setValidation() {
      let validation = {
        isRequireRefereeName: true,
        isRequireRefereePositionRole: true,
        isRequireRefereeCompany: true,
        isRequireRefereeEmail: true,
        isRequireRefereePhone: true,
        isRequireRefereeWebsite: true,
        isRequireRefereeNatureOfRelationship: true,
        isRequireRefereeLengthOfRelation: true
      };
      Object.assign(this.fieldData, { validation });
    },

    onSaveData() {
      EventBus.$emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    },

    checkForConditional() {
      if (this.fieldData.isConditional == 1) {
        this.fieldData.isHidden = true;
      }
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style></style>
