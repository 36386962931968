import { actions } from "./company.actions.store";
import { mutations } from "./company.mutations.store";
import { state } from "./company.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
