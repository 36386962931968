var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bracket-section"},_vm._l((_vm.bracketSection),function({ bracket, operator },index){return _c('div',{key:`bracket-${index}`},[(_vm.isOperatorVisible(index, operator))?_c('Select',{key:`bracket-section-operator-${index}`,staticClass:"bracket-section__bracket-operator",attrs:{"id":`bracket-section-operator-${index}`,"options":operator.options,"error":operator.error,"data-test-id":"bracket-section__bracket-operator"},on:{"change":function($event){return _vm.$emit('change', {
          index,
          property: _vm.actionKeys.OPERATOR,
          event: $event
        })}}}):_vm._e(),_c('div',{key:`bracket-section-${index}`,staticClass:"bracket-section__bracket",attrs:{"data-test-id":"bracket-section__bracket"},on:{"mouseenter":function($event){return _vm.setShowDeleteStep(index, true)},"mouseleave":function($event){return _vm.setShowDeleteStep(index, false)}}},[(_vm.showDeleteStep[index])?_c('BaseAddDeleteLine',{staticClass:"bracket-section__bracket-action bracket-section__bracket-action--remove",attrs:{"data-test-id":"bracket-section__bracket-action--remove","position":_vm.position.LEFT,"type":_vm.operations.DELETE},on:{"click":function($event){return _vm.deleteBracket(index)}}}):_vm._e(),(bracket)?_c(bracket.component,_vm._b({tag:"component",attrs:{"data-test-id":"bracket-section__bracket-component"},on:{"change":function($event){return _vm.$emit('change', {
            index,
            property: _vm.actionKeys.COMPONENT,
            event: $event
          })}}},'component',bracket.componentOptions,false)):_vm._e()],1),_c('BaseAddDeleteLine',{staticClass:"bracket-section__bracket-action bracket-section__bracket-action--add",attrs:{"data-test-id":"bracket-section__bracket-action--add","is-hidden":!_vm.showAddStep[index],"type":_vm.operations.ADD},on:{"mouseenter":function($event){return _vm.setShowAddStep(index, true)},"mouseleave":function($event){return _vm.setShowAddStep(index, false)},"click":function($event){return _vm.$emit('change', {
          eventType: _vm.operations.ADD_BRACKET,
          index: index + 1
        })}}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }