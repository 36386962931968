import { SetToBlockClass } from "@/molecules/SetToBlock/SetToBlock.class";
import { actionStepType } from "@/constants";

export class SetStepClass extends SetToBlockClass {
  stepType = actionStepType.SET;

  comment = "";

  hasComment = false;

  variableNameOptions = [];

  variableNameSearchValue = "";

  variableNameErrorMessage = "";

  endOperations = [];

  constructor({
    variableNameOptions = [],
    variableNameSearchValue = "",
    valueDataTypeOptions = [],
    valueDataType = "",
    isReadOnly = false,
    isMiddleSelectHidden = false,
    valueTypeOptions = [],
    component = {},
    componentOptions = {},
    valueDataTypeText = "",
    componentValue = "",
    isHidden = true,
    variableType = "",
    variableScope = "",
    variableNameErrorMessage = "",
    comment = "",
    hasComment = false,
    endOperations = []
  }) {
    super({
      valueDataTypeOptions,
      valueDataType,
      isReadOnly,
      isMiddleSelectHidden,
      valueTypeOptions,
      component,
      componentOptions,
      valueDataTypeText,
      componentValue,
      isHidden
    });
    this.variableNameOptions = variableNameOptions;
    this.variableNameSearchValue = variableNameSearchValue;
    this.variableType = variableType;
    this.variableScope = variableScope;
    this.setVariableNameErrorMessage(variableNameErrorMessage);
    this.comment = comment;
    this.hasComment = hasComment;
    this.endOperations = endOperations;
  }

  setVariableNameOptions(variableNameOptions) {
    this.variableNameOptions = variableNameOptions;
  }

  setVariableNameSearchValue(variableNameSearchValue) {
    this.variableNameSearchValue = variableNameSearchValue;
  }

  setVariableType(variableType) {
    this.variableType = variableType;
  }

  setVariableScope(variableScope) {
    this.variableScope = variableScope;
  }

  setVariableNameErrorMessage(errorMessage) {
    this.variableNameErrorMessage = errorMessage;
  }

  setEndOperations(endOperations) {
    this.endOperations = endOperations;
  }
}
