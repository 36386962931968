const validationKeys = {
  REQUIRED: "required",
  NUMBER: "number",
  MIN_LENGTH: "minLength",
  MAX_LENGTH: "maxLength",
  IS_VALID_URL: "isValidUrl",
  IS_VALID_MAIL_TO_LINK: "isValidMailToLink",
  IS_VALID_LINK: "isValidLink",
  IS_VALID_EMAIL: "isValidEmail",
  IS_UNIQUE_IN_LIST: "isUniqueInList",
  ALPHA_NUMERIC: "alphaNumeric"
};

const errorLinkMessages = {
  [validationKeys.REQUIRED]: "Please enter a Link",
  [validationKeys.IS_VALID_URL]:
    "Link must start with mailto:, https://, http://",
  [validationKeys.IS_VALID_MAIL_TO_LINK]:
    "Please enter a valid email starting with mailto:",
  [validationKeys.IS_VALID_LINK]: "Please enter a valid Link"
};

const errorMessages = {
  companyName: {
    [validationKeys.REQUIRED]: "Please enter the legal company name"
  },
  companyAddress: {
    [validationKeys.REQUIRED]: "Please enter the company address"
  },
  legalDisclaimer: {
    [validationKeys.REQUIRED]: "Please enter the legal disclaimer"
  },
  copyrightNotice: {
    [validationKeys.REQUIRED]: "Please enter the copyright notice link"
  },
  companyNameAbbreviation: {
    [validationKeys.REQUIRED]:
      "Please enter the legal company name abbreviation"
  },
  releaseNoteLink: {
    ...errorLinkMessages
  },
  privacyCookieLink: {
    ...errorLinkMessages
  },
  faqLink: {
    ...errorLinkMessages
  },
  supportEmailLink: {
    ...errorLinkMessages
  },
  requestSupportLink: {
    ...errorLinkMessages
  },
  tncLink: {
    ...errorLinkMessages
  },
  companyWebsite: {
    ...errorLinkMessages,
    [validationKeys.REQUIRED]: "Please enter the company website link"
  },
  knowledgeCenterLink: {
    ...errorLinkMessages
  },
  attributeName: {
    [validationKeys.REQUIRED]: "Attribute name is required"
  },
  attributeDataType: {
    [validationKeys.REQUIRED]: "Attribute type is required"
  },
  attributeSubtype: {
    [validationKeys.REQUIRED]: "Attribute subtype is required"
  },
  username: {
    [validationKeys.REQUIRED]: "Enter your username"
  },
  password: {
    [validationKeys.REQUIRED]: "Enter your password"
  },
  forgotPasswordEmail: {
    [validationKeys.REQUIRED]: "Please enter an email address",
    [validationKeys.IS_VALID_EMAIL]: "Please enter a valid email address"
  },
  productId: {
    [validationKeys.REQUIRED]: "Please select any item from list"
  },
  actionName: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]:
      "The action name must not exceed 255 characters in length."
  },
  actionDescription: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.MAX_LENGTH]:
      "The action description must not exceed 255 characters in length."
  },
  expectedVariableName: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.IS_UNIQUE_IN_LIST]: "Name has to be unique"
  },
  expectedVariableType: {
    [validationKeys.REQUIRED]: "Required"
  },
  variableName: {
    [validationKeys.REQUIRED]: "Required"
  },
  valueDataType: {
    [validationKeys.REQUIRED]: "Required"
  },
  value: {
    [validationKeys.REQUIRED]: "Required",
    [validationKeys.NUMBER]: "Require a number"
  },
  valueType: {
    [validationKeys.REQUIRED]: "Required"
  },
  functionStepsList: {
    [validationKeys.MIN_LENGTH]: "The function body is required."
  },
  nextActionStep: {
    [validationKeys.REQUIRED]: "Required"
  }
};

const customErrorMessages = {
  actions: {
    actionName: {
      [validationKeys.REQUIRED]: "Required",
      [validationKeys.MAX_LENGTH]:
        "The action name must not exceed 255 characters in length."
    },
    actionDescription: {
      [validationKeys.REQUIRED]: "Required",
      [validationKeys.MAX_LENGTH]:
        "The action description must not exceed 255 characters in length."
    },
    expectedVariableName: {
      [validationKeys.REQUIRED]: "Required",
      [validationKeys.IS_UNIQUE_IN_LIST]: "Name has to be unique"
    },
    expectedVariableType: {
      [validationKeys.REQUIRED]: "Required"
    },
    variableName: {
      [validationKeys.REQUIRED]: "Required",
      [validationKeys.ALPHA_NUMERIC]:
        "The name can only include letters or numbers",
      [validationKeys.MAX_LENGTH]:
        "The variable name must not exceed 50 characters in length."
    },
    valueDataType: {
      [validationKeys.REQUIRED]: "Required"
    },
    value: {
      [validationKeys.REQUIRED]: "Required",
      [validationKeys.NUMBER]: "Require a number"
    },
    valueType: {
      [validationKeys.REQUIRED]: "Required"
    },
    functionStepsList: {
      [validationKeys.MIN_LENGTH]: "The function body is required."
    },
    nextActionStep: {
      [validationKeys.REQUIRED]: "Required"
    },
    operator: {
      [validationKeys.REQUIRED]: "Required"
    }
  }
};

export {
  customErrorMessages,
  errorMessages,
  errorLinkMessages,
  validationKeys
};
