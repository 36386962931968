<template>
  <div>
    <h5 class="font-size-text">Avg. Third Party Risk</h5>
    <div>
      <highcharts :options="riskScoreChartOptions" ref="chart" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AverageSupplierRiskComponent",
  props: {
    riskScoreChartOptions: Object
  }
};
</script>
