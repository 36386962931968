<template id="template-radio">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <b-collapse
      :class="isPreview ? 'mt-2' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="1">
          <b-button
            v-if="
              fieldData.files &&
              fieldData.files.length > 1 &&
              fieldData.controlsOut &&
              slide > 0
            "
            @click="prev"
            class="col-1"
            variant="outline"
          >
            <font-awesome-icon size="5x" icon="caret-left" />
          </b-button>
        </b-col>
        <b-col cols="10" style="max-hieght: 300px">
          <b-carousel
            :class="
              fieldData.files &&
              fieldData.files.length > 1 &&
              fieldData.controlsOut
                ? 'col-10'
                : 'col-12'
            "
            id="carousel-1"
            ref="appCarousel"
            :interval="0"
            v-model="slide"
            :controls="fieldData.controls"
            :indicators="fieldData.indicators"
            :img-width="fieldData.imgWidth"
            :img-height="fieldData.imgHeight"
            style="text-shadow: 1px 1px 2px #333"
          >
            <!-- Text slides with image -->
            <b-carousel-slide
              v-for="(data, index) in fieldData.files"
              :key="index"
              :caption="data.caption[selectedLanguage]"
              :text="data.text[selectedLanguage]"
              :img-blank="!data.url"
              :img-alt="data.imgAlt"
              :img-src="data.url"
              :img-height="200"
              background="#ababab"
            />
          </b-carousel>
        </b-col>

        <b-col cols="1">
          <b-button
            v-if="
              fieldData.files &&
              fieldData.files.length > 1 &&
              fieldData.controlsOut &&
              slide !== fieldData.files.length - 1
            "
            @click="next"
            class="col-1"
            variant="outline"
          >
            <font-awesome-icon size="5x" icon="caret-right" />
          </b-button>
        </b-col>
      </b-row>
      <!-- advance settings tab -->
      <b-modal
        cancel-title="Close without saving"
        cancel-variant="outline-danger"
        ok-title="save"
        ok-variant="outline-success"
        @ok="onSaveData"
        no-close-on-esc
        no-close-on-backdrop
        no-enforce-focus
        hide-header-close
        @hidden="onUpdateSettings"
        :hide-footer="disableButtons"
        size="xl"
        v-model="showSettings"
        title="Carousel Advanced Settings"
      >
        <AdvanceTrainingSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
        />
      </b-modal>
    </b-collapse>
    <b-toast
      variant="primary"
      v-model="showToast1"
      id="toast-1"
      title="Notification"
      >Settings Updated</b-toast
    >
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import AdvanceTrainingSettings from "../advanceTrainingSettingsComponent.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import MenuButtons from "../menuButtons";
import { EventBus } from "@/event-bus.js";

export default {
  name: "CarouselGroup",
  components: {
    AdvanceTrainingSettings,
    MenuButtons
  },
  data() {
    return {
      showToast1: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      placeholder: undefined,
      helperText: undefined,
      slide: 0,
      sliding: null,
      disableButtons: false
    };
  },
  validations: {
    name: {
      required
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  created() {
    EventBus.$on("onUploadStart", () => {
      this.disableButtons = true;
    });
    EventBus.$on("onUploadCompleting", () => {
      this.disableButtons = false;
    });
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    onUpdateSettings() {
      // this.TOGGLE_LOADER(50);
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    prev() {
      this.$refs.appCarousel.prev();
    },
    next() {
      this.$refs.appCarousel.next();
    },
    onSaveData() {
      (this.showToast1 = true),
        EventBus.$emit("onSaveSettings", "saving settings");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
