<template>
  <div bg-light>
    <b-container class="ml-3 third-party-entity" fluid>
      <b-row>
        <b-col cols="12" class="text-left mt-3">
          <b-overlay :show="isLoadingDetail" rounded="sm">
            <div v-if="!isLoadingDetail">
              <ThirdPartyProfileHeader
                ref="profileHeader"
                :is-disabled="isArchived"
                :third-party-id="thirdPartyId"
                :third-party-data="thirdPartyData"
                :company-id="customCompanyId"
                :is-dnb-modal="isDnbModal"
              />
              <b-tabs
                v-if="!isLoadingDetail"
                v-model="$store.state.tabIndexProfilePage"
                small
              >
                <b-tab title="RISK SUMMARY" class="my-4">
                  <RiskSummaryTabComponent
                    :company-id="customCompanyId"
                    :is-disabled="isArchived"
                    :third-party-id="thirdPartyId"
                    :third-party-data="thirdPartyData"
                    :is-dnb-modal="isDnbModal"
                    :custom-attributes="customAttributes"
                  />
                </b-tab>
                <b-tab
                  :title="esgTexts.ESG_EXPLORE.toUpperCase()"
                  class="my-4"
                  lazy
                >
                  <ThirdPartyESGTemplate
                    :company-id="customCompanyId"
                    :third-party-id="thirdPartyId"
                  />
                </b-tab>
                <b-tab
                  title="DUE DILIGENCE"
                  :lazy="
                    $store.state.dueDiligenceRemediationMonitoringTabLazyLoad
                  "
                >
                  <DueDiligenceMonitoringTabComponent
                    :company-id="customCompanyId"
                    :is-disabled="isArchived"
                    :third-party-id="thirdPartyId"
                    :third-party-data="thirdPartyData"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="QUESTIONNAIRES, POLICIES, COMMS & TRAINING"
                  lazy
                >
                  <QuestionnairesPoliciesTrainingTabComponent
                    :company-id="customCompanyId"
                    :is-disabled="isArchived"
                    :third-party-id="thirdPartyId"
                    :third-party-data="thirdPartyData"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="DOCUMENTS"
                  :lazy="$store.state.documentsTabLazyLoad"
                >
                  <DocumentsTabComponent
                    :is-disabled="isArchived"
                    :company-id="customCompanyId"
                    :third-party-id="thirdPartyId"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="DETAILS"
                  :lazy="$store.state.thirdPartyDetailsAssocTabLazyLoad"
                  data-test-id="third-party-profile__details-tab"
                >
                  <ThirdPartyDetails
                    :third-party-data="thirdPartyData"
                    :company-id="customCompanyId"
                    :is-disabled="isArchived"
                    :third-party-id="thirdPartyId"
                    :lazy-load="true"
                    :custom-attributes="customAttributes"
                    @get-my-details-third-party="fetchThirdPartyDetail"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="PREMIUM CORPORATE DATA"
                  :lazy="isCorporateDataLoading"
                  style="padding-right: 80px"
                >
                  <PremiumCorporateDataTemplate
                    :premium-corporate-data="premiumCorporateData"
                    :organisations-change-log-data="organisationsChangeLogData"
                    :is-dnb-disabled="isDnbDisabled"
                    :is-dnb-verified="isDnbVerified"
                    :third-party-data="thirdPartyData"
                  />
                </b-tab>
                <b-tab class="my-4" title="ULTIMATE BENEFICIAL OWNER" lazy>
                  <UltimateBeneficialOwnersTab
                    :third-party-id="thirdPartyId"
                    :company-id="customCompanyId"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="AUDIT TRAIL"
                  :lazy="$store.state.auditTrailTabLazyLoad"
                >
                  <AuditTrailTabComponent
                    :is-disabled="isArchived"
                    :company-id="customCompanyId"
                    :third-party-id="thirdPartyId"
                    :audit-trail-type="1"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { isValidResponse } from "@/utils";
import { thirdPartyService } from "@/services";
import { EventBus } from "@/event-bus.js";
import ThirdPartyProfileHeader from "@/organisms/ThirdPartyProfileHeader/ThirdPartyProfileHeader";
import ThirdPartyDetails from "@/organisms/ThirdPartyDetails/ThirdPartyDetails";
import RiskSummaryTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/riskSummaryTab/riskSummaryTabComponent";
import UltimateBeneficialOwnersTab from "@/organisms/UltimateBeneficialOwnersList/UltimateBeneficialOwnersList";
import DueDiligenceMonitoringTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/dueDiligenceRemediation/dueDiligenceRemediationMonitoringTabComponent";
import QuestionnairesPoliciesTrainingTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/questionnairesPoliciesTraining/questionnairesPoliciesTrainingTabComponent";
import DocumentsTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/documentsTab/documentsTabComponent";
import AuditTrailTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/auditTrailTab/auditTrailTabComponent";
import ThirdPartyESGTemplate from "@/templates/ThirdPartyESGTemplate/ThirdPartyESGTemplate";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  actionName,
  corporateData,
  esgTexts,
  mutationName,
  thirdPartyTabHash
} from "@/constants";
import { Helper } from "@/helpers";
import PremiumCorporateDataTemplate from "@/templates/PremiumCorporateDataTemplate/PremiumCorporateDataTemplate";

export default {
  name: "ThirdPartyEntityPage",
  components: {
    ThirdPartyProfileHeader,
    RiskSummaryTabComponent,
    DueDiligenceMonitoringTabComponent,
    QuestionnairesPoliciesTrainingTabComponent,
    DocumentsTabComponent,
    ThirdPartyDetails,
    AuditTrailTabComponent,
    ThirdPartyESGTemplate,
    PremiumCorporateDataTemplate,
    UltimateBeneficialOwnersTab
  },
  data() {
    return {
      esgTexts,
      isLoadingDetail: false,
      isArchived: false,
      thirdPartyId: null,
      customCompanyId: null,
      thirdPartyData: {},
      customAttributes: [],
      isDnbModal: false,
      premiumCorporateData: {},
      isDnbDisabled: false,
      isDnbVerified: false,
      isCorporateDataLoading: false,
      organisationsChangeLogData: [],
      isOrganisationsChangeLogDataLoading: false
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId,
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible,
      isAddThirdPartyDnbModalVisible: (state) =>
        state.thirdParty.isAddThirdPartyDnbModalVisible
    })
  },
  watch: {
    isAddThirdPartyModalNewVisible(newValue) {
      if (!newValue) this.fetchThirdPartyDetail();
    },
    isAddThirdPartyDnbModalVisible(newValue) {
      if (!newValue) this.fetchThirdPartyDetail();
    }
  },
  created() {
    EventBus.$on("reloadThirdPartyDetailProfile", () => {
      this.fetchThirdPartyDetail();
    });
  },
  mounted() {
    this.setProfilePageTab();
    this.setStoreProperties();

    const { compId, id } = this.$route.params;
    this.thirdPartyId = parseInt(id);

    this.customCompanyId = compId ? parseInt(compId) : this.companyId;

    this.fetchCustomAttributes();
    this.fetchThirdPartyDetail();
    this.fetchPlatformFeatures();
  },
  beforeRouteLeave(_, __, next) {
    this.setThirdPartyUBOList([]);
    next();
  },
  methods: {
    ...mapActions({
      fetchCustomAttributesList:
        actionName.CUSTOM_ATTRIBUTES.FETCH_CUSTOM_ATTRIBUTES_LIST
    }),
    ...mapMutations({
      setTabIndexProfilePage: "setTabIndexProfilePage",
      setThirdPartyUBOList: mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_LIST
    }),
    async fetchPlatformFeatures() {
      try {
        const { data = {} } = await thirdPartyService.fetchPlatformFeatures(
          this.customCompanyId
        );
        for (let platformFeature of data?.data) {
          if (platformFeature.name === corporateData.CORPORATE_DATA) {
            this.isDnbModal = platformFeature.value === corporateData.DNB;
            this.isDnbDisabled = !this.isDnbModal;
            break;
          }
        }
      } catch ({ status }) {
        this.displayErrorToastMessage(status);
      }
    },
    displayErrorToastMessage(status) {
      Helper.makeToast(
        this.$bvToast,
        "danger",
        `There is an unknown error with status of ${status}. Please contact support`
      );
    },
    async fetchCompanyCorporateData() {
      this.isCorporateDataLoading = true;
      try {
        const { data = {} } = await thirdPartyService.fetchCompanyCorporateData(
          this.customCompanyId,
          this.thirdPartyData?.duns_number
        );
        this.premiumCorporateData = data.response.data;
      } catch ({ status }) {
        this.displayErrorToastMessage(status);
      } finally {
        this.isCorporateDataLoading = false;
      }
    },
    async fetchOrganisationsChangeLogData() {
      this.isOrganisationsChangeLogDataLoading = true;
      try {
        const response =
          await thirdPartyService.fetchOrganisationsChangeLogData(
            this.thirdPartyData?.duns_number
          );

        this.organisationsChangeLogData = response?.data;
      } catch ({ status }) {
        this.displayErrorToastMessage(status);
      } finally {
        this.isOrganisationsChangeLogDataLoading = false;
      }
    },
    setProfilePageTab() {
      if (this.$route.hash === thirdPartyTabHash.ESG) {
        this.setTabIndexProfilePage(1);
      } else if (this.$route.hash === thirdPartyTabHash.DUE_DILIGENCE) {
        this.setTabIndexProfilePage(2);
      } else if (this.$route.hash === thirdPartyTabHash.QUESTIONNAIRE) {
        this.setTabIndexProfilePage(3);
      } else if (this.$route.hash === thirdPartyTabHash.DETAILS) {
        this.setTabIndexProfilePage(5);
      }
    },
    setStoreProperties() {
      this.$store.state.riskSummaryTabLazyLoad = true;
      this.$store.state.dueDiligenceRemediationMonitoringTabLazyLoad = true;
      this.$store.state.questionnairesPoliciesTrainingTabLazyLoad = true;
      this.$store.state.documentsTabLazyLoad = true;
      this.$store.state.thirdPartyDetailsAssocTabLazyLoad = true;
      this.$store.state.auditTrailTabLazyLoad = true;
      this.$store.state.contactsTabLazyLoad = true;
      this.$store.state.associatesTabLazyLoad = true;
    },
    makeThirdPartyDetailPostObj() {
      return {
        thirdPartyId: this.thirdPartyId,
        companyId: this.customCompanyId,
        userId: this.userId
      };
    },
    async fetchThirdPartyDetail() {
      this.isLoadingDetail = true;
      this.thirdPartyData = {};
      try {
        let result = await thirdPartyService.fetchThirdPartyDetail(
          this.makeThirdPartyDetailPostObj()
        );
        this.setThirdPartyDetails(result);
      } catch (error) {
        return error;
      } finally {
        this.isLoadingDetail = false;
      }
    },
    setThirdPartyDetails(result) {
      if (isValidResponse(result) && result?.data?.response) {
        this.thirdPartyData = result.data.response;
        this.isArchived = parseInt(result.data.response.is_archived) === 1;
        if (this.thirdPartyData?.duns_number) {
          this.isDnbVerified = true;
          this.fetchCompanyCorporateData();
          this.fetchOrganisationsChangeLogData();
        } else {
          this.isDnbVerified = false;
        }
      } else {
        this.thirdPartyData = {};
      }
    },
    async fetchCustomAttributes() {
      try {
        this.customAttributes = [];
        this.isLoadingDetail = true;

        const { response = [] } = await this.fetchCustomAttributesList(
          this.makeCustomAttributesPostObject()
        );

        this.customAttributes = response;
      } catch (error) {
        return [error];
      } finally {
        this.isLoadingDetail = false;
      }
    },
    makeCustomAttributesPostObject() {
      return {
        sort: `tp_sequence|asc`,
        comp_id: this.companyId,
        is_active: 0
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.third-party-entity {
  height: calc(100vh - 128px);
  overflow: hidden auto;
}

:deep(.nav-tabs) {
  border: none;
}
</style>
