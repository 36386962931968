const knowledgeCentreLinks = {
  ENTITY_DETAILS:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407790466962-Entity-Details-section-on-the-Configure-third-party-screen",
  ENTITY_VERIFICATION:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407790483602-Entity-Verification-section-on-the-Configure-Third-Party-screen",
  ASSOCIATE_ENTITIES:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407790493458-Associate-Entities-section-of-the-conffigure-third-party-dialog",
  MY_RISK_RATING:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407795833874-My-Risk-Rating",
  PREVIOUSLY_PERFORMED_ACTIONS:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407790510354-Previously-Performed-Actions-section-of-the-Configure-Third-Party-Screen",
  DEFAULT_ACTIONS:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407790515730-Default-Actions-section-of-the-third-party-screen",
  AVAILABLE_ACTIONS:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407790526226-Available-actions-section-of-the-Configure-Third-party-screen",
  OTHER_ACTIONS_AVAILABLE_NOT_SUBSCRIBED:
    "https://ethixbase.zendesk.com/hc/en-us/articles/4407795846930-Other-actions-available-but-not-subscribed-in-the-configure-third-party-screen"
};

export { knowledgeCentreLinks };
