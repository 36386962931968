<template>
  <div class="profile-header">
    <BaseIcon
      class="profile-header__logo"
      :icon="entityLogo"
      :size="logoSize"
    />
    <BaseText
      class="profile-header__heading"
      :size="typographySize.DISPLAY_TEXT"
      tag="h2"
      :text="entityName"
    />
    <CallToAction
      v-if="isEditable"
      class="profile-header__edit"
      value="Edit"
      type="button"
      :icon="icons.EDIT"
      icon-space="none"
      :theme="themes.ETHIXBASE_PRIMARY"
      :has-icon-with-text="true"
      @click="handleClick"
    />
  </div>
</template>

<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseText from "@/atoms/BaseText/BaseText";
import { themes, typographySize, icons } from "@/constants";

export default {
  name: "ProfileHeader",
  components: {
    BaseIcon,
    BaseText,
    CallToAction
  },
  props: {
    entityName: {
      type: String,
      default: ""
    },
    entityLogo: {
      type: String,
      default: ""
    },
    logoSize: {
      type: Number,
      default: 80
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typographySize,
      themes,
      icons
    };
  },
  methods: {
    handleClick() {
      this.$emit('edit-click');
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-header {
  display: flex;
  margin: 30px 0px;
  align-items: center;

  :deep(.profile-header__heading) {
    margin-left: 15px;
    padding-top: 2px;
    align-self: center;
    margin-bottom: auto;
  }

  &__edit {
    margin-right: 30px;
    margin-left: auto;
    height: fit-content;
    padding: 3px 8px;
  }
}
</style>
