<template>
  <div>
    <BadgeLink v-bind="badgeLinkOptions" />
    <BaseText v-bind="badgeTextOptions" />
  </div>
</template>

<script>
import BadgeLink from "@/molecules/BadgeLink/BadgeLink";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "BadgeWithText",
  components: { BadgeLink, BaseText },
  props: {
    badgeLinkOptions: {
      type: Object,
      default: () => ({})
    },
    badgeTextOptions: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
