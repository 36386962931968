var render, staticRenderFns
import script from "./BaseText.vue?vue&type=script&lang=js"
export * from "./BaseText.vue?vue&type=script&lang=js"
import style0 from "./BaseText.vue?vue&type=style&index=0&id=78f29018&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f29018",
  null
  
)

export default component.exports