import { actionKeysApi, endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeActionAPIDTO,
  makeActionDTO,
  makeParsedExpectedDataDTO,
  makeAvailableExpectedDataDTO,
  makeEntitiesDynamicReferenceDTO
} from "./dto/actionsDTO/actions.dto";

const fetchActions = async (companyId, params = null) => {
  const result = await HTTP(
    "get",
    endpoints.ACTIONS(companyId),
    null,
    makeAuthorizationHeader(),
    params
  );
  result.data = result.data.map(makeActionDTO);
  return result;
};

const fetchAction = async (companyId, id, params = null) => {
  const result = await HTTP(
    "get",
    endpoints.ACTION(companyId, id),
    null,
    makeAuthorizationHeader(),
    params
  );
  result.data = makeActionDTO(result.data);
  return result;
};

const fetchParsedExpectedData = async (companyId, payload) => {
  const result = await HTTP(
    "post",
    endpoints.FETCH_PARSED_ACTIONS_DATA(companyId),
    {
      [actionKeysApi.EXPECTED_DATA_DEFINITION]: {
        [actionKeysApi.COMPANY_ID]: companyId,
        [actionKeysApi.VARIABLES]: payload
      }
    },
    makeAuthorizationHeader()
  );
  result.data = result.data.map(makeParsedExpectedDataDTO);
  return result;
};

const fetchProxyExpectedData = async (companyId, payload) => {
  const result = await HTTP(
    "post",
    endpoints.FETCH_PROXY_EXPECTED_DATA(companyId),
    payload,
    makeAuthorizationHeader()
  );
  result.data = makeParsedExpectedDataDTO(result.data);
  return result;
};

const saveAction = async (companyId, payload) => {
  const result = await HTTP(
    "post",
    endpoints.ACTIONS(companyId),
    makeActionAPIDTO(payload),
    makeAuthorizationHeader()
  );
  result.data = makeActionDTO(result.data);
  return result;
};

const amendAction = async (companyId, actionId, payload) => {
  const result = await HTTP(
    "put",
    endpoints.AMEND_ACTION(companyId, actionId),
    makeActionAPIDTO(payload),
    makeAuthorizationHeader()
  );
  result.data = makeActionDTO(result.data);
  return result;
};

const deleteAction = (companyId, actionId) =>
  HTTP(
    "delete",
    endpoints.AMEND_ACTION(companyId, actionId),
    null,
    makeAuthorizationHeader()
  );

const cloneAction = (companyId, actionId) =>
  HTTP(
    "post",
    endpoints.CLONE_ACTION(companyId, actionId),
    null,
    makeAuthorizationHeader()
  );

const fetchExpectedDataEntities = async (companyId = "") => {
  const result = await HTTP(
    "get",
    endpoints.FETCH_EXPECTED_DATA_ENTITIES(companyId),
    null,
    makeAuthorizationHeader()
  );
  result.data = result.data.map(makeAvailableExpectedDataDTO);
  return result;
};

const fetchEntitiesDynamicReference = async (dynamicUrl) => {
  const result = await HTTP("get", dynamicUrl, null, makeAuthorizationHeader());
  result.data = makeEntitiesDynamicReferenceDTO(result.data);
  return result;
};

export const actionsService = {
  fetchActions,
  fetchAction,
  fetchParsedExpectedData,
  fetchProxyExpectedData,
  saveAction,
  amendAction,
  deleteAction,
  cloneAction,
  fetchExpectedDataEntities,
  fetchEntitiesDynamicReference
};
