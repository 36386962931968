const formBuilderConstants = {
  ACCEPTED_TERMS: "accepted",
  NOT_ACCEPTED_TERMS: "not_accepted",
  NO_TEXT_SET: "No text set",
  NO_VALUE_SET: "No value set"
};

const formStatus = {
  PUBLISHED: 1
}

export {
  formStatus,
  formBuilderConstants
}
