const gradeRating = {
  AAA: "AAA",
  AA: "AA",
  A: "A",
  BBB: "BBB",
  BB: "BB",
  B: "B",
  C: "C"
};

const strengthScale = {
  STRONG: "Strong",
  MODERATE: "Moderate",
  WEAK: "Weak",
  NONE: "None"
};

const riskScale = {
  NONE: "None",
  NO_MATCH: "No match",
  CRITICAL: "Critical",
  SERIOUS: "Serious",
  MODERATE: "Moderate"
};

const incomingRiskStatus = {
  NOT_REQUESTED: "not_requested",
  IN_PROGRESS: "in_progress",
  GREEN: "green",
  RED: "red"
};

const riskStatus = {
  NOT_RUN: "notRun",
  IN_PROGRESS: "inProgress",
  NO_RISK_ALERT: "noRiskAlert",
  RISK_ALERT: "riskAlert"
};

const riskStatusMap = {
  [incomingRiskStatus.NOT_REQUESTED]: riskStatus.NOT_RUN,
  [incomingRiskStatus.IN_PROGRESS]: riskStatus.IN_PROGRESS,
  [incomingRiskStatus.GREEN]: riskStatus.NO_RISK_ALERT,
  [incomingRiskStatus.RED]: riskStatus.RISK_ALERT
};

export { riskScale, strengthScale, gradeRating, riskStatus, riskStatusMap };
