<template>
  <div class="base-chart-widget">
    <BaseText
      v-if="chartTitle"
      class="base-chart-widget__title"
      :text="chartTitle"
      data-test-id="base-chart-widget__title"
    />
    <template v-if="isEmpty(chartOptions)">
      <slot>
        <BaseText
          data-test-id="base-chart-widget__no-data"
          text="Data is not available"
        />
      </slot>
    </template>
    <slot v-else name="chart">
      <BaseChart
        :highcharts-component-options="highchartsComponentOptions"
        :chart-options="chartOptions"
      />
    </slot>
  </div>
</template>

<script>
import BaseChart from "@/atoms/BaseChart/BaseChart";
import BaseText from "@/atoms/BaseText/BaseText";
import { isEmpty } from "lodash";

export default {
  name: "BaseChartWidget",
  components: {
    BaseText,
    BaseChart
  },
  props: {
    chartTitle: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === undefined
    },
    chartOptions: {
      required: true,
      type: Object
    },
    highchartsComponentOptions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    isEmpty
  }
};
</script>

<style lang="scss" scoped>
.base-chart-widget {
  padding: 12px;
  border: 1px solid rgba($black, 0.125);
  border-radius: 3px;

  &:deep(.base-chart-widget__title) {
    @include body-text-bold;
    text-align: center;
    margin: 0px;
  }
}
</style>
