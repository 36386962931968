import { actions } from "./userEmailTemplates.actions.store";
import { mutations } from "./userEmailTemplates.mutations.store";
import { state } from "./userEmailTemplates.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
