<template>
  <div>
    <b-row class="mx-3 my-2">
      <b-button @click="onDeleteField" variant="outline-danger">
        <font-awesome-icon icon="trash" />
      </b-button>
    </b-row>

    <b-tabs pills card vertical nav-wrapper-class="over-flow-tabs">
      <!-- Render Tabs, supply a unique `key` to each tab -->
      <b-tab
        @click="tabClick(language)"
        v-for="(language, index) in form.formTranslations"
        :key="index"
        :title="language"
      >
        <!-- translation form wrappe -->
        <div class="col-md-12">
          <vue-dropzone
            ref="myVueDropzone"
            @vdropzone-success="vsuccess"
            id="dropzone"
            :options="dropzoneOptions"
            v-on:vdropzone-sending="sendingEvent"
            @vdropzone-upload-progress="onUploadImage"
            @vdropzone-queue-complete="onUploadFinish"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                Drag and drop to upload content!
              </h3>
              <div class="subtitle">
                ...or click to select a file from your computer
              </div>
            </div>
          </vue-dropzone>
          <b-button
            class="my-2"
            v-if="fieldData.file[translationLanguage]"
            :href="fieldData.file[translationLanguage]"
            target="_blank"
            >{{
              fieldData.fileName[translationLanguage] || "View File"
            }}</b-button
          >
          <br />
          <b-button
            class="my-2"
            variant="danger"
            v-if="fieldData.file[translationLanguage]"
            @click="removeFile"
            >Remove File</b-button
          >
          <b-row> </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vue2Dropzone from "@/components/Dropzone/VueDropzone.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { makeAuthorizationHeader } from "@/utils";

export default {
  name: "UploadPDFs",
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      showPdfModal: false,
      translationLanguage: "english",
      files: [],
      fields: ["filename", "type", "size", ""],
      file: null,
      imgUrl: null,
      thumbnailProps: { width: 90, height: 90 },
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}v1/uploads/file/form`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        acceptedFiles: "application/pdf",
        maxFiles: 1,
        addRemoveLinks: true,
        headers: makeAuthorizationHeader()
      }
    };
  },
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage"
    })
  },
  mounted() {
    this.tabClick("English");
  },
  methods: {
    ...mapActions(["removeField", "cloneField"]),

    tabClick(key) {
      let tempKey = key.toLowerCase();
      this.translationLanguage = tempKey;
      this.imgUrl = this.fieldData.file[this.transalationLanguage];
    },

    readableBytes(bytes) {
      let i = Math.floor(Math.log(bytes) / Math.log(1024));
      let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${sizes[i]}`;
    },

    onUploadImage() {},

    onUploadFinish() {},

    vsuccess(file, response) {
      if (file.type == "application/pdf") {
        if (response && response.url) {
          this.fieldData.file[this.translationLanguage] = response.url;
          this.fieldData.fileName[this.translationLanguage] = file.name;
          this.imgUrl = response.url;
        }
      } else {
        alert("please upload pdf file only");
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("formId", this.form.formId);
      formData.append("fieldId", this.fieldData.id);
      formData.append("fieldIndex", this.fieldIndex);
      formData.append("sectionIndex", this.fieldIndex);
      formData.append("sectionId", this.fieldIndex);
    },

    removeFile() {
      this.fieldData.file[this.translationLanguage] = null;
      this.fieldData.fileName[this.translationLanguage] = null;
    },

    onDeleteField() {
      let payload = {
        fieldIndex: this.fieldIndex,
        field: this.fieldData,
        sectionIndex: this.sectionIndex
      };
      this.removeField(payload);
    },

    showToast(variant = null, message) {
      this.$bvToast.toast(message, {
        // title: `Clone ${variant || "default"}`,
        title: "Alert",
        variant,
        solid: true
      });
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style>
.pdf-viewer__header {
  display: none !important;
}
</style>
