import { render, staticRenderFns } from "./TpOverTimeComponent.vue?vue&type=template&id=336d6760&scoped=true"
import script from "./TpOverTimeComponent.vue?vue&type=script&lang=js"
export * from "./TpOverTimeComponent.vue?vue&type=script&lang=js"
import style0 from "./TpOverTimeComponent.vue?vue&type=style&index=0&id=336d6760&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336d6760",
  null
  
)

export default component.exports