import { getSelectedOption, isRequired } from "@/utils";
import { cloneDeep } from "lodash";

export default {
  methods: {
    validateTriggerStepActionName(triggerStep) {
      const selectedValueOption = triggerStep.getSelectedActionOption();
      let isInvalid = false;

      if (
        !isRequired(selectedValueOption) ||
        !isRequired(selectedValueOption.value)
      ) {
        triggerStep.setActionNameErrorMessage("required");
        isInvalid = true;
      } else {
        triggerStep.setActionNameErrorMessage("");
      }

      return isInvalid;
    },
    validateTriggerExpectedData(triggerStep, index) {
      let isInvalid = false;
      const expectedDataFieldClone = cloneDeep(triggerStep.expectDataList[index]);
      const selectedValueOption = getSelectedOption(expectedDataFieldClone?.options);

      if (
        !isRequired(selectedValueOption) ||
        !isRequired(selectedValueOption.value)
      ) {
        expectedDataFieldClone.errorMessage = "required";
        isInvalid = true;
      } else {
        expectedDataFieldClone.errorMessage = "";
      }

      triggerStep.setExpectedDataOptionByIndex(expectedDataFieldClone, index);

      return isInvalid;
    },
    validateTriggerExpectedDataList(triggerStep) {
      const isFieldInvalid = [];

      for (let index = 0; index < triggerStep.expectDataList.length; index++) {
        isFieldInvalid.push(this.validateTriggerExpectedData(triggerStep, index));
      }

      return  isFieldInvalid.includes(true);
    },
    validateTriggerStep(triggerStep) {
      const isTriggerActionNameInvalid = this.validateTriggerStepActionName(triggerStep);
      const isTriggerExpectDataListInvalid = this.validateTriggerExpectedDataList(triggerStep);

      return isTriggerActionNameInvalid || isTriggerExpectDataListInvalid;
    }
  }
}