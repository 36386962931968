<template>
  <div>
    <QuestionnairesComponent
      :companyId="companyId"
      :thirdPartyId="thirdPartyId"
      :isDisabled="isDisabled"
      :thirdPartyData="thirdPartyData"
    />
  </div>
</template>

<script>
import QuestionnairesComponent from "./questionnairesComponent.vue";

export default {
  name: "QuestionnairesPoliciesTrainingTabComponent",
  data() {
    return {};
  },
  components: {
    QuestionnairesComponent
  },
  methods: {},
  props: {
    isDisabled: Boolean,
    companyId: Number,
    thirdPartyId: Number,
    thirdPartyData: Object
  }
};
</script>
