<template>
  <section class="user-email-templates">
    <BaseLoader v-if="isLoadingTableData" />
    <template v-else>
      <Modal
        class="user-email-templates__modal"
        v-if="isModalVisible"
        title="Delete Template"
        @close-modal="closeModal"
      >
        <div class="user-email-templates__modal-text">
          <BaseText :text="makeModalText" />
        </div>
        <template #left>
          <CallToAction
            type="button"
            theme="error-inverse"
            value="Cancel"
            data-test-id="user-email-templates__modal-cancel-cta"
            @click="onCancelDeleteUserEmailTemplate"
          />
        </template>
        <template #right>
          <CallToAction
            type="button"
            value="Delete"
            data-test-id="user-email-templates__modal-delete-cta"
            @click="deleteEmailTemplateWrapper"
          />
        </template>
      </Modal>
      <div class="user-email-templates__operations">
        <CallToAction
          theme="strong"
          value="Add"
          data-test-id="user-email-templates__operations-cta"
          @click="onAddUserEmailTemplate"
        />
      </div>
      <BaseText
        :text="operationFeedback"
        theme="success-inverse"
        data-test-id="user-email-templates__operations-feedback"
      />
      <InputErrorMessage :error="errorMessagesApi" />
      <StickyTable
        v-if="isDataAvailable"
        caption="User Email Templates"
        class="user-email-templates__table"
        data-test-id="user-email-templates__table"
        :table-headers="makeUserEmailTemplatesHeaders"
        :table-rows="makeUserEmailTemplatesRows"
        @table-row-click="onTableRowClick"
      />
      <BaseText
        v-else
        text="You do not have any templates. Click the Add button to create one."
        data-test-id="user-email-templates__table-fallback-message"
      />
    </template>
  </section>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import DropDownList from "@/molecules/DropDownList/DropDownList";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { mapState, mapActions } from "vuex";
import {
  userEmailTemplatesListTableHeaders,
  typographySize,
  userEmailTemplateKeysDTO,
  operations,
  actionName,
  urls,
  userEmailTemplateEvents
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Modal from "@/molecules/Modal/Modal";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { hasStatus500, hasStatus404, isNotAuthorized } from "@/utils";

export default {
  name: "UserEmailTemplates",
  components: {
    StickyTable,
    BaseText,
    CallToAction,
    InputErrorMessage,
    Modal,
    BaseLoader
  },
  data() {
    return {
      operationFeedback: "",
      errorMessagesApi: "",
      selectedUserEmailTemplate: {
        id: "",
        name: ""
      },
      isModalVisible: false,
      isLoadingTableData: false
    };
  },
  computed: {
    ...mapState({
      userEmailTemplates: (state) => state.userEmailTemplates.userEmailTemplates
    }),
    makeUserEmailTemplatesListForTable() {
      return this.userEmailTemplates?.length
        ? this.userEmailTemplates.map(({ name, description }) => ({
            [userEmailTemplateKeysDTO.NAME]: name,
            [userEmailTemplateKeysDTO.DESCRIPTION]: description,
            [userEmailTemplateKeysDTO.ACTION]: "Action"
          }))
        : [];
    },
    isDataAvailable() {
      return (
        !!this.makeUserEmailTemplatesHeaders.length &&
        !!this.makeUserEmailTemplatesRows.length
      );
    },
    makeUserEmailTemplatesHeaders() {
      return this.makeUserEmailTemplatesListForTable.length
        ? Object.keys(this.makeUserEmailTemplatesListForTable[0]).reduce(
            (acc, key) => {
              if (userEmailTemplatesListTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: userEmailTemplatesListTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    verticalAlign: "top",
                    background: "white",
                    padding: "5px"
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    makeUserEmailTemplatesRows() {
      return this.makeUserEmailTemplatesListForTable.length
        ? this.makeUserEmailTemplatesListForTable.map((template, index) =>
            Object.keys(template).reduce((acc, templateKey) => {
              if (templateKey === userEmailTemplateKeysDTO.ACTION) {
                acc[templateKey] = {
                  component: DropDownList,
                  componentOptions: {
                    dropDownList: [
                      {
                        value: JSON.stringify({
                          operation: operations.EDIT,
                          id: this.userEmailTemplates[index].id
                        }),
                        text: "Edit"
                      },
                      {
                        value: JSON.stringify({
                          operation: operations.CLONE,
                          id: this.userEmailTemplates[index].id
                        }),
                        text: "Clone"
                      },
                      {
                        value: JSON.stringify({
                          operation: operations.DELETE,
                          id: this.userEmailTemplates[index].id,
                          name: this.userEmailTemplates[index].name
                        }),
                        text: "Delete"
                      }
                    ],
                    style: {
                      display: "inline-block"
                    }
                  }
                };
              } else {
                acc[templateKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: template[templateKey],
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              }
              return acc;
            }, {})
          )
        : [];
    },
    makeModalText() {
      return `Are you sure you want to delete the template, ${this.selectedUserEmailTemplate.name}?`;
    }
  },
  methods: {
    ...mapActions({
      deleteUserEmailTemplate:
        actionName.USER_EMAIL_TEMPLATES.DELETE_USER_EMAIL_TEMPLATE,
      cloneUserEmailTemplate:
        actionName.USER_EMAIL_TEMPLATES.CLONE_USER_EMAIL_TEMPLATE
    }),
    onTableRowClick(tableRowObj) {
      const { operation, id, name } = JSON.parse(tableRowObj);
      if (operation === operations.EDIT) {
        this.$emit(userEmailTemplateEvents.FETCH_USER_EMAIL_TEMPLATE, id);
        this.$router.push(urls.EDIT_USER_EMAIL_TEMPLATE_ID(id));
      } else if (operation === operations.DELETE) {
        this.setSelectedUserEmailTemplate(id, name);
        this.isModalVisible = true;
      } else if (operation === operations.CLONE) {
        this.cloneUserEmailTemplateWrapper(id);
      }
    },
    onAddUserEmailTemplate() {
      this.$emit(userEmailTemplateEvents.RESET_USER_EMAIL_TEMPLATE);
      this.$router.push(urls.ADD_USER_EMAIL_TEMPLATE);
    },
    setSelectedUserEmailTemplate(id = "", name = "") {
      this.selectedUserEmailTemplate.id = id;
      this.selectedUserEmailTemplate.name = name;
    },
    async cloneUserEmailTemplateWrapper(templateId) {
      try {
        this.isLoadingTableData = true;
        await this.cloneUserEmailTemplate(templateId);
      } catch (error) {
        this.showError(error, "clone");
      } finally {
        this.isLoadingTableData = false;
      }
    },
    onCancelDeleteUserEmailTemplate() {
      this.resetSelectedUserEmailTemplate();
      this.closeModal();
    },
    async deleteEmailTemplateWrapper() {
      try {
        this.closeModal();
        this.isLoadingTableData = true;
        this.operationFeedback = "";
        this.errorMessagesApi = "";
        await this.deleteUserEmailTemplate(this.selectedUserEmailTemplate.id);
        this.operationFeedback = "Template deleted successfully";
      } catch (error) {
        this.showError(error, "delete");
      } finally {
        this.resetSelectedUserEmailTemplate();
        this.isLoadingTableData = false;
      }
    },
    resetSelectedUserEmailTemplate() {
      this.setSelectedUserEmailTemplate("", "");
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showError({ response } = {}, type = "") {
      this.operationFeedback = "";

      if (hasStatus500(response)) {
        this.errorMessagesApi =
          "There is a server error. Please contact support.";
      } else if (hasStatus404(response)) {
        this.errorMessagesApi = `Failed to find and ${type} the template.`;
      } else if (!isNotAuthorized(response)) {
        const description = response?.status
          ? `with status of ${response?.status}`
          : "";
        this.errorMessagesApi = `There is an unknown error ${description}. Please contact support.`;
      }
    }
  },
  mounted() {
    this.operationFeedback = this.$route?.params?.message;
  }
};
</script>

<style lang="scss" scoped>
.user-email-templates {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &__modal-text {
    padding: 15px 15px 0;
  }

  &__operations {
    text-align: right;
    padding-bottom: 20px;
  }
}
</style>
