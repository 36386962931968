import { actions } from "./thirdParty.actions.store";
import { mutations } from "./thirdParty.mutations.store";
import { state } from "./thirdParty.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
