var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.isLoadingInstantDueDiligenceArchivedReportsData,"rounded":"sm"}},[_c('b-card',{staticClass:"border-0",attrs:{"header-tag":"header","body-class":"cardBodyClass"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('h6',[_vm._v("Archived Due Diligence Reports")])]),_c('b-col',{staticStyle:{"display":"flex"},attrs:{"cols":"3"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-info","size":"sm"},on:{"click":_vm.getTpArchiveDueDeligenceReportStatus}},[_c('font-awesome-icon',{attrs:{"icon":"sync"}})],1),_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","id":"filterInputArchived","placeholder":"Type to Search"},model:{value:(_vm.filterArchived),callback:function ($$v) {_vm.filterArchived=$$v},expression:"filterArchived"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filterArchived},on:{"click":function($event){_vm.filterArchived = ''}}},[_vm._v(_vm._s(_vm.$t("clearBtnText")))])],1)],1)],1)],1)]},proxy:true}])},[_c('b-card-body',{staticClass:"dueScrollFlow",staticStyle:{"paddingtop":"15px"},attrs:{"body-class":"cardBodyClass"}},[_c('b-table',{attrs:{"responsive":"sm","sort-by":_vm.sortByArchive,"hover":"","filter":_vm.filterArchived,"per-page":_vm.perPageArchive,"current-page":_vm.currentPageArchive,"items":_vm.instantDueDiligenceArchivedReportsItems,"fields":_vm.instantDueDiligenceReportsfields},on:{"update:sortBy":function($event){_vm.sortByArchive=$event},"update:sort-by":function($event){_vm.sortByArchive=$event}},scopedSlots:_vm._u([{key:"thead-top",fn:function(data){return [_c('b-tr',{staticClass:"text-center"},[_c('b-th',{attrs:{"variant":"info","colspan":"4"}},[_vm._v(_vm._s(_vm.$t("eb360.dueDiligenceRemediation.initialReport")))]),(
                      data.fields.findIndex(
                        (x) => x.key == 'after_remediation_alert_status'
                      ) != -1
                    )?_c('b-th',{attrs:{"variant":"primary","colspan":"5"}},[_vm._v(_vm._s(_vm.$t("eb360.dueDiligenceRemediation.remediation")))]):_vm._e()],1)]}},{key:"cell(inserted_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.dateFormat(data.item.inserted_date))+" ")]}},{key:"cell(product_type)",fn:function(data){return [(data.item.reportTypeText == 'OGM')?_c('div',{staticStyle:{"text-transform":"uppercase"}},[_c('div',[_vm._v(" "+_vm._s(data.item.product_type == "1" ? "IDD" : data.item.product_type == "2" ? "IDD+" : "")+" OGM "+_vm._s(data.item.remediation_date == null ? "Unremediated" : "Remediated")+" "+_vm._s(data.item.rpt_status == "0" ? "Green" : data.item.rpt_status == "1" ? "Red" : "")+" ")])]):_c('p',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(" "+_vm._s(data.item.reportTypeText)+" ")])]}},{key:"cell(initial_alert_status)",fn:function(data){return [(data.item.reportTypeText == 'OGM')?_c('div',[_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],attrs:{"width":"40%","src":require(`../../../../assets/HighAlert.png`),"title":"High Alert"}})],1):_c('div',[(
                      data.item.initial_alert_status == '0' ||
                      data.item.initial_alert_status == null
                    )?_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"width":"40%","src":require(`../../../../assets/OK.png`),"title":"No Risk Alert"}}):_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"width":"40%","src":require(`../../../../assets/HighAlert.png`),"title":"Risk Alert"}})],1)]}},{key:"cell(action)",fn:function(data){return [(data.item.reportTypeText != 'OGM')?_c('b-dropdown',{attrs:{"size":"sm","variant":"outline","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-v"}})]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{staticClass:"text-secondary",on:{"click":function($event){return _vm.downloadArchivedPdfReport(data)}}},[_vm._v("Download")])],1):_vm._e()]}},{key:"cell(remediation_date)",fn:function(data){return [(data.item.remediation_date == null)?_c('p',[_vm._v(" ")]):_c('p',[_vm._v(_vm._s(_vm.dateFormat(data.item.remediation_date)))])]}},{key:"cell(after_remediation_alert_status)",fn:function(data){return [(
                    data.item.after_remediation_alert_status == 5 ||
                    data.item.after_remediation_alert_status == 6
                  )?_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.html",value:(
                    _vm.iconData.afterRemediationAlertStatus.status[
                      data.item.after_remediation_alert_status
                    ].tooltipEnable
                      ? _vm.iconData.afterRemediationAlertStatus.status[
                          data.item.after_remediation_alert_status
                        ].tooltip
                      : ''
                  ),expression:"\n                    iconData.afterRemediationAlertStatus.status[\n                      data.item.after_remediation_alert_status\n                    ].tooltipEnable\n                      ? iconData.afterRemediationAlertStatus.status[\n                          data.item.after_remediation_alert_status\n                        ].tooltip\n                      : ''\n                  ",modifiers:{"hover":true,"bottom":true,"html":true}}],attrs:{"width":_vm.iconData.afterRemediationAlertStatus.status[
                      data.item.after_remediation_alert_status
                    ].imageWidth,"src":require(`../../../../assets/${
                      _vm.iconData.afterRemediationAlertStatus.status[
                        data.item.after_remediation_alert_status
                      ].image
                    }`)}}):(
                    (data.item.initial_alert_status == '0' ||
                      data.item.initial_alert_status == null) &&
                    data.item.reportTypeText != 'OGM'
                  )?_c('div'):(
                    data.item.reportTypeText == 'OGM' &&
                    data.item.risk_document_id != null
                  )?_c('div',[(
                      data.item.product_type == '1' &&
                      data.item.rpt_status == '0'
                    )?_c('div',[_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.html",value:(
                        _vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_GREEN_IDD_OGM.tooltipEnable
                          ? _vm.iconData.afterOGMRemediationAlertStatus.status
                              .Remediated_GREEN_IDD_OGM.tooltip
                          : ''
                      ),expression:"\n                        iconData.afterOGMRemediationAlertStatus.status\n                          .Remediated_GREEN_IDD_OGM.tooltipEnable\n                          ? iconData.afterOGMRemediationAlertStatus.status\n                              .Remediated_GREEN_IDD_OGM.tooltip\n                          : ''\n                      ",modifiers:{"hover":true,"bottom":true,"html":true}}],attrs:{"width":_vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_GREEN_IDD_OGM.imageWidth,"src":require(`../../../../assets/${_vm.iconData.afterOGMRemediationAlertStatus.status.Remediated_GREEN_IDD_OGM.image}`)}})],1):(
                      data.item.product_type == '2' &&
                      data.item.rpt_status == '0'
                    )?_c('div',[_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.html",value:(
                        _vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_GREEN_IDD_Plus_OGM.tooltipEnable
                          ? _vm.iconData.afterOGMRemediationAlertStatus.status
                              .Remediated_GREEN_IDD_Plus_OGM.tooltip
                          : ''
                      ),expression:"\n                        iconData.afterOGMRemediationAlertStatus.status\n                          .Remediated_GREEN_IDD_Plus_OGM.tooltipEnable\n                          ? iconData.afterOGMRemediationAlertStatus.status\n                              .Remediated_GREEN_IDD_Plus_OGM.tooltip\n                          : ''\n                      ",modifiers:{"hover":true,"bottom":true,"html":true}}],attrs:{"width":_vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_GREEN_IDD_Plus_OGM.imageWidth,"src":require(`../../../../assets/${_vm.iconData.afterOGMRemediationAlertStatus.status.Remediated_GREEN_IDD_Plus_OGM.image}`)}})],1):(
                      data.item.product_type == '1' &&
                      data.item.rpt_status == '1'
                    )?_c('div',[_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.html",value:(
                        _vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_RED_IDD_OGM.tooltipEnable
                          ? _vm.iconData.afterOGMRemediationAlertStatus.status
                              .Remediated_RED_IDD_OGM.tooltip
                          : ''
                      ),expression:"\n                        iconData.afterOGMRemediationAlertStatus.status\n                          .Remediated_RED_IDD_OGM.tooltipEnable\n                          ? iconData.afterOGMRemediationAlertStatus.status\n                              .Remediated_RED_IDD_OGM.tooltip\n                          : ''\n                      ",modifiers:{"hover":true,"bottom":true,"html":true}}],attrs:{"width":_vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_RED_IDD_OGM.imageWidth,"src":require(`../../../../assets/${_vm.iconData.afterOGMRemediationAlertStatus.status.Remediated_RED_IDD_OGM.image}`)}})],1):_vm._e(),(
                      data.item.product_type == '2' &&
                      data.item.rpt_status == '1'
                    )?_c('div',[_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.html",value:(
                        _vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_RED_IDD_Plus_OGM.tooltipEnable
                          ? _vm.iconData.afterOGMRemediationAlertStatus.status
                              .Remediated_RED_IDD_Plus_OGM.tooltip
                          : ''
                      ),expression:"\n                        iconData.afterOGMRemediationAlertStatus.status\n                          .Remediated_RED_IDD_Plus_OGM.tooltipEnable\n                          ? iconData.afterOGMRemediationAlertStatus.status\n                              .Remediated_RED_IDD_Plus_OGM.tooltip\n                          : ''\n                      ",modifiers:{"hover":true,"bottom":true,"html":true}}],attrs:{"width":_vm.iconData.afterOGMRemediationAlertStatus.status
                          .Remediated_RED_IDD_Plus_OGM.imageWidth,"src":require(`../../../../assets/${_vm.iconData.afterOGMRemediationAlertStatus.status.Remediated_RED_IDD_Plus_OGM.image}`)}})],1):_vm._e()]):_vm._e()]}},{key:"cell(remediation_action)",fn:function(data){return [(
                    data.item.after_remediation_alert_status == '6' ||
                    data.item.after_remediation_alert_status == '5'
                  )?_c('b-dropdown',{attrs:{"size":"sm","variant":"outline","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-v"}})]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{staticClass:"text-secondary",on:{"click":function($event){return _vm.downloadArchivedRemediatedReport(data)}}},[_vm._v("Download")])],1):(
                    data.item.reportTypeText == 'OGM' &&
                    data.item.risk_document_id != null
                  )?_c('b-dropdown',{attrs:{"size":"sm","variant":"outline","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-v"}})]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{staticClass:"text-secondary",on:{"click":function($event){return _vm.downloadArchivedRemediatedOGMReport(data)}}},[_vm._v("Download")])],1):_vm._e()]}}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-select',{attrs:{"id":"perPageSelect","size":"sm","options":_vm.pageOptionsArchive},model:{value:(_vm.perPageArchive),callback:function ($$v) {_vm.perPageArchive=$$v},expression:"perPageArchive"}})],1),_c('b-col',[_c('b-pagination',{staticClass:"float-right",attrs:{"total-rows":_vm.rowsArchive,"per-page":_vm.perPageArchive,"first-text":"First","prev-text":"Prev","next-text":"Next","last-text":"Last","aria-controls":"my-table"},model:{value:(_vm.currentPageArchive),callback:function ($$v) {_vm.currentPageArchive=$$v},expression:"currentPageArchive"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }