<!-- onboarding header -->
<template>
  <b-navbar toggleable="lg" type="light" variant="light">
    <b-navbar-brand>
      <Logo :logoProps="logo" />
    </b-navbar-brand>
    <b-navbar-nav v-if="isUserSuperAdmin">
      <b-nav-item href="/onboarding/templates">Templates</b-nav-item>
      <b-nav-item href="/onboarding/clients-configuration">Clients</b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown
        :text="defaultLanguageText(formDefaultLanguage)"
        class="capitalize"
        right
        v-if="isUserThirdParty"
      >
        <b-dropdown-item
          v-for="(lang, index) in $store.state.form1.formTranslations"
          class="capitalize"
          :key="index"
          @click="onUpdateLanguage(lang)"
          >{{ defaultLanguageText(lang) }}</b-dropdown-item
        >
      </b-nav-item-dropdown>
      <b-nav-item-dropdown right>
        <template v-slot:button-content>
          <em>
            <font-awesome-icon icon="user" />
            {{ fullName }}
          </em>
        </template>
        <b-dropdown-item @click="onLogOut">
          {{ $t("signOutBtnText") }}
        </b-dropdown-item>
        <b-dropdown-item href="/onboarding/profile">
          {{ $t("updateProfile") }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import Logo from "@/organisms/Logo/Logo";
import { mapActions, mapGetters } from "vuex";
import { getterName } from "@/constants";

export default {
  name: "eb2HeaderEmails",
  components: {
    Logo
  },
  data() {
    return {
      logo: {
        width: "auto",
        height: "35px"
      },
      formDefaultLanguage: "english"
    };
  },
  computed: {
    ...mapGetters({
      fullName: getterName.USER.GET_FULL_NAME,
      isUserThirdParty: getterName.USER.IS_USER_THIRD_PARTY,
      isUserSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN
    })
  },
  mounted() {
    this.setLanguagesToStore();
    this.onUpdateLanguage(this.formDefaultLanguage);
  },
  methods: {
    ...mapActions("auth", ["logOut"]),
    setLanguagesToStore() {
      let languageInLocalStore =
        localStorage.getItem("formDefaultLanguage") || "english";
      if (languageInLocalStore == "chinese simplified") {
        this.$i18n.locale = "chinese";
      } else if (languageInLocalStore == "chinese traditional taiwan") {
        this.$i18n.locale = "chinesetr";
      } else if (languageInLocalStore == "bahasa indonesia") {
        this.$i18n.locale = "bahasaIndonesia";
      } else {
        this.$i18n.locale = languageInLocalStore;
      }
      this.$store.state.form1.formDefaultLanguage = languageInLocalStore;
      this.$store.state.selectedLanguage = languageInLocalStore;
      this.formDefaultLanguage = languageInLocalStore;
    },
    defaultLanguageText(lang) {
      let ll = lang.toLowerCase();
      if (ll == "chinese simplified") {
        return this.$t("languages.chinese");
      } else if (ll == "chinese traditional taiwan") {
        return this.$t("languages.chinesetr");
      } else if (ll == "bahasa indonesia") {
        return this.$t("languages.bahasaIndonesia");
      } else {
        return this.$i18n.availableLocales.includes(ll)
          ? this.$t(`languages.${ll}`)
          : ll;
      }
    },
    onUpdateLanguage(lang) {
      let yy = lang.toLowerCase();
      if (yy == "chinese simplified") {
        lang = "chinese";
      }
      if (yy == "chinese traditional taiwan") {
        lang = "chinesetr";
      }
      if (yy == "bahasa indonesia") {
        lang = "bahasaIndonesia";
      }
      let ll = lang.toLowerCase();
      let ff = this.$i18n.availableLocales.includes(ll); // checking selected languages with saved messages
      this.$i18n.locale = ff ? ll : "english";
      this.$store.state.form1.formDefaultLanguage = yy;
      this.$store.state.selectedLanguage = yy;
      localStorage.setItem("formDefaultLanguage", yy);
      this.formDefaultLanguage = ff ? ll : "english";
      if (ll == "arabic" || ll == "hebrew") {
        this.$store.state.rightAlign = true;
      } else {
        this.$store.state.rightAlign = false;
      }
    },
    onLogOut() {
      this.logOut();
    }
  }
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
