<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-card
            no-body
            class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0"
          >
            <b-navbar toggleable="lg">
              <b-navbar-brand
                href="#"
                class="text-dark"
              >
                <strong style="color:#3b3e66">EDD Report Type</strong>
              </b-navbar-brand>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <!-- Add button -->
                  <b-button
                    @click="showAddOrderTypeModal = !showAddOrderTypeModal"
                    class="mr-2"
                    variant="outline-primary"
                    size="sm"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>

                  <!-- Reload button -->
                  <b-button
                    variant="outline-info"
                    size="sm"
                    class="mr-4"
                    @click="reloadTable"
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>

                  <!-- Search bar -->
                  <b-form onSubmit="return false;">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        @keyup.enter="submitSearch"
                        @input="onEmpltyFilter($event)"
                      ></b-form-input>
                      <b-input-group-append>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-button
                          :disabled="!filter"
                          @click="setFIlterClear"
                        >Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form>
                </b-nav-form>
              </b-navbar-nav>
            </b-navbar>
            <!-- EDD report type table start -->
            <b-table
              sticky-header="calc(100vh - 295px)"
              :busy.sync="isLoading"
              bordered
              ref="table"
              id="edd-order-type-table"
              :fields="staticFields"
              :items="getElements"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              no-sort-reset
            >
              <template v-slot:cell(created_at)="data">
                <p>{{ createdAtDate(data.item.created_at) }}</p>
              </template>
              <template v-slot:cell(actions)="data">
                <b-btn
                  @click="editOrderType(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-info"
                >
                  <font-awesome-icon icon="edit" />
                </b-btn>
              </template>
            </b-table>
            <!-- EDD report type table end -->

            <template v-slot:footer>
              <b-row>
                <b-col cols="2">
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
                <b-col cols="6">
                  <p class="text-center">
                    Showing Result From
                    <b>{{fromRows}}</b> to
                    <b>{{toRows}}</b>
                  </p>
                </b-col>
                <b-col cols="4">
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    aria-controls="edd-order-type-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Modal for add/update EDD report type -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :title="isEditOrderType ? 'Update EDD Report Type' : 'Add EDD Report Type'"
      size="lg"
      scrollable
      v-model="showAddOrderTypeModal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label class="text-left">EDD Report Type:</label>
                <b-form-input
                  v-model="form.tag"
                  size="sm"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <div style="display: grid; justify-content: center;">
             <p>{{ isEditOrderType ? 'Your EDD Order Type is being Updating......' : 'Your EDD Order Type is being Creating......' }}</p>
            <div style="display: flex;justify-content: center;">
              <b-spinner
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{ isEditOrderType ? 'Your EDD Order Type has been updated successfully.' :'Your EDD Order Type has been added successfully.' }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          v-if="!submiting"
          @click="resetModal"
          variant="outline-danger"
        >Close</b-button>
        <b-button
          v-if="!submiting && !responseSuccess"
          @click="checkValidation"
          variant="outline-success"
        >{{ isEditOrderType ? 'Update' : 'Save' }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "EddOrderType",
  data () {
    return {
      isEditOrderType: false,
      submiting: false,
      isLoading: false,
      responseSuccess: false,
      showAddOrderTypeModal: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "created_at", label: "Created Date", sortable: false },
        { key: "name", label: "EDD Report Type Title", sortable: false },
        { key: "actions", label: "Action", sortable: false },
      ],
      form: {
        tag: null,
      },
      orderTypeId: null,
      filterTable: false,
      filterInputValue: null,
    };
  },
  computed: {
    rows () {
      return this.totalRows;
    },
  },
  methods: {
    createdAtDate ( value ) {
      moment.locale( "en" );
      return moment( value ).format( "DD/MM/YYYY" );
    },
    reloadTable () {
      this.$root.$emit( "bv::refresh::table", "edd-order-type-table" );
    },
    resetForm () {
      this.form = {
        tag: null,
      };
    },
    resetModal () {
      this.resetForm();
      this.orderTypeId = null;
      this.showAddOrderTypeModal = false;
      this.isEditOrderType = false;
      this.submiting = false;
      this.isLoading = false;
      this.responseSuccess = false;
      this.filterTable = false;
    },
    // check validation before adding/updating edd report type
    checkValidation () {
    if ( this.isEditOrderType ) {
        this.updateApprovalType().then( this.reloadTable );
      } else {
        this.saveApprovalType().then( this.reloadTable );
      }
    },

    // Add EDD report type API
    async saveApprovalType () {
      let formData = {
        name: this.form.tag,
      }
      try {
        this.submiting = true;
        this.isLoading = true;
        await HTTP( "post", "thirdparty/edd/client/packages/create", formData, makeAuthorizationHeader());
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
      } catch ( error ) {
        this.isLoadingData = false;
        return [error];
      }
    },

    // get EDD report type API
    async getElements () {
      this.isLoading = true;
      let formData = {
        per_page: this.perPage,
        page: this.currentPage,
        filter: this.filter,
      };
      try {
         let result = await HTTP( "post", `thirdparty/edd/client/all/packages/${0}`, formData, makeAuthorizationHeader());
        if ( result.status == 200 ) {
          const items = result.data.data ;
          this.isLoading = false;
          this.fromRows = result.data.from;
          this.toRows = result.data.to;
          this.totalRows = result.data.total;
          return items;
        }
      } catch ( error ) {
        this.isLoading = false;
        return [error];
      }
    },

    // Update EDD report type API
    async updateApprovalType () {
      let formData = {
        name: this.form.tag,
        package_id: this.orderTypeId,
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        await HTTP( "post", "thirdparty/edd/client/packages/edit", formData, makeAuthorizationHeader());
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
      } catch ( err ) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    submitSearch () {
      if ( this.filter ) {
        this.filterInputValue = this.filter;
        this.filterTable = true;
        this.currentPage = 1;
        this.getElements();
      }
    },
    onEmpltyFilter ( value ) {
      if ( value ) {
        return false;
      } else {
        this.setFIlterClear();
      }
    },
    setFIlterClear () {
      this.filter = "";
      this.filterInputValue = null;
      this.filterTable = false;
    },
    // open edit report type modal
    editOrderType ( data ) {
      this.isEditOrderType = true;
      this.orderTypeId = data.item.id;
      this.form = {
        tag: data.item.name,
      };
      this.showAddOrderTypeModal = true;
    },
  },
};
</script>
