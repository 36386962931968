import { state as makeState } from "@/store/actions/actions.state.store";

export const mutations = {
  setActions(state, payload) {
    state.actions = payload;
  },
  setAction(state, payload) {
    state.action = payload;
  },
  setExpectedDataEntities(state, payload) {
    state.expectedDataEntities = payload;
  },
  setEntityReference(state, { references, index }) {
    state.expectedDataEntities[index].references = references;
  },
  resetAction(state) {
    state.action = {};
  },
  resetActions(state) {
    Object.assign(state, makeState());
  }
};
