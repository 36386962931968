const riskScoreCategoryValues = {
  HIGH: 8,
  MODERATE_HIGH: 7,
  MODERATE_LOW: 5,
  LOW: 4
};

const ddqScoring = {
  IS_SCORED: 1
};

const riskRating = {
  INVALID: "Invalid"
};

export { riskRating, ddqScoring, riskScoreCategoryValues };
