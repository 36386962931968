<template>
  <div>
    <b-card no-body class="mt-3" header="Introduction Page">
      <b-tabs pills card vertical nav-wrapper-class="over-flow-tabs">
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div class="col-md-12 text-left">
            <quill-editor v-model="formIntroText" @change="setFormIntroText" />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-navbar
      toggleable="sm"
      type="dark"
      variant="dark"
      class="m-0"
      fixed="bottom"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-button
              size="sm"
              variant="light"
              class="my-2 my-sm-0 mr-2"
              type="button"
              to="/form/details"
              >Back</b-button
            >
            <b-button
              size="sm"
              variant="light"
              class="my-2 my-sm-0"
              type="button"
              to="/form/builder"
              >Next</b-button
            >
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "FormIntroContainer",
  data() {
    return {
      isLoading: false,
      formIntroText: undefined,
      translationLanguage: "english"
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage"
    })
  },
  mounted() {
    if (this.form.formIntroText == undefined) {
      let formIntroText = { english: "" };
      Object.assign(this.form, { formIntroText });
    }
    this.tabClick("English");

    // this.formIntroText =
    // 	(this.form.formIntroText &&
    // 		this.form.formIntroText[this.selectedLanguage]) ||
    // 	undefined;
  },
  async beforeDestroy() {
    await EventBus.$emit("onAutoSaveJsonForm", true);
  },
  methods: {
    tabClick(key) {
      //
      let tempKey = key.toLowerCase();
      this.translationLanguage = tempKey;
      this.formIntroText = this.form.formIntroText[this.translationLanguage];
    },

    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        // title: `Clone ${variant || "default"}`,
        title: "Alert",
        variant,
        solid: true
      });
    },
    setFormIntroText(value) {
      this.form.formIntroText[this.translationLanguage] = value;
    }
  }
};
</script>
<style>
.over-flow-tabs {
  display: grid;
  overflow-y: scroll;
  /* max-height: 500px !important */
  max-height: calc(100vh - 306px);
}
</style>
