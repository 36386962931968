import { esgService } from "@/services";
import { mutationName } from "@/constants";

export default {
  async fetchESGReports({ commit }, endpointParameters) {
    const response = await esgService.fetchESGReports(endpointParameters);

    commit(mutationName.ESG.SET_ESG_REPORTS, response, { root: true });
  },
  async fetchESGReportById({commit}, endpointParameters) {
    const response = await esgService.fetchESGReportById(endpointParameters);

    commit(mutationName.ESG.SET_LATEST_ESG_REPORT, response, { root: true });
  }
};
