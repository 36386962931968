<template>
  <div class="if-block">
    <template
      v-for="({ left, right, comparator, operator, id }, index) in ifStatements"
    >
      <div
        class="if-block__statement-wrapper"
        data-test-id="if-block__statement-wrapper"
        :key="`if-block__statement-${index}-${id}`"
        @mouseover="setShowDeleteStep(index, true)"
        @mouseleave="setShowDeleteStep(index, false)"
      >
        <Select
          v-if="showOperator(index, operator)"
          :id="`if-block__statement-${index}-${id}`"
          :options="operator.options"
          @change="emitChange(actionKeys.OPERATOR, index, $event, id)"
          class="if-block__statement-operator"
          data-test-id="if-block__statement-operator"
        />
        <div class="if-block__statement" :key="`if-block__Statement${index}`">
          <div class="if-block__left-center">
            <!-- eslint-disable-next-line vue/require-component-is -->
            <Select
              :id="`if-statement-left-${index}-${id}`"
              :options="left.options"
              :error="left.error"
              class="if-block__left-center--left"
              data-test-id="if-block__left-center--left"
              @change="emitChange(actionKeys.LEFT, index, $event, id)"
            />
            <Select
              :id="`if-statement-comparator-${index}-${id}`"
              :options="comparator.options"
              :error="comparator.error"
              class="if-block__left-center--center"
              data-test-id="if-block__left-center--center"
              @change="emitChange(actionKeys.COMPARATOR, index, $event, id)"
            />
          </div>
          <!-- eslint-disable-next-line vue/require-component-is -->
          <component
            v-if="showRight(index)"
            :is="right.component"
            v-bind="right.componentOptions"
            class="if-block__right"
            data-test-id="if-block__right"
            @change="emitChange(actionKeys.RIGHT, index, $event, id)"
          />
        </div>
        <BaseAddDeleteLine
          v-if="showDeleteStep[index]"
          class="calculation-bracket__step-action"
          data-test-id="calculation-bracket__step-action--delete"
          :position="position.RIGHT"
          :type="operations.DELETE"
          @click="deleteIfStatement(id, index)"
        />
        <BaseAddDeleteLine
          class="if-block__step-action"
          data-test-id="if-block__step-action--add"
          :is-hidden="!showAddStep[index]"
          :type="operations.ADD"
          @mouseenter="setShowAddStep(index, true)"
          @mouseleave="setShowAddStep(index, false)"
          @click="addIfStatement(index + 1)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Select from "@/molecules/Select/Select";
import { isEmpty } from "lodash";
import { actionKeys, operations, position } from "@/constants";
import BaseAddDeleteLine from "@/atoms/BaseAddDeleteLine/BaseAddDeleteLine";

export default {
  name: "IfBlock",
  components: { BaseAddDeleteLine, Select },
  props: {
    ifStatements: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showAddStep: [],
      showDeleteStep: [],
      position,
      operations,
      actionKeys
    };
  },
  watch: {
    ifStatements: {
      deep: true,
      handler() {
        this.setStepHoverStates();
      }
    }
  },
  computed: {
    isOnlyStatement() {
      return this.ifStatements?.length === 1;
    }
  },
  created() {
    this.setStepHoverStates();
  },
  methods: {
    setStepHoverStates() {
      this.showAddStep = this.ifStatements.map(() => false);
      this.showDeleteStep = this.ifStatements.map(() => false);
    },
    showOperator(index, operator) {
      return !!(operator?.options?.length && index !== 0);
    },
    showRight(index) {
      return (
        !isEmpty(this.ifStatements?.[index]?.right?.component) &&
        !this.ifStatements?.[index]?.right?.componentOptions.isHidden
      );
    },
    emitChange(property, index, event, id) {
      this.$emit("change", { property, index, event, id });
    },
    addIfStatement(index) {
      this.$emit("change", { property: operations.ADD, index });
    },
    deleteIfStatement(id, index) {
      this.$emit("change", { property: operations.DELETE, id, index });
    },
    setShowAddStep(index, value) {
      this.showAddStep.splice(index, 1, value);
    },
    setShowDeleteStep(index, value) {
      if (!this.isOnlyStatement) {
        this.showDeleteStep.splice(index, 1, value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.if-block {
  display: flex;
  flex-direction: column;

  &__statement {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &-wrapper {
      position: relative;
    }
  }

  &__left-center {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: calc(50% - 46px);

    &--left {
      width: 50%;
      margin-right: 15px;
    }

    &--left,
    &--center {
      margin-bottom: 5px;
    }
  }

  &__right {
    width: 50%;
    border-left: 1px solid $davy-grey;
    margin-left: 15px;
    padding-left: 15px;
  }
}
</style>
