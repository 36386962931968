import {
  platformSettingsKeysDTO,
  platformSettingsKeysAPIDTO,
  productVersionsKeysDTO,
  productVersionsKeysAPIDTO
} from "@/constants";

const makePlatformSettingsDTO = (data = {}) => ({
  [platformSettingsKeysDTO.PLATFORM_FAV_ICON]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_FAV_ICON],
  [platformSettingsKeysDTO.PLATFORM_LOGO]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_LOGO],
  [platformSettingsKeysDTO.PLATFORM_PRIMARY_COLOR]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_PRIMARY_COLOR],
  [platformSettingsKeysDTO.PLATFORM_SECONDARY_COLOR]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_SECONDARY_COLOR],
  [platformSettingsKeysDTO.PLATFORM_TERMS_AND_CONDITIONS_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_TERMS_AND_CONDITIONS_LINK],
  [platformSettingsKeysDTO.PLATFORM_REGISTERED_BUSINESS_NAME]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_REGISTERED_BUSINESS_NAME],
  [platformSettingsKeysDTO.PLATFORM_PRIVACY_POLICY_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_PRIVACY_POLICY_LINK],
  [platformSettingsKeysDTO.PLATFORM_BUSINESS_ADDRESS]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_BUSINESS_ADDRESS],
  [platformSettingsKeysDTO.PLATFORM_COPYRIGHT_NOTICE]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_COPYRIGHT_NOTICE],
  [platformSettingsKeysDTO.PLATFORM_FAQ_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_FAQ_LINK],
  [platformSettingsKeysDTO.PLATFORM_KNOWLEDGE_CENTRE_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_KNOWLEDGE_CENTRE_LINK],
  [platformSettingsKeysDTO.PLATFORM_LEGAL_DISCLAIMER]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_LEGAL_DISCLAIMER],
  [platformSettingsKeysDTO.PLATFORM_MAIN_HEADER_LOGO]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_MAIN_HEADER_LOGO],
  [platformSettingsKeysDTO.PLATFORM_NAME]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_NAME],
  [platformSettingsKeysDTO.PLATFORM_RELEASE_NOTE_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_RELEASE_NOTE_LINK],
  [platformSettingsKeysDTO.PLATFORM_REQUEST_SUPPORT_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_REQUEST_SUPPORT_LINK],
  [platformSettingsKeysDTO.PLATFORM_SUPPORT_EMAIL]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_SUPPORT_EMAIL],
  [platformSettingsKeysDTO.PLATFORM_WEB_URL]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_WEB_URL]
});

const makePublicPlatformSettingsDTO = (data = {}) => ({
  [platformSettingsKeysDTO.DISABLE_PRIVACY_POLICY]:
    data?.[platformSettingsKeysAPIDTO.DISABLE_PRIVACY_POLICY],
  [platformSettingsKeysDTO.PLATFORM_SSO_URL]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_SSO_URL],
  [platformSettingsKeysDTO.PLATFORM_FAV_ICON]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_FAV_ICON],
  [platformSettingsKeysDTO.PLATFORM_LOGO]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_LOGO],
  [platformSettingsKeysDTO.PLATFORM_PRIMARY_COLOR]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_PRIMARY_COLOR],
  [platformSettingsKeysDTO.PLATFORM_SECONDARY_COLOR]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_SECONDARY_COLOR],
  [platformSettingsKeysDTO.PLATFORM_PRIVACY_POLICY_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_PRIVACY_POLICY_LINK],
  [platformSettingsKeysDTO.PLATFORM_REGISTERED_BUSINESS_NAME]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_REGISTERED_BUSINESS_NAME],
  [platformSettingsKeysDTO.PLATFORM_TERMS_AND_CONDITIONS_LINK]:
    data?.[platformSettingsKeysAPIDTO.PLATFORM_TERMS_AND_CONDITIONS_LINK]
});

const makeProductVersionsDTO = (data) => ({
  [productVersionsKeysDTO.EB360]:
    data?.[productVersionsKeysAPIDTO.EB360] || "-",
  [productVersionsKeysDTO.ERAF_ACTIONS]:
    data?.[productVersionsKeysAPIDTO.ERAF_ACTIONS] || "-",
  [productVersionsKeysDTO.ERAF_AUTOMATIONS]:
    data?.[productVersionsKeysAPIDTO.ERAF_AUTOMATIONS] || "-",
  [productVersionsKeysDTO.CORPORATE_DATA]:
    data?.[productVersionsKeysAPIDTO.CORPORATE_DATA] || "-",
  [productVersionsKeysDTO.GREENLITE_ANALYTICS]:
    data?.[productVersionsKeysAPIDTO.GREENLITE_ANALYTICS] || "-",
  [productVersionsKeysDTO.GREENLITE_SCORING]:
    data?.[productVersionsKeysAPIDTO.GREENLITE_SCORING] || "-",
  [productVersionsKeysDTO.ROLES_AND_PERMISSIONS]:
    data?.[productVersionsKeysAPIDTO.ROLES_AND_PERMISSIONS] || "-"
});

export {
  makePlatformSettingsDTO,
  makePublicPlatformSettingsDTO,
  makeProductVersionsDTO
};
