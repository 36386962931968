<template>
  <div class="principals">
    <div v-if="isDnbDisabled" class="principals__disable-text">
      <BaseText
        text="To enable please upgrade to Premium Corporate Data."
        data-test-id="principals__disable-text--dnb-disabled"
        :size="typographySize.BODY_TEXT_ITALIC"
      />
    </div>
    <div v-else-if="!isDnbVerified" class="principals__disable-text">
      <BaseText
        text="Premium Corporate Data is enabled on your system but hasn't been used to verify this Third Party so there is no additional data available."
        data-test-id="principals__disable-text--dnb-not-verified"
        :size="typographySize.BODY_TEXT_ITALIC"
      />
    </div>
    <div v-else class="principals__row" data-test-id="principals__row">
      <div>
        <div class="principals__table">
          <StickyTable
            caption="Principals"
            :table-headers="makeSeniorPrincipalsHeaders"
            :table-rows="makeSeniorPrincipalsRows"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  typographySize,
  seniorPrincipalsKeys,
  seniorPrincipalsTableHeaders
} from "@/constants";

export default {
  name: "Principals",
  components: {
    StickyTable,
    BaseText
  },
  props: {
    premiumCorporateData: {
      type: Object,
      default: () => ({})
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    },
    thirdPartyData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      typographySize
    };
  },
  computed: {
    makeSeniorPrincipalsListForTable() {
      let principalsData = [
        ...this.premiumCorporateData?.mostSeniorPrincipals,
        ...this.premiumCorporateData?.currentPrincipals
      ];
      return principalsData?.length
        ? principalsData.map(
            ({ jobTitles, namePrefix, fullName, nameSuffix, gender }) => ({
              [seniorPrincipalsKeys.TITLE]: jobTitles?.[0]?.title,
              [seniorPrincipalsKeys.NAME_PREFIX]: namePrefix,
              [seniorPrincipalsKeys.FULL_NAME]: fullName,
              [seniorPrincipalsKeys.NAME_SUFFIX]: nameSuffix,
              [seniorPrincipalsKeys.GENDER]: gender,
              [seniorPrincipalsKeys.SELECTED]: ""
            })
          )
        : [];
    },
    makeSeniorPrincipalsHeaders() {
      return this.makeSeniorPrincipalsListForTable.length
        ? Object.keys(this.makeSeniorPrincipalsListForTable[0]).reduce(
            (acc, key) => {
              if (seniorPrincipalsTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: seniorPrincipalsTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    padding: "5px"
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    makeSeniorPrincipalsRows() {
      return this.makeSeniorPrincipalsListForTable.length
        ? this.makeSeniorPrincipalsListForTable.map((action) =>
            Object.keys(action).reduce((acc, actionKey) => {
              if (actionKey === seniorPrincipalsKeys.SELECTED) {
                acc[actionKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: this.checkAssociateExist(action.fullName)
                      ? "Yes"
                      : "No",
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              } else {
                acc[actionKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: action[actionKey],
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              }

              return acc;
            }, {})
          )
        : [];
    }
  },
  methods: {
    checkAssociateExist(fullName) {
      return this.thirdPartyData.srch_tpi_assos.find(
        (associate) => associate.asso_name === fullName
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.principals {
  &__disable-text {
    text-align: center;
  }

  &__table {
    margin: 10px;
  }
}
</style>
