<template>
  <b-row>
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- ----------------Text------------------- -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabClick(data)"
              v-for="(data, index) in form.formTranslations"
              :key="index"
              :title="data"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group
                  id="input-label"
                  label="Label"
                  label-for="input-label"
                >
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    id="input-label"
                    v-model="langugeInput"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Abbreviation Label">
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    v-model="
                      fieldData.abbreviationLabelText[transalationLanguage]
                    "
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Text for table">
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    v-model="fieldData.labelForTable[transalationLanguage]"
                    maxlength="18"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Placeholder"
                  label-for="input-2"
                >
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    id="input-2"
                    v-model="langugePlaceholder"
                    type="text"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="text-area"
                  label="Helper Text"
                  label-for="text-area"
                >
                  <b-form-textarea
                    @blur="addLanguageToField(data)"
                    id="text-area"
                    v-model="langugeHelperText"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Validation Message"
                  label-for="input-3"
                >
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    id="input-3"
                    v-model="langugeValidateMessage"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <!-- validation msessage for min lenth -->
                <b-form-group
                  label="Minimmun Validation Message"
                  v-if="fieldData.validation.min"
                >
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    v-model="minValidateMessage"
                  ></b-form-input>
                </b-form-group>

                <!-- validation msessage for man lenth -->
                <b-form-group
                  label="Maximum Validation Message"
                  v-if="fieldData.validation.max"
                >
                  <b-form-input
                    @blur="addLanguageToField(data)"
                    v-model="maxValidateMessage"
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation------------------- -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col cols="6">
              <b-form-group>
                <b-form-checkbox
                  class="mt-2"
                  v-model="isRequireCheck"
                  value="1"
                  unchecked-value="0"
                  >Is required?</b-form-checkbox
                >
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Min Characters">
                <b-form-input
                  type="number"
                  v-model="minLength"
                  placeholder="Number"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Max Characters">
                <b-form-input
                  type="number"
                  v-model="maxLength"
                  placeholder="Number"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </div>
        </b-tab>

        <!-- ----------------Data------------------- -->
        <b-tab title="Data">
          <table class="table table-sm">
            <thead>
              <tr>
                <th></th>
                <th>Key</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(attribute, index) in fieldData.dataAttributes"
                :key="index"
              >
                <td class="text-right align-middle">data-</td>
                <td>
                  <b-form-input
                    v-model="attribute.dataAttributeKey"
                  ></b-form-input>
                </td>
                <td>
                  <b-form-input
                    v-model="attribute.dataAttributeValue"
                  ></b-form-input>
                </td>
                <td>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="onDeleteDataAttribute(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-primary btn-sm" @click="addDataAttribute">
            <i class="fa fa-plus"></i>
          </button>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <div class="form-group col-md-12">
              <label for>ID</label>
              <b-form-input v-model="selectId"></b-form-input>
            </div>
            <div class="form-group col-md-12">
              <label for>Class</label>
              <b-form-input v-model="selectClass"></b-form-input>
            </div>
            <div class="form-group col-md-12">
              <label for>Title</label>
              <b-form-input v-model="selectTitle"></b-form-input>
            </div>
            <div class="form-group col-md-12">
              <label for>Post Name</label>
              <b-form-input v-model="selectedPostName"></b-form-input>
            </div>

            <div class="form-group col-md-12">
              <label for>style</label>
              <b-form-textarea
                id="textarea"
                v-model="selectStyle"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
            <div class="form-check">
              <b-form-checkbox
                id="checkbox-1"
                v-model="selecReadOnly"
                name="checkbox-1"
                >Readonly</b-form-checkbox
              >
            </div>
            <div class="form-check">
              <b-form-checkbox
                id="checkbox-4"
                value="1"
                unchecked-value="0"
                v-model="fieldData.enableSelfReportingForThirdPartyProfile"
                name="checkbox-4"
                >Show Self Reporting Data in Third Party
                Profile</b-form-checkbox
              >
              <b-form-checkbox
                id="checkbox-5"
                value="1"
                unchecked-value="0"
                v-model="fieldData.enableSelfReportingForManageThirdParty"
                name="checkbox-5"
                >Show Self Reporting Data in Mangage Third
                Party</b-form-checkbox
              >
            </div>
            <div class="form-check">
              <b-form-checkbox
                id="checkbox-6"
                value="1"
                unchecked-value="0"
                v-model="fieldData.enableFilterForManageThirdParty"
                name="checkbox-5"
                >Filter in Mangage Third Party</b-form-checkbox
              >
            </div>
          </b-form>
        </b-tab>

        <!-- ----------------Validation------------------- -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col cols="6">
              <b-form-group>
                <b-form-checkbox
                  class="mt-2"
                  v-model="isRequireCheck"
                  value="1"
                  unchecked-value="0"
                  >Is required?</b-form-checkbox
                >
              </b-form-group>

              <b-form-group label="Select Advance Validation">
                <b-form-radio-group
                  stacked
                  v-model="selectedAdvanceValidation"
                  :options="optionsAdvanceValidations"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Min Characters">
                <b-form-input
                  type="number"
                  v-model="minLength"
                  placeholder="Number"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Max Characters">
                <b-form-input
                  type="number"
                  v-model="maxLength"
                  placeholder="Number"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </div>
        </b-tab>

        <!-- ----------------Advanced------------------- -->
        <b-tab title="Advanced" v-if="fieldData.inputType === 'date'">
          <div @submit.stop.prevent class="text-left mt-4">
            <div class="col-12">
              <b-form-checkbox
                v-model="fieldData.allowCurrentDate"
                class="col-md-12 my-1"
                >Allow Current Date only</b-form-checkbox
              >
              <b-collapse :visible="!fieldData.allowCurrentDate">
                <b-form-checkbox
                  v-model="disabledMinDateInput"
                  class="col-md-12 my-1"
                  @change="onToggleDatesCheckbox('min')"
                  >Disable Past Dates</b-form-checkbox
                >
                <b-collapse :visible="!disabledMinDateInput">
                  <div class="form-group col-md-6">
                    <label for>Minimum date</label>
                    <b-form-input type="date" v-model="minDate"></b-form-input>
                  </div>
                </b-collapse>

                <b-form-checkbox
                  class="col-md-12 my-1"
                  @change="onToggleDatesCheckbox('max')"
                  v-model="disabledMaxDateInput"
                  >Disable Future Dates</b-form-checkbox
                >
                <b-collapse :visible="!disabledMaxDateInput">
                  <div class="form-group col-md-6">
                    <label for>Maximum date</label>
                    <b-form-input type="date" v-model="maxDate"></b-form-input>
                  </div>
                </b-collapse>
              </b-collapse>

              <b-form-group label="Select Date Format">
                <b-form-radio-group
                  @input="onSetDateFormat(selectedDateFormat)"
                  stacked
                  v-model="selectedDateFormat"
                  :options="optionsDateFormatt"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>

          <div
            class="active text-left mt-2"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="form-group col-md-6">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id />
                <label class="form-check-label" for>Hide on load</label>
              </div>
            </div>
          </div>
        </b-tab>

        <!-- --------------Conditional------------------ -->
        <b-tab title="Conditional">
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab title="Simple">
              <b-row> </b-row>
              <b-col cols="12" class="mr-0">
                <b-form-checkbox
                  v-model="isConditional"
                  name="conditional"
                  value="1"
                  unchecked-value="0"
                  >Is Conditional?</b-form-checkbox
                >

                <div v-if="isConditional == 1">
                  <b-row>
                    <table class="table table-sm">
                      <thead>
                        <tr class="text-center">
                          <th>No</th>
                          <th>When Field</th>
                          <th>Has Value</th>
                          <th>Actions</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(selectedOpt, index) in fieldData.conditions"
                          :key="selectedOpt.id"
                        >
                          <td>{{ index }}</td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.label" />
                          </td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.value" />
                          </td>
                          <td>
                            <b-button
                              variant="outline-danger"
                              class="btn-sm ml-1"
                              @click="
                                onRemoveConditionOpt(selectedOpt.id, index)
                              "
                            >
                              <i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>

                        <tr
                          v-for="(noOfOption, index) in noOfConditions"
                          :key="index"
                        >
                          <td>{{ fieldData.conditions.length }}</td>
                          <td>
                            <v-select
                              v-model="conditionalField"
                              class="mb-2"
                              :options="conditionalSectionElementLables"
                              label="label"
                              @input="getSelectedElement"
                            ></v-select>
                          </td>
                          <td>
                            <v-select
                              class="mb-2"
                              label="text"
                              v-model="conditionalFieldValue"
                              :options="selectedElementOption"
                            ></v-select>
                          </td>

                          <td>
                            <b-button
                              class="btn-sm"
                              variant="outline-primary"
                              @click="setFieldConditions"
                              >Apply</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-row>
                </div>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- --------------CSS------------------ -->
      </b-tabs>
    </b-col>
    <!-- End of column -->
  </b-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Helper } from "@/helpers";
import uid from "uid";
import { EventBus } from "@/event-bus.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/base16-dark.css";

export default {
  name: "AdvanceSettings",
  components: {},
  data() {
    return {
      fieldCSS: null,
      fieldJS: null,
      cmOptions: {
        // codemirror options
        tabSize: 4,
        mode: "Text/Css",
        theme: "Paraiso-Light",
        lineNumbers: true,
        line: true,
        height: 500
      },

      //  for consdiontional
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      selectedElementOption: [],
      conditionalField: null,
      conditionalFieldValue: null,
      abbreviationLabel: undefined,
      isConditional:
        this.fieldData.isConditional && this.fieldData.conditions.length,
      condition: {},
      transalationLanguage: "english",
      noOfConditions: [1],

      langugeInput: undefined,
      langugePlaceholder: undefined,
      langugeValidateMessage: undefined,
      langugeHelperText: undefined,
      langugeToolTip: undefined,
      minValidateMessage: undefined,
      maxValidateMessage: undefined,
      isLoading: false,
      dataAttributeValue: undefined,
      dataAttributeKey: undefined,

      minDate:
        this.fieldData.advance && this.fieldData.advance.minDate
          ? this.fieldData.advance.minDate
          : undefined,
      maxDate:
        this.fieldData.advance && this.fieldData.advance.maxDate
          ? this.fieldData.advance.maxDate
          : undefined,
      disabledMinDateInput:
        this.fieldData.advance && this.fieldData.advance.disabledMinDateInput
          ? this.fieldData.advance.disabledMinDateInput
          : false,
      disabledMaxDateInput:
        this.fieldData.advance && this.fieldData.advance.disabledMaxDateInput
          ? this.fieldData.advance.disabledMaxDateInput
          : false,
      // for validation
      isRequireCheck:
        this.fieldData.validation && this.fieldData.validation.required,
      minLength:
        (this.fieldData.validation && this.fieldData.validation.min) ||
        undefined,
      maxLength:
        (this.fieldData.validation && this.fieldData.validation.max) ||
        undefined,

      isAlphaOnly:
        (this.fieldData.validation && this.fieldData.validation.isAlphaOnly) ||
        undefined,
      isAlphaNumOnly:
        (this.fieldData.validation &&
          this.fieldData.validation.isAlphaNumOnly) ||
        undefined,
      isAlphaNumCharsOnly:
        (this.fieldData.validation &&
          this.fieldData.validation.isAlphaNumCharsOnly) ||
        undefined,

      selectId:
        this.fieldData.attributes && this.fieldData.attributes.newId
          ? this.fieldData.attributes.newId
          : undefined,
      selectClass:
        this.fieldData.attributes && this.fieldData.attributes.Class
          ? this.fieldData.attributes.Class
          : undefined,
      selectTitle:
        this.fieldData.attributes && this.fieldData.attributes.Title
          ? this.fieldData.attributes.Title
          : undefined,
      selectedPostName:
        this.fieldData.attributes && this.fieldData.attributes.postName
          ? this.fieldData.attributes.postName
          : undefined,

      selectStyle:
        this.fieldData.attributes && this.fieldData.attributes.Style
          ? this.fieldData.attributes.Style
          : undefined,
      selecReadOnly:
        this.fieldData.attributes && this.fieldData.attributes.readonly
          ? this.fieldData.attributes.readonly
          : false,

      // date format settings
      selectedDateFormat: this.fieldData.format || "default",
      optionsDateFormatt: [
        { text: "Default", value: "default" },
        { text: "Monday 12 Aug 2019", value: "dddd MMMM Do YYYY" },
        { text: "September 19, 2019", value: "LL" },
        { text: "dd/mm/yyyy", value: "DD/MM/YYYY" },
        { text: "mm/dd/yyyy", value: "MM/DD/YYYY" }
      ],

      // for advance/custom validation
      selectedAdvanceValidation:
        this.fieldData.validation &&
        this.fieldData.validation.selectedAdvanceValidation
          ? this.fieldData.validation.selectedAdvanceValidation
          : "all",
      optionsAdvanceValidations: [
        { text: "Allow All", value: "all" },
        { text: "Allow alpha characters only", value: "alpha" },
        { text: "Allow alpha-numeric characters only", value: "alphaNum" },
        {
          text: "Allow alpha-numeric-hyphen-period only",
          value: "alphaNumChar"
        }
      ]
    };
  },

  filters: {
    trim(value) {
      return value.trim();
    }
  },

  created() {
    EventBus.$on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.setAttributes();
      this.setValidation();
      this.addAdvacne();
    });
  },

  mounted() {
    (this.langugeInput = this.fieldData.label[this.transalationLanguage]),
      (this.langugePlaceholder =
        this.fieldData.placeholder[this.transalationLanguage]),
      (this.langugeValidateMessage =
        this.fieldData.validationMessage[this.transalationLanguage]),
      (this.langugeHelperText =
        this.fieldData.helperText[this.transalationLanguage]);
    this.minValidateMessage =
      (this.fieldData.minValidateMessage &&
        this.fieldData.minValidateMessage[this.transalationLanguage]) ||
      undefined;
    this.maxValidateMessage =
      (this.fieldData.maxValidateMessage &&
        this.fieldData.maxValidateMessage[this.transalationLanguage]) ||
      undefined;

    this.langugeHelperText =
      this.fieldData.helperText[this.transalationLanguage];
    this.langugeToolTip = this.fieldData.tooltipText[this.transalationLanguage];
    this.addExtras();
    this.getCurrentSectionList();
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },

  methods: {
    ...mapActions(["updateField"]),
    /**
     * get section fields of type radio, checkbox and select
     */
    addExtras() {
      if (!this.fieldData.abbreviationLabelText) {
        let abbreviationLabelText = { english: " " };
        Object.assign(this.fieldData, { abbreviationLabelText });
      }
      if (!this.fieldData.labelForTable) {
        let labelForTable = { english: "" };
        Object.assign(this.fieldData, { labelForTable });
      }
    },

    onToggleDatesCheckbox(key) {
      if (key == "min") {
        this.minDate = undefined;
      }
      if (key == "max") {
        this.maxDate = undefined;
      }
    },

    getCurrentSectionList() {
      let conditionalSectionElements =
        this.form.formSection[this.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (
            element.inputType === "radio" ||
            element.inputType === "checkbox" ||
            element.inputType === "select"
          ) {
            // return element
            let dd = {
              label: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
    },

    tabClick(key) {
      this.transalationLanguage = key.toLowerCase();
      this.langugeInput = this.fieldData.label[this.transalationLanguage];
      this.langugePlaceholder =
        this.fieldData.placeholder[this.transalationLanguage];
      this.langugeValidateMessage =
        this.fieldData.validationMessage[this.transalationLanguage];
      this.langugeHelperText =
        this.fieldData.helperText[this.transalationLanguage];

      if (this.fieldData.validation.max) {
        this.maxValidateMessage =
          this.fieldData.maxValidateMessage[this.transalationLanguage];
      }
      if (this.fieldData.validation.min) {
        this.minValidateMessage =
          this.fieldData.minValidateMessage[this.transalationLanguage];
      }
      // this.langugeToolTip = this.fieldData.tooltipText[this.transalationLanguage];
    },

    setAttributes() {
      let attributes = {
        newId: this.selectId ? this.selectId : undefined,
        Class: this.selectClass ? this.selectClass : undefined,
        Title: this.selectTitle ? this.selectTitle : undefined,
        Style: this.selectStyle ? this.selectStyle : undefined,
        readonly: this.selecReadOnly ? this.selecReadOnly : false,
        postName: this.selectedPostName ? this.selectedPostName : undefined
      };
      Object.assign(this.fieldData, { attributes });
    },
    /**
     * Required for validation on
     */
    setValidation() {
      this.isLoading = true;
      let validation = {
        required: this.isRequireCheck,
        min: this.minLength || undefined,
        max: this.maxLength || undefined,
        selectedAdvanceValidation: this.selectedAdvanceValidation
      };

      if (this.minLength) {
        let minValidateMessage = { english: "" };
        Object.assign(this.fieldData, { minValidateMessage });
      }
      if (this.maxLength) {
        let maxValidateMessage = { english: "" };
        Object.assign(this.fieldData, { maxValidateMessage });
      }

      if (this.isAlphaOnly) {
        let alphaOnlyValidateMessage = { english: "" };
        Object.assign(this.fieldData, { alphaOnlyValidateMessage });
      }

      if (this.isAlphaNumOnly) {
        let alphaNumOnly = { english: "" };
        Object.assign(this.fieldData, { alphaNumOnly });
      }

      if (this.isAlphaNumCharsOnly) {
        let alphaNumCharsOnly = { english: "" };
        Object.assign(this.fieldData, { alphaNumCharsOnly });
      }

      Object.assign(this.fieldData, { validation });
      this.isLoading = false;
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.langugeInput;
      this.fieldData.placeholder[tempKey] = this.langugePlaceholder;
      this.fieldData.helperText[tempKey] = this.langugeHelperText;
      this.fieldData.validationMessage[tempKey] = this.langugeValidateMessage;
      // this.fieldData.tooltipText[tempKey] = this.langugeToolTip;

      if (this.fieldData.maxValidateMessage) {
        this.fieldData.minValidateMessage[tempKey] = this.minValidateMessage;
      }
      if (this.fieldData.maxValidateMessage) {
        this.fieldData.minValidateMessage[tempKey] = this.minValidateMessage;
      }

      // Helper.makeToast(this.$bvToast, "success", "Language Update");
    },

    onCmReady() {},

    onCmFocus() {},

    onCmCodeChange(newCode) {
      this.fieldCSS = newCode;
    },

    // for consitional
    addConditionsNumber() {
      this.noOfConditions.push(1);
    },

    setFieldConditions() {
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceFieldId: this.conditionalField.element.id,
          targetFieldId: this.fieldData.id,
          value: this.conditionalFieldValue,
          label: this.conditionalField.label
        };
        let dd = {
          id: this.conditionalField.element.id,
          isActive: false,
          value: this.conditionalFieldValue
        };
        let elements = [
          ...this.form.formSection[this.sectionIndex].formElements
        ];
        elements.map((element) => {
          if (element.options) {
            element.options.map((opt) => {
              if (
                opt.value === this.conditionalFieldValue &&
                this.conditionalField.element.id === element.id
              ) {
                this.fieldData.multiConditions.push(dd);
                element.conditionalFields.push(condition);
              }
            });
          }
        });
        this.fieldData.isConditional = this.isConditional;
        this.fieldData.isHidden = true;
        // Object.assign(this.fieldData, { condition });
        this.fieldData.conditions.push(condition);
        this.conditionalFieldValue = null;
        this.conditionalField = null;
        Helper.makeToast(this.$bvToast, "success", "Conditional  Updated");
      } else {
        Helper.makeToast(this.$bvToast, "danger", "No Condition selected");
      }
    },

    onRemoveConditionOpt(optId, index) {
      if (optId && index !== -1) {
        this.fieldData.conditions.splice(index, 1);
      }
    },

    addDataAttribute() {
      let newDataAttribute = {
        value: this.dataAttributeValue ? this.dataAttributeValue : undefined,
        key: this.dataAttributeKey ? `data-${this.dataAttributeKey}` : undefined
      };
      this.fieldData.dataAttributes.push(newDataAttribute);
    },

    onDeleteDataAttribute(index) {
      this.fieldData.dataAttributes.splice(
        this.fieldData.dataAttributes.indexOf(index),
        1
      );
    },

    // advance date tab
    addAdvacne() {
      let yesterday = new Date(Date.now() - 864e5); // testing required
      let tomorrow = new Date();

      let advance = {
        minDate:
          this.minDate && !this.disabledMinDateInput
            ? this.minDate
            : this.disabledMinDateInput
            ? yesterday
            : undefined,
        maxDate:
          this.maxDate && !this.disabledMaxDateInput
            ? this.maxDate
            : this.disabledMaxDateInput
            ? tomorrow
            : undefined,
        disabledMinDateInput: this.disabledMinDateInput,
        disabledMaxDateInput: this.disabledMaxDateInput
      };
      Object.assign(this.fieldData, { advance });
    },

    onSetDateFormat() {
      this.fieldData.format = this.selectedDateFormat;
    }
  },
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
