<template>
  <section class="add-edit-user-email-template">
    <form
      class="add-edit-user-email-template__form"
      data-test-id="add-edit-user-email-template__form"
      @submit.prevent
    >
      <InputErrorMessage :error="errorMessagesApi" />
      <div class="add-edit-user-email-template__details">
        <h4
          class="add-edit-user-email-template__title"
          data-test-id="add-edit-user-email-template__title"
        >
          User email template details
        </h4>
        <Input
          id="userEmailTemplateName"
          label="Name"
          name="userEmailTemplateName"
          placeholder="eg. name"
          class="add-edit-user-email-template__name"
          data-test-id="add-edit-user-email-template__name"
          :value="userEmailTemplateName"
          :error="errorMessages.userEmailTemplateName"
          :focus="true"
          @change="onChangeInput('userEmailTemplateName', $event)"
        />
        <Textarea
          id="userEmailTemplateDescription"
          label="Description"
          name="userEmailTemplateDescription"
          placeholder="Description"
          data-test-id="add-edit-user-email-template__description"
          :value="userEmailTemplateDescription"
          :error="errorMessages.userEmailTemplateDescription"
          @change="
            (_, $event) => onChangeInput('userEmailTemplateDescription', $event)
          "
        />
      </div>

      <div class="add-edit-user-email-template__expected-data">
        <h4
          class="add-edit-user-email-template__title"
          data-test-id="add-edit-user-email-template__title"
        >
          Expected data
        </h4>
        <div
          v-for="({ variable, id }, index) in expectedData"
          :key="id"
          class="add-edit-user-email-template__expected-data-row"
        >
          <div class="add-edit-user-email-template__expected-data-row-wrapper">
            <BaseText :text="getStepCount(index)" />
            <Input
              :id="`expectedData${variable}`"
              :name="`expectedData${variable}`"
              placeholder="eg. name"
              class="add-edit-user-email-template__expected-data-name"
              data-test-id="add-edit-user-email-template__expected-data-name"
              :value="getExpectedDataValue(index)"
              @change="
                onChangeInput(
                  {
                    key: 'expectedData',
                    index,
                    subKey: 'variable'
                  },
                  $event
                )
              "
            />
          </div>

          <CallToAction
            class="add-edit-user-email-template__expected-data-delete"
            icon="trash-alt"
            :size="20"
            :theme="themes.GREY"
            data-test-id="add-edit-user-email-template__expected-data-delete"
            @click="onDeleteExpectedData(index)"
          />
        </div>
        <CallToAction
          type="button"
          value="Add"
          :theme="themes.SECONDARY"
          data-test-id="add-edit-user-email-template__expected-data--add"
          @click="onAddDataDefinition"
        />
      </div>
      <div class="add-edit-user-email-template__email-destination">
        <h4
          class="add-edit-user-email-template__title"
          data-test-id="add-edit-user-email-template__title"
        >
          Email destinations
        </h4>
        <MultiSelect
          id="emailDestinationTo"
          :options="emailDestinationToOptions"
          :error="errorMessages.emailDestinationToOptions"
          data-test-id="add-edit-user-email-template__email-destination--to"
          description="Email to destination"
          name="emailDestinationTo"
          label="To"
          :showAllSelectedOptions="true"
          :isRequired="false"
          @change="
            ($event) =>
              onUpdateEmailDestinationOptions(
                $event,
                'emailDestinationToOptions'
              )
          "
        />
        <MultiSelect
          id="emailDestinationCc"
          :options="emailDestinationCcOptions"
          :error="errorMessages.emailDestinationCcOptions"
          data-test-id="add-edit-user-email-template__email-destination--cc"
          description="Email cc destination"
          name="emailDestinationCc"
          label="Cc"
          :showAllSelectedOptions="true"
          :isRequired="false"
          @change="
            ($event) =>
              onUpdateEmailDestinationOptions(
                $event,
                'emailDestinationCcOptions'
              )
          "
        />
        <MultiSelect
          id="emailDestinationBcc"
          :options="emailDestinationBccOptions"
          :error="errorMessages.emailDestinationBccOptions"
          data-test-id="add-edit-user-email-template__email-destination--bcc"
          description="Email bcc destination"
          name="emailDestinationBcc"
          label="Bcc"
          :showAllSelectedOptions="true"
          :isRequired="false"
          @change="
            ($event) =>
              onUpdateEmailDestinationOptions(
                $event,
                'emailDestinationBccOptions'
              )
          "
        />
      </div>
      <div class="add-edit-user-email-template__email-details">
        <h4
          class="add-edit-user-email-template__title"
          data-test-id="add-edit-user-email-template__title"
        >
          User email template
        </h4>
        <Input
          id="userEmailTemplateTitle"
          label="Email title"
          name="userEmailTemplateTitle"
          placeholder="eg. title"
          class="add-edit-user-email-template__title"
          data-test-id="add-edit-user-email-template__title"
          :value="userEmailTemplateTitle"
          :error="errorMessages.userEmailTemplateTitle"
          @change="onChangeInput('userEmailTemplateTitle', $event)"
        />
        <Textarea
          id="userEmailTemplateEmailBody"
          label="Email body"
          name="userEmailTemplateEmailBody"
          placeholder="Email body"
          data-test-id="add-edit-user-email-template__email-body"
          :value="userEmailTemplateEmailBody"
          :error="errorMessages.userEmailTemplateEmailBody"
          :rows="10"
          @change="
            (_, $event) => onChangeInput('userEmailTemplateEmailBody', $event)
          "
        />
      </div>
      <div class="add-edit-user-email-template__form-cta-holder">
        <BaseFooter>
          <template #right>
            <CallToAction
              class="add-edit-user-email-template__form-cta"
              type="button"
              theme="secondary"
              value="Close"
              data-test-id="add-edit-user-email-template__form-cta-holder--close"
              @click="onCancel"
            />
            <CallToAction
              class="add-edit-user-email-template__form-cta"
              type="button"
              value="Submit"
              :is-loading="isSavingAction"
              :is-error="hasActionError"
              :is-success="isSavingSuccessful"
              data-test-id="add-edit-user-email-template__form-cta-holder--submit"
              @click="onSubmit"
            />
          </template>
        </BaseFooter>
      </div>
    </form>
  </section>
</template>

<script>
import Input from "@/molecules/Input/Input";
import Textarea from "@/molecules/Textarea/Textarea";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseText from "@/atoms/BaseText/BaseText";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import {
  themes,
  userEmailTemplatesDestinationTypes,
  operations,
  urls,
  actionName,
  routeNames,
  timers,
  userEmailTemplateKeysDTO
} from "@/constants";
import { makeUserEmailTemplateExpectedDataDTO } from "@/services/userEmailTemplates/userEmailTemplates.dto";
import {
  makeOptionsForMultiSelect,
  makeLiteralOptionForMultiSelect
} from "@/molecules/MultiSelect/MultiSelect.dto";
import { mapActions } from "vuex";
import { hasCorrectEmailFormat } from "@/utils";
import {
  validateUserEmailTemplateText,
  validateUserEmailTemplateBody,
  makeDestinationList,
  makeErrorMessagesApi,
  getListSelectedValues,
  getStepCount,
  getSelectedValuesFromDestinationKey
} from "./AddEditUserEmailTemplate.logic";

export default {
  name: "AddEditUserEmailTemplate",
  props: {
    userEmailTemplate: {
      type: Object,
      default: () => ({})
    },
    baseOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Input,
    Textarea,
    CallToAction,
    BaseText,
    MultiSelect,
    BaseFooter,
    InputErrorMessage
  },
  data() {
    return {
      themes,
      userEmailTemplateName: "",
      userEmailTemplateDescription: "",
      userEmailTemplateTitle: "",
      userEmailTemplateEmailBody: "",
      errorMessages: {
        userEmailTemplateName: "",
        userEmailTemplateDescription: "",
        userEmailTemplateTitle: "",
        userEmailTemplateEmailBody: "",
        emailDestinationBccOptions: "",
        emailDestinationCcOptions: "",
        emailDestinationToOptions: ""
      },
      expectedData: [],
      emailDestinationBccOptions: [],
      emailDestinationCcOptions: [],
      emailDestinationToOptions: [],
      isSavingAction: false,
      hasActionError: false,
      isSavingSuccessful: false,
      errorMessagesApi: ""
    };
  },
  watch: {
    hasActionError(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasActionError = false;
        }, timers.MODERATE);
      }
    }
  },
  methods: {
    validateUserEmailTemplateText,
    validateUserEmailTemplateBody,
    makeDestinationList,
    makeErrorMessagesApi,
    getListSelectedValues,
    getStepCount,
    getSelectedValuesFromDestinationKey,
    ...mapActions({
      makeUserEmailTemplate:
        actionName.USER_EMAIL_TEMPLATES.MAKE_USER_EMAIL_TEMPLATE,
      updateUserEmailTemplate:
        actionName.USER_EMAIL_TEMPLATES.UPDATE_USER_EMAIL_TEMPLATE
    }),
    getExpectedDataValue(index) {
      return this.expectedData?.[index]?.variable || "";
    },
    setUserEmailTemplateData() {
      this.userEmailTemplateName = this.userEmailTemplate?.name || "";
      this.userEmailTemplateDescription =
        this.userEmailTemplate?.description || "";
      this.userEmailTemplateTitle = this.userEmailTemplate?.subject || "";
      this.userEmailTemplateEmailBody = this.userEmailTemplate?.body || "";
      this.expectedData = this.userEmailTemplate?.expectedData || [];

      this.makeDataForMultiSelects();
    },
    makeDataForMultiSelects() {
      const toDestinations = this.getSelectedValuesFromDestinationKey(
        this.userEmailTemplate[userEmailTemplateKeysDTO.TO]
      );
      const ccDestinations = this.getSelectedValuesFromDestinationKey(
        this.userEmailTemplate[userEmailTemplateKeysDTO.CC]
      );
      const bccDestinations = this.getSelectedValuesFromDestinationKey(
        this.userEmailTemplate[userEmailTemplateKeysDTO.BCC]
      );

      [
        {
          listName: "emailDestinationToOptions",
          selectedValues: toDestinations.selectedValues,
          amendableValues: toDestinations.selectedValues,
          literalEmailOptions: toDestinations.literalEmailOptions
        },
        {
          listName: "emailDestinationCcOptions",
          selectedValues: ccDestinations.selectedValues,
          amendableValues: ccDestinations.selectedValues,
          literalEmailOptions: ccDestinations.literalEmailOptions
        },
        {
          listName: "emailDestinationBccOptions",
          selectedValues: bccDestinations.selectedValues,
          amendableValues: bccDestinations.selectedValues,
          literalEmailOptions: bccDestinations.literalEmailOptions
        }
      ].forEach((multiSelectData) =>
        this.updateMultiSelectList(multiSelectData)
      );
    },
    onCancel() {
      this.$router.push(urls.USER_EMAIL_TEMPLATES);
    },
    makeUserEmailTemplatePayload() {
      return {
        ...(this.userEmailTemplate?.id && { id: this.userEmailTemplate?.id }),
        name: this.userEmailTemplateName,
        description: this.userEmailTemplateDescription,
        subject: this.userEmailTemplateTitle,
        body: this.userEmailTemplateEmailBody,
        expectedData: [
          ...this.expectedData.filter(({ variable }) => !!variable)
        ],
        to: this.makeDestinationList({
          options: this.emailDestinationToOptions
        }),
        cc: this.makeDestinationList({
          options: this.emailDestinationCcOptions
        }),
        bcc: this.makeDestinationList({
          options: this.emailDestinationBccOptions
        })
      };
    },
    resetValueBeforeSubmit() {
      this.isSavingAction = true;
      this.hasActionError = false;
      this.errorMessagesApi = "";
    },
    async onSubmit() {
      this.resetUserEmailTemplateDestinationsErrors();
      const isInvalid = this.isValidUserEmailTemplate();
      this.resetValueBeforeSubmit();
      try {
        if (!isInvalid) {
          await this.submitUserEmailTemplate();
        }
      } catch (error) {
        this.hasActionError = true;
        this.errorMessagesApi = this.makeErrorMessagesApi(error);
      } finally {
        this.isSavingAction = false;
      }
    },
    async submitUserEmailTemplate() {
      const payload = this.makeUserEmailTemplatePayload();
      if (this.$route.name === routeNames.ADD_USER_EMAIL_TEMPLATE) {
        await this.makeUserEmailTemplate(payload);
        this.submitSuccess("created");
      } else if (this.$route.name === routeNames.EDIT_USER_EMAIL_TEMPLATE_ID) {
        await this.updateUserEmailTemplate(payload);
        this.submitSuccess("updated");
      }
    },
    submitSuccess(operation) {
      this.isSavingSuccessful = true;

      setTimeout(() => {
        this.$router.push({
          name: routeNames.USER_EMAIL_TEMPLATES,
          params: { message: `User email template ${operation} successfully` }
        });
      }, timers.MODERATE);
    },
    onUpdateEmailDestinationOptionsAdd({ value, key }) {
      const selectedValues = [
        ...this.getListSelectedValues(this?.[key]),
        value
      ];
      this[key] = makeOptionsForMultiSelect(
        selectedValues,
        selectedValues,
        this[key]
      );
    },
    onUpdateEmailDestinationOptionsDelete({ value, key }) {
      this[key] = this?.[key]?.filter((option) => option.value !== value);
    },
    onUpdateEmailDestinationOptionsCreate({ value, key }) {
      const isValueInList = !!this[key].find(
        (option) => option.value === value
      );
      if (hasCorrectEmailFormat(value)) {
        if (isValueInList) {
          this.errorMessages[
            key
          ] = `${value} is a duplicate of a previous entry`;
        } else {
          this[key]?.push(
            makeLiteralOptionForMultiSelect({
              text: value,
              value,
              type: userEmailTemplatesDestinationTypes.EMAIL
            })
          );
        }
      } else {
        this.errorMessages[key] =
          "The inputted value is not a valid email address";
      }
    },
    onUpdateEmailDestinationOptions({ eventType, value }, key) {
      this.errorMessages[key] = "";

      if (eventType === operations.ADD) {
        this.onUpdateEmailDestinationOptionsAdd({ value, key });
      } else if (eventType === operations.DELETE) {
        this.onUpdateEmailDestinationOptionsDelete({ value, key });
      } else if (eventType === operations.CREATE) {
        this.onUpdateEmailDestinationOptionsCreate({ value, key });
      }
    },
    onChangeInput(inputName, value) {
      if (typeof inputName === "string") {
        this[inputName] = value.trim();
      } else {
        const { key, index, subKey } = inputName;
        this[key][index][subKey] =
          typeof value === "string" ? value.trim() : value;
      }
    },
    onDeleteExpectedData(index) {
      this.expectedData = this.expectedData.filter(
        (_, position) => index !== position
      );
    },
    onAddDataDefinition() {
      this.expectedData.push(
        makeUserEmailTemplateExpectedDataDTO({ type: "string" })
      );
    },
    updateMultiSelectList({
      listName,
      selectedValues,
      amendableValues,
      literalEmailOptions
    }) {
      this[listName] = makeOptionsForMultiSelect(
        selectedValues,
        amendableValues,
        [...this.baseOptions, ...literalEmailOptions]
      );
    },
    resetUserEmailTemplateDestinationsErrors() {
      this.errorMessages.emailDestinationBccOptions = "";
      this.errorMessages.emailDestinationCcOptions = "";
      this.errorMessages.emailDestinationToOptions = "";
    },
    isValidUserEmailTemplate() {
      this.errorMessages.userEmailTemplateName =
        this.validateUserEmailTemplateText(this.userEmailTemplateName);
      this.errorMessages.userEmailTemplateDescription =
        this.validateUserEmailTemplateText(this.userEmailTemplateDescription);
      this.errorMessages.userEmailTemplateTitle =
        this.validateUserEmailTemplateText(this.userEmailTemplateTitle);
      this.errorMessages.userEmailTemplateEmailBody =
        this.validateUserEmailTemplateBody(this.userEmailTemplateEmailBody);

      return (
        !!this.errorMessages.userEmailTemplateName &&
        !!this.errorMessages.userEmailTemplateDescription &&
        !!this.errorMessages.userEmailTemplateTitle &&
        !!this.errorMessages.userEmailTemplateEmailBody
      );
    }
  },
  created() {
    this.setUserEmailTemplateData();
  }
};
</script>

<style lang="scss" scoped>
.add-edit-user-email-template {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &__details {
    max-width: 500px;
  }

  &__expected-data {
    &-row {
      max-width: 500px;
      display: flex;
      justify-content: space-between;

      &-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &-name {
      max-width: 200px;
      margin-left: 10px;
    }
  }

  &__email-destination,
  &__email-details {
    padding-top: 20px;
  }

  &__form-cta {
    margin-left: 20px;

    &-holder {
      padding: 15px 0;
    }
  }
}
</style>
