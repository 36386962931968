<template>
   <div>
        <h4 class="text-center font-size-text">{{barChartTitle}}</h4>
        <b-card>
            <div>
                <highcharts
                    class="hc"
                    :options="barChartOptions"
                    ref="bar-chart"
                />
            </div>
        </b-card>
   </div>
</template>

<script>
export default {
  name: "EndStateDistributionComponent",
  props:{
      barChartOptions: Object,
      barChartTitle: String
  },
}
</script>