/**
 * Returns a new array of options with `selected: true` added to the
 * option matching the provided `selectedValue`.
 * @param {string | number} selectedValue - Selected value of an option
 * @param {Array} options - list of options
 * @returns {Array} - edited options array
 */
export const makeOptionsForSelect = (selectedValue, options) => {
  const hasEmptyOption = options?.find((option) => option?.value === "");
  let hasSelectedOption = false;

  let mappedOptions = options?.map((optionElement) => {
    const option = { ...optionElement };
    delete option?.selected;
    const isSelected = option.value === selectedValue;

    if (!hasSelectedOption && isSelected) {
      hasSelectedOption = true;
    }

    return {
      ...option,
      ...(isSelected && { selected: true })
    };
  });

  if (!hasSelectedOption && hasEmptyOption) {
    for (let index = 0; index < mappedOptions.length; index++) {
      if (mappedOptions[index].value === "") {
        mappedOptions[index].selected = true;
        break;
      }
    }
  }

  return mappedOptions || [];
};
