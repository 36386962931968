<template>
  <section class="add-edit-automation" data-test-id="add-edit-automation">
    <BaseLoader v-if="isLoadingTableData" />
    <form
      v-else
      @submit.prevent
      class="add-edit-automation__form"
      data-test-id="add-edit-automation__form"
    >
      <div
        class="add-edit-automation__form-holder"
        data-test-id="add-edit-automation__form-holder"
      >
        <div
          class="add-edit-automation__form-section--details"
          data-test-id="add-edit-automation__form-section--details"
        >
          <Input
            id="automationName"
            label="Name"
            name="automationName"
            placeholder="eg. name"
            class="add-edit-automation__form-input"
            data-test-id="add-edit-automation__name"
            :error="automationNameErrorMessage"
            :value="getAutomationProperties('name')"
            @change="onChangeInput('name', $event)"
          />
          <Textarea
            id="automationDescription"
            label="Description"
            name="automationDescription"
            placeholder="Description"
            data-test-id="add-edit-automation__description"
            :error="automationDescriptionErrorMessage"
            :value="getAutomationProperties('description')"
            @change="(_, $event) => onChangeInput('description', $event)"
          />
          <Select
            label="Status"
            class="add-edit-automation__form-section-status"
            id="add-edit-automation__form-section-status"
            data-test-id="add-edit-automation__status"
            placeholder="Status"
            name="statusAutomation"
            :error="automationStatusErrorMessage"
            :options="statusAutomationOptions"
            @change="onChangeStatusInput"
          />
          <MultiSelect
            v-if="hasManualCondition"
            :id="`${makeUUID()}`"
            :options="triggerRoleOptions"
            :error="triggerAutomationRoleErrorMessage"
            data-test-id="add-edit-automation__trigger-permissions"
            description="Select roles that can trigger this automation"
            name="triggerAutomationRoles"
            label="Trigger permissions"
            @change="updateTriggerRoleOptions"
          />
          <Select
            label="Condition"
            class="add-edit-automation__form-section-condition"
            id="add-edit-automation__form-section-condition"
            data-test-id="add-edit-automation__condition"
            placeholder="Condition"
            name="makeAvailableConditionOptions"
            :error="automationConditionErrorMessage"
            :options="conditionAutomationOptions"
            @change="onChangeConditionAutomation"
          />
          <template v-if="requiredMatchFields.length">
            <Select
              v-for="(field, index) in requiredMatchFields"
              :label="field.label"
              :key="`required-match-${index}`"
              :id="`add-edit-automation__form-section-required-match-${index}`"
              :data-test-id="`add-edit-automation__required-match--${index}`"
              :options="field.options"
              :name="`requiredMatchField${index}`"
              :error="getRequiredMatchErrorMessage(index)"
              @change="onSelectRequireMatchValue($event, index)"
            />
          </template>
          <StickyTable
            v-if="isExpectedDataTableVisible"
            caption="Expected data"
            class="add-edit-automation__form-section-table"
            data-test-id="add-edit-automation__expected-data"
            :table-headers="makeAutomationsHeaders"
            :table-rows="makeAutomationsRows"
          />
        </div>
        <div
          class="add-edit-automation__form-section--triggers"
          data-test-id="add-edit-automation__form-section--triggers"
        >
          <template v-if="isTriggerVisible">
            <AccordionSection
              v-for="(triggerStep, index) in triggerStepList"
              :key="triggerStep.id"
              title="Trigger"
              :data-test-id="`add-edit-automation__trigger-step--${index}`"
              :menuItems="triggerStepAccordionOptions"
              @click="onAccordionDropDownSelection(index, $event)"
            >
              <TriggerStep
                :id="triggerStep.id"
                :actionNameOptions="triggerStep.actionNameOptions"
                :actionNameErrorMessage="triggerStep.actionNameErrorMessage"
                :expectDataList="triggerStep.expectDataList"
                @change="onTriggerStepChange"
                @reset="onTriggerStepChange"
                @create="onTriggerStepChange"
              />
            </AccordionSection>
          </template>
          <InputErrorMessage
            v-if="automationBodyErrorMessage"
            class="add-edit-automation__actions-feedback"
            :error="automationBodyErrorMessage"
          />
          <CallToAction
            value="Add trigger"
            @click="onAddTrigger"
            data-test-id="add-edit-automation__add-trigger"
          />
        </div>
      </div>
      <BaseFooter>
        <template #right>
          <CallToAction
            class="add-edit-automation__form-cta"
            data-test-id="add-edit-automation__form-cta--cancel"
            type="button"
            theme="secondary"
            value="Cancel"
            @click="onCancel"
          />
          <CallToAction
            class="add-edit-automation__form-cta"
            data-test-id="add-edit-automation__form-cta--submit"
            type="button"
            value="Save"
            :is-loading="isSavingAutomation"
            :is-error="hasErrorInAutomation"
            :is-success="isSavingSuccessful"
            @click="onSubmit"
          />
        </template>
      </BaseFooter>
    </form>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  actionKeysApi,
  actionName,
  activityStateOptions,
  automationConditionKeys,
  automationConditionTableHeaders,
  automationEventType,
  automationsKeys,
  automationsTriggerDataTypes,
  emptyOption,
  mutationName,
  operations,
  roles,
  routeNames,
  stepAccordionOptionValue,
  triggerStepAccordionOptions,
  triggerStepKeys,
  typographySize,
  urls,
  userRolesKeysDTO,
  validity,
  timers
} from "@/constants";
import {
  getSelectedOption,
  hasStatus422,
  hasStatus500,
  isNotAuthorized,
  makeUUID
} from "@/utils";
import {
  actionsService,
  automationsService,
  thirdPartyService
} from "@/services";
import {
  makeAutomationDTO,
  makeCriteriaDTO
} from "@/services/automations/dto/automations.dto";
import BaseFooter from "@/atoms/BaseFooter/BaseFooter";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Input from "@/molecules/Input/Input";
import Textarea from "@/molecules/Textarea/Textarea";
import Select from "@/molecules/Select/Select";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import TriggerStep from "@/organisms/TriggerStep/TriggerStep";
import AccordionSection from "@/molecules/AccordionSection/AccordionSection";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { cloneDeep, isEmpty } from "lodash";
import AddEditAutomationValidation from "@/organisms/AddEditAutomation/mixins/AddEditAutomationValidation.mixin";
import TriggerStepMixin from "@/organisms/TriggerStep/TriggerStep.mixin";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect.vue";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";

export default {
  name: "AddEditAutomation",
  mixins: [TriggerStepMixin, AddEditAutomationValidation],
  components: {
    MultiSelect,
    BaseFooter,
    CallToAction,
    Input,
    Textarea,
    Select,
    StickyTable,
    BaseLoader,
    TriggerStep,
    AccordionSection,
    InputErrorMessage
  },
  props: {
    automation: {
      type: Object,
      default: () => ({})
    },
    automationConditions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSavingAutomation: false,
      hasErrorInAutomation: false,
      isSavingSuccessful: false,
      isLoadingTableData: false,
      automationBodyErrorMessage: "",
      statusAutomationOptions: [],
      conditionAutomationOptions: [],
      actionList: [],
      currentAutomation: {},
      triggerStepAccordionOptions,
      selectEmptyOption: {
        ...emptyOption,
        text: "Select..."
      },
      customAttributeFields: [],
      requiredMatchFields: [],
      manualTriggerIds: [
        automationConditionKeys.MANUAL_TRIGGER,
        automationConditionKeys.ACT_ON_ALL,
        automationConditionKeys.ACT_ON_SELECTED
      ],
      triggerRoleOptions: [],
      temporarySelectedRoles: [],
      triggerAutomationRoleErrorMessage: "",
      allRoleOptions: [],
      selectedRoles: [],
      amendableRoles: []
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      roles: (state) => state.roles.roles
    }),
    getAutomationConditionObject() {
      return this.automationConditions.find(
        ({ id }) => id === this.currentAutomation?.condition?.event?.id
      );
    },
    isExpectedDataTableVisible() {
      return (
        !!this.makeAutomationsHeaders.length &&
        !!this.makeAutomationsRows.length
      );
    },
    makeAutomationsHeaders() {
      return this.makeAutomationsHeaderList(this.getAutomationConditionObject);
    },
    makeAutomationsRows() {
      return this.makeAutomationsRowList(this.getAutomationConditionObject);
    },
    makeActionOptions() {
      return this.actionList.map(({ name, id }) => ({
        text: name,
        value: id
      }));
    },
    makeAvailableConditionOptions() {
      return this.automationConditions.reduce(
        (acc, { name, id }) => {
          acc.options.push({
            text: name,
            value: id
          });
          if (this.currentAutomation?.condition?.event?.id === id) {
            acc.selectedCondition = id;
          }
          return acc;
        },
        {
          options: [],
          selectedCondition: ""
        }
      );
    },
    hasManualCondition() {
      return this.manualTriggerIds.includes(
        this.currentAutomation.condition?.event?.id
      );
    }
  },
  async created() {
    await this.fetchActions();
    await this.fetchRolesAction();
    this.setCurrentAutomation();
  },
  watch: {
    hasErrorInAutomation(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasErrorInAutomation = false;
        }, timers.MODERATE);
      }
    },
    roles: {
      deep: true,
      handler() {
        this.setTriggerPermissionRoleOptions();
      }
    },
    selectedRoles: {
      deep: true,
      handler() {
        this.setTriggerRoleOptions();
      }
    }
  },
  methods: {
    makeUUID,
    ...mapMutations({
      setTriggerFetchAdditionalVouchers:
        mutationName.COMPANY.SET_TRIGGER_FETCH_ADDITIONAL_VOUCHERS
    }),
    ...mapActions({
      fetchRolesAction: actionName.ROLES.FETCH_ROLES
    }),
    updateTriggerRoleOptions({ eventType, value }) {
      let selectedRolesCopy = cloneDeep(this.selectedRoles);

      if (eventType === operations.ADD) {
        selectedRolesCopy.push(value);
      } else {
        selectedRolesCopy = selectedRolesCopy.filter(
          (roleValue) => roleValue !== value
        );
      }

      this.selectedRoles = selectedRolesCopy;
    },
    setAmendableRoles() {
      this.amendableRoles = this.roles.reduce((amendableRoles, { id }) => {
        if (id !== roles.ADMIN) {
          amendableRoles.push(id);
        }

        return amendableRoles;
      }, []);
    },
    setAllRoleOptions() {
      this.allRoleOptions = this.roles.map((role) => ({
        id: `${makeUUID()}`,
        text: role[userRolesKeysDTO.NAME],
        value: role[userRolesKeysDTO.ID]
      }));
    },
    setSelectedRoles() {
      this.selectedRoles = this.automation?.[automationsKeys.ROLES] || [
        roles.ADMIN
      ];
    },
    setTriggerPermissionRoleOptions() {
      this.setAmendableRoles();
      this.setAllRoleOptions();
      this.setSelectedRoles();
    },
    setTriggerRoleOptions() {
      this.triggerRoleOptions = makeOptionsForMultiSelect(
        this.selectedRoles,
        this.amendableRoles,
        this.allRoleOptions
      );
    },
    makeAutomationsHeaderList(
      automationConditionObject = { parameters: [{}] }
    ) {
      return Object.keys(automationConditionObject?.parameters[0])?.reduce(
        (acc, key) => {
          if (automationConditionTableHeaders[key]) {
            acc.push(this.makeAutomationsHeader(key));
          }
          return acc;
        },
        []
      );
    },
    makeAutomationsHeader(key = "") {
      return {
        value: key,
        component: BaseText,
        componentOptions: {
          tag: "span",
          text: automationConditionTableHeaders[key],
          size: typographySize.BODY_TEXT_BOLD,
          styles: {
            margin: "0"
          }
        },
        styles: {
          verticalAlign: "top",
          background: "white",
          padding: "5px"
        }
      };
    },
    makeAutomationsRowList(getAutomationConditionObject = { parameters: [] }) {
      const mappableParameters =
        getAutomationConditionObject?.parameters?.filter(
          (parameter) => parameter?.[automationsKeys.IS_MAPPABLE]
        ) || [];

      return mappableParameters.map((parameters) =>
        this.makeAutomationsRow(parameters)
      );
    },
    makeAutomationsRow(automationCondition = {}) {
      return Object.keys(automationCondition).reduce(
        (acc, automationsConditionKey) => {
          acc[automationsConditionKey] = {
            component: BaseText,
            componentOptions: {
              tag: "span",
              text: automationCondition[automationsConditionKey],
              styles: {
                padding: "0px 5px"
              }
            }
          };
          return acc;
        },
        {}
      );
    },
    onAccordionDropDownSelection(index, selection) {
      if (selection === stepAccordionOptionValue.DELETE_STEP) {
        this.removeTriggerStepByIndex(index);
        this.currentAutomation.actions.splice(index, 1);
      }
    },
    getActionBasedOnId(actionId) {
      return this.actionList.find(({ id }) => id === actionId);
    },
    setCurrentAutomation() {
      this.currentAutomation = isEmpty(this.automation)
        ? makeAutomationDTO()
        : cloneDeep(this.automation);
      this.statusAutomationOptions = makeOptionsForSelect(
        `${this.currentAutomation?.isActive}`,
        activityStateOptions
      );
      this.conditionAutomationOptions = makeOptionsForSelect(
        this.makeAvailableConditionOptions.selectedCondition,
        [this.selectEmptyOption, ...this.makeAvailableConditionOptions.options]
      );
      this.makeTriggerListData();

      const selectedCondition = this.automationConditions.find(
        ({ id }) => this.makeAvailableConditionOptions.selectedCondition === id
      );
      const requiredMatchParameters =
        this.getRequiredMatchParameters(selectedCondition);

      if (requiredMatchParameters?.length) {
        this.setRequiredMatchFields(
          requiredMatchParameters,
          this.currentAutomation.condition.criteria
        );
      }
    },
    makeTriggerListData() {
      this.triggerStepList = this.currentAutomation.actions.map((action) =>
        this.makeTriggerStep({
          actionNameOptions: makeOptionsForSelect(action.id, [
            this.selectEmptyOption,
            ...this.makeActionOptions
          ]),
          expectDataList: this.makeExpectedDataListBasedOnActionId(action)
        })
      );
    },
    makeOptionsForTriggerDropDown(entity = "", options = []) {
      if (options.length) {
        return (
          options?.map(({ text, value }) => ({
            text,
            value: JSON.stringify(value)
          })) || []
        );
      } else {
        return this.getAutomationConditionObject?.parameters
          .filter(({ type, isMappable }) => type === entity && isMappable)
          .map(({ name }) => ({
            text: name,
            value: name
          }));
      }
    },
    getSearchValueForExpectedDataTrigger(expectedDataMapping = [], name = "") {
      const { triggeringData = {} } =
        expectedDataMapping.find(
          ({ triggeredExpectedDataVariableName }) =>
            triggeredExpectedDataVariableName === name
        ) || {};
      if (triggeringData.type === automationsTriggerDataTypes.USER_PROVIDED) {
        return JSON.stringify(triggeringData.data.value);
      } else if (
        triggeringData.type === automationsTriggerDataTypes.EVENT_PARAMETER
      ) {
        return triggeringData.data.name;
      } else {
        return "";
      }
    },
    makeExpectedDataListBasedOnActionId({
      id = "",
      expectedDataMapping = []
    } = {}) {
      return this.getActionBasedOnId(
        id
      )?.expectedDataDefinition?.variables?.map(({ entity, name, options }) => {
        const searchValue = this.getSearchValueForExpectedDataTrigger(
          expectedDataMapping,
          name
        );
        return {
          name,
          searchValue,
          options: makeOptionsForSelect(
            searchValue,
            this.makeOptionsForTriggerDropDown(entity, options)
          ),
          type: entity,
          errorMessage: ""
        };
      });
    },
    setCurrentAutomationRoles() {
      this.currentAutomation[automationsKeys.ROLES] = this.selectedRoles;
    },
    makeAutomationConditionCriteria() {
      this.setCurrentAutomationRoles();

      if (this.requiredMatchFields.length) {
        this.currentAutomation[automationsKeys.CONDITION][
          automationsKeys.CRITERIA
        ] = this.requiredMatchFields.map((requiredMatch) => {
          const { value = "" } = getSelectedOption(requiredMatch.options);

          return makeCriteriaDTO(
            requiredMatch.parameter,
            value,
            this.companyId
          );
        });
      }
    },
    async storeAutomation() {
      let operation;
      this.makeAutomationConditionCriteria();

      if (this.currentAutomation.id) {
        await automationsService.amendAutomation(
          this.companyId,
          this.currentAutomation.id,
          this.currentAutomation,
          this.triggerStepList,
          this.hasManualCondition
        );
        operation = "updated";
      } else {
        await automationsService.saveAutomation(
          this.companyId,
          this.currentAutomation,
          this.triggerStepList,
          this.hasManualCondition
        );
        operation = "created";
      }

      this.setTriggerFetchAdditionalVouchers(true);
      return operation;
    },
    async onSubmit() {
      try {
        this.automationBodyErrorMessage = "";
        this.hasErrorInAutomation = false;
        this.isSavingAutomation = true;
        const isInvalid = await this.validateAutomation();

        if (!isInvalid) {
          const operation = await this.storeAutomation();
          this.isSavingAutomation = false;
          this.isSavingSuccessful = true;
          this.lazyRedirectToAutomation(operation);
        } else {
          this.isSavingAutomation = false;
          this.hasErrorInAutomation = true;
        }
      } catch (error) {
        this.submitFailure(error);
      }
    },
    submitFailure({ response }) {
      this.isSavingAutomation = false;
      this.isSavingSuccessful = false;
      this.hasErrorInAutomation = true;

      if (hasStatus500(response)) {
        this.automationBodyErrorMessage = "There is a server error.";
      } else if (hasStatus422(response)) {
        this.automationBodyErrorMessage = "There is a validation error.";
      } else if (!isNotAuthorized(response)) {
        this.automationBodyErrorMessage = `There is an unknown error with status of ${response?.status}. Please contact support.`;
      }
    },
    lazyRedirectToAutomation(operation) {
      this.$emit(automationEventType.FETCH_AUTOMATIONS);
      setTimeout(() => {
        this.$router.push({
          name: routeNames.AUTOMATIONS,
          params: { message: `Automation ${operation} successfully` }
        });
      }, timers.MODERATE);
    },
    onCancel() {
      this.$router.push(urls.AUTOMATIONS);
    },
    onAddTrigger() {
      const triggerStep = this.makeTriggerStep({
        actionNameOptions: makeOptionsForSelect("", [
          this.selectEmptyOption,
          ...this.makeActionOptions
        ])
      });
      this.triggerStepList.push(triggerStep);
    },
    makeAutomationActionExpectedData(
      actionVariableName = null,
      conditionParameterName = null
    ) {
      return {
        [automationsKeys.ACTION_VARIABLE_NAME]: actionVariableName,
        [automationsKeys.CONDITION_PARAMETER_NAME]: conditionParameterName
      };
    },
    onChangeInput(property, value) {
      if (Object.keys(this.currentAutomation).includes(property)) {
        this.currentAutomation[property] = value.trim();
      }
    },
    onChangeStatusInput(value) {
      this.currentAutomation.isActive = value === validity.TRUE;
    },
    setAutomationTriggerCondition(selectedCondition) {
      this.currentAutomation.condition = {
        event: selectedCondition
      };
      this.conditionAutomationOptions = makeOptionsForSelect(
        this.makeAvailableConditionOptions.selectedCondition,
        [this.selectEmptyOption, ...this.makeAvailableConditionOptions.options]
      );
      this.updateTriggerListData();
    },
    updateTriggerListData() {
      this.triggerStepList.forEach((triggerStep) => {
        const id =
          getSelectedOption(triggerStep.actionNameOptions)?.value || "";

        this.getActionBasedOnId(id)?.expectedDataDefinition?.variables.forEach(
          ({ entity, options }, index) => {
            triggerStep.expectDataList[index].options = makeOptionsForSelect(
              triggerStep.expectDataList[index].searchValue,
              this.makeOptionsForTriggerDropDown(entity, options)
            );
          }
        );
      });
    },
    getRequiredMatchParameters(selectedCondition) {
      return selectedCondition?.parameters?.filter(
        ({ requiresMatch }) => !!requiresMatch
      );
    },
    addFieldToRequireMatch(options, label, parameter) {
      this.requiredMatchFields.push({
        options,
        label,
        parameter
      });
    },
    setAutomationConditionErrorMessage(errorMessage) {
      this.automationConditionErrorMessage = errorMessage;
    },
    async setRequiredMatchField(requiredMatchField, selectedValue = "") {
      try {
        if (requiredMatchField.type === automationsKeys.CUSTOM_ATTRIBUTE) {
          if (!this.customAttributeFields.length) {
            const customAttributeData =
              await thirdPartyService.fetchIdentifierTypes(
                this.companyId,
                requiredMatchField.type
              );

            this.customAttributeFields = customAttributeData.map(
              (customAttribute) => ({
                text: customAttribute[automationsKeys.NAME],
                value: customAttribute[automationsKeys.CUSTOM_ATTRIBUTE_ID]
              })
            );

            const options = makeOptionsForSelect(selectedValue, [
              emptyOption,
              ...this.customAttributeFields
            ]);

            this.addFieldToRequireMatch(
              options,
              "Custom attribute",
              requiredMatchField.name
            );
          } else {
            const options = makeOptionsForSelect(selectedValue, [
              emptyOption,
              ...this.customAttributeFields
            ]);

            this.addFieldToRequireMatch(
              options,
              "Custom attribute",
              requiredMatchField.name
            );
          }
        }
      } catch (error) {
        this.setAutomationConditionErrorMessage(
          "Something went wrong, please try again. If the issue persists please contact support."
        );
      }
    },
    setRequiredMatchFields(requiredMatchParameters = [], criteriaList = []) {
      for (let index = 0; index < requiredMatchParameters.length; index++) {
        const selectedValue = criteriaList.find(
          (criteria) =>
            criteria?.parameter === requiredMatchParameters[index].name
        );

        this.setRequiredMatchField(
          requiredMatchParameters[index],
          selectedValue?.value?.customAttributeId
        );
      }
    },
    onChangeConditionAutomation(value) {
      this.requiredMatchFields = [];
      const selectedCondition = this.automationConditions.find(
        ({ id }) => id === value
      );
      const requiredMatchParameters =
        this.getRequiredMatchParameters(selectedCondition);

      if (requiredMatchParameters?.length) {
        this.setRequiredMatchFields(requiredMatchParameters);
      }

      this.setAutomationTriggerCondition(selectedCondition);
    },
    onSelectRequireMatchValue(value, index) {
      this.requiredMatchFields[index].options = makeOptionsForSelect(
        value,
        this.requiredMatchFields[index].options
      );
    },
    onTriggerStepChange(event) {
      if (event.property === triggerStepKeys.EXPECTED_DATA_MAPPING) {
        this.amendTriggerExpectedData(event);
      } else if (event.property === triggerStepKeys.ACTIONS) {
        this.amendTriggerActionName(event);
      }
    },
    amendTriggerActionName({ event, id }) {
      const step = this.getTriggerStepById(id);
      this.setTriggerStep({
        actionNameOptions: makeOptionsForSelect(event, this.makeActionOptions),
        expectDataList: this.makeExpectedDataListBasedOnActionId({ id: event }),
        actionId: event,
        step
      });
    },
    getTriggerStepById(id) {
      return this.triggerStepList.find((step) => step.id === id);
    },
    amendTriggerExpectedData({ name, event, id }) {
      const step = this.getTriggerStepById(id);

      const index = step.expectDataList.findIndex(
        (expectDataObj) => expectDataObj.name === name
      );
      step.expectDataList[index].options = makeOptionsForSelect(
        event,
        step.expectDataList[index].options
      );

      step.expectDataList[index].searchValue = getSelectedOption(
        step.expectDataList[index].options
      )?.text;
    },
    async fetchActions() {
      try {
        this.isLoadingTableData = true;
        this.automationBodyErrorMessage = "";
        const { data = [] } = await actionsService.fetchActions(
          this.companyId,
          {
            excluded_fields: [actionKeysApi.FUNCTION_BODY],
            with_options: true
          }
        );
        this.actionList = data;
      } catch ({ response }) {
        if (hasStatus500(response)) {
          this.automationBodyErrorMessage = "There is a server error.";
        } else if (!isNotAuthorized(response)) {
          this.automationBodyErrorMessage = `There is an unknown error with status of ${response?.status}. Actions are not available.`;
        }
      } finally {
        this.isLoadingTableData = false;
      }
    },
    getRequiredMatchErrorMessage(index) {
      return this.requiredMatchFields[index]?.errorMessage || "";
    },
    getAutomationProperties(propertyPath) {
      return this.currentAutomation?.[propertyPath] || "";
    }
  }
};
</script>

<style lang="scss" scoped>
.add-edit-automation {
  text-align: left;

  &__form-holder {
    height: calc(100vh - 185px);
    overflow-y: scroll;
    padding-right: 20px;
  }

  &:deep(.add-edit-automation__form-cta) {
    margin-left: 20px;
  }

  &__form-section {
    &--details {
      max-width: 500px;

      :deep(.select) {
        display: block;
      }
      :deep(.select__items-container) {
        min-width: 100%;
      }
    }

    &--triggers {
      max-width: 1000px;
      padding-bottom: 20px;

      &:deep(.accordion-section) {
        margin-bottom: 20px;
      }
    }
  }

  &__form-section-table {
    margin-bottom: 20px;
  }
}
</style>
