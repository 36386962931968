<template id="template-input">
  <b-form-group class="mt-2" label-cols-lg="3">
    <template #label>
      <p>
        {{
          attributeData.tp_alias
            ? attributeData.tp_alias
            : attributeData.tp_customatt
        }}
      </p>
    </template>
    <!-- if custom attribute is required -->
    <div v-if="attributeData.tp_is_required == 1" :ref="attributeData.id">
      <v-select
        placeholder="---Required---"
        v-model="form"
        :state="$v.form.$dirty ? !$v.form.$error : null"
        :class="{ 'form-control is-invalid p-0': $v.form.$error }"
        :options="options"
        label="text"
        @input="saveValue"
        id="select"
      >
      </v-select>
      <b-form-invalid-feedback id="select"
        >A Required field has no value.</b-form-invalid-feedback
      >
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <v-select
        v-model="form"
        :options="options"
        label="text"
        @input="saveValue"
      >
      </v-select>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "checkboxselect",
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      form: null,
      tpRequired: false,
      options: [
        { value: "1", text: "Yes" },
        { value: "0", text: "No" }
      ]
    };
  },
  validations() {
    if (parseInt(this.attributeData.tp_is_required) || this.tpRequired) {
      return {
        form: {
          required
        }
      };
    }
  },
  created() {
    // react on event CheckBoxValidationError
    EventBus.$on("CheckBoxValidationError", (inValidObj) => {
      const element = this.$refs[inValidObj.id];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.tpRequired = true;
      this.$v.form.$touch();
    });
  },

  mounted() {
    if (this.values.value) {
      if (parseInt(this.values.value) == 1) {
        this.form = { value: "1", text: "Yes" };
      } else if (parseInt(this.values.value) == 0) {
        this.form = { value: "0", text: "No" };
      } else {
        this.form = null;
      }
    } else {
      this.form = null;
    }
  },

  methods: {
    // save custom attribute value
    saveValue() {
      let valueArray = [];
      if (this.form) {
        valueArray.push(this.form.value);
      }
      if (parseInt(this.attributeData.tp_is_required)) {
        this.$v.form.$touch();
        // emit event saveValue when custom attribute is required
        EventBus.$emit(
          "saveValue",
          valueArray.toString(),
          this.attributeData.id,
          this.$v.form.$anyError,
          this.attributeData.tp_type
        );
      } else {
        // emit event saveValue when custom attribute is not required
        EventBus.$emit(
          "saveValue",
          valueArray.toString(),
          this.attributeData.id,
          false,
          this.attributeData.tp_type
        );
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
