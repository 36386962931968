<template>
  <div
    :class="[
      'base-add-delete-step',
      `base-add-delete-step--${this.getType()}`,
      `base-add-delete-step--${this.getPosition()}`
    ]"
    data-test-id="base-add-delete-step"
    @click="$emit('click', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
  >
    <BaseIcon
      v-if="showIconBeforeDash()"
      v-show="!isHidden"
      class="base-add-delete-step__icon"
      v-bind="getIcon()"
    />
    <div v-show="!isHidden" class="base-add-delete-step__dash" />
    <BaseIcon
      v-if="!showIconBeforeDash()"
      v-show="!isHidden"
      class="base-add-delete-step__icon"
      v-bind="getIcon()"
    />
  </div>
</template>

<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { position, operations, shapes, themes } from "@/constants";

export default {
  name: "BaseAddDeleteLine",
  components: { BaseIcon },
  data() {
    return {
      allowedTypes: [operations.ADD, operations.DELETE],
      allowedPosition: [position.DEFAULT, position.LEFT, position.RIGHT]
    };
  },
  props: {
    type: {
      type: String,
      default: operations.ADD,
      validator: (value) => [operations.ADD, operations.DELETE].includes(value)
    },
    position: {
      type: String,
      default: position.DEFAULT,
      validator: (value) =>
        [position.DEFAULT, position.LEFT, position.RIGHT].includes(value)
    },
    isHidden: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getIcon() {
      const icon = this.getType() === operations.ADD ? "plus" : "minus";
      const theme =
        this.getType() === operations.ADD ? themes.SUCCESS : themes.ERROR;

      return {
        shape: shapes.CIRCLE,
        size: 8,
        icon,
        theme
      };
    },
    getType() {
      return this.allowedTypes.includes(this.type) ? this.type : operations.ADD;
    },
    getPosition() {
      return this.allowedPosition.includes(this.position)
        ? this.position
        : position.DEFAULT;
    },
    showIconBeforeDash() {
      return this.getPosition() === position.LEFT;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-add-delete-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;

  &--add {
    .base-add-delete-step__dash {
      border-bottom: 2px dashed $apple-green;
      width: calc(100% - 25px);
    }
  }

  &--delete {
    .base-add-delete-step__dash {
      border-color: $spanish-red;
      height: 100%;
    }
  }

  &--default {
    height: 15px;
  }

  &--left,
  &--right {
    height: 100%;
    position: absolute;
    left: -25px;
    top: 0;
    width: 25px;

    .base-add-delete-step__dash {
      border-left-width: 2px;
      border-left-style: dashed;
    }
  }

  &--right {
    left: initial;
    right: 0;

    .base-add-delete-step__dash {
      border-left: none;
      border-right-width: 2px;
      border-right-style: dashed;
    }
  }
}
</style>
