<template id="template-select">
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    class="text-left"
    :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'"
  >
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :label="label"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <!-- preview tab -->
    <b-collapse
      :class="isPreview ? 'mt-0 mb-3' : 'mt-5'"
      id="collapse-1"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-left">
          <b-form-group
            :id="fieldData.id"
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperTextFirst[selectedLanguage]"
            :invalid-feedback="
              fieldData.validationMessageFirstSelect[selectedLanguage]
            "
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  fieldData.validation.requiredFirstSelect == 1
                "
                class="required-span"
                >*</span
              >
              {{ fieldData.labelFirst[selectedLanguage] }}
            </label>

            <v-select
              v-if="fieldData.configuration.selectedDataSource == 1"
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchable"
              :clearable="fieldData.configuration.isClearable"
              :options="renderOptions"
              label="label"
              value="index"
              v-model="selected"
              @input="selectOption"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            />

            <v-select
              v-if="fieldData.configuration.selectedDataSource == 2"
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchable"
              :clearable="fieldData.configuration.isClearable"
              :multiple="fieldData.configuration.isMultipleSelect"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
              :options="firstSelect"
              label="name"
              value="index"
              v-model="selected"
              @input="selectOption"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="text-left">
          <b-form-group
            :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            :description="fieldData.helperTextSeconed[selectedLanguage]"
            :invalid-feedback="
              fieldData.validationMessageSeconedSelect[selectedLanguage]
            "
          >
            <label>
              <span
                v-if="
                  fieldData.validation &&
                  fieldData.validation.requiredSeconedSelect == 1
                "
                class="required-span"
                >*</span
              >
              {{ fieldData.labelSeconed[selectedLanguage] }}
            </label>
            <v-select
              :disabled="isDisabled == 1"
              :searchable="fieldData.configuration.isLiveSearchableSecond"
              :clearable="fieldData.configuration.isClearableSecond"
              :multiple="fieldData.configuration.isMultipleSelectSecond"
              :options="secondSelect"
              v-model="secondSelected"
              @input="selectOptionTwo"
              :class="$store.state.rightAlign ? 'text-right' : 'text-left'"
            >
              <template slot="option" slot-scope="option">
                <country-flag
                  v-if="option.icon"
                  :country="option.icon"
                  size="small"
                />
                <span>{{ "&nbsp;" + option.label }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- advance settings tab -->

      <b-modal
        cancel-title="Close"
        ok-title="Save"
        @ok="onSaveData"
        size="xl"
        v-model="showSettings"
        title="Combo select settings"
        no-close-on-esc
        no-close-on-backdrop
      >
        <AdvanceSelectSettings
          v-if="showSettings"
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          v-on:update-form-settings="getData"
          @label-text="label = $event"
          @helper-text="helperText = $event"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ContinentsCountryCodes from "@/utils/continentsCountryCodes.json";
import vSelect from "vue-select";
import MenuButtons from "@/components/menuButtons";
import AdvanceSelectSettings from "@/components/advanceComboSelectSettingsComponent";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import { routeKeys, routeNames } from "@/constants";
import { clone } from "lodash";

export default {
  name: "SelectGroup",
  components: {
    MenuButtons,
    AdvanceSelectSettings,
    vSelect
  },
  data() {
    return {
      firstSelect: ContinentsCountryCodes.Continents,
      isHidden: false,
      isFocus: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: undefined,
      labelFirst: undefined,
      labelSeconed: undefined,
      helperTextFirst: undefined,
      helperTextSeconed: undefined,
      isRequireFirstSelect: 1,
      isRequireSeconedSelect: 1,
      name: "",
      renderOptions: [
        {
          value: 1,
          label: "India",
          options: [{ label: "Karan" }, { label: "Aman" }, { label: "Rohit" }]
        },
        {
          value: 2,
          label: "London",
          options: [{ label: "Nazmul" }, { label: "Jahedh" }]
        },
        {
          value: 3,
          label: "Vue Js",
          options: [
            { label: "Nazmul" },
            { label: "Mohsin" },
            { label: "Gourav" },
            { label: "Karan" }
          ]
        }
      ],
      Selected: [],
      secondSelect: [],
      secondSelected: [],
      thirdSelect: [],
      thirdSelected: [],
      selected: null
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage", "userFormSubmitArray"]),
    ...mapState(["toggleAllCoppalse"])
  },
  created() {
    EventBus.$on("onSubmitEndUserForm", (value) => {
      if (value == "progress") {
        this.$store.state.validFields = true;
        this.fieldData.isInValid = false;
      } else {
        if (
          (this.fieldData.validation &&
            this.fieldData.validation.requiredFirstSelect == 1,
          this.fieldData.validation.requiredSeconedSelect == 1,
          this.fieldData.validation.requiredThirdSelect == 1)
        ) {
          if (this.fieldData.configuration.isMultipleSelectSecond) {
            if (
              this.selected &&
              this.secondSelected.length > 0 &&
              this.thirdSelected.length > 0
            ) {
              this.$store.state.validFields = true;
              this.fieldData.isInValid = false;
            } else {
              this.isFocus = true;
              this.$store.state.validFields = false;
              this.fieldData.isInValid = true;
            }
          } else if (!this.fieldData.configuration.isMultipleSelectSecond) {
            if (this.selected && this.secondSelected && this.thirdSelected) {
              this.$store.state.validFields = true;
              this.fieldData.isInValid = false;
            } else {
              this.isFocus = true;
              this.$store.state.validFields = false;
              this.fieldData.isInValid = true;
            }
          }
        }
      }
    });

    EventBus.$on("OnInvalidFieldCatch", (inValidData) => {
      document
        .getElementById(inValidData.elementId)
        .scrollIntoView({ behavior: "smooth" });
    });
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  mounted() {
    this.isRequireFirstSelect = this.fieldData.validation.requiredFirstSelect;
    this.isRequireSeconedSelect =
      this.fieldData.validation.requiredSeconedSelect;
    this.requiredThirdSelect = this.fieldData.validation.requiredThirdSelect;
    this.selected = this.fieldData.selected || null;
    this.secondSelected = this.fieldData.selectedTwo || null;
    this.thirdSelected = this.fieldData.selectedThird || null;

    if (
      this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
      this.$route.name == "MyCompanyView"
    ) {
      this.setFieldValue();
    }
  },
  methods: {
    getData(data) {
      this.$emit("update-form-settings", data);
    },

    setFieldValue() {
      this.$store.state.getUserFormSubmitArray.length &&
        this.$store.state.getUserFormSubmitArray.map((a) => {
          if (a.elementId === this.fieldData.id) {
            a.elements.map((el) => {
              this.selected = el.postValue.comboSelectOne;
              this.secondSelected = el.postValue.comboSelectTwo;
              this.thirdSelected = el.postValue.comboSelectThird
                ? el.postValue.comboSelectThird
                : [];
            });
          }
        });
    },

    selectOption(value) {
      this.secondSelected = "";
      if (this.fieldData.configuration.selectedDataSource == 1) {
        this.secondSelect = value.options;
        this.selected = value.label;
      }
      if (this.fieldData.configuration.selectedDataSource == 2) {
        this.secondSelect = value.countries;
        this.selected = value.name;
      }

      // this.fieldData.selected = this.selected;
    },

    selectOptionTwo(value) {
      if (this.fieldData.configuration.selectedDataSource == 2) {
        this.thirdSelect = value.states;
      }
      if (
        this.fieldData.configuration &&
        this.fieldData.configuration.isMultipleSelectSecond
      ) {
        this.secondSelected = value;
      } else {
        this.secondSelected = value;
      }
    },

    selectOptionThird(value) {
      if (
        this.fieldData.configuration &&
        this.fieldData.configuration.isMultipleSelectSecond
      ) {
        this.thirdSelected = value;
      } else {
        this.thirdSelected = value;
      }

      this.setValue();
    },

    setValue(e) {
      const registerData = clone(this.$store.state.registerData);

      if (
        this.$route.name === routeNames[routeKeys.USER_FORM_PAGE] ||
        this.$route.name == "MyCompanyView"
      ) {
        if (this.secondSelected) {
          registerData[this.fieldData.id] = e;
        } else {
          delete registerData[this.fieldData.id];
        }
        this.$store.state.registerData = registerData;

        // main calculations :p
        const numkeys = Object.keys(registerData).length;
        // const sectionLength = this.form1.formSection.length > 1 ? this.form1.formSection[this.sectionIndex].formElements.length : 1
        this.$store.state.progressValue = numkeys;

        if (
          this.selected.length &&
          this.secondSelected.length &&
          this.thirdSelected.length
        ) {
          this.userFormSubmitArray.find((a) => {
            if (a.elementId === this.fieldData.id) {
              a.elements[0] = {
                postValue: {
                  comboSelectOne: this.selected,
                  comboSelectTwo: this.secondSelected,
                  comboSelectThird: this.thirdSelected
                },
                postName: {
                  postNameComboSelectOne:
                    (this.fieldData.attributes &&
                      this.fieldData.attributes.postName) ||
                    "postNameComboSelectOne",
                  postNameComboSelectTwo:
                    (this.fieldData.attributes &&
                      this.fieldData.attributes.postName) ||
                    "comboSelectTwo",
                  postNameComboSelectThird:
                    (this.fieldData.attributes &&
                      this.fieldData.attributes.postName) ||
                    "comboSelectThird"
                }
              };
            }
          });
        }
      }
    },

    onSaveData() {
      EventBus.$emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings Updated");
    }
  },

  validations: {
    selected: {
      required
    },
    secondSelected: {
      required
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.v-select.disabled .v-select-toggle[data-v-138dff1d] {
  background-color: $white !important;
}
</style>
