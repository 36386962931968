// https://www.color-name.com/hex
const colors = {
  white: "#ffffff",
  platinum: "#e7e7e7",
  ghostWhite: "#f8f9fa",
  brightGray: "#e8e8ea",
  chineseSilver: "#cccccc",
  spanishGray: "#9d9d9d",
  davyGrey: "#515254",
  blackOlive: "#3c3c3c",
  $graniteGray: "#5f5f5f",
  black: "#000000",

  spanishRed: "#c60a0a",
  appleGreen: "#72c003",
  robinEggBlue: "#42dc98",
  teaGreen: "#cffbbe",
  lemonChiffon: "#fff5d0",
  metallicYellow: "#ffcc13",
  shandy: "#ffed6e",

  darkCornflowerBlue: "#1f388c",
  hanBlue: "#3f5ec3",
  azure: "#007bff",
  carolinaBlue: "#4d9de0",
  frenchSkyBlue: "#7baff8",
  lavenderBlue: "#c8dfff",
  bloodRed: "#600505",
  bubbles: "#e3f4fa"
};

const IDD_COLORS = {
  GREEN: 1,
  RED: 3
};

export  {colors, IDD_COLORS }
