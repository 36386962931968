<template>
  <section>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <div v-if="isErrorPresent">
        <InputErrorMessage :error="errorMessagesApi" />
        <a target="_blank" :href="platformRequestSupportLink">
          {{ feedbackMessage }}
        </a>
      </div>
      <router-view
        v-else
        :user-email-template="userEmailTemplate"
        :user-email-templates="userEmailTemplates"
        :base-options="makeBaseOptions"
        @fetch-user-email-template="fetchUserEmailTemplateWrapper"
        @reset-user-email-template="resetUserEmailTemplate"
      />
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  actionName,
  mutationName,
  userEmailTemplatesDestinationTypes
} from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";

export default {
  name: "UserEmailWrapperTemplate",
  data() {
    return {
      isLoading: false,
      feedbackMessage: "",
      errorMessagesApi: []
    };
  },
  components: {
    BaseLoader,
    InputErrorMessage
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userEmailTemplates: (state) =>
        state.userEmailTemplates.userEmailTemplates,
      userEmailTemplate: (state) => state.userEmailTemplates.userEmailTemplate,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      roles: (state) => state.roles.roles,
      platformUserList: (state) => state.platformSettings.platformUserList
    }),
    isErrorPresent() {
      return !!this.feedbackMessage.length && !!this.errorMessagesApi.length;
    },
    makeBaseOptions() {
      const usersOptions = this.platformUserList.map(({ email, memberId }) => ({
        text: email,
        value: memberId,
        type: userEmailTemplatesDestinationTypes.USER,
        companyId: this.companyId
      }));
      const rolesOptions = this.roles.map(({ id, name }) => ({
        text: name,
        value: id,
        type: userEmailTemplatesDestinationTypes.ROLE,
        companyId: this.companyId
      }));
      return [...rolesOptions, ...usersOptions];
    }
  },
  methods: {
    ...mapActions({
      fetchUserEmailTemplates:
        actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATES,
      fetchUserEmailTemplate:
        actionName.USER_EMAIL_TEMPLATES.FETCH_USER_EMAIL_TEMPLATE,
      fetchRoles: actionName.ROLES.FETCH_ROLES
    }),
    ...mapMutations({
      resetUserEmailTemplate:
        mutationName.USER_EMAIL_TEMPLATES.RESET_USER_EMAIL_TEMPLATE,
      resetUserEmailTemplates:
        mutationName.USER_EMAIL_TEMPLATES.RESET_USER_EMAIL_TEMPLATES
    }),
    resetHTTPfeedback() {
      this.feedbackMessage = "";
      this.isLoading = true;
    },
    setFeedbackMessage() {
      this.feedbackMessage = "Please contact support.";
    },
    async fetchUserEmailTemplateWrapper(id) {
      try {
        this.resetHTTPfeedback();
        await this.fetchUserEmailTemplate(id);
      } catch (error) {
        this.fetchUserEmailTemplateFailure(error);
        this.setFeedbackMessage();
      } finally {
        this.isLoading = false;
      }
    },
    fetchUserEmailTemplateFailure(error = {}) {
      const description = error?.response?.status
        ? ` There was an error with status ${error?.response?.status}.`
        : "";
      this.errorMessagesApi.push(
        `Failed to retrieve the selected user email template.${description}`
      );
    },
    async fetchUserEmailTemplatesWrapper() {
      try {
        this.resetHTTPfeedback();
        await this.fetchUserEmailTemplates();
      } catch (error) {
        this.fetchUserEmailTemplatesFailure(error);
        this.setFeedbackMessage();
      } finally {
        this.isLoading = false;
      }
    },
    fetchUserEmailTemplatesFailure(error = {}) {
      const description = error?.response?.status
        ? ` There was an error with status ${error?.response?.status}.`
        : "";
      this.errorMessagesApi.push(
        `Failed to retrieve the available user email templates.${description}`
      );
    },
    async fetchRolesWrapper() {
      try {
        this.resetHTTPfeedback();
        await this.fetchRoles();
      } catch (error) {
        this.fetchRolesWrapperFailure(error);
        this.setFeedbackMessage();
      } finally {
        this.isLoading = false;
      }
    },
    fetchRolesWrapperFailure(error = {}) {
      const description = error?.response?.status
        ? ` There was an error with status ${error?.response?.status}.`
        : "";
      this.errorMessagesApi.push(
        `Failed to retrieve the available roles.${description}`
      );
    }
  },
  async created() {
    await this.fetchRolesWrapper();

    if (this.$route?.params?.id) {
      await this.fetchUserEmailTemplateWrapper(this.$route?.params?.id);
    }

    await this.fetchUserEmailTemplatesWrapper();
  },
  beforeRouteLeave(_, __, next) {
    this.resetUserEmailTemplates();
    next();
  }
};
</script>
