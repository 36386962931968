import { textAlign } from "@/constants";

export default {
  props: {
    textAlign: {
      type: String,
      default: textAlign.LEFT,
      validator: (value) => Object.values(textAlign).includes(value)
    }
  },
  methods: {
    getTextAlignValue() {
      return Object.values(textAlign).includes(this.textAlign)
        ? this.textAlign
        : textAlign.LEFT;
    }
  }
};
