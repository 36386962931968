<template id="template-input">
  <b-form-group class="mt-2" label-cols-lg="3">
    <template #label>
      <p>
        {{
          attributeData.tp_alias
            ? attributeData.tp_alias
            : attributeData.tp_customatt
        }}
        <Tooltip
          v-if="getAttribute(attributeData)"
          :text="attributeData.description"
        />
      </p>
    </template>

    <!-- if custom attribute is required -->
    <div v-if="attributeData.tp_is_required == 1" :ref="attributeData.id">
      <v-select
        placeholder="---Required---"
        v-model="$v.form.$model"
        :state="$v.form.$dirty ? !$v.form.$error : null"
        :class="{ 'form-control is-invalid p-0': $v.form.$error }"
        :options="attributeData.tp_customatt_values"
        label="label"
        @input="saveValue"
      >
      </v-select>
      <b-form-invalid-feedback id="select"
        >A Required field has no value.</b-form-invalid-feedback
      >
    </div>
    <!-- if custom attribute is required -->

    <!-- if custom attribute is not required -->
    <div v-else>
      <v-select
        v-model="form"
        :options="attributeData.tp_customatt_values"
        label="label"
        @input="saveValue"
      >
      </v-select>
    </div>
    <!-- if custom attribute is not required -->
  </b-form-group>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Tooltip from "@/molecules/Tooltip/Tooltip";

export default {
  name: "dropdownselect",
  mixins: [validationMixin],
  components: {
    Tooltip
  },
  data() {
    return {
      form: null,
      tpRequired: false
    };
  },
  validations() {
    if (parseInt(this.attributeData.tp_is_required) || this.tpRequired) {
      return {
        form: {
          required
        }
      };
    }
  },
  created() {
    // react on event DropDownValidationError
    EventBus.$on("DropDownValidationError", (inValidObj) => {
      const element = this.$refs[inValidObj.id];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.tpRequired = true;
      this.$v.form.$touch();
    });
  },

  mounted() {
    if (this.values.value) {
      let valueArray = this.values.value.split(",");
      this.form = valueArray;
      valueArray.map((item, index) => {
        let filteredValue = this.attributeData.tp_customatt_values.filter(
          (val) => val.id == item
        );

        this.form[index] = {
          id: filteredValue[0].id,
          label: filteredValue[0].label,
          value: filteredValue[0].value,
          tp_customatt_id: filteredValue[0].tp_customatt_id
        };
      });
    }
  },

  methods: {
    getAttribute(attributeData) {
      return attributeData?.description || "";
    },
    // save custom attribute value
    saveValue() {
      let valueArray = [];
      if (this.form) {
        valueArray.push(this.form.id);
      }
      if (parseInt(this.attributeData.tp_is_required)) {
        this.$v.form.$touch();
        // emit event saveValue when custom attribute is required
        EventBus.$emit(
          "saveValue",
          valueArray.toString(),
          this.attributeData.id,
          this.$v.form.$anyError,
          this.attributeData.tp_type
        );
      } else {
        // emit event saveValue when custom attribute is not required
        EventBus.$emit(
          "saveValue",
          valueArray.toString(),
          this.attributeData.id,
          false,
          this.attributeData.tp_type
        );
      }
    }
  },
  props: {
    attributeData: Object,
    values: Object
  }
};
</script>
