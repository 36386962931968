var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"if-block-wrapper"},[_c('div',{staticClass:"if-block-wrapper__logic-wrapper"},[_vm._l((_vm.blocks.ifBlock),function({ condition, successSteps },blockIndex){return _c('div',{key:`if-step--${_vm.stepIndex}-${blockIndex}`},[_c('div',{staticClass:"if-block-wrapper__logic if-block-wrapper__logic--if"},[_c('div',{staticClass:"if-block-wrapper__logic-text"},[_c('BaseText',{attrs:{"text":"If","size":_vm.typographySize.LARGE_TEXT}})],1),_c('BracketSection',{staticClass:"if-block-wrapper__logic-bracket",attrs:{"bracket-section":condition,"data-test-id":"if-block-wrapper__logic--bracket"},on:{"change":function($event){return _vm.$emit('change', {
              eventType: 'change',
              property: _vm.actionKeys.IF_BLOCK,
              event: $event,
              index: blockIndex
            })}}})],1),(_vm.showIfSuccessSteps)?_vm._l((successSteps),function(successStep,index){return _c('div',{key:successStep.componentOptions.id,staticClass:"if-block-wrapper__success-blocks if-block-wrapper__success-blocks--if"},[_c('div',{staticClass:"if-block-wrapper__success-block-column--text"},[_c('BaseText',{attrs:{"text":_vm.getSuccessStepText(index),"size":_vm.typographySize.LARGE_TEXT}})],1),_c('div',{staticClass:"if-block-wrapper__success-block if-block-wrapper__success-block--if"},[_c('div',{staticClass:"if-block-wrapper__success-step"},[(successStep.component)?_c(successStep.component,_vm._b({tag:"component",attrs:{"data-test-id":`if-block-wrapper__success-step--${index}`},on:{"change":function($event){return _vm.$emit('change', {
                    eventType: _vm.ifBlockEventType.AMEND_SUCCESS_STEP,
                    stepType: successStep.stepType,
                    property: _vm.actionKeys.IF_BLOCK,
                    event: $event,
                    index: blockIndex,
                    stepIndex: index
                  })}}},'component',successStep.componentOptions,false)):_vm._e()],1),_c('CallToAction',{staticClass:"if-block-wrapper__delete-success-step",attrs:{"icon":"trash-alt","size":20,"theme":_vm.themes.GREY,"data-test-id":"if-block-wrapper__delete-success-step"},on:{"click":function($event){return _vm.$emit('change', {
                  eventType: _vm.ifBlockEventType.DELETE_SUCCESS_STEP,
                  stepType: successStep.stepType,
                  property: _vm.actionKeys.IF_BLOCK,
                  id: successStep.componentOptions.id,
                  index: blockIndex
                })}}})],1)])}):_vm._e(),_c('CallToAction',{staticClass:"if-block-wrapper__success-blocks-action--add",attrs:{"value":"Add success block","theme":_vm.themes.SECONDARY,"data-test-id":"if-block-wrapper__success-blocks-action--add"},on:{"click":function($event){return _vm.addSuccessStep(_vm.actionKeys.IF_BLOCK, blockIndex)}}})],2)}),(_vm.hasElseIF)?_vm._l((_vm.blocks.elseIfBlocks),function({ condition, successSteps },blockIndex){return _c('div',{key:`else-if-step--${_vm.stepIndex}-${blockIndex}`},[_c('div',{staticClass:"if-block-wrapper__logic if-block-wrapper__logic--else-if"},[_c('div',{staticClass:"if-block-wrapper__logic-text"},[_c('BaseText',{attrs:{"text":"Else if","size":_vm.typographySize.LARGE_TEXT}})],1),_c('BracketSection',{staticClass:"if-block-wrapper__logic-bracket",attrs:{"bracket-section":condition,"data-test-id":"if-block-wrapper__logic--else-if-bracket"},on:{"change":function($event){return _vm.$emit('change', {
                eventType: 'change',
                property: _vm.actionKeys.ELSE_IF_BLOCKS,
                event: $event,
                index: blockIndex
              })}}}),_c('CallToAction',{staticClass:"if-block-wrapper__delete-success-step",attrs:{"icon":"trash-alt","size":20,"data-test-id":"if-block-wrapper__delete-success-step--else-if-block","theme":_vm.themes.GREY},on:{"click":function($event){return _vm.$emit('change', {
                eventType: _vm.ifBlockEventType.DELETE_ELSE_IF_BLOCK,
                property: _vm.actionKeys.ELSE_IF_BLOCKS,
                index: blockIndex,
                block: _vm.blocks.elseIfBlocks[blockIndex]
              })}}})],1),(_vm.showElseIfSuccessSteps(blockIndex))?_vm._l((successSteps),function(successStep,successStepIndex){return _c('div',{key:successStep.componentOptions.id,staticClass:"if-block-wrapper__success-blocks if-block-wrapper__success-blocks--else-if"},[_c('div',{staticClass:"if-block-wrapper__success-block-column--text"},[_c('BaseText',{attrs:{"text":_vm.getSuccessStepText(successStepIndex),"size":_vm.typographySize.LARGE_TEXT}})],1),_c('div',{staticClass:"if-block-wrapper__success-block if-block-wrapper__success-block--else-if"},[_c('div',{staticClass:"if-block-wrapper__success-step"},[(successStep.component)?_c(successStep.component,_vm._b({tag:"component",attrs:{"data-test-id":`if-block-wrapper__success-block--else-if-${successStepIndex}`},on:{"change":function($event){return _vm.$emit('change', {
                      eventType: _vm.ifBlockEventType.AMEND_SUCCESS_STEP,
                      property: _vm.actionKeys.ELSE_IF_BLOCKS,
                      stepType: successStep.stepType,
                      event: $event,
                      index: blockIndex
                    })}}},'component',{
                    ...successStep.componentOptions
                  },false)):_vm._e()],1),_c('CallToAction',{staticClass:"if-block-wrapper__delete-success-step",attrs:{"icon":"trash-alt","size":20,"data-test-id":"if-block-wrapper__delete-success-step--else-if-component","theme":_vm.themes.GREY},on:{"click":function($event){return _vm.$emit('change', {
                    eventType: _vm.ifBlockEventType.DELETE_SUCCESS_STEP,
                    stepType: successStep.stepType,
                    property: _vm.actionKeys.ELSE_IF_BLOCKS,
                    id: successStep.componentOptions.id,
                    index: blockIndex
                  })}}})],1)])}):_vm._e(),_c('CallToAction',{staticClass:"if-block-wrapper__success-blocks-action--add",attrs:{"data-test-id":"if-block-wrapper__success-blocks-action--add-else-if","value":"Add success block","theme":_vm.themes.SECONDARY},on:{"click":function($event){return _vm.addSuccessStep(_vm.actionKeys.ELSE_IF_BLOCKS, blockIndex)}}})],2)}):_vm._e(),(_vm.hasElseStep)?_c('div',{staticClass:"if-block-wrapper__else-wrapper"},[_vm._l((_vm.blocks.elseBlock),function(successStep,successStepIndex){return _c('div',{key:successStep.componentOptions.id,staticClass:"if-block-wrapper__success-blocks if-block-wrapper__success-blocks--else"},[_c('div',{staticClass:"if-block-wrapper__success-block-column--text"},[_c('BaseText',{attrs:{"text":_vm.getSuccessStepText(successStepIndex, 'else'),"size":_vm.typographySize.LARGE_TEXT}})],1),_c('div',{staticClass:"if-block-wrapper__success-block if-block-wrapper__success-block--else"},[_c('div',{staticClass:"if-block-wrapper__success-step"},[(successStep.component)?_c(successStep.component,_vm._b({tag:"component",attrs:{"data-test-id":`if-block-wrapper__success-block--else-${successStepIndex}`},on:{"change":function($event){return _vm.$emit('change', {
                  eventType: _vm.ifBlockEventType.AMEND_SUCCESS_STEP,
                  property: _vm.actionKeys.ELSE_BLOCK,
                  stepType: successStep.stepType,
                  event: $event
                })}}},'component',{
                ...successStep.componentOptions
              },false)):_vm._e()],1),_c('CallToAction',{staticClass:"if-block-wrapper__delete-success-step",attrs:{"icon":"trash-alt","size":20,"data-test-id":"if-block-wrapper__delete-success-step--else","theme":_vm.themes.GREY},on:{"click":function($event){return _vm.$emit('change', {
                eventType: _vm.ifBlockEventType.DELETE_SUCCESS_STEP,
                stepType: successStep.stepType,
                property: _vm.actionKeys.ELSE_BLOCK,
                id: successStep.componentOptions.id
              })}}})],1)])}),_c('CallToAction',{staticClass:"if-block-wrapper__success-blocks-action--add",attrs:{"data-test-id":"if-block-wrapper__success-blocks-action--add-else","value":"Add success block","theme":_vm.themes.SECONDARY},on:{"click":function($event){return _vm.addSuccessStep(_vm.actionKeys.ELSE_BLOCK)}}})],2):_vm._e()],2),_c('BaseFooter',{staticClass:"if-block-wrapper__footer",scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',[_c('CallToAction',{staticClass:"if-block-wrapper__add-blocks--else-if",attrs:{"value":"Add else if","theme":_vm.themes.SECONDARY,"data-test-id":"if-block-wrapper__add-blocks--else-if"},on:{"click":_vm.addElseIfBlock}}),(!_vm.hasElseStep)?_c('CallToAction',{staticClass:"if-block-wrapper__add-blocks--else",attrs:{"value":"Add else","theme":_vm.themes.SECONDARY,"data-test-id":"if-block-wrapper__add-blocks--else"},on:{"click":_vm.addElseBlock}}):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }