<template>
  <FormsContainer v-if="isUserSuperAdmin" />
</template>

<script>
import FormsContainer from "@/containers/FormsContainer";
import { mapGetters } from "vuex";
import { getterName } from "@/constants";

export default {
  name: "TemplatesPage",
  components: {
    FormsContainer
  },
  computed: {
    ...mapGetters({
      isUserSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN
    })
  }
};
</script>
