import {
  getAllSelectedOptions,
  getSelectedOption,
  isLessThanOrEqualToMaxLength,
  isRequired
} from "@/utils";
import { cloneDeep } from "lodash";
import TriggerStepValidationMixin from "@/organisms/TriggerStep/TriggerStepValidation.mixin";

export default {
  mixins: [TriggerStepValidationMixin],
  data() {
    return {
      automationNameErrorMessage: "",
      automationDescriptionErrorMessage: "",
      automationStatusErrorMessage: "",
      automationConditionErrorMessage: ""
    };
  },
  methods: {
    validateAutomationName() {
      let isInvalid = false;

      if (!isRequired(this.currentAutomation.name)) {
        isInvalid = true;
        this.automationNameErrorMessage = "required";
      } else if (
        !isLessThanOrEqualToMaxLength(this.currentAutomation.name, 255)
      ) {
        isInvalid = true;
        this.automationNameErrorMessage = "maximum of 255 characters";
      } else {
        this.automationNameErrorMessage = "";
      }
      return isInvalid;
    },
    validateAutomationDescription() {
      let isInvalid = false;

      if (!isRequired(this.currentAutomation.description)) {
        isInvalid = true;
        this.automationDescriptionErrorMessage = "required";
      } else if (
        !isLessThanOrEqualToMaxLength(this.currentAutomation.description, 255)
      ) {
        isInvalid = true;
        this.automationDescriptionErrorMessage = "maximum of 255 characters";
      } else {
        this.automationDescriptionErrorMessage = "";
      }
      return isInvalid;
    },
    validateAutomationStatus() {
      let isInvalid = false;

      if (!isRequired(this.currentAutomation.isActive)) {
        isInvalid = true;
        this.automationStatusErrorMessage = "required";
      } else {
        this.automationStatusErrorMessage = "";
      }
      return isInvalid;
    },
    validateAutomationCondition() {
      let isInvalid = false;
      const selectedValueOption = getSelectedOption(
        this.conditionAutomationOptions
      );

      if (
        !isRequired(selectedValueOption) ||
        !isRequired(selectedValueOption.value)
      ) {
        isInvalid = true;
        this.automationConditionErrorMessage = "required";
      } else {
        this.automationConditionErrorMessage = "";
      }
      return isInvalid;
    },
    validateAutomationRequireMatchField(index) {
      let isInvalid = false;
      const requiredFieldClone = cloneDeep(this.requiredMatchFields[index]);
      const selectedValueOption = getSelectedOption(requiredFieldClone.options);

      if (
        !isRequired(selectedValueOption) ||
        !isRequired(selectedValueOption.value)
      ) {
        isInvalid = true;
        requiredFieldClone.errorMessage = "required";
      } else {
        requiredFieldClone.errorMessage = "";
      }

      this.requiredMatchFields.splice(index, 1, requiredFieldClone);

      return isInvalid;
    },
    validateAutomationRequireMatchFields() {
      const isFieldInvalid = [];

      for (let index = 0; index < this.requiredMatchFields.length; index++) {
        isFieldInvalid.push(this.validateAutomationRequireMatchField(index));
      }

      return isFieldInvalid.includes(true);
    },
    validateTriggerStepList() {
      if (!this.triggerStepList?.length) {
        this.automationBodyErrorMessage =
          "at least one trigger step is required";
        return true;
      } else {
        const isTriggerStepInvalid = [];

        for (let index = 0; index < this.triggerStepList.length; index++) {
          isTriggerStepInvalid.push(
            this.validateTriggerStep(this.triggerStepList[index])
          );
        }

        this.automationBodyErrorMessage = "";
        return isTriggerStepInvalid.includes(true);
      }
    },
    validateTriggerRoles() {
      if (!this.hasManualCondition) {
        return false;
      }
      let isInvalid = false;
      const selectedRoleOptions = getAllSelectedOptions(
        this.conditionAutomationOptions
      );

      if (!isRequired(selectedRoleOptions)) {
        isInvalid = true;
        this.triggerAutomationRoleErrorMessage = "required";
      } else {
        this.triggerAutomationRoleErrorMessage = "";
      }

      return isInvalid;
    },
    async validateAutomation() {
      const isAutomationNameInvalid = this.validateAutomationName();
      const isAutomationDescriptionInvalid =
        this.validateAutomationDescription();
      const isAutomationStatusInvalid = this.validateAutomationStatus();
      const isAutomationConditionInvalid = this.validateAutomationCondition();
      const isAutomationRequireMatchFieldsInvalid =
        this.validateAutomationRequireMatchFields();
      const isAutomationTriggerStepInvalid = this.validateTriggerStepList();
      const isAutomationTriggerRolesInvalid = this.validateTriggerRoles();

      return (
        isAutomationNameInvalid ||
        isAutomationDescriptionInvalid ||
        isAutomationStatusInvalid ||
        isAutomationConditionInvalid ||
        isAutomationRequireMatchFieldsInvalid ||
        isAutomationTriggerStepInvalid ||
        isAutomationTriggerRolesInvalid
      );
    }
  }
};
