import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makePublicPlatformSettingsDTO,
  makePlatformSettingsDTO,
  makeProductVersionsDTO
} from "./dto/platformSettings.dto";

const fetchPlatformSettings = async () => {
  const response = await HTTP(
    "get",
    endpoints.FETCH_PLATFORM_SETTINGS_DATA,
    null,
    makeAuthorizationHeader()
  );
  response.data.data = makePlatformSettingsDTO(response.data.data);
  return response;
};

const fetchPublicPlatformSettings = async () => {
  const response = await HTTP(
    "get",
    endpoints.PLATFORM_SETTINGS,
    null,
    makeAuthorizationHeader()
  );
  response.data.data = makePublicPlatformSettingsDTO(response.data.data);
  return response;
};

const updatePlatformSettings = (formData) =>
  HTTP(
    "post",
    endpoints.UPDATE_PLATFORM_SETTING_DATA,
    formData,
    makeAuthorizationHeader()
  );

const fetchProductVersions = async () => {
  const response = await HTTP(
    "get",
    endpoints.PRODUCT_VERSIONS_LIST,
    null,
    makeAuthorizationHeader()
  );
  response.data = makeProductVersionsDTO(response.data);
  return response;
};

export const platformSettingsService = {
  fetchPlatformSettings,
  fetchPublicPlatformSettings,
  updatePlatformSettings,
  fetchProductVersions
};
