<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Text -->
        <b-tab title="Text" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Title">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Description">
                  <b-form-textarea
                    @blur="addLabelLanguageToField(language)"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Entity Name label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="entityNameTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Percentage Owned Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="percentageOwnedTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Registration Number label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="registrationNumberTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Date Of Registration label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="dateOfRegistrationTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Country Of Registration Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="countryOfRegistrationTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Free Form Label">
                      <b-form-input
                        @blur="addLabelLanguageToField(language)"
                        v-model="freeFormTitle"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Helper Text------------------- -->
        <b-tab title="Helper Text">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabHelperTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Entity Name</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="entityNameHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Percentage Owned</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="percentageOwnedHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Registration Number</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="registrationNumberHelperText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Helper Text for Date Of Registration</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="dateOfRegistrationHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Helper Text for Country Of Registration</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="countryOfRegistrationHelperText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Helper Text for Free Form</label>
                    <b-form-input
                      @blur="addHelperTextLanguageToField(language)"
                      v-model="freeFormHelperText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Place Holder Text------------------- -->
        <b-tab title="Place Holder">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabPlaceHolderTextClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Entity Name</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="entityNamePlaceHolderText"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Place Holder for Percentage Owned</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="percentageOwnedPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Place Holder for Registration Number</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="registrationNumberPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Place Holder for Free Form</label>
                    <b-form-input
                      @blur="addPlaceHolderTextLanguageToField(language)"
                      v-model="freeFormPlaceHolderText"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- ----------------Validation Message------------------- -->
        <b-tab title="Validation Message">
          <b-tabs pills card vertical>
            <b-tab
              @click="tabValidationMessageClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Validation Message for Entity Name</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="entityNameValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Validation Message for Percentage Owned</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="percentageOwnedValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for
                      >Validation Message for Registration Number</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="registrationNumberValidationMessage"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for
                      >Validation Message for Date Of Registration</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="dateOfRegistrationValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for
                      >Validation Message for Country Of Registration</label
                    >
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="countryOfRegistrationValidationMessage"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Validation Message for Free Form</label>
                    <b-form-input
                      @blur="addValidationMessageLanguageToField(language)"
                      v-model="freeFormValidationMessage"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-tab>

        <b-tab title="Configure">
          <b-form-group class="w-50 mb-5" label="Selec County List Type">
            <v-select
              :id="fieldData.id"
              searchable
              clearable
              :options="renderOptions"
              label="text"
              class="mb-5"
              v-model="fieldData.selectedListTypeIndex"
            />
          </b-form-group>
        </b-tab>

        <!-- ----------------Attributes------------------- -->
        <b-tab title="Attributes">
          <b-form @submit.stop.prevent class="text-left mt-2">
            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Entity Name</label>
                <b-form-input v-model="entityNamePostName"></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Percentage Owned</label>
                <b-form-input v-model="percentageOwnedPostName"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Registration Number</label>
                <b-form-input
                  v-model="registrationNumberPostName"
                ></b-form-input>
              </b-col>

              <b-col cols="6">
                <label for>Post name for Date Of Registration</label>
                <b-form-input
                  v-model="dateOfRegistrationPostName"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="6">
                <label for>Post name for Country Of Registration</label>
                <b-form-input
                  v-model="countryOfRegistrationPostName"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for>Post name for Free Form</label>
                <b-form-input v-model="freeFormPostName"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <!-- ----------------Advance------------------->
        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <b-form-checkbox
                class="text-left mb-3"
                v-model="entityNameDisabled"
                >Disable Entity Name?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="percentageOwnedDisabled"
                >Disable Percentage Owned?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="registrationNumberDisabled"
                >Disable Registration Number?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="dateOfRegistrationDisabled"
                >Disable Date Of Registration?</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                v-model="countryOfRegistrationDisabled"
                >Disable Country Of Registration</b-form-checkbox
              >

              <b-form-checkbox class="text-left mb-3" v-model="freeFormDisabled"
                >Disable Free Form</b-form-checkbox
              >

              <b-form-checkbox
                class="text-left mb-3"
                value="1"
                unchecked-value="0"
                v-model="fieldData.isNaFeature"
                >Disable N/A Feature</b-form-checkbox
              >
            </b-col>
          </b-row>
        </b-tab>

        <!-- data validation -->
        <b-tab title="Validation">
          <div class="text-left">
            <b-col>
              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireEntityName"
                >Is required Entity Name?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequirePercentageOwned"
                >Is required Percentage Owned?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireRegistrationNumber"
                >Is required Registration Number?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireDateOfRegistration"
                >Is required Date Of Registration?</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireCountryOfRegistration"
                >Is required Country Of Registration</b-form-checkbox
              >

              <b-form-checkbox
                class="form-group col-md-6 ml-3"
                v-model="isRequireFreeForm"
                >Is required Free Form</b-form-checkbox
              >
            </b-col>
          </div>
        </b-tab>
        <!-- ----------------Advanced Date------------------- -->
        <b-tab title="Advanced Date">
          <div class="text-left mt-4">
            <div class="col-12">
              <b-form-checkbox
                v-model="disabledMinDateInput"
                class="col-md-12 my-1"
                @change="onToggleDatesCheckbox('min')"
                >Disable Past Dates</b-form-checkbox
              >
              <b-collapse :visible="!disabledMinDateInput">
                <div class="form-group col-md-6">
                  <label for>Minimum date</label>
                  <b-form-input type="date" v-model="minDate"></b-form-input>
                </div>
              </b-collapse>

              <b-form-checkbox
                class="col-md-12 my-1"
                @change="onToggleDatesCheckbox('max')"
                v-model="disabledMaxDateInput"
                >Disable Future Dates</b-form-checkbox
              >
              <b-collapse :visible="!disabledMaxDateInput">
                <div class="form-group col-md-6">
                  <label for>Maximum date</label>
                  <b-form-input type="date" v-model="maxDate"></b-form-input>
                </div>
              </b-collapse>

              <b-form-group label="Select Date Format">
                <b-form-radio-group
                  @input="onSetDateFormat(selectedDateFormat)"
                  stacked
                  v-model="selectedDateFormat"
                  :options="optionsDateFormatt"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import list from "@/utils/countryListTypes.json";

export default {
  name: "CorporateShareHoldersSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: {},

  data() {
    return {
      transalationLanguage: "english",
      // Input General

      description: null,
      // labels
      label: null,
      entityNameTitle: null,
      dateOfRegistrationTitle: null,
      registrationNumberTitle: null,
      percentageOwnedTitle: null,
      countryOfRegistrationTitle: null,
      freeFormTitle: null,

      // Helper Text
      entityNameHelperText: null,
      dateOfRegistrationHelperText: null,
      registrationNumberHelperText: null,
      percentageOwnedHelperText: null,
      countryOfRegistrationHelperText: null,
      freeFormHelperText: null,

      // Place Holder Text
      entityNamePlaceHolderText: null,
      percentageOwnedPlaceHolderText: null,
      registrationNumberPlaceHolderText: null,
      freeFormPlaceHolderText: null,

      // Validation Message
      entityNameValidationMessage: null,
      dateOfRegistrationValidationMessage: null,
      registrationNumberValidationMessage: null,
      percentageOwnedValidationMessage: null,
      countryOfRegistrationValidationMessage: null,
      freeFormValidationMessage: null,

      // hidden/diabled
      entityNameDisabled: false,
      dateOfRegistrationDisabled: false,
      registrationNumberDisabled: false,
      percentageOwnedDisabled: false,
      countryOfRegistrationDisabled: false,
      freeFormDisabled: true,

      // validation
      isRequireEntityName: true,
      isRequireDateOfRegistration: true,
      isRequirePercentageOwned: true,
      isRequireRegistrationNumber: true,
      isRequireCountryOfRegistration: true,
      isRequireFreeForm: false,

      // attributes
      entityNamePostName: null,
      dateOfRegistrationPostName: null,
      registrationNumberPostName: null,
      percentageOwnedPostName: null,
      countryOfRegistrationPostName: null,
      freeFormPostName: null,

      // advance date
      minDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.minDate
          ? this.fieldData.advanceDate.minDate
          : undefined,
      maxDate:
        this.fieldData.advanceDate && this.fieldData.advanceDate.maxDate
          ? this.fieldData.advanceDate.maxDate
          : undefined,
      disabledMinDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMinDateInput
          ? this.fieldData.advanceDate.disabledMinDateInput
          : false,
      disabledMaxDateInput:
        this.fieldData.advanceDate &&
        this.fieldData.advanceDate.disabledMaxDateInput
          ? this.fieldData.advanceDate.disabledMaxDateInput
          : false,
      selectedDateFormat: this.fieldData.format || "default",
      optionsDateFormatt: [
        { text: "Default", value: "default" },
        { text: "Monday 12 Aug 2019", value: "dddd MMMM Do YYYY" },
        { text: "September 19, 2019", value: "LL" },
        { text: "dd/mm/yyyy", value: "DD/MM/YYYY" },
        { text: "mm/dd/yyyy", value: "MM/DD/YYYY" }
      ]
    };
  },

  created() {
    EventBus.$on("onSaveSettings", () => {
      // this.addLabelLanguageToField(this.transalationLanguage);
      // this.addHelperTextLanguageToField(this.transalationLanguage);
      // this.addPlaceHolderTextLanguageToField(this.transalationLanguage);
      // this.addValidationMessageLanguageToField(this.transalationLanguage);
      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
      this.addDateAdvacne();
    });
  },

  mounted() {
    this.setFiledsLabelText();
    this.setFiledsHelperText();
    this.setFiledsPlaceHolderText();
    this.setFiledsValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();
  },

  computed: {
    ...mapGetters({ form: "form1" }),
    renderOptions() {
      return list;
    }
  },

  methods: {
    setFiledsLabelText() {
      this.description = this.fieldData.description[this.transalationLanguage];

      this.label = this.fieldData.label[this.transalationLanguage];

      this.entityNameTitle =
        this.fieldData.entityNameLabel[this.transalationLanguage];
      this.dateOfRegistrationTitle =
        this.fieldData.dateOfRegistrationLabel[this.transalationLanguage];
      this.registrationNumberTitle =
        this.fieldData.registrationNumberLabel[this.transalationLanguage];

      this.percentageOwnedTitle =
        this.fieldData.percentageOwnedLabel[this.transalationLanguage];

      this.countryOfRegistrationTitle =
        this.fieldData.countryOfRegistrationLabel[this.transalationLanguage];

      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    setFiledsHelperText() {
      // Helper Text

      this.entityNameHelperText =
        this.fieldData.entityNameHelperText[this.transalationLanguage];

      this.dateOfRegistrationHelperText =
        this.fieldData.dateOfRegistrationHelperText[this.transalationLanguage];

      this.registrationNumberHelperText =
        this.fieldData.registrationNumberHelperText[this.transalationLanguage];

      this.percentageOwnedHelperText =
        this.fieldData.percentageOwnedHelperText[this.transalationLanguage];

      this.countryOfRegistrationHelperText =
        this.fieldData.countryOfRegistrationHelperText[
          this.transalationLanguage
        ];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    setFiledsPlaceHolderText() {
      // Place Holder Text

      this.entityNamePlaceHolderText =
        this.fieldData.entityNamePlaceholderText[this.transalationLanguage];

      this.percentageOwnedPlaceHolderText =
        this.fieldData.percentageOwnedPlaceholderText[
          this.transalationLanguage
        ];

      this.registrationNumberPlaceHolderText =
        this.fieldData.registrationNumberPlaceholderText[
          this.transalationLanguage
        ];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    setFiledsValidationMessage() {
      // Validation Message

      this.entityNameValidationMessage =
        this.fieldData.entityNameValidationMessage[this.transalationLanguage];

      this.dateOfRegistrationValidationMessage =
        this.fieldData.dateOfRegistrationValidationMessage[
          this.transalationLanguage
        ];

      this.registrationNumberValidationMessage =
        this.fieldData.registrationNumberValidationMessage[
          this.transalationLanguage
        ];

      this.percentageOwnedValidationMessage =
        this.fieldData.percentageOwnedValidationMessage[
          this.transalationLanguage
        ];

      this.countryOfRegistrationValidationMessage =
        this.fieldData.countryOfRegistrationValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    setFiledsAttributes() {
      (this.entityNamePostName =
        this.fieldData.attributes &&
        this.fieldData.attributes.entityNamePostName
          ? this.fieldData.attributes.entityNamePostName
          : undefined),
        (this.dateOfRegistrationPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.dateOfRegistrationPostName
            ? this.fieldData.attributes.dateOfRegistrationPostName
            : undefined),
        (this.registrationNumberPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.registrationNumberPostName
            ? this.fieldData.attributes.registrationNumberPostName
            : undefined),
        (this.percentageOwnedPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.percentageOwnedPostName
            ? this.fieldData.attributes.percentageOwnedPostName
            : undefined),
        (this.countryOfRegistrationPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.countryOfRegistrationPostName
            ? this.fieldData.attributes.countryOfRegistrationPostName
            : undefined),
        (this.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : undefined);
    },

    setFiledsAdvance() {
      (this.entityNameDisabled =
        this.fieldData.advance && this.fieldData.advance.entityNameDisabled),
        (this.dateOfRegistrationDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.dateOfRegistrationDisabled),
        (this.registrationNumberDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.registrationNumberDisabled),
        (this.percentageOwnedDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.percentageOwnedDisabled),
        (this.countryOfRegistrationDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.countryOfRegistrationDisabled),
        (this.freeFormDisabled =
          this.fieldData.advance && this.fieldData.advance.freeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireEntityName =
        this.fieldData.validation &&
        this.fieldData.validation.isRequireEntityName),
        (this.isRequireDateOfRegistration =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireDateOfRegistration),
        (this.isRequireRegistrationNumber =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireRegistrationNumber),
        (this.isRequirePercentageOwned =
          this.fieldData.validation &&
          this.fieldData.validation.isRequirePercentageOwned),
        (this.isRequireCountryOfRegistration =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireCountryOfRegistration),
        (this.isRequireFreeForm =
          this.fieldData.validation &&
          this.fieldData.validation.isRequireFreeForm);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.label = this.fieldData.label[this.transalationLanguage];
      this.description = this.fieldData.description[this.transalationLanguage];
      this.entityNameTitle =
        this.fieldData.entityNameLabel[this.transalationLanguage];
      this.dateOfRegistrationTitle =
        this.fieldData.dateOfRegistrationLabel[this.transalationLanguage];
      this.registrationNumberTitle =
        this.fieldData.registrationNumberLabel[this.transalationLanguage];
      this.percentageOwnedTitle =
        this.fieldData.percentageOwnedLabel[this.transalationLanguage];
      this.countryOfRegistrationTitle =
        this.fieldData.countryOfRegistrationLabel[this.transalationLanguage];
      this.freeFormTitle =
        this.fieldData.freeFormLabel[this.transalationLanguage];
    },

    tabHelperTextClick(language) {
      // Helper Text

      this.transalationLanguage = language.toLowerCase();
      this.entityNameHelperText =
        this.fieldData.entityNameHelperText[this.transalationLanguage];

      this.dateOfRegistrationHelperText =
        this.fieldData.dateOfRegistrationHelperText[this.transalationLanguage];

      this.registrationNumberHelperText =
        this.fieldData.registrationNumberHelperText[this.transalationLanguage];

      this.percentageOwnedHelperText =
        this.fieldData.percentageOwnedHelperText[this.transalationLanguage];

      this.countryOfRegistrationHelperText =
        this.fieldData.countryOfRegistrationHelperText[
          this.transalationLanguage
        ];

      this.freeFormHelperText =
        this.fieldData.freeFormHelperText[this.transalationLanguage];
    },

    tabPlaceHolderTextClick(language) {
      // Place Holder Text

      this.transalationLanguage = language.toLowerCase();
      this.entityNamePlaceHolderText =
        this.fieldData.entityNamePlaceholderText[this.transalationLanguage];

      this.percentageOwnedPlaceHolderText =
        this.fieldData.percentageOwnedPlaceholderText[
          this.transalationLanguage
        ];

      this.registrationNumberPlaceHolderText =
        this.fieldData.registrationNumberPlaceholderText[
          this.transalationLanguage
        ];

      this.freeFormPlaceHolderText =
        this.fieldData.freeFormPlaceholderText[this.transalationLanguage];
    },

    tabValidationMessageClick(language) {
      // Validation Message

      this.transalationLanguage = language.toLowerCase();
      this.entityNameValidationMessage =
        this.fieldData.entityNameValidationMessage[this.transalationLanguage];

      this.dateOfRegistrationValidationMessage =
        this.fieldData.dateOfRegistrationValidationMessage[
          this.transalationLanguage
        ];

      this.registrationNumberValidationMessage =
        this.fieldData.registrationNumberValidationMessage[
          this.transalationLanguage
        ];

      this.percentageOwnedValidationMessage =
        this.fieldData.percentageOwnedValidationMessage[
          this.transalationLanguage
        ];

      this.countryOfRegistrationValidationMessage =
        this.fieldData.countryOfRegistrationValidationMessage[
          this.transalationLanguage
        ];

      this.freeFormValidationMessage =
        this.fieldData.freeFormValidationMessage[this.transalationLanguage];
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.label[tempKey] = this.label;
      this.fieldData.description[tempKey] = this.description;
      this.fieldData.entityNameLabel[tempKey] = this.entityNameTitle;
      this.fieldData.dateOfRegistrationLabel[tempKey] =
        this.dateOfRegistrationTitle;
      this.fieldData.registrationNumberLabel[tempKey] =
        this.registrationNumberTitle;
      this.fieldData.percentageOwnedLabel[tempKey] = this.percentageOwnedTitle;
      this.fieldData.countryOfRegistrationLabel[tempKey] =
        this.countryOfRegistrationTitle;
      this.fieldData.freeFormLabel[tempKey] = this.freeFormTitle;
    },

    addHelperTextLanguageToField(language) {
      // Helper Text

      let tempKey = language.toLowerCase();
      this.fieldData.entityNameHelperText[tempKey] = this.entityNameHelperText;
      this.fieldData.dateOfRegistrationHelperText[tempKey] =
        this.dateOfRegistrationHelperText;
      this.fieldData.registrationNumberHelperText[tempKey] =
        this.registrationNumberHelperText;
      this.fieldData.percentageOwnedHelperText[tempKey] =
        this.percentageOwnedHelperText;
      this.fieldData.countryOfRegistrationHelperText[tempKey] =
        this.countryOfRegistrationHelperText;
      this.fieldData.freeFormHelperText[tempKey] = this.freeFormHelperText;
    },

    addPlaceHolderTextLanguageToField(language) {
      // Place Holder Text

      let tempKey = language.toLowerCase();
      this.fieldData.entityNamePlaceholderText[tempKey] =
        this.entityNamePlaceHolderText;
      this.fieldData.percentageOwnedPlaceholderText[tempKey] =
        this.percentageOwnedPlaceHolderText;
      this.fieldData.registrationNumberPlaceholderText[tempKey] =
        this.registrationNumberPlaceHolderText;
      this.fieldData.freeFormPlaceholderText[tempKey] =
        this.freeFormPlaceHolderText;
    },

    addValidationMessageLanguageToField(language) {
      // Validation Message

      let tempKey = language.toLowerCase();
      this.fieldData.entityNameValidationMessage[tempKey] =
        this.entityNameValidationMessage;
      this.fieldData.dateOfRegistrationValidationMessage[tempKey] =
        this.dateOfRegistrationValidationMessage;
      this.fieldData.registrationNumberValidationMessage[tempKey] =
        this.registrationNumberValidationMessage;
      this.fieldData.percentageOwnedValidationMessage[tempKey] =
        this.percentageOwnedValidationMessage;
      this.fieldData.countryOfRegistrationValidationMessage[tempKey] =
        this.countryOfRegistrationValidationMessage;
      this.fieldData.freeFormValidationMessage[tempKey] =
        this.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireEntityName: this.entityNameDisabled
          ? false
          : this.isRequireEntityName,
        isRequireDateOfRegistration: this.dateOfRegistrationDisabled
          ? false
          : this.isRequireDateOfRegistration,
        isRequireRegistrationNumber: this.registrationNumberDisabled
          ? false
          : this.isRequireRegistrationNumber,
        isRequirePercentageOwned: this.percentageOwnedDisabled
          ? false
          : this.isRequirePercentageOwned,
        isRequireCountryOfRegistration: this.countryOfRegistrationDisabled
          ? false
          : this.isRequireCountryOfRegistration,
        isRequireFreeForm: this.freeFormDisabled
          ? false
          : this.isRequireFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        entityNameDisabled: this.entityNameDisabled,
        dateOfRegistrationDisabled: this.dateOfRegistrationDisabled,
        registrationNumberDisabled: this.registrationNumberDisabled,
        percentageOwnedDisabled: this.percentageOwnedDisabled,
        countryOfRegistrationDisabled: this.countryOfRegistrationDisabled,
        freeFormDisabled: this.freeFormDisabled
      };
      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        entityNamePostName: this.entityNamePostName,
        dateOfRegistrationPostName: this.dateOfRegistrationPostName,
        registrationNumberPostName: this.registrationNumberPostName,
        percentageOwnedPostName: this.percentageOwnedPostName,
        countryOfRegistrationPostName: this.countryOfRegistrationPostName,
        freeFormPostName: this.freeFormPostName
      };
      Object.assign(this.fieldData, { attributes });
    },

    onToggleDatesCheckbox(key) {
      if (key == "min") {
        this.minDate = undefined;
      }
      if (key == "max") {
        this.maxDate = undefined;
      }
    },

    addDateAdvacne() {
      let yesterday = new Date(Date.now() - 864e5); // testing required
      let tomorrow = new Date();
      // tomorrow.setDate(new Date().getDate() + 1);

      let advanceDate = {
        minDate:
          this.minDate && !this.disabledMinDateInput
            ? this.minDate
            : this.disabledMinDateInput
            ? yesterday
            : undefined,
        maxDate:
          this.maxDate && !this.disabledMaxDateInput
            ? this.maxDate
            : this.disabledMaxDateInput
            ? tomorrow
            : undefined,
        disabledMinDateInput: this.disabledMinDateInput,
        disabledMaxDateInput: this.disabledMaxDateInput
      };
      Object.assign(this.fieldData, { advanceDate });
    },

    onSetDateFormat() {
      let format = this.selectedDateFormat;
      Object.assign(this.fieldData, { format });
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
