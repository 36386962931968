const customAttributeActionsState = {
  INACTIVE: 2,
  ACTIVE: "Active",
  ACTIVATE: "Activate",
  DEACTIVATE: "Deactivate",
  DEACTIVATED: "Deactivated"
};

const customAttributesTypeData = {
  CUSTOM_ATTRIBUTE: "custom_attribute"
};

export { customAttributeActionsState, customAttributesTypeData };
