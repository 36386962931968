<template id="template-input">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <!-- input content -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden)"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-col> </b-col>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import { mapGetters, mapState } from "vuex";

export default {
  name: "VideoGroup",
  components: {
    MenuButtons
  },
  data() {
    return {
      isHidden: false,
      hasImage: false,
      image: null,
      condition: {},
      showToolTip: false,
      label: undefined,
      placeholder: undefined,
      tooltipText: undefined,
      showSettings: false,
      collapse: true
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  methods: {
    setImage(output) {
      this.hasImage = true;
      this.image = output;
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
<style lang="scss" scoped>
button,
input {
  display: none !important;
}
#fileInput {
  display: none !important;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: $robin-egg-blue;
}
.my-8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
</style>
