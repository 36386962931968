<template>
  <section class="platform-switcher">
    <div class="platform-switcher__wrapper">
      <img
        class="platform-switcher__logo"
        src="@/assets/ethixbase-grey.jpg"
        alt="logo"
      />
      <BaseText
        :text="platformSwitchMessage"
        class="platform-switcher__title"
      />
      <a class="platform-switcher__link" :href="externalUrls.EB2_PLATFORM_URL">
        Click Here
      </a>
    </div>
  </section>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { externalUrls } from "@/constants";

export default {
  name: "PlatformSwitcher",
  components: {
    BaseText
  },
  data() {
    return {
      platformSwitchMessage: [
        "Account not available for this URL",
        "Please follow the link below to access your platform."
      ],
      externalUrls
    };
  }
};
</script>

<style scoped lang="scss">
.platform-switcher {
  max-width: 580px;
  margin: 50px auto;

  &__wrapper {
    padding: 0 20px;
  }

  &:deep(.platform-switcher__title) {
    text-align: left;
    font-size: 20px;
  }
  &__logo {
    display: block;
    margin-bottom: 40px;
    height: 200px;
  }
  &__link {
    display: block;
    text-align: left;
    font-size: 20px;
  }
}
</style>
