import { productVersionsKeysDTO } from "@/constants";

const serviceVersionMapper = {
  [productVersionsKeysDTO.EB360]: "EB360",
  [productVersionsKeysDTO.ERAF_ACTIONS]: "Eraf Actions",
  [productVersionsKeysDTO.ERAF_AUTOMATIONS]: "Eraf Automations",
  [productVersionsKeysDTO.CORPORATE_DATA]: "Corporate Data",
  [productVersionsKeysDTO.GREENLITE_ANALYTICS]: "Greenlite Analytics",
  [productVersionsKeysDTO.GREENLITE_SCORING]: "Greenlite Scoring",
  [productVersionsKeysDTO.ROLES_AND_PERMISSIONS]: "Roles and Permissions"
};

export { serviceVersionMapper };
