import {
  actionKeys,
  actionKeysApi,
  actionStepType,
  expectedDataTypes,
  triggerDataType
} from "@/constants";
import { getSelectedOption } from "@/utils";
import { makeDataDefinitionVariableOptionValueAPIDTO } from "@/services/actions/dto/actionsDTO/actions.dto";

const makeTriggerStepProxyAPIDTO = ({ options = [], name = "" } = {}) => ({
  [actionKeysApi.TRIGGER_DATA]: {
    [actionKeysApi.TYPE]: triggerDataType.PROXY,
    [actionKeysApi.DATA]: {
      [actionKeysApi.NAME]: getSelectedOption(options)?.value
    }
  },
  [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name
});

const getDDQformObj = (options = []) => {
  try {
    const value = JSON.parse(getSelectedOption(options)?.value);
    return makeDataDefinitionVariableOptionValueAPIDTO(value);
  } catch (e) {
    return makeDataDefinitionVariableOptionValueAPIDTO();
  }
};

const makeTriggerStepDDQformAPIDTO = ({ options = [], name = "" } = {}) => {
  const valueObj = getDDQformObj(options);

  return {
    [actionKeysApi.TRIGGER_DATA]: {
      [actionKeysApi.TYPE]: triggerDataType.IDENTIFIER,
      [actionKeysApi.DATA]: {
        [actionKeysApi.TYPE]: expectedDataTypes.DDQ_FORM,
        [actionKeysApi.VALUE]: {
          [actionKeysApi.DDQ_ID]: valueObj?.[actionKeysApi.DDQ_ID],
          [actionKeysApi.COMPANY_ID]: valueObj?.[actionKeysApi.COMPANY_ID]
        }
      }
    },
    [actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name
  };
};

const makeTriggerStepDDQformDTO = (expectDataObj) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.VALUE]: {
        [actionKeys.COMPANY_ID]:
          expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.COMPANY_ID],
        [actionKeys.DDQ_ID]:
          expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
            actionKeysApi.VALUE
          ]?.[actionKeysApi.DDQ_ID]
      }
    },
    [actionKeys.TYPE]:
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepProxyDTO = (expectDataObj) => ({
  [actionKeys.TRIGGER_DATA]: {
    [actionKeys.DATA]: {
      [actionKeys.NAME]:
        expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.DATA]?.[
          actionKeysApi.NAME
        ]
    },
    [actionKeys.TYPE]:
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE]
  },
  [actionKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
    expectDataObj[actionKeysApi.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]
});

const makeTriggerStepAPIDTO = (functionStep) => ({
  [actionKeysApi.STEP_TYPE]: actionStepType.TRIGGER,
  [actionKeysApi.COMMENT]: functionStep[actionKeys.COMMENT] || "",
  [actionKeysApi.ACTION_ID]: getSelectedOption(functionStep.actionNameOptions)
    .value,
  [actionKeysApi.EXPECTED_DATA_MAPPING]: functionStep.expectDataList.map(
    (expectDataObj) => {
      if (
        expectDataObj.type === expectedDataTypes.THIRD_PARTY ||
        expectDataObj.type === expectedDataTypes.IDD_IDD_PLUS
      ) {
        return makeTriggerStepProxyAPIDTO(expectDataObj);
      } else if (expectDataObj.type === expectedDataTypes.DDQ_FORM) {
        return makeTriggerStepDDQformAPIDTO(expectDataObj);
      }
    }
  )
});

const makeTriggerStepDTO = (functionStep) => ({
  [actionKeys.STEP_TYPE]: functionStep[actionKeysApi.STEP_TYPE],
  [actionKeys.COMMENT]: functionStep[actionKeysApi.COMMENT] || "",
  [actionKeys.ACTION_ID]: functionStep[actionKeysApi.ACTION_ID],
  [actionKeys.EXPECTED_DATA_MAPPING]: functionStep[
    actionKeysApi.EXPECTED_DATA_MAPPING
  ].map((expectDataObj) => {
    if (
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ===
      triggerDataType.PROXY
    ) {
      return makeTriggerStepProxyDTO(expectDataObj);
    } else if (
      expectDataObj?.[actionKeysApi.TRIGGER_DATA]?.[actionKeysApi.TYPE] ===
      triggerDataType.IDENTIFIER
    ) {
      return makeTriggerStepDDQformDTO(expectDataObj);
    }
  })
});

export {
  makeTriggerStepProxyAPIDTO,
  getDDQformObj,
  makeTriggerStepDDQformAPIDTO,
  makeTriggerStepDDQformDTO,
  makeTriggerStepProxyDTO,
  makeTriggerStepAPIDTO,
  makeTriggerStepDTO
};
