var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"set-step"},[_c('div',{staticClass:"set-step__wrapper"},[_c('div',{staticClass:"set-step__from-block"},[_c('BaseText',{staticClass:"set-step__text",attrs:{"text":"Set","size":_vm.typographySize.LARGE_TEXT}}),_c('SearchSelect',{staticClass:"set-step__from-block--step-name",attrs:{"label":"SET","placeholder":"Select...","name":`variable-name-${_vm.id}`,"id":`variable-name-${_vm.id}`,"options":_vm.variableNameOptions,"error":_vm.variableNameErrorMessage,"searchValue":_vm.variableNameSearchValue,"data-test-id":"set-step__from-block","is-label-hidden":""},on:{"change":function($event){return _vm.$emit('change', {
            name: _vm.actionKeys.VARIABLE_NAME,
            id: _vm.id,
            event: $event
          })},"create":function($event){return _vm.$emit('create', {
            name: _vm.actionKeys.VARIABLE_NAME,
            id: _vm.id,
            event: $event
          })},"reset":function($event){return _vm.$emit('reset', {
            name: _vm.actionKeys.VARIABLE_NAME,
            id: _vm.id,
            event: $event
          })}}}),_c('div',{staticClass:"set-step__text set-step__separator"},[_c('BaseText',{attrs:{"text":"to","size":_vm.typographySize.LARGE_TEXT}}),_c('div',{staticClass:"set-step__separator--line"})],1)],1),_c('SetToBlock',_vm._b({staticClass:"set-step__to-block",attrs:{"data-test-id":"set-step__to-block"},on:{"change":function($event){return _vm.$emit('change', $event)}}},'SetToBlock',_vm.$props,false))],1),(_vm.isEndOperationAvailable)?_c('EndOperations',{staticClass:"set-step__operations",attrs:{"data-test-id":"set-step__operations","endOperations":_vm.endOperations},on:{"change":function($event){return _vm.$emit('change', {
        ...$event,
        setStepId: _vm.id
      })}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }