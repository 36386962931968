<template>
  <tr class="table-row" data-test-id="table-row">
    <td
      v-for="({ value, sortOrder }, columnIndex) in tableHeaders"
      data-test-id="table-row__cell"
      :class="[
        'table-row__cell',
        { 'table-row__cell--sorted': isHighlight(sortOrder) }
      ]"
      :key="columnIndex"
      :style="getColumnStyles(value)"
    >
      <!-- eslint-disable-next-line vue/require-component-is -->
      <component
        v-if="tableRow[value].component"
        :is="tableRow[value].component"
        v-bind="tableRow[value].componentOptions"
        @click="$emit('click', $event)"
      >
        {{ tableRow[value].componentOptions.defaultSlots }}
      </component>
      <BaseText
        v-else
        :text="tableRow[value]"
        tag="span"
        data-test-id="table-row__cell-text"
      />
    </td>
  </tr>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { sortOrder as sortOrderEnum } from "@/constants";
import { isEmpty } from "lodash";

export default {
  name: "TableRow",
  components: {
    BaseText
  },
  props: {
    tableRow: {
      type: Object,
      default: () => ({})
    },
    tableHeaders: {
      type: Array,
      default: () => []
    },
    tableRowLevel: {
      type: Number,
      default: 1
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    isHighlight(sortDirection) {
      return (
        sortDirection === sortOrderEnum.ASCENDING ||
        sortDirection === sortOrderEnum.DESCENDING
      );
    },
    getColumnStyles(value) {
      return isEmpty(this.tableRow[value]?.styles)
        ? {
            paddingLeft: `${this.tableRowLevel * 15}px`
          }
        : this.tableRow[value].styles;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-row {
  &__wrapper {
    display: contents;
  }

  &__cell {
    border-bottom: 1px solid $spanish-gray;
    border-left: 1px solid $spanish-gray;
    padding: 10px 15px;
    text-align: left;

    &:last-child {
      border-right: 1px solid $spanish-gray;
    }

    &--sorted {
      background: $lemon-chiffon;
    }
  }
}
</style>
