<template>
  <div>
    <b-card style="width: -webkit-fill-available">
      <div class="d-flex" style="justify-content: space-between">
        <div style="margin: auto">
          <h4 style="font-size: 17px">
            Third Party Risk Distribution By End State %
          </h4>
        </div>
      </div>
      <b-row>
        <b-col cols="8">
          <highcharts :options="supplierRiskBarChartOptions" />
        </b-col>
        <b-col cols="4" style="align-self: center">
          <div>
            <div
              v-for="(item, index) in avgSupplierRiskDistributionScorePercent"
              :key="index"
              class="mt-4"
            >
              <div class="pb-3">
                <span class="d-flex" style="justify-content: center">
                  <div>
                    <h2 style="color: #3ddc97">
                      {{ item.avg_low ? item.avg_low : 0 }}%
                    </h2>
                  </div>
                  <div v-if="item.avg_low != 0">
                    <span v-if="item && item.status_low == 1">
                      <font-awesome-icon
                        style="font-size: 30px; color: #3ddc97"
                        icon="arrow-up"
                      />
                    </span>
                    <span v-else-if="item && item.status_low == 0">
                      <font-awesome-icon
                        style="font-size: 30px; color: #3ddc97"
                        icon="arrow-down"
                      />
                    </span>
                  </div>
                </span>
                <span>Low Risk</span>
              </div>
              <div class="pb-3">
                <span class="d-flex" style="justify-content: center">
                  <div>
                    <h2 style="color: #f7a35c">
                      {{ item.avg_moderate ? item.avg_moderate : 0 }}%
                    </h2>
                  </div>
                  <div v-if="item.avg_moderate != 0">
                    <span v-if="item && item.status_moderate == 1">
                      <font-awesome-icon
                        style="font-size: 30px; color: #f7a35c"
                        icon="arrow-up"
                      />
                    </span>
                    <span v-else-if="item && item.status_moderate == 0">
                      <font-awesome-icon
                        style="font-size: 30px; color: #f7a35c"
                        icon="arrow-down"
                      />
                    </span>
                  </div>
                </span>
                <span>Moderate Risk</span>
              </div>
              <div class="pb-3">
                <span class="d-flex" style="justify-content: center">
                  <div>
                    <h2 style="color: #e71d36">
                      {{ item.avg_high ? item.avg_high : 0 }}%
                    </h2>
                  </div>
                  <div v-if="item.avg_high != 0">
                    <span v-if="item && item.status_high == 1">
                      <font-awesome-icon
                        style="font-size: 30px; color: #e71d36"
                        icon="arrow-up"
                      />
                    </span>
                    <span v-else-if="item && item.status_high == 0">
                      <font-awesome-icon
                        style="font-size: 30px; color: #e71d36"
                        icon="arrow-down"
                      />
                    </span>
                  </div>
                </span>
                <span>High Risk</span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "SupplierRiskComparativeComponent",
  props: {
    supplierRiskBarChartOptions: Object,
    avgSupplierRiskDistributionScorePercent: Array
  }
};
</script>
