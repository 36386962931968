import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makePlatformUserDTO } from "@/services/views/dto/views.dto";

const fetchPlatformView = (id) =>
  HTTP(
    "get",
    `${endpoints.PLATFORM_VIEW_DETAILS}/${id}`,
    null,
    makeAuthorizationHeader()
  );

const deletePlatformView = (id, companyId) =>
  HTTP(
    "post",
    `${endpoints.PLATFORM_VIEW_DELETE}/${id}`,
    {
      company_id: companyId
    },
    makeAuthorizationHeader()
  );

const fetchPlatformUsers = async (formData) => {
  const response = await HTTP(
    "post",
    endpoints.PLATFORM_USERS_LIST_ALL,
    formData,
    makeAuthorizationHeader()
  );
  return makePlatformUserDTO(response);
};

export const viewsService = {
  fetchPlatformView,
  deletePlatformView,
  fetchPlatformUsers
};
