<template>
  <b-tabs v-model="tabIndex" nav-class="custom-tab-css" vertical small>
    <b-tab :lazy="true" title="Company Details">
      <CompanyDetails
        :premium-corporate-data="premiumCorporateData"
        :is-dnb-disabled="isDnbDisabled"
        :is-dnb-verified="isDnbVerified"
      />
    </b-tab>
    <b-tab :lazy="true" title="Principals">
      <Principals
        :premium-corporate-data="premiumCorporateData"
        :is-dnb-disabled="isDnbDisabled"
        :is-dnb-verified="isDnbVerified"
        :third-party-data="thirdPartyData"
      />
    </b-tab>
    <b-tab :lazy="true" title="Financial Information">
      <FinancialInformation
        :premium-corporate-data="premiumCorporateData"
        :is-dnb-disabled="isDnbDisabled"
        :is-dnb-verified="isDnbVerified"
      />
    </b-tab>
    <b-tab :lazy="true" title="Change log">
      <ChangeLog
        :organisations-change-log-data="organisationsChangeLogData"
        :is-dnb-disabled="isDnbDisabled"
        :is-dnb-verified="isDnbVerified"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import CompanyDetails from "@/organisms/CompanyDetails/CompanyDetails";
import Principals from "@/organisms/Principals/Principals";
import FinancialInformation from "@/organisms/FinancialInformation/FinancialInformation";
import ChangeLog from "@/organisms/ChangeLog/ChangeLog";

export default {
  name: "PremiumCorporateDataTemplate",
  components: {
    CompanyDetails,
    Principals,
    FinancialInformation,
    ChangeLog
  },
  props: {
    premiumCorporateData: {
      type: Object,
      default: () => ({})
    },
    organisationsChangeLogData: {
      type: Array,
      default: () => []
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    },
    thirdPartyData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tabIndex: 0
    };
  }
};
</script>
