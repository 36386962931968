var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border border-light p-3 pb-2 mb-1"},[_c('b-collapse',{attrs:{"visible":_vm.form1.formTitle.english.toLowerCase().includes(_vm.formTitles.MSQ)
        ? !_vm.isHidden
        : !_vm.sectionData.isHidden}},[(
        (_vm.formTypeLocal == 1 || _vm.formTypeLocal == 9) &&
        _vm.sectionData.advance &&
        !_vm.sectionData.advance.hideSectionTitle
      )?_c('div',{class:_vm.rightAlign ? 'text-right' : 'text-left'},[_c('h3',[(!_vm.rightAlign)?_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(_vm.counter + 1))]):_vm._e(),_vm._v("  "+_vm._s(_vm.sectionData.sectionTitle[_vm.selectedLanguage])+" "),(_vm.rightAlign && _vm.selectedLanguage == 'arabic')?_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(new Intl.NumberFormat("ar-EG", {}).format(_vm.counter + 1)))]):_vm._e(),(_vm.rightAlign && _vm.selectedLanguage == 'hebrew')?_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(_vm.counter + 1))]):_vm._e()],1),_c('br'),_c('h5',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.sectionData.sectionSubTitle && _vm.sectionData.sectionSubTitle[_vm.selectedLanguage])+" ")])]):_vm._e(),_vm._l((_vm.sectionData.formElements),function(fieldData,index){return _c('ElementsContainer',{key:index,attrs:{"fieldData":fieldData,"isPreview":true,"isDisabled":_vm.isDisabled,"sectionIndex":_vm.sectionData.index,"fieldIndex":index,"inputType":fieldData.inputType}})}),_c('hr')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }