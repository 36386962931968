import actions from "@/store/userSettings/userSettings.actions.store";
import getters from "@/store/userSettings/userSettings.getters.store";
import mutations from "@/store/userSettings/userSettings.mutations.store";
import state from "@/store/userSettings/userSettings.state.store";

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
