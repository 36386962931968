import { state as makeState } from "@/store/country/country.state.store";

export const mutations = {
  setCountries(state, payload) {
    state.countries = payload;
  },
  resetCountries(state) {
    Object.assign(state, makeState());
  }
};
