<template>
  <div
    bg-light
    :style="{
      paddingLeft: isSidebarOpened ? '0px' : '40px',
      paddingTop: '20px'
    }"
  >
    <b-container fluid>
      <div class="pt-2">
        <div class="text-left">
          <h4>List of available Sample Excel Files</h4>
        </div>
        <b-row class="mt-4">
          <b-col cols="6" offset="3">
            <b-form-group>
              <b-input-group>
                <b-form-input
                  @keyup.enter="onFilterExcel"
                  @input="onEmpltyFilter($event)"
                  v-model="filter"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="onFilterExcel"
                    class="green-btn"
                    variant="outline-success"
                    >Search</b-button
                  >
                  <b-button
                    class="red-btn"
                    variant="outline-danger"
                    :disabled="!filter"
                    @click="resetData"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-container style="height: 65vh; overflow-y: auto">
          <b-overlay :show="isLoading">
            <div
              class="mt-4 text-left"
              v-for="(list, index) in filteredList"
              :key="index"
            >
              <p v-if="list.url !== ''">
                <b-btn
                  class="mr-2"
                  size="sm"
                  variant="outline-info"
                  @click="downloadSampleExcelFile(list)"
                >
                  <font-awesome-icon icon="file-excel" />
                </b-btn>
                <a :href="list.url">{{ list.title }}</a>
              </p>
              <p v-else>
                <b-btn
                  class="mr-2"
                  size="sm"
                  variant="outline-info"
                  @click="getExcelFileLink()"
                >
                  <font-awesome-icon icon="file-excel" />
                </b-btn>
                <span @click="getExcelFileLink()">
                  <a href="#">{{ list.title }}</a>
                </span>
              </p>
              <hr />
            </div>
          </b-overlay>
        </b-container>
      </div>
    </b-container>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { endpoints } from "@/constants";
import { mapState } from "vuex";

export default {
  name: "ListOfSampleExcelFiles",
  data() {
    return {
      conditonCheck: true,
      filter: "",
      excelFileData: [],
      appApiUrl: process.env.VUE_APP_API_URL.substring(
        0,
        process.env.VUE_APP_API_URL.length - 4
      ),
      AllExcelFileData: [],
      isLoading: false
    };
  },
  mounted() {
    this.AllExcelFileData = [
      {
        title: "Sample Excel File For ThirdParty Bulk Upload",
        url: ""
      },
      {
        title: "Sample Excel File For Import Generic MSQ",
        url: `${this.appApiUrl}public/platform/Data_Msq_Import_Template.xlsm`
      }
    ];
    this.resetData();
  },
  computed: {
    filteredList() {
      return this.AllExcelFileData.filter((post) =>
        post.title.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId,
      isSidebarOpened: (state) => state.isSidebarOpened
    })
  },
  methods: {
    onEmpltyFilter(value) {
      if (value) {
        return false;
      } else {
        this.resetData();
      }
    },
    resetData() {
      this.filter = "";
      this.excelFileData = [];
      this.excelFileData = this.AllExcelFileData;
    },
    onFilterExcel: debounce(function () {
      return this.AllExcelFileData.filter((post) =>
        post.title.toLowerCase().includes(this.filter.toLowerCase())
      );
    }, 500),
    downloadSampleExcelFile(list) {
      window.location.href = list.url;
    },
    async getExcelFileLink() {
      try {
        this.isLoading = true;
        const result = await HTTP(
          "post",
          endpoints.THIRD_PARTY_BULK_UPLOAD_SAMPLE_FILE,
          this.makeBulkUploadAPIData(),
          makeAuthorizationHeader(),
          null,
          false,
          "blob"
        );
        if (result) {
          this.downloadExcel(result);
        }
      } catch (error) {
        return [error];
      } finally {
        this.isLoading = false;
      }
    },
    makeBulkUploadAPIData() {
      return {
        company_id: this.companyId,
        user_id: this.userId,
        filename: "Data_Template.xls",
        filepath: "public/platform"
      };
    },
    downloadExcel(result) {
      const blob = new Blob([result.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data_Template.xlsx"); // or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style lang="scss">
.green-btn {
  color: $eucalyptus !important;
  border-color: $eucalyptus !important;
}
.green-btn:hover {
  color: $white !important;
  background-color: $eucalyptus !important;
  border-color: $eucalyptus !important;
}
.green-btn.disabled,
.green-btn:disabled {
  color: $eucalyptus !important;
  background-color: $white !important;
}
.green-btn:disabled.green-btn:hover {
  border-color: $eucalyptus !important;
  background-color: $white !important;
  color: $eucalyptus !important;
}
.red-btn {
  color: $alizarin-crimson !important;
  border-color: $alizarin-crimson !important;
}
.red-btn:hover {
  color: $white !important;
  background-color: $alizarin-crimson !important;
  border-color: $alizarin-crimson !important;
}
.red-btn.disabled,
.red-btn:disabled {
  color: $alizarin-crimson !important;
  background-color: $white !important;
}
.red-btn:disabled.red-btn:hover {
  border-color: $alizarin-crimson !important;
  background-color: $white !important;
  color: $alizarin-crimson !important;
}
</style>
