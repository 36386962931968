<template>
  <div class="textarea">
    <Label :id="id" :label="label" :isRequired="isRequired">
      <template v-for="(_, slot) in $slots">
        <template :slot="slot">
          <slot :name="slot"></slot>
        </template>
      </template>
    </Label>
    <div class="textarea__input-container">
      <b-form-textarea
        :id="id"
        :value="value"
        :placeholder="placeholder"
        :state="validationState"
        :rows="rows"
        :no-resize="noResize"
        @change="onChangeHandler"
      />
      <InputErrorMessage :error="error" />
    </div>
  </div>
</template>

<script>
import Label from "@/atoms/Label/Label.vue";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage.vue";

export default {
  name: "Textarea",
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    name: {
      type: String,
      default: null
    },
    error: {
      default: "",
      type: String
    },
    validationState: {
      type: [Boolean, Object],
      default: null
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Number,
      default: 4
    },
    noResize: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Label,
    InputErrorMessage
  },
  methods: {
    onChangeHandler(value) {
      this.$emit("change", this.name, value);
    }
  }
};
</script>

<style lang="scss" scoped>
.textarea {
  &__input-container {
    width: 100%;
    padding: 0 0 20px 0;
  }

  & .label {
    width: 100%;
  }
}
</style>
