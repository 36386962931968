<template>
  <span
    :class="[
      'base-icon',
      `base-icon--theme-${getTheme}`,
      `base-icon--shape-${getShape}`
    ]"
    data-test-id="base-icon"
    :style="getComputedStyles"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      class="base-icon__icon"
      :style="{ fontSize: `${size}px` }"
      data-test-id="base-icon__icon"
    />
    <span v-if="overlayText.length" class="base-icon__overlay-text">{{
      overlayText
    }}</span>
  </span>
</template>

<script>
import { isValidShape, isValidTheme } from "@/utils";

export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      default: ""
    },
    shape: {
      type: String,
      default: "none",
      validator(value) {
        return isValidShape(value);
      }
    },
    size: {
      type: Number,
      default: 40
    },
    theme: {
      type: String,
      default: "none",
      validator(value) {
        return isValidTheme(value);
      }
    },
    overlayText: {
      type: String,
      default: ""
    },
    border: {
      type: String,
      default: ""
    }
  },
  computed: {
    getComputedStyles() {
      let iconSize;
      switch (this.getShape) {
        case "circle":
          iconSize = this.size * 2.1;
          break;
        case "triangle":
          iconSize = this.size * 3.0;
          break;
        case "octagon":
          iconSize = this.size * 2.4;
          break;
        case "square":
          iconSize = this.size * 1.75;
          break;
        default:
          iconSize = this.size * 1.4;
          break;
      }

      return {
        "--icon-size": `${iconSize}px`,
        "--smaller-icon-size": `${iconSize * 0.65}px`,
        border: `${this.border}`
      };
    },
    getTheme() {
      return isValidTheme(this.theme) ? this.theme : "none";
    },
    getShape() {
      return isValidShape(this.shape) ? this.shape : "none";
    }
  }
};
</script>

<style lang="scss" scoped>
.base-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transition-duration;
  position: relative;

  &--shape-none {
    @each $theme, $primary-color, $secondary-color, $has-border in $themes {
      &.base-icon--theme-#{$theme} {
        background: transparent;
        width: var(--icon-size);
        height: var(--icon-size);
        color: $secondary-color;
      }
    }
  }

  &--shape-square {
    @each $theme, $primary-color, $secondary-color, $has-border in $themes {
      &.base-icon--theme-#{$theme} {
        background: $primary-color;
        width: var(--icon-size);
        height: var(--icon-size);
        color: $secondary-color;
        border-radius: 5px;

        @if $has-border {
          border: 1px solid $chinese-silver;
        }
      }
    }
  }

  &--shape-circle {
    @each $theme, $primary-color, $secondary-color, $has-border in $themes {
      &.base-icon--theme-#{$theme} {
        border-radius: 50%;
        width: var(--icon-size);
        height: var(--icon-size);
        background: $primary-color;
        color: $secondary-color;

        @if $has-border {
          border: 1px solid $chinese-silver;
        }
      }
    }
  }

  &--shape-triangle {
    @each $theme, $primary-color, $secondary-color, $has-border in $themes {
      &.base-icon--theme-#{$theme} {
        position: relative;
        width: var(--icon-size);
        height: var(--smaller-icon-size);
        color: $secondary-color;
        z-index: 2;

        .base-icon__icon {
          z-index: 3;
          margin-top: 10%;
        }

        &:after {
          content: "";
          position: absolute;
          width: var(--icon-size);
          height: var(--icon-size);
          background-color: $primary-color;
          clip-path: polygon(50% 35%, 5% 100%, 95% 100%);
          transform: translateY(-17%);
        }

        @if $has-border {
          &:before {
            content: "";
            position: absolute;
            width: calc(var(--icon-size) + 1px);
            height: calc(var(--icon-size) + 1px);
            background-color: $chinese-silver;
            clip-path: polygon(50% 35%, 5% 100%, 95% 100%);
            transform: translateY(-17%);
          }
        }
      }
    }
  }

  &--shape-octagon {
    @each $theme, $primary-color, $secondary-color, $has-border in $themes {
      &.base-icon--theme-#{$theme} {
        position: relative;
        width: var(--icon-size);
        height: var(--icon-size);
        background-color: $secondary-color;
        clip-path: polygon(
          30% 0%,
          70% 0%,
          100% 30%,
          100% 70%,
          70% 100%,
          30% 100%,
          0% 70%,
          0% 30%
        );

        @if $has-border {
          z-index: 2;

          .base-icon__icon {
            z-index: 3;
          }

          &:after {
            content: "";
            position: absolute;
            width: var(--icon-size);
            height: var(--icon-size);
            background-color: $primary-color;
            border: 1px solid $chinese-silver;
          }
        }
      }
    }
  }

  &--shape-square {
    @each $theme, $primary-color, $secondary-color in $themes {
      &.base-icon--theme-#{$theme} {
        width: var(--icon-size);
        height: var(--icon-size);
        background: $primary-color;
        color: $secondary-color;
        border-radius: 10px;
        border: 2px solid;
        border-color: $chinese-silver;
      }
    }
  }

  &__icon {
    &--small {
      font-size: 14px;
    }

    &--medium {
      font-size: 28px;
    }

    &--large {
      font-size: 42px;
    }
  }

  &__overlay-text {
    width: 15px;
    height: 15px;
    background: $white;
    border-radius: 45%;
    border: 2px solid $chinese-silver;
    position: absolute;
    z-index: 5;
    display: flex;
    font-size: 9px;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    color: $black;
  }
}
</style>
