import { state as makeState } from "@/store/roles/roles.state.store";

export const mutations = {
  setRoles(state, payload) {
    state.roles = payload;
  },
  setIsLoadingRoles(state, payload) {
    state.isLoadingRoles = payload;
  },
  resetRoles(state) {
    Object.assign(state, makeState());
  }
};
