<!-- Custom Attribute Filter component -->
<template>
  <div>
    <div
      v-if="
        attributeData.tp_type == 'Text' ||
        attributeData.tp_type == 'email' ||
        attributeData.tp_type == 'htmlString' ||
        attributeData.tp_type == 'Textarea' ||
        attributeData.tp_type == 'url'
      "
    >
      <b-form-group>
        <b-form-input
          v-model="form.inputValue"
          placeholder=""
          @blur="saveFilterValue"
        ></b-form-input>
      </b-form-group>
    </div>
    <div
      v-else-if="
        attributeData.tp_type == 'integer' || attributeData.tp_type == 'double'
      "
    >
      <b-form-group>
        <b-form-input
          v-model="form.integerValue"
          placeholder=""
          type="number"
          @blur="saveFilterValue"
        ></b-form-input>
      </b-form-group>
    </div>
    <div v-else-if="attributeData.tp_type == 'color'">
      <b-form-group>
        <b-form-input
          v-model="form.colorValue"
          placeholder=""
          type="color"
          @blur="saveFilterValue"
        ></b-form-input>
      </b-form-group>
    </div>
    <div v-else-if="attributeData.tp_type == 'Date'">
      <b-form-group>
        <b-form-datepicker
          v-model="form.dateValue"
          :date-format-options="{
            year: 'numeric',
            month: 'long',
            day: '2-digit'
          }"
          placeholder=""
          @input="saveFilterValue"
        ></b-form-datepicker>
      </b-form-group>
    </div>
    <div
      v-else-if="
        attributeData.tp_type == 'Dropdown' ||
        attributeData.tp_type == 'Multiselect' ||
        attributeData.tp_type == 'Radio' ||
        attributeData.tp_type === 'Rating'
      "
    >
      <b-form-group>
        <v-select
          label="label"
          v-model="form.dropdownValue"
          :options="attributeData.tp_customatt_values"
          :clearable="true"
          :multiple="true"
          @input="saveFilterValue"
        ></v-select>
      </b-form-group>
    </div>
    <div
      v-else-if="
        attributeData.tp_type == 'Country List' ||
        attributeData.tp_type === 'Multiselect Country'
      "
    >
      <b-form-group>
        <v-select
          :maxHeight="220"
          label="country"
          track-by="name"
          :options="countries"
          :clearable="true"
          :multiple="true"
          v-model="form.countryValue"
          @input="saveFilterValue"
        >
          <template slot="option" slot-scope="option">
            <country-flag
              v-if="option.icon"
              :country="option.icon"
              size="small"
            />
            <span>{{ "&nbsp;" + option.name }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>
    <div v-else-if="attributeData.tp_type == 'boolean'">
      <b-form-group>
        <v-select
          label="label"
          v-model="form.booleanValue"
          :options="optionsBoolean"
          :clearable="true"
          :multiple="true"
          @input="saveFilterValue"
        ></v-select>
      </b-form-group>
    </div>
    <!-- <div v-else-if="attributeData.tp_type == 'File' || attributeData.tp_type == 'image'">
            <b-form-group>
            <b-form-input
                    v-model="form.fileValue"
                    placeholder=""
                    @blur="saveFilterValue"
                ></b-form-input>
            </b-form-group>
        </div> -->
    <div v-else-if="attributeData.tp_type == 'User'">
      <b-form-group>
        <v-select
          label="name"
          v-model="form.UserValue"
          :options="optionsUsers"
          :clearable="true"
          :multiple="true"
          @input="saveFilterValue"
        ></v-select>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapState } from "vuex";

export default {
  name: "CustomAttributesFilterComponent",
  props: {
    attributeData: Object,
    optionsUsers: Array,
    filterType: Object,
    filterItemsIndex: Number,
    filterTypeValue: Array,
    dateTypeValue: String,
    stringTypeValue: String
  },
  data() {
    return {
      optionsBoolean: [
        { label: "true", value: 1 },
        { label: "false", value: 0 }
      ],
      form: {
        inputValue: this.stringTypeValue,
        integerValue: this.stringTypeValue,
        colorValue: this.stringTypeValue,
        dateValue: this.dateTypeValue,
        dropdownValue: this.filterTypeValue,
        countryValue: this.filterTypeValue,
        booleanValue: this.filterTypeValue,
        fileValue: null,
        UserValue: this.filterTypeValue
      }
    };
  },
  mounted() {
    this.optionsBoolean.sort(this.dynamicSort("label"));
    this.attributeData.tp_customatt_values.sort(this.dynamicSort("label"));
  },
  computed: {
    ...mapState({
      countries: (state) => state.country.countries
    })
  },
  methods: {
    dynamicSort(property) {
      return function (a, b) {
        return a[property].localeCompare(b[property]);
      };
    },
    saveFilterValue() {
      //    Emit event saveFilterValue
      if (
        this.attributeData.tp_type == "Text" ||
        this.attributeData.tp_type == "email" ||
        this.attributeData.tp_type == "htmlString" ||
        this.attributeData.tp_type == "Textarea" ||
        this.attributeData.tp_type == "url"
      ) {
        let valueArray = [this.form.inputValue];
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          this.form.inputValue,
          valueArray,
          this.attributeData.id
        );
      } else if (
        this.attributeData.tp_type == "integer" ||
        this.attributeData.tp_type == "double"
      ) {
        let valueArray = [this.form.integerValue];
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          this.form.integerValue,
          valueArray,
          this.attributeData.id
        );
      } else if (this.attributeData.tp_type == "color") {
        let valueArray = [this.form.colorValue];
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          this.form.colorValue,
          valueArray,
          this.attributeData.id
        );
      } else if (this.attributeData.tp_type == "Date") {
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          this.form.dateValue,
          this.form.dateValue,
          this.attributeData.id,
          this.attributeData.tp_type,
          this.filterType
        );
      } else if (
        this.attributeData.tp_type == "Dropdown" ||
        this.attributeData.tp_type == "Multiselect" ||
        this.attributeData.tp_type == "Radio" ||
        this.attributeData.tp_type === "Rating"
      ) {
        let valueArray = [];
        let filterValue = [];
        this.form.dropdownValue.map((val) => {
          valueArray.push(val.id);
          filterValue.push({
            label: val.label,
            value: val.value
          });
        });
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          filterValue,
          valueArray,
          this.attributeData.id
        );
      } else if (
        this.attributeData.tp_type == "Country List" ||
        this.attributeData.tp_type == "Multiselect Country"
      ) {
        let countryobj = [];
        let filterValue = [];
        this.form.countryValue.map((val) => {
          filterValue.push({
            name: val.name,
            value: val.id
          });
          let jsonStringObj = {
            id: val.id,
            value: val.name
          };
          countryobj.push(JSON.stringify(jsonStringObj));
        });
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          filterValue,
          countryobj,
          this.attributeData.id
        );
      } else if (this.attributeData.tp_type == "boolean") {
        let valueArray = [];
        let filterValue = [];
        this.form.booleanValue.map((val) => {
          filterValue.push({
            label: val.label,
            value: val.value
          });
          valueArray.push(val.value);
        });
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          filterValue,
          valueArray,
          this.attributeData.id
        );
      }
      // else if(this.attributeData.tp_type == 'File' || this.attributeData.tp_type == 'image'){
      //     EventBus.$emit("saveFilterValue", this.form.fileValue, this.attributeData.id);
      // }
      else if (this.attributeData.tp_type == "User") {
        let userobj = [];
        let filterValue = [];
        this.form.UserValue.map((val) => {
          filterValue.push({
            name: val.name,
            value: val.member_id
          });
          let jsonStringObj = {
            id: val.member_id,
            value: val.name
          };
          userobj.push(JSON.stringify(jsonStringObj));
        });
        EventBus.$emit(
          "saveFilterValue",
          this.filterItemsIndex,
          filterValue,
          userobj,
          this.attributeData.id
        );
      }
    }
  }
};
</script>
