import { render, staticRenderFns } from "./BracketSection.vue?vue&type=template&id=53a5fbfc&scoped=true"
import script from "./BracketSection.vue?vue&type=script&lang=js"
export * from "./BracketSection.vue?vue&type=script&lang=js"
import style0 from "./BracketSection.vue?vue&type=style&index=0&id=53a5fbfc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a5fbfc",
  null
  
)

export default component.exports