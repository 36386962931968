import uid from "uid";

class FormData {
  inputData(type) {
    let data;
    switch (type) {
      case "input":
        data = {
          name: "Input",
          postName: "",
          inputType: "input",
          icon: "keyboard",
          id: uid(),
          value: "",
          size: "",
          fieldLayout: 'vertical',
          label: {
            english: "Input field label"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          dataAttributes: [],
          isHidden: false,
          isConditional: 0,
          isInValid: null,
          conditions: [],
          multiConditions: [],
          css: null,
          js: null
        };
        return data;

      case "website":
        data = {
          name: "Website",
          postName: "",
          inputType: "website",
          icon: "keyboard",
          id: uid(),
          value: "",
          size: "",
          fieldLayout: 'vertical',
          label: {
            english: "Website"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          dataAttributes: [],
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          multiConditions: [],
          css: null,
          js: null
        };
        return data;

      case "number":
        data = {
          name: "Input",
          inputType: "number",
          icon: "hashtag",
          id: uid(),
          value: "",
          size: "",
          fieldLayout: 'vertical',
          label: {
            english: "Number field label"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          dataAttributes: [],
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          multiConditions: [],
          css: null,
          js: null
        };
        return data;

      case "email":
        data = {
          name: "Email",
          inputType: "email",
          icon: "envelope",
          id: uid(),
          value: "",
          size: "",
          fieldLayout: 'vertical',
          label: {
            english: "Email field label"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          dataAttributes: [],
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          multiConditions: [],
          css: null,
          js: null
        };
        return data;

      case "phone":
        data = {
          name: "Phone",
          inputType: "phone",
          icon: "phone-alt",
          id: uid(),
          value: "",
          size: "",
          fieldLayout: 'vertical',
          label: {
            english: "Phone field label"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          dataAttributes: [],
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          multiConditions: [],
          css: null,
          js: null
        };
        return data;

      case "textarea":
        data = {
          name: "Text Area",
          inputType: "textarea",
          icon: "align-left",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Text area label"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },
          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          multiConditions: [],
          css: null,
          js: null,
          size: "",

          dataAttributes: []
        };
        return data;

      case "select":
        data = {
          name: "Select",
          inputType: "select",
          icon: "check",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Select english Title"
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",

          selected: null,
          options: [
            {
              value: "Select 1",
              text: {
                english: "Select 1"
              },
              score: 0
            },
            {
              value: "Select 2 ",
              text: {
                english: "Select "
              },
              score: 0
            }
          ],
          dataAttributes: [{ value: "option title", key: "data-title" }],
          configuration: {
            isLiveSearchable: false,
            isMultipleSelect: false,
            isClearable: false,
            maxSelectedOptions: 2,
            minSelectedOptins: 1
          },
          enabledConditionalField: 0,
          conditionalShowHiddenOption: 0,
          conditionalFieldIsVisible: 0,
          selectedConditionalFieldValue: null,

          conditionalFieldLabel: {
            english: 'Conditional Field Label'
          },
          conditionalFieldHelperText: {
            english: ""
          },
          conditionalFieldPlaceHolderText: {
            english: ""
          },
          conditionalFieldValidationMessage: {
            english: 'This field is required'
          },
          conditionalFieldIsRequired: 0,
          conditionalFieldMinRows: 1,
          conditionalFieldMaxRows: 1,
          conditionalFieldInputValue: null,
          conditionalFieldPostName: null
        };
        return data;

      case "comboselect":
        data = {
          name: "Combo Select",
          inputType: "comboselect",
          icon: "check",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Combo Select Title"
          },
          labelFirst: {
            english: "Combo Select"
          },
          labelSeconed: {
            english: "Second Select"
          },
          labelThird: {
            english: "Third Select"
          },
          helperTextFirst: {
            english: ""
          },
          helperTextSeconed: {
            english: ""
          },
          helperTextThird: {
            english: ""
          },

          validationMessageFirstSelect: {
            english: "This field is required"
          },

          validationMessageSeconedSelect: {
            english: "This field is required"
          },

          validationMessageThirdSelect: {
            english: "This field is required"
          },
          validation: {
            requiredFirstSelect: 1,
            requiredSeconedSelect: 1,
            requiredThirdSelect: 1,
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",

          selected: [],
          selectedTwo: [],
          selectedThird: [],
          options: [
            {
              value: "Select 1",
              text: {
                english: "Select 1"
              },
              score: 0
            },
            {
              value: "Select 2 ",
              text: {
                english: "Select "
              },
              score: 0
            }
          ],
          firstoptions: [
            {
              value: "Select 1",
              text: {
                english: "Select 1"
              },
              score: 0
            },
            {
              value: "Select 2 ",
              text: {
                english: "Select "
              },
              score: 0
            }
          ],
          secondoptions: [
            {
              value: "Select 1",
              text: {
                english: "Select 1"
              },
              score: 0
            },
            {
              value: "Select 2 ",
              text: {
                english: "Select "
              },
              score: 0
            }
          ],
          thirdoptions: [
            {
              value: "Select 1",
              text: {
                english: "Select 1"
              },
              score: 0
            },
            {
              value: "Select 2 ",
              text: {
                english: "Select "
              },
              score: 0
            }
          ],
          dataAttributes: [{ value: "option title", key: "data-title" }],
          configuration: {
            selectedDataSource: 1,
            isLiveSearchable: false,
            isMultipleSelect: false,
            isClearable: false,
            isLiveSearchableSecond: false,
            isMultipleSelectSecond: false,
            isClearableSecond: false,
            isLiveSearchableThird: false,
            isMultipleSelectThird: false,
            isClearableThird: false
          }
        };
        return data;

      case "comboSelectProgressive":
        data = {
          name: "Combo Select Progressive",
          inputType: "comboSelectProgressive",
          icon: "check",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Combo Select Progressive"
          },
          labelFirst: {
            english: "Division"
          },
          labelSeconed: {
            english: "Sub Division"
          },
          labelThird: {
            english: "Group"
          },
          helperTextFirst: {
            english: ""
          },
          helperTextSeconed: {
            english: ""
          },
          helperTextThird: {
            english: ""
          },

          validationMessageFirstSelect: {
            english: "This field is required"
          },

          validationMessageSeconedSelect: {
            english: "This field is required"
          },

          validationMessageThirdSelect: {
            english: "This field is required"
          },
          validation: {
            requiredFirstSelect: 1,
            requiredSeconedSelect: 1,
            requiredThirdSelect: 1,
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",

          selected: [],
          selectedTwo: [],
          selectedThird: [],
          dataAttributes: [{ value: "option title", key: "data-title" }],
          configuration: {
            selectedDataSource: 1,
            isLiveSearchable: false,
            isMultipleSelect: false,
            isClearable: false,
            isLiveSearchableSecond: false,
            isMultipleSelectSecond: false,
            isClearableSecond: false,
            isLiveSearchableThird: false,
            isMultipleSelectThird: false,
            isClearableThird: false
          }
        };
        return data;

      case "countries":
        data = {
          name: "Select",
          inputType: "countries",
          icon: "check",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Countries"
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",

          selected: null,
          // options: [
          //   {
          //     value: "Pk",
          //     code: "pk",
          //     flagIcon: "pk",
          //     text: {
          //       english: "Pakistan"
          //     },
          //     score: 0
          //   }
          // ],
          dataAttributes: [{ value: "option title", key: "data-title" }],
          configuration: {
            isLiveSearchable: false,
            isMultipleSelect: false,
            isClearable: false,
            maxSelectedOptions: 2,
            minSelectedOptins: 1
          }
        };
        return data;

      case "cpi-countries":
        data = {
          name: "Cpi Countries",
          inputType: "cpi-countries",
          icon: "check",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Countries"
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",

          selected: null,
          // options: [
          //   {
          //     value: "Pk",
          //     code: "pk",
          //     flagIcon: "pk",
          //     text: {
          //       english: "Pakistan"
          //     },
          //     score: 0
          //   }
          // ],
          dataAttributes: [{ value: "option title", key: "data-title" }],
          configuration: {
            isLiveSearchable: false,
            isMultipleSelect: false,
            isClearable: false,
            maxSelectedOptions: 2,
            minSelectedOptins: 1
          }
        };
        return data;

      case "currency":
        data = {
          name: "Currency",
          inputType: "currency",
          icon: "check",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Currency"
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",

          selectedCurrency: null,
          options: [
            {
              value: "Select 1",
              text: {
                english: "Select 1"
              },
              score: 0
            },
            {
              value: "Select 2 ",
              text: {
                english: "Select "
              },
              score: 0
            }
          ],
          dataAttributes: [{ value: "option title", key: "data-title" }],
          configuration: {
            isLiveSearchable: false,
            isMultipleSelect: false,
            isClearable: false,
            maxSelectedOptions: 1,
            minSelectedOptins: 1
          }
        };
        return data;

      case "titles":
        data = {
          name: "Select",
          inputType: "titles",
          icon: "flag",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Title"
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",

          selected: null,
          options: [
            {
              value: "Mr",
              text: {
                english: "Mr"
              },
              score: 0
            },
            {
              value: "Mrs",
              text: {
                english: "Mrs"
              },
              score: 0
            },
            {
              value: "Miss",
              text: {
                english: "Miss"
              },
              score: 0
            },
            {
              value: "dr",
              text: {
                english: "Dr"
              },
              score: 0
            },
            {
              value: "Sir",
              text: {
                english: "Sir"
              },
              score: 0
            },
            {
              value: "Lord",
              text: {
                english: "Lord"
              },
              score: 0
            },
            {
              value: "Dame",
              text: {
                english: "Dame"
              },
              score: 0
            }
          ],
          dataAttributes: [{ value: "option title", key: "data-title" }],
          configuration: {
            isLiveSearchable: false,
            isMultipleSelect: false,
            isClearable: false,
            maxSelectedOptions: 2,
            minSelectedOptins: 1
          }
        };
        return data;

      case "radio":
        data = {
          name: "Radio",
          inputType: "radio",
          icon: "dot-circle",
          id: uid(),
          fieldLayout: 'vertical',
          selected: null,
          label: {
            english: "Radio button label"
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",
          configuration: {
            isStacked: true,
          },
          options: [
            {
              value: "No",
              text: {
                english: "No"
              },
              score: 0
            },
            {
              value: "Yes",
              text: {
                english: "Yes"
              },
              score: 1
            }
          ],
          dataAttributes: [],
          enabledConditionalField: 0,
          conditionalShowHiddenOption: 0,
          conditionalFieldIsVisible: 0,
          selectedConditionalFieldValue: null,
          conditionalRadio: false,
          conditionalInput: false,
          selectedMutiInputs: 1,
          selectedMutiRadios: 1,

          conditionalFieldLabel: {
            english: 'Conditional Field Label'
          },
          conditionalFieldHelperText: {
            english: ""
          },
          conditionalFieldPlaceHolderText: {
            english: ""
          },
          conditionalFieldValidationMessage: {
            english: 'This field is required'
          },
          conditionalFieldIsRequired: 0,
          conditionalFieldMinRows: 1,
          conditionalFieldMaxRows: 1,
          conditionalFieldInputValue: null,
          conditionalFieldPostName: null

        };
        return data;

      case "radioMulti":
        data = {
          name: "Radio",
          inputType: "radioMulti",
          icon: "dot-circle",
          id: uid(),
          fieldLayout: 'vertical',
          selected: null,
          label: {
            english: "Radio button label"
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",
          configuration: {
            isStacked: true,
          },
          options: [
            {
              value: "No",
              text: {
                english: "No"
              },
              score: 0
            },
            {
              value: "Yes",
              text: {
                english: "Yes"
              },
              score: 1
            }
          ],
          dataAttributes: [],
          enabledConditionalField: 0,
          conditionalShowHiddenOption: 0,
          conditionalFieldIsVisible: 0,
          selectedConditionalFieldValue: null,
          conditionalRadio: false,
          conditionalInput: false,
          selectedMutiInputs: 1,
          selectedMutiRadios: 1,

          progressiveOptions: [
            {
              id: uid(),
              label: {
                english: "Multi Fileds Label"
              },
              labelValue: null,
              yesLabel: {
                english: "Yes"
              },
              yesValue: null,
              noLabel: {
                english: "No"
              },
              noValue: null,
              conditionalFieldLabel: {
                english: "Conditional Field Label"
              },
              conditionalFieldValidationMessage: {
                english: "This field is required"
              },
              options: [
                {
                  value: "No",
                  text: {
                    english: "No"
                  },
                  score: 0
                },
                {
                  value: "Yes",
                  text: {
                    english: "Yes"
                  },
                  score: 1
                }
              ],
              type: "radio",
              isConditional: false,
              isRequired: true,
              fieldInputValue: null,
              fieldRadioValue: null,
              conditionalFieldInputValue: null,
              selectedConditionalFieldValue: null
            }
          ],

          conditionalFieldLabel: {
            english: 'Conditional Field Label'
          },
          conditionalFieldHelperText: {
            english: ""
          },
          conditionalFieldPlaceHolderText: {
            english: ""
          },
          conditionalFieldValidationMessage: {
            english: 'This field is required'
          },
          conditionalFieldIsRequired: 0,
          conditionalFieldMinRows: 1,
          conditionalFieldMaxRows: 1,
          conditionalFieldInputValue: null,
          conditionalFieldPostName: null

        };
        return data;


      case "summary":
        data = {
          id: uid(),
          inputType: "summary",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          selectedLayout: 'l1',
          label: {
            english: "Advanced Table"
          },
          description: {
            english: "Please selecr atleast one"
          },
          validationMessage: {
            english: "This field is required"
          },

          selectedTitle: null,
          postData: [],
          tableHeaders: [],
          tableRowsItems: [],
          tableColsItems: [],
          enableNumbers: false,
          isRequired: false,

          headingCol: {
            english: "Column Title"
          },
          headingRow: {
            english: "Row Title"
          }

        };
        return data;


      case "checkbox":
        data = {
          name: "Checkbox",
          inputType: "checkbox",
          icon: "check-square",
          id: uid(),
          fieldLayout: 'vertical',
          label: {
            english: "Checkbox label "
          },
          helperText: {
            english: ""
          },

          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 1,
            minSelect: null,
            maxSelect: null,
          },

          tooltipText: {
            english: "Tooltip message"
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          conditionalFields: [],
          sectionConditions: [],
          css: null,
          js: null,
          size: "",
          selected: null,
          configuration: {
            isStacked: true,
          },
          options: [
            {
              value: "Checkbox 1",
              text: {
                english: "Checkbox 1"
              },
              score: 0
            },
            {
              value: "Checkbox 2",
              text: {
                english: "Checkbox 2"
              },
              score: 0
            }
          ],
          dataAttributes: [],
          enabledConditionalField: 0,
          conditionalShowHiddenOption: 0,
          conditionalFieldIsVisible: 0,
          selectedConditionalFieldValue: null,

          conditionalFieldLabel: {
            english: 'Conditional Field Label'
          },
          conditionalFieldHelperText: {
            english: ""
          },
          conditionalFieldPlaceHolderText: {
            english: ""
          },
          conditionalFieldValidationMessage: {
            english: 'This field is required'
          },
          conditionalFieldIsRequired: 0,
          conditionalFieldMinRows: 1,
          conditionalFieldMaxRows: 1,
          conditionalFieldInputValue: null,
          conditionalFieldPostName: null
        };
        return data;

      case "date":
        data = {
          name: "Date",
          inputType: "date",
          icon: "calendar-alt",
          id: uid(),
          format: "default",
          size: "",
          fieldLayout: 'vertical',
          label: {
            english: "Date input label"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },
          validation: {
            required: 1
          },
          validationMessage: {
            english: "This field is required"
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          multiConditions: [],
          css: null,
          js: null
        };
        return data;

      case "signature":
        data = {
          name: "Signature",
          inputType: "signature",
          icon: "signature",
          id: uid(),
          fieldLayout: 'vertical',
          data: {
            signatureValue: null,
            nameValue: null,
            dateValue: null,
          },
          label: {
            english: "Signature Title"
          },
          signatureTitle: {
            english: "Signature Title"
          },
          signatureDescription: {
            english: "Signature description"
          },
          acceptTermsText: {
            english: "Accept terms checkbox"
          },
          acceptTermsDescriptionText: {
            english: "Accept terms Description text"
          },
          printNameLabel: {
            english: "Enter your name"
          },
          printNamePlaceholder: {
            english: "eg John Smith"
          },
          positionRoleLabel: {
            english: "Enter your Postion"
          },
          positionRolePlaceholder: {
            english: "Postion / Role"
          },
          dateFieldLabel: {
            english: "Date"
          },
          signInTheAreaBelowLabel: {
            english: "Sign in the area below"
          },
          dateFieldPlaceholder: {
            english: "12/12/19"
          },

          signatureDescriptionIsDisabled: 0,
          signatureTitleTextIsDisabled: 0,
          acceptTermsTextIsDisabled: 0,
          acceptTermsDescriptionTextIsDisabled: 0,
          printNameLabelIsDisabled: 0,
          positionRoleLabelIsDisabled: 0,
          dateFieldLabelIsDisabled: 0,
          signInTheAreaBelowLabelIsDisabled: 0,

          signature: null,
          validation: {
            required: 1,
            requiredName: 1,
            requiredPositionRole: 1,
            requiredDate: 1,
            requiredTerms: 1,
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          css: null,
          js: null
        };
        return data;

      case "file":
        data = {
          name: "Upload",
          inputType: "file",
          icon: "file-upload",
          id: uid(),

          label: {
            english: "Default file title"
          },
          uploadTitleTextIsDisabled: 0,

          fileDescription: {
            english: "Default file description"
          },
          uploadDescriptionTextIsDisabled: 0,
          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 0
          },
          configuration: {
            isMultipleUpload: false,
            selectedFormats: [],
            maxUploads: 2,
            maxfileSize: 1
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          css: null,
          js: null
        };
        return data;

      case "rating":
        data = {
          name: "Rating",
          inputType: "rating",
          icon: "star"
        };
        return data;

      case "button":
        data = { name: "Button", inputType: "button", icon: "" };
        return data;

      case "wyswg-editor":
        data = {
          name: "What you see what you get editor",
          inputType: "wyswg-editor",
          icon: "image",
          id: uid(),

          label: {
            english: "What you see what you get editor"
          },
          isHidden: false
        };
        return data;

      case "question":
        data = {
          name: "Question",
          inputType: "question",
          icon: "question-circle",
          id: uid(),

          label: {
            english: "Question"
          },
          questionTitle: {
            english: "Question Title"
          },
          children: [],
          isInValid: null,
          isHidden: false
        };
        return data;

      case "exam-question":
        data = {
          name: "Question",
          inputType: "exam-question",
          icon: "question-circle",
          id: uid(),

          label: {
            english: "Exam Question"
          },
          questionType: 'multiple-choice',
          questionTimeLimit: null,
          questionText: {
            english: "Exam Question Title?"
          },
          questionLevel: 1,
          questionImage: null,
          questionScore: 1,
          questionAnswerOrderType: 1,
          questionMinimumAnswers: 1,
          questionMaximumAnswers: 1,
          style: {},
          isCompulsory: 0,
          questionAnswerInputType: 1,
          answers: [],
          children: [],
          isInValid: null,
          isHidden: false
        };
        return data;

      case "sum":
        data = {
          name: "Auto Sum",
          inputType: "sum",
          icon: "question-circle",
          id: uid(),
          totalSum: 0,
          label: {
            english: "Auto Sum"
          },
          options: [],
          isInValid: null,
          isHidden: false
        };
        return data;

      case "range":
        data = {
          name: "Range",
          inputType: "range",
          icon: "arrows-alt-h",
          id: uid(),
          fieldLayout: 'vertical',
          totalSum: 0,
          label: {
            english: "Range Title"
          },
          helperText: {
            english: " "
          },
          max: 100,
          min: 0,
          isInValid: null,
          isHidden: false
        };
        return data;

      case "carousel":
        data = {
          name: "Carousel",
          inputType: "carousel",
          icon: "image",
          id: uid(),
          label: {
            english: "Carousel title"
          },
          interval: 0,
          imgWidth: 1024,
          imgHeight: 250,
          controls: false,
          controlsOut: true,
          indicators: true,
          isInValid: null,
          isHidden: false,
          slideData: [{
            caption: { english: "Blank Slide" },
            text: { english: "Go to settings to configure slide show" },
            imgBlank: true,
            imgSrc: null,
            imgAlt: "Blank image Alt",
            imgName: ''
          }]
        };
        return data;

      case "pdf":
        data = {
          name: "Upload PDF",
          inputType: "pdf",
          icon: "file-upload",
          id: uid(),
          label: {
            english: "Default Title"
          },
          file: {
            english: null
          },
          fileName: {
            english: null
          },
          files: [],
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          css: null,
          js: null
        };
        return data;

      case "downloadPDF":
        data = {
          name: "Download PDF",
          inputType: "downloadPDF",
          icon: "file-download",
          id: uid(),
          selected: null,
          linkFormId: null,
          label: {
            english: "Default PDF label"
          },
          buttonLabel: {
            english: "Default button label"
          },
          checkboxLabel: {
            english: "Default checkbox label"
          },
          helperText: {
            english: "Default helper text"
          },
          validationMessage: {
            english: "This field is required"
          },
          validation: {
            required: 0
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
        };
        return data;
    }
  }

  // ------------------------for html tags
  htmlData(type) {
    let data;
    switch (type) {
      case "video":
        data = {
          name: "Video",
          inputType: "video",
          icon: "video",
          id: uid(),

          label: {
            english: "Upload Video"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },

          validationMessage: {
            english: "video is required"
          },
          validation: {
            required: 0
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isHidden: false,
          isInValid: null,
          isConditional: 0,
          conditions: [],
          css: null,
          js: null
        };
        return data;

      case "image":
        data = {
          name: "Image",
          inputType: "image",
          icon: "image",
          id: uid(),
          imageData: {},
          label: {
            english: "Select Image"
          },
          helperText: {
            english: ""
          },
          placeholder: {
            english: ""
          },
          validationMessage: {
            english: "Image is required"
          },
          validation: {
            required: 0
          },
          tooltipText: {
            english: "Tooltip message"
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          css: null,
          js: null
        };
        return data;

      case "paragraph":
        data = {
          name: "Paragraph",
          inputType: "paragraph",
          icon: "image",
          id: uid(),

          label: {
            english: "Paragraph text"
          },
          isInValid: null,
          isHidden: false
        };
        return data;

      case "link":
        data = {
          name: "Hyperlink",
          inputType: "link",
          icon: "pen",
          id: uid(),
          label: {
            english: "Hyperlink Tag"
          },
          isInValid: null,
          isHidden: false
        };
        return data;

      case "h1":
        data = {
          name: "H1",
          inputType: "h1",
          icon: "image",
          id: uid(),
          label: {
            english: "H1 Tag"
          },
          isInValid: null,
          isHidden: false
        };
        return data;

      case "h2":
        data = {
          name: "H2",
          inputType: "h2",
          icon: "image",
          id: uid(),
          label: {
            english: "H2 Tag"
          },
          isInValid: null,
          isHidden: false
        };
        return data;

      case "h3":
        data = {
          name: "H3",
          inputType: "h3",
          icon: "image",
          id: uid(),
          label: {
            english: "H3 Tag"
          },
          isInValid: null,
          isHidden: false
        };
        return data;

      case "hr":
        data = {
          name: "Divider",
          inputType: "hr",
          icon: "image",
          id: uid(),
          label: {
            english: "Hr Tag"
          },
          isInValid: null,
          isHidden: false,
          isConditional: 0,
          conditions: [],
          css: null,
          js: null
        };
        return data;

      case "ulli":
        data = {
          name: "List item",
          inputType: "ulli",
          icon: "image",
          id: uid(),
          label: {
            english: "UL li Tag"
          },
          title: {
            english: "List Title"
          },
          description: {
            english: "List Description"
          },
          isInValid: null,
          isHidden: false,
          selectedListType: 1,
          selectedListStyle: 'text-left',
          items: [
            {
              value: "Foo",
              message: {
                english: "Foo"
              }
            },
            {
              value: "Bar",
              message: {
                english: "Bar"
              }
            }
          ],
        };
        return data;
    }
  }

  // ------------------------for html tags
  workflowData(type) {
    let data;
    switch (type) {
      case "personal-details":
        data = {
          id: uid(),
          inputType: "personal-details",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          label: {
            english: "Personal Details"
          },
          description: {
            english: "Please enter at least one Personal Details"
          },
          selectedTitle: null,
          optionsTitle: [
            { value: "1", text: "Mr" },
            { value: "2", text: "Mrs" },
            { value: "3", text: "Miss" },
            { value: "4", text: "Ms" },
            { value: "5", text: "Dr" },
            { value: "6", text: "Sir" },
            { value: "7", text: "Madam" },
            { value: "8", text: "Lord" },
            { value: "9", text: "Dame" }
          ],

          selectedMaritalStatus: null,
          optionsMaritalStatus: [
            { value: "Single", text: "Single" },
            { value: "Married", text: "Married" },
            { value: "Separated", text: "Separated" },
            { value: "Divorced", text: "Divorced" },
            { value: "Other", text: "Other" }
          ],
          // Label

          selectedTitleLabel: {
            english: "Title"
          },
          foreNameLabel: {
            english: "First Name"
          },
          middelNamesLabel: {
            english: "Middel Name"
          },
          lastNameLabel: {
            english: "Last Name"
          },
          knownAsLabel: {
            english: "Known As"
          },
          dateOfBirthLabel: {
            english: "Date of birth"
          },
          selectedMaritalStatusLabel: {
            english: "Marital Status"
          },
          mobileContactNumberLabel: {
            english: "Contact Number"
          },
          personalEmailAddressLabel: {
            english: "Email Address"
          },
          linkedInProfileLabel: {
            english: "LinkedIn Profile"
          },

          // Placeholder

          foreNamePlaceholder: {
            english: ""
          },
          middelNamesPlaceholder: {
            english: ""
          },
          lastNamePlaceholder: {
            english: ""
          },
          knownAsPlaceholder: {
            english: ""
          },
          mobileContactNumberPlaceholder: {
            english: ""
          },
          personalEmailAddressPlaceholder: {
            english: ""
          },
          linkedInProfilePlaceholder: {
            english: ""
          },

          // helper Text

          selectedTitleHelperText: {
            english: ""
          },
          foreNameHelperText: {
            english: ""
          },
          middelNamesHelperText: {
            english: ""
          },
          lastNameHelperText: {
            english: ""
          },
          knownAsHelperText: {
            english: ""
          },
          dateOfBirthHelperText: {
            english: ""
          },
          selectedMaritalStatusHelperText: {
            english: ""
          },
          mobileContactNumberHelperText: {
            english: ""
          },
          personalEmailAddressHelperText: {
            english: ""
          },
          linkedInProfileHelperText: {
            english: ""
          },

          // Validation Text

          selectedTitleValidationMessage: {
            english: "This field is required"
          },
          foreNameValidationMessage: {
            english: "This field is required"
          },
          middelNamesValidationMessage: {
            english: "This field is required"
          },
          lastNameValidationMessage: {
            english: "This field is required"
          },
          knownAsValidationMessage: {
            english: "This field is required"
          },
          dateOfBirthValidationMessage: {
            english: "This field is required"
          },
          selectedMaritalStatusValidationMessage: {
            english: "This field is required"
          },
          mobileContactNumberValidationMessage: {
            english: "This field is required"
          },
          personalEmailAddressValidationMessage: {
            english: "This field is required"
          },
          linkedInProfileValidationMessage: {
            english: "This field is required"
          },

          validation: {
            isRequireSelectedTitle: true,
            isRequireForeName: true,
            isRequireMiddelNames: true,
            isRequireLastName: true,
            isRequireKnownAs: true,
            isRequireDateOfBirth: true,
            isRequireSelectedMaritalStatus: true,
            isRequireMobileContactNumber: true,
            isRequirePersonalEmailAddress: true,
            isRequireLinkedInProfile: true
          },
          advance: {
            titleDisabled: false,
            foreNameDisabled: false,
            middelNameDisabled: false,
            lastNameDisabled: false,
            knownAsDisabled: false,
            dateOfBirthDisabled: false,
            maritalStatusDisabled: false,
            contactNumberDisabled: false,
            emailAddressDisabled: false,
            linkedInProfileDisabled: false
          }
        };
        return data;

      case "principal-contact":
        data = {
          id: uid(),
          inputType: "principal-contact",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          label: {
            english: "Principal Contact"
          },
          description: {
            english: "Please enter at least one principal contact"
          },

          selectedTitle: null,
          postData: [],
          optionsTitle: [
            { value: "1", text: "Mr" },
            { value: "2", text: "Mrs" },
            { value: "3", text: "Miss" },
            { value: "4", text: "Ms" },
            { value: "5", text: "Dr" },
            { value: "6", text: "Sir" },
            { value: "7", text: "Madam" },
            { value: "8", text: "Lord" },
            { value: "9", text: "Dame" }
          ],
          foreNameLabel: {
            english: "First Name"
          },
          foreNameHelperText: {
            english: ""
          },

          foreNameValidationMessage: {
            english: "This field is required"
          },
          foreNamePlaceholderText: {
            english: ""
          },
          lastNameLabel: {
            english: "Last Name"
          },

          lastNameHelperText: {
            english: ""
          },

          lastNameValidationMessage: {
            english: "This field is required"
          },

          lastNamePlaceholderText: {
            english: ""
          },

          contactNumberLabel: {
            english: "Contact number"
          },
          contactNumberHelperText: {
            english: ""
          },
          contactNumberValidationMessage: {
            english: "This field is required"
          },
          contactNumberPlaceholderText: {
            english: ""
          },
          emailAddressLabel: {
            english: "Email address"
          },
          emailAddressHelperText: {
            english: ""
          },

          emailAddressValidationMessage: {
            english: "This field is required"
          },
          emailAddressPlaceholderText: {
            english: ""
          },
          rolePositionLabel: {
            english: "Role or position within company"
          },
          rolePositionHelperText: {
            english: ""
          },

          rolePositionValidationMessage: {
            english: "This field is required"
          },

          rolePositionPlaceholderText: {
            english: ""
          },
          linkedInProfileLabel: {
            english: "LinkedIn Profile"
          },
          linkedInProfileHelperText: {
            english: ""
          },
          linkedInProfileValidationMessage: {
            english: "This field is required"
          },
          linkedInProfilePlaceholderText: {
            english: ""
          },
          validation: {
            isRequireForename: true,
            isRequireLastname: true,
            isRequireContact: true,
            isRequireEmail: true,
            isRequireRole: true,
            isRequireLinkedin: true
          },
          advance: {
            forenameDisabled: false,
            lastnameDisabled: false,
            contactDisabled: false,
            emailDisabled: false,
            roleDisabled: false,
            linkedinDisabled: false
          }

        };
        return data;

      case "corporate-shareholders":
        data = {
          id: uid(),
          inputType: "corporate-shareholders",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          label: {
            english: "Corporate Share Holders"
          },
          description: {
            english: "Please enter at least one Corporate Share Holders"
          },

          selectedTitle: null,
          postData: [],
          entityNameLabel: {
            english: "Entity Name"
          },
          entityNameHelperText: {
            english: ""
          },

          entityNameValidationMessage: {
            english: "This field is required"
          },
          entityNamePlaceholderText: {
            english: ""
          },

          dateOfRegistrationLabel: {
            english: "Date of registration"
          },
          dateOfRegistrationHelperText: {
            english: ""
          },
          dateOfRegistrationValidationMessage: {
            english: "This field is required"
          },

          countryOfRegistrationLabel: {
            english: "Country of Registration"
          },
          countryOfRegistrationHelperText: {
            english: ""
          },
          countryOfRegistrationValidationMessage: {
            english: "This field is required"
          },

          percentageOwnedLabel: {
            english: "Percentage Owned"
          },
          percentageOwnedHelperText: {
            english: ""
          },
          percentageOwnedValidationMessage: {
            english: "This field is required"
          },
          percentageOwnedPlaceholderText: {
            english: ""
          },

          registrationNumberLabel: {
            english: "Registration Number"
          },
          registrationNumberHelperText: {
            english: ""
          },
          registrationNumberValidationMessage: {
            english: "This field is required"
          },
          registrationNumberPlaceholderText: {
            english: ""
          },
          validation: {
            isRequireEntityName: true,
            isRequireDateOfRegistration: true,
            isRequirePercentageOwned: true,
            isRequireRegistrationNumber: true,
            isRequireCountryOfRegistration: true
          },
          advance: {
            entityNameDisabled: false,
            dateOfRegistrationDisabled: false,
            registrationNumberDisabled: false,
            percentageOwnedDisabled: false,
            countryOfRegistrationDisabled: false
          }

        };
        return data;

      case "individual-shareholders":
        data = {
          id: uid(),
          inputType: "individual-shareholders",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          label: {
            english: "Individual Share Holders"
          },
          description: {
            english: "Please enter at least one Individual Share Holders"
          },

          selectedTitle: null,
          postData: [],
          firstNameLabel: {
            english: "First Name"
          },
          firstNameHelperText: {
            english: ""
          },

          firstNameValidationMessage: {
            english: "This field is required"
          },
          firstNamePlaceholderText: {
            english: ""
          },
          lastNameLabel: {
            english: "Last Name"
          },

          lastNameHelperText: {
            english: ""
          },

          lastNameValidationMessage: {
            english: "This field is required"
          },

          lastNamePlaceholderText: {
            english: ""
          },

          dateOfBirthLabel: {
            english: "Date of birth"
          },
          dateOfBirthHelperText: {
            english: ""
          },
          dateOfBirthValidationMessage: {
            english: "This field is required"
          },
          percentageOwnedLabel: {
            english: "Percentage Owned"
          },
          percentageOwnedHelperText: {
            english: ""
          },
          percentageOwnedValidationMessage: {
            english: "This field is required"
          },
          percentageOwnedPlaceholderText: {
            english: ""
          },
          countryOfBirthLabel: {
            english: "Country of birth"
          },
          countryOfBirthHelperText: {
            english: ""
          },
          countryOfBirthValidationMessage: {
            english: "This field is required"
          },
          passportNumberLabel: {
            english: "Passport Number"
          },
          passportNumberHelperText: {
            english: ""
          },
          passportNumberValidationMessage: {
            english: "This field is required"
          },
          passportNumberPlaceholderText: {
            english: ""
          },
          validation: {
            isRequireFirstName: true,
            isRequireLastName: true,
            isRequireDateOfBirth: true,
            isRequirePercentageOwned: true,
            isRequirePassportNumber: true,
            isRequireCountryOfBirth: true
          },
          advance: {
            firstNameDisabled: false,
            lastNameDisabled: false,
            dateOfBirthDisabled: false,
            percentageOwnedDisabled: false,
            countryOfBirthDisabled: false,
            passportNumberDisabled: false
          }

        };
        return data;

      case "company-officers":
        data = {
          id: uid(),
          inputType: "company-officers",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          label: {
            english: "Company Officers"
          },
          description: {
            english: "Please enter at least one Individual Company Officers"
          },

          selectedTitle: null,
          postData: [],
          fullNameLabel: {
            english: "Full Name"
          },
          fullNameHelperText: {
            english: ""
          },

          fullNameValidationMessage: {
            english: "This field is required"
          },
          fullNamePlaceholderText: {
            english: ""
          },
          officerRoleLabel: {
            english: "Officer role"
          },

          officerRoleHelperText: {
            english: ""
          },

          officerRoleValidationMessage: {
            english: "This field is required"
          },

          officerRolePlaceholderText: {
            english: ""
          },

          dateAppointedLabel: {
            english: "Date appointed"
          },
          dateAppointedHelperText: {
            english: ""
          },
          dateAppointedValidationMessage: {
            english: "This field is required"
          },
          validation: {
            isRequireFullName: true,
            isRequireDateAppointed: true,
            isRequireOfficerRole: true
          },
          advance: {
            fullNameDisabled: false,
            dateAppointedDisabled: false,
            officerRoleDisabled: false
          }
        };
        return data;

      case "trade-reference":
        data = {
          id: uid(),
          inputType: "trade-reference",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          label: {
            english: "Trade References"
          },
          description: {
            english: "Please enter at least one Trade References"
          },

          selectedTitle: null,
          postData: [],
          refereeNameLabel: {
            english: "Referee Name"
          },
          refereeNameHelperText: {
            english: ""
          },

          refereeNameValidationMessage: {
            english: "This field is required"
          },
          refereeNamePlaceholderText: {
            english: ""
          },

          refereePositionRoleLabel: {
            english: "Referee Position"
          },
          refereePositionRoleHelperText: {
            english: ""
          },

          refereePositionRoleValidationMessage: {
            english: "This field is required"
          },
          refereePositionRolePlaceholderText: {
            english: ""
          },

          refereeCompanyLabel: {
            english: "Referee Company Name"
          },
          refereeCompanyHelperText: {
            english: ""
          },

          refereeCompanyValidationMessage: {
            english: "This field is required"
          },
          refereeCompanyPlaceholderText: {
            english: ""
          },

          refereeEmailLabel: {
            english: "Referee Email Address"
          },
          refereeEmailHelperText: {
            english: ""
          },

          refereeEmailValidationMessage: {
            english: "This field is required"
          },
          refereeEmailPlaceholderText: {
            english: ""
          },

          refereePhoneLabel: {
            english: "Referee Contact number"
          },
          refereePhoneHelperText: {
            english: ""
          },

          refereePhoneValidationMessage: {
            english: "This field is required"
          },
          refereePhonePlaceholderText: {
            english: ""
          },

          refereeWebsiteLabel: {
            english: "Company Website"
          },
          refereeWebsiteHelperText: {
            english: ""
          },

          refereeWebsiteValidationMessage: {
            english: "This field is required"
          },
          refereeWebsitePlaceholderText: {
            english: ""
          },

          refereeNatureOfRelationshipLabel: {
            english: "Nature of relationship"
          },
          refereeNatureOfRelationshipHelperText: {
            english: ""
          },

          refereeNatureOfRelationshipValidationMessage: {
            english: "This field is required"
          },
          refereeNatureOfRelationshipPlaceholderText: {
            english: ""
          },

          refereeLengthOfRelationLabel: {
            english: "Length of relationship"
          },
          refereeLengthOfRelationHelperText: {
            english: ""
          },

          refereeLengthOfRelationValidationMessage: {
            english: "This field is required"
          },
          refereeLengthOfRelationPlaceholderText: {
            english: ""
          },
          validation: {
            isRequireRefereeName: true,
            isRequireRefereePositionRole: true,
            isRequireRefereeCompany: true,
            isRequireRefereeEmail: true,
            isRequireRefereePhone: true,
            isRequireRefereeWebsite: true,
            isRequireRefereeNatureOfRelationship: true,
            isRequireRefereeLengthOfRelation: false
          },
          advance: {
            refereeNameDisabled: false,
            refereePositionRoleDisabled: false,
            refereeCompanyDisabled: false,
            refereeEmailDisabled: false,
            refereePhoneDisabled: false,
            refereeWebsiteDisabled: false,
            refereeNatureOfRelationshipDisabled: false,
            refereeLengthOfRelationDisabled: false
          }
        };
        return data;

      case "address-corporate":
        data = {
          id: uid(),
          inputType: "address-corporate",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          postData: [],
          label: {
            english: "Corporate Address"
          },
          checkBoxLabel: {
            english: "Trading address is same as registered address"
          },
          registeredLabel: {
            english: "Registered / Incorporation Address"
          },
          registeredDescription: {
            english: "Registered / Incorporation Address Descrption"
          },

          tradingLabel: {
            english: "Trading Address"
          },
          tradingDescription: {
            english: "Trading Address Descrption"
          },

          // registered address

          // Registered Address Label

          registeredAddressLine1Label: {
            english: "Address"
          },
          registeredAddressLine2Label: {
            english: "Address 2"
          },
          registeredAddressTownLabel: {
            english: "Town"
          },
          registeredAddressCityStateLabel: {
            english: "City/State"
          },
          registeredAddressUsStateLabel: {
            english: "States"
          },
          registeredAddressCountryLabel: {
            english: "Country"
          },
          registeredAddressPostalZipCodeLabel: {
            english: "Postal / Zip Code"
          },

          // Registered Address Placeholder

          registeredAddressLine1Placeholder: {
            english: ""
          },
          registeredAddressLine2Placeholder: {
            english: ""
          },
          registeredAddressTownPlaceholder: {
            english: ""
          },
          registeredAddressCityStatePlaceholder: {
            english: ""
          },
          registeredAddressPostalZipCodePlaceholder: {
            english: ""
          },

          // helper Text

          registeredAddressLine1HelperText: {
            english: ""
          },
          registeredAddressLine2HelperText: {
            english: ""
          },
          registeredAddressTownHelperText: {
            english: ""
          },
          registeredAddressCityStateHelperText: {
            english: ""
          },
          registeredAddressUsStateHelperText: {
            english: ""
          },
          registeredAddressCountryHelperText: {
            english: ""
          },
          registeredAddressPostalZipCodeHelperText: {
            english: ""
          },

          // Validation Text

          registeredAddressLine1ValidationMessage: {
            english: "This field is required"
          },
          registeredAddressLine2ValidationMessage: {
            english: "This field is required"
          },
          registeredAddressTownValidationMessage: {
            english: "This field is required"
          },
          registeredAddressCityStateValidationMessage: {
            english: "This field is required"
          },
          registeredAddressUsStateValidationMessage: {
            english: "This field is required"
          },
          registeredAddressCountryValidationMessage: {
            english: "This field is required"
          },
          registeredAddressPostalZipCodeValidationMessage: {
            english: "This field is required"
          },

          tradingAndRegisteredAddressIsSame: 0,

          // trading address

          tradingAddressIsrequired: 1,

          tradingAddressLine1Label: {
            english: "Address 1"
          }
          ,
          tradingAddressLine2Label: {
            english: "Address 2"
          }
          ,
          tradingAddressTownLabel: {
            english: "Town"
          },
          tradingAddressCityStateLabel: {
            english: "City/State"
          },
          tradingAddressUsStateLabel: {
            english: "States"
          },
          tradingAddressCountryLabel: {
            english: "Country"
          },
          tradingAddressPostalZipCodeLabel: {
            english: "Postal Zip Address"
          },

          // trading place holderss

          tradingAddressLine1Placeholder: {
            english: ""
          }
          ,
          tradingAddressLine2Placeholder: {
            english: ""
          }
          ,
          tradingAddressTownPlaceholder: {
            english: ""
          },
          tradingAddressCityStatePlaceholder: {
            english: ""
          },
          tradingAddressPostalZipCodePlaceholder: {
            english: ""
          },

          // helper Text

          tradingAddressAddressLine1HelperText: {
            english: ""
          }
          ,
          tradingAddressAddressLine2HelperText: {
            english: ""
          }
          ,
          tradingAddressAddressTownHelperText: {
            english: ""
          },
          tradingAddressAddressCityStateHelperText: {
            english: ""
          },
          tradingAddressUsStateHelperText: {
            english: ""
          },
          tradingAddressAddressCountryHelperText: {
            english: ""
          },
          tradingAddressAddressPostalZipCodeHelperText: {
            english: ""
          },

          // Validation Text

          tradingAddressAddressLine1ValidationMessage: {
            english: "This field is required"
          }
          ,
          tradingAddressAddressLine2ValidationMessage: {
            english: "This field is required"
          }
          ,
          tradingAddressAddressTownValidationMessage: {
            english: "This field is required"
          },
          tradingAddressAddressCityStateValidationMessage: {
            english: "This field is required"
          },
          tradingAddressUsStateValidationMessage: {
            english: "This field is required"
          },
          tradingAddressAddressCountryValidationMessage: {
            english: "This field is required"
          },
          tradingAddressAddressPostalZipCodeValidationMessage: {
            english: "This field is required"
          },
          validation: {
            isRequireRegistrationFormCountry: true,
            isRequireRegistrationFormCity: true,
            isRequireRegistrationFormUsState: true,
            isRequireRegistrationFormAddress1: true,
            isRequireRegistrationFormAddress2: true,
            isRequireRegistrationFormTown: true,
            isRequireRegistrationFormZip: true,

            isRequireTradingFormCountry: false,
            isRequireTradingFormCity: false,
            isRequireTradingFormUsState: false,
            isRequireTradingFormAddress1: false,
            isRequireTradingFormAddress2: false,
            isRequireTradingFormTown: false,
            isRequireTradingFormZip: false,
          },
          advance: {
            registeredFormCountryDisabled: false,
            registeredFormCityDisabled: false,
            registeredFormAddress1Disabled: false,
            registeredFormAddress2Disabled: false,
            registeredFormTownDisabled: false,
            registeredFormZipDisabled: false,

            tradingFormCountryDisabled: false,
            tradingFormCityDisabled: false,
            tradingFormAddress1Disabled: false,
            tradingFormAddress2Disabled: false,
            tradingFormTownDisabled: false,
            tradingFormZipDisabled: false
          }
        };
        return data;

      case "address-personal":
        data = {
          id: uid(),
          inputType: "address-personal",
          name: "",
          value: "",
          isInValid: null,
          size: "",
          label: {
            english: "Personal Address"
          },
          description: {
            english: "Please enter at least one Personal Address"
          },

          selectedTitle: null,
          postData: [],
          addressLabel: {
            english: "Address"
          },
          addressHelperText: {
            english: ""
          },
          addressValidationMessage: {
            english: "This field is required"
          },
          addressPlaceholderText: {
            english: ""
          },

          addressTwoLabel: {
            english: "Address 2"
          },
          addressTwoHelperText: {
            english: ""
          },
          addressTwoValidationMessage: {
            english: "This field is required"
          },
          addressTwoPlaceholderText: {
            english: ""
          },

          townLabel: {
            english: "Town"
          },
          townHelperText: {
            english: ""
          },
          townValidationMessage: {
            english: "This field is required"
          },
          townPlaceholderText: {
            english: ""
          },

          cityStateLabel: {
            english: "City"
          },
          cityStateHelperText: {
            english: ""
          },
          cityStateValidationMessage: {
            english: "This field is required"
          },
          cityStatePlaceholderText: {
            english: ""
          },

          zipCodeLabel: {
            english: "Postal / Zip Code"
          },
          zipCodeHelperText: {
            english: ""
          },
          zipCodeValidationMessage: {
            english: "This field is required"
          },
          zipCodePlaceholderText: {
            english: ""
          },

          countryLabel: {
            english: "Country"
          },
          countryHelperText: {
            english: ""
          },
          countryValidationMessage: {
            english: "This field is required"
          },
          validation: {
            isRequireAddress: true,
            isRequireAddressTwo: true,
            isRequireTown: true,
            isRequireCityState: true,
            isRequireZipCode: true,
            isRequireCountry: true
          },
          advance: {
            addressDisabled: false,
            cityStateDisabled: false,
            townDisabled: false,
            addressTwoDisabled: false,
            zipCodeDisabled: false,
            countryDisabled: false
          }
        };
        return data;


    }
  }
}
export default new FormData();
