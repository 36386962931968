import { makeUUID, getSelectedOption } from "@/utils";
import { endOperations } from "@/constants";

export class Round {
  endOperationType = endOperations.ROUND;

  id = makeUUID();

  component = {};

  componentOptions = {
    decimalOptions: []
  };

  constructor({ component = {}, componentOptions = {} } = {}) {
    this.component = component;
    this.componentOptions = componentOptions;
  }

  setComponent(component) {
    this.component = component;
  }

  setComponentOptions(componentOptions) {
    this.componentOptions = componentOptions;
  }

  getSelectedDecimalValue() {
    return getSelectedOption(this.componentOptions?.decimalOptions);
  }
}
