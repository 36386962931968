<!-- Enable on platform modal -->
<template>
  <b-modal
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    title="Enable on platform"
    v-model="enablePlateformModal"
  >
    <b-overlay :show="isLoadingData">
      Would you like us to contact you with further information on this feature?
      If yes a representative will be in touch to discuss adding this feature to
      your platform.
    </b-overlay>
    <template v-slot:modal-footer>
      <b-btn size="sm" variant="outline-danger" @click="closeModal"
        >Cancel</b-btn
      >
      <b-btn size="sm" variant="outline-success" @click="sendEmail">Yes</b-btn>
    </template>
  </b-modal>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { mapState } from "vuex";

export default {
  name: "EnablePlatformModal",
  data() {
    return {
      enablePlateformModal: false,
      actionType: null,
      isLoadingData: false
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  created() {
    EventBus.$on("onEnablePlatformModal", (value) => {
      this.actionType = value;
      this.enablePlateformModal = true;
    });
  },
  methods: {
    sendEmail() {
      let array = {
        action_name: this.actionType.toString()
      };
      let formData = {
        email_template_name: "Enable Action Request Email",
        company_id: this.companyId,
        email_address: "support@ethixbase.com",
        additional_data: array,
        member_id: this.userId
      };
      this.isLoadingData = true;
      let result = HTTP(
        "post",
        "v1/dynamic-templates/send-email",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          if (response.data.success == 1) {
            this.$bvToast.toast("Email has been sent successfully", {
              variant: "success",
              autoHideDelay: 5000,
              appendToast: false
            });
          }
          this.enablePlateformModal = false;
          this.isLoadingData = false;
        })
        .catch((error) => {
          this.enablePlateformModal = false;
          this.isLoadingData = false;
          return [error];
        });
    },
    closeModal() {
      this.enablePlateformModal = false;
    }
  }
};
</script>
>
