import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import {
  actionKeys,
  variableAssignmentOptions,
  variableAssignmentType,
  variableDisplayTextToTypeMap,
  variableType,
  variableTypeToDisplayTextMap,
  variableTypeToInputTypeMap,
  variableValueBooleanOptions,
  erafCasting,
  operations
} from "@/constants";
import Input from "@/molecules/Input/Input";
import Select from "@/molecules/Select/Select";
import { SetToBlockClass } from "@/molecules/SetToBlock/SetToBlock.class";
import { getSelectedOption } from "@/utils";
import { cloneDeep, isEmpty } from "lodash";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";

export default {
  data() {
    return {
      setToBlockList: []
    };
  },
  methods: {
    getSharedComponentOptions(id) {
      return {
        name: "setOptionTo",
        isLabelHidden: true,
        label: "Set value to:",
        id: `setToValue-${id}`
      };
    },
    getToBlockToUpdate(id) {
      return this.setToBlockList.find((setToBlock) => setToBlock.id === id);
    },
    getListBasedOnType({
      stepIndex = 0,
      selectedLeftValueType = "",
      selectedRightValue = "",
      isArray = false,
      isEmptyOptionRequired = true
    }) {
      const availableOptionList = this.getAllAvailableOptionsByStepIndex(
        stepIndex
      ).filter((item) => {
        if (isArray) {
          return (
            item.type === selectedLeftValueType && item.isArray === isArray
          );
        } else {
          return item.type === selectedLeftValueType;
        }
      });
      return makeOptionsForSelect(selectedRightValue, [
        ...this.makeFirstOptionForSelect(isEmptyOptionRequired),
        ...availableOptionList.map(({ text, value }) => ({ text, value }))
      ]);
    },
    getRightComponentOptionsListOptions({
      stepIndex,
      selectedLeftValueType = "",
      selectedRightValue = "",
      selectedLeftValue = "",
      isArrayCheck = false,
      isEmptyOptionRequired = true,
      selectedComparator = ""
    }) {
      const castingList = this.getCastingBasedOnTypeAndComparator({
        name: selectedLeftValue,
        evaluationSign: selectedComparator
      });

      if (castingList.length) {
        const options = castingList.reduce((castedOptions, casting) => {
          const options = this.getListBasedOnType({
            stepIndex,
            selectedLeftValueType: casting.type,
            selectedRightValue,
            isArray: casting.isArray,
            isEmptyOptionRequired: false
          });
          castedOptions.push(...options);
          return castedOptions;
        }, []);
        return makeOptionsForSelect(selectedRightValue, [
          ...this.makeFirstOptionForSelect(isEmptyOptionRequired),
          ...options
        ]);
      } else {
        return this.getListBasedOnType({
          stepIndex,
          selectedLeftValueType,
          selectedRightValue,
          isArray: isArrayCheck,
          isEmptyOptionRequired
        });
      }
    },
    getCastingBasedOnTypeAndComparator({
      name = "",
      evaluationSign = ""
    } = {}) {
      const { comparators = [] } =
        erafCasting.types.find((typeObj) => {
          const type =
            this.getAllEntities[name]?.data?.type ||
            this.functionSteps.find(
              ({ variableNameSearchValue }) => variableNameSearchValue === name
            )?.variableType;
          const isArray =
            this.getAllEntities[name]?.data?.data?.isArray || false;
          return typeObj?.type === type && typeObj?.isArray === isArray;
        }) || {};
      return (
        comparators.find(
          ({ comparator }) => comparator === evaluationSign.toLowerCase()
        )?.types || []
      );
    },
    makeSetToBlockForLiteral({
      dataType,
      componentOptions,
      componentValue,
      stepIndex,
      selectedType
    }) {
      if (
        this.isStringValueDataType(dataType) ||
        this.isNumericValueDataType(dataType)
      ) {
        return {
          component: Input,
          componentOptions: {
            ...componentOptions,
            value: componentValue
          }
        };
      } else if (this.isBooleanValueDataType(dataType)) {
        const { component, componentOptions } = this.makeSelectInput(
          makeOptionsForSelect(`${componentValue}`, variableValueBooleanOptions)
        );
        return {
          component,
          componentOptions
        };
      } else {
        return {
          component: Select,
          componentOptions: {
            ...componentOptions,
            options: this.getRightComponentOptionsListOptions({
              stepIndex,
              selectedLeftValueType: selectedType,
              selectedRightValue: componentValue
            })
          }
        };
      }
    },
    makeSetToBlockForEntity({
      selectedLeftValue,
      stepIndex,
      selectedType,
      componentValue,
      defaultComponentOptions
    }) {
      if (
        this.isMultipleSelectionExpectedForEntityPropertyName(selectedLeftValue)
      ) {
        const availableRightOptions = this.getRightComponentOptionsListOptions({
          stepIndex,
          selectedLeftValueType: selectedType,
          selectedRightValue: selectedLeftValue,
          isArrayCheck: true
        });
        const amendableOptions = availableRightOptions.map(
          ({ value }) => value
        );
        return this.makeMultiSelectInput(
          componentValue,
          amendableOptions,
          availableRightOptions
        );
      } else {
        return {
          component: Select,
          componentOptions: {
            ...defaultComponentOptions,
            options: this.getRightComponentOptionsListOptions({
              stepIndex,
              selectedLeftValueType: selectedType,
              selectedRightValue: componentValue
            })
          }
        };
      }
    },
    makeSetToBlock({
      leftOptions = [],
      right = {},
      stepIndex,
      selectedLeftValue
    }) {
      let result;
      let componentOptions = this.getSharedComponentOptions(stepIndex);
      const selectedValue = getSelectedOption(leftOptions) || leftOptions[0];
      const selectedType = selectedValue?.type;
      const selectedRightValueType = right?.type || "";
      const valueTypeOptions = makeOptionsForSelect(
        selectedRightValueType,
        variableAssignmentOptions
      );
      let selectedValueType = valueTypeOptions.find(
        (options) => options?.selected
      );
      let componentValue =
        right?.data?.data?.value === undefined
          ? right?.data?.data?.data?.name
          : right.data.data.value;
      const dataType = right?.data?.type;

      if (!selectedValueType) {
        valueTypeOptions[0].selected = true;
        selectedValueType = valueTypeOptions[0];
      }

      if (this.isLiteralValueType(selectedValueType?.value)) {
        result = this.makeSetToBlockForLiteral({
          dataType,
          componentOptions,
          componentValue,
          stepIndex,
          selectedType
        });
      } else if (this.isEntityValueType(selectedValueType?.value)) {
        result = this.makeSetToBlockForEntity({
          selectedLeftValue,
          stepIndex,
          selectedType,
          componentValue,
          defaultComponentOptions: componentOptions
        });
      }

      const setToBlock = new SetToBlockClass({
        valueDataType: selectedType,
        valueDataTypeText: variableTypeToDisplayTextMap[selectedType] || "",
        isReadOnly: true,
        valueTypeOptions,
        component: result.component,
        componentOptions: result.componentOptions,
        componentValue,
        isHidden: !selectedType
      });
      setToBlock.componentOptions.id = `setToBlock__${setToBlock.id}`;

      this.setToBlockList.push(setToBlock);
      return setToBlock;
    },
    updateSetToBlockFromIfStatement({ event, ifStatement, stepIndex }) {
      const { event: eventValue, name: property } = event;
      const setToBlockToUpdate = this.getToBlockToUpdate(
        ifStatement.setToBlockId
      );
      const selectedLeftOption =
        ifStatement.ifStatement.getSelectedLeftOption();
      const { value: selectedComparator } = getSelectedOption(
        ifStatement.ifStatement.comparator.options
      );
      const componentValue = this.getRightValueBasedOnAllStepDetails({
        selectedLeftValue: selectedLeftOption?.value,
        comparator: selectedComparator,
        stepIndex,
        setToBlockToUpdate,
        valueType: eventValue
      });

      if (property === actionKeys.VALUE_TYPE) {
        this.updateSetToBlockValueType({
          newValueType: eventValue,
          setToBlockToUpdate,
          selectedLeftOption,
          stepIndex,
          isIfStatement: true,
          selectedComparator,
          componentValue
        });
      } else if (property === actionKeys.VALUE) {
        this.updateSetToBlockValue({
          newValue: eventValue,
          setToBlockToUpdate,
          selectedLeftValue: selectedLeftOption?.value,
          selectedComparator,
          stepIndex
        });
      }
    },
    resetSetToBlockValue({ setToBlockId } = {}) {
      const setToBlockToUpdate = this.getToBlockToUpdate(setToBlockId);

      if (setToBlockToUpdate) {
        setToBlockToUpdate.setComponentValue("");
        setToBlockToUpdate.componentOptions.value = "";

        if (setToBlockToUpdate.componentOptions.options) {
          setToBlockToUpdate.componentOptions.options = makeOptionsForSelect(
            "",
            setToBlockToUpdate.componentOptions.options
          );
        }
      }
    },
    getRightValueBasedOnAllStepDetails({
      selectedLeftValue = "",
      comparator = "",
      stepIndex = 0,
      setToBlockToUpdate = {},
      valueType
    } = {}) {
      const { subType = "" } =
        this.expectedDataListOfAllOptions[stepIndex]?.find(
          ({ value }) => value === selectedLeftValue
        ) || {};
      if (
        this.isMultiSelectLiteralRequired({
          entityPropertyName: selectedLeftValue,
          subType,
          comparator,
          valueType
        })
      ) {
        return Array.isArray(setToBlockToUpdate.componentValue)
          ? setToBlockToUpdate.componentValue
          : [];
      } else {
        return Array.isArray(setToBlockToUpdate.componentValue)
          ? ""
          : setToBlockToUpdate.componentValue;
      }
    },
    updateSetToBlockByIfStatementLeftOptions(
      { setToBlockId, stepIndex },
      leftOptions,
      selectedComparator
    ) {
      const setToBlockToUpdate = this.getToBlockToUpdate(setToBlockId);
      const { value: selectedValueType } =
        setToBlockToUpdate.getSelectedValueTypeOption();
      const selectedLeftOption = leftOptions.find(
        (options) => options?.selected
      );
      const selectedLeftValueType = selectedLeftOption?.type;
      const componentValue = this.getRightValueBasedOnAllStepDetails({
        selectedLeftValue: selectedLeftOption?.value,
        comparator: selectedComparator,
        stepIndex,
        setToBlockToUpdate,
        valueType: selectedValueType
      });

      setToBlockToUpdate.setValueDataTypeText(
        variableTypeToDisplayTextMap[selectedLeftValueType]
      );
      setToBlockToUpdate.setValueDataType(selectedLeftValueType);
      if (!this.isHasOrHasNoValue(selectedComparator)) {
        setToBlockToUpdate.setIsHidden(!selectedLeftValueType);
        this.updateSetToBlockValueType({
          newValueType: selectedValueType,
          setToBlockToUpdate,
          selectedLeftOption,
          stepIndex,
          isIfStatement: true,
          selectedComparator,
          componentValue
        });
      } else {
        setToBlockToUpdate.setIsHidden(true);
      }
    },
    makeNewSetToComponent({
      valueType,
      dataType,
      componentValue,
      stepIndex,
      selectedLeftOption,
      isIfStatement,
      selectedComparator
    }) {
      let subType = selectedLeftOption?.subType || dataType;

      if (
        [variableType.EXPRESSION, variableType.DATE_TIME].includes(subType) &&
        isIfStatement
      ) {
        subType = variableType.NUMERIC;
      }

      if (this.isLiteralValueType(valueType)) {
        if (
          this.isNumericValueDataType(subType) ||
          this.isStringValueDataType(subType)
        ) {
          const mappedDataType = variableTypeToInputTypeMap[subType];

          return this.makeInputComponent(componentValue, mappedDataType);
        } else if (this.isBooleanValueDataType(subType)) {
          const selectOptions = makeOptionsForSelect(
            `${componentValue}`,
            variableValueBooleanOptions
          );

          return this.makeSelectInput(selectOptions);
        } else if (
          this.isMultiSelectLiteralRequired({
            entityPropertyName: selectedLeftOption?.value,
            subType,
            comparator: selectedComparator,
            valueType
          })
        ) {
          const availableRightOptions =
            this.getAllEntities[selectedLeftOption.value]?.data?.data
              ?.options || [];
          const amendableOptions = availableRightOptions.map(
            ({ value }) => value
          );
          return this.makeMultiSelectInput(
            componentValue,
            amendableOptions,
            availableRightOptions
          );
        } else {
          const tempFunctionStep = {
            variableName: selectedLeftOption?.text,
            variableType: subType,
            value: componentValue
          };
          const optionsForLiteralValueSelect =
            this.makeOptionsForLiteralValueSelect(tempFunctionStep);
          if (
            isEmpty(getSelectedOption(optionsForLiteralValueSelect)) &&
            optionsForLiteralValueSelect?.length
          ) {
            optionsForLiteralValueSelect[0].selected = true;
          }
          return this.makeSelectInput(optionsForLiteralValueSelect);
        }
      } else if (this.isEntityValueType(valueType)) {
        const selectOptions = this.getRightComponentOptionsListOptions({
          stepIndex,
          selectedLeftValueType: dataType,
          selectedRightValue: componentValue,
          selectedLeftValue: selectedLeftOption?.value,
          isArrayCheck: this.isMultipleSelectionExpectedForEntityPropertyName(
            selectedLeftOption?.value
          ),
          selectedComparator,
          isEmptyOptionRequired: true
        });

        return this.makeSelectInput(selectOptions);
      }
    },
    updateSetToBlockValueType({
      newValueType,
      setToBlockToUpdate,
      stepIndex,
      selectedLeftOption,
      isIfStatement,
      selectedComparator,
      componentValue = ""
    } = {}) {
      const componentValueTemp = this.getValueBasedOnValueTypeVariation({
        newValueType,
        valueTypeOptions: setToBlockToUpdate.valueTypeOptions,
        componentValue
      });
      const { component, componentOptions } = this.makeNewSetToComponent({
        valueType: newValueType,
        dataType:
          variableDisplayTextToTypeMap[setToBlockToUpdate.valueDataTypeText],
        componentValue: componentValueTemp,
        stepIndex,
        selectedLeftOption,
        isIfStatement,
        selectedComparator
      });

      setToBlockToUpdate.setValueTypeOptions(
        makeOptionsForSelect(newValueType, variableAssignmentOptions)
      );
      setToBlockToUpdate.setComponent(component);
      setToBlockToUpdate.setComponentOptions(componentOptions);
      setToBlockToUpdate.setComponentValue(componentValueTemp);
      setToBlockToUpdate.setIsHidden(false);
      setToBlockToUpdate.componentOptions.value = componentValueTemp;
    },
    getValueBasedOnValueTypeVariation({
      newValueType,
      valueTypeOptions,
      componentValue
    }) {
      const { value: currentValueType } = getSelectedOption(valueTypeOptions);
      return currentValueType === newValueType ? componentValue : "";
    },
    updateSetToBlockValueForLiteralMultiselect({
      newValue,
      setToBlockToUpdate
    }) {
      const { eventType, value } = newValue;
      if (eventType === operations.ADD) {
        setToBlockToUpdate.componentValue.push(value);
        setToBlockToUpdate.componentOptions.value =
          setToBlockToUpdate.componentValue;
      } else if (eventType === operations.DELETE) {
        setToBlockToUpdate.componentValue =
          setToBlockToUpdate.componentValue.filter(
            (option) => option !== value
          );
        setToBlockToUpdate.componentOptions.value =
          setToBlockToUpdate.componentValue;
      }

      const amendableValues = setToBlockToUpdate.componentOptions.options.map(
        ({ value }) => value
      );

      setToBlockToUpdate.componentOptions.options = makeOptionsForMultiSelect(
        setToBlockToUpdate.componentValue,
        amendableValues,
        setToBlockToUpdate.componentOptions.options
      );
    },
    updateSetToBlockValueForLiteralValue({
      selectedValue,
      setToBlockToUpdate
    }) {
      setToBlockToUpdate.componentValue = selectedValue;
      setToBlockToUpdate.componentOptions.value = selectedValue;
    },
    updateSetToBlockValueForLiteralOptions({
      selectedValue,
      options,
      setToBlockToUpdate
    }) {
      setToBlockToUpdate.componentOptions.options = makeOptionsForSelect(
        selectedValue,
        options
      );
      this.updateSetToBlockValueForLiteralValue({
        selectedValue,
        setToBlockToUpdate
      });
    },
    updateSetToBlockValueForLiteral({
      setToBlockToUpdate,
      newValue,
      selectedLeftValue,
      selectedComparator,
      stepIndex
    }) {
      const { subType = "" } =
        this.expectedDataListOfAllOptions[stepIndex]?.find(
          ({ value }) => value === selectedLeftValue
        ) || {};
      if (
        this.isBooleanValueDataType(
          variableDisplayTextToTypeMap[setToBlockToUpdate.valueDataTypeText]
        )
      ) {
        this.updateSetToBlockValueForLiteralOptions({
          selectedValue: newValue,
          options: variableValueBooleanOptions,
          setToBlockToUpdate
        });
      } else if (
        this.isMultiSelectLiteralRequired({
          entityPropertyName: selectedLeftValue,
          subType,
          comparator: selectedComparator,
          valueType: setToBlockToUpdate.getSelectedValueTypeOption()?.value
        })
      ) {
        this.updateSetToBlockValueForLiteralMultiselect({
          newValue,
          setToBlockToUpdate
        });
      } else if (setToBlockToUpdate.componentOptions.options?.length) {
        this.updateSetToBlockValueForLiteralOptions({
          selectedValue: newValue,
          options: setToBlockToUpdate.componentOptions.options,
          setToBlockToUpdate
        });
      } else {
        this.updateSetToBlockValueForLiteralValue({
          selectedValue: newValue,
          setToBlockToUpdate
        });
      }
    },
    updateSetToBlockValue({
      newValue,
      setToBlockToUpdate,
      selectedLeftValue,
      selectedComparator,
      stepIndex
    }) {
      const { value: selectedValueType } =
        setToBlockToUpdate.getSelectedValueTypeOption();

      if (this.isLiteralValueType(selectedValueType)) {
        this.updateSetToBlockValueForLiteral({
          setToBlockToUpdate,
          newValue,
          selectedLeftValue,
          selectedComparator,
          stepIndex
        });
      } else if (this.isEntityValueType(selectedValueType)) {
        this.updateSetToBlockValueForEntity({ setToBlockToUpdate, newValue });
      }
    },
    updateSetToBlockValueForEntity({ setToBlockToUpdate, newValue }) {
      setToBlockToUpdate.componentOptions.value = newValue;
      setToBlockToUpdate.componentValue = newValue;
      setToBlockToUpdate.componentOptions.options = makeOptionsForSelect(
        newValue,
        setToBlockToUpdate.componentOptions.options
      );
    },
    updateExistingAllSetToBlock() {
      for (let index = 0; index < this.setToBlockList.length; index++) {
        const selectedValueType =
          this.setToBlockList[index].getSelectedValueTypeOption();

        if (selectedValueType.value === actionKeys.ENTITY) {
          const selectedValue = getSelectedOption(
            this.setToBlockList[index].componentOptions.options
          ).value;
          const setToBlockId = this.setToBlockList[index].id;
          const { ifStatement = {}, stepIndex } =
            this.getIfStatementBySetToBlockId(setToBlockId);

          if (!isEmpty(ifStatement)) {
            const selectedLeftValueType =
              ifStatement.getSelectedLeftValueType();
            const componentOptions = cloneDeep(
              this.setToBlockList[index].componentOptions
            );

            componentOptions.options = this.getRightComponentOptionsListOptions(
              {
                stepIndex,
                selectedLeftValueType,
                selectedRightValue: selectedValue
              }
            );

            this.setToBlockList[index].componentOptions = componentOptions;
          }
        }
      }
    },
    updateSetToBlockForHasOrHasNoValue(ifStatement) {
      const setToBlockToUpdate = this.getToBlockToUpdate(
        ifStatement.setToBlockId
      );

      setToBlockToUpdate.setValueTypeOptions(
        makeOptionsForSelect(
          variableAssignmentType.LITERAL,
          variableAssignmentOptions
        )
      );
      setToBlockToUpdate.setComponentOptions({
        ...setToBlockToUpdate.componentOptions,
        value: null
      });
      setToBlockToUpdate.setComponentValue(null);
      setToBlockToUpdate.setIsHidden(true);
    },
    hideSetToBlockByIfStatementBlock(ifStatement) {
      const setToBlockToHide = this.getToBlockToUpdate(
        ifStatement.setToBlockId
      );

      setToBlockToHide.setIsHidden(true);
      setToBlockToHide.setComponent({});
      setToBlockToHide.setComponentValue("");
      setToBlockToHide.setComponentOptions({});
    },
    deleteSetToBlockById(setToBlockId) {
      this.setToBlockList = this.setToBlockList.filter(
        ({ id }) => id !== setToBlockId
      );
    }
  }
};
