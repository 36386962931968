<template>
  <div>
    <b-row>
      <b-col cols="3" class="text-left">
        <button
          class="btn btn-outline-primary btn-sm mr-1"
          @click="toggleSetting"
        >
          <font-awesome-icon icon="cogs" />
        </button>
        <button
          v-if="!isFormPublished && fieldData.inputType != 'downloadPDF'"
          class="btn btn-outline-warning btn-sm mr-1"
          @click="onCloneFieldModal"
        >
          <font-awesome-icon icon="copy" />
        </button>
        <button
          v-if="!isFormPublished"
          class="btn btn-outline-danger btn-sm mr-1"
          @click="showDeleteModal"
        >
          <font-awesome-icon icon="trash" />
        </button>

        <!-- show when enale -->
        <button
          v-b-popover.hover.right="'Disable required'"
          v-if="fieldData.validation && fieldData.validation.required == '1'"
          class="btn btn-success btn-sm mr-1"
          @click="toggleValidation('0')"
        >
          <font-awesome-icon icon="check" />
        </button>

        <!-- show when disable -->
        <button
          v-b-popover.hover.right="'Enable required'"
          v-if="fieldData.validation && fieldData.validation.required == '0'"
          class="btn btn-outline-danger btn-sm mr-1"
          @click="toggleValidation('1')"
        >
          <font-awesome-icon icon="check" />
        </button>
      </b-col>
      <b-col cols="7">
        <h6 class="text-truncate" v-if="!collapse">
          {{ label ? label : fieldData.label.english }}
        </h6>
        &nbsp;
      </b-col>
      <b-col cols="2" class="text-right">
        <a href="#" class="btn btn-outline-danger btn-sm" v-if="isHidden"
          >Conditional</a
        >&nbsp;
        <button
          class="btn btn-outline-primary btn-sm"
          :aria-expanded="collapse ? 'true' : 'false'"
          aria-controls="collapse-1"
          @click="$emit('collapse-field')"
        >
          <font-awesome-icon :icon="collapse ? 'minus' : 'plus'" />
        </button>
      </b-col>
    </b-row>

    <!--delete confirmation modal -->
    <b-modal ref="modal-delete" title="Are you sure?" @ok="onDeleteField">
      <p class="my-4">
        Confirm delete of field {{ label ? label : fieldData.label.english }}?
      </p>
    </b-modal>
    <!-- deep clone fiels midal -->
    <b-modal
      ref="custom-clone-modal"
      scrollable
      body-class="custom-clone"
      size="lg"
      @ok="onCloneField()"
      title="Please Select Target Selection To Clone "
    >
      <b-form-group label="Select Sections(s)">
        <b-form-checkbox-group
          v-model="selectedSections"
          :options="currentSections"
        ></b-form-checkbox-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Helper } from "@/helpers";
import _ from "lodash";
import { localStorageItem, formStatus } from "@/constants";

export default {
  name: "MenuButtons",
  data() {
    return {
      isLoading: false,
      currentSections: [],
      selectedSections: [],
      isFormPublished: false
    };
  },
  computed: {
    ...mapGetters(["selectedLanguage"]),
    ...mapState(["form1"])
  },
  mounted() {
    this.isFormPublished =
      parseInt(localStorage.getItem(localStorageItem.FORM_STATUS)) ===
      formStatus.PUBLISHED;
  },

  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),
    ...mapActions(["removeField", "cloneField"]),
    getUpdatedForms() {
      this.$store.state.form1.formSection.map((ff) => {
        let dd = {
          text: ff.sectionTitle[this.selectedLanguage],
          value: ff
        };
        this.currentSections.push(dd);
      });
    },
    showDeleteModal() {
      this.$refs["modal-delete"].show();
    },
    hideModal() {
      this.$refs["modal-delete"].hide();
      this.$refs["custom-clone-modal"].hide();
    },
    onDeleteField() {
      let payload = {
        fieldIndex: this.fieldIndex,
        field: this.fieldData,
        sectionIndex: this.sectionIndex
      };
      Helper.makeToast(this.$bvToast, "success", "Field deleted");
      this.removeField(payload);
      this.hideModal();
    },
    showCloneFieldModal() {
      this.$refs["custom-clone-modal"].show();
    },
    async onCloneFieldModal() {
      this.currentSections = [];
      await this.getUpdatedForms();
      if (this.form1.formSection.length > 1) {
        this.$refs["custom-clone-modal"].show();
      } else {
        let clone = _.cloneDeep(this.fieldData);
        let index = this.sectionIndex;
        let payload = {
          field: clone,
          sectionIndex: index
        };
        this.cloneField(payload);
      }
    },
    onCloneField() {
      let clone = _.cloneDeep(this.fieldData);
      this.selectedSections.map((section) => {
        let payload = {
          field: clone,
          sectionIndex: section.index
        };
        this.cloneField(payload);
        Helper.makeToast(this.$bvToast, "success", "Field clone");
        this.hideModal();
      });
    },
    toggleSetting() {
      this.$emit("toggle-field");
    },
    toggleValidation(val) {
      this.fieldData.validation.required = val;
    }
  },
  props: {
    collapse: Boolean,
    isHidden: Boolean,
    fieldData: Object,
    label: String,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>

<style>
.custom-clone {
  min-height: 300px;
}
</style>
