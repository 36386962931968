<template>
  <AgGridVue
    v-bind="$attrs"
    v-on="$listeners"
    ref="grid"
    :grid-options="mergedGridOptions"
    @gridReady="onGridReady"
    class="data-table"
    data-test-id="data-table"
  />
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { merge } from "lodash";
import "ag-grid-community/styles/ag-grid.css";
import { dataTableEvents } from "@/constants";
import { sizeColumnsToGridWidth } from "@/utils";

export default {
  name: "AgGridTable",
  components: { AgGridVue },
  data() {
    return {
      defaultGridOptions: {
        rowHeight: 66,
        headerHeight: 66,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        defaultColDef: {
          resizable: true,
          suppressMovable: true,
          cellStyle: { textAlign: "left" },
          autoHeaderHeight: true,
          wrapHeaderText: true
        }
      }
    };
  },
  props: {
    gridOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    mergedGridOptions() {
      return merge(this.defaultGridOptions, this.gridOptions);
    }
  },
  methods: {
    onGridReady(event) {
      if (!this.gridOptions.suppressAutoSizing) {
        sizeColumnsToGridWidth({
          api: event.api,
          columnApi: event.columnApi,
          sizingElement: this.$refs?.grid?.$el
        });
      }
      this.$emit(dataTableEvents.GRID_READY, event);
    }
  }
};
</script>

<style lang="scss" scoped>
.data-table {
  width: 100%;
  height: 100%;
  @include ag-grid-table;
}
</style>
