<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs lazy content-class="mt-3">
        <b-tab title="Text" active>
          <b-card no-body>
            <b-tabs pills card vertical>
              <!-- Render Tabs, supply a unique `key` to each tab -->
              <b-tab
                @click="tabClick(language)"
                v-for="(language, index) in form.formTranslations"
                :key="index"
                :title="language"
              >
                <!-- translation form wrappe -->
                <b-row>
                  <b-col cols="10">
                    <b-form-group label="Section Title">
                      <b-form-input
                        v-model="langugeInput"
                        :placeholder="
                          sectionData.sectionTitle[transalationLanguage]
                        "
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Section Sub Title Title">
                      <b-form-input
                        v-model="
                          sectionData.sectionSubTitle[transalationLanguage]
                        "
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="2">
                    <b-button
                      class="mt-3"
                      @click="addLanguageToField(language)"
                      variant="outline-success"
                    >
                      <b-spinner small v-if="isLoading"></b-spinner>Update
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-tab>

        <b-tab title="Advanced">
          <b-row>
            <b-col cols="10">
              <b-form-checkbox
                class="my-2"
                v-model="isRepeatable"
                value="1"
                unchecked-value="0"
                >Section is repeatable ?</b-form-checkbox
              >
              <b-form-checkbox
                class="my-2"
                v-model="isSectionTitleHidden"
                value="1"
                unchecked-value="0"
                >Hide Section Title?</b-form-checkbox
              >
              <b-form-checkbox
                class="my-2"
                v-model="sectionData.isManualComponentEnums"
                value="1"
                unchecked-value="0"
                de
                >Enable manual enumeration for section components?
                <br />
                <span
                  v-if="sectionData.isManualComponentEnums == 1"
                  class="text-info"
                  style="font-size: 10px"
                  >You need to set mannual enumeration for every component from
                  its settings.</span
                >
              </b-form-checkbox>

              <div v-if="form.formType == 5">
                <b-form-checkbox
                  class="my-2"
                  v-model="sectionData.advance.visibleForAdmin"
                  >Visible for Admin?</b-form-checkbox
                >
                <b-form-checkbox
                  class="my-2"
                  v-model="sectionData.advance.visibleForClient"
                  >Visible for Client?</b-form-checkbox
                >
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                @click="updateSectionRepeatable"
                variant="outline-success"
                >Update</b-button
              >
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Conditional" v-if="sectionData.index">
          <b-col cols="6">
            <!-- enabledConditionalField -->
            <b-form-checkbox
              class="text-left my-2"
              @change="onToggleConditionalCheck"
              v-model="enabledConditionalField"
              >Enable Conditional Field</b-form-checkbox
            >

            <b-collapse v-model="showCollapseSection">
              <label>Select Section </label>
              <v-select
                :clearable="false"
                class="mb-2"
                label="label"
                :options="formSections"
                v-model="sectionValue"
                @input="getCurrentSectionElementsList"
              ></v-select>
            </b-collapse>
          </b-col>

          <b-row class="mx-1">
            <b-col cols="6">
              <b-collapse v-model="showCollapseFields" class="mt-2">
                <label>Select Field</label>
                <v-select
                  :clearable="false"
                  v-model="conditionalField"
                  class="mb-2"
                  :options="conditionalSectionElementLables"
                  label="elementLabel"
                  @input="getSelectedElement"
                ></v-select>
              </b-collapse>
            </b-col>

            <b-col cols="6" v-if="showCollapseOptions" class="mt-2">
              <label>Hide when selected option value is:</label>
              <v-select
                multiple
                :clearable="false"
                class="mb-2"
                label="text"
                v-model="conditionalFieldValue"
                :options="selectedElementOption"
              ></v-select>
            </b-col>
          </b-row>
          <!-- onInitialSectionHide -->
          <b-form-checkbox
            v-if="conditionalFieldValue"
            value="1"
            unchecked-value="0"
            v-model="initialSectionHide"
            class="text-left my-2"
            >Initially hide section?
            <p class="text-info" style="font-size: 12px">
              Select only if you want to hide this section by default.
            </p>
          </b-form-checkbox>

          <b-button
            :disabled="isAppliedCondition"
            v-if="conditionalFieldValue"
            class="mx-3 mt-3"
            variant="outline-success"
            @click="setFieldConditions"
            >Apply</b-button
          >
          <b-button
            v-if="conditionalFieldValue && isAppliedCondition"
            class="mx-3 mt-3"
            variant="outline-success"
            @click="resetFieldConditions"
            >Reset</b-button
          >
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import uid from "uid";
import { Helper } from "@/helpers";

export default {
  name: "SectionSettings",
  props: {
    sectionData: Object
  },
  data() {
    return {
      transalationLanguage: "english",
      langugeInput: undefined,
      isRepeatable: 0,
      isLoading: false,
      isSectionTitleHidden: this.sectionData.advance.hideSectionTitle || 0,

      // for conditional filedsss
      showCollapseSection: false,
      showCollapseFields: false,
      showCollapseOptions: false,
      enabledConditionalField: false,
      formSections: [],
      sectionValue: null,
      conditionalField: null,
      conditionalFieldValue: null,
      selectedConditionalFieldValue: null,
      selectedSectionIndex: null,
      selectedElementOption: [],
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      conditionalFieldElementId: null,
      initialSectionHide: 0,
      isAppliedCondition: false
    };
  },
  mounted() {
    this.langugeInput =
      this.sectionData.sectionTitle[this.transalationLanguage];
    this.isRepeatable = this.sectionData.isRepeatable;
    this.getSections();
    this.addSectionExtras();
    this.checkForConditions();
  },

  computed: {
    ...mapGetters({ form: "form1" })
  },
  methods: {
    tabClick(language) {
      this.transalationLanguage = language.toLowerCase();

      this.langugeInput =
        this.sectionData.sectionTitle[this.transalationLanguage];
    },

    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: "Alert",
        variant,
        solid: true
      });
    },
    addSectionExtras() {
      if (!this.sectionData.sectionSubTitle) {
        let sectionSubTitle = { english: "" };
        Object.assign(this.sectionData, { sectionSubTitle });
      }
      if (!this.sectionData.sectionDescription) {
        let sectionDescription = { english: "" };
        Object.assign(this.sectionData, { sectionDescription });
      }
    },
    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.sectionData.sectionTitle[tempKey] = this.langugeInput;
      this.makeToast("success", "Language Update");
    },

    updateSectionRepeatable() {
      this.sectionData.isRepeatable = this.isRepeatable;
      this.sectionData.advance.hideSectionTitle = parseInt(
        this.isSectionTitleHidden
      );
      this.makeToast("success", "Repeatable has been successfully updated");
    },

    // for methods conditional
    checkForConditions() {
      let isConditional =
        this.sectionData && this.sectionData.isConditional
          ? this.sectionData.isConditional
          : false;
      this.enabledConditionalField = isConditional;

      if (isConditional) {
        this.showCollapseSection = true;
        this.showCollapseFields = true;
        this.showCollapseOptions = true;
        this.conditionalFieldValue = this.sectionData.condition.elementValue;
        this.conditionalField = this.sectionData.condition;
        // this.conditionalField = this.form.formSection.find(sec => sec.sectionTitle['english'] === this.sectionData.condition.label)
        this.conditionalFieldElementId =
          this.sectionData.condition.targetElementId || null;
        this.sectionValue = this.sectionData.condition.selectedSectionDetails;
        this.selectedSectionIndex =
          this.sectionData.condition.targetSectionIndex; // sectionIndex
        this.initialSectionHide = this.sectionData.condition.initialSectionHide;
        this.isAppliedCondition = true;
      }
    },

    onToggleConditionalCheck(e) {
      this.enabledConditionalField = e;
      this.showCollapseSection = !this.showCollapseSection;
      if (!e && this.conditionalFieldValue && this.conditionalField) {
        this.resetFieldConditions("toggle");
      }
    },

    getSections() {
      let formSections = this.form.formSection;
      formSections.filter((section) => {
        if (section.index < this.sectionData.index) {
          let sec = {
            label: section.sectionTitle[this.transalationLanguage],
            sectionIndex: section.index,
            sectionId: section.id
          };
          return this.formSections.push(sec);
        }
      });
    },

    getCurrentSectionElementsList(section) {
      this.conditionalSectionElementLables = [];
      this.conditionalSectionElements = [];
      this.selectedSectionIndex = section.sectionIndex;
      let conditionalSectionElements =
        this.form.formSection[section.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (
            element.inputType === "radio" ||
            element.inputType === "checkbox" ||
            element.inputType === "select"
          ) {
            let dd = {
              elementLabel: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
      this.showCollapseFields = true;
      this.showCollapseOptions = false;
      this.conditionalField = null;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
      this.showCollapseOptions = true;
    },

    setFieldConditions() {
      // sourceSection -> section to hide
      // targetSection -> section from hide -> radio opt inside
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceSectionId: this.sectionData.id,
          sourceSectionIndex: this.sectionData.index,
          sourceSectionLabel: this.sectionData.sectionTitle.english,
          elementValue: this.conditionalFieldValue,
          elementLabel: this.conditionalField.element.label.english,
          forSection: true,
          targetElementId: this.conditionalField.element.id,
          targetSectionIndex: this.sectionValue.sectionIndex,
          targetSectionLabel: this.sectionValue.label,
          targetSectionId: this.sectionValue.sectionId,
          initialSectionHide: this.initialSectionHide,
          selectedSectionDetails: this.sectionValue
        };

        let elements = [
          ...this.$store.state.form1.formSection[this.selectedSectionIndex]
            .formElements
        ];

        elements.map((element) => {
          if (element.options) {
            for (let opt of element.options) {
              if (
                this.conditionalFieldValue.includes(opt.value) &&
                this.conditionalField.element.id === element.id
              ) {
                element.sectionConditions.push(condition);
                break;
              }
            }
          }
        });
        this.sectionData.isConditional = this.enabledConditionalField;
        this.sectionData.isHidden = this.initialSectionHide === "1"; // checkbox type giving as a string

        Object.assign(this.sectionData, { condition });
        this.isAppliedCondition = true;
        Helper.makeToast(this.$bvToast, "success", "Conditional  Updated");
      } else {
        Helper.makeToast(this.$bvToast, "danger", "No Condition selected");
      }
    },
    resetFieldConditions(e) {
      this.clearSelectedSectionValue();
      this.sectionData.condition = null;
      this.showCollapseFields = false;
      this.showCollapseOptions = false;
      this.conditionalFieldValue = null;
      this.conditionalField = null;
      this.conditionalFieldElementId = null;
      this.enabledConditionalField =
        e == "toggle" ? this.enabledConditionalField : null;
      this.showCollapseSection =
        e == "toggle" ? this.showCollapseSection : !this.showCollapseSection;
      this.sectionData.isConditional = false;
      this.sectionValue = null;
      this.initialSectionHide = 0;
      this.isAppliedCondition = false;
    },

    clearSelectedSectionValue() {
      this.sectionData.condition;
      let elements =
        this.$store.state.form1.formSection[
          this.sectionData.condition.targetSectionIndex
        ].formElements;
      let foundElement = elements.find(
        (ele) => ele.id === this.sectionData.condition.targetElementId
      );
      if (foundElement) {
        for (let i = 0; i < foundElement.sectionConditions.length; i++) {
          if (
            foundElement.sectionConditions[i].id ===
            this.sectionData.condition.id
          ) {
            foundElement.sectionConditions.splice(i, 1);
            break;
          }
        }
      }
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
