<template>
  <div
    class="tab-pane fade show active"
    id="htmlinputs"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <draggable
      :sort="false"
      :list="charts"
      :group="{ name: 'sections', pull: 'clone', put: false }"
      tag="ul"
      :clone="onClone"
    >
      <li
        class="list-group-item text-left pointer"
        v-for="element in charts"
        :key="element.name"
        :data-elementtype="element.inputType"
      >
        <font-awesome-icon
          :icon="element.icon"
          class="mr-2"
          v-if="element.icon"
        />
        {{ element.name }}
      </li>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { FormData } from "@/helpers";

export default {
  name: "ChartsComponents",
  components: {
    draggable
  },
  data() {
    return {
      charts: [
        {
          name: "line",
          inputType: "line",
          icon: "chart-line"
        },
        {
          name: "Bar",
          inputType: "bar",
          icon: "chart-bar"
        }
      ]
    };
  },
  computed: {},
  methods: {
    onClone(e) {
      let data = FormData.htmlData(e.inputType);
      return {
        ...data
      };
    }
  },

  props: {}
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
