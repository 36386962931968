var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'base-badge',
    `base-badge--${_vm.theme}`,
    `base-badge__${_vm.getSize}`,
    `base-badge__white-space-${_vm.whiteSpace}`,
    `base-badge__text-align-${_vm.textAlign}`
  ],attrs:{"data-test-id":"base-badge"}},[_c('span',{class:[
      'base-badge__text',
      _vm.size === 'large' && 'base-badge__text--large'
    ],style:({ minWidth: `${_vm.minimumWidth}` }),attrs:{"data-test-id":"base-badge__text"}},[_c('span',{class:`base-badge__space base-badge__space--${_vm.textSpace}`,attrs:{"data-test-id":"base-badge__space"}},[_vm._v(_vm._s(_vm.text))]),(_vm.isRemovable)?_c('CallToAction',{staticClass:"base-badge__button",attrs:{"theme":_vm.theme,"icon-size":12,"icon":"times","aria-label":`remove ${_vm.text} badge`,"icon-space":_vm.iconSpace,"data-test-id":"base-badge__cta--remove"},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveBadge.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onRemoveBadge.apply(null, arguments)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }