<template>
  <router-link :to="path" :target="openInNewTab && '_blank'">
    <BaseBadge v-bind="badgeOptions" />
  </router-link>
</template>

<script>
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";

export default {
  name: "BadgeLink",
  components: { BaseBadge },
  props: {
    path: {
      type: String,
      default: ""
    },
    openInNewTab: {
      type: Boolean,
      default: false
    },
    badgeOptions: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
