import { endOperations as endOperationsMap, decimalOptions } from "@/constants";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { Round as RoundClass } from "@/molecules/Round/Round.class";
import Round from "@/molecules/Round/Round";

export default {
  data() {
    return {
      roundList: []
    };
  },
  components: {
    Round
  },
  methods: {
    makeEndOperations({ operations = [] } = {}) {
      return (
        operations?.reduce((operationsInstances, operation) => {
          if (operation.operation === endOperationsMap.ROUND) {
            operationsInstances.push(
              this.makeRoundOperationAndExtendRoundList(operation)
            );
          }

          return operationsInstances;
        }, []) || []
      );
    },
    makeRoundOperationAndExtendRoundList({ settings } = {}) {
      const roundInstance = new RoundClass({
        component: Round,
        componentOptions: {
          decimalOptions: makeOptionsForSelect(
            settings?.decimals,
            decimalOptions
          )
        }
      });
      this.roundList.push(roundInstance);
      return roundInstance;
    },
    amendRoundInstance({ roundId, decimal }) {
      const roundInstance = this.roundList.find(({ id }) => id === roundId);
      roundInstance.setComponentOptions({
        decimalOptions: makeOptionsForSelect(decimal, decimalOptions)
      });
    },
    removeIdFromRoundList({ roundId }) {
      this.roundList = this.roundList.filter(({ id }) => roundId !== id);
    }
  }
};
