const visibleFields = {
  fields: [
    {
      index: 15,
      isActive: true,
      key: "actions",
      label: "Select",
      displayLabel: "",
      sortable: false,
      visible: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "50",
      thClass: "",
      tdClass: "cell-backgroundColor-created_date",
      fontSize: "15",
      fontColor: "#000000",
      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 0,
      isActive: true,
      key: "comp_name_eng",
      label: "Third Party Details",
      displayLabel: "Third Party Details",
      visible: true,
      sortable: true,
      stickyColumn: true,
      displayName: "",
      variant: "light",
      variantColor: "#fdfdfe",
      width: "200",
      thClass: "",
      tdClass: "cell-backgroundColor-comp_name_eng",
      fontSize: "15",
      fontColor: "#000000",
      description: "",
      headerFontSize: "15",
      headerFontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-left",
        alignment: "Left"
      },
      default: false
    },
    {
      index: 1,
      isActive: true,
      key: "created_date",
      label: "Created Date",
      displayLabel: "Created Date",
      sortable: true,
      visible: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "100",
      thClass: "",
      tdClass: "cell-backgroundColor-created_date",
      fontSize: "15",
      fontColor: "#000000",
      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 2,
      isActive: true,
      key: "name",
      label: "Created By",
      displayLabel: "Created By",
      visible: true,
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "100",
      thClass: "",
      tdClass: "cell-backgroundColor-name",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 3,
      isActive: true,
      key: "tpi_type",
      label: "Entity Type",
      displayLabel: "Entity Type",
      visible: true,
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "100",
      thClass: "",
      tdClass: "cell-backgroundColor-tpi_type",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 4,
      isActive: true,
      key: "country",
      label: "Country",
      displayLabel: "Country",
      visible: true,
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "100",
      thClass: "",
      tdClass: "cell-backgroundColor-country",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 5,
      isActive: true,
      key: "asso_name",
      label: "Associates",
      displayLabel: "Associates",
      visible: true,
      sortable: false,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "100",
      thClass: "",
      tdClass: "cell-backgroundColor-asso_name",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 6,
      isActive: true,
      key: "ethix_risk_rating",
      visible: true,
      label: "Standard PRI",
      displayLabel: "Standard PRI",
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "50",
      thClass: "",
      tdClass: "cell-backgroundColor-ethix_risk_rating",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 7,
      isActive: true,
      key: "config_pri",
      visible: true,
      label: "Config PRI",
      displayLabel: "Config PRI",
      sortable: false,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "100",
      thClass: "",
      tdClass: "cell-backgroundColor-config_pri",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 8,
      isActive: true,
      key: "risk_level",
      label: "IDD",
      displayLabel: "IDD",
      visible: true,
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "50",
      thClass: "",
      tdClass: "cell-backgroundColor-risk_level",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 9,
      isActive: true,
      key: "idd_color",
      label: "IDD+",
      displayLabel: "IDD+",
      visible: true,
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "50",
      thClass: "",
      tdClass: "cell-backgroundColor-idd_color",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 10,
      isActive: true,
      key: "edd_color",
      label: "EDD",
      displayLabel: "EDD",
      visible: true,
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "50",
      thClass: "",
      tdClass: "cell-backgroundColor-edd_color",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 11,
      isActive: true,
      key: "risk_rating",
      label: "My Risk Rating",
      displayLabel: "My Risk Rating",
      visible: true,
      sortable: true,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "50",
      thClass: "",
      tdClass: "cell-backgroundColor-risk_rating",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 12,
      isActive: false,
      key: "latest_completed_ddq_status",
      label: "Latest Completed MSQ Result",
      displayLabel: "Latest Completed MSQ Result",
      visible: true,
      sortable: false,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "80",
      thClass: "",
      tdClass: "cell-backgroundColor-latest_completed_ddq_status",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 13,
      isActive: false,
      key: "latest_ddq_sent",
      label: "Days since last MSQ Sent",
      displayLabel: "Days since last MSQ Sent",
      visible: true,
      sortable: false,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "50",
      thClass: "",
      tdClass: "cell-backgroundColor-latest_ddq_sent",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    },
    {
      index: 14,
      isActive: false,
      key: "latest_ddq_status",
      label: "Latest MSQ Task Status",
      displayLabel: "Latest MSQ Task Status",
      visible: true,
      sortable: false,
      stickyColumn: false,
      displayName: "",
      variant: "",
      variantColor: "",
      width: "80",
      thClass: "",
      tdClass: "cell-backgroundColor-latest_ddq_status",
      fontSize: "15",
      fontColor: "#000000",

      description: "",
      headerfontSize: "15",
      headerfontColor: "#000000",
      thStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      tdStyle: {
        backgroundColor: "#ffffff",
        textAlignment: "text-center",
        alignment: "Center"
      },
      default: false
    }
  ]
};

export { visibleFields };
