<template>
   <div>
        <b-card >
            <div class="text-center">
                <h4 style="font-size:17px">Third Parties By End State</h4>
            </div>
            <highcharts :options="ddqStatBarChartOptions" />
        </b-card>
   </div>
</template>

<script>
export default {
  name: "EndStateDistributionComparativeComponent",
  props:{
      ddqStatBarChartOptions: Object,
  },
}
</script>