<template>
  <nav v-if="hasBreadcrumbs" class="breadcrumbs" data-test-id="breadcrumbs">
    <ul class="breadcrumbs__list" data-test-id="breadcrumbs__list">
      <li
        v-for="({ link, name }, index) in breadcrumbList"
        :key="`breadcrumbs-${index}`"
        :class="[
          'breadcrumbs__list-element',
          { 'breadcrumbs__list-element--active': isLastElement(index) }
        ]"
        data-test-id="breadcrumbs__list-element"
      >
        <router-link
          :to="redirectTo(link)"
          class="breadcrumbs__list-element-link"
          data-test-id="breadcrumbs__list-element-link"
        >
          {{ name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  computed: {
    breadcrumbList() {
      return this.$route?.meta?.breadcrumbs
        ? this.$route?.meta?.breadcrumbs(this.$route)
        : [];
    },
    hasBreadcrumbs() {
      return !!this.breadcrumbList?.length;
    }
  },
  methods: {
    isLastElement(index) {
      return index + 1 === this.breadcrumbList.length;
    },
    redirectTo(link) {
      return link || "";
    }
  }
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  background: transparent;
  text-align: left;
  padding: 12px 16px;

  &__list {
    margin: 0;
    padding: 0;

    &-element {
      display: inline-block;
      color: $granite-gray;

      &-link {
        display: inherit;
        color: inherit;
      }

      &:not(:last-child)::after {
        content: " / ";
        display: inline-block;
        width: 20px;
        text-align: center;
      }

      &--active {
        color: $azure;
      }
    }
  }
}
</style>
