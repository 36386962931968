<!-- Add/Edit user view modal -->
<template>
  <div class="add-user-view">
    <b-modal
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      title="View Configuration"
      v-model="addViewModal"
      size="xl"
      modal-class="add-user-view__modal"
      content-class="add-user-view__modal-content"
      dialog-class="add-user-view__modal-dialog"
      scrollable
    >
      <b-overlay :show="submiting" class="add-user-view__wrapper">
        <div class="add-user-view__options">
          <ul class="add-user-view__options-list">
            <li class="add-user-view__options-list-option">
              <button
                :class="[
                  'add-user-view__options-list-option-cta',
                  {
                    'add-user-view__options-list-option-cta--selected':
                      viewType === userViewTypeText.ENTITY_LIST
                  },
                  {
                    'add-user-view__options-list-option-cta--hidden':
                      isViewOptionHidden(userViewTypeText.ENTITY_LIST)
                  }
                ]"
                @click="addViewType(userViewTypeText.ENTITY_LIST)"
              >
                <img
                  class="add-user-view__options-list-option-cta-img"
                  :src="require(`@/assets/newEntityView.png`)"
                  alt="entity view"
                />
                <BaseText class="add-user-view__options-list-option-cta-text">
                  Entity (Third Party) List
                </BaseText>
              </button>
            </li>
            <li class="add-user-view__options-list-option">
              <button
                :class="[
                  'add-user-view__options-list-option-cta',
                  {
                    'add-user-view__options-list-option-cta--selected':
                      viewType === userViewTypeText.EVENT_LOG
                  },
                  {
                    'add-user-view__options-list-option-cta--hidden':
                      isViewOptionHidden(userViewTypeText.EVENT_LOG)
                  }
                ]"
                @click="addViewType(userViewTypeText.EVENT_LOG)"
              >
                <img
                  class="add-user-view__options-list-option-cta-img"
                  :src="require(`@/assets/newEventLog.png`)"
                  alt="event log view"
                />
                <BaseText class="add-user-view__options-list-option-cta-text">
                  Event Log
                </BaseText>
              </button>
            </li>
            <li class="add-user-view__options-list-option">
              <button
                :class="[
                  'add-user-view__options-list-option-cta',
                  {
                    'add-user-view__options-list-option-cta--selected':
                      viewType === userViewTypeText.DASHBOARD
                  },
                  {
                    'add-user-view__options-list-option-cta--hidden':
                      isViewOptionHidden(userViewTypeText.DASHBOARD)
                  }
                ]"
                @click="addViewType(userViewTypeText.DASHBOARD)"
              >
                <img
                  class="add-user-view__options-list-option-cta-img"
                  :src="require(`@/assets/dashboard.png`)"
                  alt="dashboard view"
                />
                <BaseText class="add-user-view__options-list-option-cta-text">
                  Dashboard
                </BaseText>
              </button>
            </li>
          </ul>
          <InputErrorMessage
            v-if="!viewType"
            error="Please select at least one view type"
          />
        </div>
        <div class="add-user-view__option-configuration">
          <b-form>
            <b-form-group label="Name:">
              <b-form-input
                id="view-name"
                v-model="$v.viewName.$model"
                :state="$v.viewName.$dirty ? !$v.viewName.$error : null"
              />
              <InputErrorMessage
                v-if="!viewName && viewType"
                error="Please input a name"
              />
            </b-form-group>
          </b-form>

          <div v-if="viewType === userViewTypeText.DASHBOARD && viewName">
            <b-form-group label="Columns:">
              <v-select
                label="text"
                value="value"
                :options="columnsValueOptions"
                v-model="columnsValue"
                style="width: 100%"
                @input="resetColspan"
                id="columnValueSelect"
              />
              <InputErrorMessage
                v-if="!columnsValue"
                error="Please select column value"
              />
            </b-form-group>

            <b-row v-if="columnsValue && columnsValue.value">
              <b-col cols="6">
                <div class="add-user-view__bg">
                  <span style="margin-left: 10px">Available Widgets</span>
                  <b-input-group
                    class="m-2"
                    style="width: 95%; margin-left: 15px; margin-top: 5px"
                  >
                    <b-form-input
                      type="search"
                      v-model="filterValue"
                      placeholder="Search available widgets ..."
                      @input="filteredItems"
                    />
                  </b-input-group>

                  <div
                    class="m-2"
                    style="
                      max-height: calc(100vh - 520px);
                      overflow-y: auto;
                      cursor: -webkit-grab;
                      cursor: grab;
                    "
                  >
                    <div
                      v-for="(fields, fieldsIndex) in filteredAvailableWidgets"
                      :key="fieldsIndex"
                    >
                      <div class="add-user-view__dropdown-container">
                        <div
                          @click="fields.collapse = !fields.collapse"
                          class="add-user-view__dropdown-container-title"
                        >
                          <font-awesome-icon
                            :icon="fields.collapse ? 'minus' : 'plus'"
                          />
                          <span class="font-weight-bold ml-1">
                            {{ fields.title }}
                          </span>
                        </div>
                        <b-collapse class="p-2" v-model="fields.collapse">
                          <draggable
                            class="list-group"
                            :list="fields.widgets"
                            group="widgets"
                            @change="resetWidgets()"
                          >
                            <b-list-group
                              v-for="(widget, widgetsIndex) in fields.widgets"
                              :key="widgetsIndex"
                            >
                              <b-list-group-item
                                v-if="
                                  matchWidgetTypes(widget.type, fields.type)
                                "
                              >
                                {{ setWidgetLabel(widget) }}
                              </b-list-group-item>
                            </b-list-group>
                          </draggable>
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="6">
                <span style="margin-left: 10px">Selected Widgets</span>

                <div
                  style="display: grid; cursor: -webkit-grab; cursor: grab"
                  class="m-2"
                >
                  <b-table-simple
                    responsive="sm"
                    sticky-header="calc(100vh - 520px)"
                    fixed
                    small
                    style="overflow-x: hidden"
                  >
                    <b-thead>
                      <b-tr style="text-align: center">
                        <b-th> Widget </b-th>
                        <b-th> Column Span </b-th>
                        <b-th> </b-th>
                      </b-tr>
                    </b-thead>
                    <draggable
                      v-model="selectedWidgets"
                      tag="b-tbody"
                      group="widgets"
                      style="height: calc(100vh - 490px)"
                    >
                      <b-tr v-if="selectedWidgets.length == 0">
                        <b-td></b-td>
                        <b-td colspan="2">
                          <span style="color: gray; font-size: 14px">
                            Drag and Drop from Available Widgets
                          </span>
                          <InputErrorMessage
                            id="selectedWidgets"
                            error="Please select at least one widget"
                          />
                        </b-td>
                      </b-tr>
                      <b-tr
                        v-else
                        v-for="(fields, fieldsIndex) in selectedWidgets"
                        :key="fieldsIndex"
                        style="text-align: center"
                      >
                        <b-td>{{ setWidgetLabel(fields) }}</b-td>
                        <b-td>
                          <vue-number-input
                            :value="parseInt(fields.colspan)"
                            @change="setFieldsColspan($event, fields)"
                            :min="1"
                            :max="columnsValue.value"
                            inline
                            controls
                            :step="1"
                            size="200px"
                            center
                            :inputtable="false"
                          />
                        </b-td>
                        <b-td>
                          <b-button
                            @click="removeWidget(fields, fieldsIndex)"
                            variant="outline-danger"
                            size="sm"
                          >
                            <font-awesome-icon icon="trash" />
                          </b-button>
                        </b-td>
                      </b-tr>
                    </draggable>
                  </b-table-simple>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button
          size="md"
          variant="outline-danger"
          class="mr-3"
          @click="showDeleteModal"
          v-if="isInEditMode"
          style="position: absolute; left: 15px"
          >Delete View</b-button
        >

        <b-button
          size="md"
          variant="outline-danger"
          class="mx-1"
          @click="resetModal"
        >
          Close
        </b-button>
        <b-button
          size="md"
          variant="outline-success"
          @click="onsubmit"
          class="mx-1"
        >
          {{ isInEditMode ? "Update" : "Submit" }}
        </b-button>
      </template>
    </b-modal>

    <!-- Modal for delete confirmation -->
    <b-modal ref="modal-delete" title="Are you sure?" @ok="deleteView">
      <p class="my-4">Are you sure you want to delete view?</p>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { HTTP, makeAuthorizationHeader, hasStatus404 } from "@/utils";
import {
  visibleFields as visibleFieldsObj,
  actionName,
  urls,
  userViewType,
  userViewTypeText,
  userViewTypeMapText,
  userViewTypeTextMapInteger,
  customAttributesTypeData
} from "@/constants";
import draggable from "vuedraggable";
import { cloneDeep } from "lodash";
import { mapActions, mapState } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import BaseText from "@/atoms/BaseText/BaseText";
import { customAttributesService } from "@/services";
import { Helper } from "@/helpers";

export default {
  name: "AddNewView",
  mixins: [validationMixin],
  components: {
    draggable,
    InputErrorMessage,
    BaseText
  },
  data() {
    return {
      addViewModal: false,
      viewType: null,
      viewName: null,
      isInEditMode: false,
      viewId: null,
      submiting: false,
      columnsValue: null,
      columnsValueOptions: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 }
      ],
      filterValue: null,
      selectedWidgets: [],
      availableWidgets: [],
      filteredAvailableWidgets: [],
      tempavailableWidgets: [],
      nonFilteredtempavailableWidgets: [],
      collapse: {
        Supplier: false,
        Msq: false,
        Abcq: false,
        Msqrs: false
      },
      userViewTypeText
    };
  },
  computed: {
    ...mapState({
      companyDetails: (state) => state.company.companyDetails,
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  validations: {
    viewType: {
      required
    },
    viewName: {
      required
    }
  },
  created() {
    EventBus.$on(
      "addNewViewModal",
      (viewName, viewType, id, columnsValue, selectedWidgets) => {
        if (viewName && viewType && id) {
          this.viewName = viewName;
          this.viewType = userViewTypeTextMapInteger[viewType];
          if (viewType == userViewType.USER_VIEW_ANALYTICS) {
            this.columnsValueOptions.map((cols) => {
              if (cols.value == columnsValue) {
                this.columnsValue = cols;
              }
            });
            this.selectedWidgets = selectedWidgets;
            this.resetAvailableWidgets();
          }
          this.viewId = id;
          this.isInEditMode = true;
          this.addViewModal = true;
        } else {
          this.addViewModal = true;
          this.isInEditMode = false;
        }
      }
    );
  },
  mounted() {
    this.$v.$reset();
    this.getWidgetsList();
  },
  methods: {
    ...mapActions({
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS
    }),
    isViewOptionHidden(optionViewType) {
      return this.isInEditMode && this.viewType !== optionViewType;
    },
    resetAvailableWidgets() {
      this.selectedWidgets.map(() => {
        this.filteredAvailableWidgets.map((allItems, allItemsIndex) => {
          allItems.widgets.map((widget, index) => {
            let selectIndex = this.selectedWidgets.findIndex(
              (val) => val.key == widget.key
            );
            if (selectIndex != -1) {
              this.filteredAvailableWidgets[allItemsIndex].widgets.splice(
                index,
                1
              );
            }
          });
        });
      });
    },
    resetColspan() {
      this.selectedWidgets.map((items) => {
        if (this.columnsValue && items.colspan > this.columnsValue.value) {
          items.colspan = this.columnsValue.value;
        }
      });
    },
    showDeleteModal() {
      this.$refs["modal-delete"].show();
    },
    addViewType(val) {
      this.viewType = val;
    },
    onsubmit() {
      this.$v.viewName.$touch();
      this.$v.viewType.$touch();
      if (this.$v.viewName.$anyError || this.$v.viewType.$anyError) {
        return false;
      } else {
        if (
          this.viewType === userViewTypeText.DASHBOARD &&
          (this.columnsValue == null || this.selectedWidgets.length == 0)
        ) {
          return false;
        } else {
          if (this.isInEditMode) {
            this.updatePlatformView();
          } else {
            this.createPlatformView();
          }
        }
      }
    },
    resetModal() {
      if (this.viewType === userViewTypeText.ENTITY_LIST) {
        EventBus.$emit("closeNewViewModal");
      } else if (this.viewType === userViewTypeText.EVENT_LOG) {
        EventBus.$emit("closeNewViewEventLogModal");
      } else if (this.viewType === userViewTypeText.DASHBOARD) {
        EventBus.$emit("refreshDashboardView");
      }
      this.addViewModal = false;
      this.viewName = null;
      this.viewType = null;
      this.viewId = null;
      this.columnsValue = null;
      this.selectedWidgets = [];
      this.filterValue = null;
      this.availableWidgets = cloneDeep(this.nonFilteredtempavailableWidgets);
      this.filteredAvailableWidgets = cloneDeep(
        this.nonFilteredtempavailableWidgets
      );
      this.tempavailableWidgets = cloneDeep(
        this.nonFilteredtempavailableWidgets
      );
      this.$v.$reset();
    },

    // call create user view API
    async createPlatformView() {
      try {
        let visibleFields = [];
        if (this.viewType === userViewTypeText.ENTITY_LIST) {
          if (this.companyDetails?.additionalData?.selectedColumns?.length) {
            this.companyDetails.additionalData.selectedColumns.forEach(
              (items) => {
                if (items.default) {
                  visibleFields.push(items);
                }
              }
            );
          } else {
            visibleFields = visibleFieldsObj.fields;
          }
        } else {
          visibleFields = [];
        }
        let configuredColumns = {
          visibleFields,
          filters: [],
          widgets: {
            columnsValue: this.columnsValue ? this.columnsValue.value : null,
            selectedWidgets: this.selectedWidgets
          }
        };
        this.submiting = true;
        let formData = {
          company_id: this.companyId,
          created_by_id: this.userId,
          title: this.viewName,
          description: null,
          view_type: userViewTypeMapText[this.viewType],
          configuration: configuredColumns,
          is_active: 1
        };
        let result = await HTTP(
          "post",
          "v1/platform/view/create",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data && result.data.success == 1) {
          this.resetModal();
          await this.fetchAdditionalVouchers();
          this.redirectBasedOnViewType(result.data.data);
        } else if (result.data.status == 0) {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
        }
        this.submiting = false;
      } catch (error) {
        this.submiting = false;
        return [error];
      }
    },
    redirectBasedOnViewType({ view_type, id } = {}) {
      if (parseInt(view_type) === userViewType.THIRD_PARTY) {
        this.$router.push(urls.MANAGE_THIRD_PARTY_ID(id));
      } else if (parseInt(view_type) === userViewType.EVENT_LOG) {
        this.$router.push(urls.EVENT_LOG_ID(id));
      } else {
        this.$router.push(urls.ANALYTICS_USER_VIEW_ID(id));
      }
    },
    async updatePlatformView() {
      let configuredColumns = {
        visibleFields: [],
        filters: [],
        widgets: {
          columnsValue: this.columnsValue ? this.columnsValue.value : null,
          selectedWidgets: this.selectedWidgets
        }
      };
      let formData = {
        company_id: this.companyId,
        configuration:
          this.viewType === userViewTypeText.DASHBOARD
            ? configuredColumns
            : null,
        title: this.viewName,
        created_by_id: this.userId,
        view_type: userViewTypeMapText[this.viewType],
        description: null
      };
      this.submiting = true;
      let result = HTTP(
        "put",
        `v1/platform/view/update/${this.viewId}`,
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then(async (response) => {
          if (response.data.success == 1) {
            await this.fetchAdditionalVouchers();
            this.resetModal();
            this.$bvToast.toast(response.data.message, {
              title: "Done",
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
          } else if (response.data.status == 0) {
            this.$bvToast.toast(response.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
          }
          this.submiting = false;
        })
        .catch((error) => {
          this.submiting = false;
          return error;
        });
    },
    async deleteView() {
      let formData = {
        company_id: this.companyId
      };
      this.submiting = true;
      let result = HTTP(
        "post",
        `v1/platform/view/delete/${this.viewId}`,
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then(async (response) => {
          if (response.data.success == 1) {
            if (response.data.platformViews.length == 0) {
              this.$store.state.sidebarMenu = [];
            }
            this.resetModal();
            await this.fetchAdditionalVouchers();
            this.submiting = false;
            this.$bvToast.toast(response.data.message, {
              title: "Done",
              variant: "success",
              autoHideDelay: 2000,
              appendToast: false
            });
            if (this.viewType === userViewTypeText.ENTITY_LIST) {
              this.$router.push("/manage-third-parties");
            } else if (this.viewType === userViewTypeText.EVENT_LOG) {
              this.$router.push("/event-log");
            } else {
              this.$router.push("/analytics-overview");
            }
          } else if (response.data.success == 0) {
            this.$bvToast.toast(response.data.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false
            });
          }
          this.submiting = false;
        })
        .catch((error) => {
          this.submiting = false;
          return error;
        });
    },
    filteredItems() {
      if (this.filterValue) {
        this.filteredAvailableWidgets.map((allItems, allItemsIndex) => {
          allItems.widgets.map((widget, index) => {
            let selectIndex = this.selectedWidgets.findIndex(
              (val) => val.key == widget.key
            );
            if (selectIndex != -1) {
              this.availableWidgets[allItemsIndex].widgets.splice(index, 1);
              this.filteredAvailableWidgets[allItemsIndex].widgets.splice(
                index,
                1
              );
            }
          });
        });
        this.availableWidgets.map((fields, index) => {
          this.filteredAvailableWidgets[index].widgets = fields.widgets.filter(
            (item) =>
              item.label.toLowerCase().indexOf(this.filterValue.toLowerCase()) >
              -1
          );
        });
      } else {
        this.availableWidgets = cloneDeep(this.nonFilteredtempavailableWidgets);
        this.filteredAvailableWidgets = cloneDeep(
          this.nonFilteredtempavailableWidgets
        );
        this.resetAvailableWidgets();
      }
    },
    handleChange() {
      if (!this.filterValue) {
        this.tempavailableWidgets = this.filteredAvailableWidgets;
      } else {
        this.tempavailableWidgets = this.nonFilteredtempavailableWidgets;
        this.filteredAvailableWidgets.map((items, index) => {
          items.widgets.map((widget) => {
            let selectIndex = this.tempavailableWidgets[
              index
            ].widgets.findIndex((val) => val.key == widget.key);
            if (selectIndex == -1) {
              this.tempavailableWidgets[index].widgets.push(items);
            }
          });
        });
      }
      this.filteredAvailableWidgets.map((items) => {
        items.widgets = this.dynamicSort(items.widgets);
      });
    },
    resetWidgets() {
      let allwidget = [];
      this.filteredAvailableWidgets.map((items) => {
        items.widgets.map((widget) => {
          allwidget.push(widget);
        });
      });
      this.filteredAvailableWidgets.map((items, index) => {
        allwidget.map((widget) => {
          if (this.matchWidgetTypes(widget.type, items.type)) {
            let isPresentIndex = this.filteredAvailableWidgets[
              index
            ].widgets.findIndex((val) => val.key == widget.key);
            if (isPresentIndex == -1) {
              this.filteredAvailableWidgets[index].widgets.push(widget);
            }
          }
        });
      });
      this.filteredAvailableWidgets.map((items, index) => {
        allwidget.map((widget) => {
          let isPresentIndex = this.filteredAvailableWidgets[
            index
          ].widgets.findIndex((val) => {
            if (
              items.type.includes(customAttributesTypeData.CUSTOM_ATTRIBUTE)
            ) {
              return (
                val.key === widget.key &&
                !widget.type.includes(customAttributesTypeData.CUSTOM_ATTRIBUTE)
              );
            }
            return val.key === widget.key && widget.type !== items.type;
          });
          if (isPresentIndex != -1) {
            this.filteredAvailableWidgets[index].widgets.splice(
              isPresentIndex,
              1
            );
          }
        });
      });
      this.handleChange();
    },
    matchWidgetTypes(type1, type2) {
      return (
        type1 === type2 ||
        (type1.includes(customAttributesTypeData.CUSTOM_ATTRIBUTE) &&
          type2.includes(customAttributesTypeData.CUSTOM_ATTRIBUTE))
      );
    },
    setFieldsColspan(value, fields) {
      let columnsIndex = this.selectedWidgets.findIndex(
        (x) => x.key == fields.key
      );
      if (columnsIndex != -1) {
        this.selectedWidgets[columnsIndex].colspan = value;
      }
    },
    getWidgetsList() {
      let result = HTTP(
        "post",
        "v1/platform/view/get/widgets",
        {
          company_id: this.companyId
        },
        makeAuthorizationHeader()
      );

      return result
        .then(async (response) => {
          const customAttributeWidgets =
            await this.getCustomAttributeWidgetsList();
          this.availableWidgets = this.makeAvailableWidgets(
            response?.data,
            customAttributeWidgets
          );
          this.filteredAvailableWidgets = cloneDeep(this.availableWidgets);
          this.tempavailableWidgets = cloneDeep(this.availableWidgets);
          this.nonFilteredtempavailableWidgets = cloneDeep(
            this.availableWidgets
          );
        })
        .catch((error) => {
          this.submiting = false;
          return error;
        });
    },
    makeAvailableWidgets(data = [], customAttributeWidgets = {}) {
      if (customAttributeWidgets?.widgets?.length) {
        return [...data, customAttributeWidgets];
      }

      return data;
    },
    async getCustomAttributeWidgetsList() {
      try {
        const data =
          await customAttributesService.fetchCustomAttributesWidgetsList(
            this.companyId
          );

        return {
          ...data,
          collapse: true,
          id: "999",
          widgets: this.makeWidgetList(data?.widgets)
        };
      } catch (error) {
        if (hasStatus404(error)) {
          return null;
        }

        Helper.makeToast(
          this.$bvToast,
          "danger",
          "Unable to retrieve custom attribute widgets, please try refreshing the browser. If the issue persists please contact support."
        );
      }
    },
    makeWidgetList(widgets = []) {
      if (!widgets?.length) {
        return [];
      }

      return widgets?.map((widget, index) => ({
        ...widget,
        colspan: 0,
        index,
        key: widget?.type || ""
      }));
    },
    setWidgetLabel(widget) {
      if (
        widget?.type?.includes(customAttributesTypeData.CUSTOM_ATTRIBUTE) &&
        !widget?.isActive
      ) {
        return `${widget?.label} (inactive)`;
      }

      return widget?.label;
    },
    removeWidget(field, index) {
      this.selectedWidgets.splice(index, 1);
      this.filteredAvailableWidgets.map((items) => {
        if (this.matchWidgetTypes(field.type, items.type)) {
          items.widgets.splice(field.index, 0, field);
          items.widgets = this.dynamicSort(items.widgets);
        }
      });
    },
    dynamicSort(arr) {
      const copy = arr.slice();
      const sorter = (a, b) => a.index - b.index;
      copy.sort(sorter);
      const res = copy.map((items) => items);
      return res;
    }
  }
};
</script>

<style lang="scss" scoped>
.add-user-view {
  &__wrapper {
    display: flex;
  }

  &__bg {
    background: $light-silver;
  }

  &__option-configuration {
    width: 100%;
    padding-left: 20px;
  }

  &__dropdown-container {
    margin-top: 10px;

    &-title {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  &__options {
    width: 125px;

    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      &-option {
        margin-bottom: 10px;

        &-cta {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          outline: 1px solid $light-silver;
          border: none;
          border-radius: 5px;
          color: $black;
          display: block;
          padding: 0;
          background: $white;
          transition: background $transition-duration;

          &:focus,
          &:hover,
          &:active {
            background: $white;
          }

          &.add-user-view__options-list-option-cta--selected {
            background: $tea-green;
            outline: 3px solid $light-silver;
          }

          &--hidden {
            display: none;
          }

          &-img {
            width: 100%;
            padding: 10px;
            height: auto;
          }

          &-text {
            color: $black;
          }
        }
      }
    }
  }
}

:deep(.add-user-view__modal) {
  margin: 0;
}

:deep(.add-user-view__modal-content) {
  height: calc(100vh - 95px);
}

:deep(.add-user-view__modal-dialog) {
  margin: 5px;
  width: 100%;
  margin-top: 90px;
  max-width: calc(100% - 10px);
}
</style>
