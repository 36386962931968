var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":"Table","active":""}},[_c('b-tabs',{attrs:{"pills":"","card":"","vertical":""}},_vm._l((_vm.form.formTranslations),function(language,index){return _c('b-tab',{key:index,attrs:{"title":language},on:{"click":function($event){return _vm.tabLabelClick(language)}}},[_c('div',[_c('b-button',{staticClass:"mr-2 my-2",attrs:{"size":"sm"},on:{"click":_vm.onAddRowrModal}},[_vm._v("Add Row")]),_c('b-button',{staticClass:"mr-2 my-2",attrs:{"size":"sm"},on:{"click":_vm.onAddHeaderModal}},[_vm._v("Add Header")]),_c('b-table-simple',{attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.fieldData.tableHeaders),function(field,index){return _c('b-th',{key:index,attrs:{"id":field.id}},[_vm._v(" "+_vm._s(field.heading[_vm.transalationLanguage])+" "),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onDeleteCol(index)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onEditCol(index, field)}}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}})],1)],1)}),1)],1),(
                    _vm.fieldData.tableRowsItems.length &&
                    _vm.fieldData.tableHeaders.length
                  )?_c('b-tbody',_vm._l((_vm.fieldData.tableRowsItems),function(itemRow,itemIndex){return _c('b-tr',{key:itemIndex},[_c('b-th',[_vm._v(_vm._s(itemRow.text[_vm.transalationLanguage]))]),_vm._l((_vm.fieldData.tableHeaders.length -
                      1),function(item,index){return _c('b-td',{key:index},[_c('b-button',{on:{"click":function($event){return _vm.addColModal(itemRow, itemIndex, index)}}},[_vm._v("Add col")])],1)}),_c('b-td',[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onDeleteRow(itemIndex)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)],1)],2)}),1):_vm._e()],1)],1)])}),1)],1),_c('b-tab',{attrs:{"title":"Configuration"}},[_c('b-form-checkbox',{model:{value:(_vm.fieldData.enableNumbers),callback:function ($$v) {_vm.$set(_vm.fieldData, "enableNumbers", $$v)},expression:"fieldData.enableNumbers"}},[_vm._v("Enable Serial Numbers")])],1)],1)],1),_c('b-modal',{attrs:{"title":"Add New Column"},on:{"ok":function($event){_vm.isAddHeader
        ? _vm.addHeader()
        : _vm.isAddRow
        ? _vm.addRow()
        : _vm.isAddCol
        ? _vm.addCol()
        : null}},model:{value:(_vm.showAddColModal),callback:function ($$v) {_vm.showAddColModal=$$v},expression:"showAddColModal"}},[(_vm.isAddCol)?_c('b-form-group',{attrs:{"label":"Select Type"}},[_c('b-form-radio-group',{attrs:{"options":_vm.optionsColsTypes},model:{value:(_vm.selectedColType),callback:function ($$v) {_vm.selectedColType=$$v},expression:"selectedColType"}})],1):_vm._e(),(_vm.selectedColType == 'text')?_c('b-form-group',{attrs:{"label":"Column Name"}},[_c('b-form-input',{model:{value:(_vm.colHeading),callback:function ($$v) {_vm.colHeading=$$v},expression:"colHeading"}})],1):_vm._e(),(_vm.selectedColType == 'radio')?_c('b-form',[_c('b-form-group',{attrs:{"label":"Column Name"}},[_c('b-form-input',{model:{value:(_vm.colHeading),callback:function ($$v) {_vm.colHeading=$$v},expression:"colHeading"}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }