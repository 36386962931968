const getterName = {
  PLATFORM_SETTINGS: {
    HAS_PUBLIC_PLATFORM_SETTINGS: "platformSettings/hasPublicPlatformSettings",
    HAS_PLATFORM_SETTINGS: "platformSettings/hasPlatformSettings",
    GET_PLATFORM_USER_LIST_OPTIONS:
      "platformSettings/getPlatformUserListOptions",
    GET_PRODUCT_VERSIONS_LIST: "platformSettings/getProductVersionsList"
  },
  USER: {
    IS_USER_ADMIN: "user/isUserAdmin",
    IS_USER_SUPER_ADMIN: "user/isUserSuperAdmin",
    IS_USER_SUPER_ADMIN_OR_ADMIN: "user/isUserSuperAdminOrAdmin",
    IS_USER_THIRD_PARTY: "user/isUserThirdParty",
    IS_USER_NOT_UPDATED: "user/isUserNotUpdated",
    HAS_NO_USER_DATA: "user/hasNoUserData",
    IS_LOGGED_IN_VIA_THE_PLATFORM: "user/isLoggedInViaThePlatform",
    GET_COMPANY_NAME: "user/getCompanyName",
    GET_FULL_NAME: "user/getFullName"
  },
  USER_SETTINGS: {
    HAS_PLATFORM_PAGE_AND_PAGE_LIST: "userSettings/hasHomePageAndPageList"
  },
  ACTIONS: {
    GET_SYSTEM_ACTIONS: "actions/getSystemActions",
    GET_DDQ_TASKS: "actions/getDDQTasks"
  },
  AUTH: {
    IS_USER_AUTHENTICATED: "auth/isUserAuthenticated"
  },
  ROLES: {
    GET_ID_TO_ROLE_NAME_MAP: "roles/getIdToRoleNameMap",
    GET_IDS_TO_ROLE_NAMES: "roles/getIdsToRoleNames"
  },
  ESG: {
    GET_LATEST_ESG_REPORT_SUMMARY: "esg/getLatestESGReportSummary"
  }
};

export { getterName };
