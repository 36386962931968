import {
  userRolesKeysDTO,
  userRolesKeysAPIDTO,
  userPermissionKeysDTO,
  userPermissionKeysAPIDTO
} from "@/constants";

const makeRoleDTO = (role = {}) => ({
  [userRolesKeysDTO.ID]: role?.[userRolesKeysAPIDTO.ID] || "",
  [userRolesKeysDTO.NAME]: role?.[userRolesKeysAPIDTO.NAME] || "",
  [userRolesKeysDTO.DESCRIPTION]: role?.[userRolesKeysAPIDTO.DESCRIPTION] || "",
  [userRolesKeysDTO.IS_SYSTEM]: role?.[userRolesKeysAPIDTO.IS_SYSTEM] || ""
});

const makeRoleAPIDTO = (role = {}) => ({
  [userRolesKeysAPIDTO.NAME]: role[userRolesKeysDTO.NAME] || "",
  [userRolesKeysAPIDTO.DESCRIPTION]: role[userRolesKeysDTO.DESCRIPTION] || ""
});

const makeRolesDTO = (rolesData = []) => rolesData?.map(makeRoleDTO);

const makePermissionsDTO = (permission = {}) => ({
  [userPermissionKeysDTO.ID]: permission?.[userPermissionKeysAPIDTO.ID] || "",
  [userPermissionKeysDTO.NAME]:
    permission?.[userPermissionKeysAPIDTO.NAME] || "",
  [userPermissionKeysDTO.ACTION]:
    permission?.[userPermissionKeysAPIDTO.ACTION] || "",
  [userPermissionKeysDTO.SUBJECT]:
    permission?.[userPermissionKeysAPIDTO.SUBJECT] || "",
  [userPermissionKeysDTO.DESCRIPTION]:
    permission?.[userPermissionKeysAPIDTO.DESCRIPTION] || "",
  [userPermissionKeysDTO.CONTEXTS]:
    permission?.[userPermissionKeysAPIDTO.CONTEXTS]?.map((context) => ({
      [userPermissionKeysDTO.COMPANY_ID]:
        context?.[userPermissionKeysAPIDTO.COMPANY_ID] || "",
      [userPermissionKeysDTO.CUSTOM_ATTRIBUTE_ID]:
        context?.[userPermissionKeysAPIDTO.CUSTOM_ATTRIBUTE_ID] || ""
    })) || []
});

export { makeRoleDTO, makeRoleAPIDTO, makeRolesDTO, makePermissionsDTO };
