const shapes = {
  NONE: "none",
  SQUARE: "square",
  CIRCLE: "circle",
  TRIANGLE: "triangle",
  OCTAGON: "octagon",
};

const icons = {
  CARET_UP: "caret-up",
  CARET_DOWN: "caret-down",
  EDIT: "edit"
};

export { shapes, icons };
