<template>
   <div>
       <div v-if="!isPrintContent">
        <div>
          <b-card
            style="margin-right: 20px;width: max-content;min-width: 186px;max-height: 186px;"
            class="text-center statsCard"
          >
            <span>
              <b>
                % {{$t('eb360.statsTabChart.startedCard')}}
              </b>
            </span>
            <b-card-text
              style="justify-content:center"
              class="text-center d-flex"
            >

              <div
                style="align-self: center;"
                v-if="avgDdqStatPercent.length"
              >
                <span
                  v-for="(item,index) in avgDdqStatPercent"
                  :key="index"
                >
                  <div class="d-flex">
                    <div>
                      <span style="font-size:60px">
                        <span :style="item.avg_tpWithDDQNotStarted == 0 ? 'align-self: center;color:#4D9DE0!important;font-size:60px' : (item.status_tpWithDDQNotStarted == 1  ? 'color: #3ddc97;font-size:60px' : 'color: #e71d36;font-size:60px') ">
                          {{item.avg_tpWithDDQNotStarted ? item.avg_tpWithDDQNotStarted : '0'}}%</span></span>
                    </div>
                    <div v-if="item.avg_tpWithDDQNotStarted != 0"  style="align-self: center;">
                      <span v-if=" item && item.status_tpWithDDQNotStarted == 1">
                        <font-awesome-icon
                          style="font-size: 50px;color: #3ddc97 ;"
                          icon="long-arrow-alt-up"
                        />
                      </span>
                      <span v-else-if="item && item.status_tpWithDDQNotStarted == 0">
                        <font-awesome-icon
                          style="font-size: 50px; color: #e71d36;"
                          icon="long-arrow-alt-down"
                        />
                      </span>
                    </div>
                  </div>
                </span>
              </div>
                <div class="text-right" style="color: #515254;display: grid;margin-left:35px">
                <span
                  v-for="(item,index) in overallValues"
                  :key="index"
                >
                  <span style="font-size:25px;color:#529fe0;">{{ item.tpWithDDQNotStartedpar }}%</span>
                  <span style="margin-left:10px">Period {{index + 1}}  </span>
                </span>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </div>
      <b-row v-if="isPrintContent" class="my-4">
        <b-col cols="4">
            <div class="mt-2">
                <b-card
                    style="width: max-content;min-width: auto;max-height: auto;"
                    class="text-center statsCard"
                >
                    <span>
                    <b>
                        % {{$t('eb360.statsTabChart.startedCard')}}
                    </b>
                    </span>
                    <b-card-text
                    style="justify-content:center"
                    class="text-center d-flex"
                    >

                    <div
                        style="align-self: center;"
                        v-if="avgDdqStatPercent.length"
                    >
                        <span
                        v-for="(item,index) in avgDdqStatPercent"
                        :key="index"
                        >
                        <div class="d-flex">
                            <div>
                            <span style="font-size:30px">
                                <span :style="item.status_tpWithDDQNotStarted == 1 ? 'color: #3ddc97' : 'color: #e71d36'">
                                {{item.avg_tpWithDDQNotStarted ? item.avg_tpWithDDQNotStarted : '0'}}%</span></span>
                            </div>
                            <div>
                            <span v-if=" item && item.status_tpWithDDQNotStarted == 1">
                                <font-awesome-icon
                                style="font-size: 40px;color: #3ddc97 ;"
                                icon="arrow-up"
                                />
                            </span>
                            <span v-else-if="item && item.status_tpWithDDQNotStarted == 0">
                                <font-awesome-icon
                                style="font-size: 40px; color: #e71d36;"
                                icon="arrow-down"
                                />
                            </span>
                            </div>
                        </div>
                        </span>
                    </div>
                        <div class="text-right" style="color: #515254; font-weight: bold;display: grid;margin-left:22px">
                        <span
                        v-for="(item,index) in overallValues"
                        :key="index"
                        >
                        <span style="font-size:40px;color:#529fe0;">{{ item.tpWithDDQNotStartedpar }}%</span>
                        <span style="margin-left:10px">Period {{index + 1}}  </span>
                        </span>
                    </div>
                    </b-card-text>
                </b-card>
            </div>
        </b-col>
      </b-row>
   </div>
</template>

<script>
export default {
  name: "DDQCompareNotStartedCountCard",
  props:{
      isPrintContent: Boolean,
      avgDdqStatPercent: Array,
      overallValues: Array
  },
}
</script>