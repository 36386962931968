import { attributeType } from "@/constants";

/**
 * @typedef CustomAttributeDTO
 * @property {string | number} id - id of custom attribute
 * @property {string} value - value of custom attribute
 * @property {string} name - name of custom attribute
 * @property {string} description - description of custom attribute
 */

/**
 * Returns an object
 * @param {object} customAttribute - single custom attribute object
 * @param {number | string} id - id to be used for custom attribute id
 * @returns {CustomAttributeDTO} - single custom attribute DTO
 */
export const makeCustomAttributeDTO = (customAttribute, id) => ({
  id: customAttribute?.id || `custom-attribute-${id}`,
  value: customAttribute?.tp_values_meta || "",
  name: customAttribute?.tp_customatt || "Custom attribute name not provided",
  description: customAttribute?.description || ""
});

/**
 * Returns A list of objects
 * @param {Array} customAttributes - List of custom attributes
 * @returns {CustomAttributeDTO[]} - list of custom attribute DTOs
 */
export const makeCustomAttributeListDTO = (customAttributes) => {
  const tempRatingCustomAttributes = Array.isArray(customAttributes)
    ? customAttributes
    : [];
  return tempRatingCustomAttributes.reduce((acc, customAttribute, index) => {
    if (customAttribute.tp_type === attributeType.RATING) {
      acc.push(makeCustomAttributeDTO(customAttribute, index));
    }
    return acc;
  }, []);
};
