<template>
  <b-tabs content-class="mt-3">
    <b-tab title="Text" active>
      <b-tabs pills card vertical>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <!-- translation form wrappe -->
          <div>
            <b-form-group label="Section Sub Title Title">
              <b-form-input
                v-model="fieldData.label[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="First Select Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="labelFirst"
                :placeholder="fieldData.labelFirst[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Seconed Select Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="labelSeconed"
                :placeholder="fieldData.labelSeconed[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Third Select Label">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="labelThird"
                :placeholder="fieldData.labelThird[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Abbreviation Label">
              <b-form-input
                @blur="addLanguageToField(data)"
                v-model="fieldData.abbreviationLabelText[transalationLanguage]"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Text for table">
              <b-form-input
                @blur="addLanguageToField(data)"
                v-model="fieldData.textForTable[transalationLanguage]"
                maxlength="18"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="First Select Helper Text">
              <b-form-textarea
                @blur="addLanguageToField(language)"
                v-model="helperTextFirst"
                :placeholder="fieldData.helperTextFirst[transalationLanguage]"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Seconed Select Helper Text">
              <b-form-textarea
                @blur="addLanguageToField(language)"
                v-model="helperTextSeconed"
                :placeholder="fieldData.helperTextSeconed[transalationLanguage]"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Third Select Helper Text">
              <b-form-textarea
                @blur="addLanguageToField(language)"
                v-model="helperTextThird"
                :placeholder="fieldData.helperTextThird[transalationLanguage]"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="First Select Validation Message">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="validationMessageFirstSelect"
                :placeholder="
                  fieldData.validationMessageFirstSelect[transalationLanguage]
                "
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Seconed Select Validation Message">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="validationMessageSeconedSelect"
                :placeholder="
                  fieldData.validationMessageSeconedSelect[transalationLanguage]
                "
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Third Select Validation Message">
              <b-form-input
                @blur="addLanguageToField(language)"
                v-model="validationMessageThirdSelect"
                :placeholder="
                  fieldData.validationMessageThirdSelect[transalationLanguage]
                "
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- for charts enable and disable -->
    <b-tab title="Configure">
      <b-form-group label="Enter Weightage (in %)">
        <b-form-input
          no-wheel
          placeholder="0"
          max="2"
          maxlength="2"
          trim
          onkeydown="return (event.keyCode >= 46 && event.keyCode <= 57) || event.keyCode == 8"
          v-model="fieldData.weightage"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Select Grphical Representaton for Data">
        <b-form-radio-group
          stacked
          v-model="fieldData.selectedCharts"
          id="checkbox-group-1"
          :options="optionsCharts"
        ></b-form-radio-group>
      </b-form-group>
    </b-tab>

    <!-- options tabb -->
    <b-tab title="Options" v-if="fieldData.inputType === 'comboselect'">
      <!-- select options tab -->
      <b-tabs pills card vertical>
        <b-tab
          @click="tabClick(language)"
          v-for="(language, index) in form.formTranslations"
          :key="index"
          :title="language"
        >
          <b-form @submit.stop.prevent class="active text-left mt-2">
            <table class="table table-sm">
              <thead>
                <tr class="text-center">
                  <th>Option text</th>
                  <th>Value</th>
                  <th>Score</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, optionIndex) in fieldData.options"
                  :key="optionIndex"
                >
                  <td>
                    <b-form-input
                      :value="option.text[transalationLanguage]"
                      @input="setFieldOptionText($event, optionIndex)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      :disabled="transalationLanguage !== 'english'"
                      :value="option.value"
                      @input="setFieldOptionValue($event, optionIndex)"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      :placeholder="JSON.stringify(option.score)"
                      @input="optionValue = $event"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-button
                      variant="outline-danger"
                      v-if="optionIndex > 1"
                      class="btn btn-outline-danger btn-sm"
                      @click="onDeleteOption(optionIndex)"
                    >
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-button variant="primary" @click="addOption">
              <i class="fa fa-plus"></i>
            </b-button>
            <br />
          </b-form>
        </b-tab>
      </b-tabs>
    </b-tab>

    <!-- attribute tab -->
    <b-tab title="Attributes">
      <b-form @submit.stop.prevent class="text-left mt-2">
        <div class="form-group col-md-6">
          <label for>ID</label>
          <b-form-input v-model="selectId"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Class</label>
          <b-form-input v-model="selectClass"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Title</label>
          <b-form-input v-model="selectTitle"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>Post Name</label>
          <b-form-input v-model="selectedPostName"></b-form-input>
        </div>
        <div class="form-group col-md-6">
          <label for>style</label>
          <b-form-textarea
            id="textarea"
            v-model="selectStyle"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div class="form-check">
          <b-form-checkbox
            id="checkbox-1"
            v-model="selecReadOnly"
            name="checkbox-1"
            >Readonly</b-form-checkbox
          >
        </div>
        <div class="form-check">
          <b-form-checkbox
            id="checkbox-4"
            value="1"
            unchecked-value="0"
            v-model="fieldData.enableSelfReportingForThirdPartyProfile"
            name="checkbox-4"
            >Show Self Reporting Data in Third Party Profile</b-form-checkbox
          >
          <b-form-checkbox
            id="checkbox-5"
            value="1"
            unchecked-value="0"
            v-model="fieldData.enableSelfReportingForManageThirdParty"
            name="checkbox-5"
            >Show Self Reporting Data in Mangage Third Party</b-form-checkbox
          >
        </div>
        <div class="form-check">
          <b-form-checkbox
            id="checkbox-6"
            value="1"
            unchecked-value="0"
            v-model="fieldData.enableFilterForManageThirdParty"
            name="checkbox-5"
            >Filter in Mangage Third Party</b-form-checkbox
          >
        </div>
      </b-form>
    </b-tab>

    <!-- data tab -->
    <b-tab title="Data">
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>Key</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(attribute, index) in fieldData.dataAttributes"
            :key="index"
          >
            <td class="text-right align-middle">data-</td>
            <td>
              <b-form-input v-model="attribute.dataAttributeKey"></b-form-input>
            </td>
            <td>
              <b-form-input
                v-model="attribute.dataAttributeValue"
              ></b-form-input>
            </td>
            <td>
              <button
                class="btn btn-danger btn-sm"
                @click="onDeleteDataAttribute(index)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary btn-sm" @click="addDataAttribute">
        <i class="fa fa-plus"></i>
      </button>
    </b-tab>

    <!-- data validation -->
    <b-tab title="Validation">
      <div class="text-left">
        <b-col>
          <b-form-checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireFirstSelect"
            value="1"
            unchecked-value="0"
            >{{ this.fieldData.labelFirst.english }} Is
            required?</b-form-checkbox
          >
          <b-form-checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireSeconedSelect"
            value="1"
            unchecked-value="0"
            >{{ this.fieldData.labelSeconed.english }} Is
            required?</b-form-checkbox
          >
          <b-form-checkbox
            class="form-group col-md-6 ml-3"
            v-model="isRequireThirdSelect"
            value="1"
            unchecked-value="0"
            >{{ this.fieldData.labelThird.english }} Is
            required?</b-form-checkbox
          >
        </b-col>
      </div>
    </b-tab>

    <!-- Advance for select only -->
    <b-tab title="Advanced">
      <div class="text-left">
        <b-row>
          <b-col>
            <h3>{{ fieldData.labelFirst.english }}</h3>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isMultipleSelect"
                >Allow Multiple Selection</b-form-checkbox
              >
            </b-form-group>
            <b-row v-if="isMultipleSelect" class="ml-1">
              <b-col cols="4">
                <b-form-group label="Minimum number of selection">
                  <b-form-input
                    type="number"
                    v-model="minSelection"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Maximum number of selection">
                  <b-form-input
                    type="number"
                    v-model="maxSelection"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isLiveSearchable"
                >Enable Live Search</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isClearable"
                >Clearable</b-form-checkbox
              >
            </b-form-group>
            <b-form-group label="Number of options to be displayed">
              <b-form-input
                type="number"
                v-model="renderOptionsLimit"
                placeholder="0"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <h3>{{ fieldData.labelSeconed.english }}</h3>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isMultipleSelectSecond"
                >Allow Multiple Selection</b-form-checkbox
              >
            </b-form-group>
            <b-row v-if="isMultipleSelectSecond" class="ml-1">
              <b-col cols="4">
                <b-form-group label="Minimum number of selection">
                  <b-form-input
                    type="number"
                    v-model="minSelectionSecond"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Maximum number of selection">
                  <b-form-input
                    type="number"
                    v-model="maxSelectionSecond"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isLiveSearchableSecond"
                >Enable Live Search</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isClearableSecond"
                >Clearable</b-form-checkbox
              >
            </b-form-group>
            <b-form-group label="Number of options to be displayed">
              <b-form-input
                type="number"
                v-model="renderOptionsLimitSecond"
                placeholder="0"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <h3>{{ fieldData.labelThird.english }}</h3>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isMultipleSelectThird"
                >Allow Multiple Selection</b-form-checkbox
              >
            </b-form-group>
            <b-row v-if="isMultipleSelectThird" class="ml-1">
              <b-col cols="4">
                <b-form-group label="Minimum number of selection">
                  <b-form-input
                    type="number"
                    v-model="minSelectionThird"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Maximum number of selection">
                  <b-form-input
                    type="number"
                    v-model="maxSelectionThird"
                    placeholder="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isLiveSearchableThird"
                >Enable Live Search</b-form-checkbox
              >
            </b-form-group>
            <b-form-group>
              <b-form-checkbox class="mt-2" v-model="isClearableThird"
                >Clearable</b-form-checkbox
              >
            </b-form-group>
            <b-form-group label="Number of options to be displayed">
              <b-form-input
                type="number"
                v-model="renderOptionsLimitThird"
                placeholder="0"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-tab>

    <!-- condirional -->
    <b-tab title="Conditional">
      <div class="form-group col-md-6">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id />
          <label class="form-check-label" for>Hide on load</label>
        </div>
      </div>
    </b-tab>
    <b-tab title="Data Source" v-if="fieldData.inputType === 'comboselect'">
      <div class="form-group col-md-6">
        <b-form-group label="Select dataset options">
          <b-form-radio-group
            stacked
            id="radio-group-1"
            v-model="selectedDataSource"
            :options="dataSourceOptions"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
        <p v-if="selectedDataSource === 1">Please add options in option tab</p>
      </div>
    </b-tab>
  </b-tabs>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "AdvanceRadioSettings",
  data() {
    return {
      selectedCharts: [],
      optionsCharts: [
        { text: "None", value: null },
        { text: "Line", value: "line" },
        { text: "Bar", value: "bar" },
        { text: "Pie", value: "pie" },
        { text: "Area", value: "area" },
        { text: "Column", value: "column" },
        { text: "Scatter", value: "scatter" },
        { text: "Pyramid", value: "pyramid" },
        { text: "Funnel", value: "funnel" },
        { text: "Columnpyramid", value: "columnpyramid" },
        { text: "Country Map", value: "countrymap" }
      ],
      selectedDataSource: 1,
      dataSourceOptions: [
        { text: "Settings Options", value: 1 },
        { text: "Continent & Countries", value: 2 }
      ],
      isConditional: 0,
      transalationLanguage: "english",
      label: undefined,
      labelFirst: undefined,
      labelSeconed: undefined,
      labelThird: undefined,
      helperTextFirst: undefined,
      helperTextSeconed: undefined,
      helperTextThird: undefined,
      optiponName: undefined,
      optionValue: undefined,
      dataAttributeValue: undefined,
      dataAttributeKey: undefined,
      isRequireFirstSelect:
        this.fieldData.validation &&
        this.fieldData.validation.requiredFirstSelect,
      isRequireSeconedSelect:
        this.fieldData.validation &&
        this.fieldData.validation.requiredSeconedSelect,
      isRequireThirdSelect:
        this.fieldData.validation &&
        this.fieldData.validation.requiredThirdSelect,
      validationMessageFirstSelect:
        this.fieldData.validation && this.fieldData.validation.message
          ? this.fieldData.validation.message
          : "This field is required",
      validationMessageSeconedSelect:
        this.fieldData.validation && this.fieldData.validation.message
          ? this.fieldData.validation.message
          : "This field is required",
      validationMessageThirdSelect:
        this.fieldData.validation && this.fieldData.validation.message
          ? this.fieldData.validation.message
          : "This field is required",
      selectId:
        this.fieldData.attributes && this.fieldData.attributes.newId
          ? this.fieldData.attributes.newId
          : undefined,
      selectClass:
        this.fieldData.attributes && this.fieldData.attributes.Class
          ? this.fieldData.attributes.Class
          : undefined,
      selectTitle:
        this.fieldData.attributes && this.fieldData.attributes.Title
          ? this.fieldData.attributes.Title
          : undefined,
      selectedPostName:
        this.fieldData.attributes && this.fieldData.attributes.postName
          ? this.fieldData.attributes.postName
          : undefined,

      selectStyle:
        this.fieldData.attributes && this.fieldData.attributes.Style
          ? this.fieldData.attributes.Style
          : undefined,
      selecReadOnly:
        this.fieldData.attributes && this.fieldData.attributes.readonly
          ? this.fieldData.attributes.readonly
          : false,
      showTab: "text",

      // advance tab settings for select only

      // for first

      isMultipleSelect:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelect) ||
        false,
      isClearable:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isClearable) ||
        false,
      isLiveSearchable:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isLiveSearchable) ||
        false,
      minSelection:
        (this.fieldData.configuration &&
          this.fieldData.configuration.minSelection) ||
        1,
      maxSelection:
        (this.fieldData.configuration &&
          this.fieldData.configuration.maxSelection) ||
        2,
      renderOptionsLimit:
        (this.fieldData.configuration &&
          this.fieldData.configuration.renderOptionsLimit) ||
        5,

      // for second

      isMultipleSelectSecond:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelectSecond) ||
        false,
      isClearableSecond:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isClearableSecond) ||
        false,
      isLiveSearchableSecond:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isLiveSearchableSecond) ||
        false,
      minSelectionSecond:
        (this.fieldData.configuration &&
          this.fieldData.configuration.minSelectionSecond) ||
        1,
      maxSelectionSecond:
        (this.fieldData.configuration &&
          this.fieldData.configuration.maxSelectionSecond) ||
        2,
      renderOptionsLimitSecond:
        (this.fieldData.configuration &&
          this.fieldData.configuration.renderOptionsLimitSecond) ||
        5,

      // for Third

      isMultipleSelectThird:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isMultipleSelectThird) ||
        false,
      isClearableThird:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isClearableThird) ||
        false,
      isLiveSearchableThird:
        (this.fieldData.configuration &&
          this.fieldData.configuration.isLiveSearchableThird) ||
        false,
      minSelectionThird:
        (this.fieldData.configuration &&
          this.fieldData.configuration.minSelectionThird) ||
        1,
      maxSelectionThird:
        (this.fieldData.configuration &&
          this.fieldData.configuration.maxSelectionThird) ||
        2,
      renderOptionsLimitThird:
        (this.fieldData.configuration &&
          this.fieldData.configuration.renderOptionsLimitThird) ||
        5,

      // advance tab settings for checkbox
      minSelect: this.fieldData.validation.minSelect,
      maxSelect: this.fieldData.validation.maxSelect
    };
  },
  computed: {
    ...mapGetters({ form: "form1" })
  },
  created() {
    EventBus.$on("onSaveSettings", () => {
      this.addLanguageToField(this.transalationLanguage);
      this.addAttributes();
      this.setValidation();
      this.setAdvanceSettingsForSelect();
    });
  },

  mounted() {
    (this.labelFirst = this.fieldData.labelFirst[this.transalationLanguage]),
      (this.labelSeconed =
        this.fieldData.labelSeconed[this.transalationLanguage]),
      (this.labelThird = this.fieldData.labelThird[this.transalationLanguage]),
      (this.validationMessageFirstSelect =
        this.fieldData.validationMessageFirstSelect[this.transalationLanguage]),
      (this.validationMessageSeconedSelect =
        this.fieldData.validationMessageSeconedSelect[
          this.transalationLanguage
        ]),
      (this.validationMessageThirdSelect =
        this.fieldData.validationMessageThirdSelect[this.transalationLanguage]),
      (this.helperTextFirst =
        this.fieldData.helperTextFirst[this.transalationLanguage]);
    this.helperTextSeconed =
      this.fieldData.helperTextSeconed[this.transalationLanguage];
    this.helperTextThird =
      this.fieldData.helperTextThird[this.transalationLanguage];
    this.selectedDataSource = this.fieldData.configuration.selectedDataSource;

    this.addExtras();
  },
  methods: {
    ...mapMutations(["TOGGLE_LOADER"]),

    addExtras() {
      if (!this.fieldData.abbreviationLabelText) {
        let abbreviationLabelText = { english: "" };
        Object.assign(this.fieldData, { abbreviationLabelText });
      }
      if (!this.fieldData.textForTable) {
        let textForTable = { english: "" };
        Object.assign(this.fieldData, { textForTable });
      }
    },

    tabClick(language) {
      let tempKey = language.toLowerCase();
      this.transalationLanguage = tempKey;
      this.labelFirst = this.fieldData.labelFirst[this.transalationLanguage];
      this.labelSeconed =
        this.fieldData.labelSeconed[this.transalationLanguage];
      this.labelThird = this.fieldData.labelThird[this.transalationLanguage];

      this.validationMessageFirstSelect =
        this.fieldData.validationMessageFirstSelect[this.transalationLanguage];

      this.validationMessageSeconedSelect =
        this.fieldData.validationMessageSeconedSelect[
          this.transalationLanguage
        ];

      this.validationMessageThirdSelect =
        this.fieldData.validationMessageThirdSelect[this.transalationLanguage];

      this.helperTextFirst =
        this.fieldData.helperTextFirst[this.transalationLanguage];
      this.helperTextSeconed =
        this.fieldData.helperTextSeconed[this.transalationLanguage];
      this.helperTextThird =
        this.fieldData.helperTextThird[this.transalationLanguage];
    },

    addLanguageToField(language) {
      let tempKey = language.toLowerCase();
      this.fieldData.labelFirst[tempKey] = this.labelFirst;
      this.fieldData.labelSeconed[tempKey] = this.labelSeconed;
      this.fieldData.labelThird[tempKey] = this.labelThird;
      this.fieldData.helperTextFirst[tempKey] = this.helperTextFirst;
      this.fieldData.helperTextSeconed[tempKey] = this.helperTextSeconed;
      this.fieldData.helperTextThird[tempKey] = this.helperTextThird;
      //  this.fieldData.validationMessage[tempKey] = this.langugeValidateMessage;
      this.fieldData.validationMessageFirstSelect[tempKey] =
        this.validationMessageFirstSelect;
      this.fieldData.validationMessageSeconedSelect[tempKey] =
        this.validationMessageSeconedSelect;
      this.fieldData.validationMessageThirdSelect[tempKey] =
        this.validationMessageThirdSelect;
    },

    addAttributes() {
      let attributes = {
        newId: this.selectId ? this.selectId : undefined,
        Class: this.selectClass ? this.selectClass : undefined,
        Title: this.selectTitle ? this.selectTitle : undefined,
        Style: this.selectStyle ? this.selectStyle : undefined,
        readonly: this.selecReadOnly ? this.selecReadOnly : false,
        postName: this.selectedPostName ? this.selectedPostName : undefined
      };
      Object.assign(this.fieldData, { attributes });
    },

    addOption() {
      // let setName = this.fieldData.inputType === 'radio' ? 'Radio' : this.fieldData.inputType === 'checkbox' ? 'Checkbox' :
      let newOptiopn = {
        value: this.optionValue
          ? this.optionValue
          : `${this.fieldData.inputType} value${
              this.fieldData.options.length
            }${1}`,
        text: {
          english: this.optiponName
            ? this.optionName
            : `${this.fieldData.inputType} text ${
                this.fieldData.options.length
              }${1}`
        },
        score: 0
      };

      this.fieldData.options.push(newOptiopn);
    },

    addDataAttribute() {
      let newDataAttribute = {
        value: this.dataAttributeValue ? this.dataAttributeValue : undefined,
        key: this.dataAttributeKey ? `data-${this.dataAttributeKey}` : undefined
      };
      this.fieldData.dataAttributes.push(newDataAttribute);
    },

    onDeleteDataAttribute(index) {
      this.fieldData.dataAttributes.splice(
        this.fieldData.dataAttributes.indexOf(index),
        1
      );
    },

    onDeleteOption(index) {
      if (this.fieldData.options.length > 2) {
        this.fieldData.options.splice(this.fieldData.options.indexOf(index), 1);
      }
    },
    setFieldOptionValue(value, optionIndex) {
      this.fieldData.options[optionIndex].value;
      this.optionValue = value;
      this.fieldData.options[optionIndex].value = value;
    },

    setFieldOptionText(value, optionIndex) {
      this.optiponName = value;
      this.fieldData.options[optionIndex].text[this.transalationLanguage] =
        value;
    },
    /**
     * Required for validation on
     */
    setValidation() {
      let validation = {
        requiredFirstSelect: this.isRequireFirstSelect,
        requiredSeconedSelect: this.isRequireSeconedSelect,
        requiredThirdSelect: this.isRequireThirdSelect
      };
      Object.assign(this.fieldData, { validation });
    },

    setAdvanceSettingsForSelect() {
      this.fieldData.configuration = {
        selectedDataSource: this.selectedDataSource,

        // first
        isMultipleSelect: this.isMultipleSelect,
        isClearable: this.isClearable,
        isLiveSearchable: this.isLiveSearchable,
        minSelection: this.minSelection,
        maxSelection: this.maxSelection,
        renderOptionsLimit: this.renderOptionsLimit,

        // second

        isMultipleSelectSecond: this.isMultipleSelectSecond,
        isClearableSecond: this.isClearableSecond,
        isLiveSearchableSecond: this.isLiveSearchableSecond,
        minSelectionSecond: this.minSelectionSecond,
        maxSelectionSecond: this.maxSelectionSecond,
        renderOptionsLimitSecond: this.renderOptionsLimitSecond,

        // for Third

        isMultipleSelectThird: this.isMultipleSelectThird,
        isClearableThird: this.isClearableThird,
        isLiveSearchableThird: this.isLiveSearchableThird,
        minSelectionThird: this.minSelectionThird,
        maxSelectionThird: this.maxSelectionThird,
        renderOptionsLimitThird: this.renderOptionsLimitThird
        // enableSelfReportingForThirdPartyProfile:
        //   this.fieldData.configuration
        //     .enableSelfReportingForThirdPartyProfile || false,
        // enableSelfReportingForManageThirdParty:
        //   this.fieldData.configuration.enableSelfReportingForManageThirdParty ||
        //   false
      };
      // Object.assign(this.fieldData, { advanceOption });
      this.TOGGLE_LOADER(50);
    }
  },

  props: {
    fieldData: Object,
    isPreview: Boolean
  }
};
</script>
