const usageState = {
  AVAILABLE: "Available",
  UNAVAILABLE: "Unavailable"
};

const activityState = {
  ACTIVE: "Active",
  INACTIVE: "Inactive"
};

const validity = {
  TRUE: "true",
  FALSE: "false"
};

export { validity, usageState, activityState };
