const allActions = [
  {
    id: 0,
    btnText: "Instant Due Diligence (IDD)",
    title: "Instant Due Diligence (IDD)",
    gridBtntext: "IDD",
    actionType: "idd",
    isActive: false,
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 1,
    btnText: "Instant Due Diligence PLUS (IDD+)",
    title: "Instant Due Diligence PLUS (IDD+)",
    gridBtntext: "IDD+",
    isActive: false,
    actionType: "idd_plus",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 2,
    btnText: "Enhanced Due Diligence (EDD)",
    title: "Enhanced Due Diligence (EDD)",
    gridBtntext: "EDD",
    isActive: false,
    actionType: "edd",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 3,
    btnText: "Anti Bribery & Corruption APRI",
    title: "Anti Bribery & Corruption",
    gridBtntext: "ABC APRI",
    isActive: false,
    actionType: "apri",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 4,
    btnText: "Human Rights APRI",
    title: "Human Right",
    gridBtntext: "HR APRI",
    isActive: false,
    actionType: "apri",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 5,
    btnText: "Labour APRI",
    title: "Labour",
    gridBtntext: "LAB APRI",
    isActive: false,
    actionType: "apri",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 6,
    btnText: "Environmental Practices APRI",
    title: "Environmental Practices",
    gridBtntext: "ENV APRI",
    isActive: false,
    actionType: "apri",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 7,
    btnText: "Modern Slavery Questionnaire (MSQ)",
    title: "Modern Slavery DDQ",
    gridBtntext: "MSQ",
    isActive: false,
    actionType: "msq",
    isHidden: true,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 8,
    btnText: "Standard PRI",
    title: "Standard PRI",
    gridBtntext: "PRI",
    isActive: false,
    actionType: "pri",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 9,
    btnText: "Anti-Bribery & Corruption Questionnaire (ABCQ)",
    title: "Anti-Bribery & Corruption Questionnaire (ABCQ)",
    gridBtntext: "ABCQ",
    isActive: false,
    actionType: "abcq",
    isHidden: true,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 10,
    btnText: "Anti-Bribery & Corruption Training (ABCT)",
    title: "Anti-Bribery & Corruption Training (ABCT)",
    gridBtntext: "ABCT",
    isActive: false,
    actionType: "abct",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 11,
    btnText: "Code of Conduct Attestation",
    title: "Code of Conduct Attestation",
    gridBtntext: "COCA",
    isActive: false,
    actionType: "coca",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 12,
    btnText: "Anti-Corruption Policy Attestation ",
    title: "Anti-Corruption Policy Attestation ",
    gridBtntext: "ACPA",
    isActive: false,
    actionType: "acpa",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 13,
    btnText: "Executive Summary Analysis",
    title: "Executive Summary Analysis",
    gridBtntext: "Executive",
    isActive: false,
    actionType: "esa",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 14,
    btnText: "Configured PRI",
    title: "Configured PRI",
    gridBtntext: "CONFIG PRI",
    isActive: false,
    actionType: "config_pri",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  },
  {
    id: 15,
    btnText: "Modern Slavery DDQ Send To All",
    title: "Modern Slavery DDQ Send To All",
    gridBtntext: "MSQ To All",
    isActive: false,
    actionType: "msq_all",
    isHidden: true,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: false,
    requestedDate: ""
  },
  {
    id: 16,
    btnText: "ESG Explore Report",
    title: "ESG Explore Report",
    gridBtntext: "ESG",
    isActive: false,
    actionType: "esg",
    isHidden: false,
    backgroundColor: "",
    imgName: "",
    count: 1,
    isDisabled: true,
    requestedDate: ""
  }
];

export { allActions };
