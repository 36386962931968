<template>
  <div class="ubo-entity-profile">
    <div v-if="Object.keys(UBOEntityProfileData).length">
      <div class="ubo-entity-profile__row-column">
        <StickyTable
          class="ubo-entity-profile__row-column-table"
          caption="Details"
          :table-headers="tableHeaders"
          :table-rows="detailsTableRows"
        />
        <StickyTable
          class="ubo-entity-profile__row-column-table"
          caption="Ownership Details"
          :table-headers="tableHeaders"
          :table-rows="ownershipDetailsTableRows"
        />
      </div>
      <div class="ubo-entity-profile__row-column">
        <StickyTable
          class="ubo-entity-profile__row-column-table"
          caption="Business Entity Details"
          :table-headers="tableHeaders"
          :table-rows="businessEntityDetailsTableRows"
        />
      </div>
    </div>
    <div class="ubo-entity-profile__error-message">
      <BaseText :text="UBOEntityProfileErrorMessage" />
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import { typographySize } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "UltimateBeneficialOwnersEntityProfile",
  components: {
    StickyTable,
    BaseText
  },
  props: {
    UBOEntityProfileData: {
      type: Object,
      default: () => {}
    },
    UBOEntityProfileErrorMessage: {
      type: String,
      default: ""
    }
  },
  async created() {
    this.setTableData();
  },
  data() {
    return {
      tableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ],
      detailsTableRows: [],
      ownershipDetailsTableRows: [],
      businessEntityDetailsTableRows: [],
      detailsTableDataMap: [
        {
          label: "Name",
          value: this.UBOEntityProfileData?.name || ""
        },
        {
          label: "Type",
          value: this.UBOEntityProfileData?.beneficiaryType || ""
        },
        {
          label: "DOB",
          value: this.UBOEntityProfileData?.birthDate || ""
        },
        {
          label: "Nationality",
          value: this.UBOEntityProfileData?.nationality || ""
        },
        {
          label: "Country of Residence",
          value: this.UBOEntityProfileData?.addressCountry || ""
        }
      ],
      ownershipDetailsTableDataMap: [
        {
          label: "Is Person of Significant Control",
          value: this.UBOEntityProfileData?.isPSC || ""
        },
        {
          label: "Direct Ownership %",
          value: `${this.UBOEntityProfileData?.directOwnershipPercentage || ""}`
        },
        {
          label: "Indirect Ownership %",
          value: `${
            this.UBOEntityProfileData?.indirectOwnershipPercentage || ""
          }`
        },
        {
          label: "Beneficial Ownership %",
          value: `${
            this.UBOEntityProfileData?.beneficialOwnershipPercentage || ""
          }`
        },
        {
          label: "Out of Business",
          value: this.UBOEntityProfileData?.isOutOfBusiness || ""
        },
        {
          label: "Control Ownership",
          value: this.UBOEntityProfileData?.controlOwnershipType || ""
        },
        {
          label: "Degree of Separation",
          value: `${this.UBOEntityProfileData?.degreeOfSeparation || ""}`
        }
      ],
      businessEntityDetailsTableDataMap: [
        {
          label: "Business Ownership Type",
          value: this.UBOEntityProfileData?.businessEntityType || ""
        },
        {
          label: "Address",
          value: this.getBusinessAddress()
        }
      ]
    };
  },
  methods: {
    setTableData() {
      this.setDetailsTableRows();
      this.setOwnershipDetailsTableRows();
      this.setBusinessEntityDetailsTableDataRows();
    },
    setDetailsTableRows() {
      this.detailsTableRows = this.makeTableRowData(this.detailsTableDataMap);
    },
    setOwnershipDetailsTableRows() {
      this.ownershipDetailsTableRows = this.makeTableRowData(
        this.ownershipDetailsTableDataMap
      );
    },
    setBusinessEntityDetailsTableDataRows() {
      this.businessEntityDetailsTableRows = this.makeTableRowData(
        this.businessEntityDetailsTableDataMap
      );
    },
    makeTableRowData(dataMap) {
      return dataMap.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label,
            size: typographySize.BODY_TEXT_BOLD
          },
          styles: {
            border: "none",
            width: "200px",
            verticalAlign: "baseline"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            text: value
          },
          styles: {
            border: "none",
            verticalAlign: "baseline"
          }
        }
      }));
    },
    getBusinessAddress() {
      return `${this.UBOEntityProfileData?.address}, ${this.UBOEntityProfileData?.addressLocality} ${this.UBOEntityProfileData?.addressCountry}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.ubo-entity-profile {
  height: calc(100vh - 240px);
  overflow-y: auto;

  &__row-column {
    display: flex;

    &-table {
      max-width: 50%;
      margin: 25px;
    }
  }

  &__error-message {
    text-align: center;
    font-size: 20px;
  }
}
</style>
