import { isLessThanOrEqualToMaxLength, isRequired } from "@/utils";
import { userEmailTemplatesDestinationTypes } from "@/constants";
import { makeLiteralOptionForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";

const validateUserEmailTemplateText = (text) => {
  if (!isRequired(text)) {
    return "required";
  } else if (!isLessThanOrEqualToMaxLength(text, 255)) {
    return "maximum of 255 characters";
  } else {
    return "";
  }
};

const validateUserEmailTemplateBody = (userEmailTemplateEmailBody) => {
  if (!isRequired(userEmailTemplateEmailBody)) {
    return "required";
  } else {
    return "";
  }
};

const makeDestinationList = ({ options = [] } = {}) =>
  options
    ?.filter(({ selected }) => selected)
    ?.reduce((acc, option) => {
      if (option.type === userEmailTemplatesDestinationTypes.ROLE) {
        acc.push({
          type: userEmailTemplatesDestinationTypes.ROLE,
          value: {
            roleId: option.value,
            companyId: option.companyId
          }
        });
      } else if (option.type === userEmailTemplatesDestinationTypes.USER) {
        acc.push({
          type: userEmailTemplatesDestinationTypes.USER,
          value: {
            userId: option.value
          }
        });
      } else if (option.type === userEmailTemplatesDestinationTypes.EMAIL) {
        acc.push({
          type: userEmailTemplatesDestinationTypes.EMAIL,
          value: {
            email: option.value
          }
        });
      }

      return acc;
    }, []);

const makeErrorMessagesApi = (error = {}) => {
  const description = error?.response?.status
    ? ` with status ${error?.response?.status}`
    : "";
  return `An error has occurred${description}, when trying to submit. Please contact support.`;
};

const getListSelectedValues = (list = []) =>
  list?.reduce((acc, { selected, value }) => {
    if (selected) {
      acc.push(value);
    }
    return acc;
  }, []) || [];

const getStepCount = (index) => `${index + 1}.`;

const getSelectedValuesFromDestinationKey = (list = []) => {
  const destinationEmails = {
    selectedValues: [],
    literalEmailOptions: []
  };

  return (
    list?.reduce((acc, { type, value }) => {
      if (type === userEmailTemplatesDestinationTypes.EMAIL) {
        acc.selectedValues.push(value?.email);
        acc.literalEmailOptions.push(
          makeLiteralOptionForMultiSelect({
            text: value?.email,
            value: value?.email,
            type
          })
        );
      } else if (type === userEmailTemplatesDestinationTypes.USER) {
        acc.selectedValues.push(value?.userId);
      } else if (type === userEmailTemplatesDestinationTypes.ROLE) {
        acc.selectedValues.push(value?.roleId);
      }

      return acc;
    }, destinationEmails) || destinationEmails
  );
};

export {
  validateUserEmailTemplateText,
  validateUserEmailTemplateBody,
  makeDestinationList,
  makeErrorMessagesApi,
  getListSelectedValues,
  getStepCount,
  getSelectedValuesFromDestinationKey
};
