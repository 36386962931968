<template>
  <div class="settings">
    <div class="settings__header-wrapper">
      <BaseText
        :size="typographySize.BODY_TEXT_BOLD"
        :text="`Platform Features for ${companyName}`"
      />
    </div>
    <div class="settings__body">
      <BaseLoader v-if="isSubmiting" />
      <div v-else class="settings__body-wrapper">
        <BaseText
          text="Enable MSQ V2 autofill field"
          class="settings__body-wrapper-label"
        />
        <b-form-checkbox
          size="lg"
          class="settings__body-wrapper-value"
          v-model="isAutoFieldEnabled"
          name="check-button"
          switch
          value="1"
          unchecked-value="0"
          @change="updateAutoFieldStatus"
        />
      </div>
      <BaseLoader v-if="isLoading" />
      <div
        v-else
        v-for="(platformFeature, index) in platformFeatureOptions"
        :key="index"
        class="settings__body-wrapper"
      >
        <BaseText
          :text="platformFeature.name"
          class="settings__body-wrapper-label"
        />
        <b-form-checkbox
          v-if="platformFeature.type === settingsType.BOOLEAN"
          size="lg"
          class="settings__body-wrapper-value"
          v-model="platformFeature.value"
          name="check-button"
          switch
          value="1"
          unchecked-value="0"
          @change="
            updatePlatformFeature(
              platformFeature.setting_id,
              platformFeature.value
            )
          "
        />
        <Input
          v-else-if="platformFeature.type === settingsType.STRING"
          id="platformFeatureString"
          class="settings__body-wrapper-value"
          :value="platformFeature.value"
          @change="updatePlatformFeature(platformFeature.setting_id, $event)"
        />
        <Select
          v-else-if="platformFeature.type === settingsType.DROPDOWN"
          id="platformFeatureDropdown"
          :options="platformFeature.setting_option"
          class="settings__body-wrapper-value"
          @change="onChangeDropdownItems(platformFeature.setting_id, $event)"
        />
        <Input
          v-else-if="platformFeature.type === settingsType.INTEGER"
          id="platformFeatureString"
          class="settings__body-wrapper-value"
          type="number"
          :value="platformFeature.value"
          @change="updatePlatformFeature(platformFeature.setting_id, $event)"
        />
        <v-select
          v-else-if="platformFeature.type === settingsType.MULTISELECT"
          label="text"
          v-model="platformFeature.value"
          :options="platformFeature.setting_option"
          :clearable="true"
          :multiple="true"
          class="settings__body-wrapper-value"
          @input="getSettingValue(platformFeature.setting_id, $event)"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import {
  typographySize,
  localStorageItem,
  settingsType,
  corporateData
} from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { thirdPartyService } from "@/services";
import Input from "@/molecules/Input/Input.vue";
import Select from "@/molecules/Select/Select.vue";
import { hasStatus200, isNotAuthorized } from "@/utils";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";

export default {
  name: "Setting",
  components: {
    BaseText,
    BaseLoader,
    Input,
    Select
  },
  data() {
    return {
      isAutoFieldEnabled: false,
      isSubmiting: false,
      companyName: null,
      typographySize,
      platformFeatureOptions: [],
      isLoading: false,
      settingsType
    };
  },
  mounted() {
    this.companyName = localStorage.getItem(
      localStorageItem.OPENED_COMPANY_PROFILE
    );
    const status = localStorage.getItem(localStorageItem.AUTO_FILL_MSQ_V2);
    if (status) {
      this.isAutoFieldEnabled = status;
    }
    this.fetchPlatformFeatures();
  },
  methods: {
    async updateAutoFieldStatus() {
      this.isSubmiting = true;
      try {
        const result = await thirdPartyService.updateAutoFieldStatus({
          comp_id: this.$route.params.id,
          status: this.isAutoFieldEnabled
        });
        if (result?.data?.status === 1 && hasStatus200(result)) {
          localStorage.setItem(
            localStorageItem.AUTO_FILL_MSQ_V2,
            this.isAutoFieldEnabled
          );
          this.$bvToast.toast(result?.data?.message, {
            title: "Done",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
        }
      } catch (error) {
        return error;
      } finally {
        this.isSubmiting = false;
      }
    },
    async fetchPlatformFeatures() {
      try {
        const result = await thirdPartyService.fetchPlatformFeatures(
          this.$route.params.id
        );
        this.platformFeatureOptions = result?.data?.data;
        this.platformFeatureOptions.forEach((platformFeature) => {
          if (platformFeature?.name === corporateData.CORPORATE_DATA) {
            platformFeature.setting_option = makeOptionsForSelect(
              platformFeature?.value,
              platformFeature?.setting_option
            );
          }
        });
      } catch ({ status }) {
        this.fetchPlatformFeaturesFailure(status);
      }
    },
    fetchPlatformFeaturesFailure(status) {
      this.$bvToast.toast(
        `There is an unknown error with status of ${status}. Please contact support`,
        {
          title: "Error",
          variant: "danger",
          autoHideDelay: 2000,
          appendToast: false
        }
      );
    },
    onChangeDropdownItems(settingId, settingValue) {
      this.platformFeatureOptions.forEach((platformFeature) => {
        platformFeature.setting_option = makeOptionsForSelect(
          settingValue,
          platformFeature?.setting_option
        );
      });
      this.updatePlatformFeature(settingId, settingValue);
    },
    getSettingValue(settingId, settingValue) {
      let valueArray = [];
      settingValue.forEach((settingValue) => {
        valueArray.push(settingValue.id.toString());
      });
      this.updatePlatformFeature(settingId, valueArray);
    },
    async updatePlatformFeature(settingId, settingValue) {
      this.isLoading = true;
      try {
        const result = await thirdPartyService.updatePlatformFeature(
          {
            setting_id: settingId,
            value: settingValue
          },
          this.$route.params.id
        );
        this.$bvToast.toast(result.data.message, {
          title: "Done",
          variant: "success",
          autoHideDelay: 2000,
          appendToast: false
        });
      } catch ({ response }) {
        this.updatePlatformFeatureFailure(response);
      } finally {
        this.isLoading = false;
      }
    },
    updatePlatformFeatureFailure(response) {
      if (response.status === 422) {
        this.$bvToast.toast(response?.data?.message, {
          title: "Error",
          variant: "danger",
          autoHideDelay: 2000,
          appendToast: false
        });
      } else if (!isNotAuthorized()) {
        this.$bvToast.toast(
          `There is an unknown error with status of ${response?.status}. Please contact support`,
          {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  &:deep(.settings__body-wrapper-label) {
    margin-top: 3px;
  }
  &:deep(.settings__body-wrapper-value) {
    margin-left: auto;
  }

  &__header-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  &__body {
    height: calc(100vh - 120px);
    overflow-y: auto;

    &-wrapper {
      text-align: left;
      margin-top: 20px;
      display: flex;

      &-value {
        margin-left: auto;
      }
    }
  }
}
</style>
