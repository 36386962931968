import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import {
  makeAutomationAPIDTO,
  makeAutomationConditionsDTO,
  makeAutomationDTO
} from "./dto/automations.dto";

const fetchAutomations = async (companyId) => {
  const result = await HTTP(
    "get",
    endpoints.AUTOMATIONS(companyId),
    null,
    makeAuthorizationHeader()
  );
  result.data = result.data.map(makeAutomationDTO);
  return result;
};

const fetchAutomationConditions = async (companyId) => {
  const result = await HTTP(
    "get",
    endpoints.AUTOMATION_CONDITIONS(companyId),
    null,
    makeAuthorizationHeader()
  );
  result.data = result.data.data.map(makeAutomationConditionsDTO);
  return result;
};

const deleteAutomation = async (companyId, id) =>
  HTTP(
    "delete",
    endpoints.AMEND_AUTOMATION(companyId, id),
    null,
    makeAuthorizationHeader()
  );

const saveAutomation = (
  companyId,
  payload,
  triggerStepList,
  includeTriggerRoles
) => {
  const dto = makeAutomationAPIDTO(
    payload,
    triggerStepList,
    includeTriggerRoles
  );

  return HTTP(
    "post",
    endpoints.AUTOMATIONS(companyId),
    dto,
    makeAuthorizationHeader()
  );
};

const amendAutomation = (
  companyId,
  id,
  payload,
  triggerStepList,
  includeTriggerRoles
) => {
  const dto = makeAutomationAPIDTO(
    payload,
    triggerStepList,
    includeTriggerRoles
  );

  return HTTP(
    "put",
    endpoints.AMEND_AUTOMATION(companyId, id),
    dto,
    makeAuthorizationHeader()
  );
};
export const automationsService = {
  fetchAutomations,
  fetchAutomationConditions,
  deleteAutomation,
  saveAutomation,
  amendAutomation
};
