import { actionKeys, actionKeysApi } from "@/constants";

const makeEndStepDTO = (functionStep = {}) => ({
  [actionKeys.STEP_TYPE]: functionStep?.[actionKeysApi.STEP_TYPE],
  [actionKeys.COMMENT]: functionStep?.[actionKeysApi.COMMENT] || ""
});

const makeEndStepAPIDTO = (functionStep = {}) => ({
  [actionKeysApi.STEP_TYPE]: functionStep?.[actionKeys.STEP_TYPE],
  [actionKeysApi.COMMENT]: functionStep?.[actionKeys.COMMENT] || ""
});

export { makeEndStepDTO, makeEndStepAPIDTO };
