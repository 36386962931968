<template>
  <b-alert
    class="text-box"
    data-test-id="text-box"
    :show="isVisible"
    :variant="status"
  >
    <h4 v-if="title">{{ title }}</h4>
    <p v-if="text">{{ text }}</p>
    <slot />
  </b-alert>
</template>

<script>
export default {
  name: "TextBox",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    }
  }
};
</script>
