<template>
  <div class="table-navbar d-flex">
    <CallToAction
      v-if="isUserSuperAdmin"
      class="table-navbar__call-to-action"
      value="ESG Export"
      :is-loading="isLoading"
      @click="fetchESGExportReportWrapper"
    />
    <b-btn
      @click="advanceFilterForms = !advanceFilterForms"
      class="mr-2"
      size="sm"
      variant="outline-dark"
    >
      <font-awesome-icon icon="filter" />
    </b-btn>
    <b-form onSubmit="return false;">
      <b-input-group size="sm" class="mr-2">
        <b-form-input
          v-model="searchInputValue"
          type="search"
          placeholder="Type to Search Thirdparty name"
        />
        <b-input-group-append>
          <b-button
            :disabled="isTableLoading"
            @click="onSubmitSearch"
            variant="outline-success"
          >
            {{ $t("searchBtn") }}
          </b-button>
          <b-button :disabled="isTableLoading" @click="onClearSearch">
            {{ $t("clearBtnText") }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <Modal
      v-if="isModalVisible"
      title="Export Third Party"
      @close-modal="onCloseModal"
      class="esg-export-modal"
    >
      <template>
        <BaseText class="esg-export-modal__text" :text="modalMessage" />
      </template>
      <template v-slot:right>
        <CallToAction value="Close" @click="onCloseModal" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getterName, esgExportReportKeys } from "@/constants";
import { hasStatus200 } from "@/utils";
import { esgService } from "@/services";
import Modal from "@/molecules/Modal/Modal";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "TableNavBar",
  components: {
    Modal,
    CallToAction,
    BaseText
  },
  data() {
    return {
      searchInputValue: null,
      advanceFilterForms: false,
      isLoading: false,
      isModalVisible: false,
      modalMessage: null
    };
  },
  props: {
    searchValue: {
      type: String,
      default: ""
    },
    isTableLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      companyName: (state) => state.company.companyName,
      email: (state) => state.user.email,
      userId: (state) => state.user.userId
    }),
    ...mapGetters({
      isUserSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN
    })
  },
  mounted() {
    this.searchInputValue = this.searchValue;
  },
  methods: {
    onSubmitSearch() {
      if (this.searchInputValue) {
        this.$emit("table-search", this.searchInputValue);
      }
    },
    onClearSearch() {
      this.searchInputValue = null;
      this.$emit("table-search-clear", this.searchInputValue);
    },
    makePostObjectForEsgExport() {
      return {
        [esgExportReportKeys.COMPANY_ID]: this.companyId,
        [esgExportReportKeys.USER_ID]: this.userId,
        [esgExportReportKeys.COMPANY_NAME]: this.companyName,
        [esgExportReportKeys.EMAIL]: this.email
      };
    },
    async fetchESGExportReportWrapper() {
      this.isLoading = true;
      try {
        const result = await esgService.exportESGReports(
          this.makePostObjectForEsgExport()
        );
        if (hasStatus200(result)) {
          this.modalMessage = result?.data?.message;
          this.isLoading = false;
          this.isModalVisible = true;
        }
      } catch (error) {
        return [error];
      } finally {
        this.isLoading = false;
      }
    },
    onCloseModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-navbar {
  &__call-to-action {
    margin: 0px 3px;
    padding: 0px 10px;
  }
}

.esg-export-modal {
  padding: 50px;

  &:deep(.esg-export-modal__text) {
    text-align: left;
    margin: 0px 15px;
  }
}
</style>
