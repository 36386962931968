import { state as makeState } from "./auth.state.store";

export const mutations = {
  setToken(state, payload) {
    state.token = payload;
  },
  resetAuth(state) {
    Object.assign(state, makeState());
  }
};
