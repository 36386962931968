import {
  customAttributesWidgetListKeysDTO,
  customAttributesWidgetListKeysAPIDTO
} from "@/constants";

const makeCustomAttributesWidgetDTO = (widget = {}) => ({
  [customAttributesWidgetListKeysDTO.LABEL]:
    widget?.[customAttributesWidgetListKeysAPIDTO.LABEL],
  [customAttributesWidgetListKeysDTO.DESCRIPTION]:
    widget?.[customAttributesWidgetListKeysAPIDTO.DESCRIPTION],
  [customAttributesWidgetListKeysDTO.TYPE]:
    widget?.[customAttributesWidgetListKeysAPIDTO.TYPE],
  [customAttributesWidgetListKeysDTO.ID]:
    widget?.[customAttributesWidgetListKeysAPIDTO.ID],
  [customAttributesWidgetListKeysDTO.IS_ACTIVE]:
    widget?.[customAttributesWidgetListKeysAPIDTO.IS_ACTIVE]
});

const makeCustomAttributesWidgetListDTO = (data = {}) => ({
  [customAttributesWidgetListKeysDTO.TYPE]:
    data?.[customAttributesWidgetListKeysAPIDTO.TYPE],
  [customAttributesWidgetListKeysDTO.TITLE]:
    data?.[customAttributesWidgetListKeysAPIDTO.TITLE],
  [customAttributesWidgetListKeysDTO.WIDGETS]: data?.[
    customAttributesWidgetListKeysAPIDTO.WIDGETS
  ]?.map((widget) => makeCustomAttributesWidgetDTO(widget))
});

export { makeCustomAttributesWidgetListDTO, makeCustomAttributesWidgetDTO };
