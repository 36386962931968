<template>
  <div>
    <h4>Configure EDD for {{ companyName }}</h4>
    <b-overlay :show="isSubmiting">
      <b-tabs justified>
        <b-tab title="Configure Report Types and Scope: " class="p-4" active>
          <ProductsTab
            :listOfEDD="listOfEDD"
            @onAddNewItem="pushNewItem"
            @onGetDropDownItems="getDropDownItems"
          />
        </b-tab>
        <b-tab title="Configure Region" class="p-4" lazy>
          <RegionTab
            :listOfRegions="listOfRegions"
            @onAddNewRegion="pushNewRegion"
            @onGetDropDownRegions="getDropDownRegions"
            :eddDefaultRegion="companyDetails.eddDefaultRegion || null"
          />
        </b-tab>
        <b-tab title="Configure Tat & Cost" class="p-4">
          <TatCostTab :listOfEDD="listOfEDD" :listOfRegions="listOfRegions" />
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import ProductsTab from "./dynamicEddTabs/productsTab.vue";
import RegionTab from "./dynamicEddTabs/regionTab.vue";
import TatCostTab from "./dynamicEddTabs/tatCostTab.vue";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { mapState } from "vuex";

export default {
  name: "DynamicEDDConfiguration",
  components: {
    ProductsTab,
    RegionTab,
    TatCostTab
  },
  data() {
    return {
      isSubmiting: false,
      listOfEDD: [],
      listOfRegions: [],
      eddDefaultRegion: null,
      companyName: null
    };
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
    this.getDropDownItems();
    this.getDropDownRegions();
  },
  computed: {
    ...mapState({
      companyDetails: (state) => state.company.companyDetails
    })
  },
  methods: {
    pushNewItem(value) {
      this.listOfEDD.push(value);
    },
    async getDropDownItems() {
      this.listOfEDD = [];
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "post",
          `thirdparty/edd/client/all/packages/${this.$route.params.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          result.data.map((item) => {
            if (item.client_packages_id > 0) {
              this.listOfEDD.push(item);
            }
          });
          result.data.map((item) => {
            if (item.client_packages_id == null) {
              this.listOfEDD.push(item);
            }
          });
          // this.listOfEDD = result.data
          this.isSubmiting = false;
        }
        return result.data && result.data.length ? result.data : [];
      } catch (error) {
        //
        this.isSubmiting = false;
        return [error];
      }
    },
    pushNewRegion(value) {
      this.listOfRegions.push(value);
    },
    async getDropDownRegions() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          `thirdparty/edd/regions/${this.$route.params.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.listOfRegions = result.data;
          this.isSubmiting = false;
        }
        return result.data && result.data.length ? result.data : [];
      } catch (error) {
        //
        this.isSubmiting = false;
        return [error];
      }
    }
  }
};
</script>

<style>
.kanban-col {
  min-height: 500px;
}
.drop-down {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 50px;
}
.dropdownFieldsContainer {
  display: flex;
}
</style>
