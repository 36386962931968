import { actions } from "./actions.actions.store";
import { mutations } from "./actions.mutations.store";
import { state } from "./actions.state.store";
import { getters } from "./actions.getters.store";

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
};
