import { authUserKeysDTO, authUserKeysAPIDTO } from "@/constants";
import { makeRoleDTO, makePermissionsDTO } from "@/services/user/dto/user.dto";

const makeAuthUserObject = (user = {}) => ({
  [authUserKeysDTO.AUTH_TOEKN]: user?.[authUserKeysAPIDTO.AUTH_TOEKN] || "",
  [authUserKeysDTO.MEMBER_ID]: user?.[authUserKeysAPIDTO.MEMBER_ID] || "",
  [authUserKeysDTO.MEMBER_GROUP_ID]:
    user?.[authUserKeysAPIDTO.MEMBER_GROUP_ID] || "",
  [authUserKeysDTO.USER_FULL_NAME]:
    user?.[authUserKeysAPIDTO.USER_FULL_NAME] || "",
  [authUserKeysDTO.EMAIL]: user?.[authUserKeysAPIDTO.EMAIL] || "",
  [authUserKeysDTO.USER_REGISTRATION_STATUS]:
    user?.[authUserKeysAPIDTO.USER_REGISTRATION_STATUS] || "",
  [authUserKeysDTO.USER_TOUR_STATUS]:
    parseInt(user?.[authUserKeysAPIDTO.USER_TOUR_STATUS]) || "",
  [authUserKeysDTO.LANDING_PAGE]: user?.[authUserKeysAPIDTO.LANDING_PAGE] || "",
  [authUserKeysDTO.USER_FIRST_NAME]:
    user?.[authUserKeysAPIDTO.USER_FIRST_NAME] || "",
  [authUserKeysDTO.USER_LAST_NAME]:
    user?.[authUserKeysAPIDTO.USER_LAST_NAME] || "",
  [authUserKeysDTO.COMPANY_ID]: user?.[authUserKeysAPIDTO.COMPANY_ID] || "",
  [authUserKeysDTO.COMPANY_NAME]: user?.[authUserKeysAPIDTO.COMPANY_NAME] || "",
  [authUserKeysDTO.LOGIN_TYPE]: user.hasOwnProperty(
    authUserKeysAPIDTO.LOGIN_TYPE
  )
    ? user[authUserKeysAPIDTO.LOGIN_TYPE]
    : "",
  [authUserKeysDTO.PLATFORM_VERSION]:
    user?.[authUserKeysAPIDTO.PLATFORM_VERSION] || "",
  [authUserKeysDTO.PLATFORM_VIEWS]: user?.[authUserKeysAPIDTO.PLATFORM_VIEWS]
    ? JSON.parse(user?.[authUserKeysAPIDTO.PLATFORM_VIEWS])
    : "",
  [authUserKeysDTO.FORM_TRANSLATIONS]:
    user?.[authUserKeysAPIDTO.FORM_TRANSLATIONS] || "",
  [authUserKeysDTO.CONTACT]: user?.[authUserKeysAPIDTO.CONTACT] || "",
  [authUserKeysDTO.PASSWORD_CREATED]: user.hasOwnProperty(
    authUserKeysAPIDTO.PASSWORD_CREATED
  )
    ? user?.[authUserKeysAPIDTO.PASSWORD_CREATED]
    : "",
  [authUserKeysDTO.ROLES]:
    user?.[authUserKeysAPIDTO.ROLES]?.map((role) => makeRoleDTO(role)) || [],
  [authUserKeysDTO.PERMISSIONS]:
    user?.[authUserKeysAPIDTO.PERMISSIONS]?.map((permission) =>
      makePermissionsDTO(permission)
    ) || []
});

export { makeAuthUserObject };
