<template>
  <div class="risk-alert-table" data-test-id="risk-alert-table">
    <BaseLoader
      v-if="loadingTableData"
      data-test-id="risk-alert-table__loader"
    />
    <template v-else>
      <StickyTable
        :table-headers="riskAlertTableHeaders"
        :table-rows="riskAlertTableRows"
      />
      <BaseText
        class="risk-alert-table__not-activated"
        v-if="!hasAlerts"
        text="There are no risks to display for this third party."
        tag="p"
        data-test-id="risk-alert-table__not-activated"
      />
    </template>
  </div>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseText from "@/atoms/BaseText/BaseText";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { endpoints, externalUrls, RISK_ALERT_HEADERS } from "@/constants";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import { makeRiskAlertDTO } from "@/organisms/RiskAlertTable/RiskAlertTable.dto";

export default {
  name: "RiskAlertTable",
  components: {
    StickyTable,
    BaseLoader,
    BaseText
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    thirdPartyId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      greenliteLink: externalUrls.GREENLITE,
      loadingTableData: false,
      riskAlertTableRows: [],
      riskAlertTableHeaders: RISK_ALERT_HEADERS,
      currentPage: 1,
      totalRiskAlerts: 0,
      itemsPerPage: 50
    };
  },
  watch: {
    currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchRiskAlerts();
      }
    }
  },
  computed: {
    hasAlerts() {
      return !!this.riskAlertTableRows?.length;
    }
  },
  created() {
    this.fetchRiskAlerts();
  },
  methods: {
    async fetchRiskAlerts() {
      this.loadingTableData = true;
      try {
        // Needed when pagination is implemented.
        // const response = await HTTP(
        //   "get",
        //   `${endpoints.ESG_RISK_ALERTS(
        //     this.companyId,
        //     this.thirdPartyId
        //   )}?${queryParameters}`,
        //   null,
        //   makeAuthorizationHeader(),
        //   this.makeQueryParameters()
        // );

        const response = await HTTP(
          "get",
          `${endpoints.ESG_RISK_ALERTS(this.companyId, this.thirdPartyId)}`,
          null,
          makeAuthorizationHeader()
        );

        if (response?.data?.length) {
          this.makeRiskAlertTableRows(response.data);
          // Needed when pagination is implemented.

          // this.totalRiskAlerts = response.totalItems;
        } else {
          this.resetTableData();
        }
      } catch (error) {
        this.resetTableData();
      }
    },
    // Needed when pagination is implemented.

    // makeQueryParameters() {
    //   return {
    //     page_number: this.currentPage,
    //     per_page: this.itemsPerPage
    //   };
    // },
    makeRiskAlertTableRows(data) {
      this.riskAlertTableRows = data.map((risk) => ({
        styles: {
          verticalAlign: "top"
        },
        ...makeRiskAlertDTO(risk)
      }));
      this.loadingTableData = false;
    },
    resetTableData() {
      this.loadingTableData = false;
      this.riskAlertTableRows = [];
      // Needed when pagination is implemented.

      // this.totalRiskAlerts = response.totalItems;
    },
    onChangeCurrentPage(value) {
      this.currentPage = parseInt(value);
    },
    onChangeItems(value) {
      this.itemsPerPage = parseInt(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-alert-table {
  @include page-container;

  &__table {
    margin-bottom: 20px;
    min-width: 500px;
  }

  &:deep(.risk-alert-table__not-activated) {
    text-align: center;
    margin-top: 15px;
  }
}
</style>
