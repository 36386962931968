<template>
  <div
    v-if="showSearchFilterBar"
    class="search-filter-bar"
    data-test-id="search-filter-bar"
  >
    <div
      class="search-filter-bar__action-buttons"
      data-test-id="search-filter-bar__action-buttons"
    >
      <CallToAction
        v-for="buttonOptions in actionButtons"
        :key="buttonOptions.id"
        v-bind="buttonOptions"
        class="search-filter-bar__action-button"
        data-test-id="search-filter-bar__action-button"
        @click="$emit('click', { id: buttonOptions.id, event: $event })"
      />
    </div>
  </div>
</template>

<script>
import { makeUUID } from "@/utils";
import CallToAction from "@/atoms/CallToAction/CallToAction.vue";
import { isValidActionButton } from "./SearchFilterBar.logic";

export default {
  name: "SearchFilterBar",
  components: { CallToAction },
  props: {
    actionButtons: {
      type: Array,
      default: () => [],
      validator: (prop) => isValidActionButton(prop)
    }
  },
  computed: {
    showSearchFilterBar() {
      return !!this.actionButtons?.length;
    }
  },
  methods: { makeUUID }
};
</script>

<style lang="scss" scoped>
.search-filter-bar {
  display: flex;
  background: $grey;
  justify-content: space-between;
  padding: $small-spacing $spacing;

  &__action-button {
    margin-right: $spacing;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
