<template>
  <div class="row">
    <b-col cols="12">
      <b-tabs content-class="mt-3">
        <!-- Label For component -->
        <b-tab title="Label" active>
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab
              @click="tabLabelClick(language)"
              v-for="(language, index) in form.formTranslations"
              :key="index"
              :title="language"
            >
              <!-- translation form wrappe -->
              <div>
                <b-form-group label="Field Label">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="label"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Checkbox Label">
                  <b-form-input
                    @blur="addLabelLanguageToField(language)"
                    v-model="checkBoxLabel"
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <b-tab title="Configure">
          <b-form-group class="w-50 mb-5" label="Selec County List Type">
            <v-select
              :id="fieldData.id"
              searchable
              clearable
              :options="renderOptions"
              label="text"
              class="mb-5"
              v-model="fieldData.selectedListTypeIndex"
            />
          </b-form-group>
        </b-tab>

        <b-tab title="Registered Address Settings">
          <b-tabs content-class="mt-3">
            <!-- Text -->
            <b-tab title="Text" active>
              <b-tabs pills card vertical>
                <!-- Render Tabs, supply a unique `key` to each tab -->
                <b-tab
                  @click="tabRegisteredAddressLabelClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <!-- translation form wrappe -->
                  <div>
                    <b-form-group label="Registered From Label">
                      <b-form-input
                        @blur="addRegisteredFormLabelLanguageToField(language)"
                        v-model="registeredLabel"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="Registered From Description">
                      <b-form-textarea
                        @blur="addRegisteredFormLabelLanguageToField(language)"
                        v-model="registeredDescription"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Country label">
                          <b-form-input
                            @blur="
                              addRegisteredFormLabelLanguageToField(language)
                            "
                            v-model="registeredForm.countryTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="City label">
                          <b-form-input
                            @blur="
                              addRegisteredFormLabelLanguageToField(language)
                            "
                            v-model="registeredForm.cityTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Address1 label">
                          <b-form-input
                            @blur="
                              addRegisteredFormLabelLanguageToField(language)
                            "
                            v-model="registeredForm.address1Title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group label="Address2 Label">
                          <b-form-input
                            @blur="
                              addRegisteredFormLabelLanguageToField(language)
                            "
                            v-model="registeredForm.address2Title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Town Label">
                          <b-form-input
                            @blur="
                              addRegisteredFormLabelLanguageToField(language)
                            "
                            v-model="registeredForm.townTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group label="Zip Label">
                          <b-form-input
                            @blur="
                              addRegisteredFormLabelLanguageToField(language)
                            "
                            v-model="registeredForm.zipTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Free Form Label">
                          <b-form-input
                            @blur="
                              addRegisteredFormLabelLanguageToField(language)
                            "
                            v-model="registeredForm.freeFormTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Helper Text------------------- -->
            <b-tab title="Helper Text">
              <b-tabs pills card vertical>
                <b-tab
                  @click="tabRegisteredAddressHelperTextClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <b-form @submit.stop.prevent class="text-left mt-2">
                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Country</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormHelperTextLanguageToField(language)
                          "
                          v-model="registeredForm.countryHelperText"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="6">
                        <label for>Helper Text for City</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormHelperTextLanguageToField(language)
                          "
                          v-model="registeredForm.cityHelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Address</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormHelperTextLanguageToField(language)
                          "
                          v-model="registeredForm.address1HelperText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Helper Text for Address 2</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormHelperTextLanguageToField(language)
                          "
                          v-model="registeredForm.address2HelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Town</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormHelperTextLanguageToField(language)
                          "
                          v-model="registeredForm.townHelperText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Helper Text for Zip Code</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormHelperTextLanguageToField(language)
                          "
                          v-model="registeredForm.zipHelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Free Form</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormHelperTextLanguageToField(language)
                          "
                          v-model="registeredForm.freeFormHelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Place Holder Text------------------- -->
            <b-tab title="Place Holder">
              <b-tabs pills card vertical>
                <b-tab
                  @click="tabRegisteredAddressPlaceHolderTextClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <b-form @submit.stop.prevent class="text-left mt-2">
                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Place Holder for Address</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormPlaceHolderTextLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.address1PlaceHolderText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Place Holder for Address 2</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormPlaceHolderTextLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.address2PlaceHolderText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Place Holder for Town</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormPlaceHolderTextLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.townPlaceHolderText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Place Holder for City</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormPlaceHolderTextLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.cityPlaceHolderText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Place Holder for Zip Code</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormPlaceHolderTextLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.zipPlaceHolderText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Place Holder for Free Form</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormPlaceHolderTextLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.freeFormPlaceHolderText"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Validation Message------------------- -->
            <b-tab title="Validation Message">
              <b-tabs pills card vertical>
                <b-tab
                  @click="tabRegisteredAddressValidationMessageClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <b-form @submit.stop.prevent class="text-left mt-2">
                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Country</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.countryValidationMessage"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="6">
                        <label for>Validation Message for City</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.cityValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Address</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.address1ValidationMessage"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Validation Message for Address 2</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.address2ValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Town</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.townValidationMessage"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Validation Message for Zip Code</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.zipValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Free Form</label>
                        <b-form-input
                          @blur="
                            addRegisteredFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="registeredForm.freeFormValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Attributes------------------- -->
            <b-tab title="Attributes">
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Country</label>
                    <b-form-input
                      v-model="registeredForm.countryPostName"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Post name for City</label>
                    <b-form-input
                      v-model="registeredForm.cityPostName"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Address</label>
                    <b-form-input
                      v-model="registeredForm.address1PostName"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Post name for Address 2</label>
                    <b-form-input
                      v-model="registeredForm.address2PostName"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Town</label>
                    <b-form-input
                      v-model="registeredForm.townPostName"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Post name for Zip Code</label>
                    <b-form-input
                      v-model="registeredForm.zipPostName"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Free Form</label>
                    <b-form-input
                      v-model="registeredForm.freeFormPostName"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>

            <!-- ----------------Advance------------------->
            <b-tab title="Advanced">
              <b-row>
                <b-col cols="10">
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="registeredFormCountryDisabled"
                    >Disable Country?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="registeredFormCityDisabled"
                    >Disable City?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="registeredFormAddress1Disabled"
                    >Disable Address1?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="registeredFormAddress2Disabled"
                    >Disable Address2?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="registeredFormTownDisabled"
                    >Disable Town?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="registeredFormZipDisabled"
                    >Disable Zip?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="registrationFormFreeFormDisabled"
                    >Disable Free Form?</b-form-checkbox
                  >
                </b-col>
              </b-row>
            </b-tab>

            <!-- data validation -->
            <b-tab title="Validation">
              <div class="text-left">
                <b-col>
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireRegistrationFormCountry"
                    >Is required Country?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireRegistrationFormCity"
                    >Is required City?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireRegistrationFormAddress1"
                    >Is required Address1?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireRegistrationFormAddress2"
                    >Is required Address2?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireRegistrationFormTown"
                    >Is required Town?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireRegistrationFormZip"
                    >Is required Zip?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireRegistrationFreeForm"
                    >Is required Free Form?</b-form-checkbox
                  >
                </b-col>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <b-tab title="Trading Address Settings">
          <b-tabs content-class="mt-3">
            <!-- Text -->
            <b-tab title="Text" active>
              <b-tabs pills card vertical>
                <!-- Render Tabs, supply a unique `key` to each tab -->
                <b-tab
                  @click="tabTradingAddressLabelClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <!-- translation form wrappe -->
                  <div>
                    <b-form-group label="Trading From Label">
                      <b-form-input
                        @blur="addTradingFormLabelTextLanguageToField(language)"
                        v-model="tradingLabel"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="Trading From Description">
                      <b-form-textarea
                        @blur="addTradingFormLabelTextLanguageToField(language)"
                        v-model="tradingDescription"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Country label">
                          <b-form-input
                            @blur="
                              addTradingFormLabelTextLanguageToField(language)
                            "
                            v-model="tradingForm.countryTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="City label">
                          <b-form-input
                            @blur="
                              addTradingFormLabelTextLanguageToField(language)
                            "
                            v-model="tradingForm.cityTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Address1 label">
                          <b-form-input
                            @blur="
                              addTradingFormLabelTextLanguageToField(language)
                            "
                            v-model="tradingForm.address1Title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group label="Address2 Label">
                          <b-form-input
                            @blur="
                              addTradingFormLabelTextLanguageToField(language)
                            "
                            v-model="tradingForm.address2Title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Town Label">
                          <b-form-input
                            @blur="
                              addTradingFormLabelTextLanguageToField(language)
                            "
                            v-model="tradingForm.townTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group label="Zip Label">
                          <b-form-input
                            @blur="
                              addTradingFormLabelTextLanguageToField(language)
                            "
                            v-model="tradingForm.zipTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Free Form Label">
                          <b-form-input
                            @blur="
                              addTradingFormLabelTextLanguageToField(language)
                            "
                            v-model="tradingForm.freeFormTitle"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Helper Text------------------- -->
            <b-tab title="Helper Text">
              <b-tabs pills card vertical>
                <b-tab
                  @click="tabTradingAddressHelperTextClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <b-form @submit.stop.prevent class="text-left mt-2">
                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Country</label>
                        <b-form-input
                          @blur="
                            addTradingFormHelperTextLanguageToField(language)
                          "
                          v-model="tradingForm.countryHelperText"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="6">
                        <label for>Helper Text for City / State</label>
                        <b-form-input
                          @blur="
                            addTradingFormHelperTextLanguageToField(language)
                          "
                          v-model="tradingForm.cityHelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Address</label>
                        <b-form-input
                          @blur="
                            addTradingFormHelperTextLanguageToField(language)
                          "
                          v-model="tradingForm.address1HelperText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Helper Text for Address 2</label>
                        <b-form-input
                          @blur="
                            addTradingFormHelperTextLanguageToField(language)
                          "
                          v-model="tradingForm.address2HelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Town</label>
                        <b-form-input
                          @blur="
                            addTradingFormHelperTextLanguageToField(language)
                          "
                          v-model="tradingForm.townHelperText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Helper Text for Zip/Postal Code</label>
                        <b-form-input
                          @blur="
                            addTradingFormHelperTextLanguageToField(language)
                          "
                          v-model="tradingForm.zipHelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Helper Text for Free Form</label>
                        <b-form-input
                          @blur="
                            addTradingFormHelperTextLanguageToField(language)
                          "
                          v-model="tradingForm.freeFormHelperText"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Place Holder Text------------------- -->
            <b-tab title="Place Holder">
              <b-tabs pills card vertical>
                <b-tab
                  @click="tabTradingAddressPlaceHolderTextClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <b-form @submit.stop.prevent class="text-left mt-2">
                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Place Holder for Address</label>
                        <b-form-input
                          @blur="
                            addTradingFormPlaceHolderLanguageToField(language)
                          "
                          v-model="tradingForm.address1PlaceHolderText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Place Holder for Address 2</label>
                        <b-form-input
                          @blur="
                            addTradingFormPlaceHolderLanguageToField(language)
                          "
                          v-model="tradingForm.address2PlaceHolderText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Place Holder for Town</label>
                        <b-form-input
                          @blur="
                            addTradingFormPlaceHolderLanguageToField(language)
                          "
                          v-model="tradingForm.townPlaceHolderText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Place Holder for City / State</label>
                        <b-form-input
                          @blur="
                            addTradingFormPlaceHolderLanguageToField(language)
                          "
                          v-model="tradingForm.cityPlaceHolderText"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Place Holder for Zip/Postal Code</label>
                        <b-form-input
                          @blur="
                            addTradingFormPlaceHolderLanguageToField(language)
                          "
                          v-model="tradingForm.zipPlaceHolderText"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Place Holder for Free Form</label>
                        <b-form-input
                          @blur="
                            addTradingFormPlaceHolderLanguageToField(language)
                          "
                          v-model="tradingForm.freeFormPlaceHolderText"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Validation Message------------------- -->
            <b-tab title="Validation Message">
              <b-tabs pills card vertical>
                <b-tab
                  @click="tabTradingAddressValidationMessageClick(language)"
                  v-for="(language, index) in form.formTranslations"
                  :key="index"
                  :title="language"
                >
                  <b-form @submit.stop.prevent class="text-left mt-2">
                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Country</label>
                        <b-form-input
                          @blur="
                            addTradingFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="tradingForm.countryValidationMessage"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="6">
                        <label for>Validation Message for City / State</label>
                        <b-form-input
                          v-model="tradingForm.cityValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Address</label>
                        <b-form-input
                          @blur="
                            addTradingFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="tradingForm.address1ValidationMessage"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for>Validation Message for Address 2</label>
                        <b-form-input
                          @blur="
                            addTradingFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="tradingForm.address2ValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Town</label>
                        <b-form-input
                          @blur="
                            addTradingFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="tradingForm.townValidationMessage"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6">
                        <label for
                          >Validation Message for Zip/Postal Code</label
                        >
                        <b-form-input
                          @blur="
                            addTradingFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="tradingForm.zipValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row class="mx-2">
                      <b-col cols="6">
                        <label for>Validation Message for Free Form</label>
                        <b-form-input
                          @blur="
                            addTradingFormValidationMessageLanguageToField(
                              language
                            )
                          "
                          v-model="tradingForm.freeFormValidationMessage"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-tab>
              </b-tabs>
            </b-tab>

            <!-- ----------------Attributes------------------- -->
            <b-tab title="Attributes">
              <b-form @submit.stop.prevent class="text-left mt-2">
                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Country</label>
                    <b-form-input
                      v-model="tradingForm.countryPostName"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label for>Post name for City / State</label>
                    <b-form-input
                      v-model="tradingForm.cityPostName"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Address</label>
                    <b-form-input
                      v-model="tradingForm.address1PostName"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Post name for Address 2</label>
                    <b-form-input
                      v-model="tradingForm.address2PostName"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Town</label>
                    <b-form-input
                      v-model="tradingForm.townPostName"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label for>Post name for Zip/Postal Code</label>
                    <b-form-input
                      v-model="tradingForm.zipPostName"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mx-2">
                  <b-col cols="6">
                    <label for>Post name for Free Form</label>
                    <b-form-input
                      v-model="tradingForm.freeFormPostName"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>

            <!-- ----------------Advance------------------->
            <b-tab title="Advanced">
              <b-row>
                <b-col cols="10">
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="tradingFormCountryDisabled"
                    >Disable Country?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="tradingFormCityDisabled"
                    >Disable City?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="tradingFormAddress1Disabled"
                    >Disable Address1?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="tradingFormAddress2Disabled"
                    >Disable Address2?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="tradingFormTownDisabled"
                    >Disable Town?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="tradingFormZipDisabled"
                    >Disable Zip?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="text-left mb-3"
                    v-model="tradingFormFreeFormDisabled"
                    >Disable Free Form?</b-form-checkbox
                  >
                </b-col>
              </b-row>
            </b-tab>

            <!-- data validation -->
            <b-tab title="Validation">
              <div class="text-left">
                <b-col>
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireTradingFormCountry"
                    >Is required Country?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireTradingFormCity"
                    >Is required City?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireTradingFormAddress1"
                    >Is required Address1?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireTradingFormAddress2"
                    >Is required Address2?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireTradingFormTown"
                    >Is required Town?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireTradingFormZip"
                    >Is required Zip?</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="form-group col-md-6 ml-3"
                    v-model="isRequireTradingFreeForm"
                    >Is required Free Form?</b-form-checkbox
                  >
                </b-col>
              </div>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- --------------Conditional------------------ -->
        <b-tab title="Conditional">
          <b-tabs pills card vertical>
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab title="Simple">
              <b-row> </b-row>
              <b-col cols="12" class="mr-0">
                <b-form-checkbox
                  v-model="fieldData.isConditional"
                  name="conditional"
                  value="1"
                  unchecked-value="0"
                  >Is Conditional?</b-form-checkbox
                >

                <div v-if="fieldData.isConditional == 1">
                  <b-row>
                    <table class="table table-sm">
                      <thead>
                        <tr class="text-center">
                          <th>No</th>
                          <th>When Field</th>
                          <th>Has Value</th>
                          <th>Actions</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(selectedOpt, index) in fieldData.conditions"
                          :key="selectedOpt.id"
                        >
                          <td>{{ index }}</td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.label" />
                          </td>
                          <td>
                            <b-form-input disabled :value="selectedOpt.value" />
                          </td>
                          <td>
                            <b-button
                              variant="outline-danger"
                              class="btn-sm ml-1"
                              @click="
                                onRemoveConditionOpt(selectedOpt.id, index)
                              "
                            >
                              <i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>

                        <tr
                          v-for="(noOfOption, index) in noOfConditions"
                          :key="index"
                        >
                          <td>{{ fieldData.conditions.length }}</td>
                          <td>
                            <v-select
                              v-model="conditionalField"
                              class="mb-2"
                              :options="conditionalSectionElementLables"
                              label="label"
                              @input="getSelectedElement"
                            ></v-select>
                          </td>
                          <td>
                            <v-select
                              class="mb-2"
                              label="text"
                              v-model="conditionalFieldValue"
                              :options="selectedElementOption"
                            ></v-select>
                          </td>

                          <td>
                            <b-button
                              class="btn-sm"
                              variant="outline-primary"
                              @click="setFieldConditions"
                              >Apply</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-row>
                </div>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import uid from "uid";
import list from "@/utils/countryListTypes.json";

export default {
  name: "PrincipalContactSettings",
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number
  },
  components: {},

  data() {
    return {
      // conditional
      isConditional:
        this.fieldData.isConditional && this.fieldData.conditions.length,
      conditionalSectionElements: [],
      conditionalSectionElementLables: [],
      selectedElementOption: [],
      conditionalField: null,
      conditionalFieldValue: null,
      condition: {},
      noOfConditions: [1],

      // default lang
      transalationLanguage: "english",
      // Input General
      registeredDescription: null,
      tradingDescription: null,
      description: null,
      // labels
      registeredLabel: null,
      tradingLabel: null,
      label: null,
      checkBoxLabel: null,
      registeredForm: {
        countryTitle: null,
        cityTitle: null,
        address1Title: null,
        address2Title: null,
        townTitle: null,
        zipTitle: null,
        freeFormTitle: null,
        // helper text
        address1HelperText: null,
        address2HelperText: null,
        townHelperText: null,
        cityHelperText: null,
        usStateHelperText: null,
        countryHelperText: null,
        zipHelperText: null,
        freeFormHelperText: null,

        // placeholder
        address1PlaceHolderText: null,
        address2PlaceHolderText: null,
        townPlaceHolderText: null,
        cityPlaceHolderText: null,
        zipPlaceHolderText: null,
        freeFormPlaceHolderText: null,

        // validation
        address1ValidationMessage: null,
        address2ValidationMessage: null,
        townValidationMessage: null,
        cityValidationMessage: null,
        usStateValidationMessage: null,
        countryValidationMessage: null,
        zipValidationMessage: null,
        freeFormValidationMessage: null,

        // postname, attibute
        countryPostName: null,
        cityPostName: null,
        usStatePostName: null,
        address1PostName: null,
        address2PostName: null,
        townPostName: null,
        zipPostName: null,
        freeFormPostName: null
      },

      tradingForm: {
        countryTitle: null,
        cityTitle: null,
        address1Title: null,
        address2Title: null,
        townTitle: null,
        zipTitle: null,
        freeFormTitle: null,
        // helper text
        address1HelperText: null,
        address2HelperText: null,
        townHelperText: null,
        cityHelperText: null,
        usStateHelperText: null,
        countryHelperText: null,
        zipHelperText: null,
        freeFormHelperText: null,
        // placeholder
        address1PlaceHolderText: null,
        address2PlaceHolderText: null,
        townPlaceHolderText: null,
        cityPlaceHolderText: null,
        zipPlaceHolderText: null,
        freeFormPlaceHolderText: null,
        // validation
        address1ValidationMessage: null,
        address2ValidationMessage: null,
        townValidationMessage: null,
        cityValidationMessage: null,
        usStateValidationMessage: null,
        countryValidationMessage: null,
        zipValidationMessage: null,
        freeFormValidationMessage: null,
        countryPostName: null,
        cityPostName: null,
        usStatePostName: null,
        address1PostName: null,
        address2PostName: null,
        townPostName: null,
        zipPostName: null,
        freeFormPostName: null
      },

      // Registerd Form hidden/diabled
      registeredFormCountryDisabled: false,
      registeredFormUsStateDisabled: false,
      registeredFormCityDisabled: false,
      registeredFormAddress1Disabled: false,
      registeredFormAddress2Disabled: false,
      registeredFormTownDisabled: false,
      registeredFormZipDisabled: false,
      registrationFormFreeFormDisabled: true,

      // Trading Form hidden/diabled

      tradingFormCountryDisabled: false,
      tradingFormUsStateDisabled: false,
      tradingFormCityDisabled: false,
      tradingFormAddress1Disabled: false,
      tradingFormAddress2Disabled: false,
      tradingFormTownDisabled: false,
      tradingFormZipDisabled: false,
      tradingFormFreeFormDisabled: true,

      // validation
      isRequireRegistrationFormCountry: true,
      isRequireRegistrationFormCity: true,
      isRequireRegistrationFormUsState: true,
      isRequireRegistrationFormAddress1: true,
      isRequireRegistrationFormAddress2: true,
      isRequireRegistrationFormTown: true,
      isRequireRegistrationFormZip: true,
      isRequireRegistrationFreeForm: false,

      isRequireTradingFormCountry: false,
      isRequireTradingFormCity: false,
      isRequireTradingFormUsState: false,
      isRequireTradingFormAddress1: false,
      isRequireTradingFormAddress2: false,
      isRequireTradingFormTown: false,
      isRequireTradingFormZip: false,
      isRequireTradingFreeForm: false
    };
  },

  created() {
    EventBus.$on("onSaveSettings", () => {
      this.addLabelLanguageToField(this.transalationLanguage);

      // // Registered Form
      // this.addRegisteredFormLabelLanguageToField(this.transalationLanguage);
      // this.addRegisteredFormHelperTextLanguageToField(this.transalationLanguage);
      // this.addRegisteredFormPlaceHolderTextLanguageToField(this.transalationLanguage);
      // this.addRegisteredFormValidationMessageLanguageToField(this.transalationLanguage);

      // // Trading Form
      // this.addTradingFormLabelTextLanguageToField(this.transalationLanguage);
      // this.addTradingFormHelperTextLanguageToField(this.transalationLanguage);
      // this.addTradingFormPlaceHolderLanguageToField(this.transalationLanguage);
      // this.addTradingFormValidationMessageLanguageToField(this.transalationLanguage);

      this.updateAdvancedSettings();
      this.setValidation();
      this.setAttributes();
    });
  },

  mounted() {
    this.setFiledsLabelText();

    // Registered Form
    this.setFiledsRegisteredFormLabelText();
    this.setFiledsRegisteredFormHelperText();
    this.setFiledsRegisteredFormPlaceHolderText();
    this.setFiledsRegisteredFormValidationMessage();

    // Trading Form
    this.setFiledsTradingFormLabelText();
    this.setFiledsTradingFormHelperText();
    this.setFiledsTradingFormPlaceHolderText();
    this.setFiledsTradingFormValidationMessage();
    this.setFiledsAttributes();
    this.setFiledsAdvance();
    this.setFiledsValidations();

    // condtional
    this.getCurrentSectionList();
  },

  computed: {
    ...mapGetters({ form: "form1" }),
    renderOptions() {
      return list;
    }
  },

  methods: {
    setFiledsLabelText() {
      // field label

      this.label = this.fieldData.label[this.transalationLanguage];

      this.checkBoxLabel =
        this.fieldData.checkBoxLabel[this.transalationLanguage];
    },

    setFiledsRegisteredFormLabelText() {
      // Registered Form
      // Registerd Form label

      this.registeredLabel =
        this.fieldData.registeredLabel[this.transalationLanguage];

      this.registeredDescription =
        this.fieldData.registeredDescription[this.transalationLanguage];

      this.registeredForm.address1Title =
        this.fieldData.registeredAddressLine1Label[this.transalationLanguage];

      this.registeredForm.address2Title =
        this.fieldData.registeredAddressLine2Label[this.transalationLanguage];

      this.registeredForm.townTitle =
        this.fieldData.registeredAddressTownLabel[this.transalationLanguage];

      this.registeredForm.cityTitle =
        this.fieldData.registeredAddressCityStateLabel[
          this.transalationLanguage
        ];
      this.registeredForm.countryTitle =
        this.fieldData.registeredAddressCountryLabel[this.transalationLanguage];
      this.registeredForm.zipTitle =
        this.fieldData.registeredAddressPostalZipCodeLabel[
          this.transalationLanguage
        ];
      this.registeredForm.freeFormTitle =
        this.fieldData.registrationFormFreeFormLabel[this.transalationLanguage];
    },

    setFiledsTradingFormLabelText() {
      // trading Form Label

      this.tradingDescription =
        this.fieldData.tradingDescription[this.transalationLanguage];

      this.tradingLabel =
        this.fieldData.tradingLabel[this.transalationLanguage];

      this.tradingForm.address1Title =
        this.fieldData.tradingAddressLine1Label[this.transalationLanguage];
      this.tradingForm.address2Title =
        this.fieldData.tradingAddressLine2Label[this.transalationLanguage];
      this.tradingForm.townTitle =
        this.fieldData.tradingAddressTownLabel[this.transalationLanguage];
      this.tradingForm.cityTitle =
        this.fieldData.tradingAddressCityStateLabel[this.transalationLanguage];
      this.tradingForm.countryTitle =
        this.fieldData.tradingAddressCountryLabel[this.transalationLanguage];
      this.tradingForm.zipTitle =
        this.fieldData.tradingAddressPostalZipCodeLabel[
          this.transalationLanguage
        ];
      this.tradingForm.freeFormTitle =
        this.fieldData.tradingFormFreeFormLabel[this.transalationLanguage];
    },

    setFiledsRegisteredFormHelperText() {
      // Registered Form Helper Text

      this.registeredForm.address1HelperText =
        this.fieldData.registeredAddressLine1HelperText[
          this.transalationLanguage
        ];

      this.registeredForm.address2HelperText =
        this.fieldData.registeredAddressLine2HelperText[
          this.transalationLanguage
        ];

      this.registeredForm.townHelperText =
        this.fieldData.registeredAddressTownHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.cityHelperText =
        this.fieldData.registeredAddressCityStateHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.usStateHelperText =
        this.fieldData.registeredAddressUsStateHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.countryHelperText =
        this.fieldData.registeredAddressCountryHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.zipHelperText =
        this.fieldData.registeredAddressPostalZipCodeHelperText[
          this.transalationLanguage
        ];
      this.registeredForm.freeFormHelperText =
        this.fieldData.registrationFormFreeFormHelperText[
          this.transalationLanguage
        ];
    },

    setFiledsTradingFormHelperText() {
      // trading form Helper text

      this.tradingForm.address1HelperText =
        this.fieldData.tradingAddressAddressLine1HelperText[
          this.transalationLanguage
        ];

      this.tradingForm.address2HelperText =
        this.fieldData.tradingAddressAddressLine2HelperText[
          this.transalationLanguage
        ];

      this.tradingForm.townHelperText =
        this.fieldData.tradingAddressAddressTownHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.cityHelperText =
        this.fieldData.tradingAddressAddressCityStateHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.usStateHelperText =
        this.fieldData.tradingAddressUsStateHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.countryHelperText =
        this.fieldData.tradingAddressAddressCountryHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.zipHelperText =
        this.fieldData.tradingAddressAddressPostalZipCodeHelperText[
          this.transalationLanguage
        ];
      this.tradingForm.freeFormHelperText =
        this.fieldData.tradingFormFreeFormHelperText[this.transalationLanguage];
    },

    setFiledsRegisteredFormPlaceHolderText() {
      // Registered Form Place Holder Text

      this.registeredForm.address1PlaceHolderText =
        this.fieldData.registeredAddressLine1Placeholder[
          this.transalationLanguage
        ];

      this.registeredForm.address2PlaceHolderText =
        this.fieldData.registeredAddressLine2Placeholder[
          this.transalationLanguage
        ];

      this.registeredForm.townPlaceHolderText =
        this.fieldData.registeredAddressTownPlaceholder[
          this.transalationLanguage
        ];

      this.registeredForm.cityPlaceHolderText =
        this.fieldData.registeredAddressCityStatePlaceholder[
          this.transalationLanguage
        ];

      this.registeredForm.zipPlaceHolderText =
        this.fieldData.registeredAddressPostalZipCodePlaceholder[
          this.transalationLanguage
        ];
      this.registeredForm.freeFormPlaceHolderText =
        this.fieldData.registrationFormFreeFormPlaceholderText[
          this.transalationLanguage
        ];
    },

    setFiledsTradingFormPlaceHolderText() {
      // trading form Place Holder Text

      this.tradingForm.address1PlaceHolderText =
        this.fieldData.tradingAddressLine1Placeholder[
          this.transalationLanguage
        ];

      this.tradingForm.address2PlaceHolderText =
        this.fieldData.tradingAddressLine2Placeholder[
          this.transalationLanguage
        ];

      this.tradingForm.townPlaceHolderText =
        this.fieldData.tradingAddressTownPlaceholder[this.transalationLanguage];

      this.tradingForm.cityPlaceHolderText =
        this.fieldData.tradingAddressCityStatePlaceholder[
          this.transalationLanguage
        ];

      this.tradingForm.zipPlaceHolderText =
        this.fieldData.tradingAddressPostalZipCodePlaceholder[
          this.transalationLanguage
        ];
      this.tradingForm.freeFormPlaceHolderText =
        this.fieldData.tradingFormFreeFormPlaceholderText[
          this.transalationLanguage
        ];
    },

    setFiledsRegisteredFormValidationMessage() {
      // Registered Form Validation Message

      this.registeredForm.address1ValidationMessage =
        this.fieldData.registeredAddressLine1ValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.address2ValidationMessage =
        this.fieldData.registeredAddressLine2ValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.townValidationMessage =
        this.fieldData.registeredAddressTownValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.cityValidationMessage =
        this.fieldData.registeredAddressCityStateValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.usStateValidationMessage =
        this.fieldData.registeredAddressUsStateValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.countryValidationMessage =
        this.fieldData.registeredAddressCountryValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.zipValidationMessage =
        this.fieldData.registeredAddressPostalZipCodeValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.freeFormValidationMessage =
        this.fieldData.registrationFormFreeFormValidationMessage[
          this.transalationLanguage
        ];
    },

    setFiledsTradingFormValidationMessage() {
      // trading form Validation Message

      this.tradingForm.address1ValidationMessage =
        this.fieldData.tradingAddressAddressLine1ValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.address2ValidationMessage =
        this.fieldData.tradingAddressAddressLine2ValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.townValidationMessage =
        this.fieldData.tradingAddressAddressTownValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.cityValidationMessage =
        this.fieldData.tradingAddressAddressCityStateValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.usStateValidationMessage =
        this.fieldData.tradingAddressUsStateValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.countryValidationMessage =
        this.fieldData.tradingAddressAddressCountryValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.zipValidationMessage =
        this.fieldData.tradingAddressAddressPostalZipCodeValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.freeFormValidationMessage =
        this.fieldData.tradingFormFreeFormValidationMessage[
          this.transalationLanguage
        ];
    },

    setFiledsAttributes() {
      // Registered Address Form

      (this.registeredForm.countryPostName =
        this.fieldData.attributes && this.fieldData.attributes.countryPostName
          ? this.fieldData.attributes.countryPostName
          : "countryPostName"),
        (this.registeredForm.cityPostName =
          this.fieldData.attributes && this.fieldData.attributes.cityPostName
            ? this.fieldData.attributes.cityPostName
            : "cityPostName"),
        (this.registeredForm.address1PostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.address1PostName
            ? this.fieldData.attributes.address1PostName
            : "address1PostName"),
        (this.registeredForm.address2PostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.address2PostName
            ? this.fieldData.attributes.address2PostName
            : "address2PostName"),
        (this.registeredForm.townPostName =
          this.fieldData.attributes && this.fieldData.attributes.townPostName
            ? this.fieldData.attributes.townPostName
            : "townPostName"),
        (this.registeredForm.zipPostName =
          this.fieldData.attributes && this.fieldData.attributes.zipPostName
            ? this.fieldData.attributes.zipPostName
            : "zipPostName"),
        (this.registeredForm.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : "freeFormPostName");

      // Trading Address Form

      (this.tradingForm.countryPostName =
        this.fieldData.attributes && this.fieldData.attributes.countryPostName
          ? this.fieldData.attributes.countryPostName
          : "countryPostName"),
        (this.tradingForm.cityPostName =
          this.fieldData.attributes && this.fieldData.attributes.cityPostName
            ? this.fieldData.attributes.cityPostName
            : "cityPostName"),
        (this.tradingForm.address1PostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.address1PostName
            ? this.fieldData.attributes.address1PostName
            : "address1PostName"),
        (this.tradingForm.address2PostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.address2PostName
            ? this.fieldData.attributes.address2PostName
            : "address2PostName"),
        (this.tradingForm.townPostName =
          this.fieldData.attributes && this.fieldData.attributes.townPostName
            ? this.fieldData.attributes.townPostName
            : "townPostName"),
        (this.tradingForm.zipPostName =
          this.fieldData.attributes && this.fieldData.attributes.zipPostName
            ? this.fieldData.attributes.zipPostName
            : "zipPostName"),
        (this.tradingForm.freeFormPostName =
          this.fieldData.attributes &&
          this.fieldData.attributes.freeFormPostName
            ? this.fieldData.attributes.freeFormPostName
            : "freeFormPostName");
    },

    setFiledsAdvance() {
      // Registered Address Form

      (this.registeredFormCountryDisabled =
        this.fieldData.advance &&
        this.fieldData.advance.registeredFormCountryDisabled),
        (this.registeredFormCityDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.registeredFormCityDisabled),
        (this.registeredFormAddress1Disabled =
          this.fieldData.advance &&
          this.fieldData.advance.registeredFormAddress1Disabled),
        (this.registeredFormAddress2Disabled =
          this.fieldData.advance &&
          this.fieldData.advance.registeredFormAddress2Disabled),
        (this.registeredFormTownDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.registeredFormTownDisabled),
        (this.registeredFormZipDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.registeredFormZipDisabled),
        (this.registrationFormFreeFormDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.registrationFormFreeFormDisabled);

      // Trading Address Form

      (this.tradingFormCountryDisabled =
        this.fieldData.advance &&
        this.fieldData.advance.tradingFormCountryDisabled),
        (this.tradingFormCityDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.tradingFormCityDisabled),
        (this.tradingFormAddress1Disabled =
          this.fieldData.advance &&
          this.fieldData.advance.tradingFormAddress1Disabled),
        (this.tradingFormAddress2Disabled =
          this.fieldData.advance &&
          this.fieldData.advance.tradingFormAddress2Disabled),
        (this.tradingFormTownDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.tradingFormTownDisabled),
        (this.tradingFormZipDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.tradingFormZipDisabled),
        (this.tradingFormFreeFormDisabled =
          this.fieldData.advance &&
          this.fieldData.advance.tradingFormFreeFormDisabled);
    },

    setFiledsValidations() {
      (this.isRequireRegistrationFormCountry =
        this.fieldData.validation.isRequireRegistrationFormCountry),
        (this.isRequireRegistrationFormCity =
          this.fieldData.validation.isRequireRegistrationFormCity),
        (this.isRequireRegistrationFormAddress1 =
          this.fieldData.validation.isRequireRegistrationFormAddress1),
        (this.isRequireRegistrationFormAddress2 =
          this.fieldData.validation.isRequireRegistrationFormAddress2),
        (this.isRequireRegistrationFormTown =
          this.fieldData.validation.isRequireRegistrationFormTown),
        (this.isRequireRegistrationFormZip =
          this.fieldData.validation.isRequireRegistrationFormZip),
        (this.isRequireRegistrationFreeForm =
          this.fieldData.validation.isRequireRegistrationFreeForm),
        (this.isRequireTradingFormCountry =
          this.fieldData.validation.isRequireTradingFormCountry),
        (this.isRequireTradingFormCity =
          this.fieldData.validation.isRequireTradingFormCity),
        (this.isRequireTradingFormAddress1 =
          this.fieldData.validation.isRequireTradingFormAddress1),
        (this.isRequireTradingFormAddress2 =
          this.fieldData.validation.isRequireTradingFormAddress2),
        (this.isRequireTradingFormTown =
          this.fieldData.validation.isRequireTradingFormTown),
        (this.isRequireTradingFormZip =
          this.fieldData.validation.isRequireTradingFormZip),
        (this.isRequireTradingFreeForm =
          this.fieldData.validation.isRequireTradingFreeForm);
    },

    tabLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // label

      this.label = this.fieldData.label[this.transalationLanguage];

      this.checkBoxLabel =
        this.fieldData.checkBoxLabel[this.transalationLanguage];
    },

    tabRegisteredAddressLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();
      //

      // Registered Form
      // Form label or Description

      this.registeredLabel =
        this.fieldData.registeredLabel[this.transalationLanguage];

      this.registeredDescription =
        this.fieldData.registeredDescription[this.transalationLanguage];

      this.registeredForm.address1Title =
        this.fieldData.registeredAddressLine1Label[this.transalationLanguage];

      this.registeredForm.address2Title =
        this.fieldData.registeredAddressLine2Label[this.transalationLanguage];

      this.registeredForm.townTitle =
        this.fieldData.registeredAddressTownLabel[this.transalationLanguage];

      this.registeredForm.cityTitle =
        this.fieldData.registeredAddressCityStateLabel[
          this.transalationLanguage
        ];
      this.registeredForm.countryTitle =
        this.fieldData.registeredAddressCountryLabel[this.transalationLanguage];
      this.registeredForm.zipTitle =
        this.fieldData.registeredAddressPostalZipCodeLabel[
          this.transalationLanguage
        ];
      this.registeredForm.freeFormTitle =
        this.fieldData.registrationFormFreeFormLabel[this.transalationLanguage];
    },

    tabTradingAddressLabelClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // trading...
      // Form label or Description

      this.tradingLabel =
        this.fieldData.tradingLabel[this.transalationLanguage];

      this.tradingDescription =
        this.fieldData.tradingDescription[this.transalationLanguage];

      this.tradingForm.address1Title =
        this.fieldData.tradingAddressLine1Label[this.transalationLanguage];
      this.tradingForm.address2Title =
        this.fieldData.tradingAddressLine2Label[this.transalationLanguage];
      this.tradingForm.townTitle =
        this.fieldData.tradingAddressTownLabel[this.transalationLanguage];
      this.tradingForm.cityTitle =
        this.fieldData.tradingAddressCityStateLabel[this.transalationLanguage];
      this.tradingForm.countryTitle =
        this.fieldData.tradingAddressCountryLabel[this.transalationLanguage];
      this.tradingForm.zipTitle =
        this.fieldData.tradingAddressPostalZipCodeLabel[
          this.transalationLanguage
        ];
      this.tradingForm.freeFormTitle =
        this.fieldData.tradingFormFreeFormLabel[this.transalationLanguage];
    },

    tabRegisteredAddressHelperTextClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // Registered Form Helper Text

      this.registeredForm.address1HelperText =
        this.fieldData.registeredAddressLine1HelperText[
          this.transalationLanguage
        ];

      this.registeredForm.address2HelperText =
        this.fieldData.registeredAddressLine2HelperText[
          this.transalationLanguage
        ];

      this.registeredForm.townHelperText =
        this.fieldData.registeredAddressTownHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.cityHelperText =
        this.fieldData.registeredAddressCityStateHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.usStateHelperText =
        this.fieldData.registeredAddressUsStateHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.countryHelperText =
        this.fieldData.registeredAddressCountryHelperText[
          this.transalationLanguage
        ];

      this.registeredForm.zipHelperText =
        this.fieldData.registeredAddressPostalZipCodeHelperText[
          this.transalationLanguage
        ];
      this.registeredForm.freeFormHelperText =
        this.fieldData.registrationFormFreeFormHelperText[
          this.transalationLanguage
        ];
    },

    tabTradingAddressHelperTextClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // trading form Helper text

      this.tradingForm.address1HelperText =
        this.fieldData.tradingAddressAddressLine1HelperText[
          this.transalationLanguage
        ];

      this.tradingForm.address2HelperText =
        this.fieldData.tradingAddressAddressLine2HelperText[
          this.transalationLanguage
        ];

      this.tradingForm.townHelperText =
        this.fieldData.tradingAddressAddressTownHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.cityHelperText =
        this.fieldData.tradingAddressAddressCityStateHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.usStateHelperText =
        this.fieldData.tradingAddressUsStateHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.countryHelperText =
        this.fieldData.tradingAddressAddressCountryHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.zipHelperText =
        this.fieldData.tradingAddressAddressPostalZipCodeHelperText[
          this.transalationLanguage
        ];

      this.tradingForm.freeFormHelperText =
        this.fieldData.tradingFormFreeFormHelperText[this.transalationLanguage];
    },

    tabRegisteredAddressPlaceHolderTextClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // Registered Form Place Holder Text

      this.registeredForm.address1PlaceHolderText =
        this.fieldData.registeredAddressLine1Placeholder[
          this.transalationLanguage
        ];

      this.registeredForm.address2PlaceHolderText =
        this.fieldData.registeredAddressLine2Placeholder[
          this.transalationLanguage
        ];

      this.registeredForm.townPlaceHolderText =
        this.fieldData.registeredAddressTownPlaceholder[
          this.transalationLanguage
        ];

      this.registeredForm.cityPlaceHolderText =
        this.fieldData.registeredAddressCityStatePlaceholder[
          this.transalationLanguage
        ];

      this.registeredForm.zipPlaceHolderText =
        this.fieldData.registeredAddressPostalZipCodePlaceholder[
          this.transalationLanguage
        ];

      this.registeredForm.freeFormPlaceHolderText =
        this.fieldData.registrationFormFreeFormPlaceholderText[
          this.transalationLanguage
        ];
    },

    tabTradingAddressPlaceHolderTextClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // trading form Place Holder Text

      this.tradingForm.address1PlaceHolderText =
        this.fieldData.tradingAddressLine1Placeholder[
          this.transalationLanguage
        ];

      this.tradingForm.address2PlaceHolderText =
        this.fieldData.tradingAddressLine2Placeholder[
          this.transalationLanguage
        ];

      this.tradingForm.townPlaceHolderText =
        this.fieldData.tradingAddressTownPlaceholder[this.transalationLanguage];

      this.tradingForm.cityPlaceHolderText =
        this.fieldData.tradingAddressCityStatePlaceholder[
          this.transalationLanguage
        ];

      this.tradingForm.zipPlaceHolderText =
        this.fieldData.tradingAddressPostalZipCodePlaceholder[
          this.transalationLanguage
        ];

      this.tradingForm.freeFormPlaceHolderText =
        this.fieldData.tradingFormFreeFormPlaceholderText[
          this.transalationLanguage
        ];
    },

    tabRegisteredAddressValidationMessageClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // Registered Form Validation Message

      this.registeredForm.address1ValidationMessage =
        this.fieldData.registeredAddressLine1ValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.address2ValidationMessage =
        this.fieldData.registeredAddressLine2ValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.townValidationMessage =
        this.fieldData.registeredAddressTownValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.cityValidationMessage =
        this.fieldData.registeredAddressCityStateValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.usStateValidationMessage =
        this.fieldData.registeredAddressUsStateValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.countryValidationMessage =
        this.fieldData.registeredAddressCountryValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.zipValidationMessage =
        this.fieldData.registeredAddressPostalZipCodeValidationMessage[
          this.transalationLanguage
        ];

      this.registeredForm.freeFormValidationMessage =
        this.fieldData.registrationFormFreeFormValidationMessage[
          this.transalationLanguage
        ];
    },

    tabTradingAddressValidationMessageClick(language) {
      this.transalationLanguage = language.toLowerCase();

      // trading form Validation Message

      this.tradingForm.address1ValidationMessage =
        this.fieldData.tradingAddressAddressLine1ValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.address2ValidationMessage =
        this.fieldData.tradingAddressAddressLine2ValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.townValidationMessage =
        this.fieldData.tradingAddressAddressTownValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.cityValidationMessage =
        this.fieldData.tradingAddressAddressCityStateValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.usStateValidationMessage =
        this.fieldData.tradingAddressUsStateValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.countryValidationMessage =
        this.fieldData.tradingAddressAddressCountryValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.zipValidationMessage =
        this.fieldData.tradingAddressAddressPostalZipCodeValidationMessage[
          this.transalationLanguage
        ];

      this.tradingForm.freeFormValidationMessage =
        this.fieldData.tradingFormFreeFormValidationMessage[
          this.transalationLanguage
        ];
    },

    addLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();

      this.fieldData.label[tempKey] = this.label;

      this.fieldData.checkBoxLabel[tempKey] = this.checkBoxLabel;
    },

    addRegisteredFormLabelLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // Registered Address Label
      // Form label or Description
      this.fieldData.registeredLabel[tempKey] = this.registeredLabel;

      this.fieldData.registeredDescription[tempKey] =
        this.registeredDescription;

      this.fieldData.registeredAddressLine1Label[tempKey] =
        this.registeredForm.address1Title;
      this.fieldData.registeredAddressLine2Label[tempKey] =
        this.registeredForm.address2Title;
      this.fieldData.registeredAddressTownLabel[tempKey] =
        this.registeredForm.townTitle;
      this.fieldData.registeredAddressCityStateLabel[tempKey] =
        this.registeredForm.cityTitle;
      this.fieldData.registeredAddressCountryLabel[tempKey] =
        this.registeredForm.countryTitle;
      this.fieldData.registeredAddressPostalZipCodeLabel[tempKey] =
        this.registeredForm.zipTitle;
      this.fieldData.registrationFormFreeFormLabel[tempKey] =
        this.registeredForm.freeFormTitle;
    },

    addTradingFormLabelTextLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // trading Forms Label
      // Form label or Description
      this.fieldData.tradingLabel[tempKey] = this.tradingLabel;
      this.fieldData.tradingDescription[tempKey] = this.tradingDescription;

      this.fieldData.tradingAddressLine1Label[tempKey] =
        this.tradingForm.address1Title;
      this.fieldData.tradingAddressLine2Label[tempKey] =
        this.tradingForm.address2Title;
      this.fieldData.tradingAddressTownLabel[tempKey] =
        this.tradingForm.townTitle;
      this.fieldData.tradingAddressCityStateLabel[tempKey] =
        this.tradingForm.cityTitle;
      this.fieldData.tradingAddressCountryLabel[tempKey] =
        this.tradingForm.countryTitle;
      this.fieldData.tradingAddressPostalZipCodeLabel[tempKey] =
        this.tradingForm.zipTitle;
      this.fieldData.tradingFormFreeFormLabel[tempKey] =
        this.tradingForm.freeFormTitle;
    },

    addRegisteredFormHelperTextLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // Registered Address Helper Text

      this.fieldData.registeredAddressLine1HelperText[tempKey] =
        this.registeredForm.address1HelperText;
      this.fieldData.registeredAddressLine2HelperText[tempKey] =
        this.registeredForm.address2HelperText;
      this.fieldData.registeredAddressTownHelperText[tempKey] =
        this.registeredForm.townHelperText;
      this.fieldData.registeredAddressCityStateHelperText[tempKey] =
        this.registeredForm.cityHelperText;
      this.fieldData.registeredAddressUsStateHelperText[tempKey] =
        this.registeredForm.usStateHelperText;
      this.fieldData.registeredAddressCountryHelperText[tempKey] =
        this.registeredForm.countryHelperText;
      this.fieldData.registeredAddressPostalZipCodeHelperText[tempKey] =
        this.registeredForm.zipHelperText;
      this.fieldData.registrationFormFreeFormHelperText[tempKey] =
        this.registeredForm.freeFormHelperText;
    },

    addTradingFormHelperTextLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // trading Forms Helper Text

      this.fieldData.tradingAddressAddressLine1HelperText[tempKey] =
        this.tradingForm.address1HelperText;
      this.fieldData.tradingAddressAddressLine2HelperText[tempKey] =
        this.tradingForm.address2HelperText;
      this.fieldData.tradingAddressAddressTownHelperText[tempKey] =
        this.tradingForm.townHelperText;
      this.fieldData.tradingAddressAddressCityStateHelperText[tempKey] =
        this.tradingForm.cityHelperText;
      this.fieldData.tradingAddressUsStateHelperText[tempKey] =
        this.tradingForm.usStateHelperText;
      this.fieldData.tradingAddressAddressCountryHelperText[tempKey] =
        this.tradingForm.countryHelperText;
      this.fieldData.tradingAddressAddressPostalZipCodeHelperText[tempKey] =
        this.tradingForm.zipHelperText;
      this.fieldData.tradingFormFreeFormHelperText[tempKey] =
        this.tradingForm.freeFormHelperText;
    },

    addRegisteredFormPlaceHolderTextLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // Registered Form Place Holder Text

      this.fieldData.registeredAddressLine1Placeholder[tempKey] =
        this.registeredForm.address1PlaceHolderText;
      this.fieldData.registeredAddressLine2Placeholder[tempKey] =
        this.registeredForm.address2PlaceHolderText;
      this.fieldData.registeredAddressTownPlaceholder[tempKey] =
        this.registeredForm.townPlaceHolderText;
      this.fieldData.registeredAddressCityStatePlaceholder[tempKey] =
        this.registeredForm.cityPlaceHolderText;
      this.fieldData.registeredAddressPostalZipCodePlaceholder[tempKey] =
        this.registeredForm.zipPlaceHolderText;
      this.fieldData.registrationFormFreeFormPlaceholderText[tempKey] =
        this.registeredForm.freeFormPlaceHolderText;
    },

    addTradingFormPlaceHolderLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // trading Forms Place Holder Text

      this.fieldData.tradingAddressLine1Placeholder[tempKey] =
        this.tradingForm.address1PlaceHolderText;
      this.fieldData.tradingAddressLine2Placeholder[tempKey] =
        this.tradingForm.address2PlaceHolderText;
      this.fieldData.tradingAddressTownPlaceholder[tempKey] =
        this.tradingForm.townPlaceHolderText;
      this.fieldData.tradingAddressCityStatePlaceholder[tempKey] =
        this.tradingForm.cityPlaceHolderText;
      this.fieldData.tradingAddressPostalZipCodePlaceholder[tempKey] =
        this.tradingForm.zipPlaceHolderText;
      this.fieldData.tradingFormFreeFormPlaceholderText[tempKey] =
        this.tradingForm.freeFormPlaceHolderText;
    },

    addRegisteredFormValidationMessageLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // Registered Address Validation Message

      this.fieldData.registeredAddressLine1ValidationMessage[tempKey] =
        this.registeredForm.address1ValidationMessage;
      this.fieldData.registeredAddressLine2ValidationMessage[tempKey] =
        this.registeredForm.address2ValidationMessage;
      this.fieldData.registeredAddressTownValidationMessage[tempKey] =
        this.registeredForm.townValidationMessage;
      this.fieldData.registeredAddressCityStateValidationMessage[tempKey] =
        this.registeredForm.cityValidationMessage;
      this.fieldData.registeredAddressUsStateValidationMessage[tempKey] =
        this.registeredForm.usStateValidationMessage;
      this.fieldData.registeredAddressCountryValidationMessage[tempKey] =
        this.registeredForm.countryValidationMessage;
      this.fieldData.registeredAddressPostalZipCodeValidationMessage[tempKey] =
        this.registeredForm.zipValidationMessage;
      this.fieldData.registrationFormFreeFormValidationMessage[tempKey] =
        this.registeredForm.freeFormValidationMessage;
    },

    addTradingFormValidationMessageLanguageToField(language) {
      let tempKey = language.toLowerCase();

      // trading Forms Validation Message

      this.fieldData.tradingAddressAddressLine1ValidationMessage[tempKey] =
        this.tradingForm.address1ValidationMessage;
      this.fieldData.tradingAddressAddressLine2ValidationMessage[tempKey] =
        this.tradingForm.address2ValidationMessage;
      this.fieldData.tradingAddressAddressTownValidationMessage[tempKey] =
        this.tradingForm.townValidationMessage;
      this.fieldData.tradingAddressAddressCityStateValidationMessage[tempKey] =
        this.tradingForm.cityValidationMessage;
      this.fieldData.tradingAddressUsStateValidationMessage[tempKey] =
        this.tradingForm.usStateValidationMessage;
      this.fieldData.tradingAddressAddressCountryValidationMessage[tempKey] =
        this.tradingForm.countryValidationMessage;
      this.fieldData.tradingAddressAddressPostalZipCodeValidationMessage[
        tempKey
      ] = this.tradingForm.zipValidationMessage;
      this.fieldData.tradingFormFreeFormValidationMessage[tempKey] =
        this.tradingForm.freeFormValidationMessage;
    },

    setValidation() {
      let validation = {
        isRequireRegistrationFormCountry: this.isRequireRegistrationFormCountry,
        isRequireRegistrationFormCity: this.isRequireRegistrationFormCity,
        isRequireRegistrationFormUsState: this.isRequireRegistrationFormUsState,
        isRequireRegistrationFormAddress1:
          this.isRequireRegistrationFormAddress1,
        isRequireRegistrationFormAddress2:
          this.isRequireRegistrationFormAddress2,
        isRequireRegistrationFormTown: this.isRequireRegistrationFormTown,
        isRequireRegistrationFormZip: this.isRequireRegistrationFormZip,
        isRequireRegistrationFreeForm: this.isRequireRegistrationFreeForm,

        isRequireTradingFormCountry: this.isRequireTradingFormCountry,
        isRequireTradingFormCity: this.isRequireTradingFormCity,
        isRequireTradingFormUsState: this.isRequireTradingFormUsState,
        isRequireTradingFormAddress1: this.isRequireTradingFormAddress1,
        isRequireTradingFormAddress2: this.isRequireTradingFormAddress2,
        isRequireTradingFormTown: this.isRequireTradingFormTown,
        isRequireTradingFormZip: this.isRequireTradingFormZip,
        isRequireTradingFreeForm: this.isRequireTradingFreeForm
      };
      Object.assign(this.fieldData, { validation });
    },

    updateAdvancedSettings() {
      let advance = {
        registeredFormCountryDisabled: this.registeredFormCountryDisabled,
        registeredFormCityDisabled: this.registeredFormCityDisabled,
        registeredFormAddress1Disabled: this.registeredFormAddress1Disabled,
        registeredFormAddress2Disabled: this.registeredFormAddress2Disabled,
        registeredFormTownDisabled: this.registeredFormTownDisabled,
        registeredFormZipDisabled: this.registeredFormZipDisabled,
        registrationFormFreeFormDisabled: this.registrationFormFreeFormDisabled,

        // Trading Forms Disable

        tradingFormCountryDisabled: this.tradingFormCountryDisabled,
        tradingFormCityDisabled: this.tradingFormCityDisabled,
        tradingFormAddress1Disabled: this.tradingFormAddress1Disabled,
        tradingFormAddress2Disabled: this.tradingFormAddress2Disabled,
        tradingFormTownDisabled: this.tradingFormTownDisabled,
        tradingFormZipDisabled: this.tradingFormZipDisabled,
        tradingFormFreeFormDisabled: this.tradingFormFreeFormDisabled
      };
      Object.assign(this.fieldData, { advance });
    },

    setAttributes() {
      let attributes = {
        countryPostName: this.registeredForm.countryPostName,
        cityPostName: this.registeredForm.cityPostName,
        usStatePostName: this.registeredForm.usStatePostName,
        address1PostName: this.registeredForm.address1PostName,
        address2PostName: this.registeredForm.address2PostName,
        townPostName: this.registeredForm.townPostName,
        zipPostName: this.registeredForm.zipPostName,
        freeFormPostName: this.registeredForm.freeFormPostName
      };
      Object.assign(this.fieldData, { attributes });
    },

    // for conditions
    onRemoveConditionOpt(optId, index) {
      if (optId && index !== -1) {
        this.fieldData.conditions.splice(index, 1);
      }
    },

    getCurrentSectionList() {
      let conditionalSectionElements =
        this.form.formSection[this.sectionIndex].formElements;
      conditionalSectionElements = conditionalSectionElements.filter(
        (element) => {
          if (
            element.inputType === "radio" ||
            element.inputType === "checkbox" ||
            element.inputType === "select"
          ) {
            // return element
            let dd = {
              label: element.label[this.transalationLanguage],
              element
            };
            return this.conditionalSectionElementLables.push(dd);
          }
        }
      );
      this.conditionalSectionElements = conditionalSectionElements;
    },

    getSelectedElement(selectedElement) {
      this.selectedElementOption = [];
      this.conditionalFieldValue = null;
      selectedElement.element.options.map((opt) => {
        this.selectedElementOption.push(opt.value);
      });
    },

    setFieldConditions() {
      //
      if (this.conditionalField && this.conditionalFieldValue) {
        let condition = {
          id: uid(),
          sourceFieldId: this.conditionalField.element.id,
          targetFieldId: this.fieldData.id,
          value: this.conditionalFieldValue,
          label: this.conditionalField.label
        };
        let elements = [
          ...this.form.formSection[this.sectionIndex].formElements
        ];
        elements.map((element) => {
          if (element.options) {
            element.options.map((opt) => {
              if (
                opt.value === this.conditionalFieldValue &&
                this.conditionalField.element.id === element.id
              ) {
                element.conditionalFields.push(condition);
              }
            });
          }
        });
        // this.fieldData.isConditional = this.isConditional;
        this.fieldData.isHidden = true;
        // Object.assign(this.fieldData, { condition });
        this.fieldData.conditions.push(condition);
        this.conditionalFieldValue = null;
        this.conditionalField = null;
        Helper.makeToast(this.$bvToast, "success", "Conditional  Updated");
      } else {
        Helper.makeToast(this.$bvToast, "danger", "No Condition selected");
      }
    }
  }
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
