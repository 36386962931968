<template>
  <div
    :class="[
      'rating-custom-attribute',
      `rating-custom-attribute--text-align-${textAlign}`
    ]"
    data-test-id="rating-custom-attribute"
  >
    <b-img
      v-if="isImageSubtype"
      :src="loadImage"
      v-b-tooltip.hover.bottom
      :title="imageTooltip"
      class="rating-custom-attribute__image"
      data-test-id="rating-custom-attribute__image"
    />
    <span
      v-else
      class="rating-custom-attribute__numeric"
      data-test-id="rating-custom-attribute__numeric"
      :style="getStyle"
    >
      {{ value }}
    </span>
  </div>
</template>

<script>
import { colors, ratingTypes, numericScale, textAlign } from "@/constants";

export default {
  name: "RatingCustomAttribute",
  props: {
    value: {
      type: String,
      required: true
    },
    textAlign: {
      type: String,
      default: textAlign.CENTER
    }
  },
  computed: {
    isImageSubtype() {
      return isNaN(parseInt(this.value));
    },
    imageTooltip() {
      return this.value || "Not set";
    },
    loadImage() {
      if (this.value === ratingTypes.LOW) {
        return require(`@/assets/OK.png`);
      } else if (this.value === ratingTypes.MODERATE) {
        return require(`@/assets/WarningAlert.png`);
      } else if (this.value === ratingTypes.HIGH) {
        return require(`@/assets/HighAlert.png`);
      } else if (this.value === ratingTypes.DO_NOT_ENGAGE) {
        return require(`@/assets/doNotEngage.png`);
      } else {
        return require(`@/assets/notavailable64x64.png`);
      }
    },
    getStyle() {
      if (parseInt(this.value) === numericScale.NUMERIC_VALUE_0) {
        return `color:${colors.spanishGray};`;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_7) {
        return `color:${colors.appleGreen};`;
      } else if (parseInt(this.value) < numericScale.NUMERIC_VALUE_9) {
        return `background-color:${colors.metallicYellow};`;
      } else {
        return `background-color:${colors.spanishRed};color:${colors.white};`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rating-custom-attribute {
  margin-right: 10px;

  &__image {
    width: 35px;
    height: 35px;
  }
  &__numeric {
    border: 1px solid $spanish-gray;
    display: inline-block;
    border-radius: 100%;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    min-width: 35px;
    padding: 0;
  }

  &--text-align-center {
    text-align: center;
  }

  &--text-align-left {
    text-align: left;
  }

  &--text-align-right {
    text-align: right;
  }
}
</style>
