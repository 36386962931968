var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2',attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"label":_vm.label,"isHidden":_vm.isHidden,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('b-collapse',{directives:[{name:"show",rawName:"v-show",value:(JSON.parse(!_vm.fieldData.isHidden) || !_vm.isPreview),expression:"JSON.parse(!fieldData.isHidden) || !isPreview"}],class:_vm.isPreview ? 'mt-1' : 'mt-5',attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.fieldData.isConditional == '1' && !_vm.isPreview)?_c('b-button',{attrs:{"variant":"outline-dark","disabled":"","title":"This field is hidden by default and will display bt is controlled element"}},[_c('font-awesome-icon',{attrs:{"icon":"eye-slash"}})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"id":_vm.fieldData.id,"description":_vm.fieldData.helperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.validationMessage[_vm.selectedLanguage]}},[_c('label',[(
                _vm.fieldData.validation &&
                JSON.parse(_vm.fieldData.validation.required)
              )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.form1.enableSectionFieldEnumeration == 1 ? _vm.sectionIndex + 1 + "." + (_vm.fieldIndex + 1) : "")+" "+_vm._s(_vm.label ? _vm.label : _vm.fieldData.label[_vm.selectedLanguage])+" ")]),_c('b-form-checkbox-group',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"stacked":_vm.fieldData.configuration.isStacked,"id":_vm.fieldData.id,"options":_vm.renderOptions,"disabled":_vm.isValidateLength || _vm.isDisabled == 1,"disabled-field":"disabled"},on:{"input":_vm.checkCondition},model:{value:(_vm.selectedChekboxes),callback:function ($$v) {_vm.selectedChekboxes=$$v},expression:"selectedChekboxes"}}),(
              _vm.fieldData.validation &&
              _vm.fieldData.validation.required == 1 &&
              !_vm.selectedChekboxes.length &&
              _vm.isFocus
            )?_c('label',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fieldData.validationMessage[_vm.selectedLanguage]))]):_vm._e(),_c('div',[(_vm.showMinAlert)?_c('label',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("questionnaires.selectAtLeast"))+" "+_vm._s(_vm.fieldData.validation.minSelect)+" "+_vm._s(_vm.$t("questionnaires.options")))]):_vm._e(),(_vm.showMaxAlert)?_c('label',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("questionnaires.options"))+" "+_vm._s(_vm.fieldData.validation.maxSelect)+" "+_vm._s(_vm.$t("questionnaires.options"))+" only.")]):_vm._e()])],1),_c('b-collapse',{staticClass:"mt-2",model:{value:(_vm.showCollapseField),callback:function ($$v) {_vm.showCollapseField=$$v},expression:"showCollapseField"}},[_c('b-form-group',{class:_vm.$store.state.rightAlign ? 'text-right ml-3 ' : 'text-left ml-3',attrs:{"description":_vm.fieldData.conditionalFieldHelperText[_vm.selectedLanguage],"invalid-feedback":_vm.$t('createProfile.requiredValidationMessage')}},[(
                _vm.fieldData.validation && _vm.fieldData.validation.required === 1
              )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fieldData.conditionalFieldLabel[_vm.selectedLanguage])+" "),_c('b-form-textarea',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"placeholder":_vm.fieldData.conditionalFieldPlaceHolderText[_vm.selectedLanguage],"rows":_vm.fieldData.conditionalFieldMinRows || 3,"max-rows":_vm.fieldData.conditionalFieldMaxRows || 6,"state":_vm.fieldData.validation.required == 0
                  ? null
                  : _vm.isFocus &&
                    _vm.conditionalFieldInputValue &&
                    _vm.isMatchCondition &&
                    _vm.fieldData.validation.required == 1
                  ? true
                  : false},on:{"input":_vm.onInputType,"focus":function($event){_vm.isFocus = true}},model:{value:(_vm.conditionalFieldInputValue),callback:function ($$v) {_vm.conditionalFieldInputValue=$$v},expression:"conditionalFieldInputValue"}})],1)],1)],1)],1),_c('b-modal',{attrs:{"cancel-title":"Close","ok-title":"save","size":"xl","title":"Checkbox Settings"},on:{"ok":_vm.onSaveData,"hidden":_vm.onUpdateSettings},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[(_vm.showSettings)?_c('AdvanceCheckboxSettings',{attrs:{"fieldData":_vm.fieldData,"sectionIndex":_vm.sectionIndex,"isPreview":_vm.isPreview,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }