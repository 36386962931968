<template>
  <div class="accordion-section">
    <header
      class="accordion-section__header"
      data-test-id="accordion-section__header"
    >
      <div
        class="accordion-section__header-left"
        data-test-id="accordion-section__header-left"
      >
        <div
          v-if="isDraggable"
          class="accordion-section__drag"
          data-test-id="accordion-section__drag"
        >
          <BaseIcon icon="grip-vertical" :size="20" :theme="themes.GREY" />
        </div>
        <h5
          class="accordion-section__title"
          data-test-id="accordion-section__title"
        >
          {{ title }}
        </h5>
      </div>
      <div
        class="accordion-section__header-right"
        data-test-id="accordion-section__header-right"
      >
        <CallToAction
          v-if="isCollapsable"
          class="accordion-section__collapse"
          data-test-id="accordion-section__collapse"
          :theme="themes.GREY"
          :icon="getIcon"
          @click="handleCollapse"
        />
        <DropDownMenu
          v-if="menuItems.length"
          class="accordion-section__menu"
          data-test-id="accordion-section__menu"
          :drop-down-list="menuItems"
          @click="$emit('click', $event)"
        />
      </div>
    </header>
    <div v-if="showStaticText" class="accordion-section__body">
      <div class="accordion-section__body-spacer"></div>
      <BaseText
        data-test-id="accordion-section__comment--read-only"
        :text="comment"
        :size="typographySize.BODY_TEXT_SMALL"
      />
    </div>
    <TransitionExpand>
      <div
        v-if="!isAccordionClosed"
        class="accordion-section__body"
        data-test-id="accordion-section__body"
      >
        <div class="accordion-section__body-spacer"></div>
        <div class="accordion-section__comment" v-if="showCommentBox">
          <BaseTextArea
            :value="comment"
            class="accordion-section__comment-input"
            data-test-id="accordion-section__comment-input"
            :id="`${makeUUID()}`"
            placeholder="Add a comment"
            @input="$emit('input', $event)"
          />
          <CallToAction
            class="accordion-section__comment-delete"
            data-test-id="accordion-section__comment-delete"
            icon="trash-alt"
            :size="20"
            :theme="themes.GREY"
            @click="$emit('click', stepAccordionOptionValue.REMOVE_COMMENT)"
          />
        </div>
        <slot></slot>
        <div class="accordion-section__body-spacer"></div>
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import BaseTextArea from "@/atoms/BaseTextArea/BaseTextArea";
import DropDownMenu from "@/molecules/DropDownList/DropDownList";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import TransitionExpand from "@/atoms/TransitionExpand/TransitionExpand";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  icons,
  stepAccordionOptionValue,
  themes,
  typographySize
} from "@/constants";
import { makeUUID } from "@/utils";

export default {
  name: "AccordionSection",
  components: {
    TransitionExpand,
    CallToAction,
    BaseIcon,
    DropDownMenu,
    BaseTextArea,
    BaseText
  },
  data() {
    return {
      isAccordionClosed: this.isCollapsed,
      themes,
      typographySize,
      stepAccordionOptionValue
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    isCollapsable: {
      type: Boolean,
      default: false
    },
    menuItems: {
      type: Array,
      default: () => []
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    showCommentBox: {
      type: Boolean,
      default: false
    },
    comment: {
      type: String,
      default: ""
    }
  },
  watch: {
    isCollapsed(newValue, oldValue) {
      if (this.isCollapsable) {
        if (newValue !== oldValue) {
          this.isAccordionClosed = newValue;
        }
      }
    }
  },
  computed: {
    showStaticText() {
      return !!(this.isAccordionClosed && this.showCommentBox && this.comment);
    },
    getIcon() {
      return this.isAccordionClosed ? icons.CARET_UP : icons.CARET_DOWN;
    }
  },
  methods: {
    makeUUID,
    handleCollapse() {
      if (this.isCollapsable) {
        this.isAccordionClosed = !this.isAccordionClosed;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-section {
  border: 1px solid $chinese-silver;
  border-radius: 5px;

  &__header {
    display: flex;
    justify-content: space-between;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: 1px solid $chinese-silver;
    padding: 10px 15px;

    &-right,
    &-left {
      display: flex;
      align-items: center;

      &:deep(.drop-down-list) {
        display: inline-flex;
      }
    }
  }

  &__body {
    padding: 0 15px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: height 0.5s ease-out;

    &-spacer {
      height: 15px;
    }
  }

  &__title {
    @include body-text-bold;
    line-height: 1;
    margin: 0;
  }

  &__drag {
    cursor: move;
    margin-right: 10px;
  }

  &__menu {
    margin-left: 10px;
  }

  &__comment {
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid $chinese-silver;
    padding-bottom: 9px;
    margin-bottom: 15px;

    &-input {
      width: 100%;
      margin-right: 30px;
    }
  }
}
</style>
