import { EventBus } from "@/event-bus.js";
import HighRiskProducts from "@/utils/highRiskProducts.json";

class Helper {
  hideSection(state, sectionSeven, secFourCountries) {
    let multi = false;
    let sections = state.form1.formSection;
    let selectedSubDivision = null;
    let selectedProducts = [];
    let filterSelectedCountries = [];

    let sectionSix = sections.find(
      (sec) => sec.sectionTitle.english == "Details of Services/Goods"
    );
    let foundCombo = sectionSix.formElements.find(
      (a) => a.inputType == "comboSelectProgressive"
    );

    let eleNewCombo = state.userFormSubmitArray.find(
      (ele) => ele.elementId == foundCombo.id
    );

    if (eleNewCombo && eleNewCombo.elements.length) {
      eleNewCombo.elements.map((el) => {
        if (el.postValue && el.postValue.comboSelectTwo) {
          selectedSubDivision = el.postValue.comboSelectTwo;
          multi = el.isMultipleSelectSecond;
        }
      });
    }

    // filtering countries
    if (selectedSubDivision) {
      selectedSubDivision.map((subDivision) => {
        let divisionName = null;
        if (subDivision.text == undefined) {
          divisionName = subDivision.divisionName;
        } else {
          divisionName = subDivision.value.divisionName.english;
        }
        let foundDivision =
          multi && HighRiskProducts.industries
            ? HighRiskProducts.industries.find(
                (division) => division.divisionName == divisionName
              )
            : null;
        if (foundDivision) {
          let subDivisionName = null;
          if (subDivision.text == undefined) {
            subDivisionName = subDivision.subDivisionName;
          } else {
            subDivisionName = subDivision.value.subDivisionName.english;
          }
          let foundSubDivision =
            multi && foundDivision.subDivisions
              ? foundDivision.subDivisions.find(
                  (subDivisionObject) =>
                    subDivisionObject.subDivisionName == subDivisionName
                )
              : null;
          if (foundSubDivision) {
            secFourCountries.map((selectedCountry) => {
              let foundSubDivisionCountry =
                multi && foundSubDivision.counties
                  ? foundSubDivision.counties.find(
                      (subDivisionCountries) =>
                        subDivisionCountries.country == selectedCountry
                    )
                  : null;
              if (foundSubDivisionCountry) {
                let findIndexCountry = filterSelectedCountries.indexOf(
                  foundSubDivisionCountry.country
                );
                if (findIndexCountry == -1) {
                  filterSelectedCountries.push(foundSubDivisionCountry.country);
                }
                foundSubDivisionCountry.products.map((product) => {
                  selectedProducts.push(product);
                });
              }
            });
          }
        }
      });
    }

    if (selectedProducts.length) {
      Object.assign(sectionSeven, (sectionSeven.isHidden = false));
      EventBus.$emit("sectionCheck", sectionSeven);
      EventBus.$emit("getRefreshProducts", selectedProducts);
      EventBus.$emit("getRefreshCountries", filterSelectedCountries);
    } else {
      Object.assign(sectionSeven, (sectionSeven.isHidden = true));
      EventBus.$emit("sectionCheck", sectionSeven);
      sectionSeven.formElements.map((ele) => {
        ele.validation.required = 0;
        ele.isInValid = false;
        let foundEle = state.userFormSubmitArray.find(
          (ele1) => ele1.elementId == ele.id
        );
        if (foundEle) {
          foundEle.elements = [];
        }
      });
    }
    return selectedProducts;
  }
}
export default new Helper();
