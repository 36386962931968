var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-tab"},[_c('div',{staticClass:"d-flex analytics-tab__header"},[_c('div',[_c('b-button',{staticClass:"mt-2 mb-2",attrs:{"disabled":_vm.isPageLoaded,"size":"sm","variant":"outline-dark"},on:{"click":_vm.printPage}},[_c('i',{staticClass:"fa fa-print",attrs:{"aria-hidden":"true"}})])],1)]),_c('div',{staticClass:"analytics-tab__content",attrs:{"id":"analytics_overview"}},[_c('b-overlay',{attrs:{"show":_vm.savedThirdPartiesLoading,"rounded":"sm"}},[_c('b-row',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.savedThirdPartiesApiCall,
          intersection: {
            threshold: 0.5
          }
        }),expression:"{\n          callback: savedThirdPartiesApiCall,\n          intersection: {\n            threshold: 0.5\n          }\n        }"}],staticClass:"analytics-tab__cards-wrapper"},[_c('TotalTPComponent',{staticClass:"analytics-tab__cards-wrapper-item",attrs:{"totalNumberOfThirdParties":_vm.totalNumberOfThirdParties}}),_c('TpOverTimeComponent',{staticClass:"analytics-tab__cards-wrapper-item",attrs:{"savedThirdPartyChartsOptions":_vm.savedThirdPartyChartsOptions}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.html",value:(
            _vm.headerConfigData && _vm.headerConfigData.autoremiddplusmoni == 1
              ? ''
              : _vm.$store.state.disabledButtonsToolTipMsg
          ),expression:"\n            headerConfigData && headerConfigData.autoremiddplusmoni == 1\n              ? ''\n              : $store.state.disabledButtonsToolTipMsg\n          ",modifiers:{"hover":true,"bottom":true,"html":true}}],staticClass:"analytics-tab__cards-wrapper-item"},[_c('OngoingMonitoringResults',{attrs:{"headerConfigData":_vm.headerConfigData,"totalNumberOfMonitoringAlertsAllTime":_vm.totalNumberOfMonitoringAlertsAllTime,"totalNumberOfMonitoringAlertsLastMonth":_vm.totalNumberOfMonitoringAlertsLastMonth,"totalNumberOfMonitoringAlertsThisMonth":_vm.totalNumberOfMonitoringAlertsThisMonth,"totalNumberOfThirdPartiesAlertsAllTime":_vm.totalNumberOfThirdPartiesAlertsAllTime,"totalNumberOfThirdPartiesAlertsLastMonth":_vm.totalNumberOfThirdPartiesAlertsLastMonth,"totalNumberOfThirdPartiesAlertsThisMonth":_vm.totalNumberOfThirdPartiesAlertsThisMonth}})],1)],1)],1),_c('b-overlay',{staticClass:"analytics-tab__content-map-chart",attrs:{"show":_vm.mapChartLoading,"rounded":"sm"}},[_c('b-row',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.mapChartApiCall,
          intersection: {
            threshold: 0.5
          }
        }),expression:"{\n          callback: mapChartApiCall,\n          intersection: {\n            threshold: 0.5\n          }\n        }"}],staticClass:"analytics-tab__cards-wrapper analytics-tab__content-map-chart"},[_c('div',{staticClass:"analytics-tab__cards-wrapper-item"},[_c('TpOverCountriesComponent',{attrs:{"mapChartOptions":_vm.mapChartOptions}})],1)])],1),_c('b-overlay',{attrs:{"show":_vm.priActiveSanctionsChartLoading,"rounded":"sm"}},[_c('b-row',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.priActiveSanctionsChartApiCall,
          intersection: {
            threshold: 0.1
          }
        }),expression:"{\n          callback: priActiveSanctionsChartApiCall,\n          intersection: {\n            threshold: 0.1\n          }\n        }"}],staticClass:"analytics-tab__cards-wrapper"},[_c('div',{staticClass:"analytics-tab__cards-wrapper-item"},[_c('PriResultsComponent',{attrs:{"priChartOptions":_vm.priChartOptions}})],1),_c('div',{staticClass:"analytics-tab__cards-wrapper-item"},[_c('IddResultsComponent',{attrs:{"SanctionsAlertsCountChartOptions":_vm.SanctionsAlertsCountChartOptions}})],1),_c('div',{staticClass:"analytics-tab__cards-wrapper-item"},[_c('IddPlusPreRemediationResultsComponent',{attrs:{"iddPlusPreRemediationCountChartOptions":_vm.iddPlusPreRemediationCountChartOptions}})],1)])],1),_c('b-overlay',{attrs:{"show":_vm.iddPlusPostRemediationEddResultCountTpCountriesChartLoading,"rounded":"sm"}},[_c('b-row',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback:
            _vm.iddPlusPostRemediationEddResultCountTpCountriesChartApiCall,
          intersection: {
            threshold: 0.1
          }
        }),expression:"{\n          callback:\n            iddPlusPostRemediationEddResultCountTpCountriesChartApiCall,\n          intersection: {\n            threshold: 0.1\n          }\n        }"}],staticClass:"analytics-tab__cards-wrapper"},[_c('div',{staticClass:"analytics-tab__cards-wrapper-item"},[_c('IddPlusPostRemediationResultsComponent',{attrs:{"iddPlusPostRemediationCountChartOptions":_vm.iddPlusPostRemediationCountChartOptions}})],1),_c('div',{staticClass:"analytics-tab__cards-wrapper-item"},[_c('EddResultsComponent',{attrs:{"eddResultCountChartOptions":_vm.eddResultCountChartOptions}})],1),_c('div',{staticClass:"analytics-tab__cards-wrapper-item"},[_c('TpOverCountriesResultsComponent',{attrs:{"tpCountriesChartOptions":_vm.tpCountriesChartOptions}})],1)])],1),_c('ThirdPartyAnalysisModalTable',{attrs:{"company-id":_vm.companyId,"modal-title":_vm.mapDrillDownOptions.modalTitle,"query-specific-data":_vm.mapDrillDownOptions.querySpecificData,"table-id":_vm.mapDrillDownOptions.tableId,"table-title":_vm.mapDrillDownOptions.tableTitle},on:{"hideModal":_vm.hideModal}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }