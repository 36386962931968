import { customAttributesService } from "@/services";
import { mutationName } from "@/constants";

export const actions = {
  async fetchCustomAttributesList({ commit }, payload) {
    try {
      commit(
        mutationName.CUSTOM_ATTRIBUTES.SET_CUSTOM_ATTRIBUTE_LIST_IS_LOADING,
        true,
        {
          root: true
        }
      );

      const { data = {} } = await customAttributesService.fetchCustomAttributes(
        payload
      );
      const attributeList = data?.response || [];

      commit(
        mutationName.CUSTOM_ATTRIBUTES.SET_CUSTOM_ATTRIBUTE_LIST,
        attributeList,
        {
          root: true
        }
      );

      return data;
    } catch (error) {
      commit(mutationName.CUSTOM_ATTRIBUTES.SET_CUSTOM_ATTRIBUTE_LIST, [], {
        root: true
      });
      return error;
    } finally {
      commit(
        mutationName.CUSTOM_ATTRIBUTES.SET_CUSTOM_ATTRIBUTE_LIST_IS_LOADING,
        false,
        {
          root: true
        }
      );
    }
  }
};
