// TODO: This should be set in the form builder and then ingested alongside the form schema.
// TODO: Colour should be set in FE when displaying the data.

const FORM_SCORING_CONFIGURATION = {
  supplyChainDDQ: {
    riskScoreRatings: [
      {
        maximumScore: 5,
        risk: "Low",
        color: "Green",
        rating: "1"
      },
      {
        maximumScore: 15,
        risk: "Low",
        color: "Green",
        rating: "2"
      },
      {
        maximumScore: 25,
        risk: "Low",
        color: "Green",
        rating: "3"
      },
      {
        maximumScore: 35,
        risk: "Low",
        color: "Green",
        rating: "4"
      },
      {
        maximumScore: 45,
        risk: "Moderate",
        color: "Yellow",
        rating: "5"
      },
      {
        maximumScore: 55,
        risk: "Moderate",
        color: "Yellow",
        rating: "6"
      },
      {
        maximumScore: 65,
        risk: "Moderate",
        color: "Yellow",
        rating: "7"
      },
      {
        maximumScore: 75,
        risk: "High",
        color: "Red",
        rating: "8"
      },
      {
        maximumScore: 85,
        risk: "High",
        color: "Red",
        rating: "9"
      },
      {
        risk: "High",
        color: "Red",
        rating: "10"
      }
    ],
    topics: [
      {
        name: "activity",
        highestPossibleScore: 32,
        weightage: 30,
        riskScoreRatings: [
          {
            color: "Red",
            risk: "High"
          },
          {
            maximumScore: 5,
            color: "Green",
            risk: "Low"
          },
          {
            maximumScore: 15,
            color: "Yellow",
            risk: "Moderate"
          }
        ]
      },
      {
        name: "human_rights",
        highestPossibleScore: 13,
        weightage: 15,
        riskScoreRatings: [
          {
            maximumScore: 4,
            color: "Green",
            risk: "Low"
          },
          {
            maximumScore: 10,
            color: "Yellow",
            risk: "Moderate"
          },
          {
            color: "Red",
            risk: "High"
          }
        ]
      },
      {
        name: "workforce",
        highestPossibleScore: 15,
        weightage: 15,
        riskScoreRatings: [
          {
            maximumScore: 9.9,
            color: "Green",
            risk: "Low"
          },
          {
            color: "Red",
            risk: "High"
          }
        ]
      },
      {
        name: "jurisdiction",
        highestPossibleScore: 13,
        weightage: 30,
        riskScoreRatings: [
          {
            maximumScore: 4,
            color: "Green",
            risk: "Low"
          },
          {
            maximumScore: 12,
            color: "Yellow",
            risk: "Moderate"
          },
          {
            color: "Red",
            risk: "High"
          }
        ]
      },
      {
        name: "policies_and_systems",
        highestPossibleScore: 13,
        weightage: 10,
        riskScoreRatings: [
          {
            maximumScore: 6,
            color: "Green",
            risk: "Low"
          },
          {
            maximumScore: 10,
            color: "Yellow",
            risk: "Moderate"
          },
          {
            color: "Red",
            risk: "High"
          }
        ]
      }
    ]
  }
};

export { FORM_SCORING_CONFIGURATION };
