export const state = () => ({
  platformSSOURL: null,
  disablePrivacyPolicy: null,
  platformLogoFallback: null,
  platformLogo: null,
  platformPrimaryColor: null,
  platformSecondaryColor: null,
  platformTermsAndConditionsLink: null,
  platformPrivacyPolicyLink: null,
  platformBusinessAddress: null,
  platformCopyrightNotice: null,
  platformFaqLink: null,
  platformFavIcon: null,
  platformKnowledgeCentreLink: null,
  platformLegalDisclaimer: null,
  platformMainHeaderLogo: null,
  platformName: null,
  platformRegisteredBusinessName: null,
  platformReleaseNoteLink: null,
  platformRequestSupportLink: null,
  platformSupportEmail: null,
  platformWebUrl: null,
  platformUserList: [],
  isLoadingPlatformSettings: true,
  productVersions: {}
});
