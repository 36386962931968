<template>
  <div
    v-observe-visibility="{
      callback: callDdqApi,
      intersection: {
        threshold: 0.1
      }
    }"
    class="standard-questions"
  >
    <b-overlay :show="loading" rounded="sm">
      <b-card>
        <b-card-title class="text-center">
          <div v-if="isCountriesQuestionDataType" class="text-right">
            <b-dropdown
              size="sm"
              variant="outline-info"
              class="text-capitalize"
            >
              <template v-slot:button-content>
                <font-awesome-icon icon="cogs" />
              </template>
              <b-dropdown-item
                data-test-id="standard-questions__bar"
                @click="handleChartSelect(chartsStandardQuestionChartType.BAR)"
              >
                Bar
              </b-dropdown-item>
              <b-dropdown-item
                data-test-id="standard-questions__map"
                @click="handleChartSelect(chartsStandardQuestionChartType.MAP)"
              >
                Map
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <span>
            {{ this.data.ddqTitle }}
          </span>
        </b-card-title>
        <highcharts
          v-show="chartType === chartsStandardQuestionChartType.MAP"
          :options="worldMapCountryChart"
          constructorType="mapChart"
        />
        <highcharts
          v-show="chartType === chartsStandardQuestionChartType.BAR"
          :options="barChartOptions"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  HTTP,
  makeAuthorizationHeader,
  makeMapChartOptions,
  makeChartCountryObj
} from "@/utils";
import {
  queryObjectKeys,
  chartsStandardQuestionDataType,
  chartsStandardQuestionChartType,
  chartTypes
} from "@/constants";

export default {
  name: "StandardQuestionsComponent",
  props: {
    questionId: {
      required: true,
      type: String
    },
    companyId: {
      required: true,
      type: Number
    },
    formId: {
      required: true,
      type: Number
    },
    itemsKey: {
      required: true,
      type: String
    },
    itemsIndex: {
      required: true,
      type: Number
    },
    selectedWidgets: {
      required: true,
      type: Array
    },
    chartType: {
      type: String,
      default: chartsStandardQuestionChartType.BAR
    }
  },
  data() {
    return {
      data: {},
      loading: false,
      loaded: [],
      chartsStandardQuestionChartType
    };
  },
  computed: {
    barChartOptions() {
      // This is assigned here so we have access to it in the chart options that
      // are functions, particularly the tooltip formatter
      const component = this;

      return {
        chart: {
          type: "bar",
          zooming: {
            mouseWheel: {
              enabled: false
            }
          }
        },
        title: {
          text: component.chartTitle
        },
        xAxis: {
          categories: component.answerCategories,
          labels: {
            style: {
              color: "#080808"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: "Number of suppliers answered in this way"
          },
          gridLineWidth: 0,
          lineWidth: 1,
          tickInterval: component.tickIntervalSize,
          endOnTick: true
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter() {
            // This is where the of 'this' to 'component' above makes all the difference
            // We need access to the computed values, but the Vue 'this' is out of scope
            // so we assign to 'component' to resolve the scope issue
            return `${component.answerCategoriesByShortened[this.x]}: ${
              this.y
            }`;
          }
        },
        colors: ["#4d9de0"],
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        series: component.seriesData
      };
    },
    worldMapCountryChart() {
      return {
        ...makeMapChartOptions(),
        mapNavigation: {
          enabled: false
        },
        title: {
          text: this.chartTitle
        },
        series: [
          makeChartCountryObj(),
          {
            type: chartTypes.MAP_BUBBLE,
            name: "Third Parties",
            color: "#6BDC99",
            borderColor: "black",
            borderWidth: 0.2,
            joinBy: ["iso-a2", "code"],
            data: this.worldMapChartData,
            minSize: 4,
            maxSize: "12%",
            tooltip: {
              pointFormat: "{point.country}: {point.z}"
            }
          }
        ],
        plotOptions: {
          [chartTypes.MAP_BUBBLE]: {
            cursor: "pointer",
            events: {
              click: ({ point }) => {
                this.handlePointClick(point?.country);
              }
            }
          }
        }
      };
    },
    worldMapChartData() {
      return this.data?.answers?.map((value) => ({
        country: value?.text,
        z: value?.count,
        code: value?.code?.toUpperCase()
      }));
    },
    chartTitle() {
      return this.data?.questionHeading || "";
    },
    isCountriesQuestionDataType() {
      return (
        this.data?.questionDataType === chartsStandardQuestionDataType.COUNTRIES
      );
    },
    seriesData() {
      if (this.data.answers) {
        return [
          {
            name: "Third parties",
            data: this.data.answers.map((answer) => answer.count),
            point: {
              events: {
                click: ({ point }) => {
                  this.handlePointClick(point?.category);
                }
              }
            }
          }
        ];
      }
      return {};
    },
    answerCategories() {
      if (this.data?.answers) {
        return this.data.answers.map((answer) => this.addEllipsis(answer.text));
      }
      return {};
    },
    tickIntervalSize() {
      if (this.data?.answers) {
        let answerMaxCount = Math.max(
          ...this.data.answers.map((answer) => answer.count)
        );
        if (answerMaxCount >= 100) {
          return 50;
        } else if (answerMaxCount >= 50) {
          return 10;
        } else if (answerMaxCount >= 10) {
          return 5;
        } else if (answerMaxCount >= 5) {
          return 3;
        }
        return 1;
      }
      return 1;
    },
    answerCategoriesByShortened() {
      if (this.data.answers) {
        let categories = {};
        for (const answer of this.data.answers) {
          categories[this.addEllipsis(answer.text)] = answer.text;
        }
        return categories;
      }
      return {};
    }
  },
  mounted() {
    this.selectedWidgets.map((items) => {
      if (items.key.includes("standard_question")) {
        this.loaded[items.index] = false;
      }
    });
  },
  methods: {
    handlePointClick(category) {
      const mapDrillDownOptions = {};
      // Longer names are shortened within the "answers" array and we need the full name for the drill down
      const answerIndex = this.data.answers?.findIndex(
        (answer) => this.addEllipsis(answer?.text) === category
      );
      const fullQuestion = this.data.answers[answerIndex]?.text;
      const thirdParties = this.data.answers[answerIndex]?.thirdParties?.map(
        (thirdParty) => thirdParty?.id
      );

      mapDrillDownOptions.modalTitle = this.data.questionHeading;
      mapDrillDownOptions.tableTitle = fullQuestion;
      mapDrillDownOptions.querySpecificData = {
        thirdParties
      };
      mapDrillDownOptions.tableId = queryObjectKeys.THIRD_PARTY_DDQ_QUESTIONS;

      this.$emit("questionClicked", mapDrillDownOptions);
    },
    handleChartSelect(type) {
      this.$emit("chart-type-select", {
        key: this.itemsKey,
        type
      });
    },
    callDdqApi(isVisible) {
      if (isVisible) {
        this.selectedWidgets.map((items) => {
          if (items.key == this.itemsKey) {
            this.getData();
          }
        });
      }
    },
    getData() {
      // If we've already got data, bail out and don't worry about loading anything new
      if (this.loaded[this.itemsIndex]) {
        return false;
      }

      this.loading = true;
      const apiData = {
        companyId: this.companyId,
        formId: this.formId,
        questionId: this.questionId
      };

      HTTP(
        "post",
        "dashboard/standardquestionanswers",
        apiData,
        makeAuthorizationHeader()
      ).then((response) => {
        this.data = response.data;
        this.loading = false;
        this.loaded[this.itemsIndex] = true;
      }); // TODO: decide what to do on API error?
    },
    addEllipsis: (str, length = 25) => {
      const trimmed = str.substring(0, length);
      if (trimmed !== str) {
        return `${trimmed}...`;
      }
      return str;
    }
  }
};
</script>
