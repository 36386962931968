const modalVariants = {
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger"
};

const successModalConfig = {
  variant: modalVariants.SUCCESS,
  autoHideDelay: 2500,
  appendToast: false,
  noCloseButton: true
};

const dangerModalConfig = {
  variant: modalVariants.DANGER,
  autoHideDelay: 2500,
  appendToast: false,
  noCloseButton: true
};

export { dangerModalConfig, successModalConfig, modalVariants };
