<script>
export default {
  name: "TransitionExpand",
  functional: true,
  render(createElement, context) {
    const data = {
      props: {
        name: "expand"
      },
      on: {
        afterEnter(element) {
          element.style.height = "auto";
          element.style.overflow = "visible";
        },
        enter(element) {
          element.style.width = getComputedStyle(element).width;
          element.style.position = "absolute";
          element.style.visibility = "hidden";
          element.style.height = "auto";

          const height = getComputedStyle(element).height;

          element.style.width = null;
          element.style.position = null;
          element.style.visibility = null;
          element.style.height = 0;
          getComputedStyle(element).height;
          requestAnimationFrame(() => {
            element.style.height = height;
          });
          element.style.overflow = "hidden";
        },
        leave(element) {
          element.style.height = getComputedStyle(element).height;
          getComputedStyle(element).height;
          requestAnimationFrame(() => {
            element.style.height = 0;
          });
          element.style.overflow = "hidden";
        }
      }
    };

    return createElement("transition", data, context.children);
  }
};
</script>
