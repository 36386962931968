<template>
   <div>
        <b-card
            style="margin-right: 20px;min-width: 151px;min-height: 151px;"
            class="statsCard text-center"
        >
            <span>
            <b>
                {{$t('eb360.statsTabChart.totalCard')}}
            </b>
            </span>
            <b-card-text class="text-primary text-center">
            <span style="color: #515254; font-weight: bold; font-size: 40px">
                {{ overallValues[0] }}
            </span>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
export default {
  name: "DDQTotalCountCard",
  props:{
      overallValues: Array
  },
}
</script>