<template>
    <div class="idd-plus-post-results">
       <b-card style="border: none">
            <b-card-title>
                <div class="text-right">
                <b-dropdown
                    size="sm"
                    variant="outline-info"
                    class="text-capitalize"
                >
                    <template v-slot:button-content>
                    <font-awesome-icon icon="cogs" />
                    </template>
                    <b-dropdown-item @click="onSelectIddPlusPostRemediationCChart('Pie')">{{$t('eb360.analyticsTabChart.selectChart.pie')}}</b-dropdown-item>
                    <b-dropdown-item @click="onSelectIddPlusPostRemediationCChart('Pyramid')">{{$t('eb360.analyticsTabChart.selectChart.pyramid')}}</b-dropdown-item>
                    <b-dropdown-item @click="onSelectIddPlusPostRemediationCChart('Funnel')">{{$t('eb360.analyticsTabChart.selectChart.funnel')}}</b-dropdown-item>
                </b-dropdown>
                </div>
            </b-card-title>
            <highcharts :options="iddPlusPostRemediationCountChartOptions" />
        </b-card>
    </div>
</template>

<script>
export default {
  name: "IddPlusPostRemediationResultsComponent",
  props:{
      iddPlusPostRemediationCountChartOptions: Object
  },
  methods:{
    // change chart type functions
    onSelectIddPlusPostRemediationCChart ( type ) {
      this.iddPlusPostRemediationCountChartOptions.chart.type = type.toLowerCase();
    },
  }
}
</script>

<style lang="scss" scoped>
.idd-plus-post-results {
  border: $card-border;
}
</style>
