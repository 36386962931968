<template id="template-input">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <!-- input content -->
    <b-collapse
      v-if="!isPreview"
      v-show="JSON.parse(!fieldData.isHidden)"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-col>
        <label class="pointer" for="upload-photo">
          <font-awesome-icon size="2x" icon="camera" />
          <b-form-file
            :disabled="isDisabled == 1"
            @change="handleChange"
            class="uploaded_image_input"
            accept="image/*"
          />
        </label>
        <!--
        <font-awesome-icon
          class="pointer"
          v-if="hasImage"
          size="2x"
          icon="trash"
          @click="hasImage"
        />-->
      </b-col>
      <b-button
        v-if="hasImage"
        class="mt-2"
        variant="primary"
        @click="saveImage"
        >Save</b-button
      >
    </b-collapse>
    <b-img-lazy
      v-if="hasImage || isPreview"
      v-bind="mainProps"
      :src="fieldData.imageData.url || imgUrl"
      alt="Image"
    ></b-img-lazy>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ImageGroup",
  components: {
    MenuButtons
  },
  data() {
    return {
      isHidden: false,
      hasImage: !!(this.fieldData.imageData && this.fieldData.imageData.url),
      imgFile: null,
      imgUrl: null,
      condition: {},
      showToolTip: false,
      label: undefined,
      placeholder: undefined,
      tooltipText: undefined,
      showSettings: false,
      collapse: true,
      mainProps: {
        center: true,
        // fluidGrow: true, original size enable
        blank: true,
        blankColor: "#bbb",
        width: 200,
        height: 200,
        class: "m1"
      }
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  methods: {
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },

    handleChange(info) {
      let imageFile = info.target.files[0];

      if (imageFile.type !== "image/jpeg") {
        alert("You can only upload JPG and PNG file!");
      } else if (imageFile.size > 3769152) {
        alert("Image must smaller than 4MB!");
      } else if (imageFile) {
        this.getBase64(imageFile, (imageUrl) => {
          this.imgFile = imageFile;
          this.hasImage = true;
          this.imgUrl = imageUrl;
        });
      } else {
        alert("Image Error please try later");
      }
    },
    saveImage() {
      this.fieldData.imageData = {
        file: this.imgFile,
        url: this.imgUrl,
        height: 200,
        width: 200
      };
    },
    removeImage() {
      this.imgFile = null;
      this.imgUrl = null;
      this.hasImage = false;
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style scoped>
.uploaded_image_input {
  opacity: 0;
  position: inherit;
}
</style>
