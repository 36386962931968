import { userService } from "@/services";

export default {
  async getUserSettings({ commit }, { companyId, userId }) {
    try {
      const response = await userService.fetchHomePages(companyId, userId);
      commit("setUserHomePage", response?.data?.my_home_page || null);
      commit("setAccountHomePage", response?.data?.account_home_page || null);
      commit("setAllActivePages", response?.data?.allActivePages || []);
    } catch (error) {
      return error;
    }
  }
};
