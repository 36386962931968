<template>
  <div class="round" data-test-id="round">
    <div class="round__decimals" data-test-id="round__decimals">
      <BaseText data-test-id="round__decimals-text" text="Round" />
      <Select
        label="Decimals"
        :id="`round__decimals-select--${id}`"
        :options="decimalOptions"
        data-test-id="round__decimals-select"
        :isRequired="false"
        @change="
          $emit('change', {
            name: roundEvents.ROUND_DECIMAL,
            event: $event,
            id
          })
        "
      />
    </div>
    <CallToAction
      icon="trash-alt"
      :size="20"
      class="round__remove-cta"
      :theme="themes.GREY"
      data-test-id="round__remove-cta"
      @click="$emit('change', { name: roundEvents.REMOVE_ROUND, id })"
    />
  </div>
</template>

<script>
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseText from "@/atoms/BaseText/BaseText";
import Select from "@/molecules/Select/Select";
import { roundEvents, themes } from "@/constants";

export default {
  name: "Round",
  components: {
    CallToAction,
    BaseText,
    Select
  },
  props: {
    decimalOptions: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      roundEvents,
      themes
    };
  }
};
</script>

<style lang="scss" scoped>
.round {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:deep(.label) {
    padding-right: 20px;
  }

  &:deep(.label__label-text) {
    font-weight: 400;
  }
}
</style>
