<!-- eslint-disable vue-a11y/alt-text -->
<template>
  <img v-bind="$attrs" />
</template>

<script>
export default {
  name: "BaseImage"
};
</script>
