import { getters } from "./roles.getters.store";
import { actions } from "./roles.actions.store";
import { mutations } from "./roles.mutations.store";
import { state } from "./roles.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
};
