const toCamel = (string = "") =>
  string?.replace(/([-_\s][a-z])/gi, (firstCharacter) =>
    firstCharacter
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
      .replace(" ", "")
      .replace(" ", "")
  ) || string;

const toSnake = (string = "") => string?.replace(/([_\s])/gi, "-") || string;

const isJsonString = (stringToParse) => {
  try {
    return !!JSON.parse(stringToParse);
  } catch {
    return false;
  }
};

const removeInstancesFromString = (
  item = "",
  values = [],
  caseSensitivity = "gi"
) => {
  const regex = new RegExp(values.join("|"), caseSensitivity);

  return item.replaceAll(regex, "");
};

export { toCamel, toSnake, isJsonString, removeInstancesFromString };
