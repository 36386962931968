<template>
  <div>
    <h2 class="mb-3">{{ $t("dashboardTitle") }}</h2>
    <AppLoader v-if="isLoading" class="text-center" small />
    <b-row class="dashboard-row">
      <b-col cols="12">
        <b-row class="ml-4 mr-4 mt-3"> </b-row>
        <b-row class="ml-2 mr-2 justify-content-md-center">
          <b-col cols="4">
            <h3 class="text-muted">
              {{ $t("questionnaires.title") }} ({{ questionnaires.length }})
            </h3>

            <b-card
              @click="$router.push('/my-questionnaires')"
              bg-variant="default"
              class="mb-2 click-cursor card-wrapper"
              style="height: 400px"
            >
              <b-card-text class="my-5">
                <font-awesome-icon
                  icon="file-signature"
                  size="10x"
                  class="supplier__icon"
                />
              </b-card-text>
              <b-card-text>
                <strong>{{ $t("questionnaires.cardText") }}</strong>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppLoader from "@/components/Loader/Loader";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { Helper } from "@/helpers";
import { getterName } from "@/constants";

export default {
  name: "MyTPPPage",
  components: {
    AppLoader
  },
  data() {
    return {
      accountUpdate: null,
      training: [],
      questionnaires: [],
      exams: [],
      certifications: [],
      summaries: [],
      totalRows: 0,
      filter: null,
      perPage: 10,
      currentPage: 1,
      sortBy: "created_at",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 100],
      fields: [
        {
          key: "created_at",
          label: "Age"
        },
        {
          key: "type",
          label: "Type"
        },
        {
          key: "task_title",
          label: "Title"
        },
        {
          key: "actions",
          label: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      form: "form1",
      selectedLanguage: "selectedLanguage",
      isLoading: "isLoading",
      userFormSubmitArray: "userFormSubmitArray",
      fullName: getterName.USER.GET_FULL_NAME
    }),
    ...mapState({
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.totalRows;
    }
  },
  mounted() {
    this.checkUserAccountDetails();
    this.getTasks();
  },
  methods: {
    checkUserAccountDetails() {
      if (this.fullName) {
        this.accountUpdate = false;
      } else {
        this.accountUpdate = true;
      }
    },

    age(value) {
      return moment(value).fromNow();
    },
    async getTasks() {
      try {
        let result = await HTTP(
          "post",
          "v1/mytpp/tasks",
          {
            userId: this.userId
          },
          makeAuthorizationHeader(),
          null,
          true
        );
        this.training = result.data.training;
        this.questionnaires = result.data.questionnaires;
        this.exams = result.data.exams;
        this.certifications = result.data.certifications || [];
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-row {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}
.supplier__icon {
  color: $bright-gray;
}
</style>
