<template>
    <div class="tp-over-countries">
        <!-- graphical representation of third parties by countries start-->
        <highcharts
            :constructorType="'mapChart'"
            class="hc"
            :options="mapChartOptions"
            ref="chart"
        />
        <!-- graphical representation of third parties by countries end-->
    </div>
</template>

<script>
export default {
  name: "TpOverCountriesComponent",
  props:{
      mapChartOptions: Object
  }
}
</script>

<style lang="scss" scoped>
.tp-over-countries {
  border: $card-border;
}
</style>
