<template>
  <div>
    <InputGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'input'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <PhoneGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'phone'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <WebSite
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'website'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <NumberGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'number'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <EmailGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'email'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <TextAreaGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'textarea'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <SelectGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'select'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <ComboSelectGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'comboselect'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <ComboSelectProgressiveGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'comboSelectProgressive'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <CountriesSelectGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'countries'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <CpiCountriesSelectGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'cpi-countries'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <CurrencySelectGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'currency'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <TitleSelectGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'titles'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <RadioGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'radio'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <RadioGroupProgressive
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'radioMulti'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <CheckboxGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'checkbox'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <DateGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'date'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <UploadGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'file'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <RatingGroup
      :isPreview="isPreview"
      v-if="inputType === 'rating'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <AddressCorporateGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'address-corporate'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <AddressPersonalGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'address-personal'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <PersonalDetailsGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'personal-details'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <PrincipalContactGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'principal-contact'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <ReferencesGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'trade-reference'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <IndividualShareholderGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'individual-shareholders'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <CorporateShareholderGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'corporate-shareholders'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <CompanyOfficersGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'company-officers'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <HrGroup
      :isPreview="isPreview"
      v-if="inputType === 'hr'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <ButtonGroup
      :isPreview="isPreview"
      v-if="inputType === 'button'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <SignaturePad
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'signature'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <UploadPDFs
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'pdf'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <DownloadPDF
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'downloadPDF'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <!-- html Tagsss -->
    <HeadingGroup
      :isPreview="isPreview"
      v-if="inputType === 'h1' || inputType === 'h2' || inputType === 'h3'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <Hyperlink
      :isPreview="isPreview"
      v-if="inputType === 'link'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <ParagraphGroup
      :isPreview="isPreview"
      v-if="inputType === 'paragraph'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <ListItemGroup
      :isPreview="isPreview"
      v-if="inputType === 'ulli'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <WyswgGroup
      :isPreview="isPreview"
      v-if="inputType === 'wyswg-editor'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <QuestionGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'question'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <ExamQuestionGroup
      :isPreview="isPreview"
      v-if="inputType === 'exam-question'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <ImageGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'image'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <VideoGroup
      :isPreview="isPreview"
      v-if="inputType === 'video'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <CarouselGroup
      :isPreview="isPreview"
      v-if="inputType === 'carousel'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />

    <AutoSumGroup
      :isPreview="isPreview"
      v-if="inputType === 'sum'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <RangeGroup
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'range'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <!-- for executive form -->
    <AdvanceTableComponent
      :isPreview="isPreview"
      :isDisabled="isDisabled"
      v-if="inputType === 'summary'"
      :fieldData="fieldData"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
  </div>
</template>
<script>
import InputGroup from "./input";
import PhoneGroup from "./phone";
import EmailGroup from "./email";
import NumberGroup from "./number";
import WebSite from "./webSite";
import TextAreaGroup from "./textarea";
import SelectGroup from "./select";
import ComboSelectGroup from "./comboSelect";
import ComboSelectProgressiveGroup from "./comboSelectProgressive";
import CountriesSelectGroup from "./countries";
import CpiCountriesSelectGroup from "./cpi-countries";
import CurrencySelectGroup from "./currency";
import TitleSelectGroup from "./titles";
import RadioGroup from "./radio";
import CheckboxGroup from "./checkbox";
import DateGroup from "./date";
import RatingGroup from "./rating";
import AddressCorporateGroup from "./address-corporate";
import AddressPersonalGroup from "./address-personal";
import PersonalDetailsGroup from "./personal-details";
import PrincipalContactGroup from "./principal-contact";
import ReferencesGroup from "./references";
import IndividualShareholderGroup from "./individual-shareholders";
import CorporateShareholderGroup from "./corporate-shareholders";
import CompanyOfficersGroup from "./company-officers";
import HrGroup from "./hr";
import Hyperlink from "./hyper-link";
import HeadingGroup from "./heading";
import ListItemGroup from "./listItem";
import ButtonGroup from "./button";
import SignaturePad from "./signature";
import ImageGroup from "./image";
import VideoGroup from "./video";
import UploadGroup from "./upload";
import ParagraphGroup from "./paragraph";
import WyswgGroup from "./wyswgEditor";
import QuestionGroup from "./question";
import ExamQuestionGroup from "./exam-question";
import CarouselGroup from "./carousel";
import AutoSumGroup from "./autoSum";
import RangeGroup from "./range";
import UploadPDFs from "./uploadsPDF";
import DownloadPDF from "./downloadPDF";
import RadioGroupProgressive from "./radio-progresive.vue";
import AdvanceTableComponent from "./summary/advanceTableComponent";

import uid from "uid";

export default {
  name: "ElementsContainer",
  components: {
    InputGroup,
    NumberGroup,
    EmailGroup,
    PhoneGroup,
    WebSite,
    TextAreaGroup,
    SelectGroup,
    ComboSelectGroup,
    ComboSelectProgressiveGroup,
    CountriesSelectGroup,
    CpiCountriesSelectGroup,
    CurrencySelectGroup,
    TitleSelectGroup,
    RadioGroup,
    CheckboxGroup,
    DateGroup,
    RatingGroup,
    AddressCorporateGroup,
    AddressPersonalGroup,
    PersonalDetailsGroup,
    PrincipalContactGroup,
    ReferencesGroup,
    IndividualShareholderGroup,
    CorporateShareholderGroup,
    CompanyOfficersGroup,
    HrGroup,
    ButtonGroup,
    SignaturePad,
    HeadingGroup,
    ListItemGroup,
    ParagraphGroup,
    WyswgGroup,
    ImageGroup,
    VideoGroup,
    UploadGroup,
    QuestionGroup,
    ExamQuestionGroup,
    CarouselGroup,
    AutoSumGroup,
    RangeGroup,
    Hyperlink,
    // PDF
    UploadPDFs,
    DownloadPDF,
    // progressive
    RadioGroupProgressive,
    AdvanceTableComponent
    // for summary
  },
  data() {
    return {
      aa: uid()
    };
  },
  props: {
    fieldData: Object,
    sectionIndex: Number,
    fieldIndex: Number,
    inputType: String,
    isPreview: Boolean,
    isDisabled: Number
  }
};
</script>
<style>
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
