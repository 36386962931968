const eventBusName = {
  EDIT_THIRD_PARTY_DNB_MODAL: "editThirdPartyDnbModal",
  EDIT_THIRD_PARTY_MODAL: "editThirdPartyModal"
};

const ifBlockEventType = {
  CHANGE: "change",
  ADD_SUCCESS_STEP: "addSuccessStep",
  ADD_STEP: "addStep",
  AMEND_SUCCESS_STEP: "amendSuccessStep",
  DELETE_SUCCESS_STEP: "deleteSuccessStep",
  ADD_BLOCK: "addBlock",
  DELETE_ELSE_IF_BLOCK: "deleteElseIfBlock"
};

const actionsEvents = {
  FETCH_ACTION: "fetchAction",
  RESET_ACTION: "resetAction"
};

const roleEvents = {
  ADD_ROLE: "addRole",
  EDIT_ROLE: "editRole"
};

const roundEvents = {
  ADD_ROUND: "addRound",
  REMOVE_ROUND: "removeRound",
  ROUND_DECIMAL: "roundDecimal"
};

const dataTableEvents = {
  SEARCH_FILTER_BAR: "searchFilterBar",
  PER_PAGE_PAGINATION: "perPagePagination",
  ORDER_IDD: "orderIdd",
  GRID_READY: "gridReady"
};

const drilldownEvents = {
  DRILLDOWN_CLICK: "drilldown-click"
};

const userEmailTemplateEvents = {
  FETCH_USER_EMAIL_TEMPLATE: "fetch-user-email-template",
  RESET_USER_EMAIL_TEMPLATE: "reset-user-email-template"
};

export {
  eventBusName,
  ifBlockEventType,
  actionsEvents,
  roleEvents,
  roundEvents,
  userEmailTemplateEvents,
  dataTableEvents,
  drilldownEvents,
};
