<template>
  <div class="ongoing-monitoring-result">
    <!-- Ongoing Monitoring Results card -->
    <b-card class="text-center ongoing-monitoring-result__card">
      <b-card-title class="ongoing-monitoring-result__title">
        {{
          $t("eb360.analyticsTabChart.monitoringCard")
        }}
      </b-card-title>
      <b-tabs class="ongoing-monitoring-result__tabs" small>
        <b-tab
          :title="$t('eb360.analyticsTabChart.monitoringResults.thisMonth')"
          class="mt-4"
          :disabled="
            headerConfigData && headerConfigData.autoremiddplusmoni == 0
              ? true
              : false
          "
        >
          <b-card
            :bg-variant="
              totalNumberOfMonitoringAlertsThisMonth == 0
                ? 'success'
                : 'warning'
            "
            text-variant="white"
            class="text-center"
            :title="
              $t('eb360.analyticsTabChart.monitoringResults.momitoringAlerts')
            "
          >
            <b-card-text
              class="
                text-success text-center
                ongoing-monitoring-result__tabs-card-text
              "
            >
              <span>
                {{ totalNumberOfMonitoringAlertsThisMonth }}
              </span>
            </b-card-text>
          </b-card>
          <b-card
            :bg-variant="
              totalNumberOfThirdPartiesAlertsThisMonth == 0
                ? 'success'
                : 'danger'
            "
            text-variant="white"
            class="mt-2 text-center"
            :title="
              $t('eb360.analyticsTabChart.monitoringResults.thirdParyties')
            "
          >
            <b-card-text
              class="
                text-success text-center
                ongoing-monitoring-result__tabs-card-text
              "
            >
              <span>
                {{ totalNumberOfThirdPartiesAlertsThisMonth }}
              </span>
            </b-card-text>
          </b-card>
        </b-tab>
        <b-tab
          :title="$t('eb360.analyticsTabChart.monitoringResults.lastMonth')"
          class="mt-4"
          :disabled="
            headerConfigData && headerConfigData.autoremiddplusmoni == 0
              ? true
              : false
          "
        >
          <b-card
            :bg-variant="
              totalNumberOfMonitoringAlertsLastMonth == 0
                ? 'success'
                : 'warning'
            "
            text-variant="white"
            class="text-center mt-2"
            :title="
              $t('eb360.analyticsTabChart.monitoringResults.momitoringAlerts')
            "
          >
            <b-card-text
              class="
                text-success text-center
                ongoing-monitoring-result__tabs-card-text
              "
            >
              <span>
                {{ totalNumberOfMonitoringAlertsLastMonth }}
              </span>
            </b-card-text>
          </b-card>
          <b-card
            :bg-variant="
              totalNumberOfThirdPartiesAlertsLastMonth == 0
                ? 'success'
                : 'danger'
            "
            text-variant="white"
            class="mt-2 text-center"
            :title="
              $t('eb360.analyticsTabChart.monitoringResults.thirdParyties')
            "
          >
            <b-card-text
              class="
                text-success text-center
                ongoing-monitoring-result__tabs-card-text
              "
            >
              <span>
                {{ totalNumberOfThirdPartiesAlertsLastMonth }}
              </span>
            </b-card-text>
          </b-card>
        </b-tab>
        <b-tab
          :title="$t('eb360.analyticsTabChart.monitoringResults.allTime')"
          class="mt-4"
          :disabled="
            headerConfigData && headerConfigData.autoremiddplusmoni == 0
              ? true
              : false
          "
        >
          <b-card
            :bg-variant="
              totalNumberOfMonitoringAlertsAllTime == 0 ? 'success' : 'warning'
            "
            text-variant="white"
            class="text-center"
            :title="
              $t('eb360.analyticsTabChart.monitoringResults.momitoringAlerts')
            "
          >
            <b-card-text
              class="
                text-success text-center
                ongoing-monitoring-result__tabs-card-text
              "
            >
              <span>
                {{ totalNumberOfMonitoringAlertsAllTime }}
              </span>
            </b-card-text>
          </b-card>
          <b-card
            :bg-variant="
              totalNumberOfThirdPartiesAlertsAllTime == 0 ? 'success' : 'danger'
            "
            text-variant="white"
            class="mt-2 text-center"
            :title="
              $t('eb360.analyticsTabChart.monitoringResults.thirdParyties')
            "
          >
            <b-card-text
              class="
                text-success text-center
                ongoing-monitoring-result__tabs-card-text
              "
            >
              <span>
                {{ totalNumberOfThirdPartiesAlertsAllTime }}
              </span>
            </b-card-text>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
    <!-- Ongoing Monitoring Results card -->
  </div>
</template>

<script>
export default {
  name: "OngoingMonitoringResults",
  props: {
    totalNumberOfMonitoringAlertsThisMonth: Number,
    totalNumberOfThirdPartiesAlertsThisMonth: Number,
    totalNumberOfMonitoringAlertsLastMonth: Number,
    totalNumberOfThirdPartiesAlertsLastMonth: Number,
    totalNumberOfMonitoringAlertsAllTime: Number,
    totalNumberOfThirdPartiesAlertsAllTime: Number,
    headerConfigData: {
      required: false,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.ongoing-monitoring-result {
  border: $card-border;

  &__title {
    @include heading-four;
  }

  &__card {
    border: none;
  }

  &__tabs {
    &-card-text {
      font-size: 50px;
      > span {
        color: $white;
        font-weight: bold;
      }
    }
  }
}
</style>
