import { HTTP, makeAuthorizationHeader } from "@/utils";
import { endpoints } from "@/constants";
import { makeRoleDTO, makeRolesDTO } from "@/services/user/dto/user.dto";

const fetchHomePages = (companyId, userId) =>
  HTTP(
    "get",
    endpoints.USER_HOME_PAGES(companyId, userId),
    null,
    makeAuthorizationHeader()
  );

const fetchRoles = async (companyId) => {
  const { data = {} } = await HTTP(
    "get",
    endpoints.GET_ROLES(companyId),
    null,
    makeAuthorizationHeader()
  );

  return makeRolesDTO(data.data);
};

const fetchRole = async (companyId, roleId) => {
  const { data = {} } = await HTTP(
    "get",
    endpoints.GET_ROLE(companyId, roleId),
    null,
    makeAuthorizationHeader()
  );

  return makeRoleDTO(data);
};

const createRole = async (companyId, role) => {
  const { data = {} } = await HTTP(
    "post",
    endpoints.CREATE_ROLE(companyId),
    role,
    makeAuthorizationHeader()
  );

  return makeRoleDTO(data);
};

const updateRole = async (companyId, role, roleId) => {
  const { data = {} } = await HTTP(
    "patch",
    endpoints.UPDATE_ROLE(companyId, roleId),
    role,
    makeAuthorizationHeader()
  );

  return makeRoleDTO(data);
};

const deleteRole = async (companyId, roleId) => {
  const { data = {} } = await HTTP(
    "delete",
    endpoints.DELETE_ROLE(companyId, roleId),
    null,
    makeAuthorizationHeader()
  );

  return makeRoleDTO(data);
};

export const userService = {
  fetchHomePages,
  fetchRoles,
  fetchRole,
  createRole,
  updateRole,
  deleteRole
};
