<template>
  <div class="logo">
    <img
      v-if="platformLogo"
      v-bind="logoProps"
      :src="platformLogo"
      class="logo__image"
      data-test-id="logo__image"
      alt="logo"
    />
    <p v-else-if="platformLogoFallback">{{ platformLogoFallback }}</p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",
  props: {
    logoProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("platformSettings", ["platformLogo", "platformLogoFallback"])
  }
};
</script>
