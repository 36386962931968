/**
 * @namespace HTMLElements
 */

/**
 * Returns the highest clientWidth value from a HTMLCollection
 * @memberof HTMLElements
 * @param {HTMLCollection} HTMLElements - Collection of badge elements
 * @returns {number} - highest width in pixels
 */
const getWidestElement = (HTMLElements) => {
  let widestElement = 0;

  for (const badgeElement of HTMLElements) {
    if (badgeElement?.clientWidth > widestElement) {
      widestElement = badgeElement?.clientWidth;
    }
  }

  return widestElement;
};

export { getWidestElement };
