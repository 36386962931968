<template>
  <div>
    <b-overlay :show="isLoading">
      <b-navbar>
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <!-- Save button start -->
            <b-button
              variant="outline-success"
              size="sm"
              class="mr-2"
              @click="onSaveSettings"
              :disabled="isSubmitting"
            >
              <span v-if="isSubmitting"><b-spinner small></b-spinner></span>
              <span v-else>Save Settings</span>
            </b-button>
            <!-- Save button end -->

            <!-- reload button start -->
            <b-button
              @click="getUserSettings({ companyId, userId })"
              variant="outline-info"
              size="sm"
              :disabled="isSubmitting"
            >
              <font-awesome-icon icon="sync" />
            </b-button>
            <!-- reload button end -->
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>
      <div>
        <div class="tableHeading">General Settings</div>
        <b-row class="mt-4">
          <b-col cols="6" class="text-left">
            <!-- Standard Account Home Page input field -->
            <b-form-group
              label="Standard Account Home Page: "
              label-cols-lg="4"
            >
              <v-select
                v-model="standardHomePage"
                :options="allActivePages"
                label="text"
                clearable
              >
              </v-select>
            </b-form-group>

            <!-- My Home Page input field -->
            <b-form-group label="My Home Page: " label-cols-lg="4">
              <v-select
                v-model="myHomePage"
                :options="allActivePages"
                label="text"
                clearable
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { mapActions, mapState } from "vuex";

export default {
  name: "GeneralSettings",
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      standardHomePage: null,
      myHomePage: null
    };
  },
  async created() {
    await this.fetchPageSettings();
    this.myHomePage = this.userHomePage;
    this.standardHomePage = this.accountHomePage;
  },
  computed: {
    ...mapState({
      userHomePage: (state) => state.userSettings.userHomePage,
      accountHomePage: (state) => state.userSettings.accountHomePage,
      allActivePages: (state) => state.userSettings.allActivePages,
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  methods: {
    ...mapActions({
      getUserSettings: "userSettings/getUserSettings"
    }),
    async fetchPageSettings() {
      this.isLoading = true;
      await this.getUserSettings({
        companyId: this.companyId,
        userId: this.userId
      });
      this.isLoading = false;
    },
    // call save settings API
    async onSaveSettings() {
      this.isSubmitting = true;
      let formData = {
        companyId: this.companyId,
        memberId: this.userId,
        account_home_page: this.standardHomePage
          ? {
              title: this.standardHomePage.text,
              url: this.standardHomePage.value
            }
          : null,
        my_home_page: this.myHomePage
          ? { title: this.myHomePage.text, url: this.myHomePage.value }
          : null
      };
      let result = HTTP(
        "post",
        "v1/set/landing/page",
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false,
            noCloseButton: true
          });
          this.isSubmitting = false;
          this.fetchPageSettings();
        })
        .catch((error) => {
          this.isSubmitting = false;

          return [error];
        });
    }
  }
};
</script>

<style></style>
