import { mapGetters, mapState } from "vuex";
import { getterName } from "@/constants";

export default {
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      permissions: (state) => state.user.permissions
    }),
    ...mapGetters({
      isUserSuperAdminOrAdmin: getterName.USER.IS_USER_SUPER_ADMIN_OR_ADMIN
    })
  },
  methods: {
    getPermissionType(permissionType) {
      return (
        this.permissions.find(({ id = "" }) => id === permissionType)
          ?.contexts || []
      );
    },
    hasPermission(permissionType, id) {
      if (this.isUserSuperAdminOrAdmin) {
        return true;
      }

      const permissions = this.getPermissionType(permissionType);

      return !!permissions.find(
        ({ companyId = "", customAttributeId = "" }) =>
          companyId === this.companyId && customAttributeId === parseInt(id)
      );
    }
  }
};
