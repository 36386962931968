<template>
  <BaseLoader v-if="isLoadingTableData" />
  <div v-else class="third-party-entity-profile">
    <ProfileHeader
      class="third-party-entity-profile__header"
      :entity-name="UBOEntityName"
      entity-logo="sitemap"
      :logo-size="20"
    />
    <hr />
    <UltimateBeneficialOwnersEntityProfile
      v-if="Object.keys(UBOEntityData).length"
      class="u-b-o-entity-profile__table-container"
      data-test-id="u-b-o-entity-profile__table-container"
      :u-b-o-entity-profile-data="UBOEntityData"
      :u-b-o-entity-profile-error-message="UBOEntityErrorMessage"
    />
  </div>
</template>

<script>
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import UltimateBeneficialOwnersEntityProfile from "@/organisms/UltimateBeneficialOwnersEntityProfile/UltimateBeneficialOwnersEntityProfile";
import { thirdPartyService } from "@/services";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { ultimateBeneficialOwnersKeysAPIDTO } from "@/constants";

export default {
  name: "UltimateBeneficialOwnersProfile",
  components: {
    ProfileHeader,
    UltimateBeneficialOwnersEntityProfile,
    BaseLoader
  },
  data() {
    return {
      isLoadingTableData: false,
      thirdPartyId: null,
      memberId: null,
      UBOEntityData: {},
      UBOEntityErrorMessage: ""
    };
  },
  mounted() {
    this.fetchUBOEntityData();
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    }),
    UBOEntityName() { return this.UBOEntityData?.name || ""; }
  },
  methods: {
    makeUBOEntityQueryParameters() {
      return {
        [ultimateBeneficialOwnersKeysAPIDTO.THIRD_PARTY_ID]:
          this.$route.params.thirdPartyId,
        [ultimateBeneficialOwnersKeysAPIDTO.COMPANY_ID]: this.companyId,
        [ultimateBeneficialOwnersKeysAPIDTO.MEMBER_ID_UNDERSCORED]:
          this.$route.params.memberId
      };
    },
    async fetchUBOEntityData() {
      this.isLoadingTableData = true;
      try {
        const data = await thirdPartyService.fetchUBOEntityData(
          this.makeUBOEntityQueryParameters()
        );
        this.fetchUBOEntitySuccessfully(data);
      } catch ({ status }) {
        this.fetchUBOEntityFailure(status);
      } finally {
        this.isLoadingTableData = false;
      }
    },
    fetchUBOEntitySuccessfully(data) {
      if (!isEmpty(data)) {
        this.UBOEntityData = data;
      } else {
        this.UBOEntityErrorMessage = "No results found";
      }
    },
    fetchUBOEntityFailure() {
      this.UBOEntityData = {};
      this.UBOEntityErrorMessage =
        "Something went wrong, please try again. If the issue persists please contact support";
    }
  }
};
</script>
