import { actions } from "./customAttributes.actions.store";
import { mutations } from "./customAttributes.mutations.store";
import { state } from "./customAttributes.state.store";

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
