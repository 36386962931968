<template id="template-button">
  <div class="bg-light p-3 pb-5 mb-2">
    <!-- menu buttons -->
    <MenuButtons
      :fieldData="fieldData"
      :collapse="collapse"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
    />
    <b-collapse class="mt-5" id="collapse-1" v-model="collapse">
      <button type="button" class="btn btn-primary">Primary</button>
    </b-collapse>
  </div>
</template>

<script>
import MenuButtons from "../menuButtons";

export default {
  name: "ButtonGroup",
  components: {
    MenuButtons
  },
  data() {
    return {
      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true
    };
  },
  methods: {},
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
