<template>
  <div>
    <div class="d-flex" style="justify-content: center">
      <h4 class="ml-auto">Configure Analytics for {{ companyName }}</h4>

      <div class="ml-auto my-2 d-flex text-right">
        <!-- Reset Configuration button for resetting configuration -->
        <b-form-checkbox
          v-model="status"
          name="checkbox-1"
          :value="1"
          :unchecked-value="0"
          class="mt-2"
          @change="resetConfiguration"
        >
          Reset Configuration
        </b-form-checkbox>

        <!-- save btn -->
        <b-btn @click="onSubmitActions" variant="outline-success">
          Submit
        </b-btn>
      </div>
    </div>
    <b-overlay :show="isSubmiting">
      <b-row class="ml-5">
        <draggable
          :list="allAnalytics"
          style="display: flex; height: calc(100vh - 120px); overflow-y: auto"
        >
          <b-col
            class="kanban-col"
            style="border: 1px dashed black; padding: 0px"
            v-for="value in allAnalytics"
            :key="value.index"
          >
            <h5 class="p-2">
              <b-form-checkbox
                v-if="value.title"
                v-model="value.isActive"
                :value="1"
                :unchecked-value="0"
                @change="enableItems($event, value)"
              >
                {{ value.title }}
              </b-form-checkbox>
            </h5>
            <hr style="margin: 0px" />
            <draggable :list="value.items" class="list-group">
              <div
                class="list-group-item"
                v-for="(items, index) in value.items"
                :key="index"
              >
                <b-form-checkbox
                  class="float-left"
                  v-model="items.isActive"
                  :value="1"
                  :unchecked-value="0"
                  @change="enableParentItem($event, value)"
                >
                  {{ items.title }}
                </b-form-checkbox>
              </div>
            </draggable>
          </b-col>
        </draggable>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import allAnalyticsJson from "../../../utils/allAnalytics.json";
import { EventBus } from "@/event-bus.js";
import "vue-good-table/dist/vue-good-table.css";
import { clone } from "lodash";

export default {
  components: {
    draggable
  },
  data() {
    return {
      isSubmiting: false,
      isLoading: false,
      isLoadingData: false,
      allAnalytics: clone(allAnalyticsJson),
      actionsOccur: [],
      extraActions: [],
      actionsNotAvailable: [],
      status: 0,
      companyName: null
    };
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
    this.getAdditionalVouchers();
  },
  created() {
    EventBus.$on("callgetAdditionalVouchers", () => {
      this.getAdditionalVouchers();
    });
  },
  methods: {
    resetConfiguration() {
      this.allAnalytics.map((data) => {
        data.isActive = 0;
        data.items.map((items) => {
          items.isActive = 0;
        });
      });
    },

    // enable items if parent item is selected
    enableItems(event, data) {
      if (event) {
        data.items.map((val) => {
          val.isActive = 1;
        });
      } else {
        data.items.map((val) => {
          val.isActive = 0;
        });
      }
      //
    },

    // enable parent item if child item is selected
    enableParentItem(event, data) {
      if (event) {
        data.isActive = 1;
      }
    },
    async getAdditionalVouchers() {
      this.isSubmiting = true;
      let schema = JSON.parse(localStorage.getItem("actionsSchema"));
      if (schema) {
        if (schema.analyticsConfig) {
          schema.analyticsConfig.map((column) => {
            let allColumnsIndex = allAnalyticsJson.findIndex(
              (x) => x.title == column.title
            );
            if (allColumnsIndex != -1) {
              if (
                allAnalyticsJson[allColumnsIndex] &&
                allAnalyticsJson[allColumnsIndex].items.length
              ) {
                allAnalyticsJson[allColumnsIndex].items.map((field, index) => {
                  let staticAction = field;
                  Object.keys(staticAction).map((staticKey) => {
                    if (column.items.length != 0) {
                      if (column.items[index][staticKey] == null) {
                        column.items[index][staticKey] =
                          staticAction[staticKey];
                      }
                    }
                  });
                });
              }
            }
          });

          this.allAnalytics = schema.analyticsConfig || [];
        }
      }
      this.onSubmitActions();
      this.isSubmiting = false;
    },
    async getQuestionnariesData() {
      this.isSubmiting = true;
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslistforanalytics/${this.$route.params.id}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          this.allAnalytics.map((data) => {
            if (data.title.toLowerCase() == "questionnaires") {
              result.data.questionnaires.map((items) => {
                let index = data.items.findIndex(
                  (x) => x.id == items.id && x.title == items.title
                );
                if (index == -1) {
                  data.items.push(items);
                }
              });
            }
          });
        }
        this.isSubmiting = false;
      } catch (error) {
        this.isSubmiting = false;
      }
    },

    // save configuartion
    async onSubmitActions() {
      // let formatData = this.allAnalytics.filter( btn => btn.isActive );
      // formatData.map((data)=>{
      //   data.items = data.items.filter( btn => btn.isActive );
      // })
      let formData = {
        company_id: this.$route.params.id,
        analyticsConfig: this.allAnalytics,
        is_default_analytics: this.status
      };
      this.isSubmiting = true;
      this.isLoading = true;
      try {
        let result = await HTTP(
          "post",
          "thirdparty/update-tp/dynamic-actions",
          formData,
          makeAuthorizationHeader()
        );
        //
        if (result.status == 200) {
          let schema = JSON.parse(result.data.additional_data);
          if (schema) {
            if (schema.analyticsConfig && schema.analyticsConfig.length) {
              this.allAnalytics = schema.analyticsConfig;
              schema.analyticsConfig.map((questionnaireItems) => {
                if (
                  questionnaireItems.title == "Questionnaires" &&
                  questionnaireItems.items.length == 0
                ) {
                  this.getQuestionnariesData();
                }
              });
            } else {
              this.allAnalytics = allAnalyticsJson;
              this.getQuestionnariesData();
            }
          }
          let actionsData = schema || allAnalyticsJson;
          localStorage.setItem("actionsSchema", JSON.stringify(actionsData));
          this.status = 0;
          this.isSubmiting = false;
          this.isLoading = false;
        }
      } catch (error) {
        this.isSubmiting = false;
        this.isLoading = false;
      }
    }
  }
};
</script>

<style>
.kanban-col {
  min-height: 640px;
}
</style>
