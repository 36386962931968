<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="isLoading" rounded="sm">
          <b-card no-body class="shadow-sm pt-0 pl-0 pr-0 m-4 mb-0">
            <b-navbar toggleable="lg">
              <b-navbar-brand href="#" class="text-dark">
                <strong style="color: #3b3e66"
                  >Platform Settings For {{ companyName }}</strong
                >
              </b-navbar-brand>

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-form>
                  <!-- Add btn -->
                  <b-button
                    @click="showAddPlatformModal = !showAddPlatformModal"
                    class="mr-2"
                    variant="outline-primary"
                    size="sm"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>

                  <!-- Reload btn -->
                  <b-button
                    variant="outline-info"
                    size="sm"
                    class="mr-4"
                    @click="reloadTable"
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>

                  <!-- search bar -->
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-nav-form>
              </b-navbar-nav>
            </b-navbar>

            <!-- platform settings table data start -->
            <b-table
              sticky-header="calc(100vh - 295px)"
              :busy.sync="isLoading"
              bordered
              ref="table"
              id="platform-variables-table"
              :fields="staticFields"
              :items="getPlatformData"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              no-sort-reset
            >
              <template v-slot:cell(variable_type)="data">
                <span style="text-transform: capitalize">{{
                  data.item.variable_type == "hyper_link"
                    ? "Hyper Link"
                    : data.item.variable_type
                }}</span>
              </template>
              <template v-slot:cell(platform_variable_value)="data">
                <p
                  v-if="
                    data.item.variable_type == 'hyper_link' ||
                    data.item.variable_type == 'email'
                  "
                  v-html="data.item.platform_variable_value"
                ></p>
                <span v-else>{{
                  data.item.variable_type == "image"
                    ? "Binary Image"
                    : data.item.platform_variable_value
                }}</span>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge
                  :variant="data.item.status == 1 ? 'success' : 'danger'"
                  >{{ data.item.status == 1 ? "Active" : "Deactive" }}</b-badge
                >
              </template>
              <template v-slot:cell(actions)="data">
                <b-btn
                  @click="editPlatform(data)"
                  class="mr-2"
                  size="sm"
                  variant="outline-info"
                >
                  <font-awesome-icon icon="edit" />
                </b-btn>
              </template>
            </b-table>
            <!-- platform settings table data end -->

            <template v-slot:footer>
              <b-row>
                <b-col cols="2">
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
                <b-col cols="6">
                  <p class="text-center">
                    Showing Result From
                    <b>{{ fromRows }}</b> to
                    <b>{{ toRows }}</b>
                  </p>
                </b-col>
                <b-col cols="4">
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    aria-controls="platform-variables-table"
                  ></b-pagination>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Modal for add/update platform setting -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :title="isEditPlatform ? 'Update Platform' : 'Add New Platform Setting'"
      size="lg"
      v-model="showAddPlatformModal"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <b-row>
            <b-col cols="6">
              <b-form-group style="margin-top: 20px">
                <label class="text-left">Platform Variable Name:</label>
                <b-form-input
                  v-model="$v.form.platformVariableName.$model"
                  :state="
                    $v.form.platformVariableName.$dirty
                      ? !$v.form.platformVariableName.$error
                      : null
                  "
                  size="sm"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group style="margin-top: 20px">
                <label
                  >Platform Variable Key:<span class="required-span"
                    >*</span
                  ></label
                >
                <b-form-input
                  v-model="$v.form.platformVariableKey.$model"
                  :state="
                    $v.form.platformVariableKey.$dirty
                      ? !$v.form.platformVariableKey.$error
                      : null
                  "
                  size="sm"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group style="margin-top: 20px">
                <label
                  >Platform Variable Type:<span class="required-span"
                    >*</span
                  ></label
                >
                <b-form-radio-group
                  required
                  :validated="$v.form.platformVariableType.$dirty"
                  v-model="$v.form.platformVariableType.$model"
                  :state="
                    $v.form.platformVariableType.$dirty
                      ? !$v.form.platformVariableType.$error
                      : null
                  "
                  :options="inputFieldOptions"
                  @input="onChangeVariableType"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group style="margin-top: 30px">
                <b-form-checkbox v-model="form.status" switch
                  >Is Active</b-form-checkbox
                >
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="form.platformVariableType == 'email'">
              <b-form-group style="margin-top: 30px">
                <label class="text-left">Email Text:</label>
                <b-form-input v-model="form.hyperLinkText"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="form.platformVariableType == 'email'">
              <b-form-group style="margin-top: 20px">
                <label
                  >Platform Variable Value:<span class="required-span"
                    >*</span
                  ></label
                >
                <b-form-input
                  type="email"
                  v-model="$v.form.platformVariableValue.$model"
                  :state="
                    $v.form.platformVariableValue.$dirty
                      ? !$v.form.platformVariableValue.$error
                      : null
                  "
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.form.platformVariableValue.$dirty &&
                    $v.form.platformVariableValue.$error &&
                    !$v.form.platformVariableValue.required
                  "
                  >Plese Enter Variable Value</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="
                    $v.form.platformVariableValue.$dirty &&
                    $v.form.platformVariableValue.$error &&
                    !$v.form.platformVariableValue.email
                  "
                  >Plese Enter valid Email</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="form.platformVariableType == 'hyper_link'">
              <b-form-group style="margin-top: 30px">
                <label class="text-left">Hyper Link Text:</label>
                <b-form-input v-model="form.hyperLinkText"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="form.platformVariableType == 'hyper_link'">
              <b-form-group style="margin-top: 20px">
                <label
                  >Hyper Link Url:<span class="required-span">*</span></label
                >
                <b-form-input
                  v-model="$v.form.platformVariableValue.$model"
                  :state="
                    $v.form.platformVariableValue.$dirty
                      ? !$v.form.platformVariableValue.$error
                      : null
                  "
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.form.platformVariableValue.$dirty &&
                    $v.form.platformVariableValue.$error &&
                    !$v.form.platformVariableValue.required
                  "
                  >Plese Enter Variable Value</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                style="margin-top: 20px"
                v-if="form.platformVariableType == 'text'"
              >
                <label
                  >Platform Variable Value:<span class="required-span"
                    >*</span
                  ></label
                >
                <b-form-input
                  v-model="$v.form.platformVariableValue.$model"
                  :state="
                    $v.form.platformVariableValue.$dirty
                      ? !$v.form.platformVariableValue.$error
                      : null
                  "
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    $v.form.platformVariableValue.$dirty &&
                    $v.form.platformVariableValue.$error &&
                    !$v.form.platformVariableValue.required
                  "
                  >Plese Enter Variable Value</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                style="margin-top: 20px"
                v-if="form.platformVariableType == 'textarea'"
              >
                <label
                  >Platform Variable Value:<span class="required-span"
                    >*</span
                  ></label
                >
                <b-form-textarea
                  v-model="$v.form.platformVariableValue.$model"
                  :state="
                    $v.form.platformVariableValue.$dirty
                      ? !$v.form.platformVariableValue.$error
                      : null
                  "
                ></b-form-textarea>
                <b-form-invalid-feedback
                  v-if="
                    $v.form.platformVariableValue.$dirty &&
                    $v.form.platformVariableValue.$error &&
                    !$v.form.platformVariableValue.required
                  "
                  >Plese Enter Variable Value</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                style="margin-top: 20px"
                v-if="form.platformVariableType == 'date'"
              >
                <label
                  >Platform Variable Value:<span class="required-span"
                    >*</span
                  ></label
                >
                <b-form-datepicker
                  v-model="$v.form.platformVariableValue.$model"
                  :state="
                    $v.form.platformVariableValue.$dirty
                      ? !$v.form.platformVariableValue.$error
                      : null
                  "
                ></b-form-datepicker>
                <b-form-invalid-feedback
                  v-if="
                    $v.form.platformVariableValue.$dirty &&
                    $v.form.platformVariableValue.$error &&
                    !$v.form.platformVariableValue.required
                  "
                  >Plese Enter Variable Value</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                style="margin-top: 20px"
                v-if="form.platformVariableType == 'image'"
              >
                <!-- <label class="text-left">Platform Variable Value:</label> -->
                <!-- <input
                  accept="image/*"
                  type="file"
                  id="img-upload-btn"
                  @change="onFileChanged"
                /> -->
                <b-form-file
                  accept="image/*"
                  v-model="$v.imageForm.platformImageValue.$model"
                  :state="
                    $v.imageForm.platformImageValue.$dirty
                      ? !$v.imageForm.platformImageValue.$error
                      : null
                  "
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  id="img-upload-btn"
                  @change="onFileChanged"
                ></b-form-file>
                <div v-if="isEditPlatform">
                  <div v-if="platformImage != null">
                    <b-img-lazy
                      :src="platformImage"
                      class="card-img my-5"
                      id="output"
                      alt="..."
                    ></b-img-lazy>
                  </div>
                  <p v-else v-html="form.platformVariableValue"></p>
                </div>
                <div v-else-if="platformImage != null">
                  <b-img-lazy
                    v-if="platformImage"
                    :src="platformImage"
                    class="card-img my-5"
                    id="output"
                    alt="..."
                  ></b-img-lazy>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <div style="display: grid; justify-content: center">
            <p>
              {{
                isEditPlatform
                  ? "Your Platform is being Updateing......"
                  : "Your Platform is being Creating......"
              }}
            </p>
            <div style="display: flex; justify-content: center">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            {{
              isEditPlatform
                ? "Your Platform has been updated successfully."
                : "Your Platform has been added successfully."
            }}
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="!submiting" @click="resetModal" variant="outline-danger"
          >Close</b-button
        >
        <b-button
          v-if="!submiting && !responseSuccess"
          @click="checkValidation"
          variant="outline-success"
          >{{ isEditPlatform ? "Update" : "Save" }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import { Helper } from "@/helpers";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "PlatformsSetting",
  mixins: [validationMixin],
  data() {
    return {
      companyName: null,
      isEditPlatform: false,
      tableItemsIndex: null,
      submiting: false,
      isLoading: false,
      responseSuccess: false,
      showAddPlatformModal: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      inputFieldOptions: [
        { text: "Text", value: "text" },
        { text: "Date", value: "date" },
        { text: "Text Area", value: "textarea" },
        { text: "Image", value: "image" },
        { text: "Hyper Link", value: "hyper_link" },
        { text: "Email", value: "email" }
      ],
      staticFields: [
        {
          key: "platform_variable_name",
          label: "Platform Variable Name",
          sortable: true
        },
        {
          key: "platform_variable_key",
          label: "Platform Variable Key",
          sortable: true
        },
        {
          key: "variable_type",
          label: "Platform Variable Type",
          sortable: true
        },
        {
          key: "platform_variable_value",
          label: "Platform Variable Value",
          sortable: true
        },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Action", sortable: false }
      ],
      platformItems: [],
      imageForm: {
        platformImageValue: null
      },
      form: {
        platformVariableName: null,
        platformVariableKey: null,
        platformVariableValue: null,
        platformVariableType: null,
        status: true,
        hyperLinkText: null
      },
      platformId: null,
      platformImage: null,
      platformImageData: null,
      platformType: null
    };
  },
  validations() {
    if (this.form.platformVariableType == "image") {
      return {
        form: {
          platformVariableName: {
            required
          },
          platformVariableKey: {
            required
          },
          platformVariableValue: {
            required
          },
          platformVariableType: {
            required
          }
        },
        imageForm: {
          platformImageValue: {
            required
          }
        }
      };
    } else if (this.form.platformVariableType == "email") {
      return {
        form: {
          platformVariableName: {
            required
          },
          platformVariableKey: {
            required
          },
          platformVariableValue: {
            required,
            email
          },
          platformVariableType: {
            required
          }
        }
      };
    } else {
      return {
        form: {
          platformVariableName: {
            required
          },
          platformVariableKey: {
            required
          },
          platformVariableValue: {
            required
          },
          platformVariableType: {
            required
          }
        }
      };
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.totalRows;
    }
  },
  mounted() {
    this.companyName = localStorage.getItem("openedCompanyProfile");
  },
  methods: {
    onChangeVariableType(value) {
      if (this.platformType == "image" || value == "image") {
        this.form.platformVariableValue = "image";
      }
      this.platformType = value;
    },
    createdAtDate(value) {
      moment.locale("en");
      return moment(value).format("DD/MM/YYYY");
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "platform-variables-table");
    },
    onFileChanged(img) {
      this.imageForm.platformImageValue = "image";
      this.form.platformVariableValue = "image";
      this.platformImageData = img.target.files[0];
      this.setPlatformVariableImage();
    },
    setPlatformVariableImage() {
      if (this.platformImageData != null) {
        const this_ = this;
        this.uploadFile(this.platformImageData).then((result) => {
          this_.platformImage = result;
          let imageTag = `<img class='card-img my-5' src='${result}'/>`;
          this_.form.platformVariableValue = imageTag;
        });
      }
    },
    // Not Used as replaced by uploadFile function
    getBase64(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const this_ = this;
      reader.onload = function () {
        let dataURL = reader.result;

        let imageTag = `<img class='card-img my-5' src='${dataURL}'/>`;
        this_.form.platformVariableValue = imageTag;
        if (this_.isEditPlatform) {
          this_.updatePlatformSetting().then(this_.reloadTable);
        } else {
          this_.savePlatformSetting().then(this_.reloadTable);
        }
        this_.platformImage = null;
        this_.platformImageData = null;
        return false;
      };
      reader.onerror = (error) => error;
    },
    uploadFile(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result);
        };
      });
    },

    // get platform settings data
    async getPlatformData() {
      let formData = {
        company_id: this.$route.params.id,
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage
      };
      this.isLoading = true;
      let result = HTTP(
        "post",
        "v1/platform-variable/get",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          const items = response.data.data;
          this.isLoading = false;
          this.fromRows = response.data.from;
          this.toRows = response.data.to;
          this.totalRows = response.data.total;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          return [error];
        });
    },
    resetForm() {
      this.form = {
        platformVariableName: null,
        platformVariableKey: null,
        platformVariableValue: null,
        platformVariableType: null,
        hyperLinkText: null,
        status: true
      };
      this.imageForm.platformImageValue = null;
      this.$v.$reset();
    },
    resetModal() {
      this.resetForm();
      this.isEditPlatform = false;
      this.tableItemsIndex = null;
      this.showAddPlatformModal = false;
      this.submiting = false;
      this.isLoading = false;
      this.responseSuccess = false;
      this.platformImage = null;
      this.platformImageData = null;
    },

    // check validation before adding/updating platform settings
    checkValidation() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      } else {
        this.setFormsData();
      }
    },
    setFormsData() {
      if (this.form.platformVariableType == "image") {
        this.$v.imageForm.$touch();
        if (this.$v.imageForm.$anyError) {
          return false;
        }
      }
      if (this.form.platformVariableType == "hyper_link") {
        let innerHTML = this.form.hyperLinkText
          ? this.form.hyperLinkText
          : this.form.platformVariableValue;
        let imageTag = `<a href="${this.form.platformVariableValue}" target="_blank">${innerHTML}</a>`;
        this.form.platformVariableValue = imageTag;
      }
      if (this.form.platformVariableType == "email") {
        let innerHTML = this.form.hyperLinkText
          ? this.form.hyperLinkText
          : this.form.platformVariableValue;
        let imageTag = `<a href="mailto:${this.form.platformVariableValue}" target="_blank">${innerHTML}</a>`;
        this.form.platformVariableValue = imageTag;
      }
      if (this.isEditPlatform) {
        this.updatePlatformSetting().then(this.reloadTable);
      } else {
        this.savePlatformSetting().then(this.reloadTable);
      }
      this.platformImage = null;
      this.platformImageData = null;
    },

    // add platform setting API
    async savePlatformSetting() {
      let formData = {
        company_id: this.$route.params.id,
        platform_variable_name: this.form.platformVariableName,
        platform_variable_key: this.form.platformVariableKey,
        platform_variable_value: this.form.platformVariableValue,
        variable_type: this.form.platformVariableType,
        status: this.form.status ? 1 : 0,
        created_by_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "v1/platform-variable/create",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster(
            "success",
            "Your Platform Variable has been added successfully."
          );
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },

    // update platform setting API
    async updatePlatformSetting() {
      let formData = {
        platform_variable_name: this.form.platformVariableName,
        platform_variable_key: this.form.platformVariableKey,
        platform_variable_value: this.form.platformVariableValue,
        variable_type: this.form.platformVariableType,
        status: this.form.status ? 1 : 0,
        created_by_id: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "put",
          `v1/platform-variable/update/${this.platformId}`,
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result && result.data && result.data.success == 1) {
          this.makeToster("success", result.data.message);
        } else if (result && result.data && result.data.success == 0) {
          this.makeToster("danger", result.data.message);
        } else {
          this.makeToster("danger", "Oops! Something Went Wrong.");
        }
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    editPlatform(data) {
      this.isEditPlatform = true;
      this.platformId = data.item.id;
      this.tableItemsIndex = data.index;
      this.platformImage = null;
      this.platformImageData = null;
      this.platformType = data.item.variable_type;
      let platformValue = null;
      if (data.item.variable_type == "hyper_link") {
        let doc = document.createElement("html");
        doc.innerHTML = data.item.platform_variable_value;
        let element = doc.getElementsByTagName("A");
        this.form.hyperLinkText = element[0].innerText;
        platformValue = element[0].href;
      } else if (data.item.variable_type == "email") {
        let doc = document.createElement("html");
        doc.innerHTML = data.item.platform_variable_value;
        let element = doc.getElementsByTagName("A");
        platformValue = element[0].href;
        let valueLength = platformValue.length;
        platformValue = platformValue.slice(7, valueLength);
        this.form.hyperLinkText = element[0].innerText;
      } else {
        this.imageForm.platformImageValue =
          data.item.variable_type == "image" ? "image" : null;
        platformValue =
          data.item.variable_type == "image"
            ? data.item.platform_variable_value
            : data.item.platform_variable_value;
      }
      this.form.platformVariableName = data.item.platform_variable_name;
      this.form.platformVariableKey = data.item.platform_variable_key;
      this.form.platformVariableValue = platformValue;
      this.form.platformVariableType = data.item.variable_type;
      this.form.status = data.item.status == 1;
      this.showAddPlatformModal = true;
    },
    makeToster(tosterVarient, tosterMsg) {
      this.resetModal();
      Helper.makeToast(this.$bvToast, tosterVarient, tosterMsg);
    }
  }
};
</script>

<style></style>
