import {
  actionName,
  getterName,
  localStorageItem,
  mutationName,
  routeNames,
  urls,
  routeAccessRules
} from "@/constants";
import store from "@/store";
import thirdPartyRoutes from "@/routes/thirdParty.routes";

const routesNameAllowedToTp = thirdPartyRoutes.reduce(
  (acc, { name, children }) => {
    if (name) {
      acc[name] = name;
      children?.forEach(({ name }) => {
        acc[name] = name;
      });
    }
    return acc;
  },
  {}
);

const platformSettingsRouterGuard = async () => {
  if (
    !store.getters[getterName.AUTH.IS_USER_AUTHENTICATED] &&
    !!localStorage.getItem(localStorageItem.ACCESS_TOKEN)
  ) {
    store.commit(
      mutationName.AUTH.SET_TOKEN,
      localStorage.getItem(localStorageItem.ACCESS_TOKEN)
    );
    localStorage.removeItem(localStorageItem.ACCESS_TOKEN);

    if (!store.getters[getterName.PLATFORM_SETTINGS.HAS_PLATFORM_SETTINGS]) {
      await store.dispatch(
        actionName.PLATFORM_SETTINGS.FETCH_PLATFORM_SETTINGS
      );
    }
  }
};

const checkAccessibilityRouteGard = ({ to = {}, router = {} } = {}) => {
  if (
    to?.meta?.requiresAuth &&
    !store.getters[getterName.AUTH.IS_USER_AUTHENTICATED]
  ) {
    router.replace(urls.LOGIN);
  }
};

const hasAccessToRoute = (routeName = "") =>
  routeAccessRules?.[routeName]?.some((routeRole) =>
    store.state.user.roles.some((userRole) => userRole.id === routeRole)
  ) || false;

const eb360RouteGard = ({
  to = {},
  router = {},
  _hasAccessToRoute = hasAccessToRoute
} = {}) => {
  if (
    !store.getters[getterName.USER.IS_USER_THIRD_PARTY] &&
    store.getters[getterName.AUTH.IS_USER_AUTHENTICATED] &&
    !_hasAccessToRoute(to.name)
  ) {
    router.replace(urls.PAGE_NOT_FOUND);
  }
};

const thirdPartyRouterGard = ({
  to = {},
  router = {},
  routes = routesNameAllowedToTp
} = {}) => {
  if (store.getters[getterName.USER.IS_USER_THIRD_PARTY]) {
    if (store.getters[getterName.USER.IS_USER_NOT_UPDATED]) {
      if (to.path !== urls.CREATE_PROFILE) {
        router.replace(urls.CREATE_PROFILE);
      }
    } else if (!routes[to.name]) {
      router.replace(urls.DASHBOARD);
    }
  }
};

const getUsersHomepage = async () => {
  const { companyId } = store.state.company;
  const { userId } = store.state.user;

  if (companyId && userId) {
    if (
      !store.getters[getterName.USER_SETTINGS.HAS_PLATFORM_PAGE_AND_PAGE_LIST]
    ) {
      await store.dispatch(actionName.USER_SETTINGS.GET_USER_SETTINGS, {
        companyId,
        userId
      });
    }

    const { accountHomePage, userHomePage } = store.state.userSettings;

    if (userHomePage?.value) {
      return { path: userHomePage.value };
    }

    if (accountHomePage?.value) {
      return { path: accountHomePage.value };
    }

    return { name: routeNames.MANAGE_THIRD_PARTY };
  } else {
    return { name: routeNames.LOGIN };
  }
};

export {
  getUsersHomepage,
  thirdPartyRouterGard,
  checkAccessibilityRouteGard,
  platformSettingsRouterGuard,
  hasAccessToRoute,
  eb360RouteGard
};
