<template>
  <div class="trigger-step">
    <Select
      class="trigger-step__action-to-trigger"
      data-test-id="trigger-step__action-to-trigger"
      label="Action to trigger"
      :name="`action-name-${id}`"
      :id="`action-name-${id}`"
      :options="actionNameOptions"
      :error="actionNameErrorMessage"
      placeholder="Select..."
      @change="
        $emit('change', {
          property: triggerStepKeys.ACTIONS,
          id,
          event: $event
        })
      "
    />
    <template v-if="isExpectDataListVisible">
      <BaseText
        class="trigger-step__expected-data"
        data-test-id="trigger-step__expected-data"
        text="Expected data for action"
      />
      <ul
        class="trigger-step__expected-data-list"
        data-test-id="trigger-step__expected-data-list"
      >
        <li
          v-for="(
            { name, options, type, searchValue, errorMessage }, index
          ) in expectDataList"
          :key="`${name}-${index}`"
          class="trigger-step__expected-data-list-element"
          data-test-id="trigger-step__expected-data-list-element"
        >
          <BaseText
            class="trigger-step__expected-data-list-element-text"
            data-test-id="trigger-step__expected-data-list-element-text"
          >
            <span class="trigger-step__expected-data-list-element--bold">
              {{ name }}
            </span>
            ({{ getExpectedDataTypesText(type) }})
          </BaseText>
          <SearchSelect
            class="trigger-step__expected-data-list-element-input"
            :label="name"
            isLabelHidden
            :name="toSnakeWrapper(name, id)"
            :id="toSnakeWrapper(name, id)"
            :data-test-id="`trigger-step__expected-data-name--${name}`"
            :options="options"
            :searchValue="searchValue"
            :error="errorMessage"
            placeholder="Select..."
            @change="
              $emit('change', {
                name,
                property: triggerStepKeys.EXPECTED_DATA_MAPPING,
                id,
                event: $event
              })
            "
            @reset="
              $emit('reset', {
                name,
                property: triggerStepKeys.EXPECTED_DATA_MAPPING,
                id,
                event: $event
              })
            "
            @create="
              $emit('create', {
                name,
                property: triggerStepKeys.EXPECTED_DATA_MAPPING,
                id,
                event: $event
              })
            "
          />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import Select from "@/molecules/Select/Select";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import { toSnake } from "@/utils";
import { triggerStepKeys, expectedDataTypesMapText } from "@/constants";

export default {
  name: "TriggerStep",
  components: {
    BaseText,
    Select,
    SearchSelect
  },
  props: {
    id: {
      type: Number,
      require: true
    },
    actionNameOptions: {
      type: Array,
      default: () => []
    },
    actionNameErrorMessage: {
      type: String,
      default: ""
    },
    expectDataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      triggerStepKeys
    };
  },
  computed: {
    isExpectDataListVisible() {
      return !!this.expectDataList.length;
    }
  },
  methods: {
    toSnakeWrapper(value, id) {
      return `trigger-step-expected-data-name-${toSnake(value)}-${id}`;
    },
    getExpectedDataTypesText(type) {
      return expectedDataTypesMapText[type] || type;
    }
  }
};
</script>

<style scoped lang="scss">
.trigger-step {
  &__expected-data-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &-element {
      display: flex;
      align-items: center;
      padding: 10px 0;

      &--bold {
        @include body-text-bold;
      }

      :deep(.trigger-step__expected-data-list-element-text) {
        width: 250px;
        margin: 0;
        padding: 0 20px 0 0;
      }
      :deep(.trigger-step__expected-data-list-element-input) {
        width: calc(100% - 250px);
      }
    }
  }

  &__action-to-trigger {
    display: block;
  }
}
</style>
