var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ongoing-monitoring-result"},[_c('b-card',{staticClass:"text-center ongoing-monitoring-result__card"},[_c('b-card-title',{staticClass:"ongoing-monitoring-result__title"},[_vm._v(" "+_vm._s(_vm.$t("eb360.analyticsTabChart.monitoringCard"))+" ")]),_c('b-tabs',{staticClass:"ongoing-monitoring-result__tabs",attrs:{"small":""}},[_c('b-tab',{staticClass:"mt-4",attrs:{"title":_vm.$t('eb360.analyticsTabChart.monitoringResults.thisMonth'),"disabled":_vm.headerConfigData && _vm.headerConfigData.autoremiddplusmoni == 0
            ? true
            : false}},[_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":_vm.totalNumberOfMonitoringAlertsThisMonth == 0
              ? 'success'
              : 'warning',"text-variant":"white","title":_vm.$t('eb360.analyticsTabChart.monitoringResults.momitoringAlerts')}},[_c('b-card-text',{staticClass:"text-success text-center ongoing-monitoring-result__tabs-card-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.totalNumberOfMonitoringAlertsThisMonth)+" ")])])],1),_c('b-card',{staticClass:"mt-2 text-center",attrs:{"bg-variant":_vm.totalNumberOfThirdPartiesAlertsThisMonth == 0
              ? 'success'
              : 'danger',"text-variant":"white","title":_vm.$t('eb360.analyticsTabChart.monitoringResults.thirdParyties')}},[_c('b-card-text',{staticClass:"text-success text-center ongoing-monitoring-result__tabs-card-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.totalNumberOfThirdPartiesAlertsThisMonth)+" ")])])],1)],1),_c('b-tab',{staticClass:"mt-4",attrs:{"title":_vm.$t('eb360.analyticsTabChart.monitoringResults.lastMonth'),"disabled":_vm.headerConfigData && _vm.headerConfigData.autoremiddplusmoni == 0
            ? true
            : false}},[_c('b-card',{staticClass:"text-center mt-2",attrs:{"bg-variant":_vm.totalNumberOfMonitoringAlertsLastMonth == 0
              ? 'success'
              : 'warning',"text-variant":"white","title":_vm.$t('eb360.analyticsTabChart.monitoringResults.momitoringAlerts')}},[_c('b-card-text',{staticClass:"text-success text-center ongoing-monitoring-result__tabs-card-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.totalNumberOfMonitoringAlertsLastMonth)+" ")])])],1),_c('b-card',{staticClass:"mt-2 text-center",attrs:{"bg-variant":_vm.totalNumberOfThirdPartiesAlertsLastMonth == 0
              ? 'success'
              : 'danger',"text-variant":"white","title":_vm.$t('eb360.analyticsTabChart.monitoringResults.thirdParyties')}},[_c('b-card-text',{staticClass:"text-success text-center ongoing-monitoring-result__tabs-card-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.totalNumberOfThirdPartiesAlertsLastMonth)+" ")])])],1)],1),_c('b-tab',{staticClass:"mt-4",attrs:{"title":_vm.$t('eb360.analyticsTabChart.monitoringResults.allTime'),"disabled":_vm.headerConfigData && _vm.headerConfigData.autoremiddplusmoni == 0
            ? true
            : false}},[_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":_vm.totalNumberOfMonitoringAlertsAllTime == 0 ? 'success' : 'warning',"text-variant":"white","title":_vm.$t('eb360.analyticsTabChart.monitoringResults.momitoringAlerts')}},[_c('b-card-text',{staticClass:"text-success text-center ongoing-monitoring-result__tabs-card-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.totalNumberOfMonitoringAlertsAllTime)+" ")])])],1),_c('b-card',{staticClass:"mt-2 text-center",attrs:{"bg-variant":_vm.totalNumberOfThirdPartiesAlertsAllTime == 0 ? 'success' : 'danger',"text-variant":"white","title":_vm.$t('eb360.analyticsTabChart.monitoringResults.thirdParyties')}},[_c('b-card-text',{staticClass:"text-success text-center ongoing-monitoring-result__tabs-card-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.totalNumberOfThirdPartiesAlertsAllTime)+" ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }