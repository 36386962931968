import { state as makeState } from "./platformSettings.state.store";

export const mutations = {
  setPlatformLogo(state, payload) {
    state.platformLogo = payload;
  },
  setPlatformLogoFallback(state, payload) {
    state.platformLogoFallback = payload;
  },
  setTermsAndConditionsLink(state, payload) {
    state.platformTermsAndConditionsLink = payload;
  },
  setPrivacyPolicyLink(state, payload) {
    state.platformPrivacyPolicyLink = payload;
  },
  setDisablePrivacyPolicy(state, payload) {
    state.disablePrivacyPolicy = payload;
  },
  setPlatformPrimaryColor(state, payload) {
    state.platformPrimaryColor = payload;
  },
  setPlatformSecondaryColor(state, payload) {
    state.platformSecondaryColor = payload;
  },
  setSSOURL(state, payload) {
    state.platformSSOURL = payload;
  },
  setPlatformBusinessAddress(state, payload) {
    state.platformBusinessAddress = payload;
  },
  setPlatformCopyrightNotice(state, payload) {
    state.platformCopyrightNotice = payload;
  },
  setPlatformFaqLink(state, payload) {
    state.platformFaqLink = payload;
  },
  setPlatformFavIcon(state, payload) {
    state.platformFavIcon = payload;
  },
  setPlatformKnowledgeCentreLink(state, payload) {
    state.platformKnowledgeCentreLink = payload;
  },
  setPlatformLegalDisclaimer(state, payload) {
    state.platformLegalDisclaimer = payload;
  },
  setPlatformMainHeaderLogo(state, payload) {
    state.platformMainHeaderLogo = payload;
  },
  setPlatformName(state, payload) {
    state.platformName = payload;
  },
  setPlatformRegisteredBusinessName(state, payload) {
    state.platformRegisteredBusinessName = payload;
  },
  setPlatformReleaseNoteLink(state, payload) {
    state.platformReleaseNoteLink = payload;
  },
  setPlatformRequestSupportLink(state, payload) {
    state.platformRequestSupportLink = payload;
  },
  setPlatformSupportEmail(state, payload) {
    state.platformSupportEmail = payload;
  },
  setPlatformWebUrl(state, payload) {
    state.platformWebUrl = payload;
  },
  setPlatformUserList(state, payload) {
    state.platformUserList = payload;
  },
  setIsLoadingPlatformSettings(state, value) {
    state.isLoadingPlatformSettings = value;
  },
  resetPlatformSettings(state) {
    Object.assign(state, makeState());
  },
  setProductVersionsList(state, payload) {
    state.productVersions = payload;
  }
};
