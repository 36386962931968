import { companyService } from "@/services";
import { mutationName, getterName, httpStatuses } from "@/constants";
import { hasStatus200 } from "@/utils";

export const actions = {
  async fetchCompanies({ rootState, commit }) {
    const response = await companyService.fetchCompanies(rootState.user.userId);
    if (
      response.data.fakeHttpStatus === httpStatuses.SUCCESS &&
      hasStatus200(response)
    ) {
      commit(mutationName.COMPANY.SET_COMPANY_LIST, response.data.companyList, {
        root: true
      });
    }
    return response;
  },
  async selectCompany({ rootGetters, rootState, commit }, selectedCompanyId) {
    const response = await companyService.selectCompany({
      company_id: selectedCompanyId,
      member_id: rootState.user.userId
    });
    commit(mutationName.COMPANY.SET_COMPANY_ID, response.data.companyId, {
      root: true
    });
    commit(mutationName.USER.SET_ROLES, response.data.roles, {
      root: true
    });
    commit(mutationName.USER.SET_PERMISSIONS, response.data.permissions, {
      root: true
    });
    commit(
      mutationName.COMPANY.SET_COMPANY_NAME,
      rootGetters[getterName.USER.GET_COMPANY_NAME],
      {
        root: true
      }
    );
    return response;
  },
  async fetchCompanyUsers({ rootState, commit }, payload) {
    const { userId } = rootState.user;
    const { companyId } = rootState.company;
    const response = await companyService.fetchCompanyUsers({
      ...payload,
      companyId,
      userId
    });
    commit(mutationName.COMPANY.SET_COMPANY_USERS, response.data.companyUsers, {
      root: true
    });
    return response;
  },
  async updateCompanyUser({ rootState }, payload) {
    const { userId } = rootState.user;
    const { companyId } = rootState.company;
    await companyService.updateCompanyUser({
      ...payload,
      companyId,
      userId
    });
  },
  async addCompanyUser({ rootState }, payload) {
    const { userId } = rootState.user;
    const { companyId } = rootState.company;
    await companyService.addCompanyUser({
      ...payload,
      companyId,
      userId
    });
  },
  deleteCompanyUser({ rootState }, payload) {
    const { userId } = rootState.user;
    const { companyId } = rootState.company;
    return companyService.deleteCompanyUser({
      ...payload,
      companyId,
      userId
    });
  },
  async fetchAdditionalVouchers({ commit, rootState }, customCompanyId) {
    const { companyId } = rootState.company;
    const result = await companyService.fetchAdditionalVouchers(
      customCompanyId || companyId
    );
    const { data = {}, status } = result;

    if (data?.fakeHttpStatus === 1 && status === 200) {
      commit(
        mutationName.COMPANY.SET_REMAINING_VOUCHERS,
        data.remainingVouchers,
        {
          root: true
        }
      );
      if (data.companyDetails.companyViews.length === 0) {
        commit(mutationName.NO_NAME_SPACE.SET_SIDE_BAR_MENU, [], {
          root: true
        });
      }
      commit(
        mutationName.COMPANY.SET_COMPANY_VIEWS,
        data.companyDetails.companyViews,
        {
          root: true
        }
      );
      commit(mutationName.COMPANY.SET_COMPANY_DETAILS, data.companyDetails, {
        root: true
      });

      return result;
    }
  }
};
