<template>
  <b-list-group-item class="questionnaire-template">
    <h5
      class="questionnaire-template__section-title"
      data-test-id="questionnaire-template__section-title"
      :id="sectionTitle.replace(/ /g, '-').toLowerCase()"
    >
      {{ sectionIndex + 1 }}. {{ sectionTitle }}
    </h5>
    <div
      v-for="(field, fieldIndex) in filterFieldElements(sectionFields)"
      :key="`fieldIndex-${fieldIndex}`"
      class="questionnaire-template__question"
      data-test-id="questionnaire-template__question"
    >
      <p
        class="questionnaire-template__question-title"
        data-test-id="questionnaire-template__question-title"
      >
        {{ `${sectionIndex + 1}.${field.elementIndex + 1}` }}
        {{ field.elementTitle[formDefaultLanguage] }}
      </p>

      <p v-if="parseInt(field.isWorkFlowNa) === 1">N/A</p>

      <template v-if="!specialInputFields.includes(field.elementInputType)">
        <div
          v-for="(element, elementIndex) in field.elements"
          :key="`element-index-${elementIndex}`"
          class="questionnaire-template__element-value"
          data-test-id="questionnaire-template__element-value"
        >
          <template v-if="isDetailListField(field.elementInputType)">
            <h6>
              {{ getAddressLabel(field, elementIndex) }}
            </h6>
            <p>
              <span
                v-for="(line, lineIndex) in filterLines(element)"
                :key="`line-index-${lineIndex}`"
              >
                <span class="questionnaire-template__element-category"
                  >{{ getLabel(line.label) }}:</span
                >
                {{ getPostValue(line.postValue) }}<br />
              </span>
            </p>
          </template>
          <template v-else-if="field.elementInputType === 'countries'">
            <p v-if="Object.keys(element.postValue[0].country).length > 1">
              <span
                v-for="({ country = {} }, countryIndex) in element.postValue"
                :key="`country-index-${countryIndex}`"
              >
                {{ country[formDefaultLanguage] }}
              </span>
            </p>
            <p v-else>
              <span
                v-for="(country, countryIndex) in element.postValue"
                :key="`country-index-${countryIndex}`"
              >
                {{ country.name }}
              </span>
            </p>
          </template>
          <template v-else-if="field.elementInputType === 'cpi-countries'">
            <p>
              <span
                v-for="({ country_name }, countryIndex) in element.postValue"
                :key="`cpi-country-index-${countryIndex}`"
              >
                {{ country_name }}<br />
              </span>
            </p>
          </template>
          <template
            v-else-if="field.elementInputType === 'comboSelectProgressive'"
          >
            <p>
              <template v-for="(selectValue, selectKey) in element.postValue">
                <template v-if="element.labels[`${selectKey}Label`]">
                  <span
                    v-for="({ text }, textIndex) in selectValue"
                    :key="`text-index-${textIndex}`"
                  >
                    <span class="questionnaire-template__element-category"
                      >{{
                        element.labels[`${selectKey}Label`][
                          formDefaultLanguage
                        ]
                      }}:</span
                    >
                    {{ text }} <br />
                  </span>
                </template>
              </template>
            </p>
          </template>

          <template v-else-if="field.elementInputType === 'radio'">
            <a
              v-if="field.hyperLinkUrl?.[formDefaultLanguage]"
              :href="field.hyperLinkUrl?.[formDefaultLanguage]"
              target="_blank"
              >{{ field.hyperLinkText?.[formDefaultLanguage] || "" }}</a
            >
            <p>{{ element.postValue?.text?.[formDefaultLanguage] || "" }}</p>
            <div v-if="element?.key === 'progressive'" class="ml-2">
              <b>{{
                element?.postNameProgressive?.[formDefaultLanguage] || ""
              }}</b>
              <p>{{ element?.postValue || "" }}</p>
            </div>
          </template>

          <template v-else-if="field.elementInputType === 'select'">
            <p>
              <span
                v-for="(answer, answerIndex) in element.postValue"
                :key="`answer-index-${answerIndex}`"
              >
                {{ answer.value || answer }}<br />
              </span>
            </p>
          </template>

          <template v-else-if="field.elementInputType === 'checkbox'">
            <p>
              <template v-for="{ text } in element.postValue">
                {{ text?.[formDefaultLanguage] }}
              </template>
            </p>
            <div v-if="element?.key === 'progressive'" class="ml-2">
              <b>{{
                element?.postNameProgressive?.[formDefaultLanguage] || ""
              }}</b>
              <p>{{ element?.postValue || "" }}</p>
            </div>
          </template>

          <template v-else-if="field.elementInputType === 'phone'">
            <p>
              {{ `${element.postValue.code}-${element.postValue.phone}` }}
            </p>
          </template>
          <template v-else-if="field.elementInputType === 'date'">
            <p>
              {{ getDate(element.postValue) }}
            </p>
          </template>

          <template v-else-if="field.elementInputType === 'ulli'">
            <component
              :is="field.selectedListType === 2 ? 'ol' : 'ul'"
              :style="getListStyle(field)"
            >
              <li v-for="(item, index) in element.postValue" :key="index">
                {{ item?.message?.[formDefaultLanguage] }}
              </li>
            </component>
          </template>
          <template v-else>
            <p>{{ element?.postValue }}</p>
          </template>
        </div>
      </template>
      <template v-else-if="field.elementInputType === 'file'">
        <div v-if="!field.elements.length">
          <p>N/A</p>
        </div>
        <div
          v-else
          v-for="(element, elementIndex) in field.elements"
          :key="elementIndex"
        >
          <table class="questionnaire-template__table--file">
            <tr>
              <th>{{ fileName }}</th>
              <th>{{ fileSize }}</th>
              <th>{{ fileType }}</th>
            </tr>
            <tr
              v-for="{ filename, size, type, index } in element.postValue"
              :key="index"
            >
              <td>{{ filename }}</td>
              <td>{{ size }}</td>
              <td>{{ type }}</td>
            </tr>
          </table>
        </div>
      </template>
      <template v-else-if="field.elementInputType === 'downloadPDF'">
        <div
          class="questionnaire-template__element-value"
          data-test-id="questionnaire-template__element-value"
        >
          <input
            :id="field.elements[1].key"
            type="checkbox"
            disabled
            :checked="!!field.elements[0].postValue"
          />
          <label
            class="questionnaire-template__element-value--label"
            for="field.elements[1].key"
            >{{ field.elements[1].postValue }}</label
          >
        </div>
      </template>
      <template v-else-if="field.elementInputType === 'signature'">
        <p
          class="questionnaire-template__element-value"
          data-test-id="questionnaire-template__element-value"
        >
          <span
            v-for="(question, questionIndex) in getSignatureSectionQuestions(
              field.elements[0]
            )"
            :key="`question-index-${questionIndex}`"
          >
            <template v-if="question.key === 'signatureValue'">
              <img
                class="questionnaire-template__element-value--signature"
                :src="question.postValue"
                alt="signature"
              />
            </template>
            <template v-else>
              <template v-if="question.key === 'acceptTermsText'">
                {{ question.label[formDefaultLanguage] || "" }}
              </template>
              <span
                v-else-if="question.key !== 'informationText'"
                class="questionnaire-template__element-category"
                >{{
                  question.label[formDefaultLanguage]
                    ? `${question.label[formDefaultLanguage]}: `
                    : ""
                }}</span
              >
              <template v-if="Array.isArray(getSignatureTextValue(question))">
                <div
                  v-for="(text, textIndex) in getSignatureTextValue(question)"
                  :key="`text-index-${textIndex}`"
                >
                  {{ text }}<br /><br />
                </div>
              </template>
              <template v-else-if="getSignatureTextValue(question)">
                {{ getSignatureTextValue(question) }}<br />
              </template>
            </template>
          </span>
        </p>
      </template>
    </div>
  </b-list-group-item>
</template>

<script>
import moment from "moment";
import { isObject } from "@/utils";
import { isISODate } from "@/utils/date/date.utils";

export default {
  name: "QuestionnaireTemplate",
  props: {
    sectionFields: {
      type: Array,
      default: () => []
    },
    sectionTitle: {
      type: String,
      default: ""
    },
    sectionIndex: {
      type: Number,
      default: 0
    },
    taskType: {
      type: Number,
      default: 0
    },
    formDefaultLanguage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      index: 1,
      fileName: "",
      fileSize: 0,
      fileType: "",
      fallbackFormLanguage: "english",
      specialInputFields: ["downloadPDF", "signature", "file"],
      allowedElements: ["paragraph"],
      detailListFields: [
        "address-corporate",
        "address-personal",
        "personal-details",
        "individual-shareholders",
        "trade-reference",
        "company-officers",
        "corporate-shareholders",
        "principal-contact"
      ]
    };
  },
  created() {
    this.getFilesOptions();
  },
  methods: {
    getFilesOptions() {
      if (this.formDefaultLanguage === "hebrew") {
        this.fileName = "שם קובץ";
        this.fileSize = "גודל קובץ (בייטים)";
        this.fileType = "סוג קובץ";
      } else if (this.formDefaultLanguage === "spanish") {
        this.fileName = "Nombre de archivo";
        this.fileType = "Tipo de archivo";
        this.fileSize = "Tamaño del archivo (bytes)";
      } else if (this.formDefaultLanguage === "portuguese") {
        this.fileName = "Nome do Ficheiro";
        this.fileType = "Tipo de Ficheiro";
        this.fileSize = "Tamanho do Ficheiro (bytes)";
      } else if (this.formDefaultLanguage === "dutch") {
        this.fileName = "Bestandsnaam";
        this.fileType = "Bestandstype";
        this.fileSize = "Bestandsgrootte (bytes)";
      } else if (this.formDefaultLanguage === "italian") {
        this.fileName = "Nome del file";
        this.fileType = "Tipo di file";
        this.fileSize = "Dimensione file (byte)";
      } else if (this.formDefaultLanguage === "french") {
        this.fileName = "Nom du fichier";
        this.fileType = "Type de fichier";
        this.fileSize = "Taille du fichier (octets)";
      } else if (this.formDefaultLanguage === "german") {
        this.fileName = "Dateiname";
        this.fileType = "Dateityp";
        this.fileSize = "Dateigröße (Bytes)";
      } else if (this.formDefaultLanguage === "japanese") {
        this.fileName = "ファイル名";
        this.fileType = "ファイルタイプ";
        this.fileSize = "ファイルサイズ（バイト）";
      } else if (this.formDefaultLanguage === "turkish") {
        this.fileName = "Dosya Adı";
        this.fileType = "Dosya Tipi";
        this.fileSize = "Dosya Ebatı (bayt)";
      } else if (this.formDefaultLanguage === "chinese simplified") {
        this.fileName = "文件名称";
        this.fileType = "文件类型";
        this.fileSize = "文件大小(bytes)";
      } else if (this.formDefaultLanguage === "russian") {
        this.fileName = "Имя Файла";
        this.fileType = "Тип Файла";
        this.fileSize = "Размер Файла (байты)";
      } else if (this.formDefaultLanguage === "hindi") {
        this.fileName = "फ़ाइल का नाम";
        this.fileType = "फाइल का प्रकार";
        this.fileSize = "फ़ाइल का आकार (बाइट्स)";
      } else if (this.formDefaultLanguage === "tamil") {
        this.fileName = "பைல் பெயர்";
        this.fileType = "பைல் வகை";
        this.fileSize = "பைல் அளவு (பைட்கள்)";
      } else if (this.formDefaultLanguage === "telugu") {
        this.fileName = "ఫైల్ పేరు	";
        this.fileType = "ఫైల్ రకము	";
        this.fileSize = "ఫైల్ సైజ్ (బైట్లు)	";
      } else if (this.formDefaultLanguage === "kannada") {
        this.fileName = "ಫೈಲ್ ಹೆಸರು	";
        this.fileType = "ಫೈಲ್ ವರ್ಗ	";
        this.fileSize = "ಫೈಲ್ ಗಾತ್ರ (ಬೈಟ್‌ಗಳು)	";
      } else if (this.formDefaultLanguage === "marathi") {
        this.fileName = "फाईलचे नाव";
        this.fileType = "फाईलचा प्रकार";
        this.fileSize = "फाईलचा आकार (बाईट्स)";
      } else if (this.formDefaultLanguage === "gujarati") {
        this.fileName = "ફાઇલનું નામ";
        this.fileType = "ફાઇલનો પ્રકાર";
        this.fileSize = "ફાઇલની કદ (બાઈટ્સ)";
      } else if (this.formDefaultLanguage === "thai") {
        this.fileName = "ชื่อไฟล์เอกสาร";
        this.fileType = "ประเภทของไฟล์เอกสาร";
        this.fileSize = "ขนาดของไฟล์เอกสาร (ไบทส์)";
      } else if (this.formDefaultLanguage === "vietnamese") {
        this.fileName = "Đặt tên cho tập tin";
        this.fileType = "Loại tệp";
        this.fileSize = "Kích cỡ tệp (byte)";
      } else if (this.formDefaultLanguage === "polish") {
        this.fileName = "Nazwa pliku";
        this.fileType = "Typ pliku";
        this.fileSize = "Rozmiar pliku (w bajtach)";
      } else if (this.formDefaultLanguage === "ukrainian") {
        this.fileName = "Назва файлу";
        this.fileType = "Тип файлу";
        this.fileSize = "Розмір файлу (байти)";
      } else if (this.formDefaultLanguage === "romanian") {
        this.fileName = "Nume Fișier";
        this.fileType = "Tip";
        this.fileSize = "Dimensiune (biți)";
      } else {
        this.fileName = "File Name";
        this.fileSize = "File Size (bytes)";
        this.fileType = "File Type";
      }
    },
    getDate(date) {
      moment.locale("en");
      return moment(date).format("DD-MM-YYYY");
    },
    filterLines(elements) {
      return elements.filter((line) => line.postValue);
    },
    filterFieldElements(field) {
      return field.filter(
        (element) =>
          !element.isHiddenElement && element.elementInputType !== "hr"
      );
    },
    getAddressLabel(field, elementIndex) {
      if (parseInt(field.isWorkFlowNa) === 1) {
        return;
      }
      if (elementIndex === 0) {
        return (
          field?.registeredLabel?.[this.formDefaultLanguage] ||
          field?.registeredLabel
        );
      } else if (elementIndex === 1) {
        return (
          field?.tradingLabel?.[this.formDefaultLanguage] || field?.tradingLabel
        );
      }
    },
    getSignatureSectionQuestions(signatureQuestions) {
      return signatureQuestions.filter((question) => question.postValue);
    },
    getSignatureText(signatureText) {
      const splitText = signatureText?.replace(
        /(<p([^>]*)>|<br\s*\/?>)/gi,
        "(breakHere)"
      );
      const parsedText = splitText?.replace(/(<([^>]+)>|&nbsp;)/gi, "");

      return parsedText?.split("(breakHere)");
    },
    getSignatureTextValue(question) {
      if (question.key !== "acceptTermsText") {
        if (question.key === "dateValue") {
          moment.locale("en");
          return moment(question.postValue).format("DD MMM YYYY");
        }

        if (question.key === "informationText") {
          return this.getSignatureText(
            question.postValue?.[this.formDefaultLanguage]
          );
        }

        return (
          question.postValue?.[this.formDefaultLanguage] || question.postValue
        );
      }
    },
    isDetailListField(fieldType) {
      return this.detailListFields.includes(fieldType);
    },
    getPostValue(postValue) {
      if (isObject(postValue)) {
        return this.getPostValue(postValue.text || postValue.name);
      }

      if (isISODate(postValue)) {
        return this.getDate(postValue);
      }

      return postValue;
    },
    getLabel(label) {
      if (isObject(label)) {
        return label[this.formDefaultLanguage];
      }

      return label;
    },
    getListStyle(field) {
      if (field.selectedListType !== 2) {
        switch (field.selectedListStyle) {
          case "a":
            return { listStyleType: "decimal-leading-zero" };
          case "b":
            return { listStyleType: "upper-alpha" };
          case "c":
            return { listStyleType: "circle" };
          case "d":
            return { listStyleType: "square" };
          default:
            return {};
        }
      }
      return {};
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables/colors.variable.scss";

.questionnaire-template {
  border-bottom: 1px solid $spanish-gray;

  &__question {
    margin-bottom: 30px;
    border-bottom: 1px solid $chinese-silver;

    &:last-child {
      border-bottom: none;
    }

    &-title {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  &__element {
    &-value {
      font-weight: 400;

      &--label {
        padding-left: 10px;
      }

      &--signature {
        width: 100%;
        height: auto;
        margin: 20px 0;
      }

      &--list {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $chinese-silver;

        &:last-child {
          border: none;
        }
      }
    }

    &-category {
      font-weight: 700;
    }
  }

  &__table--file {
    width: 100%;
    text-align: center;
    td {
      padding: 5px;
    }
  }
}
</style>
