import { themes, typographySize } from "@/constants/design.constant";

const riskIcons = {
  NOT_RUN: {
    iconOptions: {
      shape: "square",
      border: "dashed",
      theme: themes.GREY,
      size: 20
    },
    textOptions: {
      tag: "p",
      text: "Not run",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  IN_PROGRESS: {
    iconOptions: {
      icon: "history",
      size: 28
    },
    textOptions: {
      tag: "p",
      text: "In progress",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NO_RISK_ALERT: {
    iconOptions: {
      icon: "check",
      shape: "circle",
      theme: themes.SUCCESS,
      size: 16
    },
    textOptions: {
      tag: "p",
      text: "No risk alert",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  RISK_ALERT: {
    iconOptions: {
      icon: "exclamation",
      shape: "triangle",
      theme: themes.ERROR,
      size: 16
    },
    textOptions: {
      tag: "p",
      text: "High risk",
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  NO_RISK_ALERT_REMEDIATED: {
    iconOptions: {
      icon: "check",
      shape: "circle",
      theme: themes.SUCCESS,
      size: 16,
      overlayText: "R"
    },
    textOptions: {
      tag: "p",
      text: ["No risk alert", "(Remediated)"],
      size: typographySize.BODY_TEXT_SMALL
    }
  },
  RISK_ALERT_REMEDIATED: {
    iconOptions: {
      icon: "exclamation",
      shape: "triangle",
      theme: themes.ERROR,
      size: 16,
      overlayText: "R"
    },
    textOptions: {
      tag: "p",
      text: ["Risk alert", "(Remediated)"],
      size: typographySize.BODY_TEXT_SMALL
    }
  }
};

export { riskIcons };
