import { getSelectedOption, makeUUID } from "@/utils";

export class SetToBlockClass {
  id = makeUUID();

  valueDataTypeOptions = [];

  valueDataType = "";

  valueDataTypeText = "";

  valueDataTypeErrorMessage = "";

  isReadOnly = false;

  isMiddleSelectHidden = false;

  valueTypeOptions = [];

  valueTypeErrorMessage = "";

  component = {};

  componentOptions = {};

  componentValue = "";

  isHidden = true;

  constructor({
    valueDataTypeOptions = [],
    valueDataType = "",
    isReadOnly = false,
    isMiddleSelectHidden = false,
    valueTypeOptions = [],
    component = {},
    componentOptions = {},
    valueDataTypeText = "",
    componentValue,
    isHidden = false,
    valueDataTypeErrorMessage = "",
    valueTypeErrorMessage = ""
  } = {}) {
    this.valueDataTypeOptions = valueDataTypeOptions;
    this.valueDataType = valueDataType;
    this.isReadOnly = isReadOnly;
    this.isMiddleSelectHidden = isMiddleSelectHidden;
    this.valueTypeOptions = valueTypeOptions;
    this.component = component;
    this.componentOptions = componentOptions;
    this.valueDataTypeText = valueDataTypeText;
    this.componentValue = componentValue;
    this.isHidden = isHidden;
    this.setValueDataTypeErrorMessage(valueDataTypeErrorMessage);
    this.setValueTypeErrorMessage(valueTypeErrorMessage);
  }

  setValueDataTypeOptions(valueDataTypeOptions) {
    this.valueDataTypeOptions = valueDataTypeOptions;
  }

  setValueDataType(valueDataType) {
    this.valueDataType = valueDataType;
  }

  setValueDataTypeText(valueDataTypeText) {
    this.valueDataTypeText = valueDataTypeText;
  }

  setIsReadOnly(isReadOnly) {
    this.isReadOnly = isReadOnly;
  }

  setHideNewExisting(isMiddleSelectHidden) {
    this.isMiddleSelectHidden = isMiddleSelectHidden;
  }

  setValueTypeOptions(valueTypeOptions) {
    this.valueTypeOptions = valueTypeOptions;
  }

  setComponent(component) {
    this.component = component;
  }

  setComponentOptions(componentOptions) {
    this.componentOptions = componentOptions;
  }

  setComponentValue(componentValue) {
    this.componentValue = componentValue;
  }

  setIsHidden(isHidden) {
    this.isHidden = isHidden;
  }

  setValueDataTypeErrorMessage(errorMessage) {
    this.valueDataTypeErrorMessage = errorMessage;
  }

  setValueTypeErrorMessage(errorMessage) {
    this.valueTypeErrorMessage = errorMessage;
  }

  getSelectedValueTypeOption() {
    return getSelectedOption(this.valueTypeOptions);
  }
}
