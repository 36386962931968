export const state = () => ({
  tCertificationOrderTypes: null,
  ESGMetricsOrderTypes: null,
  formsLanguagesList: [],
  isThirdPartyAddModalVisible: false,
  isAddThirdPartyDnbModalVisible: false,
  isAddThirdPartyModalNewVisible: false,
  thirdPartyModalData: null,
  OCStatesList: [],
  headerConfigData: {},
  EDDClientAllPackages: [],
  customAttributes: [],
  isUBODataLoading: false,
  thirdPartyUBOList: [],
  thirdPartyUBOError: "",
  isCreatingThirdPartySuccessful: false,
  createThirdPartyError: false,
  isCreatingThirdParty: false
});
