import { actionStepType } from "@/constants";
import { getSelectedOption, makeUUID } from "@/utils";

export class TriggerStepClass {
  id = makeUUID();

  stepType = actionStepType.TRIGGER;

  comment = "";

  hasComment = false;

  actionNameOptions = [];

  expectDataList = [];

  actionNameErrorMessage = "";

  constructor({
    actionNameOptions = [],
    expectDataList = [],
    actionNameErrorMessage = "",
    comment = "",
    hasComment = false
  }) {
    this.setActionNameOptions(actionNameOptions);
    this.setExpectDataList(expectDataList);
    this.setActionNameErrorMessage(actionNameErrorMessage);
    this.comment = comment;
    this.hasComment = hasComment;
  }

  setActionNameOptions(actionNameOptions) {
    this.actionNameOptions = actionNameOptions;
  }

  setExpectDataList(expectDataList) {
    this.expectDataList = expectDataList;
  }

  setActionNameErrorMessage(actionNameErrorMessage) {
    this.actionNameErrorMessage = actionNameErrorMessage;
  }

  getSelectedActionOption() {
    return getSelectedOption(this.actionNameOptions);
  }

  setExpectedDataOptionByIndex(option, index) {
    this.expectDataList.splice(index, 1, option);
  }
}
