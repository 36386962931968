import { breadcrumbRouteNames, routeNames, templates, urls } from "@/constants";
import ThirdPartyEntityProfileTemplate from "@/templates/UBOProfileTemplate/UBOProfileTemplate.vue";
import store from "@/store";

export default [
  {
    path: urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE(),
    name: routeNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE,
    component: ThirdPartyEntityProfileTemplate,
    meta: {
      requiresAuth: true,
      template: templates.EB360,
      isEb360HeaderRequired: true,
      isEB360SideBarRequired: true,
      breadcrumbs: (route) => [
        { name: breadcrumbRouteNames.HOME, link: urls.HOME },
        {
          name: breadcrumbRouteNames.THIRD_PARTY_PROFILE,
          link: urls.THIRD_PARTY_PROFILE_COMPANY(
            route.params.thirdPartyId,
            store.state.company.companyId
          )
        },
        {
          name: breadcrumbRouteNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE
        }
      ]
    }
  }
];
