<!-- eslint-disable vue-a11y/no-onchange -->
<template>
  <div class="per-page-pagination" data-test-id="per-page-pagination">
    <div class="per-page-pagination__display-count">
      <BaseText
        class="per-page-pagination__display-count--highlighted"
        data-test-id="per-page-pagination__display-count--highlighted"
        :text="getText"
      />
    </div>
    <div class="per-page-pagination__pagination">
      <Select
        id="results-per-page"
        label="Results per page"
        name="pagination-results"
        data-test-id="per-page-pagination__per-page"
        :isRequired="false"
        :options="getOptions"
        @change="$emit('change-items', $event)"
      />
      <BasePagination
        :items-per-page="itemsPerPage"
        :current-page="currentPage"
        :total-items="totalItems"
        :page-range="pageRange"
        @change="$emit('change-current-page', $event)"
      />
    </div>
  </div>
</template>

<script>
import BasePagination from "@/atoms/BasePagination/BasePagination";
import BaseText from "@/atoms/BaseText/BaseText";
import Select from "@/molecules/Select/Select";
import { orderBy } from "lodash";
import { sortOrder } from "@/constants";

export default {
  name: "PerPagePagination",
  components: { BaseText, BasePagination, Select },
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalItems: {
      type: Number,
      default: 10
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    pageRange: {
      type: Number,
      default: 3
    },
    options: {
      type: Array,
      default: () => [20, 50, 100]
    }
  },
  data() {
    return {
      optionKeys: {
        VALUE: "value",
        TEXT: "text",
        SELECTED: "selected"
      }
    };
  },
  computed: {
    getFirstItem() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    getLastItem() {
      return Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
    },
    getText() {
      return [
        "Showing items",
        this.getFirstItem,
        "to",
        this.getLastItem,
        "of",
        this.totalItems
      ];
    },
    makeInitOptions() {
      return this.options.map((itemsPerPage) => ({
        [this.optionKeys.VALUE]: itemsPerPage,
        [this.optionKeys.TEXT]: itemsPerPage
      }));
    },
    getOptions() {
      let tempOptions = this.makeInitOptions;
      if (!tempOptions.find(({ value }) => value === this.itemsPerPage)) {
        tempOptions.push({
          [this.optionKeys.VALUE]: this.itemsPerPage,
          [this.optionKeys.TEXT]: this.itemsPerPage
        });
      }
      tempOptions = orderBy(
        tempOptions,
        this.optionKeys.VALUE,
        sortOrder.ASCENDING
      );
      return tempOptions.map((currentOption) => ({
        ...currentOption,
        ...(currentOption.value === this.itemsPerPage && {
          [this.optionKeys.SELECTED]: true
        })
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-pagination {
  &:deep(.per-page-pagination__display-count--highlighted) {
    text-indent: 5px;
  }

  &:deep(.per-page-pagination__display-count--highlighted:nth-child(even)) {
    @include body-text-bold;
  }

  &:deep &__display-count > .base-text__wrapper {
    @include body-text;
    display: flex;
    justify-content: center;
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
