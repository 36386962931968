import { actionsService } from "@/services";
import { omit } from "lodash";
import {
  actionKeys,
  actionName,
  actionKeysApi,
  mutationName
} from "@/constants";

export const actions = {
  async fetchActions({ commit, rootState }, payload) {
    try {
      const { data = {} } = await actionsService.fetchActions(
        rootState.company.companyId,
        payload
      );
      commit(mutationName.ACTIONS.SET_ACTIONS, data, { root: true });
      return data;
    } catch (error) {
      commit(mutationName.ACTIONS.SET_ACTIONS, [], { root: true });
      throw error;
    }
  },
  async fetchAction({ commit, rootState }, id) {
    try {
      const { data = {} } = await actionsService.fetchAction(
        rootState.company.companyId,
        id
      );
      commit(mutationName.ACTIONS.SET_ACTION, data, { root: true });
      return data;
    } catch (error) {
      commit(mutationName.ACTIONS.SET_ACTION, {}, { root: true });
      throw error;
    }
  },
  async saveAction({ rootState, dispatch }, payload) {
    await actionsService.saveAction(rootState.company.companyId, payload);
    await dispatch(
      actionName.ACTIONS.FETCH_ACTIONS,
      {
        excluded_fields: [actionKeysApi.FUNCTION_BODY],
        with_options: true
      },
      { root: true }
    );
  },
  async amendAction({ rootState, dispatch }, payload) {
    await actionsService.amendAction(
      rootState.company.companyId,
      payload[actionKeys.ID],
      omit(payload, [actionKeys.ID])
    );
    await dispatch(
      actionName.ACTIONS.FETCH_ACTIONS,
      {
        excluded_fields: [actionKeysApi.FUNCTION_BODY],
        with_options: true
      },
      { root: true }
    );
  },
  async deleteAction({ rootState, dispatch }, actionId) {
    await actionsService.deleteAction(rootState.company.companyId, actionId);
    await dispatch(actionName.ACTIONS.FETCH_ACTIONS, null, { root: true });
  },
  async cloneAction({ rootState, dispatch }, actionId) {
    await actionsService.cloneAction(rootState.company.companyId, actionId);
    await dispatch(actionName.ACTIONS.FETCH_ACTIONS, null, { root: true });
  },
  async fetchExpectedDataEntities({ rootState, commit }) {
    const { data = {} } = await actionsService.fetchExpectedDataEntities(
      rootState.company.companyId
    );
    commit(mutationName.ACTIONS.SET_EXPECTED_DATA_ENTITIES, data, {
      root: true
    });
  },
  async fetchEntitiesDynamicReference({ commit, state }, id) {
    let entityIndex;
    const { referenceUrl } = state.expectedDataEntities.find(
      (expectedDataEntity, index) => {
        entityIndex = index;
        return expectedDataEntity?.context?.formId === id;
      }
    );
    const { data = {} } = await actionsService.fetchEntitiesDynamicReference(
      referenceUrl
    );
    commit(
      mutationName.ACTIONS.SET_ENTITY_REFERENCES,
      {
        references: data,
        index: entityIndex
      },
      {
        root: true
      }
    );
    return entityIndex;
  }
};
