<template>
  <div class="financial-information">
    <div v-if="isDnbDisabled" class="financial-information__disable-text">
      <BaseText
        text="To enable please upgrade to Premium Corporate Data."
        data-test-id="financial-information__disable-text--dnb-disabled"
        :size="typographySize.BODY_TEXT_ITALIC"
      />
    </div>
    <div v-else-if="!isDnbVerified" class="financial-information__disable-text">
      <BaseText
        text="Premium Corporate Data is enabled on your system but hasn't been used to verify this Third Party so there is no additional data available."
        data-test-id="financial-information__disable-text--dnb-not-verified"
        :size="typographySize.BODY_TEXT_ITALIC"
      />
    </div>
    <div v-else data-test-id="financial-information__table">
      <div class="financial-information__table">
        <StickyTable
          caption="Financial Information"
          :table-headers="financialInformationTableHeaders"
          :table-rows="financialInformationTableRows"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import { typographySize } from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "FinancialInformation",
  components: {
    StickyTable,
    BaseText
  },
  props: {
    premiumCorporateData: {
      type: Object,
      default: () => ({})
    },
    isDnbDisabled: {
      type: Boolean,
      default: false
    },
    isDnbVerified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      financialInformationTableHeaders: [
        {
          value: "label",
          styles: {
            display: "none"
          }
        },
        {
          value: "value",
          styles: {
            display: "none"
          }
        }
      ],
      financialInformationTableDataMap: [
        {
          label: "Accountants",
          value:
            this.premiumCorporateData?.financials?.[0]?.accountantName || ""
        },
        {
          label: "Yearly Revenue",
          value:
            this.premiumCorporateData?.financials?.[0]?.yearlyRevenue?.[0]
              ?.value || ""
        },
        {
          label: "Currency",
          value:
            this.premiumCorporateData?.financials?.[0]?.yearlyRevenue?.[0]
              ?.currency || ""
        },
        {
          label: "Growth Rate",
          value: this.getGrowthRate()
        }
      ],
      financialInformationTableRows: [],
      typographySize
    };
  },
  created() {
    this.setTableData();
  },
  methods: {
    getGrowthRate() {
      return this.premiumCorporateData?.financials?.[0]?.yearlyRevenue?.[0]?.trend?.map(
        (trend) => [trend.timePeriod.description, ":-", trend.growthRate]
      );
    },
    setTableData() {
      this.setFinancialInformationTableRows();
    },
    setFinancialInformationTableRows() {
      this.financialInformationTableRows = this.makeTableRowData(
        this.financialInformationTableDataMap
      );
    },
    makeTableRowData(dataMap) {
      return dataMap.map(({ label, value }) => ({
        label: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: label,
            size: typographySize.BODY_TEXT_BOLD
          },
          styles: {
            border: "none"
          }
        },
        value: {
          component: BaseText,
          componentOptions: {
            tag: "span",
            text: [value]
          },
          styles: {
            border: "none"
          }
        }
      }));
    }
  }
};
</script>
<style lang="scss" scoped>
.financial-information {
  &__disable-text {
    text-align: center;
  }

  &__table {
    margin-bottom: 20px;
  }
}
</style>
