/**
 * Checks the validity of the `actionButtons` prop for SearchFilterBar
 * @param {Array} valueToTest
 * @returns {boolean}
 */

export const isValidActionButton = (valueToTest) => {
  let index = 0;
  for (const value of valueToTest) {
    if (!value?.id) {
      throw new Error(
        `'actionButtons' must have a valid 'id' property, at index ${index}`
      );
    }
    if (!value?.value) {
      throw new Error(
        `'actionButtons' must have a valid 'value' property, at index ${index}`
      );
    }
    index++;
  }

  return true;
};

export default {
  isValidActionButton
};
