<template>
  <div class="image-upload">
    <div class="mb-5">
      <b-navbar-brand v-if="path">
        <b-img :src="path" class="mb-3 image-upload__image" />
      </b-navbar-brand>
      <div class="mb-3 image-upload__description">
        <slot />
      </div>
      <div class="image-upload__button-holder">
        <b-btn
          class="mb-2 image-upload__button"
          variant="outline-primary"
          @click="showDragAndDropModal"
        >
          Choose File
        </b-btn>
      </div>
    </div>
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Add Logo"
      v-model="showAddDocumentModal"
      scrollable
      size="lg"
    >
      <div>
        <div v-if="!submitting && !responseSuccess">
          <b-overlay :show="uploading">
            <b-row>
              <b-col cols="12">
                <label>Upload Supporting Logo :</label>
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="makeDropzoneOptions()"
                  :useCustomSlot="true"
                  @vdropzone-success="vsuccess"
                  @vdropzone-file-added="onUploadPreview"
                  @vdropzone-sending="sendingEvent"
                  @vdropzone-error="verror"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drag and drop to upload content!
                    </h3>
                    <p class="subtitle">
                      ...or click to select a file from your computer
                    </p>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
        <div v-else-if="submitting && !responseSuccess">
          <p>Your Logo is being Uploading......</p>
          <b-spinner type="grow" label="Logo Upload is complete" />
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          variant="outline-danger"
          v-if="!submitting"
          :disabled="uploading"
          @click="resetModal"
        >
          Close
        </b-button>
        <b-button
          size="md"
          v-if="!submitting && !responseSuccess"
          :disabled="uploading"
          variant="outline-success"
          @click="checkValidation"
        >
          Upload
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from "@/components/Dropzone/VueDropzone";
import { endpoints, imageType as imageTypeEnum } from "@/constants";
import { makeAuthorizationHeader } from "@/utils";
import { mapState } from "vuex";

export default {
  name: "ImageUpload",
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    path: {
      required: true,
      type: String
    },
    imageType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      showAddDocumentModal: false,
      submitting: false,
      responseSuccess: false,
      uploading: false,
      form: {
        fileName: null,
        fileType: null,
        fileUploader: null,
        uploadedDate: null,
        status: null,
        fileSize: null
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + endpoints.THIRD_PARTY_UPLOAD_LOGO,
        thumbnailWidth: 150,
        maxFilesize: 0.2,
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxFiles: 1,
        acceptedFiles: "",
        headers: {
          ...makeAuthorizationHeader()
        }
      },
      files: [],
      changeIcon: null
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId
    })
  },
  methods: {
    makeDropzoneOptions() {
      return this.imageType === imageTypeEnum.FAVICON
        ? {
            ...this.dropzoneOptions,
            acceptedFiles: ".png,.gif,.ico,.svg"
          }
        : {
            ...this.dropzoneOptions,
            acceptedFiles: "image/jpg,image/jpeg,image/png"
          };
    },
    showDragAndDropModal() {
      this.showAddDocumentModal = true;
    },
    vsuccess(_, response) {
      this.uploading = false;
      this.submitting = false;
      this.responseSuccess = true;
      this.files.push(response.document);
      this.$emit("update-image", this.imageType, response.image_link);
      this.$bvToast.toast(response.message, {
        title: "Logo Upload is complete",
        noAutoHide: false,
        appendToast: true
      });
      this.resetModal();
    },
    verror(file, message) {
      this.uploading = false;
      this.submitting = false;
      this.responseSuccess = false;
      let fileSize = message.replace("0.2MiB", "200KB");
      this.$bvToast.toast(fileSize, {
        variant: "danger",
        noAutoHide: false,
        appendToast: true,
        title: "Error"
      });
      this.$refs.myVueDropzone.removeFile(file);
    },
    onUploadPreview(file) {
      if (file) {
        this.form.fileName = file.name;
        this.form.fileType = file.type;
        this.form.fileSize = file.size;
      }
    },
    sendingEvent(_, __, formData) {
      this.uploading = false;
      const metaData = {
        fileName: this.form.fileName,
        fileType: this.form.fileType,
        fileSize: this.form.fileSize
      };
      formData.append("companyId", this.companyId);
      formData.append("memberId", this.userId);
      formData.append("displayName", this.form.fileName.split(".")[0]);
      formData.append("meta_data", JSON.stringify(metaData));
    },
    resetModal() {
      this.showAddDocumentModal = false;
      if (this.files.length === 0) {
        this.form = {
          fileName: null,
          displayName: null,
          fileType: null,
          comments: null,
          fileSize: null
        };
      }
      this.submitting = false;
      this.uploading = false;
      this.responseSuccess = false;
    },
    checkValidation() {
      if (!this.form.fileName) {
        return false;
      } else {
        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
          this.uploading = true;
          this.$refs.myVueDropzone.processQueue();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.image-upload {
  &__image {
    height: 50px;
  }

  &__description {
    font-weight: 400;
  }

  &__button {
    width: 120px;

    &-holder {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
