<template>
  <div
    class="tab-pane show active fade"
    id="workflow"
    role="tabpanel"
    aria-labelledby="profile-tab"
  >
    <draggable
      :sort="false"
      class="list-group"
      v-model="fieldData"
      :group="{ name: 'sections', pull: 'clone', put: false }"
      :clone="onClone"
      tag="ul"
    >
      <li
        class="list-group-item text-left"
        v-for="element in fieldData"
        :key="element.id"
        :data-elementtype="element.inputType"
      >
        <font-awesome-icon
          :icon="element.icon"
          class="mr-2 text-primary"
          v-if="element.icon"
        />
        {{ element.name }}
      </li>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { FormData } from "@/helpers";

export default {
  name: "WorkflowComponents",
  components: {
    draggable
  },
  data() {
    return {
      fieldData: [
        {
          name: "Individual Personal Details",
          inputType: "personal-details",
          icon: "keyboard"
        },

        {
          name: "Principal Contact",
          inputType: "principal-contact",
          icon: "keyboard"
        },

        // Coporate address
        {
          name: "Personal Address",
          inputType: "address-personal",
          icon: "envelope"
        },
        // Coporate address
        {
          name: "Corporate Address",
          inputType: "address-corporate",
          icon: "envelope"
        },
        // beneficial owners indiviudal
        {
          name: "Individual Shareholders",
          inputType: "individual-shareholders",
          icon: "phone-alt"
        },
        // beneficial owners Company
        {
          name: "Corporate Shareholders",
          inputType: "corporate-shareholders",
          icon: "align-left"
        },

        {
          name: "Company officers",
          inputType: "company-officers",
          icon: "align-left"
        },
        // Corporate references
        {
          name: "Trade References",
          inputType: "trade-reference",
          icon: "align-left"
        }
      ]
    };
  },
  computed: {},
  methods: {
    onClone(e) {
      let data = FormData.workflowData(e.inputType);
      return {
        ...data
      };
    }
  }
};
</script>
