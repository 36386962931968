import { HTTP, makeAuthorizationHeader } from "@/utils";
import { endpoints } from "@/constants";
import { makeCustomAttributesWidgetListDTO } from "./dto/customAttributes.dto";

const fetchCustomAttributeTypes = () =>
  HTTP(
    "get",
    endpoints.CUSTOM_ATTRIBUTES_TYPES_GET,
    null,
    makeAuthorizationHeader()
  );

const fetchCustomAttributes = (formData) =>
  HTTP(
    "post",
    endpoints.CUSTOM_ATTRIBUTES_GET,
    formData,
    makeAuthorizationHeader()
  );

const toggleAttributeStatus = (formData) =>
  HTTP(
    "post",
    endpoints.CUSTOM_ATTRIBUTES_TOGGLE_STATUS,
    formData,
    makeAuthorizationHeader()
  );

const createAttribute = (formData) =>
  HTTP(
    "post",
    endpoints.CUSTOM_ATTRIBUTE_CREATE,
    formData,
    makeAuthorizationHeader()
  );

const updateAttribute = (formData) =>
  HTTP(
    "post",
    endpoints.CUSTOM_ATTRIBUTE_UPDATE,
    formData,
    makeAuthorizationHeader()
  );

const updateSequence = (formData) =>
  HTTP(
    "post",
    endpoints.CUSTOM_ATTRIBUTE_SEQUENCE,
    formData,
    makeAuthorizationHeader()
  );

const deleteAttribute = (formData) =>
  HTTP(
    "post",
    endpoints.CUSTOM_ATTRIBUTE_DELETE,
    formData,
    makeAuthorizationHeader()
  );

const fetchCustomAttributesWidgetsList = async (companyId) => {
  const { data = {} } = await HTTP(
    "GET",
    endpoints.CUSTOM_ATTRIBUTE_WIDGETS_LIST(companyId),
    null,
    makeAuthorizationHeader()
  );

  return makeCustomAttributesWidgetListDTO(data);
};

const fetchCustomAttributesThirdPartiesList = ({
  companyId = "",
  customAttributeId = "",
  value = ""
}) =>
  HTTP(
    "get",
    endpoints.CUSTOM_ATTRIBUTES_THIRD_PARTIES_LIST({
      companyId,
      customAttributeId,
      value
    }),
    null,
    makeAuthorizationHeader()
  );

export const customAttributesService = {
  fetchCustomAttributeTypes,
  fetchCustomAttributes,
  toggleAttributeStatus,
  createAttribute,
  updateAttribute,
  updateSequence,
  deleteAttribute,
  fetchCustomAttributesWidgetsList,
  fetchCustomAttributesThirdPartiesList
};
