import { riskAlertKeysDTO, riskAlertKeysAPIDTO } from "@/constants";

export function makeRiskAlertDTO(risk) {
  return {
    [riskAlertKeysDTO.PILLAR]: risk?.[riskAlertKeysAPIDTO.PILLAR] || "",
    [riskAlertKeysDTO.SEVERITY]: risk?.[riskAlertKeysAPIDTO.SEVERITY] || "",
    [riskAlertKeysDTO.CATEGORY]: risk?.[riskAlertKeysAPIDTO.CATEGORY] || "",
    [riskAlertKeysDTO.DATE]: risk?.[riskAlertKeysAPIDTO.DATE] || "",
    [riskAlertKeysDTO.RECEIVED_DATE]:
      risk?.[riskAlertKeysAPIDTO.RECEIVED_DATE] || "",
    [riskAlertKeysDTO.DESCRIPTION]:
      risk?.[riskAlertKeysAPIDTO.DESCRIPTION] || ""
  };
}
