import { numericBinaryOperator } from "@/constants";

const getTextFromValueInList = (tempValue, list) =>
  list.find(({ value }) => value === tempValue)?.text || "";

const isBinaryOperator = (value) =>
  !!Object.values(numericBinaryOperator).find((operator) => operator === value);

const getDuplicateIndexes = (list) => {
  const { duplicateIndexes } = list.reduce(
    (duplicates, value, index) => {
      const { names, duplicateIndexes } = duplicates;

      if (String(value).length) {
        if (names.includes(value)) {
          duplicateIndexes.push(index);
        } else {
          names.push(value);
        }
      }

      return duplicates;
    },
    { names: [], duplicateIndexes: [] }
  );

  return duplicateIndexes;
};

export {
  getTextFromValueInList,
  isBinaryOperator,
  getDuplicateIndexes
};
