import {
  riskStatusMap,
  thirdPartyFormsLanguagesAPIDTO,
  thirdPartyFormsLanguagesKeysDTO,
  thirdPartyHeaderConfigAPIDTO,
  thirdPartyHeaderConfigKeysDTO,
  thirdPartyOCStatesListAPIDTO,
  thirdPartyOCStatesListKeysDTO,
  ultimateBeneficialOwnerEntityKeysAPIDTO,
  ultimateBeneficialOwnerEntityKeysDTO,
  ultimateBeneficialOwnersKeysAPIDTO,
  ultimateBeneficialOwnersKeysDTO
} from "@/constants";

const thirdPartyOrderTypeTComplianceDTO = (data = []) =>
  data?.map(({ text, value }) => ({ text, value })) || [];

const thirdPartyFormsLanguagesDTO = (data = {}) =>
  data?.languages?.map((language) => ({
    [thirdPartyFormsLanguagesKeysDTO.ID]:
      language?.[thirdPartyFormsLanguagesAPIDTO.ID],
    [thirdPartyFormsLanguagesKeysDTO.LANGUAGES]:
      language?.[thirdPartyFormsLanguagesAPIDTO.LANGUAGES],
    [thirdPartyFormsLanguagesKeysDTO.LANGUAGES_CODE]:
      language?.[thirdPartyFormsLanguagesAPIDTO.LANGUAGES_CODE],
    [thirdPartyFormsLanguagesKeysDTO.STATUS]:
      language?.[thirdPartyFormsLanguagesAPIDTO.STATUS],
    [thirdPartyFormsLanguagesKeysDTO.CREATED_AT]:
      language?.[thirdPartyFormsLanguagesAPIDTO.CREATED_AT],
    [thirdPartyFormsLanguagesKeysDTO.UPDATED_AT]:
      language?.[thirdPartyFormsLanguagesAPIDTO.UPDATED_AT],
    [thirdPartyFormsLanguagesKeysDTO.CREATED_BY_ID]:
      language?.[thirdPartyFormsLanguagesAPIDTO.CREATED_BY_ID]
  })) || [];

const makeFetchUBOThirdPartyDTO = (data = []) =>
  data?.map((item) => ({
    [ultimateBeneficialOwnersKeysDTO.MEMBER_ID]:
      item?.[ultimateBeneficialOwnersKeysAPIDTO.MEMBER_ID],
    [ultimateBeneficialOwnersKeysDTO.BENEFICIARY_NAME]:
      item?.[ultimateBeneficialOwnersKeysAPIDTO.BENEFICIARY_NAME],
    [ultimateBeneficialOwnersKeysDTO.BENEFICIARY_TYPE]:
      item?.[ultimateBeneficialOwnersKeysAPIDTO.BENEFICIARY_TYPE]?.[
        ultimateBeneficialOwnersKeysAPIDTO.DESCRIPTION
      ] || "",
    [ultimateBeneficialOwnersKeysDTO.BUSINESS_OWNERSHIP_TYPE]:
      item?.[ultimateBeneficialOwnersKeysAPIDTO.BUSINESS_OWNERSHIP_TYPE]?.[
        ultimateBeneficialOwnersKeysAPIDTO.DESCRIPTION
      ] || "",
    [ultimateBeneficialOwnersKeysDTO.BENEFICIARY_OWNERSHIP_PERCENTAGE]:
      item?.[
        ultimateBeneficialOwnersKeysAPIDTO.BENEFICIARY_OWNERSHIP_PERCENTAGE
      ],
    [ultimateBeneficialOwnersKeysDTO.DIRECT_OWNERSHIP_PERCENTAGE]:
      item?.[ultimateBeneficialOwnersKeysAPIDTO.DIRECT_OWNERSHIP_PERCENTAGE],
    [ultimateBeneficialOwnersKeysDTO.INDIRECT_OWNERSHIP_PERCENTAGE]:
      item?.[ultimateBeneficialOwnersKeysAPIDTO.INDIRECT_OWNERSHIP_PERCENTAGE],
    [ultimateBeneficialOwnersKeysDTO.PERSON_OF_SIGNIFICANT_CONTROL]: item?.[
      ultimateBeneficialOwnersKeysAPIDTO.PERSON_OF_SIGNIFICANT_CONTROL
    ]
      ? "Yes"
      : "",
    [ultimateBeneficialOwnersKeysDTO.RISK_STATUS]:
      riskStatusMap[item?.[ultimateBeneficialOwnersKeysAPIDTO.RISK_STATUS]]
  })) || [];

const makeFetchUBOEntityDTO = (item = {}) =>
  ({
    [ultimateBeneficialOwnerEntityKeysDTO.BENEFICIARY_NAME]:
      item?.[ultimateBeneficialOwnerEntityKeysAPIDTO.BENEFICIARY_NAME],
    [ultimateBeneficialOwnerEntityKeysDTO.BENEFICIARY_TYPE]:
      item?.[ultimateBeneficialOwnerEntityKeysAPIDTO.BENEFICIARY_TYPE]?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.DESCRIPTION
      ] || "",
    [ultimateBeneficialOwnerEntityKeysDTO.BIRTH_DATA]:
      item?.[ultimateBeneficialOwnerEntityKeysAPIDTO.BIRTH_DATA],
    [ultimateBeneficialOwnerEntityKeysDTO.NATIONALITY]:
      item?.[ultimateBeneficialOwnerEntityKeysAPIDTO.NATIONALITY],
    [ultimateBeneficialOwnerEntityKeysDTO.ADDRESS_COUNTRY]:
      item?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.BUSINESS_OWNERSHIP_ADDRESS
      ]?.[ultimateBeneficialOwnerEntityKeysAPIDTO.ADDRESS_COUNTRY]?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.COUNTRY_NAME
      ] || "",
    [ultimateBeneficialOwnerEntityKeysDTO.BUSINESS_OWNERSHIP_TYPE]:
      item?.[ultimateBeneficialOwnerEntityKeysAPIDTO.BUSINESS_OWNERSHIP_TYPE]?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.DESCRIPTION
      ] || "",
    [ultimateBeneficialOwnerEntityKeysDTO.BUSINESS_OWNERSHIP_ADDRESS]:
      item?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.BUSINESS_OWNERSHIP_ADDRESS
      ]?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO
          .BUSINESS_OWNERSHIP_STREET_ADDRESS
      ]?.[ultimateBeneficialOwnerEntityKeysAPIDTO.ADDRESS_LANE] || "",
    [ultimateBeneficialOwnerEntityKeysDTO.ADDRESS_LOCALITY]:
      item?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.BUSINESS_OWNERSHIP_ADDRESS
      ]?.[ultimateBeneficialOwnerEntityKeysAPIDTO.ADDRESS_LOCALITY]?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.COUNTRY_NAME
      ] || "",
    [ultimateBeneficialOwnerEntityKeysDTO.PERSON_OF_SIGNIFICANT_CONTROL]:
      item?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.PERSON_OF_SIGNIFICANT_CONTROL
      ]
        ? "Yes"
        : "",
    [ultimateBeneficialOwnerEntityKeysDTO.DIRECT_OWNERSHIP_PERCENTAGE]:
      item?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.DIRECT_OWNERSHIP_PERCENTAGE
      ],
    [ultimateBeneficialOwnerEntityKeysDTO.INDIRECT_OWNERSHIP_PERCENTAGE]:
      item?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.INDIRECT_OWNERSHIP_PERCENTAGE
      ],
    [ultimateBeneficialOwnerEntityKeysDTO.BENEFICIARY_OWNERSHIP_PERCENTAGE]:
      item?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.BENEFICIARY_OWNERSHIP_PERCENTAGE
      ],
    [ultimateBeneficialOwnerEntityKeysDTO.OUT_OF_BUSINESS]: item?.[
      ultimateBeneficialOwnerEntityKeysAPIDTO.OUT_OF_BUSINESS
    ]
      ? "Yes"
      : "",
    [ultimateBeneficialOwnerEntityKeysDTO.CONTROL_OWNERSHIP_TYPE]:
      item?.[ultimateBeneficialOwnerEntityKeysAPIDTO.CONTROL_OWNERSHIP_TYPE]?.[
        ultimateBeneficialOwnerEntityKeysAPIDTO.DESCRIPTION
      ] || "",
    [ultimateBeneficialOwnerEntityKeysDTO.DEGREE_OF_SEPARATION]:
      item?.[ultimateBeneficialOwnerEntityKeysAPIDTO.DEGREE_OF_SEPARATION]
  } || {});

const thirdPartyOCStatesListDTO = (data = {}) =>
  data?.osStates?.map((state) => ({
    [thirdPartyOCStatesListKeysDTO.JURISDICTION_CODE]:
      state?.[thirdPartyOCStatesListAPIDTO.JURISDICTION_CODE],
    [thirdPartyOCStatesListKeysDTO.CODE]:
      state?.[thirdPartyOCStatesListAPIDTO.CODE],
    [thirdPartyOCStatesListKeysDTO.ID]:
      state?.[thirdPartyOCStatesListAPIDTO.ID],
    [thirdPartyOCStatesListKeysDTO.COUNTRY_CODE]:
      state?.[thirdPartyOCStatesListAPIDTO.COUNTRY_CODE],
    [thirdPartyOCStatesListKeysDTO.STATE]:
      state?.[thirdPartyOCStatesListAPIDTO.STATE],
    [thirdPartyOCStatesListKeysDTO.STATE]:
      state?.[thirdPartyOCStatesListAPIDTO.STATE],
    [thirdPartyOCStatesListKeysDTO.STATE_CODE]:
      state?.[thirdPartyOCStatesListAPIDTO.CODE]?.split("_")?.[1] || ""
  })) || [];

const thirdPartyHeaderConfigDataDTO = (data = {}) =>
  ({
    [thirdPartyHeaderConfigKeysDTO.MAXIMUM_ASSOCIATE]:
      data?.[thirdPartyHeaderConfigAPIDTO.MAXIMUM_ASSOCIATE],
    [thirdPartyHeaderConfigKeysDTO.SPECIAL_FEATURE]:
      data?.[thirdPartyHeaderConfigAPIDTO.SPECIAL_FEATURE]
  } || {});

export {
  thirdPartyOrderTypeTComplianceDTO,
  thirdPartyFormsLanguagesDTO,
  makeFetchUBOThirdPartyDTO,
  makeFetchUBOEntityDTO,
  thirdPartyOCStatesListDTO,
  thirdPartyHeaderConfigDataDTO
};
