<template id="template-signature">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      :label="fieldData.label[selectedLanguage]"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />

    <b-collapse
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="fieldData.isConditional == '1' && !isPreview"
            variant="outline-dark"
            disabled
            title="This field is hidden by default and will display bt is controlled element"
          >
            <font-awesome-icon icon="eye-slash" />
          </b-button>
        </b-col>
      </b-row>
      <h2>THIS IS A PROTOTYPE AND NOT FUNCTIONAL AND FOR DEMO PURPOSES</h2>
      <h3 class="text-left">
        {{
          form1.enableSectionFieldEnumeration == 1
            ? sectionIndex + 1 + "." + (fieldIndex + 1)
            : ""
        }}
        {{ label.english }}
      </h3>
      <b-row v-for="(field, index) in fields" :key="index">
        <b-col cols="10">
          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="4"
            label-cols-lg="3"
            :description="field.inputHelperText"
            :label="field.inputLabel"
          >
            <b-form-input
              :disabled="isDisabled == 1"
              id="input-horizontal"
              :placeholder="field.inputPlaceHolderText"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="text-left">
          <b-button variant="outline-warning" size="sm" @click="onOpen">
            <font-awesome-icon icon="clone" /> </b-button
          >&nbsp;
          <b-button
            variant="outline-primary"
            size="sm"
            id="popover-reactive"
            :ref="'button' + index"
          >
            <font-awesome-icon icon="cogs" /> </b-button
          >&nbsp;
          <b-button
            @click="deleteField(index)"
            variant="outline-danger"
            size="sm"
          >
            <font-awesome-icon icon="trash" />
          </b-button>
        </b-col>
      </b-row>

      <b-button @click="addField" variant="outline-success" class="float-right">
        <font-awesome-icon icon="plus" />
      </b-button>
      <div class="clearfix"></div>
      <!-- show advance field options -->
      <b-modal
        ok-only
        ok-title="Close"
        @ok="onSaveData"
        size="lg"
        v-model="showSettings"
        title="Question Settings"
      >
        <SignatureSettings
          :fieldData="fieldData"
          :sectionIndex="sectionIndex"
          v-on:toggle-field="showSettings = !showSettings"
          :fieldIndex="fieldIndex"
        />
      </b-modal>

      <b-popover
        target="popover-reactive"
        triggers="click"
        :show.sync="popoverShow"
        placement="auto"
        container="my-container"
        ref="popover"
        @show="onShow"
        @shown="onShown"
        @hidden="onHidden"
      >
        <template v-slot:title>
          <b-button @click="onClose" class="close" aria-label="Close">
            <span class="d-inline-block" aria-hidden="true"
              >&times;</span
            > </b-button
          >Interactive Content
        </template>

        <div>
          <b-form-group
            label="Name"
            label-for="popover-input-1"
            label-cols="3"
            :state="input1state"
            class="mb-1"
            description="Enter your name"
            invalid-feedback="This field is required"
          >
            <b-form-input
              ref="input1"
              id="popover-input-1"
              v-model="input1"
              :state="input1state"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Color"
            label-for="popover-input-2"
            label-cols="3"
            :state="input2state"
            class="mb-1"
            description="Pick a color"
            invalid-feedback="This field is required"
          >
            <b-form-select
              id="popover-input-2"
              v-model="input2"
              :state="input2state"
              :options="options"
              size="sm"
            ></b-form-select>
          </b-form-group>

          <b-alert show class="small">
            <strong>Current Values:</strong>
            <br />Name:
            <strong>{{ input1 }}</strong>
            <br />Color:
            <strong>{{ input2 }}</strong>
          </b-alert>

          <b-button @click="onClose" size="sm" variant="danger"
            >Cancel</b-button
          >
          <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
        </div>
      </b-popover>
    </b-collapse>
  </div>
</template>

<script>
import SignatureSettings from "../advanceSignatureSettingsCompenent";
import MenuButtons from "../menuButtons";
import { mapState, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "Question",
  components: {
    SignatureSettings,
    MenuButtons
  },

  data() {
    return {
      input1: "",
      input1state: null,
      input2: "",
      input2state: null,
      options: [{ text: "- Choose 1 -", value: "" }, "Red", "Green", "Blue"],
      input1Return: "",
      input2Return: "",
      popoverShow: false,

      isHidden: false,
      condition: {},
      showSettings: false,
      collapse: true,
      label: {
        english: "Question Title goes here"
      },
      fields: [
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        },
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        },
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        },
        {
          inputType: "input",
          inputLabel: "Some label name",
          inputPlaceHolderText: "this is a placeholder",
          inputHelperText: null,
          validation: {
            isRequired: true,
            validationMessage: {
              english: "This field is required"
            },
            minLength: null,
            maxLength: null,
            inputMask: null
          },
          fieldLabel: "",
          layout: "horizontal"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(vlue) {
      this.collapse = vlue;
    },
    input1(val) {
      if (val) {
        this.input1state = true;
      }
    },
    input2(val) {
      if (val) {
        this.input2state = true;
      }
    }
  },
  methods: {
    addField() {
      let schema = {
        inputType: "input",
        inputLabel: "Some label name",
        inputPlaceHolderText: "this is a placeholder",
        inputHelperText: null,
        validation: {
          isRequired: true,
          validationMessage: {
            english: "This field is required"
          },
          minLength: null,
          maxLength: null,
          inputMask: null
        },
        fieldLabel: "",
        layout: "horizontal"
      };
      this.fields.push(schema);
    },
    deleteField(index) {
      this.fields.splice(index, 1);
    },
    onOpen() {
      this.$refs.popover.$emit("open");
    },
    onClose() {
      this.popoverShow = false;
    },
    onOk() {
      if (!this.input1) {
        this.input1state = false;
      }
      if (!this.input2) {
        this.input2state = false;
      }
      if (this.input1 && this.input2) {
        this.onClose();
        // Return our popover form results
        this.input1Return = this.input1;
        this.input2Return = this.input2;
      }
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      this.input1 = "";
      this.input2 = "";
      this.input1state = null;
      this.input2state = null;
      this.input1Return = "";
      this.input2Return = "";
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.input1);
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      this.focusRef(this.$refs.button);
    },
    focusRef() {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          // ;(ref.$el || ref).focus()
        });
      });
    },
    onSaveData() {
      EventBus.$emit("onSaveSettings", "saving settings");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
