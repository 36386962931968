<template>
  <div class="set-step">
    <div class="set-step__wrapper">
      <div class="set-step__from-block">
        <BaseText
          class="set-step__text"
          text="Set"
          :size="typographySize.LARGE_TEXT"
        />
        <SearchSelect
          label="SET"
          placeholder="Select..."
          class="set-step__from-block--step-name"
          :name="`variable-name-${id}`"
          :id="`variable-name-${id}`"
          :options="variableNameOptions"
          :error="variableNameErrorMessage"
          :searchValue="variableNameSearchValue"
          data-test-id="set-step__from-block"
          is-label-hidden
          @change="
            $emit('change', {
              name: actionKeys.VARIABLE_NAME,
              id,
              event: $event
            })
          "
          @create="
            $emit('create', {
              name: actionKeys.VARIABLE_NAME,
              id,
              event: $event
            })
          "
          @reset="
            $emit('reset', {
              name: actionKeys.VARIABLE_NAME,
              id,
              event: $event
            })
          "
        />
        <div class="set-step__text set-step__separator">
          <BaseText text="to" :size="typographySize.LARGE_TEXT" />
          <div class="set-step__separator--line" />
        </div>
      </div>
      <SetToBlock
        v-bind="$props"
        class="set-step__to-block"
        data-test-id="set-step__to-block"
        @change="$emit('change', $event)"
      />
    </div>
    <EndOperations
      v-if="isEndOperationAvailable"
      class="set-step__operations"
      data-test-id="set-step__operations"
      :endOperations="endOperations"
      @change="
        $emit('change', {
          ...$event,
          setStepId: id
        })
      "
    />
  </div>
</template>

<script>
import SetToBlock from "@/molecules/SetToBlock/SetToBlock";
import BaseText from "@/atoms/BaseText/BaseText";
import SearchSelect from "@/molecules/SearchSelect/SearchSelect";
import EndOperations from "@/molecules/EndOperations/EndOperations";
import { actionKeys, typographySize, endOperationsPerType } from "@/constants";

export default {
  name: "SetStep",
  props: {
    variableNameOptions: {
      type: Array,
      required: true
    },
    variableNameSearchValue: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    valueDataTypeText: {
      type: String,
      default: ""
    },
    valueDataTypeOptions: {
      type: Array,
      default: () => []
    },
    valueTypeOptions: {
      type: Array,
      default: () => []
    },
    isMiddleSelectHidden: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      require: true
    },
    component: {
      type: Object,
      default: () => ({})
    },
    componentOptions: {
      type: Object,
      default: () => ({})
    },
    variableNameErrorMessage: {
      type: String,
      default: ""
    },
    valueDataTypeErrorMessage: {
      type: String,
      default: ""
    },
    valueTypeErrorMessage: {
      type: String,
      default: ""
    },
    endOperations: {
      type: Array,
      default: () => []
    },
    variableType: {
      type: String,
      default: ""
    }
  },
  components: {
    SetToBlock,
    BaseText,
    SearchSelect,
    EndOperations
  },
  data() {
    return {
      actionKeys,
      typographySize
    };
  },
  computed: {
    isEndOperationAvailable() {
      return !!endOperationsPerType[this.variableType]?.length || false;
    }
  }
};
</script>

<style scoped lang="scss">
.set-step {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    height: 100%;

    .set-step__separator {
      padding: 0 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &--line {
        border-left: 1px solid $davy-grey;
        height: 70%;
      }
    }

    &:deep(.set-step__text) {
      margin-top: 4px;
      padding-right: 5px;
      margin-bottom: 0;
    }

    &:deep(.set-step__to-block) {
      width: 50%;
    }

    &:deep(.label__label) {
      display: flex;
    }
  }

  &__from-block {
    display: flex;
    width: 50%;

    &--step-name {
      width: 100%;
    }
  }

  &__operations {
    padding-top: $spacing;
    border-top: 1px solid $chinese-silver;
  }
}
</style>
