const sortOrder = {
  NONE: "none",
  ASCENDING: "asc",
  DESCENDING: "desc"
};

const position = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
  TOP_LEFT: "topLeft",
  TOP_RIGHT: "topRght",
  BOTTOM_LEFT: "bottomLeft",
  BOTTOM_RIGHT: "bottomRight",
  LEFT_TOP: "leftTop",
  LEFT_BOTTOM: "leftBottom",
  RIGHT_TOP: "rightTop",
  RIGHT_BOTTOM: "rightBottom",
  DEFAULT: "default"
};

export { position, sortOrder };
