<template>
  <div
    :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
    :class="small ? 'loader-container' : 'loader-container-plain'"
  >
    <b-spinner class="text-center mb-2" variant="primary"></b-spinner>
    <h6 class="text-light">{{ $t("loadingSpinnerText") }}</h6>
  </div>
</template>
<script>
export default {
  name: "AppLoader",
  props: {
    small: Boolean
  }
};
</script>
<style lang="scss" scoped>
html {
  height: 100%;
}
.loader-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 9;
  text-align: center;
  background-color: rgba($black, 0.7);
  padding: 20%;
}
.loader-container-plain {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  text-align: center;
  z-index: 9;
  background-color: $white;
  padding: 20%;
}
</style>
