import { actions } from "./platformSettings.actions.store";
import { getters } from "./platformSettings.getters.store";
import { mutations } from "./platformSettings.mutations.store";
import { state } from "./platformSettings.state.store";

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
