<template>
  <div class="custom-attributes">
    <b-navbar toggleable="lg">
      <b-navbar-toggle target="nav-collapse" />
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-button
            variant="outline-success"
            size="sm"
            class="mr-2"
            title="Drag and drop the rows to reorder when this is turned on"
            v-b-tooltip.hover
            @click="showSaveModal"
          >
            {{ reorderBtnText }}
          </b-button>
          <b-button
            variant="outline-primary"
            size="sm"
            class="mr-2"
            @click="openAddAttributeModal"
          >
            Add
          </b-button>
          <b-form-group class="mr-2">
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                id="filterInput"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-success"
                  :disabled="!filter"
                  @click="submitSearch"
                >
                  {{ $t("searchBtn") }}
                </b-button>
                <b-button :disabled="!filter" @click="clearFilter">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-button
            variant="outline-info"
            size="sm"
            class="mr-2"
            @click="reloadTable"
          >
            <font-awesome-icon icon="sync" />
          </b-button>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>
    <b-overlay :show="isLoading">
      <div class="custom-attributes__heading">
        Third Party Custom Attributes
      </div>
      <b-table-simple
        id="custom-attributes-table"
        sticky-header="calc(100vh - 300px)"
        bordered
        ref="table"
        responsive="sm"
        no-sort-reset
      >
        <b-thead>
          <b-tr class="custom_attributes__table-row">
            <b-th v-if="table_col"></b-th>
            <b-th
              v-for="(field, index) in staticFields"
              :key="`staticField-${index}`"
            >
              {{ field.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <draggable
          v-if="showReordering"
          v-model="items"
          tag="b-tbody"
          group="fields"
        >
          <b-tr
            v-for="(fields, fieldsIndex) in items"
            :key="`item-${fieldsIndex}`"
            class="custom_attributes__table-row"
          >
            <b-td><i class="fa fa-bars" aria-hidden="true"></i></b-td>
            <b-td>{{ fields.id }} </b-td>
            <b-td>{{ fields.tp_customatt }} </b-td>
            <b-td
              >{{ getCustomAttributeCurrentStatus(fields.is_active) }}
            </b-td>
            <b-td>{{ fields.description }} </b-td>
            <b-td>
              <BaseBadge
                v-for="name in renderPermissions(fields)"
                :key="name"
                :text="name"
                :theme="themes.DARK_GREY_INVERSE"
                :iconSpace="space.SMALL"
                :textSpace="space.SMALL"
              />
            </b-td>
            <b-td>{{ fields.tp_is_required === 1 ? "Yes" : "No" }} </b-td>
            <b-td>{{ fields.type_display_name }} </b-td>
            <b-td>
              <b-dropdown
                size="sm"
                variant="outline"
                toggle-class="text-decoration-none"
                no-caret
                dropleft
              >
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-h" />
                </template>
                <b-dropdown-item-button
                  class="text-secondary"
                  @click="editAttribute(fields)"
                >
                  Edit
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  class="text-secondary"
                  @click="toggleAttributeState(fields)"
                >
                  {{ getCustomAttributeFutureStatus(fields.is_active) }}
                </b-dropdown-item-button>

                <b-dropdown-item-button
                  v-if="
                    parseInt(fields.is_active) ===
                    customAttributeActionsState.INACTIVE
                  "
                  class="text-secondary"
                  @click="showDeleteModal(fields)"
                >
                  Delete
                </b-dropdown-item-button>
              </b-dropdown>
            </b-td>
          </b-tr>
        </draggable>
        <b-tr
          v-else
          v-for="(fields, fieldsIndex) in items"
          :key="`item-${fieldsIndex}`"
          class="custom_attributes__table-row"
        >
          <b-td>{{ fields.id }} </b-td>
          <b-td>{{ fields.tp_customatt }} </b-td>
          <b-td>{{ getCustomAttributeCurrentStatus(fields.is_active) }} </b-td>
          <b-td>{{ fields.description }} </b-td>
          <b-td>
            <BaseBadge
              v-for="name in renderPermissions(fields)"
              :key="name"
              :text="name"
              :theme="themes.DARK_GREY_INVERSE"
              :iconSpace="space.SMALL"
              :textSpace="space.SMALL"
            />
          </b-td>
          <b-td
            >{{ parseInt(fields.tp_is_required) === 1 ? "Yes" : "No" }}
          </b-td>
          <b-td>{{ fields.type_display_name }} </b-td>
          <b-td>
            <b-dropdown
              size="sm"
              variant="outline"
              toggle-class="text-decoration-none"
              no-caret
              dropleft
            >
              <template #button-content>
                <font-awesome-icon icon="ellipsis-h" />
              </template>
              <b-dropdown-item-button
                class="text-secondary"
                @click="editAttribute(fields)"
              >
                Edit
              </b-dropdown-item-button>
              <b-dropdown-item-button
                class="text-secondary"
                @click="toggleAttributeState(fields)"
              >
                {{ getCustomAttributeFutureStatus(fields.is_active) }}
              </b-dropdown-item-button>
              <b-dropdown-item-button
                v-if="
                  parseInt(fields.is_active) ===
                  customAttributeActionsState.INACTIVE
                "
                class="text-secondary"
                @click="showDeleteModal(fields)"
              >
                Delete
              </b-dropdown-item-button>
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-table-simple>
      <div class="text-center" v-if="totalRows === 0">
        <b> No data available </b>
      </div>
      <b-row>
        <b-col cols="3"> </b-col>
        <b-col cols="6" v-if="totalRows > 0">
          <p class="text-center">
            {{ $t("eb360.manageThirdParties.resultRecords") }}
            <b>{{ fromRows }}</b>
            {{ $t("eb360.manageThirdParties.toRecords") }}
            <b>{{ toRows }}</b>
            {{ $t("eb360.manageThirdParties.ofRecords") }}
            <b>{{ totalRows }}</b>
          </p>
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      v-model="showAddAttributeModal"
      size="lg"
      :title="editModalShow ? 'Edit Attributes' : 'Add Attributes'"
    >
      <div>
        <b-overlay :show="isSubmitting">
          <div class="custom-attributes__form-holder">
            <b-form-group hidden>
              <b-form-checkbox
                class="custom-attributes__form-checkbox"
                v-model="selectedFields.searchPage"
                name="some-radios"
                :value="1"
                :unchecked-value="0"
              >
                Enable on search page
              </b-form-checkbox>
              <b-form-checkbox
                class="custom-attributes__form-checkbox"
                v-model="selectedFields.manageTpPage"
                name="some-radios"
                :value="1"
                :unchecked-value="0"
              >
                Enable on manage third parties page
              </b-form-checkbox>
              <b-form-checkbox
                class="custom-attributes__form-checkbox"
                v-model="selectedFields.filterOnManageTpPage"
                name="some-radios"
                :value="1"
                :unchecked-value="0"
              >
                Enable Filtering on manage third parties page
              </b-form-checkbox>
              <b-form-checkbox
                class="custom-attributes__form-checkbox"
                v-model="selectedFields.tpProfilePage"
                name="some-radios"
                :value="1"
                :unchecked-value="0"
              >
                Enable on third party profile page
              </b-form-checkbox>
            </b-form-group>
          </div>
          <b-form class="custom-attributes__form-holder">
            <b-form-group hidden>
              <template #label>
                <p>Sequence Number:</p>
              </template>
              <b-form-input
                disabled
                size="md"
                v-model="attributeSequenceNumber"
              />
            </b-form-group>
            <b-form-group>
              <template #label>
                <Label id="attributeName" label="Attribute name" />
              </template>
              <Input
                id="attributeName"
                name="attributeName"
                placeholder="Attribute name"
                :value="attributeName"
                :error="errorMessages.attributeName"
                :validation-state="getInputModelState('attributeName')"
                @change="onChangeInput('attributeName', $event)"
              />
            </b-form-group>
            <b-form-group>
              <template #label>
                <Label
                  id="attributeDescription"
                  label="Attribute description"
                  :isRequired="false"
                />
              </template>
              <b-form-textarea
                size="md"
                id="attributeDescription"
                v-model="attributeDescription"
                rows="3"
                max-rows="6"
              />
            </b-form-group>
            <b-form-group hidden>
              <template #label>
                <Label
                  id="attributeAlias"
                  label="Attribute alias"
                  :isRequired="false"
                />
              </template>
              <b-form-input
                size="md"
                v-model="attributeAlias"
                id="attributeAlias"
              />
            </b-form-group>
            <b-form-group>
              <template #label>
                <Label
                  id="attributeStatus"
                  label="Attribute status"
                  :isRequired="false"
                />
              </template>
              <v-select
                v-model="attributeStatus"
                id="attributeStatus"
                label="text"
                :options="optionsStatus"
                :clearable="false"
                @input="setStatus"
              />
            </b-form-group>
            <b-form-group>
              <template #label>
                <Label
                  id="attributeIsRequireCheck"
                  label="Attribute is required"
                  :isRequired="false"
                />
              </template>
              <v-select
                v-model="attributeIsRequireCheck"
                id="attributeIsRequireCheck"
                :options="optionsRequire"
                label="text"
                :clearable="false"
                @input="setIsRequire"
              />
            </b-form-group>
            <div class="custom-attributes__edit-role">
              <MultiSelect
                :id="`${makeUUID()}`"
                label="Edit permissions"
                :options="editRoleOptions"
                name="customAttributeEditPermissions"
                description="Select roles to allow edit permissions"
                :is-required="false"
                @change="updateEditPermissionRoleOptions"
              />
            </div>
            <b-form-group>
              <template #label>
                <Label id="dataType" label="Attribute type" />
              </template>
              <v-select
                label="text"
                id="dataType"
                class="custom-attributes__select"
                v-model="attributeDataType"
                :disabled="editModalShow"
                :options="customAttributeList"
                :clearable="false"
                :state="getInputModelState(attributeDataType)"
                :class="{
                  'form-control is-invalid p-0': errorMessages.attributeDataType
                }"
              />
              <b-form-invalid-feedback id="dataType">
                {{ errorMessages.attributeDataType }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group v-if="isListAttribute">
              <template #label>
                <p>Values:</p>
              </template>
              <div
                v-for="(data, dataIndex) in dataValues"
                :key="`dataValue-${dataIndex}`"
                class="custom-attributes__list"
              >
                <b-form-group>
                  <b-form-input
                    size="sm"
                    v-model="data.label"
                    placeholder="Label"
                    :state="
                      (isListAttribute && dataValues[0].label === null) ||
                      dataValues[0].label === ''
                        ? false
                        : null
                    "
                  />
                </b-form-group>
                <b-form-group class="custom-attributes__form-input-holder">
                  <b-form-input
                    size="sm"
                    v-model="data.value"
                    placeholder="Value"
                    :state="
                      (isListAttribute && dataValues[0].value === null) ||
                      dataValues[0].value === ''
                        ? false
                        : null
                    "
                  />
                </b-form-group>
                <div class="custom-attributes__form-buttons">
                  <b-button
                    v-if="dataIndex > 0"
                    variant="outline-danger"
                    size="sm"
                    @click="removeDataValueRow(dataIndex, data)"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                  <b-button
                    v-show="
                      dataIndex === 0
                        ? dataValues[0].label
                          ? true
                          : dataValues[0].value
                          ? true
                          : false
                        : false
                    "
                    variant="outline-danger"
                    size="sm"
                    @click="removeFirstDataValueRow(dataIndex, data)"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                  <b-button
                    v-show="dataIndex === dataValues.length - 1"
                    class="ml-2"
                    size="sm"
                    variant="outline-success"
                    @click="addDataValueRow"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </div>
              </div>
              <div
                v-if="
                  isListAttribute &&
                  (dataValues[0].label === null ||
                    dataValues[0].value === null ||
                    dataValues[0].label === '' ||
                    dataValues[0].value === '')
                "
                class="custom-attributes__form-error"
              >
                <span>Required field has no value.</span>
              </div>
            </b-form-group>
            <b-form-group v-else-if="isSubtypeAvailable">
              <template #label>
                <Label id="subtypes" for="subtypes" label="Attribute subtype" />
              </template>
              <v-select
                label="text"
                id="subtypes"
                class="custom-attributes__select"
                v-model="attributeSubtype"
                :disabled="editModalShow"
                :options="makeAvailableSubtypesOptions"
                :clearable="false"
                :state="getInputModelState(attributeSubtype)"
                :class="{
                  'form-control is-invalid p-0': errorMessages.attributeSubtype
                }"
                @input="setAttributeSubType"
              />
              <p>{{ selectedSubtypeDescription }}</p>
              <b-form-invalid-feedback id="dataType">
                {{ errorMessages.attributeSubtype }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </b-overlay>
      </div>
      <template v-slot:modal-footer>
        <b-button size="md" variant="outline-danger" @click="resetModal">
          <font-awesome-icon icon="ban" />
          Close
        </b-button>
        <b-button size="md" variant="outline-success" @click="saveAttribute">
          {{ editModalShow ? "Update" : "Save" }}
          <font-awesome-icon icon="download" />
        </b-button>
      </template>
    </b-modal>
    <b-modal ref="modal-delete" title="WARNING" @ok="deleteAttribute">
      <p>
        All values for this custom attribute will also be deleted, do you wish
        to continue?
      </p>
    </b-modal>
    <b-modal
      ref="modal-save"
      title="Confirm Reorder"
      @cancel="reloadTable"
      @ok="changeSequence"
    >
      <p>Are you sure you want to save configuration?</p>
    </b-modal>
    <b-modal
      ref="modal-active"
      title="Are you sure?"
      @ok="toggleAttributeStatus"
    >
      <p>
        Are you sure you want to
        {{ getCustomAttributeFutureStatus(isActive) }}
        attribute?
      </p>
    </b-modal>
  </div>
</template>

<script>
import "moment/locale/es";
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import { hasStatus200, isValidResponse, makeUUID } from "@/utils";
import {
  actionName,
  customAttributeActionsState,
  getterName,
  operations,
  roles,
  space,
  successModalConfig,
  themes,
  userRolesKeysAPIDTO,
  userRolesKeysDTO,
  validationKeys
} from "@/constants";
import { customAttributesService } from "@/services";
import { validate } from "@/mixins";
import Input from "@/molecules/Input/Input";
import Label from "@/atoms/Label/Label";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";
import { mapActions, mapGetters, mapState } from "vuex";
import { cloneDeep } from "lodash";
import { makeOptionsForMultiSelect } from "@/molecules/MultiSelect/MultiSelect.dto";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";

export default {
  name: "CustomAttributes",
  mixins: [validate],
  components: {
    MultiSelect,
    draggable,
    Input,
    Label,
    BaseBadge
  },
  data() {
    return {
      customAttributeActionsState,
      isLoading: false,
      table_col: false,
      totalRows: 0,
      filter: null,
      perPage: 100,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "tp_sequence",
      sortDesc: false,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "id", label: "ID", sortable: false },
        { key: "tp_customatt", label: "Name", sortable: false },
        { key: "is_active", label: "Status", sortable: false },
        { key: "description", label: "Description", sortable: false },
        { key: "permissions", label: "Permissions", sortable: false },
        { key: "tp_is_required", label: "Required", sortable: false },
        { key: "tp_type", label: "Type", sortable: false },
        { key: "actions", label: "Actions", sortable: false }
      ],
      items: [],
      showAddAttributeModal: false,
      errorMessages: {
        attributeName: undefined,
        attributeDataType: undefined,
        attributeSubtype: undefined
      },
      attributeId: null,
      attributeSequenceNumber: 1,
      attributeName: null,
      attributeAlias: null,
      attributeIsRequire: 0,
      attributeDataType: null,
      attributeSubtype: null,
      attributeDescription: null,
      attributeStatus: { text: customAttributeActionsState.ACTIVE, value: 1 },
      attributeIsRequireCheck: { text: "No", value: 0 },
      customAttributeList: [],
      optionsStatus: [
        { text: customAttributeActionsState.ACTIVATE, value: 1 },
        { text: customAttributeActionsState.DEACTIVATE, value: 0 }
      ],
      optionsRequire: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      dataValues: this.makeDataValueList(),
      selectedFields: {
        searchPage: 1,
        manageTpPage: 1,
        filterOnManageTpPage: 1,
        tpProfilePage: 1
      },
      inputModelList: [
        "attributeName",
        "attributeDataType",
        "attributeSubtype"
      ],
      editModalShow: false,
      isSubmitting: false,
      isActive: null,
      reorderBtnText: "Reorder",
      showReordering: false,
      temporarySelectedEditRoles: [],
      allRoleOptions: [],
      selectedRoles: [],
      amendableRoles: [],
      editRoleOptions: [],
      space,
      themes
    };
  },
  validations: {
    attributeName: {
      [validationKeys.REQUIRED]: required
    },
    attributeDataType: {
      [validationKeys.REQUIRED]: required
    },
    attributeSubtype: {
      [validationKeys.REQUIRED]: requiredIf(function () {
        return this.isSubtypeAvailable;
      })
    }
  },
  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
      companyId: (state) => state.company.companyId,
      companyName: (state) => state.company.companyName,
      userId: (state) => state.user.userId,
      countries: (state) => state.country.countries
    }),
    ...mapGetters({
      getIdsToRoleNames: getterName.ROLES.GET_IDS_TO_ROLE_NAMES
    }),
    availableSubtypes() {
      return this.attributeDataType?.subtypes || [];
    },
    makeAvailableSubtypesOptions() {
      return this.availableSubtypes.map(({ text, id }) => ({
        value: id,
        text
      }));
    },
    selectedSubtype() {
      return this.availableSubtypes.find(
        (subtype) => subtype.text === this.attributeSubtype
      );
    },
    selectedSubtypeDescription() {
      return (
        this.selectedSubtype?.values.map(({ value }) => value).join(", ") || ""
      );
    },
    isSubtypeAvailable() {
      return this.attributeDataType?.subtypes?.length;
    },
    isListAttribute() {
      return ["dropdown", "multiselect", "radio"].includes(
        this.attributeDataType?.value?.toLowerCase()
      );
    }
  },
  mounted() {
    this.$v.$reset();
    this.fetchCustomAttributeTypes();
    this.fetchCustomAttributes();
    this.fetchRolesAction();
  },
  watch: {
    roles: {
      deep: true,
      handler() {
        this.setEditPermissionRoleOptions();
      }
    },
    showAddAttributeModal(newValue) {
      if (!newValue) {
        this.resetSelectedRoleOptions();
      }
    },
    selectedRoles: {
      deep: true,
      handler() {
        this.setEditRoleOptions();
      }
    }
  },
  methods: {
    makeUUID,
    ...mapActions({
      fetchRolesAction: actionName.ROLES.FETCH_ROLES,
      fetchCustomAttributesList:
        actionName.CUSTOM_ATTRIBUTES.FETCH_CUSTOM_ATTRIBUTES_LIST
    }),

    onChangeInput(inputName, val) {
      this[inputName] = val;
    },
    submitSearch() {
      this.fetchCustomAttributes();
    },
    clearFilter() {
      this.filter = "";
      this.fetchCustomAttributes();
    },
    showDeleteModal(fields) {
      this.attributeId = fields.id;
      this.$refs["modal-delete"].show();
    },
    toggleAttributeState(fields) {
      this.attributeId = fields.id;
      this.isActive = fields.is_active;
      this.$refs["modal-active"].show();
    },
    showSaveModal() {
      if (this.reorderBtnText === "Reorder") {
        this.reorderBtnText = "Save Reorder";
        this.table_col = true;
        this.showReordering = true;
      } else {
        this.$refs["modal-save"].show();
      }
    },
    lastTriggeredDate(value) {
      moment.locale("en");
      return moment(value).format("DD/MM/YYYY");
    },
    reloadTable() {
      this.reorderBtnText = "Reorder";
      this.showReordering = false;
      this.table_col = false;
      this.$root.$emit("bv::refresh::table", "custom-attributes-table");
      this.fetchCustomAttributes();
    },
    openAddAttributeModal() {
      this.attributeSequenceNumber = this.items.length + 1;
      this.showAddAttributeModal = true;
    },
    editAttribute(data) {
      this.editModalShow = true;
      this.attributeId = data.id;
      this.attributeSequenceNumber = data.tp_sequence;
      this.attributeName = data.tp_customatt;
      this.attributeDataType = this.customAttributeList.find(
        (val) => data.tp_type === val.value
      );
      this.attributeIsRequire = parseInt(data.tp_is_required);
      this.attributeIsRequireCheck = this.optionsRequire.find(
        (val) => parseInt(data.tp_is_required) === val.value
      );
      this.attributeAlias = data.tp_alias;
      this.attributeStatus = !data.is_active
        ? { text: customAttributeActionsState.ACTIVE, value: 1 }
        : { text: customAttributeActionsState.DEACTIVATED, value: 0 };
      this.attributeDescription = data.description;
      this.attributeSubtype =
        this.attributeDataType?.subtypes?.find(
          (subtype) => parseInt(subtype?.id) === parseInt(data?.tp_subtype)
        )?.text || null;
      this.showAddAttributeModal = true;
      this.dataValues = data.tp_customatt_values;
      this.isActive = data.is_active;
      this.selectedFields.filterOnManageTpPage = parseInt(data.tp_is_filter);
      this.selectedFields.searchPage = parseInt(data.tp_is_search);
      this.selectedFields.manageTpPage = parseInt(data.tp_displayintable);
      this.selectedFields.tpProfilePage = parseInt(data.tp_is_edit);
      this.temporarySelectedEditRoles =
        data?.[userRolesKeysAPIDTO.PERMISSIONS]?.[0]?.[
          userRolesKeysAPIDTO.ROLES
        ] || [];
      this.setSelectedRoles();
    },
    makeDataValueList() {
      return [
        {
          label: null,
          value: null
        }
      ];
    },
    resetModal() {
      this.resetErrorMessages();
      this.showAddAttributeModal = false;
      this.attributeId = null;
      this.attributeSequenceNumber = 1;
      this.attributeName = null;
      this.attributeAlias = null;
      this.attributeIsRequire = 0;
      this.attributeDataType = null;
      this.attributeSubtype = null;
      this.attributeDescription = null;
      this.attributeStatus = {
        text: customAttributeActionsState.ACTIVE,
        value: 1
      };
      this.attributeIsRequireCheck = { text: "No", value: 0 };
      this.dataValues = this.makeDataValueList();
      this.selectedFields = {
        searchPage: 1,
        manageTpPage: 1,
        filterOnManageTpPage: 1,
        tpProfilePage: 1
      };
      this.isActive = null;
      this.editModalShow = false;
    },
    setAttributeSubType({ text }) {
      this.attributeSubtype = text;
    },
    setStatus({ value }) {
      this.isActive = value === 1 ? null : 2;
    },
    setIsRequire({ value }) {
      this.attributeIsRequire = value;
    },
    removeDataValueRow(index) {
      this.dataValues.splice(index, 1);
    },
    removeFirstDataValueRow(index, users) {
      if (this.dataValues.length > 1) {
        this.removeDataValueRow(index, users);
      } else {
        this.dataValues = this.makeDataValueList();
      }
    },
    addDataValueRow() {
      this.dataValues.push({
        label: null,
        value: null
      });
    },
    saveAttribute() {
      this.validateInputModels(this.inputModelList);
      if (!this.$v.$invalid) {
        if (this.editModalShow) {
          this.updateAttribute();
        } else {
          this.createAttribute();
        }
      }
    },
    changeSequence() {
      this.reorderBtnText = "Reorder";
      this.showReordering = false;
      this.table_col = false;
      this.items.forEach((value, index) => {
        value.tp_sequence = index + 1;
      });
      this.updateSequence();
    },
    async fetchCustomAttributeTypes() {
      try {
        this.isLoading = true;
        const { data } =
          await customAttributesService.fetchCustomAttributeTypes();
        this.customAttributeList = data.response;
      } catch (error) {
        return [error];
      }
    },
    makeCustomAttributesPostObject() {
      return {
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        filter: this.filter,
        comp_id: this.companyId,
        is_active: 0
      };
    },
    async fetchCustomAttributes() {
      try {
        this.isLoading = true;
        const { response } = await this.fetchCustomAttributesList(
          this.makeCustomAttributesPostObject()
        );
        this.items = response;
        this.fromRows = response === null ? 0 : 1;
        this.toRows = response?.length || 0;
        this.totalRows = response?.length || 0;
      } catch (error) {
        return [error];
      } finally {
        this.isLoading = false;
      }
    },
    makeAttributeStatusPostObject() {
      return {
        custom_attribute_id: this.attributeId,
        is_active: this.isActive === null ? 0 : 1,
        comp_id: this.companyId,
        comp_name: this.companyName,
        userId: this.userId
      };
    },
    async toggleAttributeStatus() {
      try {
        this.isLoading = true;
        const result = await customAttributesService.toggleAttributeStatus(
          this.makeAttributeStatusPostObject()
        );
        if (isValidResponse(result)) {
          this.$bvToast.toast(result.data.message, successModalConfig);
          await this.fetchCustomAttributes();
        }
      } catch (error) {
        return error;
      } finally {
        this.isLoading = false;
      }
    },
    makePostObjectToCreateAttribute() {
      const formData = {
        comp_id: this.companyId,
        attributeName: this.attributeName,
        sequenceNumber: this.attributeSequenceNumber,
        alias: this.attributeAlias,
        isRequire: this.attributeIsRequire,
        dataType: this.attributeDataType.value,
        tp_is_filter: this.selectedFields.filterOnManageTpPage,
        tp_is_search: this.selectedFields.searchPage,
        tp_displayintable: this.selectedFields.manageTpPage,
        tp_displayintpProfile: this.selectedFields.tpProfilePage,
        meta_json: {
          description: this.attributeDescription || ""
        },
        is_active: this.isActive === null ? 1 : 0,
        comp_name: this.companyName,
        userId: this.userId,
        [userRolesKeysAPIDTO.PERMISSIONS]: [
          {
            [userRolesKeysAPIDTO.PERMISSION]: userRolesKeysAPIDTO.EDIT_VALUE,
            [userRolesKeysAPIDTO.ROLES]: this.selectedRoles
          }
        ]
      };
      if (this.selectedSubtype) {
        formData.sub_type = this.selectedSubtype.id;
      }
      if (this.isListAttribute) {
        formData.dataValues = this.dataValues;
      }
      return formData;
    },
    async createAttribute() {
      try {
        this.isSubmitting = true;
        const result = await customAttributesService.createAttribute(
          this.makePostObjectToCreateAttribute()
        );
        this.$bvToast.toast(result.data.message, successModalConfig);
        this.resetModal();
        this.reloadTable();
      } catch (error) {
        return error;
      } finally {
        this.isSubmitting = false;
      }
    },
    makePostObjToUpdateAttribute() {
      return {
        ...this.makePostObjectToCreateAttribute(),
        eid: this.attributeId
      };
    },
    async updateAttribute() {
      try {
        this.isSubmitting = true;
        const result = await customAttributesService.updateAttribute(
          this.makePostObjToUpdateAttribute()
        );
        this.$bvToast.toast(result.data.message, successModalConfig);
        this.resetModal();
        this.reloadTable();
      } catch (error) {
        return error;
      } finally {
        this.isSubmitting = false;
      }
    },
    makePostObjectToDeleteAttribute() {
      return {
        comp_id: this.companyId,
        eid: this.attributeId,
        comp_name: this.companyName,
        userId: this.userId
      };
    },
    async deleteAttribute() {
      try {
        this.isLoading = true;
        const result = await customAttributesService.deleteAttribute(
          this.makePostObjectToDeleteAttribute()
        );
        if (hasStatus200(result)) {
          this.$bvToast.toast(result.data.message, successModalConfig);
          this.items = result.data.response;
          this.changeSequence();
        }
      } catch (error) {
        return error;
      } finally {
        this.isLoading = false;
      }
    },
    makePostObjToUpdateSequence() {
      return {
        sequence: this.items.map((value) => ({
          id: value.id,
          sequenceNumber: value.tp_sequence
        }))
      };
    },
    async updateSequence() {
      try {
        this.isLoading = true;
        const result = await customAttributesService.updateSequence(
          this.makePostObjToUpdateSequence()
        );
        if (isValidResponse(result)) {
          this.$bvToast.toast(result.data.message, successModalConfig);
          this.reloadTable();
          this.fetchCustomAttributesList(this.makeCustomAttributesPostObject());
        }
        return result.data;
      } catch (error) {
        return error;
      } finally {
        this.isLoading = false;
      }
    },
    updateEditPermissionRoleOptions({ eventType, value }) {
      let selectedRolesCopy = cloneDeep(this.selectedRoles);

      if (eventType === operations.ADD) {
        selectedRolesCopy.push(value);
      } else {
        selectedRolesCopy = selectedRolesCopy.filter(
          (roleValue) => roleValue !== value
        );
      }

      this.selectedRoles = selectedRolesCopy;
    },
    setAmendableRoles() {
      this.amendableRoles = this.roles.reduce((amendableRoles, { id }) => {
        if (id !== roles.ADMIN) {
          amendableRoles.push(id);
        }

        return amendableRoles;
      }, []);
    },
    setAllRoleOptions() {
      this.allRoleOptions = this.roles.map((role) => ({
        id: `${makeUUID()}`,
        text: role[userRolesKeysDTO.NAME],
        value: role[userRolesKeysDTO.ID]
      }));
    },
    setSelectedRoles() {
      if (this.editModalShow) {
        this.selectedRoles = this.temporarySelectedEditRoles;
        this.temporarySelectedEditRoles = [];
      } else {
        this.selectedRoles = [roles.ADMIN];
      }
    },
    setEditPermissionRoleOptions() {
      this.setAmendableRoles();
      this.setAllRoleOptions();
      this.setSelectedRoles();
    },
    setEditRoleOptions() {
      this.editRoleOptions = makeOptionsForMultiSelect(
        this.selectedRoles,
        this.amendableRoles,
        this.allRoleOptions
      );
    },
    resetSelectedRoleOptions() {
      this.selectedRoles = [roles.ADMIN];
    },
    getCustomAttributeCurrentStatus(isActive) {
      return isActive
        ? customAttributeActionsState.DEACTIVATED
        : customAttributeActionsState.ACTIVE;
    },
    getCustomAttributeFutureStatus(isActive) {
      return isActive
        ? customAttributeActionsState.ACTIVATE
        : customAttributeActionsState.DEACTIVATE;
    },
    renderPermissions(fields) {
      return this.getIdsToRoleNames(fields?.permissions?.[0]?.roles) || [];
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-attributes {
  &__breadcrumbs {
    margin: 0;
    background: transparent;
  }

  &__heading {
    text-align: left;
    background: $white;
    font-size: 18px;
    font-weight: 700;
    padding: 5px;
    margin-bottom: 15px;
  }

  &__table-row {
    text-align: center;
  }

  &__form {
    &-holder {
      text-align: left;
    }

    &-checkbox {
      display: flex;
      align-items: center;
    }

    &-buttons {
      margin-left: 20px;
    }

    &-error {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: $spanish-red;
    }

    &-input-holder {
      margin-left: 50px;
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
  }

  & .vs__search::placeholder,
  & .vs__dropdown {
    &-toggle,
    &-menu {
      max-height: 200px;
    }
  }

  &__edit-role {
    padding-bottom: 15px;
  }
}
</style>
