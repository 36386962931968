<template>
  <div>
    <b-modal
      scrollable
      body-bg-variant="light"
      no-close-on-backdrop
      v-model="isThirdPartyAddModalVisible"
      modal-class="px-5"
      size="xl"
      centered
      @hidden="onHideModal"
      title="Create New Third Party"
    >
      <div>
        <b-row v-if="!isLoading">
          <b-col>
            <b-card
              @click="openNewModal()"
              bg-variant="default"
              class="click-cursor card-wrapper text-center"
              data-test-id="add-model__button--single"
            >
              <b-card-text>
                <strong>Single</strong>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col>
            <b-card
              @click="onSelectCreateType"
              bg-variant="default"
              class="click-cursor card-wrapper text-center"
              data-test-id="add-model__button--import-data"
            >
              <b-card-text>
                <strong>Import Data</strong>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <BaseLoader v-else />
      </div>
      <template v-slot:modal-footer>
        <b-col cols="5" class="text-right p-0">
          <b-button
            class="red-btn"
            variant="outline-danger"
            v-if="!isLoading"
            @click="resetModal"
            >Cancel</b-button
          >
        </b-col>
      </template>
    </b-modal>
    <BulkUpload
      v-if="isBulkUploadModalVisible"
      :companyId="companyId"
      :fileFormats="fileFormats"
      :show-modal="isBulkUploadModalVisible"
      @close-modal="onHideModal"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import BulkUpload from "./bulkUploadModal";
import { urls, mutationName, actionName } from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";

export default {
  name: "AddThirdPartyModal",
  components: {
    BulkUpload,
    BaseLoader
  },
  props: {
    companyId: Number
  },
  data() {
    return {
      urls,
      fileFormats: [{ text: "Excel 2012", value: "application/vnd.ms-excel" }],
      isLoading: false,
      isBulkUploadModalVisible: false
    };
  },
  computed: {
    ...mapState({
      isThirdPartyAddModalVisible: (state) =>
        state.thirdParty.isThirdPartyAddModalVisible
    })
  },
  methods: {
    ...mapActions({
      fetchPlatformFeature: actionName.THIRD_PARTY.FETCH_PLATFORM_FEATURE
    }),
    ...mapMutations({
      setIsThirdPartyAddModalVisible:
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_ADD_MODAL_VISIBLE
    }),
    openNewModal() {
      this.isLoading = true;
      this.fetchPlatformFeature();
    },
    onSelectCreateType() {
      this.isBulkUploadModalVisible = true;
    },
    resetModal() {
      this.isLoading = false;
      this.setIsThirdPartyAddModalVisible(false);
    },
    onHideModal() {
      this.isBulkUploadModalVisible = false;
      this.setIsThirdPartyAddModalVisible(false);
    }
  }
};
</script>
<style lang="scss">
.click-cursor:hover {
  cursor: pointer;
}
.card-wrapper {
  box-shadow: 0 0px 0px rgba($black, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}
.card-wrapper:hover {
  box-shadow: 2px 5px 10px 0px $granite-gray;
  transition: box-shadow 0.3s ease-in-out;
}
.green-btn {
  color: $eucalyptus;
  border-color: $eucalyptus;
}
.green-btn:hover {
  color: $white;
  background-color: $eucalyptus;
  border-color: $eucalyptus;
}
.red-btn {
  color: $alizarin-crimson;
  border-color: $alizarin-crimson;
}
.red-btn:hover {
  color: $white;
  background-color: $alizarin-crimson;
  border-color: $alizarin-crimson;
}
</style>
