<template>
   <div>
        <b-card
            style="margin-right: 20px;min-width: 151px;max-height: 151px;"
            class="text-center statsCard"
        >
        <span>
            <b>
                {{$t('eb360.statsTabChart.progressCard')}}
            </b>
            </span>
            <b-card-text class="text-primary text-center">
            <span style="color: #515254; font-weight: bold; font-size: 40px">
                {{ overallValues }}
            </span>
            <br>
            <span style="color: #515254;">
                {{ overallValuesPer}}%
            </span>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
export default {
  name: "DDQInProgressCountCard",
  props:{
      overallValues: Number,
      overallValuesPer: Number
  },
}
</script>