var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-pagination",attrs:{"data-test-id":"base-pagination"}},[_c('button',{class:[
      'base-pagination__button',
      _vm.disableFirstPageLink && 'base-pagination__button--disabled'
    ],attrs:{"title":"First page","type":"button","aria-disabled":_vm.disableFirstPageLink,"tabindex":_vm.disableFirstPageLink ? -1 : 0},on:{"click":function($event){return _vm.updatePageNumberEvent(1)}}},[_c('font-awesome-icon',{attrs:{"icon":"angle-double-left"}})],1),_c('button',{class:[
      'base-pagination__button',
      _vm.disablePreviousPageLink && 'base-pagination__button--disabled'
    ],attrs:{"title":"Previous page","type":"button","aria-disabled":_vm.disablePreviousPageLink,"tabindex":_vm.disablePreviousPageLink ? -1 : 0},on:{"click":function($event){return _vm.updatePageNumberEvent(_vm.currentPage - 1)}}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left"}})],1),(!_vm.disableFirstPageLink)?_c('div',{class:[
      'base-pagination__button',
      'base-pagination__button--display-only'
    ]},[_vm._v(" ... ")]):_vm._e(),_vm._l((_vm.getPagesToShow),function(pageNumber){return _c('button',{key:`paginated-page-number-${pageNumber}`,class:[
      'base-pagination__button',
      _vm.isCurrentPage(pageNumber) && `base-pagination__button--active`
    ],attrs:{"type":"button","title":`Page number ${pageNumber}`},on:{"click":function($event){return _vm.updatePageNumberEvent(pageNumber)}}},[_vm._v(" "+_vm._s(pageNumber)+" ")])}),(!_vm.disableLastPageLink)?_c('div',{class:[
      'base-pagination__button',
      'base-pagination__button--display-only'
    ]},[_vm._v(" ... ")]):_vm._e(),_c('button',{class:[
      'base-pagination__button',
      _vm.disableNextPageLink && 'base-pagination__button--disabled'
    ],attrs:{"type":"button","title":"Next page","aria-disabled":_vm.disableNextPageLink,"tabindex":_vm.disableNextPageLink ? -1 : 0},on:{"click":function($event){return _vm.updatePageNumberEvent(_vm.currentPage + 1)}}},[_c('font-awesome-icon',{attrs:{"icon":"angle-right"}})],1),_c('button',{class:[
      'base-pagination__button',
      _vm.disableLastPageLink && 'base-pagination__button--disabled'
    ],attrs:{"type":"button","title":"Last page","aria-disabled":_vm.disableLastPageLink,"tabindex":_vm.disableLastPageLink ? -1 : 0},on:{"click":function($event){return _vm.updatePageNumberEvent(_vm.getTotalPages)}}},[_c('font-awesome-icon',{attrs:{"icon":"angle-double-right"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }