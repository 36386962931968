<template>
  <div class="body-container">
    <div class="card-header text-left">
      <font-awesome-icon icon="list-ol" />&nbsp;Order Sections
      <span class="float-right">
        <b-button
          v-if="!isFormPublished"
          variant="outline-primary"
          size="sm"
          @click="addSection(form1.formSection.length, 'section')"
        >
          <font-awesome-icon icon="plus" />
        </b-button>
      </span>
    </div>

    <draggable
      tag="ul"
      v-model="form1.formSection"
      class="nav navbar-nav text-left pl-1 pr-1"
      ghost-class="ghost"
    >
      <transition-group type="transition" name="flip-list">
        <li
          class="list-group-item"
          v-for="(section, sIndex) in form1.formSection"
          :key="section.id"
        >
          <font-awesome-icon
            icon="cog"
            class="text-primary"
            @click="openSettings(section)"
          />&nbsp;&nbsp;
          <font-awesome-icon
            icon="clone"
            class="text-warning"
            @click="onCloneSection(section)"
            v-if="form1.formType == 1 && !isFormPublished"
          />&nbsp;&nbsp;
          <font-awesome-icon
            icon="trash"
            class="text-danger"
            @click="$bvModal.show(section.id)"
            v-if="form1.formType == 1 && !isFormPublished"
          />
          <b-badge class="float-right" variant="dark">{{
            section.formElements.length
          }}</b-badge>
          <br />
          <font-awesome-icon
            v-b-toggle.collapse-section
            v-if="section.formElements.length > 0"
            icon="caret-down"
          />&nbsp;
          <strong>{{ section.sectionTitle[selectedLanguage] }}</strong>
          <span class="caret" v-if="section.formElements.length > 0"></span>
          <b-collapse id="collapse-section" class="mt-2">
            <br />
            <!-- render elements -->
            <draggable
              tag="ul"
              v-model="section.formElements"
              class="nav navbar-nav text-left orderable mt-2"
              ghost-class="ghost"
            >
              <li
                class="list-group-item"
                v-for="(element, eIndex) in section.formElements"
                :key="element.id"
              >
                {{ eIndex + 1 + ". " }} {{ element.label[selectedLanguage] }}
                <font-awesome-icon
                  v-if="element.icon"
                  class="float-right text-info"
                  :icon="element.icon"
                />
              </li>
            </draggable>
          </b-collapse>
          <b-modal
            :id="section.id"
            title="Alert!"
            @ok="onDeleteSection(sIndex)"
          >
            <p class="my-4">Confirm delete?</p>
          </b-modal>
        </li>
      </transition-group>
    </draggable>
    <b-modal
      ok-only
      ok-title="Close"
      size="lg"
      v-model="showSettings"
      scrollable
      title="Section Settings"
    >
      <SectionSettings :sectionData="selectedSection" />
    </b-modal>
  </div>
</template>
<script>
import uid from "uid";
import draggable from "vuedraggable";
import { mapMutations, mapGetters, mapActions } from "vuex";
import SectionSettings from "@/components/sectionSettingsCompenent";
import { Helper } from "@/helpers";

export default {
  name: "ComponentListView",
  enabled: true,
  components: {
    draggable,
    SectionSettings
  },
  data() {
    return {
      selectedSection: null,
      showSettings: false
    };
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"])
  },

  props: {
    isPreview: Boolean,
    isFormPublished: Boolean
  },
  methods: {
    ...mapMutations(["ADD_SECTION", "TOGGLE_ALL_COLLAPSE"]),

    ...mapActions(["removeSection", "cloneSection"]),

    openSettings(section) {
      this.selectedSection = section;
      this.showSettings = !this.showSettings;
    },

    onCloneSection(section) {
      this.cloneSection(section);
    },

    onDeleteSection(sIndex) {
      this.removeSection(sIndex);
      this.$bvModal.hide("modal-1");
      Helper.makeToast(this.$bvToast, "success", "Section deleted");
    },

    addSection(length, type) {
      let id = uid();
      let newSection;
      let title;
      this.sectionLength = length;
      switch (type) {
        case "section":
        default:
          title = `Section ${length + 1} Title`;
          newSection = {
            type: "section",
            id,
            index: length,
            name: "",
            sectionStyle: {
              backgroundColor: null,
              backgroundImage: null,
              backgroundSize: null,
              backgroundRepeat: null,
              backgroundPosition: null,
              height: null,
              width: null,
              opacity: 1,
              padding: {
                top: null,
                right: null,
                bottom: null,
                left: null
              },
              margin: {
                top: null,
                right: null,
                bottom: null,
                left: null
              },
              border: {
                top: null,
                right: null,
                bottom: null,
                left: null
              }
            },
            advance: { hideSectionTitle: false },
            isRepeatable: false,
            sectionTitle: {
              english: title
            },
            formElements: []
          };

          break;
      }
      this.ADD_SECTION(newSection);
    }
  }
};
</script>
<style scoped>
.body-container {
  height: calc(100vh - 190px);
  overflow-y: auto;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  cursor: move;
}
</style>
