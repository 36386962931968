var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-5"},[_c('b-card',{staticClass:"shadow-sm pt-0 pl-0 pr-0 m-4 mb-0",attrs:{"no-body":""}},[_c('b-navbar',{attrs:{"toggleable":"lg"}},[_c('b-navbar-brand',{staticClass:"text-dark",attrs:{"href":"#"}},[_c('strong',{staticStyle:{"color":"#3b3e66"}},[_vm._v("Action Settings for "+_vm._s(_vm.companyName))])]),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-form',[_c('b-button',{staticClass:"mr-4",attrs:{"variant":"outline-info","size":"sm"},on:{"click":_vm.reloadTable}},[_c('font-awesome-icon',{attrs:{"icon":"sync"}})],1),(_vm.showEditBtn)?_c('b-btn',{staticClass:"ml-1",attrs:{"disabled":_vm.isLoading,"size":"sm","variant":"outline-warning"},on:{"click":_vm.onEditTable}},[_vm._v("Edit")]):_c('div',[_c('b-btn',{staticClass:"ml-1",attrs:{"disabled":_vm.isLoading,"size":"sm","variant":"outline-danger"},on:{"click":_vm.onCancelActionSettings}},[_vm._v("Cancel")]),_c('b-btn',{staticClass:"ml-1",attrs:{"disabled":_vm.isLoading,"size":"sm","variant":"outline-success"},on:{"click":_vm.onShowSubmitModal}},[_vm._v("Submit")])],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[(_vm.isTableLoading)?_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.tablecolumns,"rows":_vm.tablerows,"group-options":{
            enabled: true
          },"max-height":"500px","fixed-header":true},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action_max_volume')?_c('span',[(!_vm.isLoadingDisabled)?_c('div',{staticClass:"d-flex"},[_c('div',[(props.row.actionKeyType == 'actionsOccur')?_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.disableAnticipatedVolume,"size":"sm","onkeydown":"return event.keyCode !== 69","type":"number","placeholder":"Anticipated volume"},model:{value:(
                        _vm.actionArray.actionsOccur[props.index]
                          .action_max_volume
                      ),callback:function ($$v) {_vm.$set(_vm.actionArray.actionsOccur[props.index]
                          , "action_max_volume", $$v)},expression:"\n                        actionArray.actionsOccur[props.index]\n                          .action_max_volume\n                      "}})],1):_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.disableAnticipatedVolume,"size":"sm","onkeydown":"return event.keyCode !== 69","type":"number","placeholder":"Anticipated volume"},model:{value:(
                        _vm.actionArray.extraActions[props.index]
                          .action_max_volume
                      ),callback:function ($$v) {_vm.$set(_vm.actionArray.extraActions[props.index]
                          , "action_max_volume", $$v)},expression:"\n                        actionArray.extraActions[props.index]\n                          .action_max_volume\n                      "}})],1)],1),_c('div')]):_vm._e()]):(props.column.field == 'enable_action_notifications')?_c('span',[_c('b-form-checkbox',{staticClass:"text-center",model:{value:(
                  _vm.actionArray[props.row.actionKeyType][props.index]
                    .enable_action_notifications
                ),callback:function ($$v) {_vm.$set(_vm.actionArray[props.row.actionKeyType][props.index]
                    , "enable_action_notifications", $$v)},expression:"\n                  actionArray[props.row.actionKeyType][props.index]\n                    .enable_action_notifications\n                "}})],1):(
                props.column.field == 'action_notification_trigger_count'
              )?_c('span',[(!_vm.isLoadingDisabled)?_c('div',{staticClass:"d-flex"},[_c('div',[(props.row.actionKeyType == 'actionsOccur')?_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.disableNotificationCount,"size":"sm","onkeydown":"return event.keyCode !== 69","type":"number","placeholder":"Notification percentage"},model:{value:(
                        _vm.actionArray.actionsOccur[props.index]
                          .action_notification_trigger_count
                      ),callback:function ($$v) {_vm.$set(_vm.actionArray.actionsOccur[props.index]
                          , "action_notification_trigger_count", $$v)},expression:"\n                        actionArray.actionsOccur[props.index]\n                          .action_notification_trigger_count\n                      "}})],1):_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.disableNotificationCount,"size":"sm","onkeydown":"return event.keyCode !== 69","type":"number","placeholder":"Notification percentage"},model:{value:(
                        _vm.actionArray.extraActions[props.index]
                          .action_notification_trigger_count
                      ),callback:function ($$v) {_vm.$set(_vm.actionArray.extraActions[props.index]
                          , "action_notification_trigger_count", $$v)},expression:"\n                        actionArray.extraActions[props.index]\n                          .action_notification_trigger_count\n                      "}})],1)],1),_c('div')]):_vm._e()]):_vm._e()]}}],null,false,3245338711)})],1):_vm._e()])],1),_c('b-modal',{attrs:{"no-close-on-backdrop":"","no-close-on-esc":"","title":"Submit Settings"},on:{"ok":_vm.onSubmitActionsData,"cancel":_vm.onCloseModal},model:{value:(_vm.showSubmitModal),callback:function ($$v) {_vm.showSubmitModal=$$v},expression:"showSubmitModal"}},[_c('p',[_vm._v("Are you sure you want to submit these settings")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }