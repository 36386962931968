<template>
  <div
    :class="[
      'base-badge',
      `base-badge--${theme}`,
      `base-badge__${getSize}`,
      `base-badge__white-space-${whiteSpace}`,
      `base-badge__text-align-${textAlign}`
    ]"
    data-test-id="base-badge"
  >
    <span
      :class="[
        'base-badge__text',
        size === 'large' && 'base-badge__text--large'
      ]"
      data-test-id="base-badge__text"
      :style="{ minWidth: `${minimumWidth}` }"
    >
      <span
        :class="`base-badge__space base-badge__space--${textSpace}`"
        data-test-id="base-badge__space"
        >{{ text }}</span
      >
      <CallToAction
        v-if="isRemovable"
        class="base-badge__button"
        :theme="theme"
        :icon-size="12"
        icon="times"
        :aria-label="`remove ${text} badge`"
        :icon-space="iconSpace"
        data-test-id="base-badge__cta--remove"
        @click.stop="onRemoveBadge"
        @keyup.enter="onRemoveBadge"
      />
    </span>
  </div>
</template>

<script>
import {
  sizes,
  themes,
  typographySize,
  space,
  whiteSpace,
  textAlign
} from "@/constants";
import CallToAction from "@/atoms/CallToAction/CallToAction";

export default {
  name: "BaseBadge",
  props: {
    text: {
      type: String,
      default: ""
    },
    theme: {
      type: String,
      default: themes.NONE,
      validator: (value) => Object.values(themes).includes(value)
    },
    minimumWidth: {
      type: String,
      default: "0px"
    },
    size: {
      type: String,
      default: typographySize.BODY_TEXT_SMALL,
      validator: (value) =>
        [
          typographySize.BODY_TEXT_SMALL,
          typographySize.BODY_TEXT_BOLD,
          typographySize.DISPLAY_TEXT
        ].includes(value)
    },
    badgeSize: {
      type: String,
      default: sizes.DEFAULT,
      validator: (value) => [sizes.DEFAULT, sizes.LARGE].includes(value)
    },
    iconSpace: {
      type: String,
      default: space.MODERATE,
      validator: (value) =>
        [space.NONE, space.MODERATE, space.LARGE, space.SMALL].includes(value)
    },
    textSpace: {
      type: String,
      default: space.MODERATE,
      validator: (value) =>
        [space.NONE, space.MODERATE, space.LARGE, space.SMALL].includes(value)
    },
    isRemovable: {
      type: Boolean,
      default: false
    },
    whiteSpace: {
      type: String,
      default: whiteSpace.NORMAL,
      validator: (value) =>
        [whiteSpace.NORMAL, whiteSpace.NO_WRAP].includes(value)
    },
    textAlign: {
      type: String,
      default: textAlign.CENTER,
      validator: (value) => [textAlign.LEFT, textAlign.CENTER].includes(value)
    }
  },
  components: {
    CallToAction
  },
  data() {
    return {
      space
    };
  },
  computed: {
    getSize() {
      return this.badgeSize === sizes.LARGE
        ? typographySize.DISPLAY_TEXT
        : this.size;
    }
  },
  methods: {
    onRemoveBadge() {
      this.$emit("remove-badge");
    }
  }
};
</script>

<style lang="scss" scoped>
.base-badge {
  &.base-badge__text-align-center {
    text-align: center;
  }

  &.base-badge__text-align-left {
    text-align: left;
  }

  &.base-badge__white-space-no-wrap {
    white-space: nowrap;
  }

  &.base-badge__white-space-normal {
    white-space: normal;
  }

  &__space--moderate {
    padding: 4px 15px 2px;
  }

  &__space--small {
    padding: 0 5px;
  }

  &__text {
    display: flex;
    border-radius: 5px;
    justify-content: center;
    margin: 0;
    align-items: stretch;

    &--large {
      padding: 6px 15px 4px;
    }
  }

  &__body-text--small .base-badge__text {
    @include body-text-small;
    font-weight: 700;
  }

  &__body-text--bold .base-badge__text {
    @include body-text-bold;
  }

  &__display-text .base-badge__text {
    @include display-text;
  }

  @each $theme, $primary-color, $secondary-color, $has-border in $themes {
    &--#{$theme} .base-badge__text {
      background: $primary-color;
      color: $secondary-color;

      @if ($has-border) {
        border: 1px solid $spanish-gray;
      }

      & .base-badge__button {
        border-radius: 0 5px 5px 0;
        border-left: 1px solid $secondary-color;
      }
    }
  }
}
</style>
