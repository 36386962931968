const downloadCSVData = (data) => {
  const fileData = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");

  link.setAttribute("href", fileData);
  link.setAttribute("download", "ExportData.xlsx");
  link.click();
  link.remove();
  return true;
};

export { downloadCSVData };
