<template>
  <button
    data-test-id="call-to-action"
    v-bind="$attrs"
    v-on="$listeners"
    :class="getClassNames"
    :disabled="getIsDisabled"
    :type="getButtonType"
    :title="value"
    tabindex="0"
  >
    <BaseIcon
      v-if="hasState"
      v-bind="getIcon"
      :class="[
        'call-to-action__icon',
        hasIconWithText && 'call-to-action__icon--with-text'
      ]"
      data-test-id="call-to-action__icon"
    />
    <span
      :class="[
        'call-to-action__text',
        { 'call-to-action__text--hidden': hasState && !hasIconWithText }
      ]"
      data-test-id="call-to-action__text"
    >
      {{ value }}
    </span>
  </button>
</template>

<script>
import { space } from "@/constants";
import { isValidTheme, isValidShape } from "@/utils";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "CallToAction",
  components: {
    BaseIcon
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    theme: {
      type: String,
      default: "primary",
      validator(value) {
        return isValidTheme(value);
      }
    },
    buttonType: {
      type: String,
      default: "button",
      validator(value) {
        return ["button", "reset", "submit"].includes(value);
      }
    },
    iconSpace: {
      type: String,
      default: space.MODERATE,
      validator: (value) =>
        [space.LARGE, space.MODERATE, space.SMALL, space.NONE].includes(value)
    },
    iconShape: {
      type: String,
      default: "none",
      validator(value) {
        return isValidShape(value);
      }
    },
    iconSize: {
      type: Number,
      default: 20
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasIconWithText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allowedButtonTypes: ["button", "reset", "submit"],
      space
    };
  },
  computed: {
    getTheme() {
      return isValidTheme(this.theme) ? this.theme : "none";
    },
    getButtonType() {
      return this.allowedButtonTypes.includes(this.buttonType)
        ? this.buttonType
        : "button";
    },
    hasState() {
      return this.isLoading || this.isSuccess || this.isError || !!this.icon;
    },
    getClassNames() {
      const classes = ["call-to-action"];

      if (this.icon) {
        classes.push(`call-to-action--icon-${this.iconSpace}`);
      }

      if (this.iconShape === "none") {
        classes.push(`call-to-action--theme-${this.getTheme}`);
      } else {
        classes.push("call-to-action--theme-default");
      }

      if (this.isLoading) {
        classes.push("call-to-action--state-loading");
      } else if (this.isSuccess) {
        classes.push("call-to-action--state-success");
      } else if (this.isError) {
        classes.push("call-to-action--state-error");
      }

      if (this.isDisabled) {
        classes.push("call-to-action--state-disabled");
      }

      return classes;
    },
    getIcon() {
      if (this.isLoading) {
        return {
          icon: "spinner",
          size: 20,
          theme: this.getTheme
        };
      }

      if (this.isSuccess) {
        return {
          icon: "check",
          size: 20,
          theme: "success"
        };
      }

      if (this.isError) {
        return {
          icon: "times",
          size: 20,
          theme: "error"
        };
      }

      if (this.icon) {
        return {
          icon: this.icon,
          shape: this.iconShape,
          size: this.iconSize,
          theme: this.getTheme
        };
      }

      return null;
    },
    getIsDisabled() {
      return (
        this.isDisabled || this.isError || this.isLoading || this.isSuccess
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.call-to-action {
  padding: 6px 12px;
  position: relative;
  border-radius: 5px;
  transition: background-color 0.5s ease;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--with-text {
      display: contents;
    }
  }

  &--icon {
    &-none {
      height: auto;
      width: auto;
      padding-left: 9px;
      padding-right: 9px;
    }

    &-small {
      height: 20px;
      width: 20px;
    }

    &-moderate {
      height: 30px;
      width: 30px;
    }

    &-large {
      height: 34px;
      width: 34px;
    }
  }

  &__text {
    &--hidden {
      visibility: hidden;
    }
  }

  @each $theme, $primary-color, $secondary-color, $has-grey-border in $themes {
    &--theme-#{$theme} {
      background-color: $primary-color;
      color: $secondary-color;
      border: 1px solid $primary-color;

      @if $has-grey-border {
        border-color: $chinese-silver;
      }

      &:hover,
      &:focus {
        background-color: $secondary-color;
        color: $primary-color;

        &:deep(.base-icon) {
          color: $primary-color;
        }
      }
    }
  }

  &--theme-default {
    border: none;
    background: transparent;
  }

  // States
  &--state-success {
    background-color: $apple-green;
    border-color: $apple-green;
  }

  &--state-error {
    background-color: $spanish-red;
    border-color: $spanish-red;
  }

  &--state-loading,
  &--state-success,
  &--state-error,
  &--state-disabled {
    pointer-events: none;
  }

  &--state-disabled {
    opacity: 0.5;
  }

  &--state-loading {
    :deep(.base-icon__icon) {
      @include rotate(1s);
    }
  }
}
</style>
