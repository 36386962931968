import { state as makeState } from "./version.state.store";

export const mutations = {
  setLastRecordedUIVersion(state, payload) {
    state.lastRecordedUIVersion = payload;
  },
  resetVersionState(state) {
    Object.assign(state, makeState());
  }
};
