<template>
  <div
    :class="['message-banner', `message-banner__theme--${theme}`]"
    data-test-id="message-banner"
  >
    <BaseText
      :text="message"
      :theme="theme"
      data-test-id="message-banner__text"
      class="message-banner__text"
    />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import { themes } from "@/constants";

export default {
  name: "MessageBanner",
  components: { BaseText },
  props: {
    message: {
      type: String,
      default: ""
    },
    theme: {
      type: String,
      default: themes.NONE,
      validator: (value) => Object.values(themes).includes(value)
    }
  }
};
</script>

<style lang="scss" scoped>
.message-banner {
  border-radius: 5px;
  padding: 15px 15px;
  width: 100%;

  &:deep(.message-banner__text) {
    margin: 0;
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme} {
      background: $primary-color;
    }
  }
}
</style>
