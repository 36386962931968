<template>
  <div>
    <div v-for="(opt, index) in sec.section" :key="index">
      <b-row>
        <b-col>
          <b-card class="my-4" footer-tag="footer">
            <div class="d-flex" style="justify-content: space-between">
              <div style="margin: auto">
                <h4
                  class="text-center font-size-text"
                  :style="opt.questionTableAbbreviation ? '' : 'display: none;'"
                >
                  {{ opt.questionTableAbbreviation }}
                </h4>
              </div>
            </div>

            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <BaseChart :chartOptions="opt.series" />
                </b-col>
              </b-row>
            </b-card-body>
            <div class="d-flex">
              <div>
                <p>
                  % of suppliers who selected to
                  {{ opt.questionTableAbbreviation }}
                  <span style="color: #4d9de0">{{
                    opt.avg_per.avg == 0
                      ? "DID NOT CHANGE"
                      : opt.avg_per.status == 1
                      ? "INCREASED"
                      : "DECREASED"
                  }}</span>
                </p>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BaseChart from "@/atoms/BaseChart/BaseChart";

export default {
  name: "DdqComparativeGraph",
  components: {
    BaseChart
  },
  props: {
    sec: Object
  }
};
</script>
