var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2',attrs:{"dir":_vm.$store.state.rightAlign ? 'rtl' : 'ltr'}},[(!_vm.isPreview)?_c('MenuButtons',{attrs:{"fieldData":_vm.fieldData,"collapse":_vm.collapse,"label":_vm.label,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings},"collapse-field":function($event){_vm.collapse = !_vm.collapse}}}):_vm._e(),_c('b-collapse',{directives:[{name:"show",rawName:"v-show",value:(JSON.parse(!_vm.fieldData.isHidden) || !_vm.isPreview),expression:"JSON.parse(!fieldData.isHidden) || !isPreview"}],class:_vm.isPreview ? 'mt-0' : 'mt-5',attrs:{"id":"collapse-1"},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.fieldData.isConditional == '1' && !_vm.isPreview)?_c('b-button',{attrs:{"variant":"outline-dark","disabled":"","title":"This field is hidden by default and will display bt is controlled element"}},[_c('font-awesome-icon',{attrs:{"icon":"eye-slash"}})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"id":_vm.fieldData.id,"description":_vm.fieldData.helperText[_vm.selectedLanguage],"invalid-feedback":_vm.fieldData.validationMessage[_vm.selectedLanguage]}},[_c('label',[(
                _vm.fieldData.validation && _vm.fieldData.validation.required == 1
              )?_c('span',{staticClass:"required-span"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.form1.enableSectionFieldEnumeration == 1 ? _vm.sectionIndex + 1 + "." + (_vm.fieldIndex + 1) : "")+" "+_vm._s(_vm.label ? _vm.label : _vm.fieldData.label[_vm.selectedLanguage])+" ")]),_c('b-form-textarea',{class:_vm.$store.state.rightAlign ? 'text-right' : 'text-left',attrs:{"disabled":_vm.isDisabled == 1,"id":_vm.fieldData.id,"placeholder":_vm.placeholder
                ? _vm.placeholder
                : _vm.fieldData.placeholder[_vm.selectedLanguage],"state":_vm.isFocus &&
              _vm.fieldData.validation &&
              JSON.parse(_vm.fieldData.validation.required)
                ? !_vm.$v.name.required
                  ? false
                  : true
                : null,"min-rows":"6","max-rows":"100"},on:{"input":_vm.setValue,"blur":_vm.checkValidate,"focus":function($event){_vm.isFocus = true}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)],1),_c('b-modal',{attrs:{"cancel-title":"Close","ok-title":"Save","size":"lg","title":"Textarea advanced Settings"},on:{"ok":_vm.onSaveData},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}},[_c('AdvanceTextAreaSettings',{attrs:{"fieldData":_vm.fieldData,"sectionIndex":_vm.sectionIndex,"fieldIndex":_vm.fieldIndex},on:{"toggle-field":function($event){_vm.showSettings = !_vm.showSettings}}})],1)],1),_c('b-alert',{staticClass:"position-fixed fixed-top m-0 rounded-0",staticStyle:{"z-index":"2000"},attrs:{"variant":"danger","dismissible":"","solid":"","change":_vm.hideAllError()},model:{value:(_vm.showErrorAlert),callback:function ($$v) {_vm.showErrorAlert=$$v},expression:"showErrorAlert"}},[_vm._v("One or more Errors Found, please have a look.!")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }