<template>
  <div>
    <h5 class="font-size-text">Third Party Business Operations</h5>
    <div class="mb-5">
      <highcharts
        :constructorType="'mapChart'"
        class="hc"
        :options="mapChartOptions"
        ref="chart"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SupplierBusinessOperationsComponent",
  props: {
    mapChartOptions: Object
  }
};
</script>
