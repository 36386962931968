<template id="template-input">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <!-- input content -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-col cols="12">
        <p class="text-left">{{ fieldData.label[selectedLanguage] }}</p>
      </b-col>
    </b-collapse>

    <b-modal
      ok-only
      ok-title="Close"
      size="lg"
      v-model="showSettings"
      scrollable
      title="Paragraph Settings"
    >
      <AdvanceHeadingSettings
        :fieldData="fieldData"
        v-on:toggle-field="showSettings = !showSettings"
        :sectionIndex="sectionIndex"
        :fieldIndex="fieldIndex"
      />
    </b-modal>
  </div>
</template>

<script>
import AdvanceHeadingSettings from "../advanceHeadingSettingsComponent";
import MenuButtons from "../menuButtons";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ParagraphGroup",
  components: {
    AdvanceHeadingSettings,
    MenuButtons
  },
  data() {
    return {
      isHidden: false,
      label: undefined,
      collapse: true,
      showSettings: false
    };
  },
  mounted() {
    this.label = this.fieldData.label[this.selectedLanguage];
  },
  computed: {
    ...mapGetters(["form1", "selectedLanguage"]),
    ...mapState(["toggleAllCoppalse"])
  },
  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },
  methods: {
    setValue() {
      this.fieldData.label[this.selectedLanguage] = this.label;
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number
  }
};
</script>
<style scoped>
.custom-input-tag-h1 {
  background-color: transparent;
  height: 60px;
  font-size: 36px;
  border: 0;
  width: auto;
}
.custom-input-tag-h2 {
  background-color: transparent;
  height: 50px;
  font-size: 26px;
  border: 0;
  width: auto;
}
.custom-input-tag-h3 {
  background-color: transparent;
  height: 40px;
  font-size: 20px;
  border: 0;
  width: auto;
}
</style>
