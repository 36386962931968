var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionares-page"},[_c('BaseText',{staticClass:"questionares-page__title",attrs:{"text":_vm.$t('questionnaires.title'),"size":_vm.typographySize.BODY_TEXT_BOLD}}),(_vm.isLoading)?_c('AppLoader',{staticClass:"text-center",attrs:{"small":""}}):_vm._e(),_c('b-row',{staticClass:"ml-2 mt-2 mr-2",staticStyle:{"height":"calc(100vh - 200px)","overflow":"auto"}},_vm._l((_vm.questionnaires),function(questionnaire,index){return _c('b-col',{key:index,attrs:{"cols":"3"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"height":"350px"},attrs:{"bg-variant":"default"}},[_c('b-card-text',{staticClass:"my-3"},[_c('font-awesome-icon',{staticClass:"supplier__icon",attrs:{"icon":"file-signature","size":"5x"}})],1),_c('b-card-text',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.getFormTitle(questionnaire)))]),(questionnaire.comp_name_eng && questionnaire.country)?_c('b-card-text',{staticClass:"mb-0 text-capitalize"},[_vm._v("("+_vm._s(questionnaire.comp_name_eng)+" - "+_vm._s(questionnaire.country)+")")]):_vm._e(),_c('b-card-text',{staticClass:"mb-0 text-capitalize"},[_vm._v(_vm._s(_vm.$t("questionnaires.requestedDate"))+": "+_vm._s(_vm.dateFormat(questionnaire.onboarding_stage_tasks_created_at)))]),_c('b-card-text',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(_vm.getQuestionnaireStatus(questionnaire.task_status))}}),(
            questionnaire.task_status < 4 || questionnaire.task_status == 14
          )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.getTaskForm(
              questionnaire.form_id,
              questionnaire.type,
              questionnaire.id,
              questionnaire.task_title
            )}}},[_vm._v(" "+_vm._s(_vm.getQuestionnaireButtonLabel(questionnaire.task_status))+" ")]):(questionnaire.task_status == 4)?_c('b-button',{attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.downloadPdf(questionnaire, '/download/questionnaire')}}},[_c('font-awesome-icon',{attrs:{"icon":"file-pdf"}})],1):_vm._e()],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }