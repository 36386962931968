import { actionTypes, expectedDataTypes } from "@/constants";

export const getters = {
  getSystemActions: (state) =>
    state.actions.filter(
      ({ isSystem }) => isSystem === actionTypes.SYSTEM_ACTIONS
    ),
  getDDQTasks: (state) =>
    state.expectedDataEntities.filter(
      ({ entity }) => entity === expectedDataTypes.DDQ_TASK
    )
};
