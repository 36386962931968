var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g(_vm._b({class:_vm.getClassNames,attrs:{"data-test-id":"call-to-action","disabled":_vm.getIsDisabled,"type":_vm.getButtonType,"title":_vm.value,"tabindex":"0"}},'button',_vm.$attrs,false),_vm.$listeners),[(_vm.hasState)?_c('BaseIcon',_vm._b({class:[
      'call-to-action__icon',
      _vm.hasIconWithText && 'call-to-action__icon--with-text'
    ],attrs:{"data-test-id":"call-to-action__icon"}},'BaseIcon',_vm.getIcon,false)):_vm._e(),_c('span',{class:[
      'call-to-action__text',
      { 'call-to-action__text--hidden': _vm.hasState && !_vm.hasIconWithText }
    ],attrs:{"data-test-id":"call-to-action__text"}},[_vm._v(" "+_vm._s(_vm.value)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }