var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',_vm._b({staticClass:"sticky-table",attrs:{"data-test-id":"sticky-table"}},'table',_vm.$attrs,false),[(_vm.caption)?_c('caption',{staticClass:"sticky-table__caption",attrs:{"data-test-id":"sticky-table__caption"}},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e(),_c('thead',{staticClass:"sticky-table__head"},_vm._l((_vm.tableHeaders),function({
          text,
          value,
          sortable,
          sortOrder,
          componentOptions,
          component,
          styles
        },index){return _c('TableHeader',{key:_vm.makeStringWithSeparatorWrapper(value),attrs:{"table-header":text,"sortable":sortable,"sort-order":sortOrder,"header-position":index,"component":component,"component-options":componentOptions,"styles":styles},on:{"click":_vm.onColumnHeaderClick}})}),1),_c('tbody',{staticClass:"sticky-table__body"},_vm._l((_vm.tableRows),function(tableRow,tableRowLevel){return _c('TableRow',{key:`table-row-${tableRowLevel}`,attrs:{"table-row":tableRow,"table-headers":_vm.tableHeaders,"table-row-level":tableRow.tableRowLevel,"styles":tableRow.styles},on:{"click":function($event){return _vm.onTableRow($event, tableRowLevel)}}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }