import { roles } from "./user.constant";
import { routeNames } from "./urls.constant";

const routeAccessRules = {
  [routeNames.LOGIN]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.FORGOT_PASSWORD]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.HOME]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.THIRD_PARTY_PROFILE]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.THIRD_PARTY_PROFILE_COMPANY]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.MANAGE_THIRD_PARTY]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.MANAGE_THIRD_PARTY_ID]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.PAGE_NOT_FOUND]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.ESG_THIRD_PARTIES]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EVENT_LOG]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EVENT_LOG_ID]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.USER_SETTINGS]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.NOTIFICATION_SETTINGS]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.BRANDING_AND_LINKS_SETTINGS]: [roles.SUPER_ADMIN],
  [routeNames.GENERAL_SETTINGS]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.CUSTOM_ATTRIBUTES]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EMAIL_TEMPLATE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.PLATFORM_SWITCHER]: [roles.SUPER_ADMIN],
  [routeNames.DDQ_ONBOARDING]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.ACTIONS_WRAPPER]: [roles.SUPER_ADMIN],
  [routeNames.ACTIONS]: [roles.SUPER_ADMIN],
  [routeNames.ADD_ACTION]: [roles.SUPER_ADMIN],
  [routeNames.EDIT_ACTION]: [roles.SUPER_ADMIN],
  [routeNames.EDIT_ACTION_ID]: [roles.SUPER_ADMIN],
  [routeNames.AUTOMATIONS]: [roles.SUPER_ADMIN],
  [routeNames.AUTOMATIONS_WRAPPER]: [roles.SUPER_ADMIN],
  [routeNames.ADD_AUTOMATION]: [roles.SUPER_ADMIN],
  [routeNames.EDIT_AUTOMATION]: [roles.SUPER_ADMIN],
  [routeNames.EDIT_AUTOMATION_ID]: [roles.SUPER_ADMIN],
  [routeNames.SET_PASSWORD]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.SET_PASSWORD_EMAIL_ID]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.PRODUCT_VERSION]: [roles.SUPER_ADMIN],
  [routeNames.ROLES_WRAPPER]: [roles.BASIC, roles.SUPER_ADMIN],
  [routeNames.ROLES]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.ADD_ROLE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EDIT_ROLE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EDIT_ROLE_ID]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.ANALYTICS_OVERVIEW]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.QUESTIONS_REFERENCE]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.ANALYTICS_USER_VIEW_ID]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.ULTIMATE_BENEFICIAL_OWNERS]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.ANALYTICS_QUESTIONNAIRES_ID]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.EDD_REPORT_TYPE]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.NOTIFICATION_SETTINGS]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.LIST_OF_SAMPLE_EXCEL_FILES]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.ANALYTICS_QUESTIONNAIRES_COMPARATIVE]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.GREENLITE_THIRD_PARTIES]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.ULTIMATE_BENEFICIAL_OWNERS_PROFILE]: [
    roles.BASIC,
    roles.ADMIN,
    roles.SUPER_ADMIN
  ],
  [routeNames.USER_FORM_PAGE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_TEMPLATES]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_FORM_CONFIGURATION]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_FORM_INTRO]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_FORM_DETAILS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_FORM_BUILDER]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_FORM_JSON]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_PLATFORM_SETTINGS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_SETTINGS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_EMAIL_TEMPLATES]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_ACTIIONS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_BULK_ACTIONS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_COLUMNS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_EDD_CONFIGURATION]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_ACTION_DETAILS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_ACTION_SETTINGS]: [roles.SUPER_ADMIN],
  [routeNames.ONBOARDING_CLIENTS_ANALYTICS]: [roles.SUPER_ADMIN],
  [routeNames.ANALYTICS]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.USER_VIEWS]: [roles.BASIC, roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.PLATFORM_SETTINGS]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.USER_EMAIL_TEMPLATES]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.ADD_USER_EMAIL_TEMPLATE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EDIT_USER_EMAIL_TEMPLATE]: [roles.ADMIN, roles.SUPER_ADMIN],
  [routeNames.EDIT_USER_EMAIL_TEMPLATE_ID]: [roles.ADMIN, roles.SUPER_ADMIN]
};

export { routeAccessRules };
