<template>
  <div class="checkbox" @click="$emit('click', $event)">
    <input
      :id="id"
      type="checkbox"
      ref="checkbox"
      class="checkbox__input"
      :name="label"
      :checked="checked"
      :aria-label="label"
    />
    <BaseIcon v-bind="getIconOptions" />
    <label
      :for="id"
      :class="['checkbox__label', labelHidden && 'checkbox__label--hidden']"
      >{{ getLabel() }}</label
    >
  </div>
</template>

<script>
import { makeUUID } from "@/utils";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { shapes, themes } from "@/constants";

export default {
  name: "Checkbox",
  components: { BaseIcon },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    labelHidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shapes,
      id: `checkbox_${makeUUID()}`
    };
  },
  computed: {
    getIconOptions() {
      return {
        theme: this.checked ? themes.PRIMARY : themes.SECONDARY,
        icon: this.checked ? "check" : "",
        shape: shapes.SQUARE,
        size: 20
      };
    }
  },
  methods: {
    getLabel() {
      return this.labelHidden ? "" : this.label;
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__input {
    @include screen-reader-only;
  }

  &__label {
    margin-bottom: initial;
    margin-left: 10px;

    &--hidden {
      margin-left: 0;
    }
  }
}
</style>
