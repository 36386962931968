const userMember = {
  USER: 3,
  ADMIN: 8,
  VIEWER: 10,
  SUPER_ADMIN: 4,
  THIRD_PARTY: 16
};

const roles = {
  BASIC: "basic",
  ADMIN: "admin",
  SUPER_ADMIN: "super-admin"
};

const userPassword = {
  RESET_PASSWORD: 2,
  DO_NOT_RESET_PASSWORD: 1
};

const userPasswordUpdated = {
  SUCCESS_PASSWORD_UPDATE: 1,
  FAIL_PASSWORD_UPDATE: 0
};

const userRegistration = {
  UPDATE_PROFILE: 0,
  NOT_UPDATE_PROFILE: 1
};

const memberGroupId = {
  DEACTIVATED: 5,
  ACTIVATED: 3
};

export {
  userMember,
  userPassword,
  userPasswordUpdated,
  userRegistration,
  roles,
  memberGroupId
};
